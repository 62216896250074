import React from "react";
import moment from "moment";
import axios from "axios";
import { Tooltip } from "chart.js";
import { Bar } from "react-chartjs-2";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import RoundDropdown from "../projects/round_dropdown";
import CalendarIcon from "../icons/calendar_icon";

Tooltip.positioners.custom2 = function (elements, eventPosition) {
  return {
    x: eventPosition.x - 120,
    y: eventPosition.y - 40,
  };
};

class WeekChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      pickerFocus: false,
      pickerDate: moment(new Date()),
      currentWeekStart: moment().startOf("week").format("YYYY-MM-DD"),
      currentWeekEnd: moment().endOf("week").format("YYYY-MM-DD"),
      chartData: {
        datasets: [
          {
            label: "Hours Booked",
            backgroundColor: "rgba(108, 170, 218 ,0.2)",
            strokeColor: "rgba(108, 170, 218 ,1)",
            pointColor: "rgba(108, 170, 218 ,1)",
            pointStrokeColor: "#fff",
            pointHighlightFill: "#fff",
            barThickness: 40,
            pointHighlightStroke: "rgba(108, 170, 218, 0.8)",
            data: [0],
          },
          {
            label: "Hours Available",
            backgroundColor: "rgba(77, 182, 172, .6)",
            strokeColor: "rgba(19, 140, 31,1)",
            pointColor: "rgba(19, 140, 31,1)",
            pointStrokeColor: "#fff",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "rgba(151,187,205,1)",
            barThickness: 40,
            data: [40],
          },
          {
            label: "total hours",
            backgroundColor: "rgba(119, 140, 31,0.2)",
            strokeColor: "rgba(19, 140, 31,1)",
            pointColor: "rgba(19, 140, 31,1)",
            pointStrokeColor: "#fff",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "rgba(151,187,205,1)",
            barThickness: 40,
            data: [40],
          },
        ],
      },
      chartOptions: {
        indexAxis: "y",
        tooltips: {
          display: true,
          caretSize: 0,
          position: "custom2",
          titleFontSize: 0,
          callbacks: {
            label(tooltipItem, data) {
              const label = data.datasets[tooltipItem.datasetIndex].label || "";
              return `${label} : ${tooltipItem.xLabel} hrs`;
            },
            //   footer: function(tooltipItem, data) {
            //     return ['Capacity for week : ' + (data.datasets[0].data[0] + data.datasets[1].data[0])];
            // }
          },
        },
        responsive: true,
        scales: {
          x: {
            max: 1200,
            beginAtZero: true,
            stacked: true,
            ticks: {
              stepSize: 300,
            },
          },
          y: {
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
          },
        },
        plugins: {
          datalabels: {
            display(context) {
              const total = context.chart.config.data.datasets[4].data[0];
              return (
                Math.round(
                  (context.dataset.data[context.dataIndex] / total) * 100
                ) >= 1
              );
            },
            formatter(value, context) {
              const total = context.chart.config.data.datasets[4].data[0];
              const percent = Math.round((value / total) * 100);
              if (percent > 2) {
                return `${percent}%`;
              }
              return [percent, "%"];
            },
          },
          legend: {
            labels: {
              filter(item, chart) {
                // Logic to remove a particular legend item goes here
                if (item && item.text && item.text.includes("total hours")) {
                  return false;
                }
                if (item && item.text && item.text.includes("Capacity")) {
                  return false;
                }
                return true;
              },
            },
          },
        },
      },
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.prevWeek = this.prevWeek.bind(this);
    this.upcomingWeek = this.upcomingWeek.bind(this);
    this.resetWeek = this.resetWeek.bind(this);
    this.handleFocusChange = this.handleFocusChange.bind(this);
  }

  handleDateChange(date) {
    this.setState({ pickerDate: date });
    const today = moment(date);
    const weekStart = moment(today).startOf("week").format("YYYY-MM-DD");
    const weekEnd = moment(today).endOf("week").format("YYYY-MM-DD");
    this.setState({ currentWeekStart: weekStart, currentWeekEnd: weekEnd });
    this.updateWeeklyHours(weekStart, weekEnd);
  }

  getWeeklyHours() {
    const { currentWeekStart } = this.state;
    const { currentWeekEnd } = this.state;

    axios
      .get(`/week_dash/${currentWeekStart}/${currentWeekEnd}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const x = this.state.chartOptions;
        x.scales.x.max = res.data.datasets[4].data[0];
        this.setState({
          chartData: res.data,
          chartOptions: x,
          loading: false,
        });
      })
      .catch((err) => console.log(err));
  }

  resetWeeklyHours() {
    const currentWeekStart = moment().startOf("week").format("YYYY-MM-DD");
    const currentWeekEnd = moment().endOf("week").format("YYYY-MM-DD");

    axios
      .get(`/week_dash/${currentWeekStart}/${currentWeekEnd}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => this.setState({ chartData: res.data }))
      .catch((err) => console.log(err));
  }

  updateWeeklyHours(week_start, week_end) {
    axios
      .get(`/week_dash/${week_start}/${week_end}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => this.setState({ chartData: res.data }))
      .catch((err) => console.log(err));
  }

  renderDateDisplay() {
    const displayDateStart = moment(this.state.currentWeekStart)
      .add(1, "day")
      .format("M/DD/YY");
    const displayDateEnd = moment(this.state.currentWeekEnd)
      .subtract(1, "day")
      .format("M/DD/YY");
    return (
      <div id="date-display">
        {this.state.loading ? (
          <div
            style={{ height: "30px", width: "30px" }}
            className="page-load-spinner"
          />
        ) : (
          <p>
            {displayDateStart} -{displayDateEnd}
          </p>
        )}
      </div>
    );
  }

  componentDidMount() {
    this.getWeeklyHours();
  }

  prevWeek() {
    const newWeekStart = moment(this.state.currentWeekStart.slice())
      .subtract(7, "d")
      .format("YYYY-MM-DD");
    const newWeekEnd = moment(this.state.currentWeekEnd.slice())
      .subtract(7, "d")
      .format("YYYY-MM-DD");
    this.setState({
      currentWeekStart: newWeekStart,
      currentWeekEnd: newWeekEnd,
    });
    // this.updateWeeklyHours(moment(this.state.currentWeekStart).subtract(7, 'day').format('YYYY-MM-DD'), moment(this.state.currentWeekEnd).subtract(7, 'day').format('YYYY-MM-DD'));
    this.updateWeeklyHours(newWeekStart, newWeekEnd);
  }

  upcomingWeek() {
    const newWeekStart = moment(this.state.currentWeekStart.slice())
      .add(7, "d")
      .format("YYYY-MM-DD");
    const newWeekEnd = moment(this.state.currentWeekEnd.slice())
      .add(7, "d")
      .format("YYYY-MM-DD");
    this.setState({
      currentWeekStart: newWeekStart,
      currentWeekEnd: newWeekEnd,
    });
    // this.updateWeeklyHours(moment(this.state.currentWeekStart).add(7, 'day').format('YYYY-MM-DD'), moment(this.state.currentWeekEnd).add(7, 'day').format('YYYY-MM-DD'));
    this.updateWeeklyHours(newWeekStart, newWeekEnd);
  }

  resetWeek() {
    this.setState({
      currentWeekStart: moment().startOf("week").format("YYYY-MM-DD"),
      currentWeekEnd: moment().endOf("week").format("YYYY-MM-DD"),
    });
    this.resetWeeklyHours();
  }

  handleFocusChange({ focused }) {
    this.setState({ pickerFocus: focused });
  }

  isDayBlocked(day) {
    return !!(moment(day).day() === 0 || moment(day).day() === 6);
  }

  render() {
    const displayDateStart = moment(this.state.currentWeekStart).add(1, "day");
    const displayDateEnd = moment(this.state.currentWeekEnd)
      .subtract(1, "day")
      .endOf("day");
    const today = moment(new Date());
    const todayIsInRange = today.isBetween(
      displayDateStart,
      displayDateEnd,
      "days",
      "(]"
    );

    return (
      <div id="week-schedule-container">
        <h1 style={{ margin: "0 0 20px" }} className="rope-title">
          Reports
        </h1>
        <div className="col s12">
          <div id="date-nav-header" className="valign-wrapper">
            <div className="flex-j-start flex-container flex-al-center">
              <div
                style={{
                  position: "relative",
                  left: "40px",
                  zIndex: 1,
                  top: "5px",
                }}
              >
                <CalendarIcon width="22px" height="22px" color="#519acc" />
              </div>
              <SingleDatePicker
                date={this.state.pickerDate} // momentPropTypes.momentObj or null
                onDateChange={this.handleDateChange} // PropTypes.func.isRequired
                focused={this.state.pickerFocus} // PropTypes.bool
                onFocusChange={this.handleFocusChange} // PropTypes.func.isRequired
                numberOfMonths={1}
                placeholder="Select a date"
                orientation="horizontal"
                daySize={30}
                navPrev={
                  <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                    <RoundDropdown rotate="rotate(90deg)" />
                  </div>
                }
                navNext={
                  <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                    <RoundDropdown rotate="rotate(-90deg)" />
                  </div>
                }
                hideKeyboardShortcutsPanel
                inputIconPosition="after"
                customInputIcon={<RoundDropdown />}
                isOutsideRange={this.isDayBlocked}
                id="single_date_picker" // PropTypes.string.isRequired,
              />
            </div>
            <div
              style={{ flex: 1, marginLeft: "-161px" }}
              className="flex-container flex-al-center flex-j-center valign-wrapper"
            >
              <button
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
                id="prevWeek"
                onClick={this.prevWeek}
              >
                <img
                  alt="left chevron"
                  style={{
                    transform: "rotate(90deg)",
                    marginRight: "15px",
                    verticalAlign: "-8px",
                  }}
                  width="20"
                  src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/syncit/Icon_DropArrow_white.svg"
                />
              </button>
              <div>{this.renderDateDisplay()}</div>
              <button
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
                id="upcomingWeek"
                onClick={this.upcomingWeek}
              >
                <img
                  alt="right chevron"
                  style={{
                    transform: "rotate(-90deg)",
                    marginLeft: "15px",
                    verticalAlign: "-8px",
                  }}
                  width="20"
                  src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/syncit/Icon_DropArrow_white.svg"
                />
              </button>
            </div>
            <div
              style={{
                marginLeft: "auto",
                height: "60px",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                top: "5px",
              }}
              className="col s2 m2 l1 center"
              id="resetWeekContainer"
            >
              {!todayIsInRange && (
                <a id="todayBtn" className="adjusted" onClick={this.resetWeek}>
                  <i
                    style={{ pointerEvents: "none" }}
                    className="material-icons white-text"
                  >
                    today
                  </i>
                </a>
              )}
            </div>
          </div>
        </div>
        {!this.state.loading ? (
          <Bar
            data={this.state.chartData}
            options={this.state.chartOptions}
            width={1000}
            height={100}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default WeekChart;
