import React from "react";

const AccordionClosedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
    >
      <rect x="0.5" y="0.5" width="34" height="34" rx="4.5" fill="white" />
      <rect x="0.5" y="0.5" width="34" height="34" rx="4.5" stroke="#D7D7D7" />
      <path
        d="M10 17.5C10 16.6716 10.5596 16 11.25 16H23.75C24.4404 16 25 16.6716 25 17.5C25 18.3284 24.4404 19 23.75 19H11.25C10.5596 19 10 18.3284 10 17.5Z"
        fill="#519ACC"
      />
      <path
        d="M17.5 10C18.3284 10 19 10.5596 19 11.25L19 23.75C19 24.4404 18.3284 25 17.5 25C16.6716 25 16 24.4404 16 23.75L16 11.25C16 10.5596 16.6716 10 17.5 10Z"
        fill="#519ACC"
      />
    </svg>
  );
};

export default AccordionClosedIcon;
