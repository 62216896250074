import React from "react";
import moment from "moment";
import TimeEntryColumnCard from "./time_entry_column_card";
import ClosureRow from "../closures/closure_row";

class TimeEntryDayColumn extends React.Component {
  constructor(props) {
    super(props);
    this.addTask = this.addTask.bind(this);
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragDrop = this.onDragDrop.bind(this);
  }

  addTask() {
    const data = {
      userName: this.props.userName,
      userId: this.props.userId,
      date: this.props.day,
      closeButton: true,
      customClass: "add-task-modal",
    };
    this.props.modalAction(true, "add-task", data);
  }

  onDragEnter(e) {
    if (!e.target.classList.contains("task-card")) {
      e.target.classList.add("day-column-drag-enter");
    }
  }

  onDragLeave(e) {
    e.target.classList.remove("day-column-drag-enter");
  }

  onDragOver(e) {
    e.preventDefault();
  }

  onDragDrop(e) {
    e.preventDefault();
    if (!e.target.classList.contains("task-card")) {
      e.target.classList.remove("add-task-dash-drag-enter");
      const data = e.dataTransfer.getData("text");
      const newData = {
        userId: this.props.userId,
        date: this.props.day,
      };
      const copy = !!e.altKey;
      this.props.dragDropAddTask(data, newData, copy);
    }
  }

  render() {
    let {
      day, timeEntries, closure, fullTime
    } = this.props;
    if (!timeEntries) {
      timeEntries = [];
    }
    let totalHours = 0;
    timeEntries.forEach((t) => (totalHours += t.hours));
    return (
      <div
        aria-label={`${moment(day).format("dddd MMMM Do")}, ${
          timeEntries.length > 0
            ? `${timeEntries.length} time ${
              timeEntries.length > 1 ? "entries" : "entry"
            } logged, totaling ${totalHours.toFixed(2)} hours`
            : "No time entries logged"
        }`}
        role={timeEntries.length > 0 ? "list" : "group"}
        id={`${this.props.day}-${this.props.userId}`}
        onDrop={this.onDragDrop}
        onDragOver={this.onDragOver}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        style={{
          width: this.props.twoWeeks
            ? this.props.userSchedule
              ? "calc(10% - 15px)"
              : "9.09%"
            : this.props.userSchedule
              ? "calc(20% - 30px)"
              : "16.66%",
          padding: `0px 15px ${this.props.userSchedule ? "0px" : "35px"}`,
          position: "relative",
          borderRight:
            this.props.twoWeeks && this.props.currentWeekEnd
              ? "solid 1px lightgray"
              : "none",
        }}
      >
        {closure && !fullTime ? (
          <ClosureRow
            twoWeeks={this.props.twoWeeks}
            name={closure.name}
            key={closure.id}
          />
        ) : timeEntries && timeEntries.length > 0 ? (
          timeEntries.map((t) => {
            return (
              <TimeEntryColumnCard
                userShowPage={this.props.userShowPage}
                twoWeeks={this.props.twoWeeks}
                date={day}
                modalAction={this.props.modalAction}
                key={t.id}
                timeEntry={t}
              />
            );
          })
        ) : (
          <p
            aria-hidden="true"
            style={{
              color: "#b3b3b3",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              margin: "0px",
              padding: "20px 0px 15px",
            }}
          >
            Available
          </p>
        )}
      </div>
    );
  }
}

TimeEntryDayColumn.defaultProps = {
  userSchedule: false,
};

export default TimeEntryDayColumn;
