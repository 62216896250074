import React from "react";
import moment from "moment";
import axios from "axios";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";

class MonthChart extends React.Component {
  // displayName: "MonthChart",
  constructor(props) {
    super(props);
    this.state = {
      currentMonthStart: moment().startOf("month").format("YYYY-MM-DD"),
      currentMonthEnd: moment().endOf("month").format("YYYY-MM-DD"),
      pickerFocus: false,
      pickerDate: moment(new Date()),
      chartData: {
        labels: [
          "2018-04-22",
          "2018-04-29",
          "2018-05-06",
          "2018-05-13",
          "2018-05-20",
          "2018-05-27",
          "2018-05-03",
        ],
        datasets: [
          {
            label: "Hours Booked",
            arThickness: 73,
            backgroundColor: "rgba(21, 91, 219,0.2)",
            strokeColor: "rgba(21, 91, 219,1)",
            pointColor: "rgba(21, 91, 219,1)",
            pointStrokeColor: "#fff",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "rgba(220,220,220,1)",
            data: [65, 59, 80, 81, 56],
          },
          {
            label: "Hours Available",
            barThickness: 73,
            backgroundColor: "rgba(119, 140, 31,0.2)",
            strokeColor: "rgba(19, 140, 31,1)",
            pointColor: "rgba(19, 140, 31,1)",
            pointStrokeColor: "#fff",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "rgba(151,187,205,1)",
            data: [28, 48, 40, 19, 86],
          },
        ],
      },
      chartOptions: {
        scales: {
          x: {
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
          },
          y: {
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
          },
        },
        legend: {
          labels: {
            filter(item, chart) {
              // Logic to remove a particular legend item goes here
              if (item.text.includes("Vacation Hours")) {
                return false;
              }
              if (item.text.includes("Capacity")) {
                return false;
              }
              return true;
            },
          },
        },
        plugins: {
          datalabels: {
            display(context) {
              if (context.datasetIndex === 1 || context.datasetIndex === 0) {
                if (
                  context.chart.legend.legendItems[context.datasetIndex]
                    .hidden === true
                ) {
                  return false;
                }
                return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
              }
              return false;
            },
          },
        },
      },
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.prevMonth = this.prevMonth.bind(this);
    this.upcomingMonth = this.upcomingMonth.bind(this);
    this.resetMonth = this.resetMonth.bind(this);
    this.handleFocusChange = this.handleFocusChange.bind(this);
  }

  componentDidMount() {
    this.getMonthlyHours();
  }

  handleDateChange(date) {
    this.setState({ pickerDate: date });
    const today = moment(date);
    const monthStart = moment(today).startOf("month").format("YYYY-MM-DD");
    const monthEnd = moment(today).endOf("month").format("YYYY-MM-DD");
    this.setState({ currentMonthStart: monthStart, currentMonthEnd: monthEnd });
    this.updateMonthlyHours(
      moment(monthStart).format("M"),
      moment(monthStart).format("YYYY")
    );
  }

  getMonthlyHours() {
    const month = moment(this.state.currentMonthStart).format("M");
    const year = moment(this.state.currentMonthStart).format("YYYY");
    const url = `/month_dash/${month}/${year}`;

    axios
      .get(`/month_dash/${month}/${year}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.setState({
          chartData: res.data,
          loading: false,
        });
      })
      .catch((err) => console.log(err));
  }

  resetMonthlyHours() {
    const month = moment().startOf("month").format("MM");
    const year = moment(this.state.currentMonthStart).format("YYYY");
    const url = `/month_dash/${month}/${year}`;

    axios
      .get(`/month_dash/${month}/${year}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.setState({
          chartData: res.data,
          loading: false,
        });
      })
      .catch((err) => console.log(err));
  }

  updateMonthlyHours(month, year) {
    const url = `/month_dash/${month}/${year}`;
    axios
      .get(`/month_dash/${month}/${year}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.setState({
          chartData: res.data,
          loading: false,
        });
      })
      .catch((err) => console.log(err));
  }

  renderDateDisplay() {
    const displayDateStart = moment(this.state.currentMonthStart).format(
      "MMMM"
    );
    return <p id="date-display">{displayDateStart}</p>;
  }

  prevMonth() {
    const newMonthStart = moment(this.state.currentMonthStart.slice())
      .subtract(1, "month")
      .format("YYYY-MM-DD");
    const newMonthEnd = moment(this.state.currentMonthEnd.slice())
      .subtract(1, "month")
      .format("YYYY-MM-DD");
    this.setState({
      currentMonthStart: newMonthStart,
      currentMonthEnd: newMonthEnd,
    });
    this.updateMonthlyHours(
      moment(newMonthStart).format("M"),
      moment(newMonthStart).format("YYYY")
    );
  }

  upcomingMonth() {
    const newMonthStart = moment(this.state.currentMonthStart.slice())
      .add(1, "month")
      .format("YYYY-MM-DD");
    const newMonthEnd = moment(this.state.currentMonthEnd.slice())
      .add(1, "month")
      .format("YYYY-MM-DD");
    this.setState({
      currentMonthStart: newMonthStart,
      currentMonthEnd: newMonthEnd,
    });
    this.updateMonthlyHours(
      moment(newMonthStart).format("M"),
      moment(newMonthStart).format("YYYY")
    );
  }

  resetMonth() {
    this.setState({
      currentMonthStart: moment().startOf("month").format("YYYY-MM-DD"),
      currentMonthEnd: moment().endOf("month").format("YYYY-MM-DD"),
    });
    this.resetMonthlyHours();
  }

  handleFocusChange({ focused }) {
    this.setState({ pickerFocus: focused });
  }

  isDayBlocked(day) {
    return !!(moment(day).day() === 0 || moment(day).day() === 6);
  }

  render() {
    const todayIsInRange = moment().isBetween(
      this.state.currentMonthStart,
      this.state.currentMonthEnd,
      "days",
      "[]"
    );

    return (
      <div id="month-schedule-container" className="col s12">
        <div className="row card task-card light-gray valign-wrapper">
          <label
            className="col s2 m1 valign-wrapper navbar-date-icon"
            htmlFor="single_date_picker1"
          >
            <i
              className="black-text right material-icons"
              style={{ marginLeft: "auto", marginRight: "auto" }}
              id="date-range-icon"
            >
              date_range
            </i>
          </label>

          {/* <input type="text" className="datepicker3" id="nav-datepicker" style={{height: "0px", width: "0px"}} required={true} value={ this.state.value } /> */}
          <div
            className="col s8 push-s2 m6 push-m3 l6 push-l3 valign-wrapper"
            style={{ left: "33%" }}
          >
            <div className="col s1 m1 push-m1">
              <a
                className="black-text valign-wrapper"
                id="prevWeek"
                onClick={this.prevMonth}
              >
                <i className="material-icons">arrow_back</i>
              </a>
            </div>
            <div className="col s10 m10">{this.renderDateDisplay()}</div>
            <div className="col s1 m1 pull-m1">
              <a
                className="black-text right valign-wrapper"
                id="upcomingWeek"
                onClick={this.upcomingMonth}
              >
                <i className="material-icons">arrow_forward</i>
              </a>
            </div>
          </div>
          <div className="col s1 m2" />
          <div className="col s0 m4 l6" />
          <div className="col s2 m2 l2 center" id="resetWeekContainer">
            <a className="black-text" id="resetWeek" onClick={this.resetMonth}>
              {todayIsInRange ? "" : "Current Month"}
            </a>
          </div>
        </div>
        <Bar
          data={this.state.chartData}
          options={this.state.chartOptions}
          width={1000}
          height={250}
        />
        <SingleDatePicker
          date={this.state.pickerDate} // momentPropTypes.momentObj or null
          onDateChange={this.handleDateChange} // PropTypes.func.isRequired
          focused={this.state.pickerFocus} // PropTypes.bool
          onFocusChange={this.handleFocusChange} // PropTypes.func.isRequired
          numberOfMonths={12}
          orientation="vertical"
          verticalSpacing={0}
          daySize={50}
          isOutsideRange={this.isDayBlocked}
          id="single_date_picker1" // PropTypes.string.isRequired,
        />
      </div>
    );
  }
}

export default MonthChart;
