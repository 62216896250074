import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";

const TestCard = styled.div`
  margin: 20px 0;

  label {
    color: #313131 !important;
    font-size: 14px !important;
  }

  .input-field > svg {
    height: 22px;
    vertical-align: sub;
  }
  .input-field > input::-webkit-outer-spin-button,
  .input-field > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

function TestStage(props) {
  function save() {
    const project = {};
    props.submitHandler(project, "testing", "completing", true);
  }

  function submit(e) {
    e.preventDefault();
    const errors = [];
    const project = {};
    if (testStartDate) {
      project.test_start_date = testStartDate;
    }
    if (testEndDate) {
      project.test_end_date = testEndDate;
    }
    if (hoursBooked) {
      project.booked_hours = hoursBooked;
    }
    if (errors.length === 0) {
      props.submitHandler(project, "testing", "completing", false);
    } else {
      M.toast({
        html: errors.join(", "),
        classes: "danger",
      });
    }
  }

  return (
    <TestCard>
      <form
        onSubmit={submit}
        style={{ margin: "55px 0px 0px 4px" }}
        className="flex-container flex-wrap flex-row flex-al-center"
      >
        <div
          style={{
            flex: 1,
            marginLeft: "-30px",
            maxWidth: "200px",
            marginRight: "20px",
          }}
          className="flex-j-start flex-container flex-al-center"
        />
        <div
          className="flex-container"
          style={{ flex: "1 1 100%", justifyContent: "flex-end" }}
        >
          <button type="button" onClick={save} className="common-button-cancel">
            Save
          </button>
        </div>
      </form>
    </TestCard>
  );
}

export default TestStage;
