import React from "react";

function LeadSourceFilledIcon() {
  return (
    <svg
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1845_3785)">
        <rect width="51" height="51" rx="25.5" fill="white" />
        <path
          d="M41.105 45.6038L34.5202 39.2531C34.2842 39.0184 34.097 38.7392 33.9696 38.4317C33.8422 38.1242 33.777 37.7945 33.7778 37.4616V35.2183L30.9211 32.3616C27.9076 34.8615 24.0446 36.1001 20.1393 35.8188C16.234 35.5374 12.5884 33.7579 9.96432 30.8519C7.34023 27.9458 5.9406 24.1382 6.05785 20.2245C6.17511 16.3108 7.80018 12.5938 10.5935 9.85015C13.3869 7.10645 17.1324 5.54831 21.0476 5.50126C24.9628 5.45422 28.7447 6.92192 31.6032 9.59771C34.4616 12.2735 36.1755 15.9504 36.3868 19.8601C36.5981 23.7699 35.2903 27.61 32.7368 30.5782L35.5934 33.4349H37.8126C38.4829 33.4355 39.1262 33.6992 39.604 34.1692L46.0597 40.5846C49.8819 35.3751 51.584 28.9089 50.822 22.4927C50.06 16.0766 46.8908 10.1889 41.9547 6.01953C37.0187 1.85017 30.684 -0.28996 24.2308 0.0316301C17.7775 0.35322 11.687 3.11255 7.18995 7.75199C2.69291 12.3914 0.124741 18.565 0.004426 25.0251C-0.115889 31.4853 2.22063 37.7502 6.54179 42.5539C10.863 47.3576 16.8465 50.3418 23.2833 50.9034C29.7201 51.4651 36.1301 49.5623 41.218 45.5796L41.105 45.6038Z"
          fill="url(#paint0_linear_1845_3785)"
        />
        <path
          d="M21.2129 15.818C20.7183 15.818 20.2349 15.9645 19.8236 16.2391C19.4123 16.5137 19.0915 16.904 18.9019 17.3608C18.7123 17.8175 18.6623 18.3202 18.7582 18.8054C18.8541 19.2906 19.0916 19.7364 19.4408 20.0867C19.7899 20.4369 20.235 20.6759 20.7199 20.7733C21.2047 20.8707 21.7076 20.8223 22.1649 20.6342C22.6223 20.446 23.0136 20.1265 23.2895 19.7161C23.5654 19.3057 23.7135 18.8227 23.7151 18.3281C23.7172 17.9986 23.6539 17.6719 23.529 17.3669C23.4041 17.0619 23.22 16.7847 22.9874 16.5513C22.7547 16.3179 22.4781 16.1329 22.1735 16.007C21.8689 15.8812 21.5424 15.8169 21.2129 15.818Z"
          fill="url(#paint1_linear_1845_3785)"
        />
        <path
          d="M30.5203 29.0541C32.1284 27.2577 33.1822 25.0342 33.5542 22.6521C33.9263 20.27 33.6008 17.8311 32.617 15.6299C31.6332 13.4288 30.0332 11.5595 28.0102 10.2478C25.9872 8.93611 23.6278 8.23813 21.2168 8.23813C18.8058 8.23813 16.4463 8.93611 14.4233 10.2478C12.4004 11.5595 10.8004 13.4288 9.81657 15.6299C8.83278 17.8311 8.50726 20.27 8.87932 22.6521C9.25138 25.0342 10.3051 27.2577 11.9133 29.0541C12.3109 28.4771 12.8633 28.0241 13.507 27.7472L14.8298 27.1814C16.8457 26.3235 19.014 25.8813 21.2048 25.8813C23.3957 25.8813 25.5639 26.3235 27.5798 27.1814L28.9026 27.7472C29.554 28.0221 30.1146 28.4751 30.5203 29.0541ZM21.2128 23.3086C20.2239 23.3086 19.2573 23.0151 18.4353 22.4654C17.6133 21.9157 16.9729 21.1344 16.5952 20.2205C16.2175 19.3066 16.1195 18.3012 16.3136 17.3316C16.5077 16.3619 16.9851 15.4717 17.6855 14.7736C18.3858 14.0754 19.2776 13.6008 20.2478 13.4098C21.2181 13.2189 22.2232 13.3201 23.1359 13.7007C24.0486 14.0813 24.8278 14.7242 25.3749 15.5479C25.922 16.3717 26.2124 17.3393 26.2092 18.3281C26.2029 19.6498 25.6739 20.9154 24.7379 21.8485C23.8018 22.7816 22.5345 23.3065 21.2128 23.3086Z"
          fill="url(#paint2_linear_1845_3785)"
        />
        <path
          d="M26.6236 29.4844C24.913 28.7549 23.0725 28.3788 21.2128 28.3788C19.3531 28.3788 17.5127 28.7549 15.802 29.4844L14.4792 30.0501C14.3192 30.1268 14.1783 30.238 14.0667 30.3758C13.955 30.5137 13.8755 30.6746 13.8337 30.847C15.9603 32.449 18.5504 33.3154 21.2128 33.3154C23.8752 33.3154 26.4653 32.449 28.5919 30.847C28.5501 30.6746 28.4706 30.5137 28.3589 30.3758C28.2473 30.238 28.1064 30.1268 27.9464 30.0501L26.6236 29.4844Z"
          fill="url(#paint3_linear_1845_3785)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1845_3785"
          x1="25.5"
          y1="0"
          x2="25.5"
          y2="51"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1845_3785"
          x1="21.2129"
          y1="15.818"
          x2="21.2129"
          y2="20.8224"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1845_3785"
          x1="21.2168"
          y1="8.23813"
          x2="21.2168"
          y2="29.0541"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1845_3785"
          x1="21.2128"
          y1="28.3788"
          x2="21.2128"
          y2="33.3154"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
        <clipPath id="clip0_1845_3785">
          <rect width="51" height="51" rx="25.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

LeadSourceFilledIcon.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "#519acc",
};

export default LeadSourceFilledIcon;
