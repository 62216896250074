import React from "react";
import SvgShell from "./svg_shell";

function LeadSourceIcon({ height, width, svgStyles, color }) {
  return (
    <SvgShell height={height} width={width} svgStyles={svgStyles} color={color}>
      <path d="M60,53,49.49,42.51a3.5,3.5,0,0,0-2.47-1H44L40,37.55A21,21,0,1,0,37.55,40L41.49,44V47a3.5,3.5,0,0,0,1,2.47L53,60a3.49,3.49,0,0,0,4.93,0L60,57.91A3.49,3.49,0,0,0,60,53ZM6.61,24.05A17.44,17.44,0,1,1,37,35.57a5.17,5.17,0,0,0-2.21-1.82L33,33a22.7,22.7,0,0,0-17.86,0l-1.85.79a5.23,5.23,0,0,0-2.21,1.82A17.26,17.26,0,0,1,6.61,24.05Zm7.13,14a1.7,1.7,0,0,1,.9-1.08l1.85-.8a19.36,19.36,0,0,1,15.12,0l1.85.8a1.7,1.7,0,0,1,.9,1.08,17.13,17.13,0,0,1-20.62,0Zm10.31-10.5a7,7,0,1,0-7-7A7,7,0,0,0,24.05,27.54Zm0-10.47a3.49,3.49,0,1,1-3.49,3.49A3.49,3.49,0,0,1,24.05,17.07Z" />
    </SvgShell>
  );
}

LeadSourceIcon.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "#519acc",
};

export default LeadSourceIcon;
