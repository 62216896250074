import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ms from "components/modals/ModalStyled";
import moment from "moment";
import { taskboardProjectTypes, userTypes } from "../../../types";
import SingleDateWrapper from "../../common/single_date_wrapper";

const EstSentTransition = ({
  cancelHandler,
  submitHandler,
  currentUser,
  project,
}) => {
  const startUrl =
    project.project_links.length > 0 ? project.project_links[0].location : "";
  const [testStartDate, setTestStartDate] = useState(
    project.test_start_date !== "Unknown"
      ? moment(project.test_start_date)
      : undefined
  );
  const [startDateFocus, setStartDateFocus] = useState(false);
  const [focusCounter, setFocusCounter] = useState(0);
  const [estimateUrl, setEstimateUrl] = useState(startUrl);
  const [bugTrackerTp, setBugTrackerTp] = useState(
    project.bug_tracker_tp === null ? true : project.bug_tracker_tp
  );
  const [hours, setHours] = useState(
    project.budgeted_hours ? parseFloat(project.budgeted_hours).toFixed(2) : ""
  );

  const handleStartDateChange = (date) => {
    setTestStartDate(date);
  };

  const roundInput = (e) => {
    const input = parseFloat(e.target.value);
    let nextValue = input;
    if (input) {
      if (input < 0) {
        nextValue = Math.abs(input);
      }
      if (input <= 0.12) {
        nextValue = "0.25";
      } else {
        nextValue = (Math.round(input * 4) / 4).toFixed(2);
      }
    } else {
      nextValue = "0.00";
    }
    setHours(nextValue);
  };

  const onPaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("Text");
    const num = parseFloat(pastedData);
    if (num && num < 2000) {
      setHours(num.toFixed(2));
    }
  };

  const handleStartDateFocus = (focused) => {
    if (startDateFocus && focused.focused && focusCounter === false) {
      setStartDateFocus(true);
      setFocusCounter(true);
    } else if (startDateFocus && focused.focused && focusCounter) {
      setStartDateFocus(true);
      setFocusCounter(true);
    } else {
      setStartDateFocus(focused.focused);
      setFocusCounter(true);
    }
  };

  const submit = (e) => {
    e.preventDefault();
    const errors = [];
    const projectUpdate = {
      bug_tracker_tp: bugTrackerTp,
    };
    const estimateDetails = {};

    if (testStartDate) {
      projectUpdate.test_start_date = testStartDate;
    } else {
      projectUpdate.test_start_date = null;
    }

    if (startUrl !== estimateUrl) {
      if (project.project_links.length > 0) {
        estimateDetails.id = project.project_links[0].id;
        estimateDetails.location = estimateUrl;
        estimateDetails.name = estimateUrl;
      } else {
        estimateDetails.project_id = project.id;
        estimateDetails.user_id = currentUser.id;
        estimateDetails.location = estimateUrl;
        estimateDetails.name = estimateUrl;
        estimateDetails.link_type = "estimate";
      }
      projectUpdate.project_links_attributes = [estimateDetails];
    }

    if (hours) {
      projectUpdate.budgeted_hours = hours;
    } else {
      errors.push("You must set estimated hours to proceed");
    }

    if (errors.length === 0) {
      submitHandler(projectUpdate, "sending", "completing");
    } else {
      // eslint-disable-next-line no-undef
      M.toast({
        html: errors.join(", "),
        classes: "danger",
      });
    }
  };

  const handleInputChange = (e) => {
    let input = e.target.value;
    if (input.length === 0 || (input.length === 1 && input === ".")) {
      input = "";
    } else if (input.length === 5 && !input.includes(".")) {
      if (input[4] !== "." && Number.isNaN(input[4])) {
        e.preventDefault();
      } else if (!Number.isNaN(input[4])) {
        input = `${input.slice(0, 4)}.${input[4]}`;
      }
    } else if (input.length > 7) {
      input = input.slice(0, input.length - 1);
    } else if (input.split(".")[1] && input.split(".")[1].length > 2) {
      input = input.slice(0, input.length - 1);
    }
    setHours(input);
  };

  const handleBugTrackerChange = (e) => {
    setBugTrackerTp(e.target.value === "true");
  };

  return (
    <TestCard>
      <ms.Form
        onSubmit={submit}
        style={{
          flexDirection: "column",
          alignItems: "flex-start",
        }}
        className="flex-container flex-row flex-al-center"
      >
        <ms.FormRow>
          <ms.FormItem>
            <ms.Label className="custom-labels">
              Expected Test Start Date{" "}
              <ms.DisclaimerInline>
                (if unsure, leave blank)
              </ms.DisclaimerInline>
            </ms.Label>
            <SingleDateWrapper
              date={testStartDate}
              onDateChange={handleStartDateChange}
              focused={startDateFocus}
              onFocusChange={handleStartDateFocus}
              required={false}
              label={null}
              placeholder="Select a date"
              subLabel={null}
            />
          </ms.FormItem>
        </ms.FormRow>
        <ms.FormRow>
          <ms.FormItem>
            <ms.Label className="custom-labels">
              Estimate Hours
              <span className="base-color">*</span>
            </ms.Label>
            <input
              className="hoursInput browser-default text-input-v2"
              name="hours"
              type="number"
              value={hours}
              placeholder="0.00"
              style={{ margin: "0" }}
              onPaste={onPaste}
              onBlur={roundInput}
              onChange={handleInputChange}
              min={0}
              max={1000000}
              step={0.25}
            />
          </ms.FormItem>
        </ms.FormRow>
        <BugTracker>
          <ms.FormRow>
            <ms.Label className="custom-labels" htmlFor="recurring-edit">
              Bug Tracker <span className="base-color">*</span>
            </ms.Label>
          </ms.FormRow>
          <ms.FormItem>
            <InlineItems>
              <label
                style={{ marginRight: "10px", marginLeft: "-4px" }}
                htmlFor="recurring-bi-weekly-edit"
              >
                <input
                  onChange={handleBugTrackerChange}
                  className="with-gap"
                  type="radio"
                  name="recurring-edit"
                  id="recurring-bi-weekly-edit"
                  value="true"
                  checked={bugTrackerTp}
                />
                <span>Test Platform</span>
              </label>

              <label htmlFor="recurring-yes-edit">
                <input
                  onChange={handleBugTrackerChange}
                  className="with-gap"
                  type="radio"
                  name="recurring-edit"
                  id="recurring-yes-edit"
                  value="false"
                  checked={!bugTrackerTp}
                />
                <span>Client Provided</span>
              </label>
            </InlineItems>
          </ms.FormItem>
          {bugTrackerTp && (
            <a
              target="_blank"
              rel="noreferrer"
              href="https://testplatform.plusqa.com/admin_panel?new_project=create"
              style={{ marginBottom: "22px", marginTop: "-8px" }}
              className="btn-flat"
            >
              Click here to create Test Platform project
            </a>
          )}
        </BugTracker>
        <ms.FormRow>
          <ms.FormItem>
            <ms.Label className="custom-labels" htmlFor="filter_filters">
              Link to Estimate
              <span className="base-color">*</span>
            </ms.Label>
            <input
              className="browser-default text-input-v2"
              name="hours"
              type="text"
              value={estimateUrl}
              placeholder="Please provide a URL for the estimate"
              style={{ margin: "0" }}
              onChange={(e) => {
                setEstimateUrl(e.target.value ? e.target.value : "");
              }}
            />
          </ms.FormItem>
        </ms.FormRow>
        <ms.ControlsRow>
          <button
            onClick={cancelHandler}
            type="button"
            className="common-button-cancel"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={!estimateUrl}
            className="common-button-submit"
          >
            Submit
          </button>
        </ms.ControlsRow>
      </ms.Form>
    </TestCard>
  );
};

const TestCard = styled.div``;

const BugTracker = styled.div`
  display: flex;
  flex-direction: column;
`;

const InlineItems = styled.div`
  display: flex;

  span {
    font-size: 13px;
    font-family: Arial;
    font-weight: 400;
    color: #242424;
  }
`;

const { func } = PropTypes;

EstSentTransition.propTypes = {
  cancelHandler: func,
  submitHandler: func,
  currentUser: userTypes,
  project: taskboardProjectTypes,
};

export default EstSentTransition;
