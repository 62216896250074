import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import DeleteIcon from "../../icons/delete_icon";
import EditIcon from "../../icons/edit_icon";

function TransitionContactRow(props) {
  const [edit, setEdit] = useState(!props.contact.name);
  const [name, setName] = useState(props.contact.name);
  const [email, setEmail] = useState(props.contact.email);
  const nameInput = useRef(null);
  useEffect(() => {
    if (edit && nameInput.current) {
      nameInput.current.focus();
    }
  }, [edit]);

  function saveContact(e) {
    e.preventDefault();
    if (!name) {
      M.toast({
        html: `Name is required`,
        displayLength: 3000,
        classes: "red",
      });
    } else if (!email) {
      M.toast({
        html: `Email is required`,
        displayLength: 3000,
        classes: "red",
      });
    } else if (!validateEmail(email)) {
      M.toast({ html: `Invalid Email`, displayLength: 3000, classes: "red" });
    } else if (props.contact.newContact) {
      props.addContactHandler(props.type, name, email);
    } else {
      props.editContactHandler(props.contact.id, name, email);
      setEdit(!edit);
    }
  }

  function deleteContact(e) {
    e.preventDefault();
    props.deleteContactHandler(props.contact.id, props.type);
  }

  function validateEmail(email) {
    if (
      email &&
      email.includes("@") &&
      email.includes(".") &&
      email.indexOf("@") !== 0 &&
      email.split("@").length === 2 &&
      email.split("@")[1].split(".").length === 2 &&
      email.split("@")[1].split(".")[0].length > 0 &&
      email.split("@")[1].split(".")[1].length > 1
    ) {
      return true;
    }
    return false;
  }

  function toggleEdit(e) {
    e.preventDefault();
    setEdit(!edit);
    props.setEditingContact(!edit);
    if (props.contact.newContact && props.type === "invoicing") {
      props.removeNewInvoiceContact();
    } else if (props.contact.newContact && props.type !== "invoicing") {
      props.removeNewProjectContact();
    }
  }

  function onNameChange(e) {
    setName(e.target.value);
  }

  function onEmailChange(e) {
    setEmail(e.target.value);
  }

  return edit ? (
    <ContactRow>
      <div>
        <input
          style={{
            fontFamily: "Arial",
            fontSize: "13px",
            width: "160px",
            color: "#313131",
            boxShadow: "0px 1px 0px 0px #519acc",
            borderBottom: "1px solid #519acc",
          }}
          className="browser-default text-input-v2"
          maxLength={50}
          value={name || ""}
          onChange={onNameChange}
          placeholder="Enter name"
          ref={nameInput}
        />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          style={{
            fontFamily: "Arial",
            fontSize: "13px",
            width: "160px",
            color: "#313131",
            boxShadow: "0px 1px 0px 0px #519acc",
            borderBottom: "1px solid #519acc",
          }}
          className="browser-default text-input-v2"
          maxLength={50}
          value={email || ""}
          onChange={onEmailChange}
          placeholder="Enter email"
        />
        <button
          onClick={saveContact}
          aria-label="Edit Contact"
          style={{
            marginLeft: "20px",
            height: "22px",
            padding: "0px",
            backgroundColor: "white",
          }}
        >
          <i
            className="material-icons"
            style={{ color: "#519acc", fontSize: "21px", lineHeight: "20px" }}
          >
            done
          </i>
        </button>
        <button
          onClick={toggleEdit}
          aria-label="Delete Contact"
          style={{
            marginLeft: "5px",
            height: "22px",
            width: "22px",
            padding: "0px",
            backgroundColor: "white",
          }}
        >
          <i
            className="material-icons"
            style={{
              color: "#519acc",
              fontSize: "22px",
              position: "relative",
              bottom: "1px",
              right: "1px",
            }}
          >
            close
          </i>
        </button>
      </div>
    </ContactRow>
  ) : (
    <ContactRow>
      <div>
        <p>{name}</p>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p>{email}</p>
        <button
          onClick={toggleEdit}
          disabled={props.editingContact}
          aria-label="Edit Contact"
          style={{
            marginLeft: "20px",
            height: "22px",
            padding: "2px",
            backgroundColor: "white",

            pointerEvents: props.editingContact ? "none" : "all",
          }}
        >
          <EditIcon
            height={16}
            width={20}
            color="#519acc"
            svgStyles={{ pointerEvent: "none" }}
          />
        </button>
        <button
          onClick={deleteContact}
          disabled={props.editingContact}
          aria-label="Delete Contact"
          style={{
            marginLeft: "5px",
            height: "22px",
            padding: "2px",
            // backgroundColor: props.editingContact ? "#e6e6e6" : "white",
            backgroundColor: "white",
            pointerEvents: props.editingContact ? "none" : "all",
          }}
        >
          <DeleteIcon
            height={16}
            width={20}
            color="#519acc"
            svgStyles={{ pointerEvent: "none" }}
          />
        </button>
      </div>
    </ContactRow>
  );
}

export const ContactRow = styled.div`
  border: solid 1px lightgray;
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0px 10px;

  p {
    font-size: 13px;
    display: inline-block;
    margin: 0px;
  }

  button {
    background: none;
    border: solid 1px lightgray;
    border-radius: 3px;
    cursor: pointer;
    line-height: 13px;
    padding: 2px 4px;
  }

  input {
    background-color: white;
    border: none;
    width: 90px;
    height: 30px;
    border-radius: 5px;
    margin: 0px 0px 0px 5px !important;
    box-shadow: 0px 2px 0px 0px transparent;
    border: 1px solid lightgray;
    position: relative;
    color: #313131;
    transition: box-shadow 0.3s;
    font-size: 15px;
    padding: 0 0 0 10px;
  }

  input::placeholder {
    color: #b2b2b2 !important;
    font-size: 13px !important;
    font-family: Arial !important;
  }
`;

export default TransitionContactRow;
