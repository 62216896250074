import React, { useMemo, useEffect } from "react";
import moment from "moment";
import ScheduleUserRow from "../schedule_user_row";

export default function TasksTable(props) {
  const {
    search,
    searchData,
    users,
    selectedFilter,
    selectedProject,
    selectedClient,
    selectedStatus,
    selectedTeam,
    availability,
    currentWeekStart,
    closures,
    setDraggingItem,
    getDraggingItem,
    setModal,
    dragDropAddTask,
    allowedProjectIds,
    currentUser,
    twoWeeks,
  } = props;

  const usersList = useMemo(() => {
    let list;
    if (search !== "") {
      list = searchData;
    } else if (
      selectedFilter.length +
        selectedProject.length +
        selectedClient.length +
        selectedStatus.length +
        selectedTeam.length >=
        1 ||
      availability.value !== false
    ) {
      list = searchData;
    } else {
      list = users;
    }
    return list;
  }, [
    search,
    searchData,
    users,
    selectedFilter,
    selectedProject,
    selectedClient,
    selectedStatus,
    selectedTeam,
    availability,
  ]);

  const firstDay = currentWeekStart;
  const cutoffDate = moment().day("sunday").subtract(7, "d");
  const currentWeek = [...Array(5)].map((_, i) =>
    moment(firstDay).add(i, "d").format("YYYY-MM-DD")
  );
  const secondWeek = [...Array(5)].map((_, i) =>
    moment(firstDay)
      .add(i + 7, "d")
      .format("YYYY-MM-DD")
  );

  const currentWeekClosures = Array(5).fill(false);
  const secondWeekClosures = Array(5).fill(false);

  if (closures.length > 0) {
    for (let x = 0; x < 5; x += 1) {
      for (let y = 0; y < closures.length; y += 1) {
        if (
          closures[y].start_date <= currentWeek[x] &&
          closures[y].end_date >= currentWeek[x]
        ) {
          currentWeekClosures[x] = closures[y];
        }
        if (
          closures[y].start_date <= secondWeek[x] &&
          closures[y].end_date >= secondWeek[x]
        ) {
          secondWeekClosures[x] = closures[y];
        }
      }
    }
  }

  useEffect(() => {
    // make the date nav sticky on scroll, add the class when the header
    // intersects the top of the viewport and remove when it is scrolled
    // back into view
    const header = document.querySelector(".date-nav-wrapper-not-sticky");

    const intersectionObserverOptions = {
      root: null,
      rootMargin: "-16px",
      threshold: 1.0,
    };

    const observerCb = (entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          header.classList.replace(
            "date-nav-wrapper-not-sticky",
            "date-nav-wrapper-sticky"
          );
        } else {
          header.classList.replace(
            "date-nav-wrapper-sticky",
            "date-nav-wrapper-not-sticky"
          );
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCb,
      intersectionObserverOptions
    );
    if (header && observer) {
      observer.observe(header);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const parentContainer = document.querySelector("#schedule-container");

    // the #schedule-container height is set based on the viewport, while
    // its children will overflow, in order for sticky header behavior
    // to work for scolling all the way to the bottom, we need to
    // explicitly set the height of the container to its scrollHeight.
    if (parentContainer) {
      parentContainer.style.height = `${parentContainer.scrollHeight}px`;
    }

    return () => {
      if (parentContainer) {
        parentContainer.style.height = "auto";
      }
    };
  }, [usersList]);

  if (usersList.length === 0) {
    return (
      <div className="row frame-row">
        <div id="noTasksBox">
          <p className="center">No tasks scheduled for this query</p>
        </div>
      </div>
    );
  }

  return (
    <section id="tasks-section">
      <div className="user-rows-container">
        {usersList.map((u, i) => {
          return (
            <ScheduleUserRow
              allowedProjectIds={allowedProjectIds}
              selectedProjects={selectedProject}
              dragDropAddTask={dragDropAddTask}
              modalAction={setModal}
              user={u}
              bookedByMe={availability.value === null}
              userName={u.name}
              fullTime={u.full_time}
              currentUserId={currentUser.id}
              currentUserRoleId={currentUser.role_id}
              userId={u.id}
              userTasks={u.tasks}
              setDraggingItem={setDraggingItem}
              getDraggingItem={getDraggingItem}
              index={i}
              key={u.id}
              cutoffDate={cutoffDate}
              twoWeeks={twoWeeks}
              currentWeek={currentWeek}
              secondWeek={secondWeek}
              currentWeekClosures={currentWeekClosures}
              secondWeekClosures={secondWeekClosures}
            />
          );
        })}
      </div>
    </section>
  );
}
