import React from "react";
import styled from "styled-components";
import CheckboxMenu from "./checkbox_menu";
import SingleCheckboxMenu from "./single_checkbox_menu";

class PageNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const chars = [].concat.apply([], Array(26)).map((_, i) => {
      return String.fromCharCode(i + 65);
    });
    chars.unshift("#");
    const { usedLetters } = this.props;
    let usedLetterIndex = 0;
    return (
      <div
        id="new-filter-container"
        className="row"
        style={{
          marginBottom: "0px",
        }}
      >
        <div
          style={{ paddingRight: "5px" }}
          className="flex-container flex-al-center flex-space-between"
        >
          <div className="flex-container flex-al-center">
            {this.props.pageIcon && (
              <div style={{ marginRight: "16px", marginBottom: "7px" }}>
                {this.props.pageIcon}
              </div>
            )}
            {this.props.pageName && (
              <h1
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                  marginBottom: "17px",
                  fontFamily: "Manrope",
                  outline: "none",
                }}
                tabIndex={0}
                id="page-nav-title"
              >
                {this.props.pageName}
              </h1>
            )}
          </div>
          {this.props.usedLetters && (
            <ul
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "0px",
              }}
            >
              {chars.map((link, index) => {
                if (usedLetters && usedLetters.includes(link)) {
                  usedLetterIndex += 1;
                }
                return (
                  <li
                    className={
                      usedLetters.includes(link) ? "letters l-hover" : "letters"
                    }
                    style={{
                      color: usedLetters.includes(link)
                        ? "#519acc"
                        : "lightgray",
                      padding: "0 5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                    key={index}
                    value={index}
                  >
                    <button
                      disabled={!usedLetters.includes(link)}
                      className="div-btn-overlay"
                      onClick={this.props.scrollHandler}
                      value={link}
                      aria-label={link}
                      data-index={usedLetterIndex - 1}
                    />
                    {link}
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        <div
          className={`flex-container flex-al-center ${
            !this.props.showSearch && this.props.weekHandler ? "" : "flex-j-end"
          }`}
        >
          {this.props.addHandler && (
            <button
              style={{ marginRight: "15px" }}
              className="common-button-submit"
              onClick={this.props.addHandler}
            >
              {this.props.addText
                ? this.props.addText
                : `Add ${this.props.pageName.substring(
                    0,
                    this.props.pageName.length - 1
                  )}`}
            </button>
          )}

          {this.props.weekHandler && (
            <>
              <button
                aria-label={`1 Week view, ${
                  !this.props.twoWeeks ? "selected" : "unselected"
                }`}
                style={{
                  padding: "14px 15px",
                  marginRight: "4px",
                  pointerEvents: this.props.twoWeeks === false ? "none" : "all",
                  borderRadius: "20px",
                  boxShadow: "none",
                  background:
                    this.props.twoWeeks === false ? "#519acc" : "#C0C0C0",
                  color: this.props.twoWeeks === true ? "#000" : "#fff",
                  border: "none",
                  cursor: "pointer",
                  fontFamily: "Arial",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
                onClick={this.props.weekHandler}
              >
                Week View
              </button>
              <button
                aria-label={`2 Week view, ${
                  this.props.twoWeeks ? "selected" : "unselected"
                }`}
                style={{
                  whiteSpace: "nowrap",
                  padding: "14px 15px",
                  pointerEvents: this.props.twoWeeks === true ? "none" : "all",
                  borderRadius: "20px",
                  boxShadow: "none",
                  background:
                    this.props.twoWeeks === true ? "#519acc" : "#C0C0C0",
                  color: this.props.twoWeeks === true ? "#fff" : "#000",
                  border: "none",
                  cursor: "pointer",
                  fontFamily: "Arial",
                  fontSize: "14px",
                  fontWeight: "400",
                  marginRight: "16px",
                }}
                onClick={this.props.weekHandler}
              >
                2 Week View
              </button>
            </>
          )}

          {this.props.showSearch && (
            <div
              id="ticketSearch"
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "auto",
                paddingRight: "20px",
                // marginLeft: "16px",
              }}
            >
              <i
                aria-hidden="true"
                style={{
                  position: "absolute",
                  pointerEvents: "none",
                  left: "6px",
                  color: "#ababab",
                }}
                className="material-icons prefix"
              >
                {" "}
                search{" "}
              </i>
              <input
                id="bug-search-input"
                className="browser-default"
                type="text"
                onChange={this.props.updateSearchHandler}
                onKeyDown={this.props.searchKeyDownHandler}
                placeholder=" "
                aria-live="polite"
                autoComplete="off"
                value={this.props.search}
                aria-label="Search"
              />
            </div>
          )}
          <div
            style={{ gridGap: "8px" }}
            className="flex-container flex-row flex-wrap"
            tabIndex={0}
          >
            {this.props.checkboxes.map((checkbox, index) => {
              if (!Array.isArray(checkbox.options)) {
                var { options } = checkbox;
                options.groups[0].options.map(
                  (option) =>
                    (option.checked =
                      checkbox.selected.indexOf(option.id) !== -1)
                );
                options.groups[1].options.map(
                  (option) =>
                    (option.checked =
                      checkbox.selected.indexOf(option.id) !== -1)
                );
              }
              if (checkbox.single) {
                return (
                  <SingleCheckboxMenu
                    modalButton={checkbox.modalButton}
                    key={index}
                    clearFunction={this.props.clearFunction}
                    objectTitle={checkbox.objectTitle}
                    maxWidth="100%"
                    containerMinWidth="160px"
                    name={checkbox.name}
                    count={checkbox.selected.length}
                    active={checkbox.selected.length >= 1}
                    options={
                      Array.isArray(checkbox.options)
                        ? checkbox.options.map((option) => ({
                            label: option.name,
                            value: option.id,
                            count: option.count,
                            handler: checkbox.handler,
                            checked:
                              checkbox.selected.indexOf(option.id) !== -1,
                            classList: "user-option",
                          }))
                        : options
                    }
                    title={checkbox.title}
                    id={index}
                    icons={[]}
                  />
                );
              }
              return (
                <CheckboxMenu
                  currentUser={this.props.currentUser}
                  searchEnabled={checkbox.searchEnabled}
                  modalButton={checkbox.modalButton}
                  key={index}
                  clearFunction={this.props.clearFunction}
                  maxWidth="100%"
                  width={checkbox.width}
                  right={checkbox.right}
                  last={index + 1 === this.props.checkboxes.length}
                  containerMinWidth="160px"
                  filter={checkbox.filter && checkbox.filter === true}
                  optionFilter={
                    checkbox.optionFilter ? checkbox.optionFilter : false
                  }
                  filterModalAction={checkbox.filterModalAction}
                  name={checkbox.name}
                  count={checkbox.selected.length}
                  active={checkbox.selected.length >= 1}
                  options={
                    Array.isArray(checkbox.options)
                      ? checkbox.options.map((option) => ({
                          label: option.name,
                          value: option.id,
                          user_id: option.user_id ? option.user_id : null,
                          handler: checkbox.handler,
                          checked: checkbox.selected.indexOf(option.id) !== -1,
                          classList: "user-option",
                        }))
                      : options
                  }
                  title={checkbox.title}
                  id={index}
                  icons={[]}
                />
              );
            })}
          </div>
        </div>
        {this.props.borderBottom && (
          <div
            style={{
              width: "calc(100% + 35px)",
              position: "relative",
              height: "1px",
              backgroundColor: "#d1d1d1",
              left: "-25px",
              margin: "25px 0 0",
            }}
          />
        )}
      </div>
    );
  }
}
PageNav.defaultProps = {
  showSearch: true,
};

export default PageNav;
