import React from "react";

const TaskboardCircle = () => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="42"
        height="42"
        rx="21"
        fill="url(#paint0_linear_5079_5098)"
      />
      <path
        d="M10.0897 11.5294H14.8515C15.4208 11.5294 15.8823 12.0908 15.8823 12.5459V13.597C15.8823 14.1584 15.4208 14.6135 14.8515 14.6135H10.0897C9.5204 14.6135 9.05884 14.0522 9.05884 13.597V12.5459C9.05884 11.9846 9.5204 11.5294 10.0897 11.5294Z"
        fill="white"
      />
      <path
        d="M18.6192 11.5294H23.381C23.9503 11.5294 24.4119 12.0908 24.4119 12.5459V13.597C24.4119 14.1584 23.9503 14.6135 23.381 14.6135H18.6192C18.0499 14.6135 17.5884 14.0522 17.5884 13.597V12.5459C17.5884 11.9846 18.0499 11.5294 18.6192 11.5294Z"
        fill="white"
      />
      <path
        d="M27.1485 11.5294H31.9103C32.4796 11.5294 32.9412 12.0908 32.9412 12.5459V13.597C32.9412 14.1584 32.4796 14.6135 31.9103 14.6135H27.1485C26.5792 14.6135 26.1177 14.0522 26.1177 13.597V12.5459C26.1177 11.9846 26.5792 11.5294 27.1485 11.5294Z"
        fill="white"
      />
      <path
        d="M10.0897 15.5944H14.8515C15.4208 15.5944 15.8823 16.1557 15.8823 16.6109V19.0634C15.8823 19.6248 15.4208 20.0799 14.8515 20.0799H10.0897C9.5204 20.0799 9.05884 19.5185 9.05884 19.0634V16.6109C9.05884 16.0495 9.5204 15.5944 10.0897 15.5944Z"
        fill="white"
      />
      <path
        d="M18.6192 15.5944H23.381C23.9503 15.5944 24.4119 16.1557 24.4119 16.6109V19.0634C24.4119 19.6248 23.9503 20.0799 23.381 20.0799H18.6192C18.0499 20.0799 17.5884 19.5185 17.5884 19.0634V16.6109C17.5884 16.0495 18.0499 15.5944 18.6192 15.5944Z"
        fill="white"
      />
      <path
        d="M27.1485 15.5944H31.9103C32.4796 15.5944 32.9412 16.1557 32.9412 16.6109V19.0634C32.9412 19.6248 32.4796 20.0799 31.9103 20.0799H27.1485C26.5792 20.0799 26.1177 19.5185 26.1177 19.0634V16.6109C26.1177 16.0495 26.5792 15.5944 27.1485 15.5944Z"
        fill="white"
      />
      <path
        d="M10.0897 21.2018H14.8515C15.4208 21.2018 15.8823 21.7631 15.8823 22.2183V24.6708C15.8823 25.2322 15.4208 25.6873 14.8515 25.6873H10.0897C9.5204 25.6873 9.05884 25.126 9.05884 24.6708V22.2183C9.05884 21.6569 9.5204 21.2018 10.0897 21.2018Z"
        fill="white"
      />
      <path
        d="M18.6192 21.2018H23.381C23.9503 21.2018 24.4119 21.7631 24.4119 22.2183V24.6708C24.4119 25.2322 23.9503 25.6873 23.381 25.6873H18.6192C18.0499 25.6873 17.5884 25.126 17.5884 24.6708V22.2183C17.5884 21.6569 18.0499 21.2018 18.6192 21.2018Z"
        fill="white"
      />
      <path
        d="M27.1485 21.2018H31.9103C32.4796 21.2018 32.9412 21.7631 32.9412 22.2183V24.6708C32.9412 25.2322 32.4796 25.6873 31.9103 25.6873H27.1485C26.5792 25.6873 26.1177 25.126 26.1177 24.6708V22.2183C26.1177 21.6569 26.5792 21.2018 27.1485 21.2018Z"
        fill="white"
      />
      <path
        d="M10.0897 26.8086H14.8515C15.4208 26.8086 15.8823 27.37 15.8823 27.8251V30.2776C15.8823 30.839 15.4208 31.2941 14.8515 31.2941H10.0897C9.5204 31.2941 9.05884 30.7328 9.05884 30.2776V27.8251C9.05884 27.2637 9.5204 26.8086 10.0897 26.8086Z"
        fill="white"
      />
      <path
        d="M18.6192 26.8086H23.381C23.9503 26.8086 24.4119 27.37 24.4119 27.8251V30.2776C24.4119 30.839 23.9503 31.2941 23.381 31.2941H18.6192C18.0499 31.2941 17.5884 30.7328 17.5884 30.2776V27.8251C17.5884 27.2637 18.0499 26.8086 18.6192 26.8086Z"
        fill="white"
      />
      <path
        d="M27.1485 26.8086H31.9103C32.4796 26.8086 32.9412 27.37 32.9412 27.8251V30.2776C32.9412 30.839 32.4796 31.2941 31.9103 31.2941H27.1485C26.5792 31.2941 26.1177 30.7328 26.1177 30.2776V27.8251C26.1177 27.2637 26.5792 26.8086 27.1485 26.8086Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5079_5098"
          x1="21"
          y1="0"
          x2="21"
          y2="42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TaskboardCircle;
