import React from "react";
import SvgShell from "./svg_shell";

function RecurringIcon({ height, width, svgStyles, color }) {
  return (
    <SvgShell height={height} width={width} svgStyles={svgStyles} color={color}>
      <rect x="15.81" y="17.69" width="3.83" height="3.84" rx="1.12" />
      <rect x="7.24" y="17.69" width="3.83" height="3.84" rx="1.12" />
      <rect x="24.92" y="17.69" width="3.83" height="3.84" rx="1.12" />
      <rect x="34.06" y="17.69" width="3.84" height="3.84" rx="1.12" />
      <rect x="43.19" y="17.69" width="3.84" height="3.84" rx="1.12" />
      <rect x="15.81" y="25.27" width="3.83" height="3.83" rx="1.12" />
      <rect x="7.24" y="25.27" width="3.84" height="3.83" rx="1.12" />
      <rect x="24.92" y="25.27" width="3.83" height="3.83" rx="1.12" />
      <rect x="15.81" y="32.84" width="3.83" height="3.83" rx="1.12" />
      <rect x="7.24" y="32.84" width="3.84" height="3.83" rx="1.12" />
      <path d="M46.82,36.28l4.62,1.91A1,1,0,0,0,52.51,38l3.77-3.3a1,1,0,0,0,.09-1.44,1,1,0,0,0-.7-.35,1,1,0,0,0-.75.25l-2.45,2.15,0-1A11,11,0,0,0,32.51,28.8a1.18,1.18,0,0,0-.08,1.29,1.06,1.06,0,0,0,.33.37,1.2,1.2,0,0,0,1.67-.25,8.66,8.66,0,0,1,15.62,4.36l.11.8-.73-.23-1.82-.75-.1,0a1,1,0,0,0-.69,0,1,1,0,0,0-.55.55,1.05,1.05,0,0,0,0,.9A1.07,1.07,0,0,0,46.82,36.28Z" />
      <path d="M27.92,37.92l2.39-2.1.11.87a11,11,0,0,0,1.36,4.22,10.9,10.9,0,0,0,6.64,5.18A11,11,0,0,0,50.29,42a1.17,1.17,0,0,0,.22-.88,1.19,1.19,0,0,0-.47-.79,1.18,1.18,0,0,0-1.66.25,8.66,8.66,0,0,1-15.6-4.14l-.09-.78,2.54,1.05a1,1,0,0,0,1.34-.56,1,1,0,0,0,0-.78,1,1,0,0,0-.55-.55L31.4,32.9a1,1,0,0,0-1.06.17l-3.77,3.3a1,1,0,0,0-.22,1.28.66.66,0,0,0,.12.17A1,1,0,0,0,27.92,37.92Z" />
      <path d="M26,40.38H4.86a2,2,0,0,1-2-2V13.8H49.17V24.66h2.24V4.93a4.21,4.21,0,0,0-4.2-4.2H4.86A4.2,4.2,0,0,0,.67,4.93V38.42a4.2,4.2,0,0,0,4.19,4.19H26ZM2.9,4.93a2,2,0,0,1,2-2H47.21a2,2,0,0,1,2,2v6.64H2.9Z" />
    </SvgShell>
  );
}

RecurringIcon.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "#519acc",
};

export default RecurringIcon;
