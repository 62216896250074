import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import TeamShow from "../users/team_show";
import CommentIcon from "../icons/comment_icon";

function TaskCard(props) {
  const [monthlyInvoice, setMonthlyInvoice] = useState(false);

  useEffect(() => {
    setMonthlyInvoice(props.project.monthly_invoice);
  });
  function getStyle(provided, style, columnKey) {
    // both styles need merged for when they are hidden and shown
    if (!style) {
      return provided.draggableProps.style;
    }

    if (props.isDragging) {
      style.backgroundColor = "#d2f6e4";
    }

    if (props.loading) {
      style.opacity = ".5";
    } else {
      style.opacity = "1";
    }

    return {
      ...provided.draggableProps.style,
      ...style,
    };
  }

  function displayTags() {
    return (
      <div className="flex-container flex-al-center">
        <div
          className="tag-group taskboard"
          id={`project-tag-group-${props.project.id}`}
        >
          {props.project.tag && (
            <span
              className="tag sm"
              style={{
                backgroundColor: monthlyInvoice
                  ? "#ca327a"
                  : props.project.tag.color,
                height: "23px",
              }}
            >
              {monthlyInvoice ? "Monthly" : props.project.tag.name}
            </span>
          )}
        </div>
      </div>
    );
  }

  function displayLeads(max) {
    let { leads } = props.project;
    const userObject = {};
    if (props.columnKey !== "invoicing" && props.columnKey !== "readying") {
      if (
        props.project.estimate_owner_name &&
        props.project.estimate_owner_id
      ) {
        leads = leads.filter((l) => l.id !== props.project.estimate_owner_id);
        userObject[props.project.estimate_owner_name] = {
          role_name: "an Estimate Owner",
          name: props.project.estimate_owner_name,
          id: props.project.estimate_owner_id,
          avatar_url: props.project.estimate_owner_avatar_url,
        };
      }
      if (props.project.lead_owner_name && props.project.lead_owner_id) {
        if (userObject[props.project.lead_owner_name]) {
          userObject[props.project.lead_owner_name].role_name = `${
            userObject[props.project.lead_owner_name].role_name
          } and Lead Manager`;
        } else {
          userObject[props.project.lead_owner_name] = {
            role_name: "a Lead Manager",
            name: props.project.lead_owner_name,
            id: props.project.lead_owner_id,
            avatar_url: props.project.lead_owner_avatar_url,
          };
        }
      }
    }

    leads.forEach((l) => {
      if (userObject[l.name]) {
        userObject[l.name].role_name = `${userObject[l.name].role_name.replace(
          " and ",
          ","
        )} and Lead`;
      } else {
        userObject[l.name] = {
          role_name: "a Lead",
          name: l.name,
          id: l.id,
          avatar_url: l.avatar_url,
        };
      }
    });

    return (
      <TeamShow
        users={Object.values(userObject)}
        hide={false}
        maxCards={max}
        currentUser={props.currentUser}
      />
    );
  }

  function copyProject() {
    props.modalHandler(false, "", {});
    setTimeout(() => {
      props.modalHandler(true, "copy-project", {
        customClass: "taskTransitionModal",
        project: props.project,
        tagOptions: props.tagOptions,
        leadOptions: props.taskboardUserOptions,
        taskboard: true,
        projectsHandler: props.projectsHandler,
      });
    }, 300);
  }

  function projectUpdater(project) {
    props.modalHandler(true, "", {
      project,
      leadOptions: props.leadOptions,
      userOptions: props.userOptions,
      tagOptions: props.tagOptions,
      loadRegular: props.loadRegular,
      copyProject,
      column: props.columnKey,
      customClass: "taskModal",
      projectUpdater,
      currentUser: props.currentUser,
      stateCheckHandler: props.stateCheckHandler,
      projectHandler: props.projectHandler,
    });
  }

  async function getProject() {
    const response = await axios.get(
      `/projects/${props.project.id}/project_taskboard`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const project = response.data;
    return project;
  }

  function linkClick(e) {
    e.preventDefault();
    e.stopPropagation();
    const { target } = e;
    Swal.fire({
      title: `Exit Taskboard`,
      text: `Are you sure that you want to navigate away from the Taskboard?`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    })
      .then((result) => {
        if (result.value) {
          window.location = target.href;
        }
      })
      .catch((err) => console.error(err));
  }

  async function cardClick(e) {
    var e = e;
    const { target } = e;
    const project = await getProject();
    let focusTarget = "";
    if (e && target && target.dataset.focusTarget) {
      focusTarget = target.dataset.focusTarget;
    }
    props.modalHandler(true, "project-stage", {
      project,
      leadOptions: props.leadOptions,
      userOptions: props.userOptions,
      tagOptions: props.tagOptions,
      column: props.columnKey,
      copyProject,
      focusTarget,
      customClass: "taskModal",
      projectUpdater,
      stateCheckHandler: props.stateCheckHandler,
      currentUser: props.currentUser,
      projectHandler: props.projectHandler,
    });
  }

  function projectLink() {
    if (props.currentUser.role_id >= 4) {
      return (
        <a
          href={`/projects/${props.project.id}`}
          className="taskcard-project"
          onClick={linkClick}
        >
          {props.project.name}
        </a>
      );
    }
    if (props.project.access_ids.includes(props.currentUser.id)) {
      return (
        <a
          href={`/projects/${props.project.id}`}
          className="taskcard-project"
          onClick={linkClick}
        >
          {props.project.name}
        </a>
      );
    }
    return <p className="taskcard-project">{props.project.name}</p>;
  }

  function getDynamicTasks() {
    const today = moment();
    switch (props.columnKey) {
      case "leading":
        return (
          <>
            <p className="taskcard-client">{props.project.client_name}</p>
            {projectLink()}
            <div
              style={{ width: "100%", paddingTop: "16px" }}
              className="flex-container flex-row flex-al-center"
            >
              {displayTags()}
              <button
                data-focus-target="comments"
                type="button"
                id="comment-counter-box"
              >
                <CommentIcon
                  svgStyles={{ transform: "translateY(2px)", minWidth: "16px" }}
                  width="16"
                  height="13"
                />
                <p>{props.project.comment_count}</p>
              </button>
              {props.project.follow_up_date && (
                <p
                  style={{
                    fontSize:
                      props.project.tag &&
                      (props.project.tag.name === "E-Commerce" ||
                        props.project.tag.name === "Mobile App")
                        ? "10px"
                        : "11px",
                  }}
                  className={
                    props.project.follow_up_date &&
                    moment(props.project.follow_up_date).isBefore(today)
                      ? "task-date overdue"
                      : "task-date"
                  }
                >
                  Follow-up:{" "}
                  <span style={{ fontWeight: 500 }}>
                    {" "}
                    {moment(props.project.follow_up_date).format("MMM D")}
                  </span>
                </p>
              )}
              {displayLeads(
                props.project.follow_up_date
                  ? props.project.tag && props.project.tag.name === "E-Commerce"
                    ? 1
                    : 2
                  : 5
              )}
            </div>
          </>
        );
      case "est_requesting":
        return (
          <>
            <p className="taskcard-client">{props.project.client_name}</p>
            {projectLink()}
            <div
              style={{ width: "100%", paddingTop: "15px" }}
              className="flex-container flex-row flex-wrap flex-al-center"
            >
              {displayTags()}
              <button
                type="button"
                id="comment-counter-box"
                data-focus-target="comments"
              >
                <CommentIcon
                  svgStyles={{ transform: "translateY(2px)", minWidth: "16px" }}
                  width="16"
                  height="13"
                />
                <p>{props.project.comment_count}</p>
              </button>
              <p
                className={
                  props.project.est_due_date &&
                  moment(props.project.est_due_date).isBefore(today)
                    ? "task-date overdue"
                    : "task-date"
                }
              >
                Due:{" "}
                <span style={{ fontWeight: 500 }}>
                  {" "}
                  {props.project.est_due_date
                    ? moment(props.project.est_due_date).format("MMM D")
                    : "Unknown"}
                </span>
              </p>
              {displayLeads(3)}
            </div>
          </>
        );
      case "project_confirming":
        return (
          <>
            <p className="taskcard-client">{props.project.client_name}</p>
            {projectLink()}
            <div
              style={{ width: "100%", paddingTop: "15px" }}
              className="flex-container flex-row flex-wrap flex-al-center"
            >
              {displayTags()}
              <button
                type="button"
                id="comment-counter-box"
                data-focus-target="comments"
              >
                <CommentIcon
                  svgStyles={{ transform: "translateY(2px)", minWidth: "16px" }}
                  width="16"
                  height="13"
                />
                <p>{props.project.comment_count}</p>
              </button>
              <p className="task-date">
                Start:{" "}
                <span style={{ fontWeight: 500 }}>
                  {props.project.test_start_date === "Unknown"
                    ? "Unknown"
                    : moment(props.project.test_start_date).format("MMM D")}
                </span>
              </p>
              {displayLeads(3)}
            </div>
          </>
        );
      case "est_sending":
        return (
          <>
            <p className="taskcard-client">{props.project.client_name}</p>
            {projectLink()}
            {!props.project.client_parent && (
              <span
                style={{
                  position: "relative",
                  top: "8px",
                }}
                className="bold"
              >
                Budget Hours:
                <label
                  style={{ color: "#313131 !important", marginLeft: "5px" }}
                >
                  <span style={{ fontWeight: 500 }}>
                    {props.project.budgeted_hours
                      ? parseFloat(props.project.budgeted_hours).toFixed(2)
                      : ""}
                  </span>
                </label>
              </span>
            )}
            <div
              style={{ width: "100%", paddingTop: "15px" }}
              className="flex-container flex-row flex-wrap flex-al-center"
            >
              {displayTags()}
              <button
                type="button"
                id="comment-counter-box"
                data-focus-target="comments"
              >
                <CommentIcon
                  svgStyles={{ transform: "translateY(2px)", minWidth: "16px" }}
                  width="16"
                  height="13"
                />
                <p>{props.project.comment_count}</p>
              </button>
              <p className="task-date">
                Sent:{" "}
                <span style={{ fontWeight: 500 }}>
                  {" "}
                  {props.project.est_send_date
                    ? moment(props.project.est_send_date).format("MMM D")
                    : "Unknown"}
                </span>
              </p>
              {displayLeads(3)}
            </div>
          </>
        );
      case "testing":
        return (
          <>
            <p className="taskcard-client">{props.project.client_name}</p>
            {projectLink()}
            <div
              style={{ width: "100%", paddingTop: "15px" }}
              className="flex-container flex-row flex-wrap flex-al-center"
            >
              {displayTags()}
              <button
                type="button"
                id="comment-counter-box"
                data-focus-target="comments"
              >
                <CommentIcon
                  svgStyles={{ transform: "translateY(2px)", minWidth: "16px" }}
                  width="16"
                  height="13"
                />
                <p>{props.project.comment_count}</p>
              </button>
              <p className="task-date">
                Start:{" "}
                <span style={{ fontWeight: 500 }}>
                  {moment(props.project.test_start_date).format("MMM D")}
                </span>
              </p>
              {displayLeads(3)}
            </div>
          </>
        );
      case "completing":
        return (
          <>
            <p className="taskcard-client">{props.project.client_name}</p>
            {projectLink()}
            <div
              style={{ width: "100%", paddingTop: "15px" }}
              className="flex-container flex-row flex-wrap flex-al-center"
            >
              {displayTags()}
              <button
                data-focus-target="comments"
                type="button"
                id="comment-counter-box"
              >
                <CommentIcon
                  svgStyles={{ transform: "translateY(2px)", minWidth: "16px" }}
                  width="16"
                  height="13"
                />
                <p>{props.project.comment_count}</p>
              </button>
              <p className="task-date">
                <span style={{ fontWeight: 500 }}>
                  {props.project.test_start_date === "Unknown"
                    ? "Unknown"
                    : moment(props.project.test_start_date).format(
                        "MMM D"
                      )}{" "}
                  -{" "}
                  {props.project.completion_date
                    ? moment(props.project.completion_date).format("MMM D")
                    : "Unknown"}
                </span>
              </p>
              {displayLeads(3)}
            </div>
          </>
        );
      case "readying":
        return (
          <>
            <p className="taskcard-client">{props.project.client_name}</p>
            {projectLink()}
            <div
              style={{ width: "100%", paddingTop: "15px" }}
              className="flex-container flex-row flex-wrap flex-al-center"
            >
              {displayTags()}
              <button
                type="button"
                id="comment-counter-box"
                data-focus-target="comments"
              >
                <CommentIcon
                  svgStyles={{ transform: "translateY(2px)", minWidth: "16px" }}
                  width="16"
                  height="13"
                />
                <p>{props.project.comment_count}</p>
              </button>
              {displayLeads(9)}
            </div>
          </>
        );
      case "archiving":
        return (
          <>
            <p className="taskcard-client">{props.project.client_name}</p>
            {projectLink()}
            <div
              style={{ width: "100%", paddingTop: "15px" }}
              className="flex-container flex-row flex-wrap flex-al-center"
            >
              <button
                type="button"
                id="comment-counter-box"
                data-focus-target="comments"
              >
                <CommentIcon
                  svgStyles={{ transform: "translateY(2px)", minWidth: "16px" }}
                  width="16"
                  height="13"
                />
                <p>{props.project.comment_count}</p>
              </button>
            </div>
          </>
        );
      case "invoicing":
        return (
          <>
            <p className="taskcard-client">{props.project.client_name}</p>
            {projectLink()}
            <div
              style={{ width: "100%", paddingTop: "15px" }}
              className="flex-container flex-row flex-wrap flex-al-center"
            >
              {displayTags()}
              <button
                type="button"
                id="comment-counter-box"
                data-focus-target="comments"
              >
                <CommentIcon
                  svgStyles={{ transform: "translateY(2px)", minWidth: "16px" }}
                  width="16"
                  height="13"
                />
                <p>{props.project.comment_count}</p>
              </button>
              <p className="task-date">
                Invoiced:
                <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                  {props.project.last_invoiced
                    ? props.project.last_invoiced.submitted_date &&
                      moment(
                        props.project.last_invoiced.submitted_date
                      ).isValid()
                      ? moment(
                          props.project.last_invoiced.submitted_date
                        ).format("MMM D")
                      : moment(props.project.last_invoiced.updated_at).format(
                          "MMM D"
                        )
                    : "Uninvoiced"}
                </span>
              </p>
            </div>
          </>
        );
      default:
        return (
          <>
            <p className="taskcard-client">{props.project.client_name}</p>
            <a
              href={`/projects/${props.project.id}`}
              className="taskcard-project"
              onClick={linkClick}
            >
              {props.project.name}
            </a>
            <p className="task-date">
              Last Year:{" "}
              <span style={{ fontWeight: 500 }}>
                {props.project.last_year_hours}
              </span>{" "}
              | Budget: {props.project.budgeted_hours}
            </p>
            <p className="task-date">Start / End Date</p>
            {displayTags()}
          </>
        );
    }
  }

  const tasks = getDynamicTasks();

  return (
    <div
      onClick={cardClick}
      className="taskboard-card"
      {...props.provided.draggableProps}
      {...props.provided.dragHandleProps}
      style={getStyle(props.provided, props.style, props.columnKey)}
      key={props.project.id}
      id={`project-${props.project.id}-card`}
      data-is-dragging={props.isDragging}
      ref={props.refProp}
    >
      {props.loading && (
        <div
          style={{
            width: "50px",
            height: "50px",
            position: "absolute",
            left: "40%",
          }}
        >
          <div className="page-load-spinner" />
        </div>
      )}
      {tasks}
    </div>
  );
}

export default TaskCard;
