import React from "react";
import MeetingIcon from "../icons/meeting_icon.jsx";
import SingleSelectWrapper from "../common/single_select_wrapper";
import axios from "axios";
import NumberCounter from "../common/number_counter";
import Swal from "sweetalert2";
import moment from "moment";
import ExitIcon from "components/icons/exit_icon.jsx";

class Meeting extends React.Component {
  constructor(props) {
    super(props);
    const start = moment();
    const remainder = start.minute() % 15;
    const currentTime = moment(start)
      .subtract(remainder, "minutes")
      .format("HH:mm");
    var currentTimeIndex = 0;
    var timeOptions = [];
    var incrementingTime = moment("1987-02-22 08:00:00");
    for (var x = 0; x < 41; x++) {
      timeOptions.push({
        label: moment(incrementingTime).format("h:mm A"),
        value: moment(incrementingTime).format("HH:mm:ss"),
        timeIndex: x,
      });
      incrementingTime.add(15, "minutes");
      if (moment(incrementingTime).format("HH:mm") === currentTime) {
        currentTimeIndex = x + 1;
      }
    }
    var users = this.props.data.users.map((u) => ({
      label: u.name,
      value: u.id,
    }));
    if (
      users.map((u) => u.value).indexOf(this.props.data.currentUser.id) === -1
    ) {
      users = [
        {
          label: this.props.data.currentUser.name,
          value: this.props.data.currentUser.id,
        },
        ...users,
      ];
    } else if (
      this.props.data.booking &&
      users.map((u) => u.value).indexOf(this.props.data.booking.user.id) === -1
    ) {
      users = [
        {
          label: this.props.data.booking.user.name,
          value: this.props.data.booking.user.id,
        },
        ...users,
      ];
    }
    this.state = {
      owner:
        this.props.data.action === "create"
          ? {
              label: this.props.data.currentUser.name,
              value: this.props.data.currentUser.id,
            }
          : {
              label: this.props.data.booking.user.name,
              value: this.props.data.booking.user.id,
            },
      type: { label: "Internal", value: "internal" },
      timeOptions,
      startTime:
        this.props.data.action === "create"
          ? this.props.data.selectedTimeIndex
            ? timeOptions[this.props.data.selectedTimeIndex]
            : timeOptions[currentTimeIndex]
          : timeOptions[this.props.data.booking.booking_array.indexOf("1")],
      endTime:
        this.props.data.action === "create"
          ? this.props.data.selectedTimeIndex
            ? timeOptions[this.props.data.selectedTimeIndex + 1]
            : timeOptions[currentTimeIndex + 1]
          : timeOptions[
              this.props.data.booking.booking_array.lastIndexOf("1") + 1
            ],
      attendees:
        this.props.data.action === "create"
          ? 2
          : this.props.data.booking.attendees,
      weeks:
        this.props.data.action === "edit" &&
        this.props.data.booking.multi_booking_id
          ? 1
          : 1,
      description:
        this.props.data.action === "create"
          ? ""
          : this.props.data.booking.description,
      name:
        this.props.data.action === "create" ? "" : this.props.data.booking.name,
      recurring:
        this.props.data.action === "edit" &&
        this.props.data.booking.multi_booking_id
          ? true
          : false,
      client:
        this.props.data.action === "edit" &&
        this.props.data.booking.meeting_type === "client"
          ? true
          : false,
      currentTimeIndex,
      users,
      editAll: false,
      overlapBlock: false,
    };
    this.roomUrls = {
      "Crater Lake":
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/syncit/crater.jpg",
      "Green room":
        "https://syncit.sfo2.cdn.digitaloceanspaces.com/Rooms/Green%20Room.jpg",
      "Grey room":
        "https://syncit.sfo2.cdn.digitaloceanspaces.com/Rooms/Grey%20Room.jpg",
      "Orange room":
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/syncit/room-view-card-orange.jpg",
      "Red room":
        "https://syncit.sfo2.cdn.digitaloceanspaces.com/Rooms/Red%20Room.jpg",
      "Yellow room":
        "https://syncit.sfo2.cdn.digitaloceanspaces.com/Rooms/Yellow%20Room.jpg",
      "Blue room":
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/syncit/room-view-card-blue.jpg",
      // "Chinook Room": "chinook.jpg",
      // "Shasta Room": "shasta.jpg",
      // "Wasco Room": "wasco.jpg",
      // "Lego Room": "lego.jpg",
      "Sci-Fi Room":
        "https://syncit.sfo2.cdn.digitaloceanspaces.com/Rooms/Sci-Fi%20Room.jpg",
    };
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    if (this.props.data.booking && this.props.data.booking.multi_booking_id) {
      axios
        .get(
          `/recurring_booking_weeks/${this.props.data.booking.multi_booking_id}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.setState({
            weeks: res.data.length - 1,
            originalWeeks: res.data.length - 1,
            existingMultiBookings: res.data,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  handleOwnerChange = (owner) => {
    this.setState({ owner });
  };

  handleStartChange = (startTime) => {
    this.setState({ startTime }, () => {
      var startTime = moment(`1987-02-22 ${this.state.startTime.value}`);
      var endTime = moment(`1987-02-22 ${this.state.endTime.value}`);
      if (
        startTime.isSameOrAfter(endTime) ||
        (this.props.data.bookings[0]
          .slice(this.state.startTime.timeIndex, this.state.endTime.timeIndex)
          .indexOf("1") !== -1 &&
          (this.props.data.action === "create" ||
            !this.props.data.bookings[1]
              .slice(
                this.state.startTime.timeIndex,
                this.state.endTime.timeIndex
              )
              .every((b) => b === "0" || b === this.props.data.booking.id)))
      ) {
        this.setState(
          {
            endTime: this.state.timeOptions[this.state.startTime.timeIndex + 1],
          },
          () => {
            if (this.state.recurring) {
              this.checkRecurringOverlap();
            }
          }
        );
      } else if (this.state.recurring) {
        this.checkRecurringOverlap();
      }
    });
  };

  handleEndChange = (endTime) => {
    this.setState({ endTime }, () => {
      var startTime = moment(`1987-02-22 ${this.state.startTime.value}`);
      var endTime = moment(`1987-02-22 ${this.state.endTime.value}`);
      if (
        endTime.isSameOrBefore(startTime) ||
        (this.props.data.bookings[0]
          .slice(this.state.startTime.timeIndex, this.state.endTime.timeIndex)
          .indexOf("1") !== -1 &&
          (this.props.data.action === "create" ||
            !this.props.data.bookings[1]
              .slice(
                this.state.startTime.timeIndex,
                this.state.endTime.timeIndex
              )
              .every((b) => b === "0" || b === this.props.data.booking.id)))
      ) {
        this.setState(
          {
            startTime: this.state.timeOptions[this.state.endTime.timeIndex - 1],
          },
          () => {
            if (this.state.recurring) {
              this.checkRecurringOverlap();
            }
          }
        );
      } else if (this.state.recurring) {
        this.checkRecurringOverlap();
      }
    });
  };

  handleAttendeesChange = (attendees) => {
    this.setState({ attendees });
  };

  handleDescriptionChange = (e) => {
    this.setState({ description: e.target.value ? e.target.value : "" });
  };

  handleNameChange = (e) => {
    this.setState({ name: e.target.value ? e.target.value : "" });
  };

  handleRecurringChange = () => {
    this.setState({ recurring: !this.state.recurring }, () => {
      if (this.state.recurring && !this.state.multiBookings) {
        axios
          .get("/recurring_booking", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            params: {
              start_date: moment(this.props.data.currentDay).format(
                "YYYY-MM-DD"
              ),
              recurring_num: 9,
              room_id: this.props.data.room,
            },
          })
          .then((res) => {
            var multiBookings = [];
            for (var x = 1; x < res.data.length; x++) {
              var array = new Array(40).fill("0");
              for (var y = 0; y < res.data[x].length; y++) {
                var booking_array = res.data[x][y].booking_array;
                for (var z = 0; z < booking_array.length; z++) {
                  if (booking_array[z] !== "0") {
                    array[z] = booking_array[z];
                  }
                }
              }
              multiBookings.push(array);
            }
            this.setState({ multiBookings }, () => {
              this.checkRecurringOverlap();
            });
          })
          .catch((err) => {
            console.error(err);
          });
      } else if (this.state.recurring) {
        this.checkRecurringOverlap();
      } else if (this.state.overlapBlock) {
        this.setState({ overlapBlock: false });
      }
    });
  };

  checkRecurringOverlap = () => {
    var bookingArrays = this.state.multiBookings.slice(0, this.state.weeks);
    for (var x = 0; x < bookingArrays.length; x++) {
      if (
        bookingArrays[x]
          .slice(this.state.startTime.timeIndex, this.state.endTime.timeIndex)
          .indexOf("1") !== -1
      ) {
        this.setState({ overlapBlock: x + 1 });
        return;
      }
    }
    if (this.state.overlapBlock) {
      this.setState({ overlapBlock: false });
    }
  };

  handleClientChange = () => {
    if (
      !this.state.client &&
      this.props.data.roomObject &&
      !this.props.data.roomObject.allow_client
    ) {
      Swal.fire({
        title: `Non-client room`,
        text: `This room isn't usually used for client meetings. Are you sure you want to ${
          this.props.data.action === "create"
            ? "book it for a"
            : "change it to a"
        } client meeting?`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.setState({ client: !this.state.client });
        }
      });
    } else {
      this.setState({ client: !this.state.client });
    }
  };

  handleWeeksChange = (weeks) => {
    this.setState({ weeks }, () => {
      this.checkRecurringOverlap();
    });
  };

  closeModal = () => {
    this.props.modalAction(false, "", {});
  };

  handleDisabledStartTime = (o) => {
    return (
      (this.state.currentTimeIndex > o.timeIndex &&
        moment(this.props.data.currentDay).format("YYYY/MM/DD") ===
          moment().format("YYYY/MM/DD")) ||
      (this.props.data.bookings[0][o.timeIndex] === "1" &&
        (this.props.data.action === "create" ||
          this.props.data.bookings[1][o.timeIndex] !==
            this.props.data.booking.id)) ||
      o.timeIndex === 40
    );
  };

  handleDisabledEndTime = (o) => {
    return (
      (this.state.currentTimeIndex + 1 > o.timeIndex &&
        moment(this.props.data.currentDay).format("YYYY/MM/DD") ===
          moment().format("YYYY/MM/DD")) ||
      (this.props.data.bookings[0][o.timeIndex - 1] === "1" &&
        (this.props.data.action === "create" ||
          this.props.data.bookings[1][o.timeIndex - 1] !==
            this.props.data.booking.id)) ||
      o.timeIndex === 0
    );
  };

  handleDeleteBooking = () => {
    Swal.fire({
      title: `Delete Booking${this.state.editAll ? "s" : ""}`,
      text: `Are you sure you want to delete ${
        this.state.editAll ? "all bookings in this group?" : "this booking?"
      }`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(
            this.state.editAll
              ? `/bookings/multi_destroy/${this.props.data.booking.multi_booking_id}.json`
              : this.props.data.booking.multi_booking_id
              ? `/bookings/multi_single_destroy/${this.props.data.booking.id}/${this.props.data.booking.multi_booking_id}`
              : `/bookings/${this.props.data.booking.id}.json`
          )
          .then((res) => {
            M.toast({
              html: `Booking${this.state.editAll ? "s" : ""} deleted`,
              displayLength: 3000,
              classes: "green",
            });
            this.props.data.deleteHandler(this.props.data.booking.id);
            this.props.modalAction(false, "", {});
          })
          .catch((err) => console.error(err));
      }
    });
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    if (!this.state.endTime && !this.state.startTime) {
      return false;
    }
    var duration = moment
      .duration(
        moment(`1987-02-22 ${this.state.endTime.value}`).diff(
          moment(`1987-02-22 ${this.state.startTime.value}`)
        )
      )
      .asMinutes();

    const dateInput =
      this.props.data.currentDay + " " + this.state.startTime.value;

    const booking_date = moment(dateInput, "MM-DD-YYYY HH:mm:ss").format(
      "YYYY/MM/DD HH:mm:ss"
    );

    if (this.props.data.action === "create") {
      axios
        .post(`/bookings.json`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          booking: {
            user_id: this.state.owner.value,
            duration,
            name: this.state.name,
            booking_date,
            meeting_type: this.state.client ? "client" : "team",
            description: this.state.description,
            room_id:
              typeof this.props.data.room === "object"
                ? this.props.data.room.room_id
                : this.props.data.room,
            attendees: this.state.attendees,
          },
          multi_booking: this.state.recurring
            ? { weeks: this.state.weeks }
            : false,
        })
        .then((res) => {
          M.toast({
            html: `Booking${this.state.recurring ? "s" : ""} created`,
            classes: "green",
          });
          this.props.data.bookingHandler(res.data);
          this.props.modalAction(false, "", {});
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.error(err);
          M.toast({
            html: "An error occured while creating the booking",
            classes: "red",
          });
        });
    } else {
      axios
        .patch(`/bookings/${this.props.data.booking.id}.json`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          booking: {
            user_id: this.state.owner.value,
            duration,
            name: this.state.name,
            booking_date,
            meeting_type: this.state.client ? "client" : "team",
            description: this.state.description,
            room_id:
              typeof this.props.data.room === "object"
                ? this.props.data.room.room_id
                : this.props.data.room,
            multi_booking_id: this.props.data.booking.multi_booking_id,
            attendees: this.state.attendees,
          },
          multi_booking: this.state.editAll
            ? { weeks: this.state.weeks }
            : false,
        })
        .then((res) => {
          M.toast({
            html: `Booking${this.state.editAll ? "s" : ""} edited`,
            classes: "green",
          });
          if (res.data.hasOwnProperty("name")) {
            this.props.data.editHandler(res.data);
          } else {
            this.props.data.deleteHandler(res.data);
          }
          this.props.modalAction(false, "", {});
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.error(err);
        });
    }
  };

  handleEditAllChange = () => {
    this.setState({ editAll: !this.state.editAll }, () => {
      if (this.state.editAll && !this.state.multiBookings) {
        axios
          .get("/recurring_booking", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            params: {
              start_date: moment(
                this.state.existingMultiBookings[0].booking_date
              ),
              recurring_num: 9,
              room_id: this.props.data.room,
              multi_booking_id: this.props.data.booking.multi_booking_id,
            },
          })
          .then((res) => {
            var multiBookings = [];
            for (var x = 1; x < res.data.length; x++) {
              var array = new Array(40).fill("0");
              for (var y = 0; y < res.data[x].length; y++) {
                var booking_array = res.data[x][y].booking_array;
                for (var z = 0; z < booking_array.length; z++) {
                  if (booking_array[z] !== "0") {
                    array[z] = booking_array[z];
                  }
                }
              }
              multiBookings.push(array);
            }
            this.setState({ multiBookings }, () => {
              this.checkRecurringOverlap();
            });
          })
          .catch((err) => {
            console.error(err);
          });
      } else if (this.state.editAll) {
        this.checkRecurringOverlap();
      } else if (this.state.overlapBlock) {
        this.setState({ overlapBlock: false });
      }
    });
  };

  render() {
    return (
      <div
        style={{ maxHeight: "97vh", overflow: "hidden" }}
        className="modal-content "
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "32px",
          }}
        >
          <MeetingIcon />

          <div
            style={{
              display: "flex",
              marginLeft: "16px",
              flexDirection: "column",
            }}
          >
            <h2
              style={{
                margin: "0px 0px 4px",
                fontSize: "18px",
                fontFamily: "Manrope",
                fontWeight: "500",
                color: "#313131",
              }}
            >
              {this.props.data.action === "view" ? (
                <span className="modal-title">
                  View conference room booking
                </span>
              ) : (
                <>
                  <span className="modal-title">
                    {this.props.data.booking
                      ? "Update a room booking"
                      : "Book a conference room"}
                  </span>
                </>
              )}
            </h2>
            <span className="modal-subheader">
              {" "}
              {moment(this.props.data.currentDay, "MM-DD-YYYY").format(
                "MMMM Do, YYYY"
              )}
            </span>
          </div>
          <button
            id="modalClose"
            aria-label="Close Modal"
            className="btn-icon"
            style={{
              position: "absolute",
              top: "39px",
              right: "31px",
              padding: "0",
            }}
            onClick={this.closeModal}
          >
            <ExitIcon
              svgStyles={{ pointerEvents: "none" }}
              height="25px"
              width={"25px"}
            />
          </button>
        </div>

        {this.props.data.action === "view" ? (
          <div style={{ padding: "0px 25px 14px" }}>
            <div
              style={{
                width: "100%",
                height: "420px",
                position: "relative",
                marginBottom: "12px",
                boxShadow: "rgb(216 216 216) 0px 0px 4px 3px",
              }}
            >
              <div
                style={{
                  height: "174px",
                  position: "relative",
                  width: "100%",
                  backgroundSize: "100% auto",
                  backgroundPositionY: "center",
                  backgroundImage: `url(${
                    this.roomUrls[this.props.data.booking.room.name]
                  })`,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    right: "14px",
                    bottom: "12px",
                    backgroundColor: "#519acc",
                    color: "white",
                    padding: "4px 8px",
                    borderRadius: "20px",
                    fontSize: "11px",
                    fontFamily: "open sans",
                    fontWeight: "600",
                  }}
                >
                  {this.props.data.booking.room.name}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexWrap: "nowrap",
                  marginBottom: "10px",
                  padding: "0 20px",
                }}
              >
                <div style={{ marginRight: "30px" }}>
                  <p className="modal-field">Meeting Owner:</p>
                  <p style={{ marginTop: "4px" }}>
                    {this.props.data.booking.user.name}
                  </p>
                </div>
                <div style={{ marginRight: "30px" }}>
                  <p style={{ fontWeight: "600", marginBottom: "0px" }}>
                    Meeting Time:
                  </p>
                  <p style={{ marginTop: "4px" }}>{`${moment(
                    this.props.data.booking.booking_date
                  ).format("MM/DD/YYYY h:mm A -")} ${moment(
                    this.props.data.booking.booking_date
                  )
                    .add(this.props.data.booking.duration, "minutes")
                    .format("h:mm A")}`}</p>
                </div>
              </div>
              <div>
                <p className="modal-field">Meeting Type:</p>
                <p style={{ marginTop: "4px" }}>
                  {this.props.data.booking.client ? "Client" : "Team"}
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  flexWrap: "nowrap",
                  marginBottom: "10px",
                  padding: "0 20px",
                }}
              >
                <p className="modal-field">Description:</p>
                <p style={{ marginTop: "4px" }}>
                  {this.props.data.booking.description
                    ? this.props.data.booking.description
                    : "none"}
                </p>
              </div>
              {!this.props.data.disabled && (
                <button
                  style={{
                    position: "absolute",
                    right: "20px",
                    bottom: "20px",
                    width: "120px",
                  }}
                  onClick={this.props.data.handleViewEdit}
                  className="common-button-cancel"
                >
                  Edit Booking
                </button>
              )}
            </div>
          </div>
        ) : (
          <div
            style={{
              overflowX: "hidden",
              overflowY: "auto",
              maxHeight: "calc(90vh - 90px)",
            }}
          >
            <div style={{ paddingRight: "5px" }}>
              <div
                style={{
                  width: "100%",
                  height: "174px",
                  position: "relative",
                  marginBottom: "16px",
                  backgroundSize: "100% auto",
                  backgroundPositionY: "center",
                  backgroundImage: `url(${
                    this.roomUrls[this.props.data.roomName]
                  })`,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    right: "16px",
                    bottom: "16px",
                    backgroundColor: "#519acc",
                    color: "white",
                    padding: "4px 8px",
                    borderRadius: "20px",
                    fontSize: "11px",
                    fontFamily: "open sans",
                    fontWeight: "600",
                  }}
                >
                  {this.props.data.roomName}
                </div>
              </div>
              <div
                style={{ width: "100%", display: "flex", flexWrap: "nowrap" }}
              >
                <div style={{ width: "50%" }}>
                  <span className="modal-field">Meeting Owner</span>
                  <SingleSelectWrapper
                    options={this.state.users}
                    onChange={this.handleOwnerChange}
                    isDisabled={this.props.data.disabled}
                    placeholder={"Select User"}
                    ariaLabel="Meeting Owner Dropdown"
                    value={this.state.owner}
                    customStyles={{
                      container: (styles) => ({
                        ...styles,
                        width: "100%",
                        marginTop: "4px",
                      }),
                      menu: (styles) => ({ ...styles, width: "100%" }),
                    }}
                  />
                </div>
              </div>
              <div style={{ width: "50%", marginTop: "16px" }}>
                <span className="modal-field">Meeting Type</span>
                <div
                  style={{
                    width: "100%",
                    flexWrap: "nowrap",
                    display: "flex",
                    marginTop: "8px",
                  }}
                >
                  <div style={{ marginRight: "18px" }}>
                    <label
                      className="custom-checkbox-menu"
                      style={{
                        boxShadow: "none",
                        backdropFilter: "none",
                        WebkitBackdropFilter: "none",
                      }}
                    >
                      <input
                        disabled={this.props.data.disabled}
                        checked={this.state.client}
                        onChange={this.handleClientChange}
                        className="filled-in"
                        type="checkbox"
                        style={{ accentColor: "#9b59b6" }}
                      />
                      <span
                        className="modal-field"
                        style={{
                          paddingLeft: "26px",
                          fontFamily: "Manrope",
                          fontWeight: "600",
                          fontSize: "13px",
                        }}
                      >
                        Client Meeting
                      </span>
                    </label>
                  </div>
                  <div>
                    {!this.props.data.booking && (
                      <label
                        className="custom-checkbox-menu"
                        style={{
                          // position: "relative",
                          // top: "10px",
                          boxShadow: "none",
                          opacity:
                            this.props.data.booking &&
                            this.props.data.booking.multi_booking_id
                              ? "0.6"
                              : "1",
                          backdropFilter: "none",
                          WebkitBackdropFilter: "none",
                        }}
                      >
                        <input
                          disabled={
                            this.props.data.disabled ||
                            (this.props.data.booking &&
                              this.props.data.booking.multi_booking_id)
                          }
                          checked={this.state.recurring}
                          onChange={this.handleRecurringChange}
                          className="filled-in"
                          type="checkbox"
                        />
                        <span
                          className="modal-field"
                          style={{
                            paddingLeft: "26px",
                            fontFamily: "Manrope",
                            fontWeight: "600",
                            fontSize: "13px",
                          }}
                        >
                          Recurring
                        </span>
                      </label>
                    )}
                  </div>
                </div>
              </div>
              {this.state.recurring && (
                <div style={{ marginTop: "8px" }}>
                  <label>
                    Number of Weeks<span style={{ color: "#519acc" }}>*</span>
                  </label>
                  <div
                    style={{
                      marginTop: "8px",
                      opacity:
                        this.props.data.booking &&
                        this.props.data.booking.multi_booking_id &&
                        !this.state.editAll
                          ? "0.5"
                          : "1",
                    }}
                  >
                    <NumberCounter
                      disabled={
                        this.props.data.disabled ||
                        (this.props.data.booking &&
                          this.props.data.booking.multi_booking_id &&
                          !this.state.editAll)
                      }
                      min={1}
                      max={this.props.data.roomCapacity}
                      value={this.state.weeks}
                      step={1}
                      updateValue={this.handleWeeksChange}
                    />
                  </div>
                </div>
              )}
              {this.state.recurring &&
                (!this.state.overlapBlock ? (
                  <p
                    style={{
                      // position: "absolute",
                      // bottom: "-38px",
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      right: this.state.existingMultiBookings ? "0" : "auto",
                      fontStyle: "italic",
                      color: "#6e6e6e",
                      marginTop: "8px",
                      marginBottom: "0px",
                    }}
                  >
                    {this.state.existingMultiBookings
                      ? `Recurring from ${moment(
                          this.state.existingMultiBookings[0].booking_date
                        ).format("MMMM Do, YYYY")} to ${moment(
                          this.state.existingMultiBookings[0].booking_date
                        )
                          .add(this.state.weeks, "w")
                          .format("MMMM Do, YYYY")}`
                      : `Recurring until ${moment(this.props.data.currentDay)
                          .add(this.state.weeks, "w")
                          .format("MMMM Do, YYYY")}`}
                  </p>
                ) : (
                  <p
                    style={{
                      fontSize: "14px",
                      fontStyle: "italic",
                      color: "red",
                      whiteSpace: "nowrap",
                      marginTop: "8px",
                      marginBottom: "0px",
                    }}
                  >
                    {" "}
                    Conflict with existing booking on{" "}
                    {moment(this.props.data.currentDay)
                      .add(this.state.overlapBlock, "w")
                      .format("MMMM Do, YYYY")}
                  </p>
                ))}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "nowrap",
                  marginTop: "8px",
                }}
              >
                <div style={{ display: "flex", flexWrap: "nowrap" }}>
                  <div style={{ paddingRight: "8px" }}>
                    <span className="modal-field">
                      Start Time<span style={{ color: "#519acc" }}>*</span>
                    </span>
                    <SingleSelectWrapper
                      classNamePrefix="react-select"
                      options={this.state.timeOptions}
                      onChange={this.handleStartChange}
                      ariaLabel="Meeting Start Time Dropdown"
                      value={this.state.startTime}
                      isDisabled={this.props.data.disabled}
                      isOptionDisabled={this.handleDisabledStartTime}
                      customStyles={{
                        container: (styles) => ({
                          ...styles,
                          width: "100%",
                          height: "39px",
                          marginTop: "8px",
                        }),
                        menu: (styles) => ({ ...styles, width: "100%" }),
                      }}
                    />
                  </div>
                  <div style={{ paddingRight: "16px" }}>
                    <span className="modal-field">
                      End Time<span style={{ color: "#519acc" }}>*</span>
                    </span>
                    <SingleSelectWrapper
                      classNamePrefix="react-select"
                      options={this.state.timeOptions}
                      onChange={this.handleEndChange}
                      ariaLabel="Meeting End Time Dropdown"
                      isDisabled={this.props.data.disabled}
                      value={this.state.endTime}
                      isOptionDisabled={this.handleDisabledEndTime}
                      customStyles={{
                        container: (styles) => ({
                          ...styles,
                          width: "100%",
                          height: "39px",
                          marginTop: "8px",
                        }),
                        menu: (styles) => ({ ...styles, width: "100%" }),
                      }}
                    />
                  </div>
                  <div>
                    <span className="modal-field">Attendees</span>
                    <div style={{ marginTop: "11px" }}>
                      <NumberCounter
                        disabled={this.props.data.disabled}
                        min={1}
                        max={
                          this.props.data.roomObject
                            ? this.props.data.roomObject.capacity
                            : this.props.data.roomCapacity
                        }
                        value={this.state.attendees}
                        step={1}
                        updateValue={this.handleAttendeesChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%", marginTop: "16px" }}>
                <span className="modal-field">Meeting Name</span>
                <input
                  type="text"
                  value={this.state.name}
                  id="meeting_name"
                  onChange={this.handleNameChange}
                  className="browser-default text-input-v2"
                  maxLength={75}
                  disabled={this.props.data.disabled}
                  placeholder="Optional"
                  autoComplete="off"
                  style={{ margin: "8px 0px 8px " }}
                  aria-describedby="character-count"
                />
                <span
                  aria-hidden={true}
                  id="character-count"
                  aria-live="polite"
                  className="input-counter"
                >{`${this.state.name ? this.state.name.length : 0}/75`}</span>
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: "16px",
                  marginBottom: "32px",
                  height: "160px",
                }}
              >
                <span className="modal-field">Description</span>
                <textarea
                  disabled={this.props.data.disabled}
                  placeholder="Optional"
                  style={{
                    minHeight: "110px",
                    padding: "5px 8px",
                    marginTop: "8px",
                    marginBottom: "0px",
                  }}
                  name="description"
                  id="description"
                  className="browser-default text-input-v2"
                  onChange={this.handleDescriptionChange}
                  value={this.state.description ? this.state.description : ""}
                  maxLength="240"
                />
                <span className="input-counter" style={{ margin: "0px" }}>{`${
                  this.state.description ? this.state.description.length : 0
                }/240`}</span>
              </div>
              {this.props.data.booking &&
                this.props.data.booking.multi_booking_id && (
                  <div style={{ marginTop: "-20px", marginBottom: "16px" }}>
                    <label
                      className="custom-checkbox-menu"
                      style={{
                        position: "relative",
                        boxShadow: "none",
                        backdropFilter: "none",
                        WebkitBackdropFilter: "none",
                      }}
                    >
                      <input
                        checked={this.state.editAll}
                        onChange={this.handleEditAllChange}
                        className="filled-in"
                        type="checkbox"
                      />
                      <span
                        style={{
                          paddingLeft: "26px",
                          fontFamily: "Manrope",
                          fontSize: "13px",
                          fontWeight: "600",
                        }}
                      >
                        Edit all bookings in group
                      </span>
                    </label>
                  </div>
                )}
              <div style={{ display: "flex", height: "43px" }}>
                <button
                  type="button"
                  name="cancel"
                  value="Cancel"
                  onClick={this.closeModal}
                  className="common-button-cancel"
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </button>
                {this.props.data.action === "edit" &&
                  (this.props.data.currentUser.role_id >= 4 ||
                    this.props.data.currentUser.role_id === 3 ||
                    this.props.data.currentUser.id ===
                      this.props.data.booking.user.id) && (
                    <button
                      disabled={this.state.loading || this.props.data.disabled}
                      style={{
                        backgroundColor: "#b82a23",
                        width: "129px",
                        margin: "0 10px 0 0",
                        whiteSpace: "nowrap",
                      }}
                      type="button"
                      name="delete"
                      value="Delete"
                      onClick={this.handleDeleteBooking}
                      className="common-button-delete"
                    >
                      Delete {`Booking${this.state.editAll ? "s" : ""}`}
                    </button>
                  )}
                <button
                  disabled={
                    this.state.loading ||
                    this.props.data.disabled ||
                    this.state.overlapBlock
                  }
                  type="button"
                  name="create"
                  value="Create"
                  onClick={this.handleSubmit}
                  className="common-button-submit"
                >
                  {this.props.data.booking ? "Update" : "Confirm"}{" "}
                  {`Booking${this.state.editAll ? "s" : ""}`}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Meeting;
