import React from "react";
import axios from "axios";

class AddClientProject extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    this.refs.submit_button.disabled = true;
    const name = this.refs.project_name.value;
    axios
      .post(`/api/v1/projects.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        project: { name, client_id: this.props.client_id },
      })
      .then((res) => {
        if (res.data.id === null) {
          M.toast({
            html: `Project already exists, choose a unique name.`,
            displayLength: 3000,
            classes: "red",
          });
          this.refs.submit_button.disabled = false;
        } else {
          M.toast({
            html: `${res.data.name} created!`,
            displayLength: 3000,
            classes: "green",
          });
          this.props.refreshProjects();
          this.props.modalAction(false, "", {});
        }
      })
      .catch((err) => console.error(err));
  }

  render() {
    return (
      <div className="modal-content">
        <h4>Add Project</h4>
        <form
          className="new_project"
          id="new_project"
          onSubmit={this.handleSubmit}
        >
          <div className="input-field">
            <input
              required="required"
              type="text"
              name="project[name]"
              id="project_name"
              ref="project_name"
            />
            <label htmlFor="project_name">Name</label>
          </div>

          <div className="button-row center">
            <input
              type="submit"
              name="commit"
              value="Create Project"
              className="common-button-submit white-text"
              ref="submit_button"
            />
          </div>
        </form>
      </div>
    );
  }
}
export default AddClientProject;
