import React from "react";
import PropTypes from "prop-types";

function Request({ accountDisplay, request, buttons, onApprovalButton }) {
  const requestStatusClass = !request.complete
    ? "pending-request account-request-tooltip"
    : request.approved
    ? "approved-request account-request-tooltip"
    : "denied-request account-request-tooltip";

  const requestStatus = !accountDisplay
    ? ""
    : !request.complete
    ? "Pending"
    : request.approved
    ? "Approved"
    : "Denied";

  const buttonDisplay = buttons ? "inline-block" : "none";

  const handleApprovalButton = (isApproved) => {
    onApprovalButton(request.user.id, isApproved, request.id);
  };

  return (
    <div className="user-show-request-group">
      <p
        role="heading"
        aria-label={`PTO Request for ${request.start_date}`}
        className={accountDisplay ? requestStatusClass : "approved-request"}
      >
        <em>
          {request.start_date === request.end_date
            ? `${request.start_date} - ${request.hours} hours`
            : `${request.start_date} - ${request.end_date}`}
        </em>
        <em className="status-tooltip-text">{requestStatus}</em>
      </p>
      <div className="user-show-request-body">
        <div
          style={{
            order: "1",
            display: "flex",
            alignItems: "center",
            flexGrow: "1",
            flexWrap: "wrap",
          }}
          tabIndex={0}
          aria-label={`PTO Request for ${request.user.name}`}
        >
          <img
            style={{
              height: "25px",
              width: "25px",
              borderRadius: "50%",
              display: "inline-block",
            }}
            alt={`Avatar for ${request.user.name}`}
            src={request.user.avatar_url}
          />
          <span className="user-show-request-name">{request.user.name}</span>
          <span className="user-show-request-details">{request.details}</span>
        </div>
        <div
          style={{
            order: "2",
            display: "block",
            flexGrow: "1",
            textAlign: "right",
            minWidth: "20%",
          }}
        >
          <div style={{ display: buttonDisplay }}>
            <button
              role="button"
              aria-label="Approve"
              onClick={() => handleApprovalButton(true)}
              className="btn-flat"
              type="button"
              style={{
                border: "1px solid lightgray",
                alignItems: "center",
                padding: 0,
                height: "24px",
                alignSelf: "center",
                cursor: "pointer",
                borderRadius: "3px",
                marginRight: "5px",
                width: "27px",
                justifyContent: "center",
                backgroundColor: "white",
              }}
            >
              <i className="material-icons">done</i>
            </button>
            <button
              role="button"
              aria-label="Deny"
              onClick={() => handleApprovalButton(false)}
              className="btn-flat"
              type="button"
              style={{
                border: "1px solid lightgray",
                alignItems: "center",
                padding: 0,
                height: "24px",
                alignSelf: "center",
                cursor: "pointer",
                borderRadius: "3px",
                width: "27px",
                justifyContent: "center",
                backgroundColor: "white",
              }}
            >
              <i className="material-icons" style={{ color: "#c21818" }}>
                close
              </i>
            </button>
            <br />
            <span className="request-created-at">
              Requested on {request.created_at}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

Request.propTypes = {
  accountDisplay: PropTypes.bool,
  buttons: PropTypes.bool,
  onApprovalButton: PropTypes.func,
  request: PropTypes.shape({
    approved: PropTypes.bool,
    complete: PropTypes.bool,
    created_at: PropTypes.string,
    details: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    hours: PropTypes.number,
    user: PropTypes.shape({
      id: PropTypes.number,
    }),
    manager: PropTypes.shape({
      id: PropTypes.number,
      avatar_url: PropTypes.string,
    }),
  }),
};

export default Request;
