import React from "react";

class TimeEntryRangeBox extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const hours = this.props.totalNumber;
    let pto = 0;
    let sickTime = 0;
    let holiday = 0;
    const range = this.props.selectedDateRangeOption.value === "custom"
      ? "Range"
      : this.props.selectedDateRangeOption.label;
    Object.entries(this.props.groupHours).forEach((g) => {
      switch (g[1].name) {
        case "Paid Time Off":
          pto = g[1].hours;
          break;
        case "Sick Time":
          sickTime = g[1].hours;
          break;
        case "Holiday":
          holiday = g[1].hours;
          break;
      }
    });
    return (
      <section
        aria-label="User time entry and PTO stats"
        style={{ height: "150px" }}
        className="bottom-section"
      >
        <div role="list">
          <div
            key="Hours"
            role="text"
            aria-label={`Hours ${hours.toFixed(2)} hours`}
          >
            <p>
              Hours For
              {' '}
              {range}
              <br />
              <span>{hours.toFixed(2)}</span>
            </p>
          </div>
          <div
            style={{ paddingLeft: "60px" }}
            key="PTO"
            role="text"
            aria-label={`PTO ${pto.toFixed(2)} hours`}
          >
            <p>
              PTO For
              {' '}
              {range}
              <br />
              <span>{pto.toFixed(2)}</span>
            </p>
          </div>
          <div
            key="Sick Time"
            role="text"
            aria-label={`Sick Time ${sickTime.toFixed(2)} hours`}
          >
            <p>
              Sick Time For
              {' '}
              {range}
              <br />
              <span>{sickTime.toFixed(2)}</span>
            </p>
          </div>
          <div
            style={{ paddingLeft: "60px" }}
            key="Holiday"
            role="text"
            aria-label={`Holiday ${holiday.toFixed(2)} hours`}
          >
            <p>
              Holiday For
              {' '}
              {range}
              <br />
              <span>{holiday.toFixed(2)}</span>
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default TimeEntryRangeBox;
