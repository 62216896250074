import React from "react";
import moment from "moment";
import ClosureRow from "../closures/closure_row";
import UserTaskWeekRow from "./user_task_week_row";

class DayColumn extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const date = this.props.day;
    const day = moment(date).format("dddd");
    const displayDate = moment(date).format("M/DD");
    const { tasks } = this.props;
    const { closures } = this.props;
    const userTasks = [];
    const taskNode = [];
    let hasTasks = false;
    const dailyHours = [];
    const closuresNode = [];
    let hasClosure = false;
    const currentDay = moment(new Date());
    const ariaLabel = "yo yo";
    let closureAriaLabel = "";
    for (let s = 0; s < closures.length; s++) {
      if (closures[s].start_date <= date && closures[s].end_date >= date) {
        closuresNode.push(
          <ClosureRow name={closures[s].name} key={closures[s].id} />
        );
        closureAriaLabel = `${day} ${moment(displayDate).format(
          "MMMM Do"
        )}, office closed, ${closures[s].name}`;
        hasClosure = true;
      }
    }
    for (let y = 0; y < tasks.length; y++) {
      userTasks.push({ occurs: false, dayTasks: [], key: y });
      if (tasks[y].start_date <= date && tasks[y].end_date >= date) {
        userTasks[y].occurs = true;
        hasTasks = true;
        if (tasks[y].project_confirmation === true) {
          dailyHours.push(tasks[y].hours);
        }
        userTasks[y].dayTasks.push([
          tasks[y].id,
          tasks[y].name,
          tasks[y].hours,
          tasks[y].start_date,
          tasks[y].end_date,
          tasks[y].project_id,
          tasks[y].confirmed,
          tasks[y].notes,
          tasks[y].card_color,
          tasks[y].created_by,
          tasks[y].project_confirmation,
        ]);
      }
      if (userTasks[y].occurs === true) {
        var total = 0;
        for (let b = 0; b < dailyHours.length; b++) {
          total += dailyHours[b];
        }
        taskNode.push(
          <UserTaskWeekRow
            twoWeeks={this.props.twoWeeks}
            modalAction={this.props.modalAction}
            taskId={userTasks[y].dayTasks[0][0]}
            taskHours={userTasks[y].dayTasks[0][2]}
            taskName={userTasks[y].dayTasks[0][1]}
            taskStatus={userTasks[y].dayTasks[0][6]}
            notes={userTasks[y].dayTasks[0][7]}
            cardColor={userTasks[y].dayTasks[0][8]}
            created_by={userTasks[y].dayTasks[0][9]}
            project_confirmation={userTasks[y].dayTasks[0][10]}
            key={userTasks[y].key}
          />
        );
      }
    }
    if (hasClosure === true) {
      return (
        <div
          aria-label={closureAriaLabel}
          style={{
            width: this.props.twoWeeks ? "10%" : "20%",
            borderRight:
              this.props.twoWeeks && this.props.index === 5
                ? "solid 1px lightgray"
                : "none",
          }}
          className={`col center ${
            displayDate === currentDay.format("M/DD")
              ? "grey lighten-3 white-text current-day"
              : ""
          }`}
        >
          <div className="row" aria-hidden>
            <p className="dark-gray-text no-bottom-margin ">{day}</p>
            <p className="dark-gray-text no-top-margin">{displayDate}</p>
            <div className="divider full-divider" />
          </div>
          {closuresNode}
        </div>
      );
    }
    if (hasTasks === true && total > 8) {
      return (
        <div
          aria-label={`${day} ${moment(displayDate).format("MMMM Do")}, ${
            taskNode.length
          } ${taskNode.length > 1 ? "tasks" : "task"} scheduled`}
          style={{
            width: this.props.twoWeeks ? "10%" : "20%",
            borderRight:
              this.props.twoWeeks && this.props.index === 5
                ? "solid 1px lightgray"
                : "none",
          }}
          className={`col center ${
            displayDate === currentDay.format("M/DD")
              ? "grey lighten-3 white-text current-day"
              : ""
          }`}
        >
          <div className="row" aria-hidden>
            <p className="overbooked dark-gray-text no-bottom-margin">
              {day}
              <i className="inline-icon material-icons yellow-text ">warning</i>
            </p>
            <p className="dark-gray-text no-top-margin">{displayDate}</p>
            <div className="divider full-divider" />
          </div>
          {taskNode}
        </div>
      );
    }
    if (hasTasks === true && total <= 8) {
      return (
        <div
          aria-label={`${day} ${moment(displayDate).format("MMMM Do")}, ${
            taskNode.length
          } ${taskNode.length > 1 ? "tasks" : "task"} scheduled`}
          style={{
            width: this.props.twoWeeks ? "10%" : "20%",
            borderRight:
              this.props.twoWeeks && this.props.index === 5
                ? "solid 1px lightgray"
                : "none",
          }}
          className={`col center ${
            displayDate === currentDay.format("M/DD")
              ? "grey lighten-3 white-text current-day"
              : ""
          }`}
        >
          <div className="row" aria-hidden>
            <p className="dark-gray-text no-bottom-margin">{day}</p>
            <p className="dark-gray-text no-top-margin">{displayDate}</p>
            <div className="divider full-divider" />
          </div>
          {taskNode}
        </div>
      );
    }
    return (
      <div
        aria-label={`${day} ${moment(displayDate).format(
          "MMMM Do"
        )}, nothing scheduled`}
        style={{
          width: this.props.twoWeeks ? "10%" : "20%",
          borderRight:
            this.props.twoWeeks && this.props.index === 5
              ? "solid 1px lightgray"
              : "none",
        }}
        className={`col center ${
          displayDate === currentDay.format("M/DD")
            ? "grey lighten-3 white-text current-day"
            : ""
        }`}
      >
        <div className="row" aria-hidden>
          <p className="dark-gray-text no-bottom-margin">{day}</p>
          <p className="dark-gray-text no-top-margin">{displayDate}</p>
          <div className="divider full-divider" />
        </div>
        <p aria-hidden>-</p>
      </div>
    );
  }
}

export default DayColumn;
