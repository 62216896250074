import React from "react";
import axios from "axios";
import Select, { components } from "react-select";

class TeamFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teamFilters: this.props.teamFilters,
      selectedUsersArray: [],
      editFilter: null,
      newSelectedUsers: [],
    };
    this.refreshFilters = this.refreshFilters.bind(this);
    this.saveFilter = this.saveFilter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteFilter = this.deleteFilter.bind(this);
    this.handleNewSelectedUsers = this.handleNewSelectedUsers.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.setEditFilter = this.setEditFilter.bind(this);
    this.handleTeamNameChange = this.handleTeamNameChange.bind(this);
    this.handleSelectedUsers = this.handleSelectedUsers.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };

    const selectedUsersArray = [];
    this.props.teamFilters.map((filter) => {
      const selectedUsers = [];
      this.props.allUsers.map((user) => {
        filter.user_ids.map((selectUser) => {
          if (user.id === selectUser) {
            selectedUsers.push({ value: selectUser, label: user.name });
          }
        });
      });
      selectedUsersArray.push(selectedUsers);
    });
    this.setState({ selectedUsersArray });
  }

  refreshFilters() {
    axios
      .get("/api/v1/filters", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const selectedUsersArray = [];
        res.data.map((filter, index) => {
          const selectedUsers = [];
          this.props.allUsers.map((user) => {
            filter.user_ids.map((selectUser) => {
              if (user.id === selectUser) {
                selectedUsers.push({ value: selectUser, label: user.name });
              }
            });
          });
          selectedUsersArray.push(selectedUsers);
        });
        this.setState({
          selectedUsersArray,
          teamFilters: res.data,
        });
      })
      .catch((err) => console.error(err));
  }

  saveFilter(filterId, index) {
    const users = [];
    this.state.selectedUsersArray[index].map((user) => users.push(user.value));
    users.unshift(0);
    axios
      .patch(`/api/v1/team_filters/${filterId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        team_filter: {
          name: this.refs[`filter_name_${index}`].value,
          user_id: this.props.userId,
          user_ids: users,
        },
      })
      .then(() => {
        M.toast({
          html: `Team updated!`,
          displayLength: 3000,
          classes: "green",
        });
        this.setState({ editFilter: null });
        this.refreshFilters();
      })
      .catch((err) => console.error(err));
  }

  handleSubmit(e) {
    e.preventDefault();
    this.refs.submit_button.disabled = true;
    const users = [];
    this.state.newSelectedUsers.map((user) => users.push(user.value));
    users.unshift(0);
    const name = this.refs.filter_name.value;
    axios
      .post(`/api/v1/create_team_filters.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        team_filter: {
          name,
          user_id: this.props.userId,
          user_ids: users,
          role_id: this.props.roleId,
        },
      })
      .then((res) => {
        M.toast({
          html: `${res.data.name} created!`,
          displayLength: 3000,
          classes: "green",
        });
        this.setState({ newSelectedUsers: [] });
        this.refs.filter_name.value = "";
        this.refs.submit_button.disabled = false;
        this.refreshFilters();
      })
      .catch((err) => console.error(err));
  }

  deleteFilter(id) {
    if (confirm("Are you sure you want to delete this filter?") === true) {
      axios
        .delete(`api/v1/team_filters/${id}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          M.toast({
            html: `Filter deleted`,
            displayLength: 3000,
            classes: "green",
          });
          this.setState({ editFilter: null });
          this.refreshFilters();
        })
        .catch((err) => console.error(err));
    }
  }

  handleNewSelectedUsers(e) {
    this.setState({ newSelectedUsers: e });
  }

  getSelectTheme(theme) {
    return {
      ...theme,
      colors: { ...theme.colors, primary: "#2bbbad", neutral0: "#fafafa" },
    };
  }

  onCancelClick() {
    this.setState({ editFilter: null });
    this.refreshFilters();
  }

  setEditFilter(e) {
    const index = parseInt(e.target.id.split("_")[1], 10);
    this.setState({ editFilter: index });
  }

  handleTeamNameChange(e) {
    const teamFiltersCopy = [...this.state.teamFilters];
    teamFiltersCopy[this.state.editFilter].name = e.target.value;
    this.setState({ teamFilters: teamFiltersCopy });
  }

  handleSelectedUsers(e) {
    const arrayCopy = [...this.state.selectedUsersArray];
    arrayCopy[this.state.editFilter] = e;
    this.setState({ selectedUsersArray: arrayCopy });
  }

  onSaveClick() {
    this.saveFilter(
      this.state.teamFilters[this.state.editFilter].id,
      this.state.editFilter
    );
  }

  onDeleteClick() {
    this.deleteFilter(this.state.teamFilters[this.state.editFilter].id);
  }

  render() {
    const options = [];
    this.props.allUsers.map((user) => {
      options.push({ value: user.id, label: user.name });
    });
    function MultiValueRemove(props) {
      return (
        <components.MultiValueRemove {...props}>
          <span />
        </components.MultiValueRemove>
      );
    }
    return (
      <div className="col s12">
        <div className="row">
          <div className="col s12 card">
            <div>
              <h4>Create Team Filter</h4>
              <form
                className="new_filter"
                id="new_filter"
                autoComplete="off"
                onSubmit={this.handleSubmit}
              >
                <div className="input-field">
                  <label id="name" htmlFor="filters">
                    Name
                  </label>
                  <input
                    required="required"
                    type="text"
                    name="filter[name]"
                    id="filter_name"
                    ref="filter_name"
                  />
                </div>
                <label htmlFor="filter_filters">Users</label>
                <input name="filter[user_ids][]" type="hidden" value="" />
                <Select
                  options={options}
                  onChange={this.handleNewSelectedUsers}
                  isMulti
                  value={this.state.newSelectedUsers}
                  theme={this.getSelectTheme}
                  closeMenuOnSelect={false}
                />
                <div className="new-modal-footer">
                  <div className="row center">
                    <div className="col s12 input-field">
                      <input
                        type="submit"
                        name="commit"
                        value="Create Filter"
                        ref="submit_button"
                        className="common-button-submit white-text modal-btn"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col s12 card">
            <h4>Teams</h4>
            <ul>
              {this.state.teamFilters.map((filter, index) => {
                return (
                  <div className="tf-box" key={filter.id}>
                    <div className="input-field">
                      <label id="name" htmlFor="filters" className="active">
                        Name
                        {this.state.editFilter === index ? (
                          <a
                            className="s1 material-icons inline-icon red-text"
                            id="filter_clear"
                            onClick={this.onDeleteClick}
                          >
                            delete
                          </a>
                        ) : (
                          ""
                        )}
                      </label>
                      <input
                        onChange={this.handleTeamNameChange}
                        required="required"
                        disabled={this.state.editFilter !== index}
                        value={this.state.teamFilters[index].name}
                        type="text"
                        name="filter[name]"
                        id={`filter_name_${index}`}
                        ref={`filter_name_${index}`}
                      />
                    </div>
                    <label htmlFor="filter_filters">Users</label>
                    <input name="filter[user_ids][]" type="hidden" value="" />
                    <Select
                      options={options}
                      isMulti
                      isDisabled={this.state.editFilter !== index}
                      value={this.state.selectedUsersArray[index]}
                      theme={this.getSelectTheme}
                      closeMenuOnSelect={false}
                      components={
                        this.state.editFilter === index
                          ? {}
                          : {
                            MultiValueRemove: (
                              <components.MultiValueRemove {...props}>
                                <span />
                              </components.MultiValueRemove>
                            ),
                          }
                      }
                      onChange={this.handleSelectedUsers}
                    />
                    <div className=" s12 input-field  center">
                      {this.state.editFilter === null ? (
                        <input
                          value="Edit Team Filter"
                          type="button"
                          id={`editButton_${index}`}
                          onClick={this.setEditFilter}
                          className="common-button-submit white-text modal-btn"
                        />
                      ) : this.state.editFilter === index ? (
                        <div>
                          <input
                            type="button"
                            value="Save"
                            id={`saveButton_${index}`}
                            className="common-button-submit white-text modal-btn"
                            onClick={this.onSaveClick}
                          />
                          <input
                            style={{ marginLeft: "5px" }}
                            type="button"
                            value="Cancel"
                            className="btn white dark-gray-text modal-btn"
                            onClick={this.onCancelClick}
                          />
                        </div>
                      ) : (
                        <input
                          type="button"
                          disabled
                          value="Edit Team Filter"
                          className="common-button-submit white-text modal-btn"
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamFilters;
