import React, { useState } from "react";
import styled from "styled-components";

const UserShowSidebar = ({
  user,
  userImage,
  fullTime,
  contractor,
  roleId,
  currentView,
  setCurrentView,
  account = false,
  show,
  handleProfilePicChange,
  currentUserRole,
}) => {
  const [hover, setHover] = useState(false);
  const roleIds = {
    1: "Tester",
    2: "Lead",
    3: "PM",
    4: "HR",
    5: "Admin",
  };

  const RoleColorMap = {
    1: "#F55454",
    2: "#386FFE",
    3: "#20835D",
    4: "#7A51AD",
    5: "#4F2A9E",
  };

  const handleClick = (e) => {
    const view = parseInt(e.target.value);
    setCurrentView(view);
  };

  const uploadProfilePicIcon = (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <path
        d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31ZM16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        fill={hover ? "#519ACC" : "#D7D7D7"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7443 8.17924C21.03 8.0609 21.3362 8 21.6454 8C21.9546 8 22.2608 8.0609 22.5465 8.17924C22.8322 8.29757 23.0917 8.47101 23.3104 8.68966C23.529 8.90831 23.7025 9.16788 23.8208 9.45356C23.9391 9.73924 24 10.0454 24 10.3546C24 10.6639 23.9391 10.97 23.8208 11.2557C23.7025 11.5414 23.529 11.801 23.3104 12.0196L18.4461 16.8839C18.0856 17.2444 17.6275 17.4893 17.1284 17.5891C17.1283 17.5891 17.1284 17.5891 17.1284 17.5891L14.8792 18.0393C14.6234 18.0905 14.3588 18.0104 14.1743 17.826C13.9897 17.6415 13.9096 17.377 13.9607 17.1211L14.41 14.872C14.5102 14.3727 14.7561 13.9136 15.1164 13.5537C15.1164 13.5537 15.1163 13.5538 15.1164 13.5537L19.9804 8.68966C20.1991 8.47101 20.4586 8.29757 20.7443 8.17924ZM21.6454 9.56099C21.5412 9.56099 21.438 9.58152 21.3417 9.6214C21.2454 9.66129 21.1579 9.71975 21.0842 9.79345L20.2657 10.6119L21.3884 11.734L22.2066 10.9158C22.2803 10.8421 22.3388 10.7546 22.3786 10.6584C22.4185 10.5621 22.439 10.4589 22.439 10.3546C22.439 10.2504 22.4185 10.1472 22.3786 10.0509C22.3388 9.95463 22.2803 9.86714 22.2066 9.79345C22.1329 9.71975 22.0454 9.66129 21.9491 9.6214C21.8528 9.58152 21.7496 9.56099 21.6454 9.56099ZM20.2846 12.8378L19.1619 11.7157L16.2199 14.6577C16.0777 14.7998 15.9803 14.9811 15.9407 15.1781C15.9407 15.1782 15.9407 15.178 15.9407 15.1781L15.7208 16.2789L16.822 16.0585C17.0193 16.019 17.2 15.9225 17.3423 15.7801L20.2846 12.8378Z"
        fill="#519ACC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4792 11.2596C10.2357 11.2596 10.0021 11.3563 9.82993 11.5285C9.65773 11.7007 9.56099 11.9343 9.56099 12.1778V21.5208C9.56099 21.7643 9.65773 21.9979 9.82993 22.1701C10.0021 22.3423 10.2357 22.439 10.4792 22.439H19.8222C20.0657 22.439 20.2993 22.3423 20.4715 22.1701C20.6437 21.9979 20.7404 21.7643 20.7404 21.5208V18.9727C20.7404 18.5416 21.0899 18.1922 21.5209 18.1922C21.952 18.1922 22.3014 18.5416 22.3014 18.9727V21.5208C22.3014 22.1783 22.0402 22.8089 21.5753 23.2739C21.1103 23.7388 20.4797 24 19.8222 24H10.4792C9.82169 24 9.19109 23.7388 8.72615 23.2739C8.2612 22.8089 8 22.1783 8 21.5208V12.1778C8 11.5203 8.2612 10.8897 8.72615 10.4247C9.19109 9.95978 9.82169 9.69858 10.4792 9.69858H13.0273C13.4584 9.69858 13.8078 10.048 13.8078 10.4791C13.8078 10.9101 13.4584 11.2596 13.0273 11.2596H10.4792Z"
        fill="#519ACC"
      />
    </svg>
  );

  return (
    <Sidebar>
      <ProfileImage
        alt={
          account ? "Your profile picture" : `${user.name}'s profile picture`
        }
        src={userImage}
      />
      {((currentUserRole > 3 && !account) || account) && (
        <ProfileUploadImage>
          <label htmlFor="user_avatar" style={{ cursor: "pointer" }}>
            {uploadProfilePicIcon}
          </label>
          <input
            onChange={handleProfilePicChange}
            style={{ display: "none" }}
            type="file"
            name="user[avatar]"
            id="user_avatar"
          />
        </ProfileUploadImage>
      )}

      <UserName
        style={{
          marginTop:
            (currentUserRole > 3 && !account) || account ? "0px " : "16px",
        }}
      >
        {user.name}
      </UserName>
      <UserRoleContainer>
        <UserRole contractor={contractor} fullTime={fullTime} roleId={roleId}>
          {contractor ? "Contractor" : fullTime ? "Full-Time" : "Part-Time"}
        </UserRole>
        <UserRoleLabel roleLabelColor={RoleColorMap[roleId]}>
          {roleIds[roleId]}
        </UserRoleLabel>
      </UserRoleContainer>
      <Navigation aria-label="Account page navigation">
        <NavButton onClick={handleClick} value="0" active={currentView === 0}>
          Personal Information
        </NavButton>
        <NavButton onClick={handleClick} value="1" active={currentView === 1}>
          Projects
        </NavButton>
        {show && (
          <NavButton
            onClick={handleClick}
            value="2"
            active={currentView === 2}
            className="user-show-nav"
            style={{ color: currentView === 2 ? "#519acc" : "#313131" }}
          >
            Schedule
          </NavButton>
        )}
        {account && (
          <NavButton onClick={handleClick} value="2" active={currentView === 2}>
            Notifications
          </NavButton>
        )}
        {account && (
          <NavButton onClick={handleClick} value="4" active={currentView === 4}>
            PTO Requests
          </NavButton>
        )}
      </Navigation>
    </Sidebar>
  );
};

const Sidebar = styled.div`
  height: calc(100vh - 98px);
  min-width: 292px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: solid 1px lightgray;
`;

const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  margin-top: 32px;
  border-radius: 50%;
`;

const ProfileUploadImage = styled.span`
  position: relative;
  top: -30px;
  left: 43px;
`;

const UploadProfilePicIcon = styled.svg`
  width: 32px;
  height: 32px;
  transition: fill 0.3s;
`;

const UserName = styled.h2`
  text-align: center;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin: 0px 0px 16px;
`;

const UserRoleContainer = styled.div`
  display: flex;
`;

const UserRole = styled.div`
  background-color: ${({ contractor, fullTime }) =>
    contractor ? "#F55454" : fullTime ? "#519acc" : "#D76F23"};
  color: white;
  border-radius: 20px;
  display: flex;
  height: 23px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
`;

const UserRoleLabel = styled.div`
  background-color: ${({ roleLabelColor }) => roleLabelColor};
  margin-left: 4px;
  color: white;
  border-radius: 20px;
  display: flex;
  height: 23px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
`;

const Navigation = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const NavButton = styled.button`
  color: ${({ active }) => (active ? "#519acc" : "#313131")};
  background: none !important;
  border: none;
  cursor: pointer;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;

  display: block;

  &:hover {
    color: #519acc;
  }
`;

export default UserShowSidebar;
