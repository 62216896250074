import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import TeamShow from "../users/team_show";
import CommentIcon from "../icons/comment_icon";
import ExpandIcon from "../icons/expand_icon";

function ProspectTaskCard({
  projectProspect,
  currentUser,
  leadOptions,
  userOptions,
  dropProspect,
  loadProspects,
  moveToTaskboard,
  modalHandler,
  prospectsHandler,
}) {
  // Display leads in TeamShow component
  function displayLeads(hide, max) {
    const { leads, lead_owner_id, lead_owner_name, lead_owner_avatar_url } =
      projectProspect;

    const userObject = {
      [lead_owner_name]: {
        role_name: "",
        name: lead_owner_name,
        id: lead_owner_id,
        avatar_url: lead_owner_avatar_url,
      },
    };

    leads.forEach((lead) => {
      if (!userObject[lead.name]) {
        userObject[lead.name] = {
          role_name: "",
          name: lead.name,
          id: lead.id,
          avatar_url: lead.avatar_url,
        };
      }
    });

    return (
      <TeamShow
        users={Object.values(userObject)}
        hide={false}
        maxCards={max}
        currentUser={currentUser}
        prospect
      />
    );
  }

  // Fetch prospect details from the server
  async function getProspect() {
    try {
      const response = await axios.get(
        `/projects/${projectProspect.id}/project_prospect`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching prospect:", error);
    }
  }

  // Handle inner modal logic
  async function innerModalHandler(e) {
    if (e.target.name !== "move-to-taskboard") {
      const { focusTarget } = e.target.dataset;
      const prospect = await getProspect();
      modalHandler(true, "project-stage", {
        project: prospect,
        leadOptions,
        userOptions,
        column: "prospect",
        focusTarget,
        customClass: "taskModal",
        currentUser,
        prospectsHandler,
        loadProspects,
        moveToTaskboard,
        dropProspect,
      });
    }
  }

  // Handle inner move to taskboard logic
  function innerMoveToTaskboard() {
    moveToTaskboard(projectProspect);
  }

  // Generate recent event text
  let recentEventText = "";
  const { most_recent_event, client_name, comment_count, lead_source } =
    projectProspect;

  if (most_recent_event.create) {
    recentEventText = `Client ${client_name} was created.`;
  } else if (most_recent_event.message) {
    recentEventText = `${most_recent_event.message}`;
  } else if (most_recent_event.comment) {
    recentEventText = `${most_recent_event.comment.split(" ")[0]} commented - ${
      most_recent_event.comment_text
    }`;
  }

  return (
    <div
      onClick={innerModalHandler}
      style={{
        maxWidth: "340px",
        minWidth: "340px",
        cursor: "pointer",
        height: "128px",
        position: "relative",
        flex: "1 1 calc(33.33% - 8px)",
      }}
      className="taskboard-card"
    >
      {/* Client name */}
      <p className="taskcard-project">{client_name}</p>

      {/* Move to taskboard button */}
      <button
        onClick={innerMoveToTaskboard}
        name="move-to-taskboard"
        style={{
          background: "none",
          cursor: "pointer",
          position: "absolute",
          top: "10px",
          right: "10px",
          border: "solid 1px lightgray",
          padding: "4px",
          borderRadius: "4px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ExpandIcon
          svgStyles={{ pointerEvents: "none" }}
          width={15}
          height={15}
        />
      </button>

      {/* Recent event text */}
      <p
        style={{
          height: "38px",
          overflow: "hidden",
          lineHeight: "18px",
          fontSize: "14px",
          textOverflow: "ellipsis",
          WebkitLineClamp: "2",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
        }}
      >
        <span
          style={{ fontWeight: "700", fontFamily: "arial", fontSize: "14px" }}
        >
          Last Update:{" "}
        </span>
        <span
          style={{
            fontStyle: "italic",
            fontFamily: "arial",
            fontSize: "13px",
            fontWeight: 400,
          }}
        >
          "{recentEventText}"
        </span>
      </p>

      {/* Additional information */}
      <div
        style={{ width: "100%", paddingTop: "8px" }}
        className="flex-container flex-row flex-wrap flex-al-center"
      >
        {/* Comments button */}
        <button
          onClick={innerModalHandler}
          data-focus-target="comments"
          type="button"
          id="comment-counter-box"
        >
          <CommentIcon
            svgStyles={{ transform: "translateY(2px)", pointerEvents: "none" }}
            width="20"
            height="20"
          />
          <p style={{ pointerEvents: "none" }}>{comment_count}</p>
        </button>

        {/* Task date */}
        <p className="task-date">
          Update:{" "}
          <span style={{ fontWeight: 500 }}>
            {moment(most_recent_event.created_at).format("MMM D")}
          </span>
        </p>

        {/* Lead source */}
        <p className="task-date">
          Source:{" "}
          <span style={{ fontWeight: 500, textTransform: "capitalize" }}>
            {lead_source}
          </span>
        </p>

        {/* Display leads */}
        {displayLeads(true, 1)}
      </div>
    </div>
  );
}

export default ProspectTaskCard;
