import React from "react";
import ScheduleNameColumn from "./schedule_name_column";
import ScheduleDayColumn from "./schedule_day_column";

class ScheduleUserRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.renderTasks = this.renderTasks.bind(this);
  }

  renderTasks() {
    const tasks = this.props.user.tasks
      ? this.props.user.tasks
      : this.props.userTasks;
    const taskNode = [];
    const secondTaskNode = [];
    for (var i = 0; i < this.props.currentWeek.length; i++) {
      taskNode.push(
        <ScheduleDayColumn
          allowedProjectIds={this.props.allowedProjectIds}
          selectedProjects={this.props.selectedProjects}
          currentUserId={this.props.currentUserId}
          currentUserRoleId={this.props.currentUserRoleId}
          userId={this.props.user.id}
          userName={this.props.user.name}
          userFullTime={this.props.user.full_time}
          dragDropAddTask={this.props.dragDropAddTask}
          modalAction={this.props.modalAction}
          day={this.props.currentWeek[i]}
          bookedByMe={this.props.bookedByMe}
          currentWeekEnd={i === this.props.currentWeek.length - 1}
          twoWeeks={this.props.twoWeeks}
          tasks={tasks.filter(
            (t) => t.start_date === this.props.currentWeek[i]
          )}
          key={i}
          today={this.props.today}
          cutoffDate={this.props.cutoffDate}
          closure={this.props.currentWeekClosures[i]}
          userSchedule={this.props.userSchedule}
          viewOnly={this.props.viewOnly}
          setDraggingItem={this.props.setDraggingItem}
          getDraggingItem={this.props.getDraggingItem}
        />
      );
    }
    for (var i = 0; i < this.props.secondWeek.length; i++) {
      secondTaskNode.push(
        <ScheduleDayColumn
          allowedProjectIds={this.props.allowedProjectIds}
          selectedProjects={this.props.selectedProjects}
          userId={this.props.user.id}
          userName={this.props.user.name}
          userFullTime={this.props.user.full_time}
          currentUserId={this.props.currentUserId}
          currentUserRoleId={this.props.currentUserRoleId}
          bookedByMe={this.props.bookedByMe}
          dragDropAddTask={this.props.dragDropAddTask}
          modalAction={this.props.modalAction}
          day={this.props.secondWeek[i]}
          twoWeeks={this.props.twoWeeks}
          tasks={tasks.filter((t) => t.start_date === this.props.secondWeek[i])}
          key={i}
          today={this.props.today}
          cutoffDate={this.props.cutoffDate}
          closure={this.props.secondWeekClosures[i]}
          userSchedule={this.props.userSchedule}
          viewOnly={this.props.viewOnly}
          setDraggingItem={this.props.setDraggingItem}
          getDraggingItem={this.props.getDraggingItem}
        />
      );
    }
    let hours = this.props.twoWeeks ? 80 : 40;
    if (this.props.twoWeeks && tasks.length > 0) {
      hours = tasks
        .filter((t) => t.project_confirmation)
        .map((t) => t.hours)
        .reduce((t, n) => t + n, 0);
      hours = hours < 80 ? 80 - hours : 0;
    } else if (tasks.length > 0) {
      hours = tasks
        .filter(
          (t) =>
            t.start_date < this.props.secondWeek[0] && t.project_confirmation
        )
        .map((t) => t.hours)
        .reduce((t, n) => t + n, 0);
      hours = hours < 40 ? 40 - hours : 0;
    }
    if (this.props.currentWeekClosures.filter((x) => x != false).length >= 1) {
      hours -=
        this.props.currentWeekClosures.filter((x) => x != false).length * 8;
    }
    if (this.props.secondWeekClosures.filter((x) => x != false).length >= 1) {
      hours -=
        this.props.secondWeekClosures.filter((x) => x != false).length * 8;
    }
    if (hours < 0) {
      hours = 0;
    }
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          minHeight: this.props.userSchedule ? "100px" : "108px",
        }}
      >
        <ScheduleNameColumn
          user={this.props.user}
          id={this.props.user.id}
          hoursAvailable={hours}
          twoWeeks={this.props.twoWeeks}
          userSchedule={this.props.userSchedule}
        />
        {taskNode}
        {this.props.twoWeeks && secondTaskNode}
      </div>
    );
  }

  render() {
    return (
      <div
        style={{ marginRight: this.props.userSchedule ? "10px" : "17px" }}
        className={` ${this.props.index % 2 != 0 ? "off-white" : ""} white-row`}
      >
        {this.renderTasks()}
      </div>
    );
  }
}

export default ScheduleUserRow;
