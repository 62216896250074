import styled, { css } from "styled-components";

const ExitButton = styled.button`
  padding: 0;
  background-color: transparent;
  outline: none;
  border: none;
  height: 25px;
  width: 25px;
  cursor: pointer;

  &:active,
  &:focus {
    background-color: transparent;
    outline: none;
    border: none;
  }

  *:active,
  *:focus {
    outline: none;
    border: none;
  }
`;

const h3Styles = css`
  font-family: Manrope;
  font-weight: 600;
  font-size: 18px;
  color: #242424;
`;

const selectHeightValue = 39;
const selectHeight = `height: ${selectHeightValue}px;`;
const selectMaxHeight = `max-height: ${selectHeightValue}px;`;
export const selectStyle = css`
  .react-select__control {
    width: 100%;
    ${selectHeight}
    ${selectMaxHeight}
    border-color: #d7d7d7;
    box-shadow: 0px 2px 0px 0px transparent;
  }

  .react-select__control:hover {
    border-color: #519acc;
  }

  .react-select__control:focus {
    box-shadow: 0px 2px 0px 0px #519acc;
  }

  .react-select__container {
    width: 100%;
    ${selectHeight}
    ${selectMaxHeight}
  }

  .react-select__value-container {
    ${selectHeight}
    ${selectMaxHeight}
  }

  .react-select__indicators {
    ${selectHeight}
    ${selectMaxHeight}
  }

  .react-select__menu {
    width: 100%;
    transform: unset;
  }
`;

export const datePickerStyle = css`
  .SingleDatePicker {
    width: 100%;
    margin-left: -22px;
  }

  .SingleDatePickerInput {
    width: 100%;
    justify-content: flex-start;
    padding-left: 46px;
  }

  .DateInput {
    width: 100%;
  }
`;

export const Headline3Manrope = styled.h3`
  ${h3Styles}
`;

export const HeadlineRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    ${h3Styles}
    margin: 0;
  }
`;

export const Headline = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin-left: 16px;

  p {
    font-size: 13px;
    margin: 0;
    margin-top: 4px;
    font-family: Arial;
    font-weight: 400;
  }
`;

export const HeadlineTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HeadlineIconContainer = styled.div`
  background-color: ${(props) => props.color || "#519acc"};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: "white";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

export const FormRow = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const colGap = 16;
export const FormRowDouble = styled(FormRow)`
  display: flex;
  gap: ${colGap}px;
`;

export const FormHalfRow = styled(FormRowDouble)`
  align-self: flex-start;
  width: 50%;
`;

export const ProjectStageTagRow = styled(FormRow)`
  margin-bottom: 32px;

  .tag.sm {
    margin-top: 0;
  }
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${selectStyle}
  ${datePickerStyle}

  /* Sometimes the datpicker jumps out of its container,
   * sometimes it doesn't. If showing a datepicker within
   * a FormItem, with the above styles applied, remove the 
   * -22px margin by passing $removeDatepickerNegMargin=true
   * prop to the FormItem component.
   */
  ${(props) => {
    return (
      props.$removeDatepickerNegMargin &&
      `
    .SingleDatePicker {
      margin-left: 0;
    } 
  `
    );
  }}
`;

export const FormItemDouble = styled(FormItem)`
  flex-basis: calc(50% - ${colGap / 2}px);
`;

export const FormItemFixedWidth = styled(FormItem)`
  min-width: ${(props) => props.$width};
  width: ${(props) => props.$width};
`;

export const Label = styled.label`
  margin-bottom: 8px;
  display: block;
  font-family: Manrope;
  font-weight: 600;
  font-size: 13px;
  color: #242424;
`;

export const CharacterCount = styled.p`
  margin: 0;
  font-size: 13px;
  font-family: "Arial";
  font-weight: normal;
  margin-top: 8px;
`;

const disclaimerTextStyle = css`
  font-size: 13px;
  font-weight: 400;
  font-family: Arial;
  color: #c0c0c0;
`;

export const Disclaimer = styled.p`
  margin: 0;
  ${disclaimerTextStyle}
`;
export const DisclaimerInline = styled.span`
  ${disclaimerTextStyle}
`;

export const TranslatedWrapper = styled.div`
  transform: translateX(-22px);
`;

export const ControlsRow = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  button,
  input {
    min-width: 100px;
  }
`;

export default {
  h3Styles,
  ExitButton,
  Headline3Manrope,
  HeadlineRow,
  HeadlineTitleContainer,
  HeadlineIconContainer,
  Headline,
  Form,
  FormRow,
  FormHalfRow,
  FormRowDouble,
  ProjectStageTagRow,
  FormItem,
  FormItemDouble,
  FormItemFixedWidth,
  Label,
  CharacterCount,
  Disclaimer,
  DisclaimerInline,
  TranslatedWrapper,
  ControlsRow,
};
