import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import styled from "styled-components";

const Room = ({
  icon,
  rooms,
  setModal,
  currentUser,
  currentDay,
  users,
  bookings,
  deleteHandler,
  editHandler,
  bookingHandler,
  roomBackgroundColor,
  roomClass,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const hoverTimeoutRef = useRef(null);

  const [state, setState] = useState({
    room_id: rooms.id,
    name: rooms.name,
    capacity: rooms.capacity,
    description: rooms.description,
    allow_client: rooms.allow_client,
    tv: rooms.tv,
    table: rooms.table,
    wellness_room: rooms.wellness_room,
    standing_desk: rooms.standing_desk,
    isBooked: false,
    roomCSS: "room",
    current_booking: null,
  });

  const dateValues = {
    "08:00": 0,
    "08:15": 1,
    "08:30": 2,
    "08:45": 3,
    "09:00": 4,
    "09:15": 5,
    "09:30": 6,
    "09:45": 7,
    "10:00": 8,
    "10:15": 9,
    "10:30": 10,
    "10:45": 11,
    "11:00": 12,
    "11:15": 13,
    "11:30": 14,
    "11:45": 15,
    "12:00": 16,
    "12:15": 17,
    "12:30": 18,
    "12:45": 19,
    "13:00": 20,
    "13:15": 21,
    "13:30": 22,
    "13:45": 23,
    "14:00": 24,
    "14:15": 25,
    "14:30": 26,
    "14:45": 27,
    "15:00": 28,
    "15:15": 29,
    "15:30": 30,
    "15:45": 31,
    "16:00": 32,
    "16:15": 33,
    "16:30": 34,
    "16:45": 35,
    "17:00": 36,
    "17:15": 37,
    "17:30": 38,
    "17:45": 39,
    "18:00": 40,
  };

  useEffect(() => {
    checkIsBooked();
    return () => {
      setIsHovered(false);
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
    };
  }, []);

  const checkIsBooked = () => {
    const start = moment();
    const remainder = start.minute() % 15;
    const dateTime = moment(start)
      .subtract(remainder, "minutes")
      .format("HH:mm");
    const dateValue = dateValues[dateTime];

    if (bookings.length > 0) {
      const foundBooking = bookings.find(
        (booking) => booking.booking_array[dateValue] === "1"
      );

      if (foundBooking) {
        setState((prevState) => ({
          ...prevState,
          isBooked: true,
          current_booking: foundBooking,
        }));
      }
    }
  };

  const openModal = () => {
    const data = {
      action: "create",
      roomCapacity: rooms.capacity,
      bookings: digestBookings(),
      bookingHandler,
      currentUser,
      roomName: rooms.name,
      room: rooms.id,
      roomObject: rooms,
      currentDay,
      users,
      deleteHandler,
      editHandler,
      customClass: "meeting-modal",
    };

    if (!state.isBooked) {
      setModal(true, "meeting", data);
    } else {
      setModal(true, "meeting", data);
      data.booking = state.current_booking;
      data.disabled =
        state.isBooked &&
        currentUser.role_id === 1 &&
        state.current_booking.user.id !== currentUser.id;
      data.action = "view";
      data.closeButton = true;
      // data.handleViewEdit = handleViewEdit;
      setModal(true, "meeting", data);
    }
  };

  const digestBookings = () => {
    const array = new Array(40).fill("0");
    const idArray = new Array(40).fill("0");

    for (const booking of bookings) {
      const bookingArray = booking.booking_array;

      for (let i = 0; i < bookingArray.length; i++) {
        const inner = bookingArray[i];

        if (inner !== "0") {
          array[i] = inner;
          idArray[i] = booking.id;
        }
      }
    }

    return [array, idArray];
  };

  const amenities = [];
  if (state.table) {
    amenities.push("Table");
  }
  if (state.tv) {
    amenities.push("TV");
  }
  if (state.standing_desk) {
    amenities.push("Standing Desk");
  }

  const amenitiesText = amenities.length > 0 ? amenities.join(", ") : "None";

  const handleMouseEnter = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }

    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    hoverTimeoutRef.current = setTimeout(() => {
      setIsHovered(false);
    }, 1);
  };
  return (
    <RoomCard
      className="white-space room-view-room-card"
      id={roomClass}
      onClick={openModal}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      roomBackgroundColor={roomBackgroundColor}
      isHovered={isHovered}
      isBooked={state.isBooked}
    >
      {!isHovered || state.isBooked ? (
        <>
          <RoomOverlay></RoomOverlay>
          <RoomIcon>{icon}</RoomIcon>
          <RoomName>{rooms.name}</RoomName>
          <RoomStatus>{state.isBooked ? "In Use" : "Open"}</RoomStatus>

          <RoomInfoWrapper>
            <RoomUsers color={roomBackgroundColor}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="11"
                viewBox="0 0 13 11"
                fill="none"
              >
                <path
                  d="M9.1 9.16667V10.5H0V9.16667C0 9.16667 0 6.50001 4.55 6.50001C9.1 6.50001 9.1 9.16667 9.1 9.16667ZM6.825 2.83336C6.825 2.37187 6.69157 1.92075 6.44159 1.53703C6.19161 1.15332 5.83631 0.854253 5.4206 0.677649C5.0049 0.501045 4.54748 0.454837 4.10617 0.544869C3.66486 0.634901 3.2595 0.857129 2.94133 1.18345C2.62317 1.50977 2.40649 1.92553 2.31871 2.37815C2.23093 2.83077 2.27598 3.29993 2.44817 3.72628C2.62036 4.15264 2.91196 4.51706 3.28608 4.77345C3.6602 5.02984 4.10005 5.16669 4.55 5.16669C5.15337 5.16669 5.73202 4.92085 6.15867 4.48327C6.58531 4.04569 6.825 3.4522 6.825 2.83336ZM9.061 6.50001C9.46059 6.81718 9.78755 7.2203 10.0189 7.68102C10.2502 8.14173 10.3803 8.64884 10.4 9.16667V10.5H13V9.16667C13 9.16667 13 6.74668 9.061 6.50001ZM8.45 0.500035C8.0026 0.49748 7.56503 0.634672 7.1955 0.893367C7.59034 1.45919 7.80264 2.13755 7.80264 2.83336C7.80264 3.52917 7.59034 4.20753 7.1955 4.77335C7.56503 5.03205 8.0026 5.16924 8.45 5.16669C9.05337 5.16669 9.63202 4.92085 10.0587 4.48327C10.4853 4.04569 10.725 3.4522 10.725 2.83336C10.725 2.21452 10.4853 1.62103 10.0587 1.18345C9.63202 0.745867 9.05337 0.500035 8.45 0.500035Z"
                  fill="white"
                />
              </svg>
              {rooms.capacity}
            </RoomUsers>
            <RoomAmenities color={roomBackgroundColor}>
              {amenitiesText}
            </RoomAmenities>
          </RoomInfoWrapper>
        </>
      ) : (
        <>
          <RoomNameBooking roomBackgroundColor={roomBackgroundColor}>
            Book
          </RoomNameBooking>
          <RoomOverlay></RoomOverlay>
        </>
      )}
    </RoomCard>
  );
};

const RoomCard = styled.div`
  // max-width: 193px;
  min-width: 140px;
  padding: 8px 16px;
  pointer-events: auto;
  cursor: pointer;
  border: ${(props) =>
    props.isBooked && props.isHovered
      ? `2px solid ${props.roomBackgroundColor}`
      : `none`};

  &:hover {
    border: ${(props) =>
      props.isBooked ? "none" : `2px solid ${props.roomBackgroundColor}`};
    border-radius: ${(props) => (props.isBooked ? "5px" : "7px")};
  }
`;

const RoomIcon = styled.span`
  position: relative;
  z-index: 20;
`;

const RoomName = styled.p`
  position: relative;
  z-index: 20;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #fff;
  margin-top: 0px;
  margin-bottom: 0px;
  text-transform: capitalize;
`;
const RoomNameBooking = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.roomBackgroundColor};
  min-width: 21px;
  position: absolute;
  border-radius: 5px;
  padding: 8px 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #fff;
`;

const RoomStatus = styled.p`
  position: relative;
  z-index: 20;
  font-family: Arial;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  color: #fff;
  margin: 0px;
`;

const RoomOverlay = styled.div`
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100%);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.55) 0%,
    rgba(0, 0, 0, 0.55) 100%
  );
  z-index: 10;
`;

const RoomInfoWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  z-index: 20;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;
const RoomUsers = styled.span`
  display: flex;
  padding: 2px 4px;
  align-items: center;
  gap: 4px;
  border-radius: 2px;
  background: ${(props) => props.color};
  color: #fff;
  text-align: center;
  font-family: "Open Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
`;

const RoomAmenities = styled.span`
  color: var(--background-colors-white-ffffff, #fff);
  text-align: center;
  /* Label Text - Open Sans - Semibold - 11 */
  font-family: "Open Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
  padding: 2px 4px;
  border-radius: 2px;
  background: ${(props) => props.color};
`;
Room.propTypes = {
  rooms: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentDay: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  bookings: PropTypes.array.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  editHandler: PropTypes.func.isRequired,
  bookingHandler: PropTypes.func.isRequired,
};

export default Room;
