import React from "react";

const AddProjectModalIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
    >
      <rect
        width="42"
        height="42"
        rx="21"
        fill="url(#paint0_linear_1858_3427)"
      />
      <path
        d="M29.0067 11.3649H24.2257C23.9915 10.691 23.5553 10.107 22.9774 9.6939C22.3996 9.28076 21.7086 9.05884 21.0002 9.05884C20.2917 9.05884 19.6008 9.28076 19.0229 9.6939C18.4451 10.107 18.0089 10.691 17.7747 11.3649H12.9936C12.3875 11.3668 11.8067 11.6103 11.3781 12.0423C10.9495 12.4744 10.7079 13.0598 10.7061 13.6708V29.8118C10.7079 30.4228 10.9495 31.0082 11.3781 31.4403C11.8067 31.8723 12.3875 32.1158 12.9936 32.1177H29.0067C29.6129 32.1158 30.1937 31.8723 30.6223 31.4403C31.0509 31.0082 31.2925 30.4228 31.2943 29.8118V13.6708C31.2925 13.0598 31.0509 12.4744 30.6223 12.0423C30.1937 11.6103 29.6129 11.3668 29.0067 11.3649ZM21.0002 11.3649C21.2264 11.3649 21.4475 11.4325 21.6356 11.5592C21.8237 11.6859 21.9703 11.866 22.0569 12.0767C22.1435 12.2873 22.1661 12.5191 22.122 12.7428C22.0779 12.9664 21.9689 13.1719 21.809 13.3331C21.649 13.4943 21.4452 13.6041 21.2233 13.6486C21.0014 13.6931 20.7715 13.6703 20.5625 13.583C20.3535 13.4958 20.1748 13.348 20.0491 13.1584C19.9235 12.9688 19.8564 12.7459 19.8564 12.5179C19.8573 12.2124 19.9781 11.9196 20.1924 11.7036C20.4067 11.4876 20.6971 11.3658 21.0002 11.3649ZM18.7126 17.1296L21.9038 20.3509L24.7861 17.4455L23.2878 15.9766H27.8629V20.5884L26.4034 19.0769L21.9061 23.6137L18.7126 20.3924L15.7547 23.3715L14.1374 21.7413L18.7126 17.1296ZM29.0067 28.6589H12.9936V26.353H29.0067V28.6589Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1858_3427"
          x1="21"
          y1="0"
          x2="21"
          y2="42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AddProjectModalIcon;
