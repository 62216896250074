import React from "react";

const timeRow = [
  "",
  "8:00",
  "8:30",
  "9:00",
  "9:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
];

class TimeRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: "",
    };
    this.renderTimeRow = this.renderTimeRow.bind(this);
  }

  renderTimeRow(time, index) {
    return (
      <span
        key={index}
        style={{
          fontWeight: 600,
          color: "rgba(0, 0, 0, 0.87)",
          fontFamily: "Manrope",
          fontSize: "14px",
          marginTop: "16px",
          marginBottom: "8px",
        }}
      >
        {time.substring(0)}
      </span>
    );
  }

  render() {
    return (
      <div>
        <div className="time-row-grid">{timeRow.map(this.renderTimeRow)}</div>
      </div>
    );
  }
}

export default TimeRow;
