import React from "react";
import axios from "axios";

class TaskConfirmationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      completed: false,
      response: "",
    };
    this.getBodyText = this.getBodyText.bind(this);
    this.handleResponseChange = this.handleResponseChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  }

  handleResponseChange(e) {
    this.setState({
      response: e.target.value,
    });
  }

  handleSubmit() {
    this.refs.submit_button.disabled = true;
    axios
      .post(`/api/v1/tasks/email_confirmation_response`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        id: this.props.task.id,
        text: this.refs.response.value,
      })
      .then((res) => {
        this.setState({
          completed: true,
        });
      })
      .catch((err) => {
        this.refs.submit_button.disabled = false;
      });
  }

  getBodyText() {
    if (this.props.alreadyConfirmed) {
      return (
        <p>
          This booking has already been marked
          {" "}
          {this.props.task.confirmed ? "available" : "unavailable"}
        </p>
      );
    }
    if (this.props.task.confirmed) {
      return (
        <p>Thank you! You have been marked available for this booking.</p>
      );
    }
    return (
      <div>
        <p style={{ maxWidth: "600px" }}>
          Thank you! You have been marked unavailable for this booking.
          Please provide a brief explanation of your unavailability below.
          Your response will be sent to the scheduling manager.
        </p>
        <textarea
          style={{
            maxWidth: "600px",
            display: "block",
            marginBottom: "20px",
          }}
          disabled={this.state.completed}
          ref="response"
          value={this.state.response}
          onChange={this.handleResponseChange}
        />
        <input
          type="button"
          disabled={!this.state.response}
          className="common-button-submit white-text closure-button"
          value="Submit"
          ref="submit_button"
          onClick={this.handleSubmit}
        />
        {this.state.completed ? (
          <p style={{ fontSize: "20px", color: "green" }}>Response Sent!</p>
        ) : (
          ""
        )}
      </div>
    );
  }

  render() {
    return (
      <div>
        <h1>
          {this.props.task.name}
          {' '}
          Booking Status
        </h1>
        <div className="divider gray z-depth-2 shadow-divider" />
        {this.getBodyText()}
      </div>
    );
  }
}

export default TaskConfirmationPage;
