// eslint-disable camelcase
import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import ms from "./ModalStyled";
import SingleSelectWrapper from "../common/single_select_wrapper";
import SingleCreateableSelectWrapper from "../common/single_creatable_select_wrapper";
import SingleDateWrapper from "../common/single_date_wrapper";
import ModalHeadline from "./ModalHeadline";

const LEAD_SOURCE_OPTIONS = [
  { value: "website", label: "Website" },
  { value: "existing client", label: "Existing Client" },
  { value: "referral", label: "Referral" },
  { value: "linkedin", label: "LinkedIn" },
  { value: "other", label: "Other" },
];

const NewLead = (props) => {
  const { modalAction, data } = props;
  const { currentUser, projectProspect } = data;

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [project_name, setProjectName] = useState("");
  const [lead_user, setLeadUser] = useState({
    label: currentUser.name,
    value: currentUser.id,
  });
  const [leadSource, setLeadSource] = useState(
    projectProspect
      ? LEAD_SOURCE_OPTIONS.find((o) => o.value === projectProspect.lead_source)
      : ""
  );
  const [startDate] = useState(null);
  const [endDate] = useState(null);
  const [tag, setTag] = useState(null);
  const [followUpDate, setFollowUpDate] = useState(null);
  const [dateFocus, setDateFocus] = useState(false);

  const handleDateFocus = (focused) => {
    setDateFocus(focused?.focused);
  };

  const handleDateChange = (date) => {
    setFollowUpDate(date);
  };

  useEffect(() => {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };

    setLoading(true);
    axios
      .get("/client_options.json", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setOptions(res.data);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  const closeModal = () => {
    modalAction(false, "", {});
  };

  const handleClientChange = (e) => {
    setSelectedClient(e);
  };

  const handleSubmit = () => {
    const errors = [];

    if (project_name === "") {
      errors.push("a project name");
    }
    if (!data.projectProspect && !data.clientId && !selectedClient) {
      errors.push("a client");
    }
    if (!data.lead_user && !lead_user) {
      errors.push("a lead");
    }
    if (!leadSource) {
      errors.push("a lead source");
    }
    if (!tag) {
      errors.push("a project type");
    }
    if (errors.length >= 1) {
      // eslint-disable-next-line no-undef
      M.toast({
        html: `Please select ${errors
          .join(", ")
          .replace(/, ([^,]*)$/, " and $1")}`,
        displayLength: 3000,
        classes: "red",
      });
      return null;
    }

    setLoading(false);
    const isProspect = !!data.projectProspect;
    const url = `/projects${
      isProspect ? `/${data.projectProspect.id}` : ""
    }.json`;
    const method = isProspect ? "patch" : "post";
    const PAYLOAD = {
      patch: {
        project: {
          name: project_name,
          tag_id: tag.value,
          lead_source: leadSource.value,
          lead_owner_id: lead_user.value,
          prospect: false,
        },
      },
      post: {
        project: {
          name: project_name,
          tag_id: tag.value,
          lead_source: leadSource.value,
          lead_owner_id: lead_user.value,
          client_id: selectedClient.__isNew__
            ? { new: true, name: selectedClient.label }
            : selectedClient.value,
          start_date: startDate,
          end_date: endDate,
          follow_up_date: followUpDate,
        },
      },
    };

    axios[method](url, PAYLOAD[method])
      .then((res) => {
        Swal.fire({
          icon: "success",
          customClass: {
            container: "tb-swal",
            title: "tb-swal-title",
            icon: "tb-swal-icon",
            content: "tb-swal-content",
            confirmButton: "tb-swal-confirm",
          },
          title: "Lead Complete!",
          html: "<p>Your new project was created successfully and has been added to the <strong>New Project</strong> column on the Taskboard.</p><p>The next step is to access the newly created card and add any more known details about the project.</p>",
          showCancelButton: false,
          confirmButtonAriaLabel: "Next Step",
          confirmButtonText: "Next Step",
        });
        if (isProspect) {
          data.loadProspects();
          data.loadRegular();
        } else {
          data.projectsHandler(res.data);
        }
        modalAction(false, "", {});
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          title: "Project Error",
          text: err.response.data,
          confirmButtonText: "OK",
        });
      })
      .finally(() => {
        setLoading(false);
      });

    return null;
  };

  const MODAL_KEY = data.projectProspect ? "project_details" : "new_lead";

  return (
    <div className="modal-content">
      <ModalHeadline modalKey={MODAL_KEY} closeModal={closeModal} />
      {loading && (
        <div
          style={{ height: "30px", width: "30px" }}
          className="page-load-spinner"
        />
      )}
      <ms.Form>
        {options.length > 0 && (
          <ms.FormRow
            className="new_project"
            id="new_project"
            onSubmit={handleSubmit}
          >
            <ms.FormItem
              style={{
                opacity: data.projectProspect ? "0.5" : "1",
              }}
            >
              <ms.Label className="more-padding">
                Client Name<span className="base-color">*</span>
              </ms.Label>
              <SingleCreateableSelectWrapper
                options={options}
                onChange={handleClientChange}
                value={
                  data.projectProspect
                    ? {
                        label: data.projectProspect.client_name,
                        value: data.projectProspect.client_id,
                      }
                    : selectedClient
                }
                isDisabled={data.projectProspect}
              />
            </ms.FormItem>
          </ms.FormRow>
        )}
        <ms.FormRow>
          <ms.FormItem>
            <ms.Label className="more-padding">
              Project Name<span className="base-color">*</span>
            </ms.Label>
            <input
              type="text"
              name="project_name"
              maxLength="50"
              className="browser-default text-input-v2"
              onChange={(e) => setProjectName(e.target.value)}
              value={project_name}
            />
            <ms.CharacterCount>{`${project_name.length}/50`}</ms.CharacterCount>
          </ms.FormItem>
        </ms.FormRow>
        <ms.FormRowDouble>
          <ms.FormItemDouble>
            <ms.Label className="more-padding">
              Lead Source<span className="base-color">*</span>
            </ms.Label>
            <SingleSelectWrapper
              options={LEAD_SOURCE_OPTIONS}
              onChange={(client) => {
                setLeadSource(client);
              }}
              name="leadSource"
              placeholder="Select a source"
              value={leadSource}
              menuPlacement="top"
              customStyles={{
                container: (styles) => ({ ...styles, width: "100%" }),
              }}
            />
          </ms.FormItemDouble>
          <ms.FormItemDouble>
            <ms.Label className="more-padding">
              Lead Manager<span className="base-color">*</span>
            </ms.Label>
            <SingleSelectWrapper
              options={data.taskboardUserOptions}
              onChange={(value) => setLeadUser(value)}
              placeholder="Select a user"
              name="lead_user"
              value={lead_user}
              menuPlacement="top"
              isSearchable
              customStyles={{
                container: (styles) => ({ ...styles, width: "100%" }),
              }}
            />
          </ms.FormItemDouble>
        </ms.FormRowDouble>
        <ms.FormRowDouble>
          <ms.FormItemDouble>
            <ms.Label className="more-padding">
              Project Type<span className="base-color">*</span>
            </ms.Label>
            <SingleSelectWrapper
              options={data.tagOptions}
              onChange={(value) => setTag(value)}
              name="tag"
              placeholder="Select a type"
              value={tag}
              menuPlacement="top"
              customStyles={{
                container: (styles) => ({ ...styles, width: "100%" }),
              }}
            />
          </ms.FormItemDouble>
          <ms.FormItemDouble>
            <ms.Label className="more-padding">Follow Up Date</ms.Label>
            <SingleDateWrapper
              date={followUpDate}
              onDateChange={handleDateChange}
              focused={dateFocus}
              onFocusChange={handleDateFocus}
              required={false}
              placeholder="Select a date"
              customStyles={{ height: "39px" }}
            />
          </ms.FormItemDouble>
        </ms.FormRowDouble>
        <ms.ControlsRow>
          <button
            type="button"
            name="cancel"
            value="Cancel"
            onClick={closeModal}
            className="common-button-cancel"
          >
            Cancel
          </button>
          <button
            disabled={
              (selectedClient === "" && !data.projectProspect) ||
              project_name === "" ||
              lead_user === "" ||
              leadSource === ""
            }
            type="button"
            name="commit"
            className="common-button-submit"
            onClick={handleSubmit}
          >
            {data.projectProspect ? "Move" : "Create Project"}
          </button>
        </ms.ControlsRow>
      </ms.Form>
    </div>
  );
};

export default NewLead;
