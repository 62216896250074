import React from "react";
import moment from "moment";
import DropperContainer from "./dropper_container";

function ProspectTimeline(props) {
  const {
    prospectEvents,
    column,
    project,
    statusUpdateText,
    handleInputChange,
    submitStatusUpdate,
  } = props;

  if (!prospectEvents || prospectEvents.length > 0 || column !== "prospect") {
    return null;
  }

  return (
    <DropperContainer dropperId="timeline" title="Timeline">
      <div>
        {column === "prospect" && project.active && (
          <p style={{ marginLeft: "10px", marginBottom: "0px" }}>
            Status Update
          </p>
        )}
        {column === "prospect" && project.active && (
          <div style={{ margin: "0px 0px 8px" }} className="input-field">
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                required="required"
                type="text"
                style={{ margin: "10px 0 10px 10px" }}
                value={statusUpdateText}
                name="statusUpdateText"
                id="status_update_text"
                onChange={handleInputChange}
                className="browser-default text-input-v2"
                maxLength={50}
                autoComplete="off"
                placeholder="Enter a status update"
                aria-describedby="character-count"
              />
              <button
                type="button"
                disabled={statusUpdateText}
                style={{ margin: "0px 10px" }}
                className="common-button-submit"
                onClick={submitStatusUpdate}
              >
                Submit
              </button>
            </div>
            <p
              id="character-count"
              aria-live="polite"
              aria-hidden
              style={{ transform: "translateX(11px)" }}
              className="input-counter"
            >{`${statusUpdateText.length}/50`}</p>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            marginTop:
              column === "prospect" && project.active ? "-25px" : "10px",
          }}
        >
          {prospectEvents.map((p, i) => {
            let message = "";
            switch (p.event_type) {
              case "create":
                message = (
                  <span>
                    Client{" "}
                    <span style={{ color: "#519acc", fontWeight: "600" }}>
                      {p.client_name}
                    </span>{" "}
                    was created
                  </span>
                );
                break;
              case "comment":
                message = (
                  <span>
                    <span style={{ fontWeight: "600" }}>{p.user_name}</span>{" "}
                    made a comment
                  </span>
                );
                break;
              case "message":
                message = p.message;
                break;
              default:
                break;
            }
            return (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
                key={p.id}
              >
                <p
                  style={{
                    textAlign: "center",
                    width: "36px",
                    fontFamily: "manrope",
                    fontWeight: "600",
                    margin: "0px 0px 0px 8px",
                    lineHeight: "16px",
                  }}
                >
                  <span>{moment(p.created_at).format("D")}</span>
                  <br />
                  <span>{moment(p.created_at).format("MMM")}</span>
                </p>
                <div
                  className={`timeline-status-circle ${
                    i === 0 ? "last-circle" : ""
                  }`}
                  style={{
                    width: "15px",
                    margin: "0px 10px 0px 8px",
                    height: "15px",
                    borderRadius: "50%",
                    border: `solid 3px ${
                      p.event_type === "create" ? "#519acc" : "#519acc"
                    }`,
                  }}
                />
                <div
                  style={{
                    flex: "1",
                    boxShadow: "lightgrey 0px 1px 3px 1px",
                    padding: "8px 15px",
                    marginRight: "10px",
                  }}
                >
                  {message}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </DropperContainer>
  );
}

export default ProspectTimeline;
