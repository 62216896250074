import React from "react";

const CraterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12.0001 1.6001C11.5201 1.6001 11.3601 1.9201 11.0401 2.2401L1.7601 17.4401C1.6001 17.6001 1.6001 17.9201 1.6001 18.0801C1.6001 18.8801 2.2401 19.2001 2.7201 19.2001H21.2801C21.9201 19.2001 22.4001 18.8801 22.4001 18.0801C22.4001 17.7601 22.4001 17.7601 22.2401 17.4401L13.1201 2.2401C12.8001 1.9201 12.4801 1.6001 12.0001 1.6001ZM12.0001 4.0001L17.2801 12.8001H16.0001L13.6001 10.4001L12.0001 12.8001L10.4001 10.4001L8.0001 12.8001H6.5601L12.0001 4.0001Z"
        fill="white"
      />
    </svg>
  );
};

export default CraterIcon;
