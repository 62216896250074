import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { components } from "react-select";
import moment from "moment";
import ms, { Form } from "components/modals/ModalStyled";
import SingleSelectWrapper from "../../common/single_select_wrapper";
import RoundDropdown from "../round_dropdown";
import SingleDateWrapper from "../../common/single_date_wrapper";

const LeadCard = styled.div`
  .react-select__menu-list {
    max-height: 185px;
  }
`;

export const StageForm = styled(Form)`
  margin-top: 0;
`;

const LeadStage = (props) => {
  const { project, submitHandler, tagOptions } = props;
  const [followUpDate, setFollowUpDate] = useState(
    project.follow_up_date ? moment(project.follow_up_date) : undefined
  );
  const [dateFocus, setDateFocus] = useState(false);
  const [tag, setTag] = useState(
    project.tag
      ? {
          label: project.tag.name,
          value: project.tag.id,
          color: project.color,
        }
      : undefined
  );

  const handleDateFocus = useCallback(
    (focused) => {
      if (dateFocus && focused.focused) {
        setDateFocus(true);
      } else {
        setDateFocus(focused.focused);
      }
    },
    [dateFocus]
  );

  function save() {
    const projectData = new FormData();
    if (tag) {
      projectData.append("project[tag_id]", tag.value);
    }
    if (followUpDate) {
      projectData.append("project[follow_up_date]", followUpDate);
    }

    submitHandler(projectData, "leading", "est_requesting", true);
  }

  const roundDrop = (dropProps) => {
    return (
      <components.DropdownIndicator {...dropProps}>
        <RoundDropdown />
      </components.DropdownIndicator>
    );
  };

  return (
    <LeadCard className="transition">
      <div>
        <StageForm>
          <ms.FormRowDouble style={{ alignItems: "flex-end" }}>
            <ms.FormItem>
              <ms.Label className="custom-labels" htmlFor="follow_up_date">
                Follow-Up Date
                <span className="base-color">*</span>
              </ms.Label>
              <SingleDateWrapper
                date={followUpDate}
                onDateChange={(date) => setFollowUpDate(date)}
                focused={dateFocus}
                onFocusChange={handleDateFocus}
                required={false}
                label={null}
                placeholder="Select a date"
              />
            </ms.FormItem>
            <ms.FormItem>
              <ms.Label className="custom-labels" htmlFor="filter_filters">
                Project Type
                <span className="base-color">*</span>
              </ms.Label>
              <SingleSelectWrapper
                options={tagOptions}
                onChange={(t) => setTag(t)}
                classNamePrefix="react-select"
                value={tag}
                blurInputOnSelect
                components={{ DropdownIndicator: roundDrop }}
              />
            </ms.FormItem>
            <ms.FormItem>
              <button
                type="button"
                onClick={save}
                className="common-button-submit"
              >
                Save All Changes
              </button>
            </ms.FormItem>
          </ms.FormRowDouble>
        </StageForm>
      </div>
    </LeadCard>
  );
};

export default LeadStage;
