import React from "react";
import axios from "axios";
import moment from "moment";
import Select, { components } from "react-select";
import SingleSelectStyles from "../common/single_select_styles";
import { CSSTransition } from "react-transition-group";
import "react-dates/initialize";
import CalendarIcon from "../icons/calendar_icon.jsx";
import RoundDropdown from "../projects/round_dropdown.jsx";
import { SingleDatePicker } from "react-dates";

class InvoiceSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.rangeOptions = [
      { label: "This Week", value: "week" },
      { label: "This Month", value: "month" },
      { label: "This Quarter", value: "quarter" },
      { label: "This Year", value: "year" },
      { label: "Last Year", value: "last_year" },
      { label: "Custom", value: "custom" },
    ];
    this.statusOptions = [
      { label: "All Statuses", value: "all" },
      { label: "Ready for Invoice", value: "ready" },
      { label: "Pending Payment", value: "pending" },
      { label: "Payment Cleared", value: "cleared" },
    ];
    this.roundDrop = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <RoundDropdown />
        </components.DropdownIndicator>
      );
    };
  }

  handleSearch = (e) => {
    var value = e.target.value ? e.target.value : "";
    this.props.searchUpdate(value);
  };

  isDayBlocked = (day) => {
    return moment(day).day() === 0 || moment(day).day() === 6 ? true : false;
  };

  render() {
    return (
      <div id="invoice-sidebar-container">
        <div
          id="ticketSearch"
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "fit-content",
          }}
        >
          <i
            aria-hidden="true"
            style={{
              position: "absolute",
              pointerEvents: "none",
              left: "6px",
              color: "#ababab",
            }}
            className="material-icons prefix"
          >
            search
          </i>
          <input
            id="invoice-search-input"
            className="browser-default"
            type="text"
            onChange={this.handleSearch}
            placeholder="Search for an Invoice"
            aria-live="polite"
            //  value={this.props.search}
            aria-label="Search"
          />
          <CSSTransition
            unmountOnExit
            in={this.props.searchLoading}
            timeout={500}
            classNames="node-fade"
          >
            <div className="invoice-search-input-spinner" />
          </CSSTransition>
        </div>
        <div>
          <p>Invoice Range</p>
          <Select
            isSearchable={false}
            classNamePrefix="react-select"
            options={this.rangeOptions}
            onChange={this.props.updateSelectedInvoiceRange}
            value={this.props.selectedInvoiceRange}
            blurInputOnSelect={true}
            components={{ DropdownIndicator: this.roundDrop }}
            styles={{
              ...SingleSelectStyles,
              ...{
                container: (styles) => ({
                  ...styles,
                  width: "200px",
                  fontSize: "13px",
                  fontFamily: "Arial",
                  zIndex: "10",
                }),

                menu: (styles) => ({
                  ...styles,
                  width: "220px",
                  left: "-10px",
                }),
              },
            }}
          />
          {this.props.selectedInvoiceRange.value === "custom" && (
            <>
              <div
                id="start-date-container"
                style={{ marginLeft: "-23px", marginTop: "40px", zIndex: "9" }}
                className="flex-j-start flex-container flex-al-center"
              >
                <div
                  style={{
                    height: "160px",
                    borderLeft: "solid 1px lightgray",
                    position: "absolute",
                    left: "47px",
                    top: "252px",
                  }}
                />
                <div
                  style={{
                    position: "relative",
                    left: "65px",
                    zIndex: 1,
                    top: "5px",
                  }}
                >
                  <CalendarIcon width="22px" height="22px" color="#519acc" />
                </div>
                <SingleDatePicker
                  date={this.props.customStartDate} // momentPropTypes.momentObj or null
                  onDateChange={this.props.handleStartDateChange} // PropTypes.func.isRequired
                  focused={this.props.startPickerFocus} // PropTypes.bool
                  onFocusChange={this.props.onStartFocusChange} // PropTypes.func.isRequired
                  numberOfMonths={1}
                  placeholder={"Select a date"}
                  orientation={"horizontal"}
                  daySize={30}
                  navPrev={
                    <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                      <RoundDropdown rotate={"rotate(90deg)"} />
                    </div>
                  }
                  navNext={
                    <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                      <RoundDropdown rotate={"rotate(-90deg)"} />
                    </div>
                  }
                  hideKeyboardShortcutsPanel
                  inputIconPosition={"after"}
                  customInputIcon={<RoundDropdown />}
                  isOutsideRange={this.isDayBlocked}
                  id="single_date_picker" // PropTypes.string.isRequired,
                />
                <label
                  style={{ paddingLeft: "4px" }}
                  className="date-labels"
                  htmlFor="filter_filters"
                >
                  Start Date
                </label>
              </div>
              <div
                id="end-date-container"
                style={{ marginLeft: "-23px", marginTop: "40px" }}
                className="flex-j-start flex-container flex-al-center"
              >
                <div
                  style={{
                    position: "relative",
                    left: "65px",
                    zIndex: 1,
                    top: "5px",
                  }}
                >
                  <CalendarIcon width="22px" height="22px" color="#519acc" />
                </div>
                <SingleDatePicker
                  date={this.props.customEndDate} // momentPropTypes.momentObj or null
                  onDateChange={this.props.handleEndDateChange} // PropTypes.func.isRequired
                  focused={this.props.endPickerFocus} // PropTypes.bool
                  onFocusChange={this.props.onEndFocusChange} // PropTypes.func.isRequired
                  numberOfMonths={1}
                  placeholder={"Select a date"}
                  orientation={"horizontal"}
                  daySize={30}
                  navPrev={
                    <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                      <RoundDropdown rotate={"rotate(90deg)"} />
                    </div>
                  }
                  navNext={
                    <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                      <RoundDropdown rotate={"rotate(-90deg)"} />
                    </div>
                  }
                  hideKeyboardShortcutsPanel
                  inputIconPosition={"after"}
                  customInputIcon={<RoundDropdown />}
                  isOutsideRange={this.isDayBlocked}
                  id="single_date_picker" // PropTypes.string.isRequired,
                />
                <label
                  style={{ paddingLeft: "4px" }}
                  className="date-labels"
                  htmlFor="filter_filters"
                >
                  End Date
                </label>
              </div>
            </>
          )}
        </div>
        <div>
          <p>Invoice Client</p>
          <Select
            classNamePrefix="react-select"
            options={this.props.clientOptions}
            onChange={this.props.updateSelectedClient}
            value={this.props.selectedClient}
            blurInputOnSelect={true}
            components={{ DropdownIndicator: this.roundDrop }}
            styles={{
              ...SingleSelectStyles,
              ...{
                container: (styles) => ({
                  ...styles,
                  width: "220px",
                  fontSize: "13px",
                  fontFamily: "Arial",
                }),
                menu: (styles) => ({
                  ...styles,
                  width: "240px",
                  left: "-10px",
                }),
              },
            }}
          />
        </div>
        <div>
          <p>Filter By Payment Status</p>
          <Select
            isSearchable={false}
            classNamePrefix="react-select"
            options={[
              ...[{ label: "All Statuses", value: "all" }],
              ...this.props.statusOptions,
            ]}
            onChange={this.props.updateSelectedStatus}
            value={this.props.selectedStatus}
            blurInputOnSelect={true}
            components={{ DropdownIndicator: this.roundDrop }}
            styles={{
              ...SingleSelectStyles,
              ...{
                container: (styles) => ({
                  ...styles,
                  width: "200px",
                  fontSize: "13px",
                  fontFamily: "Arial",
                }),
                menu: (styles) => ({
                  ...styles,
                  width: "220px",
                  left: "-10px",
                }),
              },
            }}
          />
        </div>
      </div>
    );
  }
}

export default InvoiceSidebar;
