import React from "react";
import moment from "moment";

class Task extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dragging: false,
    };
    this.editTask = this.editTask.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDragDrop = this.onDragDrop.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.taskCard = React.createRef();
  }

  editTask() {
    // in case they change the css to pointer-events:all
    if (this.props.notAllowed) {
      return;
    }
    const { task } = this.props;
    if (this.props.viewOnly) {
      var data = {
        taskId: task.id,
        name: task.project_name,
        clientName: task.client_name,
        userName: task.user_name,
        createdBy: task.created_by_name,
        createdById: task.created_by,
        projectConfirmation: task.project_confirmation,
        date: this.props.date,
        userId: task.user_id,
        hours: task.hours,
        notes: task.notes,
        closeButton: true,
        customClass: "add-task-modal",
        disabled: this.props.disabled,
        cardRef: this.taskCard,
      };
      this.props.modalAction(true, "booking-details", data);
    } else {
      var data = {
        taskId: task.id,
        currentUserId: this.props.currentUserId,
        currentUserRoleId: this.props.currentUserRoleId,
        clientName: task.client_name,
        taskName: task.name,
        userName: task.user_name,
        createdByName: task.created_by_name,
        createdById: task.created_by,
        date: this.props.date,
        userId: task.user_id,
        closeButton: true,
        customClass: "add-task-modal",
        disabled: this.props.disabled,
        backdropClickCheck: {
          title: "Discard Changes",
          text: "Are you sure that you want to discard changes to booking?",
        },
        projectId: task.project_id,
        projectName: task.project_name,
      };
      this.props.modalAction(true, "add-task", data);
    }
  }

  onDragStart(e) {
    e.dataTransfer.setData("text", "anything");
    const elem = document.getElementById("tasks-section");
    if (elem) {
      setTimeout(() => {
        elem.classList.add("dragging");
      }, 100);
    }
    this.props.setDraggingItem(this.props.task);
    e.target.style.opacity = "0.5";
    const data = {
      taskId: this.props.task.id,
      userId: this.props.task.user_id,
      date: this.props.date,
    };

    this.dragImage = e.target.cloneNode(true);
    this.dragImage.classList.add("task-card-drag-image");
    document.getElementById("drag-image-container").appendChild(this.dragImage);
    e.dataTransfer.effectAllowed = "all";
    e.dataTransfer.setData("text", JSON.stringify(data));
    e.dataTransfer.setDragImage(this.dragImage, 0, 20);
  }

  onDragEnd(e) {
    const elem = document.getElementById("tasks-section");
    if (elem) {
      setTimeout(() => {
        elem.classList.remove("dragging");
      }, 100);
    }
    e.target.style.opacity = "1";
    document.getElementById("drag-image-container").removeChild(this.dragImage);
  }

  onDragEnter(e) {
    const draggingItem = this.props.getDraggingItem();
    if (draggingItem && draggingItem.id !== this.props.task.id) {
      if (
        draggingItem.start_date === e.target.dataset.date &&
        draggingItem.user_id === this.props.task.user_id
      ) {
        if (draggingItem.order > this.props.task.order) {
          e.target.children[0].style.height = "25px";
        } else {
          e.target.children[2].style.height = "25px";
        }
      } else if (e.target.dataset.date) {
        e.target.children[0].style.height = "25px";
      }
    }
  }

  onDragLeave(e) {
    const draggingItem = this.props.getDraggingItem();

    if (draggingItem && draggingItem.id !== this.props.task.id) {
      if (
        draggingItem.start_date === e.target.dataset.date &&
        draggingItem.user_id === this.props.task.user_id
      ) {
        if (draggingItem.order > this.props.task.order) {
          e.target.children[0].style.height = "0px";
        } else {
          e.target.children[2].style.height = "0px";
        }
      } else if (e.target.dataset.date) {
        e.target.children[0].style.height = "0px";
      }
    }
  }

  onDragDrop(e) {
    e.preventDefault();
    const draggingItem = this.props.getDraggingItem();
    if (draggingItem.id !== this.props.task.id) {
      const data = e.dataTransfer.getData("text");
      const copy = !!e.altKey;
      if (
        draggingItem.start_date === e.target.dataset.date &&
        draggingItem.user_id === this.props.task.user_id
      ) {
        if (draggingItem.order > this.props.task.order) {
          e.target.children[0].style.height = "0px";
          var reorder_ids = this.props.currentOrders
            .filter((t) => t.order < this.props.task.order)
            .map((t) => t.id);
          var newData = {
            userId: this.props.userId,
            userFullTime: this.props.userFullTime,
            date: this.props.date,
            order: this.props.task.order - 1,
            reorder_ids,
            reorder_direction: "down",
          };
          this.props.dragDropAddTask(data, newData, copy);
        } else {
          var reorder_ids = this.props.currentOrders
            .filter((t) => t.order > this.props.task.order)
            .map((t) => t.id);
          e.target.children[2].style.height = "0px";
          var newData = {
            userId: this.props.task.user_id,
            userFullTime: this.props.userFullTime,
            date: this.props.date,
            order: this.props.task.order + 1,
            reorder_ids,
            reorder_direction: "up",
          };
          this.props.dragDropAddTask(data, newData, copy);
        }
      } else if (e.target.dataset.date) {
        var reorder_ids = this.props.currentOrders
          .filter((t) => t.order < this.props.task.order)
          .map((t) => t.id);
        e.target.children[0].style.height = "0px";
        var newData = {
          userId: this.props.task.user_id,
          userFullTime: this.props.userFullTime,
          date: this.props.date,
          order: this.props.task.order - 1,
          reorder_ids,
          reorder_direction: "down",
        };
        this.props.dragDropAddTask(data, newData, copy);
      }
    }
  }

  onDragOver(e) {
    e.preventDefault();
  }

  render() {
    const { task } = this.props;
    const projectHidden =
      this.props.notAllowed ||
      (this.props.bookedByMe && this.props.currentUserId !== task.created_by) ||
      (this.props.selectedProjects &&
        this.props.selectedProjects.length > 0 &&
        this.props.selectedProjects.indexOf(task.project_id) === -1);

    const formattedDay = moment(task.start_date).format("dddd");

    return (
      <div
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDragDrop}
        onDragOver={this.onDragOver}
        data-date={task.start_date}
        data-order={task.order}
        data-id={task.id}
        data-user-id={task.user_id}
        className="single-task-drag-container"
        style={{ padding: "0px 0 0" }}
      >
        <div
          id={`task-${task.id}-top-drag-area`}
          style={{
            height: "0px",
            transition: "height 0.2s",
            backgroundColor: "lightgray",
            pointerEvents: "none",
          }}
        />
        <div
          ref={this.taskCard}
          draggable={
            !(
              this.props.disabled ||
              this.props.viewOnly ||
              !task.part_time_over_confirmed
            )
          }
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}
          role="button"
          tabIndex={0}
          onClick={this.editTask}
          className={`
            task-card 
            ${projectHidden ? "project-hidden" : ""} 
            ${this.props.twoWeeks ? "two-weeks" : ""}
            ${
              (task.remote && task.project_confirmation) ||
              !task.part_time_over_confirmed
                ? "remote"
                : ""
            }    
            ${task.unavailable ? "unavailable" : ""}
            ${!task.project_confirmation ? "unconfirmed" : ""}
          `}
          aria-label={`Project ${task.project_name} booked on ${formattedDay} ${
            task.start_date
          } for ${task.hours} hour${task.hours > 1 ? "s" : ""}, ${
            !task.part_time_over_confirmed
              ? "part time overbooked"
              : !task.project_confirmation
              ? "project unconfirmed"
              : "work from home"
          }
                     ${
                       !task.project_confirmation ? ", unconfirmed" : ""
                     }. Activate for more details.`}
        >
          {task.project_name}
          <div
            style={{
              backgroundColor: task.unavailable ? "lightgray" : task.card_color,
            }}
            aria-hidden="true"
            className="task-hours-container"
          >
            <div
              style={{
                backgroundColor: this.props.overbooked ? "#B82A23" : "#fff",
                color: this.props.overbooked ? "#fff" : "#000",
                fontSize: "13px",
                fontFamily: " Arial",
                fontWeight: "400",
              }}
            >
              {task.hours}
            </div>
            {(!task.project_confirmation ||
              task.remote ||
              !task.part_time_over_confirmed) && (
              <div
                style={{
                  backgroundColor: !task.part_time_over_confirmed
                    ? "#b83347"
                    : "C0C0C0",
                  color: !task.part_time_over_confirmed ? "white" : "black",
                }}
              >
                {!task.part_time_over_confirmed
                  ? "!"
                  : !task.project_confirmation
                  ? "?"
                  : "WFH"}
              </div>
            )}
          </div>
        </div>
        <div
          id={`task-${task.id}-bottom-drag-area`}
          style={{
            height: "0px",
            transition: "height 0.2s",
            backgroundColor: "lightgray",
            pointerEvents: "none",
          }}
        />
      </div>
    );
  }
}

export default Task;
