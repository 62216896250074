import React from "react";
import styled from "styled-components";
import moment from "moment";
import TimeEntryCard from "./time_entry_card";
import ClockIcon from "../icons/clock_icon";

export const EntryCardDiv = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: #00000014 0px 0px 2px 1px;
  border: solid 1px lightgray;
  width: calc(100% - 10px);
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin: 0px 10px 10px 0px;
  font-size: 14px;
  outline: none;

  p {
    font-size: 14px;
    max-width: 500px;
    min-width: 300px;
    margin-left: 10px;
    font-family: Manrope;
    font-weight: 500;
  }
  p > span {
    color: lightgray;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Arial;
    white-space: nowrap;
  }
  button {
    background: none;
    border: solid 1px lightgray;
    border-radius: 3px;
    cursor: pointer;
    line-height: 13px;
    padding: 2px 4px;
  }
  button:hover {
    background-color: #e1e1e1;
  }
  > div:nth-child(2) span {
    font-family: "Manrope";
    font-size: 21px;
    width: 50px;
    font-weight: 500;
  }
`;

class TimeEntryList extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { timeEntries } = this.props;
    let total = 0;
    if (timeEntries) {
      timeEntries.forEach((t) => (total += t.hours));
    }
    return (
      <div
        aria-label={`${moment(this.props.selectedDay).format(
          "dddd MMMM Do"
        )}, ${
          timeEntries && timeEntries.length > 0
            ? `${timeEntries.length} time ${
                timeEntries.length > 1 ? "entries" : "entry"
              } logged, totaling ${total.toFixed(2)} hours`
            : "No time entries logged"
        }`}
        role={timeEntries && timeEntries.length > 0 ? "list" : ""}
        style={{
          height: "100%",
          overflowY: "auto",
          maxHeight: "calc(100% - 280px)",
          marginRight: "10px",
          paddingLeft: "20px",
        }}
      >
        {timeEntries && timeEntries.length > 0 ? (
          this.props.timeEntries.map((t) => {
            return (
              <TimeEntryCard
                editTimeEntry={this.props.editTimeEntry}
                deleteEntry={this.props.deleteEntry}
                EntryCardDiv={EntryCardDiv}
                timeEntry={t}
                key={t.id}
              />
            );
          })
        ) : (
          <p
            aria-hidden="true"
            style={{ textAlign: "center", color: "#bfbfbf" }}
          >
            There are currently no time entries for this day.
          </p>
        )}
        {timeEntries && timeEntries.length > 0 && (
          <div
            aria-hidden="true"
            style={{
              display: "flex",
              alignItems: "center",
              width: "130px",
              margin: "18px 119px 0px auto",
            }}
          >
            <span style={{ fontSize: "14px", color: "#bfbfbf" }}>Total: </span>
            <ClockIcon
              height={20}
              width={20}
              color="#519acc"
              svgStyles={{ margin: "0px 5px" }}
            />
            <span
              style={{
                fontSize: "21px",
                fontWeight: "600",
                fontFamily: "Manrope",
              }}
            >
              {total.toFixed(2)}
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default TimeEntryList;
