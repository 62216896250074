import React, { useEffect, useState } from "react";
import styled from "styled-components";

const ClientCard = ({ client, onEditClientClick, loadClient }) => {
  const [tagPlusCount, setTagPlusCount] = useState(0);
  const [projectPlusCount, setProjectPlusCount] = useState(0);

  useEffect(() => {
    if (client && client.tags && client?.tags?.length > 3) {
      setTagPlusCount(client.tags.length - 3);
    } else {
      setTagPlusCount(0);
    }

    if (
      client &&
      client?.project_options &&
      client?.project_options?.length > 3
    ) {
      setProjectPlusCount(client.project_options.length - 4);
    }
  }, [client]);

  if (!client) {
    return null;
  }

  const handleEditClick = () => {
    onEditClientClick(client);
  };

  return (
    <CardDiv
      tabIndex={0}
      id={`client-card-${client.id}`}
      aria-label={`${client.name}. Year to date: ${client.year_to_date_hours} Hours logged`}
    >
      <ButtonOverlayContainer onClick={() => loadClient(client.id)}>
        <ButtonOverlay type="button" aria-label="open client" />
      </ButtonOverlayContainer>
      <div
        className="flex-container flex-al-center space-between"
        style={{ height: "19px" }}
      >
        <label
          aria-hidden
          style={{
            fontFamily: "Manrope",
            fontSize: "14px",
            fontWeight: 600,
          }}
          className="project-label"
        >
          {client.name}
        </label>
        <EditButton
          type="button"
          aria-label="edit client details"
          className="btn-flat"
          onClick={handleEditClick}
        >
          <EditIcon aria-hidden className="material-icons">
            more_vert
          </EditIcon>
        </EditButton>
      </div>

      <ListWrapper>
        {client?.project_options?.slice(0, 4).map((p, index) => (
          <ListItem key={`${p.id}-${index}`} aria-label={("project ", p.label)}>
            <span style={{ marginRight: "8px" }}>&#8226;</span>
            {p.label}
          </ListItem>
        ))}
        {client?.project_options?.length > 4 && (
          <ListItemExtra>
            <span style={{ marginRight: "6px" }}>+</span>
            {projectPlusCount} Projects
          </ListItemExtra>
        )}
      </ListWrapper>
      <TagWrapper id={`client-tag-group-${client.id}`}>
        {client?.tags?.length > 0 ? (
          client?.tags?.slice(0, 3).map((t, index) => {
            return (
              <Tag
                aria-label={`client tag ` + t.name}
                key={`${t.id}-${index}`}
                color={t.color}
              >
                {t.name}
              </Tag>
            );
          })
        ) : (
          <NoTagLabel>No Projects</NoTagLabel>
        )}
      </TagWrapper>
      <TagPlus
        aria-label={`Plus ` + tagPlusCount + ` tags`}
        id={`client-tag-plus-${client.id}`}
      >
        {tagPlusCount > 0 ? `+${tagPlusCount}` : ""}
      </TagPlus>
    </CardDiv>
  );
};

export default ClientCard;

const CardDiv = styled.div`
  width: 277px;
  height: 193px;
  padding: 16px 16px 0px;
  background-color: white;
  border: none;
  outline: none;
  border-radius: 10px;
  border: 1px solid #d7d7d7;
  margin-bottom: 4px;
  margin-right: 4px;
  position: relative;
  transition: background-color 0.4s, transform 0.4s;

  &:hover {
    border: 1px solid #519acc;
  }
`;
const ListWrapper = styled.ul`
  margin: 16px 0px;
  max-height: 96px;
  overflow: hidden;
`;
const ListItem = styled.li`
  font-family: Arial;
  font-size: 13px;
  font-weight: 400;
  padding-left: px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ListItemExtra = styled.li`
  font-family: Arial;
  font-size: 13px;
  font-weight: 400;
  color: #519acc;
  list-style: none;
`;

const NoListItem = styled.li`
  font-family: Arial;
  font-size: 13px;
  font-weight: 400;
  color: #c0c0c0;
`;

const EditButton = styled.button`
  z-index: 100;
  appearance: none;
  border: none;
  background: #fff;
  width: 20px;
  display: flex;
  color: #e7e7e7 !important;
  cursor: pointer;
  justify-content: center;
  transition: color 0.4s;
  padding: 0px;
  &:hover {
    color: #519acc !important;
  }
`;

const EditIcon = styled.i`
  &:hover {
    background: #fff;
  }
`;
const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  position: absolute;
  bottom: 15px;
  max-width: 237px;
  overflow: hidden;
  height: 23px;
  margin-left: -5px;
`;
const Tag = styled.span`
  background-color: ${({ color }) => color};
  padding: 4px 10px;
  border-radius: 20px;
  height: 23px;
  font-family: "Open Sans";
  font-size: 11px;
  font-weight: 600;
  color: white;
  white-space: nowrap;
`;

const NoTagLabel = styled.span`
  padding: 4px 10px;
  border-radius: 20px;
  border: 1px dashed #d7d7d7;
  width: 82px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  color: #c0c0c0;
  white-space: nowrap;
`;

const TagPlus = styled.span`
  position: absolute;
  right: 15px;
  bottom: 18px;
  color: #519acc;
`;

const ButtonOverlayContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 3;
  cursor: pointer;
`;

const ButtonOverlay = styled.button`
  height: 100%;
  width: 100%;
  position: absolute;
  background: transparent;
  border: none;
  padding: 0;
  top: 0px;
  left: 0px;
  z-index: 3;
  pointer-events: none;
`;
