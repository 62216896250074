import React from "react";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import { Select } from "react-materialize";

class CurrentBooking extends React.Component {
  constructor(props) {
    super(props);
    const disabledArray = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ];
    let disabled = false;
    let originalTimeIndex = 0;
    for (var x = 4; x < 40; x++) {
      if (this.props.data.selectedBooking.booking_array[x] === "1") {
        originalTimeIndex = x - 4;
        break;
      }
    }
    for (var x = 1; x < 12; x++) {
      if (disabled) {
        disabledArray[x - 1] = true;
      } else if (
        originalTimeIndex + x > this.props.data.bookingsArray.length - 1 ||
        this.props.data.bookingsArray[originalTimeIndex + x] === "1"
      ) {
        disabled = true;
        disabledArray[x - 1] = true;
      }
    }
    this.state = {
      edit: false,
      disabledArray,
      duration: this.props.data.selectedBooking.duration.toString(),
      loading: false,
    };
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleEditBooking = this.handleEditBooking.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.timeChange = this.timeChange.bind(this);
    this.timeArray = [
      "09:00",
      "09:15",
      "09:30",
      "09:45",
      "10:00",
      "10:15",
      "10:30",
      "10:45",
      "11:00",
      "11:15",
      "11:30",
      "11:45",
      "12:00",
      "12:15",
      "12:30",
      "12:45",
      "13:00",
      "13:15",
      "13:30",
      "13:45",
      "14:00",
      "14:15",
      "14:30",
      "14:45",
      "15:00",
      "15:15",
      "15:30",
      "15:45",
      "16:00",
      "16:15",
      "16:30",
      "16:45",
      "17:00",
      "17:15",
      "17:30",
      "17:45",
    ];
  }

  componentDidMount() {
    const modal = document.getElementsByClassName("new-modal");
    modal[0].style.width = "70%";
    modal[0].style.minHeight = "300px";

    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  }

  handleDeleteClick() {
    Swal.fire({
      title: "",
      text: "Are you sure you want to delete this booking?",
      type: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`/bookings/${this.props.data.selectedBooking.id}.json`)
          .then((res) => {
            M.toast({
              html: `Booking deleted`,
              displayLength: 3000,
              classes: "green",
            });
            this.props.refreshBookings();
            this.props.modalAction(false, "edit-room-view-booking", {});
          })
          .catch((err) => console.error(err));
      }
    });
  }

  handleEditClick() {
    const modal = document.getElementsByClassName("new-modal");
    modal[0].style.width = "50%";
    modal[0].style.minHeight = "400px";
    this.setState({ edit: true });
  }

  handleEditBooking() {
    this.setState({
      loading: true,
    });
    this.refs.save_button.disabled = true;
    this.refs.cancel_button.disabled = true;
    axios
      .patch(`/bookings/${this.props.data.selectedBooking.id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        booking: {
          duration: parseInt(this.state.duration),
        },
      })
      .then((res) => {
        M.toast({ html: `Booking updated`, classes: "green" });
        this.setState({ loading: false });
        this.props.refreshBookings();
        this.props.modalAction(false, "", {});
      })
      .catch((err) => {
        M.toast({
          html: `There was an error. Please try again.`,
          classes: "green",
        });
        this.setState({ loading: false });
        this.refs.save_button.disabled = false;
        this.refs.cancel_button.disabled = false;
        console.error(err);
      });
  }

  timeChange(e) {
    this.setState({
      duration: e.target.value,
    });
  }

  handleCancelClick() {
    this.props.modalAction(false, "", {});
  }

  render() {
    let time = this.props.data.selectedBooking.booking_date.split(".");
    time = time[0];
    if (this.state.edit) {
      return (
        <div className="row modal-content">
          {this.state.loading ? (
            <div id="book-room-loading-overlay">
              <div className="book-room-loading-spinner" />
            </div>
          ) : (
            ""
          )}
          <div className="col s12" id="add-room-view-booking-container">
            <p
              className="bold"
              style={{ textAlign: "center", fontSize: "30px" }}
            >
              <span id="task_creator_p">
                Edit {this.props.data.selectedBooking.room.name} Booking
              </span>
            </p>
            <div>
              <Select
                style={{ width: "100px" }}
                className="select"
                value={this.state.duration}
                onChange={this.timeChange}
              >
                <option value="15">15 Minutes</option>
                <option disabled={this.state.disabledArray[0]} value="30">
                  30 Minutes
                </option>
                <option disabled={this.state.disabledArray[1]} value="45">
                  45 Minutes
                </option>
                <option disabled={this.state.disabledArray[2]} value="60">
                  60 Minutes
                </option>
                <option disabled={this.state.disabledArray[3]} value="75">
                  1 Hour 15 Minutes
                </option>
                <option disabled={this.state.disabledArray[4]} value="90">
                  1 Hour 30 Minutes
                </option>
                <option disabled={this.state.disabledArray[5]} value="105">
                  1 Hour 45 Minutes
                </option>
                <option disabled={this.state.disabledArray[6]} value="120">
                  2 Hours
                </option>
                <option disabled={this.state.disabledArray[7]} value="135">
                  2 Hours 15 Minutes
                </option>
                <option disabled={this.state.disabledArray[8]} value="150">
                  2 Hours 30 Minutes
                </option>
                <option disabled={this.state.disabledArray[9]} value="165">
                  2 Hours 45 Minutes
                </option>
                <option disabled={this.state.disabledArray[10]} value="180">
                  3 Hours
                </option>
              </Select>
            </div>
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <div
                className="btn"
                ref="save_button"
                onClick={this.handleEditBooking}
                style={{
                  marginRight: "80px",
                  width: "100px",
                  height: "40px",
                  fontSize: "18px",
                  lineHeight: "40px",
                }}
              >
                Save
              </div>
              <div
                className="btn"
                ref="cancel_button"
                onClick={this.handleCancelClick}
                style={{
                  width: "100px",
                  height: "40px",
                  fontSize: "18px",
                  lineHeight: "40px",
                }}
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="row modal-content">
        <div className="col s12">
          <p className="bold" style={{ textAlign: "center", fontSize: "20px" }}>
            {this.props.data.selectedBooking.user.name} has this room booked
            from
            {moment(time).format("h:mm")} to{" "}
            {moment(time)
              .add(this.props.data.selectedBooking.duration, "minutes")
              .format("h:mm")}
            .
            <span id="task_creator_p" />
          </p>
          <p className="bold" style={{ marginTop: "30px", fontSize: "16px" }}>
            Notes:{" "}
            {this.props.data.selectedBooking.description
              ? this.props.data.selectedBooking.description
              : "none"}
            <span id="task_notes_p" />
          </p>
          {this.props.data.selectedBooking.user.name === "Room View User" ? (
            <div style={{ textAlign: "center", marginTop: "60px" }}>
              <div
                className="btn"
                onClick={this.handleEditClick}
                style={{
                  marginRight: "80px",
                  width: "100px",
                  height: "40px",
                  fontSize: "18px",
                  lineHeight: "40px",
                }}
              >
                Edit
              </div>
              <div
                className="btn"
                onClick={this.handleDeleteClick}
                style={{
                  width: "100px",
                  height: "40px",
                  fontSize: "18px",
                  lineHeight: "40px",
                }}
              >
                Delete
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default CurrentBooking;
