import React from "react";
import PropTypes from "prop-types";
import ClientCard from "../../clients/client_card.jsx";
import ProjectCard from "../../projects/project_card.jsx";

class InactiveCardGroup extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{ paddingLeft: "4px" }}>
        <h4
          style={{
            display: "flex",
            alignItems: "center",
            margin: "5px 0px 20px",
          }}
        >
          <div
            style={{
              color: "#313131",
              whiteSpace: "nowrap",
              fontWeight: "600",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "20px",
              fontSize: "18px",
            }}
          >
            {this.props.heading}
          </div>
          <div
            style={{
              height: "1px",
              flex: "1",
              marginRight: "30px",
              backgroundColor: "lightgray",
            }}
          />
        </h4>
        <div className="inactive-cards">
          {this.props.type === "client"
            ? this.props.clients.map((c, i) => {
              return (
                <ClientCard
                  onEditClientClick={this.props.onEditClientClick}
                  loadClient={this.props.loadClient}
                  cardDiv={this.props.cardDiv}
                  client={c}
                  key={i}
                />
              );
            })
            : this.props.projects.map((p, i) => {
              return (
                <ProjectCard
                  loadProject={this.props.loadProject}
                  cardDiv={this.props.cardDiv}
                  project={p}
                  key={i}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

export default InactiveCardGroup;
