import React from "react";

const UserIconAccount = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
    >
      <path
        d="M14 0.5C15.8565 0.5 17.637 1.2375 18.9497 2.55025C20.2625 3.86301 21 5.64348 21 7.5C21 9.35652 20.2625 11.137 18.9497 12.4497C17.637 13.7625 15.8565 14.5 14 14.5C12.1435 14.5 10.363 13.7625 9.05025 12.4497C7.7375 11.137 7 9.35652 7 7.5C7 5.64348 7.7375 3.86301 9.05025 2.55025C10.363 1.2375 12.1435 0.5 14 0.5ZM14 28.5C14 28.5 28 28.5 28 25C28 20.8 21.175 16.25 14 16.25C6.825 16.25 0 20.8 0 25C0 28.5 14 28.5 14 28.5Z"
        fill="url(#paint0_linear_1600_3338)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1600_3338"
          x1="14"
          y1="0.5"
          x2="14"
          y2="28.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default UserIconAccount;
