import React from "react";
import MeetingIcon from "../icons/meeting_icon.jsx";
import SingleSelectWrapper from "../common/single_select_wrapper";
import axios from "axios";
import { SingleDatePicker } from "react-dates";
import RoundDropdown from "../projects/round_dropdown.jsx";
import CalendarIcon from "../icons/calendar_icon.jsx";
import styled from "styled-components";
import NumberCounter from "../common/number_counter";
import Swal from "sweetalert2";
import moment from "moment";
import ExitIcon from "../icons/exit_icon.jsx";

class FindRoom extends React.Component {
  constructor(props) {
    super(props);
    const start = moment();
    const remainder = start.minute() % 15;
    const currentTime = moment(start)
      .subtract(remainder, "minutes")
      .format("HH:mm");
    var currentTimeIndex = 0;
    var timeOptions = [];
    var incrementingTime = moment("1987-02-22 08:00:00");
    for (var x = 0; x < 41; x++) {
      timeOptions.push({
        label: moment(incrementingTime).format("h:mm A"),
        value: moment(incrementingTime).format("HH:mm:ss"),
        timeIndex: x,
      });
      incrementingTime.add(15, "minutes");
      if (moment(incrementingTime).format("HH:mm") === currentTime) {
        currentTimeIndex = x + 1;
      }
    }
    var users = this.props.data.users.map((u) => ({
      label: u.name,
      value: u.id,
    }));
    if (
      users.map((u) => u.value).indexOf(this.props.data.currentUser.id) === -1
    ) {
      users = [
        {
          label: this.props.data.currentUser.name,
          value: this.props.data.currentUser.id,
        },
        ...users,
      ];
    } else if (
      this.props.data.booking &&
      users.map((u) => u.value).indexOf(this.props.data.booking.user.id) === -1
    ) {
      users = [
        {
          label: this.props.data.booking.user.name,
          value: this.props.data.booking.user.id,
        },
        ...users,
      ];
    }
    this.state = {
      owner: {
        label: this.props.data.currentUser.name,
        value: this.props.data.currentUser.id,
      },
      timeOptions,
      startTime: this.props.data.selectedTimeIndex
        ? timeOptions[this.props.data.selectedTimeIndex]
        : timeOptions[currentTimeIndex],
      endTime: this.props.data.selectedTimeIndex
        ? timeOptions[this.props.data.selectedTimeIndex + 1]
        : timeOptions[currentTimeIndex + 1],
      attendees: 2,
      client: false,
      currentTimeIndex,
      bookingDate: moment(this.props.data.currentDay),
      datePickerFocus: false,
      roomResults: [],
      users,
      searchMode: true,
    };
    this.roomUrls = {
      "Crater Lake":
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/syncit/crater.jpg",
      "Green room":
        "https://syncit.sfo2.cdn.digitaloceanspaces.com/Rooms/Green%20Room.jpg",
      "Grey room":
        "https://syncit.sfo2.cdn.digitaloceanspaces.com/Rooms/Grey%20Room.jpg",
      "Orange room":
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/syncit/room-view-card-orange.jpg",
      "Red room":
        "https://syncit.sfo2.cdn.digitaloceanspaces.com/Rooms/Red%20Room.jpg",
      "Yellow room":
        "https://syncit.sfo2.cdn.digitaloceanspaces.com/Rooms/Yellow%20Room.jpg",
      "Blue room":
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/syncit/room-view-card-blue.jpg",
      "Sci-Fi Room":
        "https://syncit.sfo2.cdn.digitaloceanspaces.com/Rooms/Sci-Fi%20Room.jpg",
    };
    this.searchRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      var elem = document.querySelector(".find-room-modal");
      if (elem) {
        elem.classList.add("transition");
      }
    }, 300);
  }

  componentWillUnmount() {
    var elem = document.querySelector(".find-room-modal");
    if (elem) {
      elem.classList.remove("transition");
      elem.style.removeProperty("overflow");
    }
  }

  handleOwnerChange = (owner) => {
    this.setState({ owner });
  };
  handleStartChange = (startTime) => {
    this.setState({ startTime }, () => {
      var startTime = moment(`1987-02-22 ${this.state.startTime.value}`);
      var endTime = moment(`1987-02-22 ${this.state.endTime.value}`);
      if (startTime.isSameOrAfter(endTime)) {
        this.setState({
          endTime: this.state.timeOptions[this.state.startTime.timeIndex + 1],
        });
      }
    });
  };

  handleEndChange = (endTime) => {
    this.setState({ endTime }, () => {
      var startTime = moment(`1987-02-22 ${this.state.startTime.value}`);
      var endTime = moment(`1987-02-22 ${this.state.endTime.value}`);
      if (endTime.isSameOrBefore(startTime)) {
        this.setState({
          startTime: this.state.timeOptions[this.state.endTime.timeIndex - 1],
        });
      }
    });
  };

  handleAttendeesChange = (attendees) => {
    this.setState({ attendees });
  };

  handleClientChange = () => {
    this.setState({ client: !this.state.client });
  };

  closeModal = () => {
    this.props.modalAction(false, "", {});
  };

  datePickerClick = () => {
    this.setState({ datePickerFocus: true });
  };

  onDateFocusChange = ({ focused }) => {
    this.setState({ datePickerFocus: focused });
  };

  isDayBlocked = (day) => {
    return moment(day).day() === 0 ||
      moment(day).day() === 6 ||
      moment(day) < moment().subtract(1, "day")
      ? true
      : false;
  };

  handleDateChange = (date) => {
    this.setState({ bookingDate: date });
  };

  handleSearch = () => {
    this.props.setBackdropLoader(true);
    axios
      .post("/bookings/find_room.json", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        booking_date: this.state.bookingDate,
        attendees: this.state.attendees,
        client: this.state.client,
        start_index: this.state.startTime.timeIndex,
        end_index: this.state.endTime.timeIndex,
      })
      .then((res) => {
        var elem = document.querySelector(".find-room-modal");
        if (elem && this.searchRef.current) {
          this.searchRef.current.style.opacity = "0";
          elem.style.overflow = "hidden";
          setTimeout(() => {
            this.setState({ roomResults: res.data, searchMode: false }, () => {
              elem.classList.add("results");
              setTimeout(() => {
                this.props.setBackdropLoader(false);
                this.searchRef.current.style.opacity = "1";
              }, 300);
            });
          }, 300);
        }
      })
      .catch((err) => {
        this.props.setBackdropLoader(false);
        console.error(err);
      });
  };

  handleSubmit = (e) => {
    var duration = moment
      .duration(
        moment(`1987-02-22 ${this.state.endTime.value}`).diff(
          moment(`1987-02-22 ${this.state.startTime.value}`)
        )
      )
      .asMinutes();
    var room_id = e.target.value;
    axios
      .post(`/bookings.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        booking: {
          user_id: this.state.owner.value,
          duration: duration,
          name: this.state.name,
          booking_date: moment(
            moment(this.state.bookingDate).format("YYYY/MM/DD") +
              " " +
              this.state.startTime.value
          ).format("YYYY/MM/DD HH:mm:ss"),
          meeting_type: this.state.client ? "client" : "team",
          description: "",
          name: "",
          room_id,
          attendees: this.state.attendees,
        },
        multi_booking: false,
      })
      .then((res) => {
        M.toast({ html: `Booking created`, classes: "green" });
        if (
          moment(this.state.bookingDate).format("YYYY/MM/DD") ===
          moment(this.props.data.currentDay).format("YYYY/MM/DD")
        ) {
          this.props.data.bookingHandler(res.data);
        }
        this.props.modalAction(false, "", {});
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });
  };

  handleBackToSearch = () => {
    var elem = document.querySelector(".find-room-modal");
    if (elem && this.searchRef.current) {
      this.searchRef.current.style.opacity = "0";
      setTimeout(() => {
        elem.classList.remove("results");
        setTimeout(() => {
          this.setState({ roomResults: [], searchMode: true }, () => {
            this.searchRef.current.style.opacity = "1";
            elem.style.removeProperty("overflow");
          });
        }, 300);
      }, 300);
    }
  };

  handleDisabledStartTime = (o) => {
    return o.timeIndex === 40;
  };

  handleDisabledEndTime = (o) => {
    return o.timeIndex === 0;
  };

  render() {
    return (
      <div
        style={{
          padding: "32px",
          maxHeight: "90vh",
          overflow: "visible",
          width: "448px",
        }}
        className="modal-content"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <MeetingIcon
            svgStyles={{ width: "50px", height: "50px", marginRight: "10px" }}
          />
          <h2
            style={{
              margin: "0px",
              fontSize: "18px",
              fontFamily: "Manrope",
              fontWeight: "500",
              color: "#313131",
            }}
          >
            <span
              style={{
                fontWeight: "600",
                marginLeft: "16px",
                fontFamily: "manrope",
                fontSize: "18px",
              }}
            >
              Find a conference room
            </span>
          </h2>
          <button
            type="button"
            id="modalClose"
            aria-label="Close Modal"
            className="btn-icon"
            onClick={this.closeModal}
            style={{ position: "absolute", top: "41px", right: "26px" }}
          >
            <ExitIcon
              svgStyles={{ pointerEvents: "none" }}
              height="25px"
              width="25px"
            />
          </button>
        </div>
        <div
          style={{
            overflow: "visible",
            opacity: "1",
            transition: "opacity 0.3s",
            maxHeight: "calc(90vh - 90px)",
          }}
          ref={this.searchRef}
        >
          {this.state.searchMode ? (
            <div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "nowrap",
                  margin: "32px 0 16px",
                }}
              >
                <label
                  style={{
                    position: "relative",
                    top: "17px",
                    boxShadow: "none",
                    backdropFilter: "none",
                    WebkitBackdropFilter: "none",
                    transform: "0px !important",
                  }}
                >
                  <input
                    checked={this.state.client}
                    onChange={this.handleClientChange}
                    className="filled-in"
                    type="checkbox"
                  />
                  <span
                    style={{
                      lineHeight: "26px",
                      paddingLeft: "26px",
                      fontFamily: "Manrope",
                      fontSize: "13px",
                      fontWeight: "600",
                    }}
                  >
                    <span style={{ position: "relative", top: "-3px" }}>
                      {" "}
                      This is a client meeting
                    </span>
                  </span>
                </label>
              </div>
              <div
                style={{ width: "100%", display: "flex", flexWrap: "nowrap" }}
              >
                <div
                  style={{
                    width: "67%",
                    paddingRight: "16px",
                  }}
                >
                  <label className="custom-labels">Meeting Owner</label>
                  <SingleSelectWrapper
                    options={this.state.users}
                    onChange={this.handleOwnerChange}
                    isDisabled={this.props.data.disabled}
                    placeholder={"Select User"}
                    ariaLabel="Meeting Owner Dropdown"
                    value={this.state.owner}
                    customStyles={{
                      container: (styles) => ({
                        ...styles,
                        width: "100%",
                        marginTop: "4px",
                        height: "39px !important",
                      }),
                      menu: (styles) => ({ ...styles, width: "100%" }),
                    }}
                  />
                </div>
                <div>
                  <div
                    id="start-date-container"
                    style={{ position: "relative" }}
                  >
                    <label
                      style={{ display: "block", marginBottom: "4px" }}
                      className="custom-labels"
                    >
                      Meeting Date
                    </label>
                    <div
                      style={{
                        position: "absolute",
                        left: "16px",
                        zIndex: 1,
                        top: "34px",
                      }}
                    >
                      <CalendarIcon
                        width="22px"
                        height="22px"
                        color="#519acc"
                      />
                    </div>
                    <SingleDatePicker
                      date={this.state.bookingDate} // momentPropTypes.momentObj or null
                      onDateChange={this.handleDateChange} // PropTypes.func.isRequired
                      focused={this.state.datePickerFocus} // PropTypes.bool
                      onFocusChange={this.onDateFocusChange} // PropTypes.func.isRequired
                      numberOfMonths={1}
                      placeholder={"Select a date"}
                      orientation={"horizontal"}
                      daySize={30}
                      navPrev={
                        <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                          <RoundDropdown rotate={"rotate(90deg)"} />
                        </div>
                      }
                      navNext={
                        <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                          <RoundDropdown rotate={"rotate(-90deg)"} />
                        </div>
                      }
                      hideKeyboardShortcutsPanel
                      inputIconPosition={"after"}
                      customInputIcon={<RoundDropdown />}
                      isOutsideRange={this.isDayBlocked}
                      id="single_date_picker" // PropTypes.string.isRequired,
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "nowrap",
                  marginTop: "16px",
                }}
              >
                <div style={{ display: "flex", flexWrap: "nowrap" }}>
                  <div style={{ paddingRight: "8px" }}>
                    <label>
                      Start Time <span style={{ color: "#519acc" }}>*</span>
                    </label>
                    <SingleSelectWrapper
                      classNamePrefix="react-select"
                      options={this.state.timeOptions}
                      onChange={this.handleStartChange}
                      ariaLabel="Meeting Start Time Dropdown"
                      value={this.state.startTime}
                      isDisabled={this.props.data.disabled}
                      isOptionDisabled={this.handleDisabledStartTime}
                      customStyles={{
                        container: (styles) => ({
                          ...styles,
                          width: "120px",
                          marginTop: "4px",
                        }),
                        menu: (styles) => ({ ...styles, width: "100%" }),
                      }}
                    />
                  </div>
                  <div style={{ paddingRight: "16px" }}>
                    <label>
                      End Time <span style={{ color: "#519acc" }}>*</span>
                    </label>
                    <SingleSelectWrapper
                      classNamePrefix="react-select"
                      options={this.state.timeOptions}
                      onChange={this.handleEndChange}
                      ariaLabel="Meeting End Time Dropdown"
                      isDisabled={this.props.data.disabled}
                      value={this.state.endTime}
                      isOptionDisabled={this.handleDisabledEndTime}
                      customStyles={{
                        container: (styles) => ({
                          ...styles,
                          width: "120px",
                          marginTop: "4px",
                        }),
                        menu: (styles) => ({ ...styles, width: "100%" }),
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    position: "relative",
                  }}
                >
                  <div>
                    <span
                      style={{
                        fontFamily: "manrope",
                        fontWeight: "600",
                        fontSize: "13px",
                      }}
                    >
                      Attendees
                    </span>
                    <div style={{ marginTop: "8px" }}>
                      <NumberCounter
                        disabled={this.props.data.disabled}
                        min={1}
                        max={this.props.data.roomCapacity}
                        value={this.state.attendees}
                        step={1}
                        updateValue={this.handleAttendeesChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "32px" }} className="right-align">
                <button
                  type="button"
                  name="cancel"
                  value="Cancel"
                  onClick={this.closeModal}
                  className="common-button-cancel"
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  name="create"
                  value="Create"
                  onClick={this.handleSearch}
                  className="common-button-submit"
                >
                  Find a Room
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div
                className="flex-container flex-al-center"
                style={{
                  height: "22px",
                  margin: "16px 0px 0px",
                }}
              >
                <button
                  style={{
                    padding: 0,
                    fontFamily: "Arial",
                    fontWeight: 400,
                    fontSize: "13px",
                  }}
                  className="btn-flat"
                  onClick={this.handleBackToSearch}
                >
                  Back to Search
                </button>
              </div>
              <p
                style={{
                  fontFamily: "Manrope",
                  fontSize: "16px",
                  fontWeight: "600",
                  marginBottom: "0px",
                  marginTop: "22px",
                }}
              >
                Here's what we found for...
              </p>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexWrap: "nowrap",
                  marginTop: "16px",
                  marginBottom: "32px",
                }}
              >
                <div>
                  <p
                    style={{
                      fontWeight: "600",
                      fontFamily: "manrope",
                      fontSize: "13px",
                      marginBottom: "8px",
                      marginTop: "0px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Meeting Owner:
                  </p>
                  <FindRoomLabel>{this.state.owner.label}</FindRoomLabel>
                </div>
                <div style={{ paddingLeft: "20px" }}>
                  <p
                    style={{
                      fontWeight: "600",
                      fontFamily: "manrope",
                      fontSize: "13px",
                      marginBottom: "8px",
                      marginTop: "0px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Meeting Time:
                  </p>
                  <FindRoomLabel>
                    {`${moment(this.state.bookingDate).format("MM/DD/YYYY")} ${
                      this.state.startTime.label
                    } - ${this.state.endTime.label}`}{" "}
                  </FindRoomLabel>
                </div>
                <div style={{ paddingLeft: "20px" }}>
                  <p
                    style={{
                      fontWeight: "600",
                      fontFamily: "manrope",
                      fontSize: "13px",
                      marginBottom: "8px",
                      marginTop: "0px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Meeting Type:
                  </p>
                  <FindRoomLabel>
                    {this.state.client ? "Client" : "Team"}
                  </FindRoomLabel>
                </div>
              </div>
              <div
                style={{
                  maxHeight: "calc(90vh - 323px)",
                  overflow: "auto",
                  padding: "6px 14px 6px 10px",
                }}
              >
                {this.state.roomResults.length > 0 ? (
                  this.state.roomResults.map((r) => {
                    return (
                      <div
                        key={r.id}
                        style={{
                          width: "100%",
                          height: "270px",
                          marginBottom: "12px",
                          boxShadow: "rgb(216 216 216) 0px 0px 4px 3px",
                        }}
                      >
                        <div
                          style={{
                            height: "200px",
                            position: "relative",
                            width: "100%",
                            backgroundSize: "100% auto",
                            backgroundPositionY: "center",
                            backgroundImage:
                              "url(" + this.roomUrls[r.name] + ")",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              right: "14px",
                              bottom: "12px",
                              backgroundColor: "#519acc",
                              color: "white",
                              padding: "4px 6px",
                              borderRadius: "10px",
                            }}
                          >
                            {r.name}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ padding: "14px 10px" }}>
                            <p
                              style={{
                                fontWeight: "600",
                                margin: "0px",
                                fontSize: "13px",
                                fontFamily: "manrope",
                              }}
                            >
                              Features:
                            </p>
                            <p
                              style={{
                                margin: "0px",
                                fontSize: "13px",
                                fontFamily: "arial",
                                fontWeight: 400,
                              }}
                            >
                              {r.capacity} Attendees{r.tv && ", TV"}
                              {r.table && ", Table"}
                              {r.allow_client && ", Client Friendly"}
                            </p>
                          </div>
                          <button
                            onClick={this.handleSubmit}
                            value={r.id}
                            style={{ marginRight: "10px" }}
                            className="common-button-submit"
                          >
                            Book This Room
                          </button>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p style={{ color: "#989898" }}>No search results found</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const FindRoomLabel = styled.span`
  font-family: Arial;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
`;
export default FindRoom;
