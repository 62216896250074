import React, { useState, useEffect, useMemo, useCallback } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { useLocation } from "react-use";
import HelpContext from "./HelpContext";
import HelpMain from "./HelpMain";
import HelpDetail from "./HelpDetail";

const axiosHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const Help = (props) => {
  const {
    currentUser,
    allTopics: initialTopics,
    pages: initialPages,
    tags: initialTags,
  } = props;

  const [pages, setPages] = useState(initialPages);
  const [allTopics, setAllTopics] = useState(initialTopics);
  const [search, setSearch] = useState("");
  const [tags, setTags] = useState(initialTags);
  const [isEditing, setIsEditing] = useState(false);

  const location = useLocation();
  const currentPageId = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const p = searchParams.get("p");
    if (p === null) {
      return null;
    }
    return parseInt(p, 10) || null;
  }, [location]);

  const setCurrentPage = useCallback((id) => {
    const url = new URL(window.location);
    if (id === null || !typeof id === "number") {
      url.searchParams.delete("p");
    } else {
      url.searchParams.set("p", id);
    }
    window.history.pushState(window.history.state, null, url.toString());
  }, []);

  const activePage = useMemo(() => {
    if (currentPageId === null || Number.isNaN(currentPageId)) {
      return null;
    }

    const page = pages.find((p) => p.id === currentPageId);
    if (page) {
      return page;
    }

    console.error(`Could not find page with id ${currentPageId}`);

    setCurrentPage(null);
    return null;
  }, [pages, setCurrentPage, currentPageId]);

  const updateHelpFromResponse = useCallback(
    (resp) => {
      setPages(resp.pages);
      setCurrentPage(resp.activePage.id);
      setTags(resp.tags);
      setAllTopics(resp.allTopics);
    },
    [setCurrentPage]
  );

  const handleCreate = useCallback(() => {
    try {
      axios.post("/help.json", { headers: axiosHeaders }).then((res) => {
        const resp = res.data;
        updateHelpFromResponse(resp);
        setIsEditing(true);
      });
    } catch (err) {
      console.error(err);
    }
  }, [updateHelpFromResponse]);

  const handleDelete = useCallback(() => {
    try {
      axios
        .delete(`/help/${activePage.id}.json`, { headers: axiosHeaders })
        .then((res) => {
          const resp = res.data;
          updateHelpFromResponse(resp);
          setIsEditing(false);
        });
    } catch (err) {
      console.error(err);
    }
  }, [activePage, updateHelpFromResponse]);

  const patchHelpPage = useCallback(
    (formData) => {
      try {
        axios
          .post(`/help/${activePage.id}.json`, {
            headers: axiosHeaders,
            help_page: formData,
          })
          .then((res) => {
            const resp = res.data;
            updateHelpFromResponse(resp);
            setIsEditing(false);
          });
      } catch (err) {
        console.error(err);
      }
    },
    [activePage, updateHelpFromResponse]
  );

  const toggleEditMode = useCallback(() => {
    if (activePage.title + activePage.tags + activePage.topic === "") {
      Swal.fire({
        title: `Discard new help page`,
        text: "Are you sure that you want to discard this page?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          handleDelete();
          // eslint-disable-next-line no-undef
          M.toast({
            html: "Page Removed",
            displayLength: 3000,
            classes: "green",
          });
        }
      });
    } else {
      setIsEditing(!isEditing);
    }
  }, [activePage, isEditing, handleDelete]);

  const deleteClick = useCallback(() => {
    Swal.fire({
      title: `Discard help page`,
      text: "Are you sure that you want to discard this page?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        handleDelete();
        // eslint-disable-next-line no-undef
        M.toast({
          html: "Page Removed",
          displayLength: 3000,
          classes: "green",
        });
      }
    });
  }, [handleDelete]);

  const getHelp = useCallback(() => {
    try {
      axios.get("/help", { headers: axiosHeaders }).then((res) => {
        const resp = res.data;
        updateHelpFromResponse(resp);
      });
    } catch (err) {
      console.error(err);
    }
  }, [updateHelpFromResponse]);

  const updateSearch = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  const handleBack = useCallback(() => {
    setCurrentPage(null);
  }, [setCurrentPage]);

  const viewMore = useCallback(
    (topic) => {
      try {
        const firstPage = pages.find((p) => p.topic === topic);
        if (firstPage) {
          setCurrentPage(firstPage.id);
        } else {
          throw new Error("No help pages for this topic");
        }
      } catch (err) {
        console.error(err);
      }
    },
    [pages, setCurrentPage]
  );

  const HelpContextValue = useMemo(() => {
    return {
      currentUser,
      activePage,
      pages,
      tags,
      allTopics,
      search,
      handleCreate,
      handleDelete,
      patchHelpPage,
      handleBack,
      getHelp,
      viewMore,
      updateSearch,
      toggleEditMode,
      deleteClick,
      setCurrentPage,
      isEditing,
    };
  }, [
    currentUser,
    activePage,
    pages,
    tags,
    allTopics,
    search,
    handleCreate,
    handleDelete,
    patchHelpPage,
    handleBack,
    getHelp,
    viewMore,
    updateSearch,
    toggleEditMode,
    deleteClick,
    setCurrentPage,
    isEditing,
  ]);

  useEffect(() => {
    document.title = "Syncit - Help";
  }, []);

  return (
    <HelpContext.Provider value={HelpContextValue}>
      <div className="help-page-wrapper">
        <div className="modal-content" id="help-container">
          {activePage === null && <HelpMain />}
          {activePage !== null && <HelpDetail />}
        </div>
      </div>
    </HelpContext.Provider>
  );
};

export default Help;
