import React from "react";
import axios from "axios";
import Select, { components } from "react-select";
import MultiSelectStyles from "../common/multi_select_styles";
import ClosureModalIcon from "components/icons/closure_modal_icon";
import ExitIcon from "components/icons/exit_icon";

class AddFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      selectedUsers: [],
      filterName: this.props.data.filter ? this.props.data.filter.name : "",
      dropHeight: 119,
      loading: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.getHeight = this.getHeight.bind(this);
    this.deleteFilter = this.deleteFilter.bind(this);
    this.labelInput = (props) => (
      <components.Input {...props} maxLength={20} height={"auto"} />
    );
    this.customControl = (props) => {
      return (
        <div>
          <p className="custom-select-input">{props.selectProps.inputValue}</p>
          <components.Control {...props} />
        </div>
      );
    };
    this.DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <i
            className="material-icons base-color-hover"
            style={{ color: "inherit" }}
          >
            search
          </i>
        </components.DropdownIndicator>
      );
    };
  }

  componentDidMount() {
    var self = this;
    this.setState({ loading: true });
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };

    axios
      .get("/api/v1/filter_users", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var users = res.data.map((user) => {
          return { value: user.id, label: user.name };
        });
        if (self.props.data.filter) {
          var selected = users.filter((x) =>
            self.props.data.filter.user_ids.includes(x.value)
          );

          this.setState(
            {
              selectedUsers: selected,
              users: users,
              loading: false,
            },
            () => {
              this.getHeight();
            }
          );
        } else {
          this.setState({
            users: users,
            loading: false,
          });
        }
      })
      .catch((err) => console.error(err));
  }

  getHeight() {
    var elem = document.getElementsByClassName(
      "react-multi-select__value-container react-multi-select__value-container--is-multi react-multi-select__value-container--has-value"
    );
    if (elem && elem[0]) {
      var height = elem[0].clientHeight + 119;
    }
    this.setState({ dropHeight: height });
  }

  handleSubmit(e) {
    e.preventDefault();
    var name = this.state.filterName;
    var url, object, action;
    if (this.props.data.type === "user") {
      object = "filter";
      if (this.props.data.filter) {
        url = `api/v1/filters/${this.props.data.filter.id}.json`;
        action = "patch";
      } else {
        url = "api/v1/filters.json";
        action = "post";
      }
    } else {
      object = "team_filter";
      if (this.props.data.filter) {
        url = `api/v1/team_filters/${this.props.data.filter.id}.json`;
        action = "patch";
      } else {
        url = "api/v1/team_filters.json";
        action = "post";
      }
    }
    axios[action](url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      [`${object}`]: {
        name: name,
        role_id: 1,
        user_id: this.props.data.filter
          ? this.props.data.filter.user_id
          : this.props.data.currentUser.id,
        user_ids: this.state.selectedUsers.map((user) => user.value),
      },
    })
      .then((res) => {
        M.toast({
          html: `${res.data.name} ${
            this.props.data.edit ? "updated" : "created"
          }!`,
          displayLength: 3000,
          classes: "green",
        });
        this.props.data.handler(this.props.data.type);
        this.props.modalAction(false, "", {});
      })
      .catch((err) => {
        console.error(err);
        if (err.response.status === 500) {
          M.toast({
            html: `${err.response.data}`,
            displayLength: 3000,
            classes: "red",
          });
        }
      });
  }

  deleteFilter(e) {
    e.preventDefault();
    var name = this.state.filterName;
    var url, object;
    if (this.props.data.type === "user") {
      object = "filter";
      url = `api/v1/filters/${this.props.data.filter.id}.json`;
    } else {
      object = "team_filter";
      url = `api/v1/team_filters/${this.props.data.filter.id}.json`;
    }
    axios
      .delete(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        M.toast({
          html: `${res.data.name} deleted!`,
          displayLength: 3000,
          classes: "green",
        });
        this.props.data.handler(this.props.data.type);
        this.props.modalAction(false, "", {});
      })
      .catch((err) => {
        console.error(err);
        if (err.response.status === 500) {
          M.toast({
            html: `${err.response.data}`,
            displayLength: 3000,
            classes: "red",
          });
        }
      });
  }

  handleSelectChange = (e, meta) => {
    this.setState(
      {
        selectedUsers: e ? e : [],
      },
      () => {
        this.getHeight();
      }
    );
  };

  handleInputChange = (e) => {
    var name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };

  closeModal = (e) => {
    this.props.modalAction(false, "", {});
  };

  render() {
    return (
      <div
        className="modal-content filter-modal"
        style={{
          transition: "max-height .6s",
          maxHeight: this.state.loading ? "300px" : "1000px",
        }}
      >
        <div className="flex-container flex-al-center flex-j-start">
          <ClosureModalIcon />
          <h4
            style={{
              fontWeight: 600,
              fontFamily: "Manrope",
              fontSize: "18px",
              marginLeft: "16px",
            }}
          >
            {this.props.data.edit ? "Edit a" : "Create a new"}{" "}
            {this.props.data.type.charAt(0) + this.props.data.type.slice(1)}{" "}
            filter
          </h4>
          <button
            type="button"
            id="modalClose"
            aria-label="Close Modal"
            className="btn-icon"
            onClick={this.closeModal}
            style={{ position: "absolute", top: "41px", right: "26px" }}
          >
            <ExitIcon
              svgStyles={{ pointerEvents: "none" }}
              height="25px"
              width="25px"
            />
          </button>
        </div>
        {this.state.loading && (
          <div
            style={{ height: "300px" }}
            className="modal-content flex-container flex-al-center flex-j-center"
          >
            <div className="flex-container flex-al-center flex-j-start">
              <div className="page-load-spinner"></div>
            </div>
          </div>
        )}
        <form
          style={{
            transition: "opacity .5s ease .3s",
            opacity: this.state.loading ? 0 : 1,
          }}
          className="new_filter"
          id="new_filter"
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <div
            // className="input-field"
            style={{ width: "250px", marginTop: "32px" }}
          >
            <span htmlFor="filters" className="modal-field">
              Filter Name<span style={{ color: "#519acc" }}>*</span>
            </span>
            <input
              required="required"
              className="browser-default text-input-v2"
              type="text"
              onChange={this.handleInputChange}
              value={this.state.filterName}
              maxLength="50"
              name="filterName"
              id="filter_name"
              style={{ margin: "8px 0px" }}
            />
            <p
              style={{
                margin: "0px",
                fontFamily: "Arial",
                fontSize: "13px",
                fontWeight: "400",
              }}
            >{`${this.state.filterName.length}/50`}</p>
          </div>
          <div
            style={{
              minHeight: `${this.state.dropHeight}px`,
              transition: "min-height .3s",
              marginBottom: "0px !important",
              marginTop: "16px",
            }}
          >
            <span htmlFor="filter_filters" className="modal-field">
              Filter Members<span style={{ color: "#519acc" }}>*</span>
            </span>
            <Select
              isMulti
              required
              classNamePrefix="react-multi-select"
              className="filter-multi"
              menuShouldScrollIntoView
              isClearable={false}
              blurInputOnSelect={true}
              menuPlacement="auto"
              placeholder=""
              components={{
                Control: this.customControl,
                Input: this.labelInput,
                DropdownIndicator: this.DropdownIndicator,
              }}
              aria-label="Tag dropdown for edit project form"
              closeMenuOnSelect={false}
              options={this.state.users}
              onChange={this.handleSelectChange}
              value={this.state.selectedUsers}
              styles={{
                ...MultiSelectStyles,
              }}
            />
          </div>
          <div className="new-modal-footer">
            <div className="col s12 flex-container flex-j-end">
              {this.props.data.edit && (
                <button
                  style={{ marginRight: "15px" }}
                  type="button"
                  className="common-button-delete"
                  onClick={this.deleteFilter}
                >
                  Delete
                </button>
              )}
              <button
                style={{ marginRight: "15px" }}
                type="button"
                className="common-button-cancel"
                onClick={this.closeModal}
              >
                Cancel
              </button>
              <button
                disabled={this.state.selectedUsers.length === 0}
                type="submit"
                className="common-button-submit"
                data-disable-with={`${
                  this.props.data.edit ? "Edit" : "Create"
                } Filter`}
              >
                {this.props.data.edit ? "Edit" : "Create"} Filter
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
export default AddFilter;
