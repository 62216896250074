import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import styled from "styled-components";
import Swal from "sweetalert2";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import SingleSelectWrapper from "components/common/single_select_wrapper";
import ModalHeadline from "components/modals/ModalHeadline";
import NumberCounter from "components/common/number_counter";
import SingleSelectStyles from "components/common/single_select_styles";
import RoundDropdown from "components/projects/round_dropdown";
import CalendarIcon from "components/icons/calendar_icon";
import ms from "./ModalStyled";

const TimeOffRequest = (props) => {
  const { modalAction, data } = props;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startPickerFocus, setStartPickerFocus] = useState(false);
  const [endPickerFocus, setEndPickerFocus] = useState(false);
  const [manager, setManager] = useState(null);
  const [options, setOptions] = useState([]);
  const [hours, setHours] = useState(8);
  const [details, setDetails] = useState("");

  useEffect(() => {
    const elems = document.querySelectorAll(
      ".SingleDatePickerInput_calendarIcon"
    );

    elems.forEach((e) => e.setAttribute("aria-hidden", "true"));

    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };

    axios
      .get(`/api/v1/users/managers`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const nextOptions = [];
        res.data.forEach((user) => {
          nextOptions.push({ value: user.id, label: user.name });
        });
        setOptions(nextOptions);
      })
      .catch((err) => console.error(err));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (manager === null) {
      M.toast({
        html: `<span aria-live="assertive">Please select a manager</span>`,
        displayLength: 3000,
        classes: "red",
      });
      return;
    }

    if (moment(startDate) < moment().add(14, "d")) {
      const result = await Swal.fire({
        title: `Late Notice`,
        text: "Requests made with less than a 2 week notice may not be approved. Continue with request?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      });
      if (!result.isConfirmed) {
        return;
      }
    }

    axios
      .get("/api/v1/users/time_off_request", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          user_name: data.user.name,
          user_id: data.user.id,
          startDate: moment(startDate).format("MMMM Do, YYYY"),
          endDate: moment(endDate).format("MMMM Do, YYYY"),
          hours: startDate.isSame(endDate) ? hours : 8,
          manager: manager.value,
          details: details.length > 0 ? details : "none",
        },
      })
      .then((res) => {
        M.toast({
          html: `<span aria-live="assertive" role="alert" role="dialog">Request Sent</span>`,
          displayLength: 3000,
          classes: "green",
        });
        // Announce to screen reader that time off was reported
        const liveRegion = document.getElementById("live-region");
        if (liveRegion) {
          liveRegion.textContent = "Time Off Reported";
        }
        if (moment(endDate).diff(startDate, "days") >= 3) {
          Swal.fire({
            title: `Please reach out to your PM`,
            text: "The duration of this request is long enough that you may need to return devices or receive special instructions",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Okay",
          });
        }
        document.getElementById("time-off-request-button").focus();
        modalAction(false, "", {});
        data.onNewRequest(res.data);
      })
      .catch((err) => {
        M.toast({
          html: `<span aria-live="assertive">There was an error sending the request</span>`,
          displayLength: 3000,
          classes: "red",
        });
        const liveRegion = document.getElementById("live-region");
        if (liveRegion) {
          liveRegion.textContent =
            "There was an error submitting the time off request";
        }
        console.error(err);
      });
  };
  const updateHours = (h) => {
    setHours(h);
  };

  const onEndDateChange = (date) => {
    setEndDate(date);
    if (moment(date).isBefore(startDate)) {
      setStartDate(date);
    }
    // const elem = document.getElementById(`aria-custom-status`);
    // if (elem) {
    //   elem.innerText = `${moment(date).format(
    //     "MMMM Do YYYY"
    //   )} selected for end date`;
    // }
  };

  const onStartDateChange = (date) => {
    setStartDate(date);
    if (moment(date).isAfter(endDate)) {
      setEndDate(date);
    }

    // const elem = document.getElementById(`aria-custom-status`);
    // if (elem) {
    //   elem.innerText = `${moment(date).format(
    //     "MMMM Do YYYY"
    //   )} selected for start date`;
    // }
  };

  const handleStartFocus = (focused) => {
    setStartPickerFocus(focused.focused);
  };

  const handleEndFocus = (focused) => {
    setEndPickerFocus(focused.focused);
  };

  const isDayBlocked = (day) => {
    return !!(
      moment().utc(-7).diff(day) >= 0 ||
      moment(day).day() === 0 ||
      moment(day).day() === 6
    );
  };

  const handleInputChange = (e) => {
    setManager(e);
    const elem = document.getElementById(`aria-custom-status`);
    if (elem && manager) {
      elem.innerText = `manager ${manager.label} selected`;
    }
  };

  const onSelectKeyDown = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      setTimeout(() => {
        const elem = document.querySelector(
          ".react-select__option--is-focused"
        );
        const ariaElem = document.getElementById(`aria-custom-status`);
        if (elem && ariaElem) {
          ariaElem.innerText = elem.classList.contains(
            "react-select__option--is-selected"
          )
            ? `option ${elem.innerText}, currently selected`
            : `option ${elem.innerText}`;
        }
      }, 50);
    }
  };

  const closeModal = () => {
    modalAction(false, "", {});
  };

  const singleDay = startDate && endDate && startDate.isSame(endDate);

  return (
    <PtoModal
      id="time-off-request-modal"
      role="dialog"
      aria-label="Request Time Off"
      className="modal-content"
    >
      <ModalHeadline modalKey="time_off_request" closeModal={closeModal} />
      <ms.Form
        id="new_time_off_request"
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <ms.FormRowDouble>
          <ms.FormItemDouble>
            <ms.Label className="custom-labels" htmlFor="manager">
              Manager<span className="base-color">*</span>
            </ms.Label>
            <SingleSelectWrapper
              options={options}
              value={manager}
              onChange={handleInputChange}
              onKeyDown={onSelectKeyDown}
              placeholder="Select a manager"
              name="manager"
              styles={{
                ...SingleSelectStyles,
                container: (styles) => ({ ...styles, width: "100%" }),
              }}
            />
          </ms.FormItemDouble>
        </ms.FormRowDouble>

        <ms.FormRowDouble>
          <ms.FormItemDouble>
            <ms.Label className="custom-labels" htmlFor="filter_filters">
              Start Date<span className="base-color">*</span>
            </ms.Label>
            <div
              className="flex-j-start flex-container flex-al-center"
              aria-label="start date picker"
            >
              <div
                aria-hidden
                style={{
                  position: "relative",
                  left: "16px",
                  zIndex: 1,
                  top: "5px",
                }}
              >
                <CalendarIcon width="25px" height="25px" color="#519acc" />
              </div>
              <SingleDatePicker
                date={startDate}
                onDateChange={onStartDateChange}
                focused={startPickerFocus}
                onFocusChange={handleStartFocus}
                numberOfMonths={1}
                placeholder="Select a date"
                orientation="horizontal"
                ariaLabel="Start Date picker. Use arrow keys to navigate calendar"
                daySize={30}
                navPrev={
                  <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                    <RoundDropdown rotate="rotate(90deg)" />
                  </div>
                }
                navNext={
                  <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                    <RoundDropdown rotate="rotate(-90deg)" />
                  </div>
                }
                hideKeyboardShortcutsPanel
                inputIconPosition="after"
                customInputIcon={<RoundDropdown />}
                isOutsideRange={isDayBlocked}
                id="single_date_picker"
              />
            </div>
          </ms.FormItemDouble>
          <ms.FormItemDouble>
            <ms.Label className="custom-labels" htmlFor="filter_filters">
              End Date<span className="base-color">*</span>
            </ms.Label>
            <div
              className="flex-j-start flex-container flex-al-center"
              aria-label="end date picker"
            >
              <div
                style={{
                  position: "relative",
                  left: "16px",
                  zIndex: 1,
                  top: "5px",
                }}
              >
                <CalendarIcon width="25px" height="25px" color="#519acc" />
              </div>
              <SingleDatePicker
                date={endDate}
                onDateChange={onEndDateChange}
                focused={endPickerFocus}
                onFocusChange={handleEndFocus}
                numberOfMonths={1}
                placeholder="Select a date"
                ariaLabel="End Date picker. Use arrow keys to navigate calendar. Leave end date the same as start date to request single day off"
                orientation="horizontal"
                daySize={30}
                navPrev={
                  <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                    <RoundDropdown rotate="rotate(90deg)" />
                  </div>
                }
                navNext={
                  <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                    <RoundDropdown rotate="rotate(-90deg)" />
                  </div>
                }
                hideKeyboardShortcutsPanel
                inputIconPosition="after"
                customInputIcon={<RoundDropdown />}
                isOutsideRange={isDayBlocked}
                id="single_date_picker"
              />
            </div>
          </ms.FormItemDouble>
        </ms.FormRowDouble>
        {data.user.full_time && (
          <ms.FormRow>
            <ms.Disclaimer>
              Please confirm your available PTO time in ADP/Harvest
            </ms.Disclaimer>
          </ms.FormRow>
        )}
        {singleDay && (
          <ms.FormRow>
            <ms.FormItem>
              <ms.Label className="custom-labels" htmlFor="filter_filters">
                Hours
              </ms.Label>
              <NumberCounter
                disabled={false}
                min={1}
                max={8}
                value={hours}
                step={1}
                updateValue={updateHours}
              />
            </ms.FormItem>
          </ms.FormRow>
        )}
        <ms.FormRow>
          <ms.FormItem>
            <ms.Label className="custom-labels" htmlFor="filter_filters">
              Notes
            </ms.Label>
            <textarea
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              type="text"
              name="time_off[name]"
              placeholder="Optional"
              maxLength={1000}
              id="time_off_details"
              className="browser-default text-input-v2"
              aria-label="time off request details. Optional"
              style={{ minHeight: "100px" }}
            />
          </ms.FormItem>
        </ms.FormRow>
        <ms.ControlsRow>
          <button
            type="button"
            onClick={closeModal}
            className="common-button-cancel"
          >
            Cancel
          </button>
          <input
            type="submit"
            name="commit"
            value="Submit"
            className="common-button-submit white-text"
            id="submit_request_button"
            disabled={!startDate || !endDate}
          />
        </ms.ControlsRow>
      </ms.Form>
      {/* Hidden live region for announcements */}
      <div
        id="live-region"
        aria-live="assertive"
        style={{ position: "absolute", left: "-9999px" }}
      ></div>
    </PtoModal>
  );
};

const PtoModal = styled.div`
  .hide-focus:focus {
    box-shadow: none !important;
    outline: none !important;
  }

  /* #start-date-container .SingleDatePicker_picker {
    left: -18px !important;
    top: 55px !important;
  }

  #end-date-container .SingleDatePicker_picker {
    left: -28px !important;
    top: 55px !important;
  } */
`;

export default TimeOffRequest;
