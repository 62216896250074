import React from "react";
import moment from "moment";
import axios from "axios";
import { Select } from "react-materialize";
import { selectAll } from "css-select";

class AddRoomViewBooking extends React.Component {
  constructor(props) {
    super(props);
    const disabledArray = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ];
    let disabled = false;
    this.timeIndex = this.props.data.extendBooking
      ? this.props.data.currentTimeIndex + 1
      : this.props.data.currentTimeIndex;
    for (let x = 1; x < 12; x++) {
      if (disabled) {
        disabledArray[x - 1] = true;
      } else if (
        this.timeIndex + x > this.props.data.bookingsArray.length - 1
        || this.props.data.bookingsArray[this.timeIndex + x] === "1"
      ) {
        disabled = true;
        disabledArray[x - 1] = true;
      }
    }
    this.state = {
      disabledArray,
      roomViewUserId: 0,
      duration: this.props.data.extendBooking ? "30" : "15",
      loading: false,
    };
    this.handleBookRoom = this.handleBookRoom.bind(this);
    this.timeChange = this.timeChange.bind(this);
    this.timeArray = [
      "09:00",
      "09:15",
      "09:30",
      "09:45",
      "10:00",
      "10:15",
      "10:30",
      "10:45",
      "11:00",
      "11:15",
      "11:30",
      "11:45",
      "12:00",
      "12:15",
      "12:30",
      "12:45",
      "13:00",
      "13:15",
      "13:30",
      "13:45",
      "14:00",
      "14:15",
      "14:30",
      "14:45",
      "15:00",
      "15:15",
      "15:30",
      "15:45",
      "16:00",
      "16:15",
      "16:30",
      "16:45",
      "17:00",
      "17:15",
      "17:30",
      "17:45",
    ];
  }

  componentDidMount() {
    const modal = document.getElementsByClassName("new-modal");
    modal[0].style.width = "50%";
    modal[0].style.minHeight = "400px";
    axios
      .get("/get_room_view_user/", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.setState({
          roomViewUserId: res.data[0].id,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  handleBookRoom() {
    this.setState({
      loading: true,
    });
    this.refs.book_now_button.disabled = true;
    axios
      .post(`/bookings.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        booking: {
          user_id: this.state.roomViewUserId,
          duration: parseInt(this.state.duration),
          name: this.props.data.room_name,
          booking_date:
            `${moment().format("DD/MM/YYYY")
            } ${
              this.timeArray[this.props.data.currentTimeIndex]}`,
          meeting_type: "room",
          description: "This room was booked using the room view tablet",
          room_id: this.props.data.room_id,
          attendees: 2,
        },
      })
      .then((res) => {
        M.toast({ html: `Booking created`, classes: "green" });
        this.setState({ loading: false });
        this.props.refreshBookings();
        this.props.modalAction(false, "", {});
      })
      .catch((err) => {
        M.toast({
          html: `There was an error. Please try again.`,
          classes: "green",
        });
        this.setState({ loading: false });
        this.refs.book_now_button.disabled = false;
        console.error(err);
      });
  }

  timeChange(e) {
    this.setState({
      duration: e.target.value,
    });
  }

  render() {
    const bookingSoon = this.props.data.bookingsArray[this.timeIndex] === "1";
    return (
      <div className="row modal-content">
        {this.state.loading ? (
          <div id="book-room-loading-overlay">
            <div className="book-room-loading-spinner" />
          </div>
        ) : (
          ""
        )}
        <div className="col s12" id="add-room-view-booking-container">
          <p className="bold" style={{ textAlign: "center", fontSize: "30px" }}>
            <span id="task_creator_p">
              Book
              {this.props.data.room_name}
            </span>
          </p>
          <div>
            <Select
              style={{ width: "200px" }}
              className="select"
              value={this.state.duration}
              onChange={this.timeChange}
              disabled={bookingSoon}
            >
              <option value={this.props.data.extendBooking ? "30" : "15"}>
                15 Minutes
              </option>
              <option
                disabled={this.state.disabledArray[0]}
                value={this.props.data.extendBooking ? "45" : "30"}
              >
                30 Minutes
              </option>
              <option
                disabled={this.state.disabledArray[1]}
                value={this.props.data.extendBooking ? "60" : "45"}
              >
                45 Minutes
              </option>
              <option
                disabled={this.state.disabledArray[2]}
                value={this.props.data.extendBooking ? "75" : "60"}
              >
                60 Minutes
              </option>
              <option
                disabled={this.state.disabledArray[3]}
                value={this.props.data.extendBooking ? "90" : "75"}
              >
                1 Hour 15 Minutes
              </option>
              <option
                disabled={this.state.disabledArray[4]}
                value={this.props.data.extendBooking ? "105" : "90"}
              >
                1 Hour 30 Minutes
              </option>
              <option
                disabled={this.state.disabledArray[5]}
                value={this.props.data.extendBooking ? "120" : "105"}
              >
                1 Hour 45 Minutes
              </option>
              <option
                disabled={this.state.disabledArray[6]}
                value={this.props.data.extendBooking ? "135" : "120"}
              >
                2 Hours
              </option>
              <option
                disabled={this.state.disabledArray[7]}
                value={this.props.data.extendBooking ? "150" : "135"}
              >
                2 Hours 15 Minutes
              </option>
              <option
                disabled={this.state.disabledArray[8]}
                value={this.props.data.extendBooking ? "165" : "150"}
              >
                2 Hours 30 Minutes
              </option>
              <option
                disabled={this.state.disabledArray[9]}
                value={this.props.data.extendBooking ? "180" : "165"}
              >
                2 Hours 45 Minutes
              </option>
              <option
                disabled={this.state.disabledArray[10]}
                value={this.props.data.extendBooking ? "195" : "180"}
              >
                3 Hours
              </option>
            </Select>
            {/* <span style={{lineHeight:"78px", fontSize: "20px"}}>Minutes</span> */}
          </div>
          <div
            className="btn"
            disabled={bookingSoon}
            ref="book_now_button"
            onClick={this.handleBookRoom}
            style={{ marginTop: "40px", fontSize: "20px" }}
          >
            Book Now
          </div>
          {bookingSoon && <div>Room Will Be Booked Soon</div>}
        </div>
      </div>
    );
  }
}

export default AddRoomViewBooking;
