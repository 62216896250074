import React from "react";
import moment from "moment";
import PageNav from "../common/page_nav";
import axios from "axios";
import { SingleDatePicker } from "react-dates";
import ScheduleUserRow from "../master_schedule/schedule_user_row";
import ReactModal from "../modals/react_modal";
import ScheduleDateHeaders from "../master_schedule/schedule_date_headers";
import RoundDropdown from "../projects/round_dropdown";
import NewCalenderIcon from "../icons/new_calender_icon";
import CalendarIcon from "../icons/calendar_icon";
import LinkBox from "../common/link_box";
import Request from "../Request";
import { sentryInit } from "../utils";

sentryInit();

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentWeekStart: moment()
        .startOf("week")
        .add(1, "d")
        .format("YYYY-MM-DD"),
      currentWeekEnd: moment().endOf("week").format("YYYY-MM-DD"),
      tasks: [],
      closures: this.props.closures,
      pickerFocus: false,
      pickerDate: moment(new Date()),
      modalOpen: false,
      currentModal: "",
      modalData: {},
      twoWeeks: false,
      loading: false,
      userAvailableHours: this.props.userAvailableHours,
      otherRequests: this.props.requests.filter(
        (r) => r.manager.id === this.props.user.id
      ),
      myRequests: this.props.requests.filter(
        (r) => r.user.id === this.props.user.id
      ),
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.getUserTasks = this.getUserTasks.bind(this);
    this.renderTasks = this.renderTasks.bind(this);
    this.renderDateDisplay = this.renderDateDisplay.bind(this);
    this.setModal = this.setModal.bind(this);
    this.prevWeek = this.prevWeek.bind(this);
    this.upcomingWeek = this.upcomingWeek.bind(this);
    this.resetWeek = this.resetWeek.bind(this);
    this.handleDateFocus = this.handleDateFocus.bind(this);
    this.onWeekSwitchClick = this.onWeekSwitchClick.bind(this);
    this.openRequestModal = this.openRequestModal.bind(this);
    this.openSickTimeModal = this.openSickTimeModal.bind(this);
    this.weekChangeKeyDown = (e) => {
      if (e.keyCode === 78 && e.shiftKey) {
        this.upcomingWeek();
      } else if (e.keyCode === 80 && e.shiftKey) {
        this.prevWeek();
      }
    };
    this.ariaStatus = React.createRef();
  }

  handleDateChange(date) {
    if (moment(date).isValid()) {
      var today = moment(date);
      var weekStart = moment(today)
        .startOf("week")
        .add(1, "d")
        .format("YYYY-MM-DD");
      var weekEnd = this.state.twoWeeks
        ? moment(today).endOf("week").add(7, "d").format("YYYY-MM-DD")
        : moment(today).endOf("week").format("YYYY-MM-DD");
      this.setState({
        currentWeekStart: weekStart,
        currentWeekEnd: weekEnd,
        pickerDate: date,
      });
      this.getUserTasks(weekStart, weekEnd);
    }
  }

  componentDidMount() {
    this.getUserTasks(this.state.currentWeekStart, this.state.currentWeekEnd);
    const button = document.querySelector(
      ".SingleDatePickerInput_calendarIcon"
    );
    if (button) {
      button.setAttribute("name", "selectADate");
      button.setAttribute("aria-label", "Select a date");
    }
    document.addEventListener("keydown", this.weekChangeKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.weekChangeKeyDown);
  }

  handleNewRequest = (req) => {
    let myRequests = this.state.myRequests;
    myRequests.push(req);
    this.setState({
      myRequests: myRequests.sort((a, b) => a.sort_date - b.sort_date),
    });
  };

  openRequestModal() {
    this.setModal(true, "time-off-request", {
      user: this.props.user,
      customClass: "sick-time-modal",
      userHours: this.state.userAvailableHours,
      onNewRequest: this.handleNewRequest,
      closeButton: false,
    });
  }

  openSickTimeModal() {
    this.setModal(true, "report-sick-time", {
      user: this.props.user,
      customClass: "sick-time-modal",
      userHours: this.state.userAvailableHours,
      closeButton: false,
    });
  }

  onDateChangePicker = (date) => {
    this.handleDateChange(date);
    this.setState({ pickerDate: date });
  };

  onFocusChangePicker = ({ focused }) => {
    this.setState({ pickerFocus: focused });
  };

  setModal(bool, page, data) {
    this.setState({
      modalOpen: bool,
      currentModal: page,
      modalData: data,
    });
  }

  getUserTasks(start_date, end_date) {
    var user = this.props.user;
    var id = user.id;
    var url = "/find_tasks/" + id;
    this.timeout = setTimeout(() => {
      this.setState({ loading: true });
    }, 200);
    axios
      .get(`/find_tasks/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          start_date: start_date ? start_date : this.state.currentWeekStart,
          end_date: end_date ? end_date : this.state.currentWeekEnd,
        },
      })
      .then((res) => {
        clearTimeout(this.timeout);
        this.setState({
          tasks: res.data.tasks,
          currentWeekStart: start_date
            ? start_date
            : this.state.currentWeekStart,
          currentWeekEnd: end_date ? end_date : this.state.currentWeekEnd,
          loading: false,
        });
      })
      .catch((err) => {
        clearTimeout(this.timeout);
        this.setState({ loading: false });
        console.error(err);
      });
  }

  renderRequests() {
    const myRequests = this.state.myRequests;

    return (
      <div>
        {myRequests.length > 0 ? (
          myRequests.map((request, i) => {
            return <Request request={request} key={i} />;
          })
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center", // vertical centering
              justifyContent: "center", // horizontal centering
              height: "16vh",
            }}
          >
            <p
              style={{
                fontFamily: "Arial",
                fontSize: "13px",
                fontWeight: "400",
                color: "#C0C0C0",
              }}
            >
              You have no pending PTO requests
            </p>
          </div>
        )}
      </div>
    );
  }

  renderApprovals() {
    const otherRequests = this.state.otherRequests;

    return (
      <div style={{ height: "16vh" }}>
        {otherRequests.length > 0 ? (
          otherRequests.map((request, i) => {
            return (
              <Request
                request={request}
                buttons
                onApprovalButton={this.handleApprovalButton}
                key={i}
              />
            );
          })
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center", // vertical centering
              justifyContent: "center", // horizontal centering
              height: "16vh",
            }}
          >
            <p
              style={{
                fontFamily: "Arial",
                fontSize: "13px",
                fontWeight: "400",
                color: "#C0C0C0",
              }}
            >
              No pending requests require your approval
            </p>
          </div>
        )}
      </div>
    );
  }

  handleApprovalButton = (user_id, bool, request_id) => {
    let toastText = "";
    bool ? (toastText = "Request approved") : (toastText = "Request denied");
    axios
      .get(
        `/secure_update_time_off_request/${user_id}?confirmed=${bool}&request_id=${request_id}`
      )
      .then((res) => {
        M.toast({
          html: `<span aria-live="assertive">${toastText}</span>`,
          displayLength: 3000,
          classes: "green",
        });
        const otherRequests = this.state.otherRequests;
        this.setState({
          otherRequests: otherRequests.filter((r) => r.id !== request_id),
        });
      })
      .catch((err) => {
        M.toast({
          html: `<span aria-live="assertive">There was an error handling the request</span>`,
          displayLength: 3000,
          classes: "red",
        });
        console.log(err);
      });
  };

  renderTasks() {
    var firstDay = this.state.currentWeekStart;
    var cutoffDate = moment().day("sunday");
    var currentWeek = [...Array(5)].map((_, i) =>
      moment(firstDay).add(i, "d").format("YYYY-MM-DD")
    );
    var secondWeek = [...Array(5)].map((_, i) =>
      moment(firstDay)
        .add(i + 7, "d")
        .format("YYYY-MM-DD")
    );
    var closures = this.state.closures;
    var currentWeekClosures = Array(5).fill(false);
    var secondWeekClosures = Array(5).fill(false);
    if (closures.length > 0) {
      for (var x = 0; x < 5; x++) {
        for (var y = 0; y < closures.length; y++) {
          if (
            closures[y].start_date <= currentWeek[x] &&
            closures[y].end_date >= currentWeek[x]
          ) {
            currentWeekClosures[x] = closures[y];
          }
          if (
            closures[y].start_date <= secondWeek[x] &&
            closures[y].end_date >= secondWeek[x]
          ) {
            secondWeekClosures[x] = closures[y];
          }
        }
      }
    }
    var tasks = this.state.tasks;
    var user = this.props.user;

    return (
      <div style={{ borderBottom: "1px solid #d7d7d7" }}>
        <ScheduleUserRow
          userSchedule={true}
          modalAction={this.setModal}
          user={user}
          userName={user.name}
          fullTime={user.full_time}
          userId={user.id}
          userTasks={tasks}
          index={0}
          cutoffDate={cutoffDate}
          twoWeeks={this.state.twoWeeks}
          currentWeek={currentWeek}
          secondWeek={secondWeek}
          currentWeekClosures={currentWeekClosures}
          secondWeekClosures={secondWeekClosures}
          viewOnly={true}
        />
      </div>
    );
  }

  renderDateDisplay() {
    var displayDateStart = moment(this.state.currentWeekStart).format(
      "MM/DD/YYYY"
    );
    var displayDateEnd = moment(this.state.currentWeekEnd)
      .subtract(1, "day")
      .format("MM/DD/YYYY");
    return (
      <div
        aria-label={`Showing schedule for ${moment(
          displayDateStart,
          "MM/DD/YYYY"
        ).format("dddd MMMM Do")} through ${moment(
          displayDateEnd,
          "MM/DD/YYYY"
        ).format(
          "dddd MMMM Do"
        )}. Press shift+N or shift+P anywhere on the page to navigate to next or previous week, respectively.`}
        id="date-display"
      >
        {this.state.loading ? (
          <div
            aria-hidden="true"
            style={{ height: "30px", width: "30px" }}
            className="page-load-spinner"
          />
        ) : (
          <p>
            {displayDateStart} - {displayDateEnd}
          </p>
        )}
      </div>
    );
  }

  upcomingWeek() {
    var newWeekStart = moment(this.state.currentWeekStart.slice())
      .add(7, "d")
      .format("YYYY-MM-DD");
    var newWeekEnd = moment(this.state.currentWeekEnd.slice())
      .add(7, "d")
      .format("YYYY-MM-DD");
    this.getUserTasks(newWeekStart, newWeekEnd);
  }

  prevWeek() {
    var newWeekStart = moment(this.state.currentWeekStart.slice())
      .subtract(7, "d")
      .format("YYYY-MM-DD");
    var newWeekEnd = moment(this.state.currentWeekEnd.slice())
      .subtract(7, "d")
      .format("YYYY-MM-DD");
    this.getUserTasks(newWeekStart, newWeekEnd);
  }

  resetWeek() {
    var newWeekStart = moment()
      .startOf("week")
      .add(1, "day")
      .format("YYYY-MM-DD");
    var newWeekEnd = this.state.twoWeeks
      ? moment().endOf("week").add(7, "d").format("YYYY-MM-DD")
      : moment().endOf("week").format("YYYY-MM-DD");
    this.getUserTasks(newWeekStart, newWeekEnd);
  }

  setTwoWeeks(twoWeeks) {
    this.getUserTasks(
      this.state.currentWeekStart,
      twoWeeks
        ? moment(this.state.currentWeekEnd.slice())
            .add(7, "d")
            .format("YYYY-MM-DD")
        : moment(this.state.currentWeekEnd.slice())
            .subtract(7, "d")
            .format("YYYY-MM-DD")
    );
    this.setState({
      currentWeekEnd: twoWeeks
        ? moment(this.state.currentWeekEnd.slice())
            .add(7, "d")
            .format("YYYY-MM-DD")
        : moment(this.state.currentWeekEnd.slice())
            .subtract(7, "d")
            .format("YYYY-MM-DD"),
    });
  }

  handleDateFocus({ focused }) {
    this.setState({ pickerFocus: focused });
    if (focused) {
      document
        .getElementById("date-picker-container")
        .setAttribute("aria-hidden", false);
      setTimeout(() => {
        var elem = document.getElementsByClassName("DayPicker_focusRegion_1");
        elem[0].focus();
      }, 1000);
    }
  }

  onWeekSwitchClick(e) {
    this.setState({ twoWeeks: !this.state.twoWeeks });
    this.setTwoWeeks(!this.state.twoWeeks);
  }

  isDayBlocked(day) {
    return moment(day).day() === 0 || moment(day).day() === 6 ? true : false;
  }

  render() {
    var displayDateStart = moment(this.state.currentWeekStart);
    var displayDateEnd = moment(this.state.currentWeekEnd).subtract(1, "day");
    var today = moment(new Date());
    var todayIsInRange = today.isBetween(
      displayDateStart,
      displayDateEnd,
      "days",
      "[]"
    );
    let approvalDisplay = "none";
    let pendingMargin = "";
    if (this.props.user.role_id >= 3) {
      approvalDisplay = "";
      pendingMargin = "5px";
    }
    return (
      <div
        style={{
          backgroundColor: "#f2f2f2",
          overflowX: "auto",
          padding: "16px",
          minHeight: "calc(100vh - 68px)",
        }}
      >
        <div
          style={{
            position: "fixed",
            top: "-200px",
            left: "-200px",
            pointerEvents: "none",
            height: "0px",
            width: "0px",
            overflow: "hidden",
          }}
          data-number="0"
          role="status"
          aria-live="assertive"
          id="aria-custom-status"
        ></div>
        <div
          style={{
            margin: "0px 0px 8px",
            borderRadius: "10px",
            border: "1px solid #d7d7d7",
            backgroundColor: "white",
            padding: "32px",
            overflow: "visible",
            position: "relative",
            minWidth: "1000px",
          }}
        >
          {this.props.dashboardLinks && (
            <LinkBox links={this.props.dashboardLinks} />
          )}
          <PageNav
            pageName={"My Schedule"}
            twoWeeks={this.state.twoWeeks}
            weekHandler={this.onWeekSwitchClick}
            usedLetters={null}
            checkboxes={[]}
            scrollHandler={this.scrollIntoView}
            addHandler={null}
            addText={""}
            showSearch={false}
            pageIcon={<NewCalenderIcon />}
          />

          <div
            className="date-nav-wrapper"
            style={{
              height: "136px",

              position: "relative",
              marginTop: "32px",
            }}
          >
            <button
              id="sick-time-button"
              aria-label={"Open sick time submission modal"}
              onClick={this.openSickTimeModal}
              className="common-button-submit"
              style={{ position: "absolute", right: "149px", top: "-78px" }}
            >
              Submit Sick Time
            </button>
            <button
              id="time-off-request-button"
              aria-label={"Open time off request modal"}
              onClick={this.openRequestModal}
              className="common-button-submit"
              style={{ position: "absolute", right: "0px", top: "-78px" }}
            >
              Request Time Off
            </button>
            <div className="date-nav">
              <div id="date-nav-header">
                <button
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    padding: "0px",
                    marginRight: "16px",
                  }}
                  aria-label="Previous week"
                  id="prevWeek"
                  onClick={this.prevWeek}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="15"
                    viewBox="0 0 10 15"
                    fill="none"
                    style={{ verticalAlign: "-3px" }}
                  >
                    <g clipPath="url(#clip0_2850_1302)">
                      <path
                        d="M9.29099 11.5116C10.0711 12.2791 10.213 13.5349 9.43284 14.3023C9.00731 14.7209 8.43993 15 7.94348 15C7.44702 15 6.87965 14.7907 6.52504 14.4419L0.354823 8.37209C-0.141631 7.88372 -0.141631 7.18605 0.354823 6.69768L2.41156 4.67442L6.59596 0.55814C7.3761 -0.209302 8.6527 -0.209302 9.50376 0.55814C10.2839 1.32558 10.2839 2.5814 9.50376 3.4186L5.31936 7.53488C5.31936 7.53488 5.31936 7.53488 5.31936 7.60465L9.29099 11.5116Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2850_1302">
                        <rect
                          width="10"
                          height="15"
                          fill="white"
                          transform="translate(10 15) rotate(180)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                <div>{this.renderDateDisplay()}</div>
                <button
                  aria-hidden="false"
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    padding: "0px",
                    marginLeft: "16px",
                  }}
                  aria-label="Next week"
                  id="upcomingWeek"
                  onClick={this.upcomingWeek}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="15"
                    viewBox="0 0 10 15"
                    fill="none"
                    style={{ verticalAlign: "-3px" }}
                  >
                    <g clipPath="url(#clip0_2850_1305)">
                      <path
                        d="M0.709005 3.48837C-0.0711367 2.72093 -0.212981 1.46512 0.567161 0.697674C0.992693 0.27907 1.56007 0 2.05652 0C2.55298 0 3.12035 0.209302 3.47496 0.55814L9.64518 6.62791C10.1416 7.11628 10.1416 7.81395 9.64518 8.30233L7.58844 10.3256L3.40404 14.4419C2.6239 15.2093 1.3473 15.2093 0.496239 14.4419C-0.283903 13.6744 -0.283903 12.4186 0.496239 11.5814L4.68064 7.46512C4.68064 7.46512 4.68064 7.46512 4.68064 7.39535L0.709005 3.48837Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2850_1305">
                        <rect width="10" height="15" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                <div
                  id="start-date-container"
                  style={{ position: "absolute", right: "10px" }}
                  className="flex-j-start flex-container flex-al-center"
                  aria-label="Select a date"
                >
                  <div
                    style={{
                      position: "relative",
                      left: "40px",
                      zIndex: 1,
                      top: "4px",
                    }}
                  >
                    <CalendarIcon width="22px" height="22px" color="#519acc" />
                  </div>
                  <SingleDatePicker
                    date={this.state.pickerDate}
                    onDateChange={this.onDateChangePicker}
                    focused={this.state.pickerFocus}
                    onFocusChange={this.onFocusChangePicker}
                    numberOfMonths={1}
                    placeholder={"Select a date"}
                    orientation={"horizontal"}
                    daySize={30}
                    navPrev={
                      <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                        <RoundDropdown rotate={"rotate(90deg)"} />
                      </div>
                    }
                    navNext={
                      <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                        <RoundDropdown rotate={"rotate(-90deg)"} />
                      </div>
                    }
                    hideKeyboardShortcutsPanel
                    inputIconPosition={"after"}
                    customInputIcon={<RoundDropdown />}
                    isOutsideRange={this.isDayBlocked}
                    id="single_date_picker"
                  />
                </div>
                {!todayIsInRange && (
                  <a
                    id="todayBtn"
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      position: "absolute",
                      right: "220px",
                      top: "19px",
                    }}
                    onClick={this.resetWeek}
                  >
                    <i
                      style={{ pointerEvents: "none" }}
                      className="material-icons white-text"
                    >
                      today
                    </i>
                  </a>
                )}
              </div>
              <ScheduleDateHeaders
                userSchedule={true}
                copyProjectSchedule={this.copyProjectSchedule}
                userCount={1}
                twoWeeks={this.state.twoWeeks}
                currentWeekStart={this.state.currentWeekStart}
              />
            </div>
          </div>
          {this.renderTasks()}
          <ReactModal
            refreshUserTasks={this.getUserTasks}
            isShowing={this.state.modalOpen}
            page={this.state.currentModal}
            data={this.state.modalData}
            modalAction={this.setModal}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            margin: "0px ",
            minWidth: "1000px",
            height: "calc(100vh - 529px)",
          }}
        >
          <div
            style={{
              border: "1px solid #d7d7d7",
              borderRadius: "10px",
              backgroundColor: "white",
              padding: "28px",
              overflow: "auto",
              position: "relative",
              order: "1",
              flexGrow: "1",
            }}
          >
            <h1
              style={{
                fontWeight: "600",
                fontSize: "18px",
                marginBottom: "17px",
                fontFamily: "Manrope",
                outline: "none",
              }}
            >
              My Pending PTO Requests
            </h1>
            {this.renderRequests()}
          </div>
          {this.props.user.role_id >= 3 && (
            <div
              style={{
                marginLeft: "8px",
                border: "1px solid #d7d7d7",
                borderRadius: "10px",
                backgroundColor: "white",
                padding: "28px",
                overflow: "auto",
                position: "relative",
                order: "2",
                flexGrow: "1",
                minWidth: "50%",
                maxWidth: "50%",
              }}
            >
              <h1
                style={{
                  fontWeight: "600",
                  fontSize: "18px",
                  marginBottom: "17px",
                  fontFamily: "Manrope",
                  outline: "none",
                }}
              >
                Pending Request Approvals
              </h1>
              {this.renderApprovals()}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Home;
