import React from "react";
import SvgShell from "./svg_shell";

function CalendarIcon({ height, width, svgStyles, color }) {
  return (
    <SvgShell height={height} width={width} svgStyles={svgStyles} color={color}>
      <path d="M62.79,3.71v8.85H1.21V3.71a2.61,2.61,0,0,1,2.6-2.6H60.18A2.6,2.6,0,0,1,62.79,3.71Z" />
      <path d="M1.21,15.53V48.28a2.62,2.62,0,0,0,2.6,2.61H60.18a2.61,2.61,0,0,0,2.61-2.61V15.53ZM6,21.83A1.49,1.49,0,0,1,7.5,20.34H9.63a1.49,1.49,0,0,1,1.49,1.49V24a1.49,1.49,0,0,1-1.49,1.49H7.5A1.49,1.49,0,0,1,6,24Zm5.1,22.28A1.49,1.49,0,0,1,9.63,45.6H7.5A1.49,1.49,0,0,1,6,44.11V42A1.48,1.48,0,0,1,7.5,40.5H9.63A1.48,1.48,0,0,1,11.12,42Zm0-10.07a1.48,1.48,0,0,1-1.49,1.48H7.5A1.48,1.48,0,0,1,6,34V31.91A1.49,1.49,0,0,1,7.5,30.42H9.63a1.49,1.49,0,0,1,1.49,1.49ZM22.53,44.11A1.49,1.49,0,0,1,21,45.6H18.91a1.49,1.49,0,0,1-1.49-1.49V42a1.49,1.49,0,0,1,1.49-1.48H21A1.49,1.49,0,0,1,22.53,42Zm0-10.07A1.49,1.49,0,0,1,21,35.52H18.91A1.49,1.49,0,0,1,17.42,34V31.91a1.49,1.49,0,0,1,1.49-1.49H21a1.49,1.49,0,0,1,1.49,1.49Zm0-10.08A1.49,1.49,0,0,1,21,25.45H18.91A1.49,1.49,0,0,1,17.42,24V21.83a1.49,1.49,0,0,1,1.49-1.49H21a1.49,1.49,0,0,1,1.49,1.49ZM34.65,44.11a1.49,1.49,0,0,1-1.48,1.49H31a1.49,1.49,0,0,1-1.49-1.49V42A1.48,1.48,0,0,1,31,40.5h2.13A1.48,1.48,0,0,1,34.65,42Zm0-10.07a1.48,1.48,0,0,1-1.48,1.48H31A1.48,1.48,0,0,1,29.55,34V31.91A1.49,1.49,0,0,1,31,30.42h2.13a1.49,1.49,0,0,1,1.48,1.49Zm0-10.08a1.49,1.49,0,0,1-1.48,1.49H31A1.49,1.49,0,0,1,29.55,24V21.83A1.49,1.49,0,0,1,31,20.34h2.13a1.49,1.49,0,0,1,1.48,1.49ZM46.81,44.11a1.49,1.49,0,0,1-1.48,1.49H43.2a1.49,1.49,0,0,1-1.49-1.49V42A1.48,1.48,0,0,1,43.2,40.5h2.13A1.48,1.48,0,0,1,46.81,42Zm0-10.07a1.48,1.48,0,0,1-1.48,1.48H43.2A1.48,1.48,0,0,1,41.71,34V31.91a1.49,1.49,0,0,1,1.49-1.49h2.13a1.49,1.49,0,0,1,1.48,1.49Zm0-10.08a1.49,1.49,0,0,1-1.48,1.49H43.2A1.49,1.49,0,0,1,41.71,24V21.83a1.49,1.49,0,0,1,1.49-1.49h2.13a1.49,1.49,0,0,1,1.48,1.49ZM59,44.11a1.49,1.49,0,0,1-1.48,1.49H55.36a1.49,1.49,0,0,1-1.49-1.49V42a1.49,1.49,0,0,1,1.49-1.48h2.13A1.48,1.48,0,0,1,59,42ZM59,34a1.48,1.48,0,0,1-1.48,1.48H55.36A1.48,1.48,0,0,1,53.87,34V31.91a1.49,1.49,0,0,1,1.49-1.49h2.13A1.49,1.49,0,0,1,59,31.91ZM59,24a1.49,1.49,0,0,1-1.48,1.49H55.36A1.49,1.49,0,0,1,53.87,24V21.83a1.49,1.49,0,0,1,1.49-1.49h2.13A1.49,1.49,0,0,1,59,21.83Z" />
    </SvgShell>
  );
}

CalendarIcon.defaultProps = {
  height: "25px",
  width: "31px",
  svgStyles: {},
  color: "#519acc",
};

export default CalendarIcon;
