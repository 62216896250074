import React from "react";
import moment from "moment";

class TimeEntryWeekCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onFocus(e) {
    this.startingValue = parseFloat(e.target.value);
    e.target.select();
  }

  onMouseUp(e) {
    e.target.select();
  }

  onBlur(e) {
    let input = parseFloat(e.target.value);
    let value;
    if (input < 0) {
      input = Math.abs(input);
    }
    if (input) {
      if (input > 24) {
        value = "24.00";
      } else if (input % 0.25 === 0) {
        value = input.toFixed(2);
      } else {
        const decimal = input - Math.floor(input);
        if (input <= 0.12) {
          value = "0.25";
        } else {
          value = (Math.round(input * 4) / 4).toFixed(2);
        }
      }
    } else {
      value = "0.00";
    }
    e.target.value = value;
    if (parseFloat(value) !== this.startingValue) {
      const { timeEntry } = this.props;
      const tw = this.props.timeEntryWeek;
      if (timeEntry) {
        this.props.addUpdatedWeekEntries({
          id: timeEntry.id,
          hours: parseFloat(value),
        });
      } else {
        const newIdString = `new-${this.props.day}-${tw.id}-${tw.project_id}-${
          tw.time_entry_task_id ? tw.time_entry_task_id : "none"
        }`;
        this.props.addUpdatedWeekEntries({
          id: newIdString,
          hours: parseFloat(value),
          entry_date: this.props.day,
          user_id: tw.user_id,
          time_entry_week_id: tw.id,
          project_id: tw.project_id,
          time_entry_task_id: tw.time_entry_task_id,
        });
      }
    } else if (
      this.props.updatedWeekEntries.length === 0
      && this.props.saveWeekEnabled
    ) {
      this.props.setSaveWeekEnabled(false);
    }
  }

  onKeyDown(e) {
    if (!e.target.value && e.keyCode === 190) {
      e.preventDefault();
    }
  }

  onChange(e) {
    const input = e.target.value;
    if (!this.props.saveWeekEnabled) {
      this.props.setSaveWeekEnabled(true);
    }
    if (input.length === 0 || (input.length === 1 && input === ".")) {
      e.target.value = "";
    } else if (input.length === 2) {
      if (input[1] !== "." && isNaN(input[1])) {
        e.preventDefault();
      } else if (!isNaN(input[1]) && parseInt(input) > 24) {
        e.target.value = `${input[0]}.${input[1]}`;
      }
    } else if (input.length === 3 && !input.includes(".")) {
      e.target.value = `${input.slice(0, 2)}.${input[2]}`;
    } else if (input.length > 4 && input.split(".")[0].length === 1) {
      e.target.value = e.target.value.slice(0, e.target.value.length - 1);
    } else if (input.length > 5) {
      e.target.value = e.target.value.slice(0, e.target.value.length - 1);
    }
  }

  onPaste(e) {
    e.preventDefault();
  }

  render() {
    const { timeEntry } = this.props;
    return (
      <div
        style={{
          height: "100%",
          width: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <input
          aria-label={`Hours logged for ${moment(this.props.day).format(
            "MMMM Do"
          )} `}
          className="browser-default text-input-v2"
          onPaste={this.onPaste}
          onKeyDown={this.onKeyDown}
          onFocus={this.onFocus}
          onMouseUp={this.onMouseUp}
          onBlur={this.onBlur}
          onChange={this.onChange}
          defaultValue={timeEntry ? timeEntry.hours.toFixed(2) : "0.00"}
          min={0.25}
          max={8}
          step={0.25}
          type="number"
          style={{ width: "64px" }}
        />
      </div>
    );
  }
}

export default TimeEntryWeekCell;
