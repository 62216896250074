import React from "react";

function MemberCard(props) {
  function getStyle(style) {
    // both styles need merged for when they are hidden and shown
    if (!style) {
      return props.style;
    }

    return {
      ...props.style,
      ...style,
    };
  }

  return (
    <div
      style={getStyle({
        padding: "5px 10px 5px 25px",
        borderBottom: "1px solid #d1d1d1",
        margin: "0 4px 0 7px",
        width: "calc(100% - 10px)",
      })}
      className="flex-container flex-row flex-al-center flex-j-start"
      key={props.user.id}
    >
      <img
        alt="users"
        height="45"
        width="45"
        style={{ borderRadius: "50%" }}
        src={props.user.avatar_url}
      />
      <div
        style={{ marginLeft: "10px" }}
        className="flex-container flex-column flex-al-center flex-j-start"
      >
        <p
          className="truncate manrope semi-bold"
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: "15px",
            marginBottom: "0",
            marginTop: "10px",
          }}
        >
          {props.user.name}
        </p>
        <p
          style={{
            alignSelf: "flex-start",
            fontSize: "13px",
            marginTop: "0",
            marginBottom: "10px",
          }}
          className="arial smaller-text"
        >
          {`Available ${props.user.current_week_available_hours} hours`}
        </p>
      </div>
    </div>
  );
}

export default MemberCard;
