import React from "react";
import moment from "moment";
import ReactDOM from "react-dom";
import { Modal } from "react-materialize";
import GridFormModal from "../gridModal/GridFormModal";
import BookedModal from "../gridModal/BookedModal";
// import ToolTip from '../gridModal/ToolTip'

let cellColor;

class Cell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: this.props.currentday,
      isBooked: this.props.isBooked,
      room: this.props.room,
      id: this.props.id,
      roomName: this.props.roomName,
    };
    this.openModal = this.openModal.bind(this);
    this.valueArray = {
      0: "08:00:00",
      1: "08:15:00",
      2: "08:30:00",
      3: "08:45:00",
      4: "09:00:00",
      5: "09:15:00",
      6: "09:30:00",
      7: "09:45:00",
      8: "10:00:00",
      9: "10:15:00",
      10: "10:30:00",
      11: "10:45:00",
      12: "11:00:00",
      13: "11:15:00",
      14: "11:30:00",
      15: "11:45:00",
      16: "12:00:00",
      17: "12:15:00",
      18: "12:30:00",
      19: "12:45:00",
      20: "13:00:00",
      21: "13:15:00",
      22: "13:30:00",
      23: "13:45:00",
      24: "14:00:00",
      25: "14:15:00",
      26: "14:30:00",
      27: "14:45:00",
      28: "15:00:00",
      29: "15:15:00",
      30: "15:30:00",
      31: "15:45:00",
      32: "16:00:00",
      33: "16:15:00",
      34: "16:30:00",
      35: "16:45:00",
      36: "17:00:00",
      37: "17:15:00",
      38: "17:30:00",
      39: "17:45:00",
      40: "18:00:00",
    };

    this.toolTipArray = [
      "8:00",
      "8:15",
      "8:30",
      "8:45",
      "9:00",
      "9:15",
      "9:30",
      "9:45",
      "10:00",
      "10:15",
      "10:30",
      "10:45",
      "11:00",
      "11:15",
      "11:30",
      "11:45",
      "12:00",
      "12:15",
      "12:30",
      "12:45",
      "1:00",
      "1:15",
      "1:30",
      "1:45",
      "2:00",
      "2:15",
      "2:30",
      "2:45",
      "3:00",
      "3:15",
      "3:30",
      "3:45",
      "4:00",
      "4:15",
      "4:30",
      "4:45",
      "5:00",
      "5:15",
      "5:30",
      "5:45",
    ];
  }

  componentDidMount() {
    if (this.props.bookingId != null && this.props.isBooked === true) {
      const { bookingId } = this.props;
      const { booking } = this.props;
      const bookingDate = moment(booking.booking_date, "MM-DD-YYYY").format(
        "MM-DD-YYYY"
      );
      if (bookingDate === this.props.currentDay) {
        cellColor = true;
      } else cellColor = false;
    }
  }

  // closeModal(){
  //   document.body.style["overflow"] = "";
  //   ReactDOM.unmountComponentAtNode(document.getElementById('modalContainer'));
  // }

  openModal() {
    if (this.props.isBooked) {
      const start = this.props.booking.booking_array.findIndex((val) => {
        return val[0] > 0 || val[1] > 0;
      });
      let endTimeValue;
      for (
        let i = this.props.theKey;
        i <= this.props.booking.booking_array.length;
        i++
      ) {
        const indexValue = this.props.booking.booking_array[i];
        if (indexValue === 0 || indexValue === undefined) {
          break;
        }
        endTimeValue = parseInt(i) + 1;
      }
    }
    const valueArray = {
      0: "08:00:00",
      1: "08:15:00",
      2: "08:30:00",
      3: "08:45:00",
      4: "09:00:00",
      5: "09:15:00",
      6: "09:30:00",
      7: "09:45:00",
      8: "10:00:00",
      9: "10:15:00",
      10: "10:30:00",
      11: "10:45:00",
      12: "11:00:00",
      13: "11:15:00",
      14: "11:30:00",
      15: "11:45:00",
      16: "12:00:00",
      17: "12:15:00",
      18: "12:30:00",
      19: "12:45:00",
      20: "13:00:00",
      21: "13:15:00",
      22: "13:30:00",
      23: "13:45:00",
      24: "14:00:00",
      25: "14:15:00",
      26: "14:30:00",
      27: "14:45:00",
      28: "15:00:00",
      29: "15:15:00",
      30: "15:30:00",
      31: "15:45:00",
      32: "16:00:00",
      33: "16:15:00",
      34: "16:30:00",
      35: "16:45:00",
      36: "17:00:00",
      37: "17:15:00",
      38: "17:30:00",
      39: "17:45:00",
      40: "18:00:00",
    };
    // ReactDOM.render(
    //     <Modal
    //       actions={[]}
    //       className={"gridModal"}
    //       header={this.props.roomName}
    //       open={true}
    //       options={{endingTop: "5%", onCloseEnd: this.closeModal }}
    //       >
    //       {this.props.isBooked ?
    //         <GridFormModal
    //         action={"edit"}
    //         roomCapacity={this.props.roomCapacity}
    //         deleteHandler={this.props.deleteHandler}
    //         editHandler={this.props.editHandler}
    //         currentUser={this.props.currentUser}
    //         roomName={this.props.roomName}
    //         room={this.props.room}
    //         currentDay={this.props.currentDay}
    //         bookingId={this.props.bookingId}
    //         bookings={this.props.bookings}
    //         booking={this.props.booking}
    //         roomObject={this.props.roomObject}
    //         startTimeValue={valueArray[start]}
    //         endTimeValue={valueArray[endTimeValue]}
    //         />
    //         :
    //         <GridFormModal
    //           roomCapacity={this.props.roomCapacity}
    //           action={"create"}
    //           booking={this.props.booking}
    //           bookingHandler={this.props.bookingHandler}
    //           editHandler={this.props.editHandler}
    //           bookings={this.props.bookings}
    //           currentUser={this.props.currentUser}
    //           roomName={this.props.roomName}
    //           room={this.props.room}
    //           roomObject={this.props.roomObject}
    //           currentDay={this.props.currentDay}
    //           startTimeValue={valueArray[this.props.theKey]}
    //           endTimeValue={valueArray[this.props.theKey + 1]}
    //         />
    //         }
    //       </Modal>,
    //     document.getElementById('modalContainer'),
    //   )
    const data = {
      action: this.props.isBooked ? "edit" : "create",
      roomCapacity: this.props.room.capacity,
      booking: this.props.booking,
      bookings: this.props.bookings,
      bookingHandler: this.props.bookingHandler,
      currentUser: this.props.currentUser,
      disabled:
        this.props.isBooked &&
        this.props.currentUser.role_id === 1 &&
        this.props.booking.user.id !== this.props.currentUser.id,
      roomName: this.props.roomName,
      room: this.props.room,
      roomObject: this.props.roomObject,
      currentDay: this.props.currentDay,
      selectedTimeIndex: this.props.theKey,
      users: this.props.users,
      deleteHandler: this.props.deleteHandler,
      editHandler: this.props.editHandler,
      customClass: "meeting-modal",
    };
    this.props.setModal(true, "meeting", data);
  }

  render() {
    let cellColor;
    let bookingDateTime;
    let bookedUser;
    let duration;
    if (this.props.isBooked === true) {
      cellColor = true;
    } else {
      cellColor = false;
    }

    const roomClass = this.props.roomName.toLowerCase().split(" ").join("-");
    if (this.props.isBooked === true) {
      const { bookingId } = this.props;
      const { booking } = this.props;
      duration = booking.duration != undefined ? booking.duration : 0;
      bookedUser = booking.user.name;
      const bookingDate = moment(booking.booking_date).format("MM-DD-YYYY");
      bookingDateTime = moment(booking.booking_date).format("LT");
      if (bookingDate === this.props.currentDay) {
        cellColor = true;
      } else cellColor = false;
    }

    const tooltipInfo = `Reserved for: ${bookedUser} @
          ${bookingDateTime} for ${duration} min`;

    return (
      <div style={{ gridColumn: `span ${cellColor ? duration / 15 : 1}` }}>
        <div
          className={
            cellColor
              ? `filled-cell tooltip filled-cell-${roomClass}`
              : `empty-cell empty-cell-tooltip ${
                  moment(
                    `${this.props.currentDay} ${
                      this.valueArray[this.props.theKey]
                    }`,
                    "MM-DD-YYYY HH:mm:ss"
                  ).add(15, "minutes") > moment()
                    ? ""
                    : "disable-cell"
                }`
          }
          tooltip={
            cellColor ? tooltipInfo : this.toolTipArray[this.props.theKey]
          }
        >
          <p
            className={cellColor ? this.props.position : ""}
            onClick={this.openModal}
            style={{
              padding: "5px",
              color: cellColor ? "transparent" : "#313131",
            }}
          >
            -
          </p>
        </div>
      </div>
    );
  }
}

export default Cell;
