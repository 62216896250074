import React from "react";

function ProjectTimelineStepOne({ height, width, svgStyles, color }) {
  return (
    <svg
      aria-hidden="true"
      height={height}
      width={width}
      fill={color}
      style={svgStyles}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 35"
    >
      <path
        d="M28.4444 4.00032H21.0133C20.6493 2.97735 19.9713 2.09101 19.0732 1.46393C18.175 0.836841 17.1011 0.5 16 0.5C14.8989 0.5 13.825 0.836841 12.9268 1.46393C12.0287 2.09101 11.3507 2.97735 10.9867 4.00032H3.55556C2.61343 4.00309 1.71069 4.37272 1.04451 5.02849C0.378319 5.68427 0.00281374 6.57289 0 7.50028V32C0.00281374 32.9274 0.378319 33.8161 1.04451 34.4718C1.71069 35.1276 2.61343 35.4972 3.55556 35.5H28.4444C29.3866 35.4972 30.2893 35.1276 30.9555 34.4718C31.6217 33.8161 31.9972 32.9274 32 32V7.50028C31.9972 6.57289 31.6217 5.68427 30.9555 5.02849C30.2893 4.37272 29.3866 4.00309 28.4444 4.00032ZM16 4.00032C16.3516 4.00032 16.6953 4.10295 16.9877 4.29524C17.28 4.48753 17.5079 4.76084 17.6425 5.08061C17.777 5.40038 17.8122 5.75224 17.7436 6.09171C17.675 6.43117 17.5057 6.74299 17.2571 6.98773C17.0085 7.23246 16.6917 7.39914 16.3468 7.46666C16.002 7.53418 15.6445 7.49953 15.3197 7.36707C14.9948 7.23462 14.7172 7.01032 14.5218 6.72254C14.3265 6.43476 14.2222 6.09642 14.2222 5.7503C14.2236 5.2866 14.4114 4.84229 14.7445 4.51441C15.0776 4.18652 15.5289 4.0017 16 4.00032ZM12.4444 12.7502L17.4044 17.6397L21.8844 13.2297L19.5556 11.0002H26.6667V18.0002L24.3982 15.706L17.408 22.5921L12.4444 17.7027L7.84711 22.2246L5.33333 19.7502L12.4444 12.7502ZM28.4444 30.2501H3.55556V26.7501H28.4444V30.2501Z"
        fill={color}
      />
      <defs>
        <linearGradient
          id="paint0_linear_5034_3999"
          x1="16"
          y1="0.5"
          x2="16"
          y2="35.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
      </defs>
    </svg>
  );
}

ProjectTimelineStepOne.defaultProps = {
  height: "35px",
  width: "32px",
  svgStyles: {},
  color: "#519acc",
};

export default ProjectTimelineStepOne;

<svg
  xmlns="http://www.w3.org/2000/svg"
  width="32"
  height="36"
  viewBox="0 0 32 36"
  fill="none"
>
  <path
    d="M28.4444 4.00032H21.0133C20.6493 2.97735 19.9713 2.09101 19.0732 1.46393C18.175 0.836841 17.1011 0.5 16 0.5C14.8989 0.5 13.825 0.836841 12.9268 1.46393C12.0287 2.09101 11.3507 2.97735 10.9867 4.00032H3.55556C2.61343 4.00309 1.71069 4.37272 1.04451 5.02849C0.378319 5.68427 0.00281374 6.57289 0 7.50028V32C0.00281374 32.9274 0.378319 33.8161 1.04451 34.4718C1.71069 35.1276 2.61343 35.4972 3.55556 35.5H28.4444C29.3866 35.4972 30.2893 35.1276 30.9555 34.4718C31.6217 33.8161 31.9972 32.9274 32 32V7.50028C31.9972 6.57289 31.6217 5.68427 30.9555 5.02849C30.2893 4.37272 29.3866 4.00309 28.4444 4.00032ZM16 4.00032C16.3516 4.00032 16.6953 4.10295 16.9877 4.29524C17.28 4.48753 17.5079 4.76084 17.6425 5.08061C17.777 5.40038 17.8122 5.75224 17.7436 6.09171C17.675 6.43117 17.5057 6.74299 17.2571 6.98773C17.0085 7.23246 16.6917 7.39914 16.3468 7.46666C16.002 7.53418 15.6445 7.49953 15.3197 7.36707C14.9948 7.23462 14.7172 7.01032 14.5218 6.72254C14.3265 6.43476 14.2222 6.09642 14.2222 5.7503C14.2236 5.2866 14.4114 4.84229 14.7445 4.51441C15.0776 4.18652 15.5289 4.0017 16 4.00032ZM12.4444 12.7502L17.4044 17.6397L21.8844 13.2297L19.5556 11.0002H26.6667V18.0002L24.3982 15.706L17.408 22.5921L12.4444 17.7027L7.84711 22.2246L5.33333 19.7502L12.4444 12.7502ZM28.4444 30.2501H3.55556V26.7501H28.4444V30.2501Z"
    fill="url(#paint0_linear_5034_3999)"
  />
  <defs>
    <linearGradient
      id="paint0_linear_5034_3999"
      x1="16"
      y1="0.5"
      x2="16"
      y2="35.5"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#519ACC" />
      <stop offset="1" stopColor="#2B88C8" />
    </linearGradient>
  </defs>
</svg>;
