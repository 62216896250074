import React from "react";
function SortIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="4"
      viewBox="0 0 18 4"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4142 3.41421C17.0391 3.78929 16.5304 4 16 4C15.4696 4 14.9609 3.78929 14.5858 3.41421C14.2107 3.03914 14 2.53043 14 2C14 1.46957 14.2107 0.960859 14.5858 0.585787C14.9609 0.210715 15.4696 4.94038e-07 16 5.4041e-07C16.5304 5.86782e-07 17.0391 0.210715 17.4142 0.585787C17.7893 0.960859 18 1.46957 18 2C18 2.53043 17.7893 3.03914 17.4142 3.41421ZM10.4142 3.41421C10.0391 3.78928 9.53043 4 9 4C8.46957 4 7.96086 3.78928 7.58579 3.41421C7.21071 3.03914 7 2.53043 7 2C7 1.46957 7.21071 0.960858 7.58579 0.585786C7.96086 0.210714 8.46957 -1.17921e-07 9 -7.15493e-08C9.53043 -2.51774e-08 10.0391 0.210714 10.4142 0.585786C10.7893 0.960859 11 1.46957 11 2C11 2.53043 10.7893 3.03914 10.4142 3.41421ZM3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4C1.46956 4 0.960859 3.78928 0.585785 3.41421C0.210711 3.03914 1.28474e-07 2.53043 1.74846e-07 2C2.21217e-07 1.46957 0.210711 0.960859 0.585785 0.585786C0.96086 0.210713 1.46956 -7.29881e-07 2 -6.83509e-07C2.53043 -6.37137e-07 3.03914 0.210713 3.41421 0.585786C3.78929 0.96086 4 1.46957 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421Z"
        fill={color}
      />
    </svg>
  );
}

SortIcon.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "#519acc",
};

export default SortIcon;
