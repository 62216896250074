import React, { useMemo, useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ExitIcon from "components/icons/exit_icon";

const ANIMATION_DURATION = 300;

export default function FilterPillbox(props) {
  const { filterKey, selectedValues, label, clearFilter } = props;

  const [isEntering, setIsEntering] = useState(true);
  const [isExiting, setIsExiting] = useState(false);

  const valuesString = useMemo(() => {
    return selectedValues.map(({ name }) => name).join(", ");
  }, [selectedValues]);

  const handleRemove = useCallback(() => {
    setIsExiting(true);
    clearFilter(filterKey);
  }, [filterKey, clearFilter]);

  useEffect(() => {
    setTimeout(() => {
      setIsEntering(false);
    }, ANIMATION_DURATION);
  }, []);

  if (!selectedValues || selectedValues.length === 0) {
    return null;
  }

  return (
    <Pill $isEntering={isEntering} $isExiting={isExiting} key={filterKey}>
      <PillText>{`${label}: ${valuesString}`}</PillText>
      <RemoveFilterButton onClick={handleRemove} type="button">
        <ExitIcon color="#578bc2" />
      </RemoveFilterButton>
    </Pill>
  );
}

const Pill = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  background-color: #578bc2;
  border-radius: 20px;
  width: fit-content;
  transition: all ${ANIMATION_DURATION}ms;

  ${({ $isEntering }) =>
    $isEntering &&
    `
    animation-name: enter;
    animation-duration: ${ANIMATION_DURATION}ms;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    animation-fill-mode: forwards;

    @keyframes enter {
      0% {
        transform: scaleX(0.9) translateX(-15%);
        opacity: 0;
      }
      100% {
        transform: scaleX(1) translateX(0);
        opacity: 1;
      }
    }
  `}

  ${({ $isExiting }) =>
    $isExiting &&
    `
    animation-name: exit;
    animation-duration: ${ANIMATION_DURATION}ms;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    animation-fill-mode: forwards;
    overflow: hidden;

    @keyframes exit {
      0% {
        transform: scaleX(1) translateX(0);
        opacity: 1;
      }
      100% {
        transform: scaleX(0.9) translateX(-15%);
        opacity: 0;
      }
    }
  `}
`;

const PillText = styled.div`
  margin-right: 5px;
  color: #ffffff;
  font-size: 11px;
  line-height: 15px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
`;

const RemoveFilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #ffffff;
  font-size: 11px;
  line-height: 15px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;

  &:active,
  &:focus {
    background-color: transparent;
    outline: none;
    border: none;
  }
`;

FilterPillbox.propTypes = {
  filterKey: PropTypes.string.isRequired,
  selectedValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  label: PropTypes.string.isRequired,
  clearFilter: PropTypes.func.isRequired,
};
