import React from "react";

const OrangeRoomIcon = () => {
  const maskStyles = {
    maskType: "luminance",
    maskUnits: "userSpaceOnUse",
    x: "1",
    y: "1",
    width: "21",
    height: "21",
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <mask id="mask0_2794_1183" style={maskStyles}>
        <path
          d="M17.5038 17.425C21.6048 13.324 21.6048 6.67595 17.5038 2.57495L2.65527 17.425C6.75577 21.525 13.4043 21.525 17.5048 17.425H17.5038Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5037 1.57495C17.769 1.57494 18.0233 1.6803 18.2109 1.86784C22.6929 6.34989 22.7024 13.6098 18.2393 18.1036C18.2303 18.1132 18.2212 18.1227 18.2118 18.1321C13.7208 22.6226 6.43922 22.6226 1.94821 18.1321C1.76066 17.9446 1.65529 17.6902 1.65527 17.425C1.65526 17.1598 1.7606 16.9054 1.94813 16.7179L16.7966 1.86788C16.9842 1.68033 17.2385 1.57496 17.5037 1.57495ZM16.7718 16.7436C16.7799 16.7349 16.7882 16.7263 16.7967 16.7178C20.2683 13.2462 20.4918 7.7573 17.467 4.02597L4.10627 17.3881C7.828 20.4045 13.2993 20.1896 16.7718 16.7436Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.38427 9.28083C9.57931 9.09239 9.84252 8.99125 10.1136 9.00059L20.3671 9.35409C20.919 9.37312 21.351 9.836 21.332 10.388C21.313 10.9399 20.8501 11.3719 20.2981 11.3529L12.5801 11.0868L18.2112 16.7179C18.6017 17.1084 18.6017 17.7416 18.2112 18.1321C17.8207 18.5226 17.1875 18.5226 16.797 18.1321L11.0792 12.4143L11.0796 20.6065C11.0796 21.1587 10.6319 21.6065 10.0796 21.6065C9.52736 21.6065 9.07963 21.1588 9.0796 20.6065L9.0791 10C9.07909 9.72885 9.18923 9.46927 9.38427 9.28083Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0714 6.52655C20.6082 6.39671 21.1486 6.72663 21.2785 7.26344C22.1889 11.028 21.1757 15.1675 18.2336 18.1096C15.291 21.0522 11.1509 22.0655 7.38673 21.1545C6.84995 21.0245 6.52012 20.4841 6.65004 19.9473C6.77997 19.4105 7.32044 19.0807 7.85723 19.2106C10.9721 19.9645 14.39 19.1248 16.8194 16.6954C19.2482 14.2665 20.088 10.8491 19.3345 7.7336C19.2047 7.1968 19.5346 6.65638 20.0714 6.52655ZM12.5545 7.52487C12.9451 7.91537 12.9451 8.54854 12.5546 8.93909L8.31213 13.1821C7.92163 13.5726 7.28846 13.5727 6.89792 13.1822C6.50737 12.7917 6.50733 12.1585 6.89783 11.768L11.1403 7.52496C11.5308 7.13441 12.164 7.13437 12.5545 7.52487Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2794_1183)">
        <path d="M0.428711 0H24.4287V24H0.428711V0Z" fill="white" />
      </g>
    </svg>
  );
};

export default OrangeRoomIcon;
