import React, { useState } from "react";
import styled from "styled-components";
import "react-dates/initialize";
import moment from "moment";
import ms from "components/modals/ModalStyled";
import SingleDateWrapper from "../../common/single_date_wrapper";
import { StageForm } from "./lead_stage";

const EstimateCard = styled.div``;

const EstimateRequest = (props) => {
  const { project, submitHandler } = props;
  const [estDueDate, setEstDueDate] = useState(
    project.est_due_date ? moment(project.est_due_date) : undefined
  );
  const [dateFocus, setDateFocus] = useState(false);
  const [focusCounter, setFocusCounter] = useState(0);

  function handleStartDateChange(date) {
    setEstDueDate(date);
  }

  function handleDateFocus(focused) {
    if (dateFocus && focused.focused && focusCounter === false) {
      setDateFocus(true);
      setFocusCounter(true);
    } else if (dateFocus && focused.focused && focusCounter) {
      setDateFocus(true);
      setFocusCounter(true);
    } else {
      setDateFocus(focused.focused);
      setFocusCounter(true);
    }
  }

  function submit(e) {
    e.preventDefault();
    const errors = [];
    const projectData = new FormData();

    if (estDueDate) {
      projectData.append("project[est_due_date]", estDueDate);
    } else {
      errors.push("Please select an estimate due date");
    }

    if (errors.length === 0) {
      submitHandler(projectData, "est_requesting", "est_sending", true);
    } else {
      // eslint-disable-next-line no-undef
      M.toast({
        html: errors.join(", "),
        classes: "danger",
      });
    }
  }

  return (
    <EstimateCard>
      <StageForm
        encType="multipart/formdata"
        onSubmit={submit}
        className="flex-container flex-column flex-al-center"
      >
        <ms.FormRowDouble style={{ alignItems: "flex-end" }}>
          <ms.FormItem>
            <ms.Label className="custom-labels" htmlFor="est_due_date">
              Estimate Due Date
              <span className="base-color">*</span>
            </ms.Label>
            <SingleDateWrapper
              date={estDueDate}
              onDateChange={handleStartDateChange}
              focused={dateFocus}
              onFocusChange={handleDateFocus}
              label={null}
              placeholder="Select a date"
            />
          </ms.FormItem>
          <ms.FormItem>
            <button
              type="button"
              onClick={submit}
              className="common-button-submit"
            >
              Save All Changes
            </button>
          </ms.FormItem>
        </ms.FormRowDouble>
      </StageForm>
    </EstimateCard>
  );
};

export default EstimateRequest;
