const baseColor = "#519acc";
const darkGrey = "#6e6e6e";
const baseBlack = "#313131";
const lightGrey = "#f0f0f0";
const colors = [
  "#519acc",
  "#d76f23",
  "#b82a23",
  "#6f79b8",
  "#20835d",
  "#d4487a",
  "#246995",
  "#cb9326",
  "#1bb3d4",
];
const MultiCheckedSelectStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "white",
    borderRadius: "15px",
    boxShadow: state.isFocused
      ? `1px 1px 1px 0px ${baseColor}`
      : `1px 1px 3px 0px ${lightGrey}`,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: baseColor,
    minHeight: "42px",
    maxHeight: "42px",
  }),
  container: (styles) => ({
    ...styles,
    width: "250px",
    fontFamily: "Arial",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    height: "100%",
    marginTop: "0",
    backgroundColor: "transparent",
  }),
  input: (styles) => ({
    ...styles,
    boxShadow: "none !important",
    margin: "0",
    padding: "0",
    height: "25px",
  }),
  menu: (styles) => ({
    ...styles,
    transform: "translateX(-25px)",
    width: "300px",
    borderRadius: "5px",
    boxShadow: "0 0 9px 2px hsla(0,0%,0%,0.3), 0 4px 11px hsla(0,0%,0%,0.1)",
    zIndex: 2,
  }),
  placeholder: (styles, state) => ({
    ...styles,
    marginLeft: state.hasValue ? "30px" : "",
  }),
  menuList: (styles) => ({ ...styles, borderRadius: "5px" }),
  valueContainer: (styles, state) => ({
    ...styles,
    maxHeight: "300px",
    paddingTop: state.hasValue ? "10px" : "",
    order: 2,
    top: state.hasValue ? "30px" : "",
    left: state.hasValue ? "-10px" : "",
    position: state.hasValue ? "absolute" : "",
    width: "450px",
  }),
  indicatorsContainer: (styles, state) => ({
    ...styles,
    color: darkGrey,
    maxHeight: "42px",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: `${baseColor} !important`,
    cursor: "pointer",
  }),
  multiValue: (styles, state) => {
    return {
      ...styles,
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "400",
      textAlign: "center",
      borderRadius: "10px",
      fontSize: "13px",
      color: "white",
      backgroundColor: baseColor,
    };
  },
  multiValueLabel: (styles, state) => {
    return {
      ...styles,
      color: "white",
      padding: "4px",
      paddingLeft: "12px",
    };
  },
  multiValueRemove: (styles, state) => {
    return {
      ...styles,
      borderRadius: "10px",
      color: "white",
      cursor: "pointer",
      backgroundColor: "transparent !important",
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? "white"
        : isSelected
        ? baseColor
        : isFocused
        ? baseColor
        : "white",
      fontFamily: "Arial",
      color: isFocused ? "white" : isSelected ? "white" : baseBlack,
      cursor: isDisabled ? "not-allowed" : "default",
      paddingLeft: "25px",
    };
  },
};

export default MultiCheckedSelectStyles;
