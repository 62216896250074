import * as Sentry from "@sentry/react";

export const sentryInit = () => {
  let dsn =
    "https://0ac590b62f73416f94c2755572485e37@o345981.ingest.sentry.io/5540553";
  if (process.env.NODE_ENV === "production") {
    dsn =
      "https://2c8294904f084c60a05cfd6d025bc5e2@o345981.ingest.sentry.io/5531218";
  }
  Sentry.init({
    dsn,
  });
};

export const roundInput = (input, originalState) => {
  let value = "0.00";
  if (input !== originalState) {
    if (input) {
      if (input < 0) {
        value = Math.abs(input);
      }
      if (input <= 0.12) {
        value = "0.25";
      } else {
        value = (Math.round(input * 4) / 4).toFixed(2);
      }
    }
  }
  return value;
};

export const normalizePhone = (input) => {
  return `(${input.substring(1, 4)}) ${input.substring(6, 9)}-${input.substring(
    10
  )}`;
};

export const setAlphaGroups = (items) => {
  const alphaGroups = {};
  items.forEach((i) => {
    if (i.active && i.name) {
      const firstChar = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g.test(
        i.name.charAt(0)
      )
        ? "#"
        : /\p{Emoji_Presentation}/gu.test(i.name)
        ? "#"
        : isNaN(i.name.toUpperCase().charAt(0))
        ? i.name.toUpperCase().charAt(0)
        : "#";
      if (firstChar in alphaGroups) {
        alphaGroups[firstChar].push(i);
      } else {
        alphaGroups[firstChar] = [i];
      }
    } else if ("inactive" in alphaGroups) {
      alphaGroups.inactive.push(i);
    } else {
      alphaGroups.inactive = [i];
    }
  });
  return alphaGroups;
};
