import React from "react";

class OverbookedProjectRow extends React.Component {
  constructor(props) {
    super(props);

    this.renderStatus = this.renderStatus.bind(this);
    this.editTask = this.editTask.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
  }

  onDragStart(e) {
    const data = {
      taskId: this.props.taskId,
      multitaskId: this.props.multitaskId,
      taskName: this.props.taskName,
      userName: this.props.userName,
      date: this.props.date,
      created_by: this.props.created_by,
    };
    e.dataTransfer.effectAllowed = "all";
    e.dataTransfer.setData("text", JSON.stringify(data));
  }

  editTask() {
    const data = {
      taskId: this.props.taskId,
      multitaskId: this.props.multitaskId,
      taskName: this.props.taskName,
      userName: this.props.userName,
      date: this.props.date,
      created_by: this.props.created_by,
      userId: this.props.userId,
    };
    this.props.modalAction(true, "project-edit-task", data);
  }

  renderStatus() {
    const status = this.props.taskStatus;
    if (
      this.props.taskName === "Unavailability" ||
      this.props.taskName === "Vacation" ||
      this.props.taskName === "Unavailable" ||
      this.props.taskName === "PT Limit" ||
      this.props.taskName === "Sick Time" ||
      this.props.taskName === "Reoccurring: Unavailable"
    ) {
      return <div />;
    }
    if (status === null) {
      return <div className="nil-status-col" />;
    }
    if (status === true) {
      return <div className="true-status-col" />;
    }
    if (status === false) {
      return <div className="false-status-col" />;
    }
    return <div />;
  }

  render() {
    const name = this.props.taskName;
    let cardColor;
    // Code for designating card colors for special projects
    if (
      name === "Vacation" ||
      name === "Unavailable" ||
      name === "Unavailability" ||
      this.props.taskName === "PT Limit" ||
      this.props.taskName === "Sick Time" ||
      this.props.taskName === "Reoccurring: Unavailable"
    ) {
      cardColor = "#000000";
    } else {
      cardColor = this.props.cardColor;
    }
    const textColor = "#FFFFFF";
    return (
      <div
        className="row card day-card valign-wrapper hover"
        draggable
        onDragStart={this.onDragStart}
        onClick={this.editTask}
        style={{ backgroundColor: cardColor, color: textColor }}
      >
        <div
          className={`col ${
            this.props.twoWeeks
              ? "s11 truncate card-proj-name-small"
              : "s8 m6 l8 truncate card-proj-name"
          }`}
        >
          {this.props.taskName}
        </div>
        <div
          className={`col s1 card-proj-name${
            this.props.twoWeeks ? "-small" : ""
          }`}
          style={{ padding: `${this.props.twoWeeks ? "0 1rem" : "0 0.75rem"}` }}
        >
          <i className="inline-icon material-icons yellow-text ">warning</i>
        </div>
        <div
          className={`col s1 card-proj-name${
            this.props.twoWeeks ? "-small" : ""
          }`}
        >
          {this.props.taskHours}
        </div>
        {this.props.twoWeeks ? (
          ""
        ) : (
          <div className="col s2">{this.renderStatus()}</div>
        )}
      </div>
    );
  }
}

export default OverbookedProjectRow;
