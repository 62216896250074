import React from "react";
import styled from "styled-components";
import moment from "moment";
import TimeTrackingDateNav from "./time_tracking_date_nav";

const NavHeading = styled.div`
  width: 100%;
  padding: 20px;
  border-bottom: solid 1px #b9b9b9;
  display: flex;
  align-items: center;
  position: relative;
`;
const DateCard = styled.div.attrs((props) => ({
  selectedDay: props.selectedDay,
}))`
  width: 20%;
  height: 105px;
  text-align: center;
  background-color: ${(props) => (props.selectedDay ? "#efefef" : "white")};
  border-bottom: solid 4px
    ${(props) => (props.selectedDay ? "#519acc" : "white")};
  font-family: Manrope;
  cursor: pointer;

  span {
    color: #519acc;
    display: block;
    margin-top: 7px;
  }
`;
const WeekDateCard = styled.div.attrs((props) => ({
  hasHours: props.hasHours,
}))`
  width: 100px;
  height: 100%;
  text-align: center;
  background-color: ${(props) => (props.hasHours ? "#efefef" : "white")};
  border-bottom: solid 4px ${(props) => (props.hasHours ? "#519acc" : "white")};
  font-family: Manrope;

  span:first-child {
    display: block;
    margin-bottom: 7px;
    font-weight: 600;
  }

  span:last-child {
    color: #519acc;
    display: block;
    margin-top: 7px;
  }
`;

class TimeEntryNav extends React.Component {
  constructor(props) {
    super(props);
    this.today = moment().format("YYYY-MM-DD");
  }

  setSelectedDay = (e) => {
    var day = e.target ? e.target.dataset.day : e;
    this.props.setSelectedDay(day);
  };

  setSelectedDayPicker = (date) => {
    if (
      moment(date).isBetween(
        moment(this.props.selectedDay).startOf("week"),
        moment(this.props.selectedDay).endOf("week"),
        undefined,
        []
      )
    ) {
      this.props.setSelectedDay(moment(date).format("YYYY-MM-DD"));
    } else {
      this.props.updateRangeData(
        this.props.weekView ? "week" : "day",
        moment(date).startOf("week").add(1, "day").format("YYYY-MM-DD"),
        moment(date).endOf("week").subtract(1, "day").format("YYYY-MM-DD"),
        moment(date).format("YYYY-MM-DD")
      );
    }
  };

  render() {
    return (
      <div style={{ width: "100%" }}>
        <TimeTrackingDateNav
          selectedDay={this.props.selectedDay}
          setSelectedDay={this.setSelectedDay}
          startDate={this.props.weekRange[0]}
          endDate={this.props.weekRange[this.props.weekRange.length - 1]}
          selectedDateRangeOption={
            this.props.weekView
              ? { label: "Week", value: "week" }
              : { label: "Day", value: "day" }
          }
          todayInRange={this.props.selectedDay === this.today}
          updateRangeData={this.props.updateRangeData}
          setWeekView={this.props.setWeekView}
          setSelectedDayPicker={this.setSelectedDayPicker}
          handleSingleFocus={this.props.handleSingleFocus}
          setSingleFocus={this.props.setSingleFocus}
          singleDateFocus={this.props.singleDateFocus}
          type="buttons"
          page="time-entry"
        />
        <div
          aria-hidden="true"
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {!this.props.weekView ? (
            this.props.weekRange.map((d) => {
              var hours = this.props.timeEntries[d]
                ? this.props.timeEntries[d]
                    .map((t) => t.hours)
                    .reduce((a, b) => a + b)
                : 0;
              return (
                <DateCard
                  onClick={this.setSelectedDay}
                  data-day={moment(d).format("YYYY-MM-DD")}
                  role="button"
                  key={d}
                  selectedDay={this.props.selectedDay === d}
                >
                  <p style={{ pointerEvents: "none" }}>
                    {moment(d).format("dddd")}
                    <br />
                    {moment(d).format("MM/DD")}
                    <br />
                    <span>{hours.toFixed(2)} hours</span>
                  </p>
                </DateCard>
              );
            })
          ) : (
            <>
              <div
                style={{ width: "calc(100% - 640px)", height: "100px" }}
              ></div>
              <div
                id="time-entry-week-heading"
                style={{
                  width: "640px",
                  display: "flex",
                  position: "relative",
                  left: "0",
                }}
              >
                {this.props.weekRange.map((d) => {
                  var hours = this.props.timeEntries[d]
                    ? this.props.timeEntries[d]
                        .map((t) => t.hours)
                        .reduce((a, b) => a + b)
                    : 0;
                  return (
                    <WeekDateCard key={`${d}-week`} hasHours={hours > 0}>
                      <p>
                        <span>{moment(d).format("ddd")}</span>
                        {moment(d).format("MM/DD")}
                        <br />
                        <span>{hours.toFixed(2)}</span>
                      </p>
                    </WeekDateCard>
                  );
                })}
              </div>
              {Object.keys(this.props.timeEntryWeeks).length < 1 && (
                <p
                  aria-hidden="true"
                  style={{
                    paddingTop: "15px",
                    textAlign: "center",
                    color: "#bfbfbf",
                  }}
                >
                  There are currently no time entries for this week.
                </p>
              )}
            </>
          )}
        </div>
        {!this.props.weekView && (
          <button
            onClick={this.props.timeEntryModal}
            id="new-time-entry-button"
            style={{ fontSize: "13px", margin: "15px 20px" }}
            className="common-button-submit"
          >
            New Time Entry
          </button>
        )}
      </div>
    );
  }
}

export default TimeEntryNav;
