import React from "react";
import axios from "axios";
import Select, { components } from "react-select";
import SingleSelectStyles from "../common/single_select_styles";
import RoundDropdown from "../projects/round_dropdown.jsx";
import ClockIcon from "../icons/clock_icon";

const breakingH4 = {
  width: "280px",
  lineHeight: "30px",
  overflow: "hidden",
  wordBreak: "break-all",
  textOverflow: "ellipsis",
};

class AddTimeEntryRow extends React.Component {
  constructor(props) {
    super(props);
    var taskOptions = {};
    props.data.projects.forEach((p) => {
      taskOptions[p.id] = p.time_entry_tasks.map((t) => ({
        value: t.id,
        label: t.name,
      }));
    });
    var projectOptions = this.props.data.clientOptions;
    // var combinedOptions = projectOptions.map((p)=>p.options)
    // combinedOptions = [].concat.apply([], combinedOptions)
    this.state = {
      projectOptions,
      taskOptions,
      selectedProject: null,
      selectedTask: null,
    };
    this.submitButton = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.roundDrop = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <RoundDropdown />
        </components.DropdownIndicator>
      );
    };
    this.singleValue = ({ children, ...props }) => {
      return (
        <components.SingleValue {...props}>
          <div>
            <p
              style={{
                margin: 0,
                fontSize: "11px",
                textTransform: "uppercase",
              }}
            >
              {props.data.clientLabel}
            </p>
            <p style={{ margin: 0, fontSize: "15px" }}>{children}</p>
          </div>
        </components.SingleValue>
      );
    };
  }

  closeModal = () => {
    this.props.modalAction(false, "", {});
  };

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    this.props.data.setBackDrop;
  }

  handleSubmit(e) {
    e.preventDefault();
    var data = this.props.data;
    var time_entry_week = {
      user_id: data.userId,
      project_id: this.state.selectedProject.value,
      week_start: data.weekRange[0],
      week_end: data.weekRange[4],
    };
    if (this.state.selectedTask) {
      time_entry_week.time_entry_task_id = this.state.selectedTask.value;
    }
    axios
      .post("/create_time_entry_week", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        time_entry_week,
      })
      .then((res) => {
        this.props.data.addEmptyTimeEntryWeek(res.data);
        M.toast({ html: `Time Entry Row Added`, classes: "green" });
        this.props.modalAction(false, "", {});
      })
      .catch((err) => {
        this.props.data.setLoading(false);
        console.error(err);
      });
  }

  handleProjectChange = (value) => {
    this.setState({ selectedProject: value, selectedTask: null });
  };

  handleTaskChange = (value) => {
    this.setState({ selectedTask: value });
  };

  render() {
    return (
      <div className="modal-content">
        <div className="flex-container flex-al-center flex-j-start">
          <h2
            style={{
              fontSize: "18px",
              display: "flex",
              alignItems: "center",
              marginTop: "12px",
              marginBottom: "7px",
              fontWeight: "600",
              fontFamily: "Manrope",
            }}
          >
            <div
              aria-hidden="true"
              style={{
                backgroundColor: "#519acc",
                overflow: "hidden",
                padding: "2px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0px 20px 0px 0px",
              }}
            >
              <ClockIcon height={34} width={34} color="white" />
            </div>
            Add a Time Entry Row
          </h2>
        </div>
        <form
          className="new_client"
          id="new_client"
          onSubmit={this.handleSubmit}
        >
          <div style={{ margin: "25px 0 10px" }} className="input-field">
            <Select
              classNamePrefix="react-select"
              options={this.state.projectOptions}
              // onChange={(p)=> {this.handleProjectChange({target: { label:p.label, value: p.value, index: i}})}}
              onChange={this.handleProjectChange}
              value={this.state.selectedProject}
              theme={this.getSelectTheme}
              blurInputOnSelect={true}
              styles={{
                ...SingleSelectStyles,
                ...{
                  control: (styles, state) => ({
                    ...styles,
                    height: state.hasValue ? "60px" : "42px",
                  }),
                  indicatorsContainer: (styles, state) => ({
                    ...styles,
                    height: state.hasValue ? "60px" : "42px",
                  }),
                  container: (styles) => ({ ...styles, width: "275px" }),
                },
              }}
              components={{
                DropdownIndicator: this.roundDrop,
                SingleValue: this.singleValue,
              }}
              placeholder="Select a Project"
              // isDisabled={this.props.data.disabled}
            />
          </div>
          <div style={{ margin: "30px 0 40px" }} className="flex-container">
            <Select
              classNamePrefix="react-select"
              options={
                this.state.selectedProject &&
                this.state.taskOptions[this.state.selectedProject.value]
                  ? this.state.taskOptions[
                      this.state.selectedProject.value
                    ].map((o) => ({
                      ...o,
                      isDisabled: this.props.data.timeEntryWeeks.some(
                        (tw) =>
                          tw.project_id === this.state.selectedProject.value &&
                          tw.time_entry_task_id === o.value
                      ),
                    }))
                  : []
              }
              // onChange={(p)=> {this.handleProjectChange({target: { label:p.label, value: p.value, index: i}})}}
              onChange={this.handleTaskChange}
              value={this.state.selectedTask}
              blurInputOnSelect={true}
              theme={this.getSelectTheme}
              styles={{
                ...SingleSelectStyles,
                ...{ container: (styles) => ({ ...styles, width: "240px" }) },
              }}
              components={{ DropdownIndicator: this.roundDrop }}
              placeholder="Select a Task"
              isDisabled={
                !this.state.selectedProject ||
                this.state.selectedProject.length === 0 ||
                this.state.selectedProject.noTasks
              }
            />
          </div>
          <div style={{ marginTop: "30px" }} className="button-row right-align">
            <button
              type="button"
              name="cancel"
              onClick={this.closeModal}
              className="common-button-cancel"
            >
              Cancel
            </button>
            <button
              disabled={
                !this.state.selectedProject ||
                (!this.state.selectedTask &&
                  !this.state.selectedProject.noTasks)
              }
              type="submit"
              name="commit"
              className="common-button-submit"
              ref={this.submitButton}
              style={{ marginRight: "0px" }}
            >
              Create Row
            </button>
          </div>
        </form>
      </div>
    );
  }
}
export default AddTimeEntryRow;
