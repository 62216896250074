import React from "react";
import AlphabetRow from "../common/clients_projects/alphabet_row";
import DynamicList, { createCache } from "react-window-dynamic-list";
import AutoSizer from "react-virtualized-auto-sizer";
import axios from "axios";
import styled from "styled-components";
import InactiveCardGroup from "../common/clients_projects/inactive_card_group";

const cache = createCache();

const ClientCardDiv = styled.div`
  width: 277px;
  height: 193px;
  padding: 16px 16px 0px;
  background-color: white;
  border: none;
  outline: none;
  border-radius: 10px;
  border: 1px solid #d7d7d7;
  margin-bottom: 4px;
  margin-right: 4px;
  position: relative;
  transition: background-color 0.4s, transform 0.4s;

  &:hover {
    border: 1px solid #519acc;
  }
  // &:hover > div > label {
  //   color: #519acc !important;
  // }
  // p {
  //   margin-top: 0px;
  //   color: #313131;
  // }
  // li {
  //   color: #313131;
  // }
  // .tag {
  //   min-width: 68px;
  //   padding: 5px 8px;
  //   font-family: "Open Sans", sans-serif;
  //   font-weight: 400;
  //   text-align: center;
  //   border-radius: 10px;
  //   font-size: 12px;
  //   display: inline-block;
  //   margin-right: 5px;
  //   color: white;
  // }
  // .tag-group {
  //   position: absolute;
  //   bottom: 15px;
  //   width: 220px;
  //   overflow: hidden;
  //   height: 28px;
  // }
  // .tag-plus {
  //   position: absolute;
  //   right: 15px;
  //   bottom: 18px;
  //   color: #2bb882;
  // }
`;

class ClientDirectory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: this.props.clients,
      search: "",
      selectedTag: [],
      selectedUser: [],
      selectedStatus: [],
      searchData: [],
    };
    this.onAddClientClick = this.onAddClientClick.bind(this);
  }

  getClients = () => {
    axios
      .get(`/api/v1/clients/`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.setState({
          clients: res.data,
        });
      })
      .catch((err) => console.error(err));
  };

  renderClients() {
    var indexNode = [];
    if (
      (this.props.search != "" && this.props.searchData.length === 0) ||
      (this.props.filtersActive && this.props.searchData.length === 0)
    ) {
      return <div className="noResultCard">No clients found</div>;
    } else {
      var clientCount = 0;
      Object.entries(this.props.alphaGroups).forEach((entry, i) => {
        if (entry[0] !== "inactive") {
          clientCount += entry[1].length;
          indexNode.push({
            id: entry[0],
            node: (
              <AlphabetRow
                onEditClientClick={this.props.onEditClientClick}
                type="client"
                loadClient={this.props.loadClient}
                index={entry[0]}
                clients={entry[1]}
                key={i}
              />
            ),
          });
        }
      });
      if (this.props.alphaGroups["inactive"]) {
        indexNode.push({
          id: "inactive-group",
          node: (
            <InactiveCardGroup
              onEditClientClick={this.props.onEditClientClick}
              type="client"
              heading="Inactive Projects"
              loadClient={this.props.loadClient}
              cardDiv={ClientCardDiv}
              clients={this.props.alphaGroups["inactive"]}
              key={"inactive-group"}
            />
          ),
        });
      }

      const row = ({ index, style }) => (
        <div style={style}>{indexNode[index].node}</div>
      );
      if (
        this.props.dynamicListRef &&
        this.props.dynamicListRef.current &&
        this.props.dynamicListRef.current._outerRef
      ) {
        this.props.dynamicListRef.current._outerRef.setAttribute(
          "aria-label",
          `Displaying ${clientCount} Clients`
        );
      }
      return (
        <AutoSizer>
          {({ height, width }) => (
            //need random number bewteen 0 and 1 added to list height to make the list items resize when using search/filters
            <DynamicList
              recalculateItemsOnResize={{ width: false, height: true }}
              height={
                height -
                150 +
                (this.props.search != "" || this.props.filtersActive
                  ? Math.random()
                  : 0)
              }
              width={width}
              cache={cache}
              data={indexNode}
              ref={this.props.dynamicListRef}
              style={{ scrollBehavior: "smooth" }}
            >
              {row}
            </DynamicList>
          )}
        </AutoSizer>
      );
    }
  }

  onAddClientClick = () => {
    this.props.setModal(true, "add-client", {
      tagOptions: this.props.tagOptions,
      closeButton: true,
      customClass: "sm-modal",
      backdropClickCheck: {
        title: "Discard New Client",
        text: "Are you sure that you want to discard this client?",
      },
    });
  };

  render() {
    return (
      <div style={{ height: "100%", padding: "16px 0px 16px 16px  " }}>
        {this.renderClients()}
      </div>
    );
  }
}

export default ClientDirectory;
