import React from "react";
import styled from "styled-components";
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const ProgressContainer = styled.div`
  position: relative;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin-bottom: 10px;
  box-shadow: inset 0 0 50px #000;
  background: #222;
  z-index: 1;
`;
const ProgressSvg = styled.svg`
  position: relative;
  width: 180px;
  height: 180px;
  z-index: 1;
  overflow: visible !important;
  transform: rotate(-90deg);
  & .circle1 {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: lightgray;
    stroke-width: 21;
    stroke-linecap: round;
  }
`;
const ProgressCircle = styled.circle.attrs((props) => ({
  startOffset: props.startOffset,
  endOffset: props.endOffset,
  delay: props.delay,
  duration: props.duration,
  color: props.color,
  index: props.index,
  identifier: props.identifier,
}))`
  width: 100%;
  height: 100%;
  fill: none;
  stroke: ${(props) => props.color};
  stroke-width: 16;
  //stroke-linecap: round;
  stroke-dasharray: 502.654824574366918;
  stroke-dashoffset: ${(props) =>
    props.isSafari ? 502.654824574366918 : -502.654824574366918};
  transition: stroke-dashoffset 1s;
  // transition-delay: 1s;
  // animation: progress${(props) => props.identifier} 1s forwards;
  animation-delay: 0.5s;
  // @keyframes progress${(props) =>
    props.identifier} { from { stroke-dashoffset: -502.654824574366918; } to { stroke-dashoffset: ${(
    props
  ) => props.endOffset}; } }
`;
const ProgressNumber = styled.div.attrs((props) => ({ label: props.label }))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: white;
  border: solid 19px lightgray;
  & h3 {
    color: #313131;
    padding-top: 22px;
    font-weight: 500;
    font-family: "Manrope", sans-serif;
    font-size: ${(props) => (props.label ? "30px" : "34px")};
    transition: 0.5s;
    margin: 0px;
    line-height: 18px;
    text-align: center;
  }
  h3 > span:nth-child(2) {
    font-size: 13px;
    color: #000000de;
  }
`;

class MultiProgressRing extends React.Component {
  // componentDidUpdate(){
  //   // if(this.props.rampUpPercent){
  //   //   this.rampUpPercent()
  //   // }
  // }

  freshLoad() {
    if (this.progressTimer) {
      clearTimeout(this.progressTimer);
      this.progressTimer = null;
    }
    this.progressTimer = setTimeout(() => {
      var offsetDiff = 0;
      var baseOffset = -502.654824574366918;
      var multiProgress = this.props.multiProgress;
      var elems = document.querySelectorAll(`#${this.props.id} > svg > circle`);
      if (elems && elems.length > 1) {
        var propIndex = 0;
        for (var x = elems.length - 1; x > 0; x--) {
          var endOffset =
            baseOffset * (1 - multiProgress[propIndex].percent / 100) +
            offsetDiff;
          offsetDiff = Math.abs(endOffset - baseOffset);
          if (isSafari) {
            endOffset = Math.abs(endOffset) * 2;
          }
          elems[x].style.strokeDashoffset = endOffset;
          propIndex += 1;
        }
      }
    }, 100);
  }

  rampUpPercent = () => {
    var elem = document.getElementById(
      this.props.id ? this.props.id : "percent-text"
    );
    if (elem) {
      var counter = 0;
      var percent =
        Math.round(
          this.props.percentDecimal
            ? this.props.percent * 100
            : this.props.percent
        ) / 20;
      var time = setInterval(() => {
        if (counter < 20) {
          elem.innerText = Math.round(percent * counter);
          counter++;
        } else {
          elem.innerText = Math.round(percent * counter);
          clearInterval(time);
        }
      }, 50);
    }
  };

  barMouseEnter = (e) => {
    var rect = e.target.getBoundingClientRect();
    var popUpElem = document.createElement("div");
    popUpElem.setAttribute("id", `${e.target.dataset.id}-pop-up`);
    popUpElem.setAttribute("class", `time-bar-tt`);
    popUpElem.style.top = `${e.pageY}px`;
    popUpElem.innerHTML = `${e.target.dataset.text}`;
    popUpElem.style.color = `black`;
    popUpElem.style.fontFamily = `Arial`;
    popUpElem.style.fontSize = `13px`;
    popUpElem.style.borderColor = `${e.target.dataset.color}`;
    popUpElem.style.borderWidth = "2px";
    var element = document.querySelector("body");
    element.appendChild(popUpElem);
    //need to set left after element is appended to parent element
    popUpElem.style.left = `${e.pageX + (rect.width / 2 - 60)}px`;
  };

  barMouseLeave = (e) => {
    var popUpElem = document.getElementById(`${e.target.dataset.id}-pop-up`);
    var element = document.querySelector("body");
    if (popUpElem) {
      element.removeChild(popUpElem);
    }
  };

  render() {
    var offsetDiff = 0;
    var baseOffset = -502.654824574366918;
    if (isSafari) {
      baseOffset * -1;
    }
    this.freshLoad();
    return (
      <ProgressContainer id={this.props.id}>
        <ProgressSvg
          percent={this.props.percent}
          percentDecimal={this.props.percentDecimal}
        >
          <circle
            shapeRendering="geometricPrecision"
            className="circle1"
            cx="90"
            cy="90"
            r="80"
          ></circle>
          {this.props.multiProgress
            .map((p, i) => {
              var endOffset = baseOffset * (1 - p.percent / 100) + offsetDiff;
              offsetDiff = isNaN(Math.abs(endOffset - baseOffset))
                ? 0
                : Math.abs(endOffset - baseOffset);
              return (
                <ProgressCircle
                  onMouseEnter={this.barMouseEnter}
                  onMouseLeave={this.barMouseLeave}
                  data-text={`${p.name}: ${Math.round(p.percent)}%`}
                  data-offset={offsetDiff}
                  data-color={p.color}
                  shapeRendering="geometricPrecision"
                  index={i}
                  color={p.color}
                  isSafari={isSafari}
                  identifier={`${p.dateRange}-${i}`}
                  key={`${p.name}-${p.dateRange}-${i}`}
                  cx="90"
                  cy="90"
                  r="80"
                ></ProgressCircle>
              );
            })
            .reverse()}
          {this.props.multiProgress.length > 1}
        </ProgressSvg>
        <ProgressNumber label={this.props.label}>
          <h3>
            <span
              style={{
                color:
                  this.props.totalNumber > this.props.totalLimit
                    ? "#ff0000"
                    : "#313131",
              }}
            >
              {this.props.totalNumber.toFixed(2)}
              {/* {!this.props.rampUpPercent?Math.round(this.props.percentDecimal?this.props.percent*100:this.props.percent):0} */}
            </span>
            {/* {this.props.label && <span><br/>{this.props.label}</span>} */}
            <div>{this.props.text}</div>
          </h3>
        </ProgressNumber>
      </ProgressContainer>
    );
  }
}

MultiProgressRing.defaultProps = {
  percentDecimal: false, // set to true is using decimal number for percent prop (example: 0.6 instead of 60)
  percent: 0,
  totalLimit: Infinity,
  id: "multi-progress-1",
};

export default MultiProgressRing;
