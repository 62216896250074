import React from "react";
import moment from "moment";
import Slider from "react-rangeslider";

class MultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.updateWeek = this.updateWeek.bind(this);
  }

  updateWeek(event) {
    if (
      (this.props.bookings.length > 0 && this.props.editAll) ||
      this.props.bookings.length === 0
    ) {
      this.props.weeksHandler(event);
    }
  }

  componentDidMount() {
    if (this.props.bookings.length > 0 && !this.props.editAll) {
      document
        .querySelector(".rangeslider__fill")
        .classList.add("rangeslider__fill-disabled");
    }
  }

  render() {
    if (
      this.props.bookings.length > 0 &&
      !this.props.editAll &&
      document.querySelectorAll(".rangeslider__handle")[2]
    ) {
      document
        .querySelectorAll(".rangeslider__handle")[2]
        .classList.add("rangeslider__handle-disabled");
      document
        .querySelectorAll(".rangeslider__fill")[2]
        .classList.add("rangeslider__fill-disabled");
    } else if (this.props.bookings.length > 0 && this.props.editAll) {
      document
        .querySelectorAll(".rangeslider__handle")[2]
        .classList.remove("rangeslider__handle-disabled");
      document
        .querySelectorAll(".rangeslider__fill")[2]
        .classList.remove("rangeslider__fill-disabled");
    }

    return (
      <div className="col s12">
        <p>Number of weeks:</p>
        <Slider
          disabled
          min={1}
          max={10}
          value={this.props.weeks}
          handleLabel={this.props.weeks.toString()}
          onChange={this.updateWeek}
          tooltip
        />
        {this.props.bookings.length > 0 ? (
          <p>
            Started{" "}
            {moment(this.props.bookings[0].booking_date).format("MMM Do")},
            Recurring until{" "}
            {moment(this.props.bookings[0].booking_date)
              .add(this.props.weeks, "w")
              .format("MMM Do")}
          </p>
        ) : (
          <p>
            Recurring until{" "}
            {moment(this.props.currentDay, "MM-DD-YYYY")
              .add(this.props.weeks, "w")
              .format("MMM Do")}
          </p>
        )}
      </div>
    );
  }
}

export default MultiSelect;
