import React from "react";

function EditIcon({ height, width, svgStyles, color, ariaHidden }) {
  return (
    <svg
      height={height}
      width={width}
      fill={color}
      style={svgStyles}
      aria-hidden={ariaHidden || true}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4477 0.668034C12.7156 0.557098 13.0026 0.5 13.2925 0.5C13.5824 0.5 13.8695 0.557098 14.1373 0.668034C14.4051 0.77897 14.6485 0.941571 14.8534 1.14655C15.0584 1.35154 15.221 1.59489 15.332 1.86271C15.4429 2.13054 15.5 2.41759 15.5 2.70748C15.5 2.99736 15.4429 3.28442 15.332 3.55224C15.221 3.82006 15.0584 4.06341 14.8534 4.2684L10.2932 8.82868C9.95522 9.16661 9.52572 9.3962 9.05779 9.4898C9.05775 9.48981 9.05782 9.4898 9.05779 9.4898L6.94925 9.91183C6.70936 9.95984 6.46135 9.8848 6.28833 9.71184C6.11531 9.53888 6.04018 9.2909 6.0881 9.051L6.50933 6.94246C6.60326 6.47438 6.83376 6.04397 7.17154 5.7066C7.1715 5.70665 7.17159 5.70656 7.17154 5.7066L11.7316 1.14655C11.9366 0.941573 12.1799 0.778971 12.4477 0.668034ZM13.2925 1.96343C13.1948 1.96343 13.0981 1.98267 13.0078 2.02007C12.9175 2.05746 12.8355 2.11226 12.7664 2.18136L11.9991 2.94867L13.0516 4.00067L13.8186 3.2336C13.8877 3.16451 13.9425 3.08248 13.9799 2.99221C14.0173 2.90194 14.0366 2.80519 14.0366 2.70748C14.0366 2.60976 14.0173 2.51301 13.9799 2.42274C13.9425 2.33247 13.8877 2.25045 13.8186 2.18136C13.7495 2.11226 13.6675 2.05746 13.5772 2.02007C13.487 1.98267 13.3902 1.96343 13.2925 1.96343ZM12.0168 5.03547L10.9643 3.98347L8.20611 6.74163C8.07279 6.87477 7.98147 7.04475 7.94434 7.22947C7.94432 7.22957 7.94436 7.22937 7.94434 7.22947L7.73818 8.26147L8.77058 8.05484C8.95556 8.01784 9.12492 7.92731 9.25835 7.79388L12.0168 5.03547Z"
        fill="#519ACC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.82427 3.55584C2.59596 3.55584 2.377 3.64654 2.21556 3.80798C2.05412 3.96942 1.96343 4.18837 1.96343 4.41668V13.1757C1.96343 13.404 2.05412 13.623 2.21556 13.7844C2.377 13.9459 2.59596 14.0366 2.82427 14.0366H11.5833C11.8116 14.0366 12.0306 13.9459 12.192 13.7844C12.3535 13.623 12.4442 13.404 12.4442 13.1757V10.7869C12.4442 10.3828 12.7718 10.0552 13.1759 10.0552C13.58 10.0552 13.9076 10.3828 13.9076 10.7869V13.1757C13.9076 13.7922 13.6627 14.3834 13.2268 14.8192C12.7909 15.2551 12.1998 15.5 11.5833 15.5H2.82427C2.20783 15.5 1.61665 15.2551 1.18076 14.8192C0.744878 14.3834 0.5 13.7922 0.5 13.1757V4.41668C0.5 3.80025 0.744878 3.20906 1.18076 2.77318C1.61665 2.33729 2.20784 2.09242 2.82427 2.09242H5.2131C5.61722 2.09242 5.94482 2.42002 5.94482 2.82413C5.94482 3.22824 5.61722 3.55584 5.2131 3.55584H2.82427Z"
        fill="#519ACC"
      />
    </svg>
  );
}

EditIcon.defaultProps = {
  height: "16px",
  width: "16px",
  svgStyles: {},
  color: "#519acc",
};

export default EditIcon;
