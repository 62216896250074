import React from "react";
import moment from "moment";

// var date = this.props.day;

class DayColumn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dayToDisplay: this.props.day,
    };
    this.onSendDisplayDay = this.onSendDisplayDay.bind(this);
  }

  onSendDisplayDay() {
    this.props.displayDay(this.state.dayToDisplay);
  }

  render() {
    const date = this.props.day;
    const day = moment(date).format("dddd");
    const displayDate = moment(date).format("M/DD");
    const dayNode = [];
    const currentDay = moment();
    const { clickedDay } = this.props;

    const currentDayGrey =
      "col day-col center grey lighten-3 white-text current-day";
    const dateBackgroundColor = currentDayGrey;
    const notCurrentDay = "col day-col center";

    return (
      <div
        onClick={this.onSendDisplayDay}
        className={`col day-col center ${
          displayDate === currentDay.format("M/DD")
            ? "grey lighten-3 white-text current-day"
            : ""
        }`}
      >
        <div className="row">
          <p className="dark-gray-text no-bottom-margin">{day}</p>
          <p className="dark-gray-text no-top-margin">{displayDate}</p>
          <div className="divider full-divider" />
        </div>
      </div>
    );
  }
}

export default DayColumn;
