import React from "react";
import SvgShell from "./svg_shell";

function TeamIcon({ height, width, svgStyles, color }) {
  return (
    <SvgShell height={height} width={width} svgStyles={svgStyles} color={color}>
      <path d="M32.24,7a25,25,0,1,0,25,25A25,25,0,0,0,32.24,7Z" />
      <path
        fill="#fff"
        d="M41.66,35.38A9.93,9.93,0,0,1,42,38.15V42c-6.6,4.5-12.9,4.51-19.57,0V38.15a8.3,8.3,0,0,1,4.93-8,7.64,7.64,0,0,0,4.84,1.74,7.72,7.72,0,0,0,4.88-1.73A8.15,8.15,0,0,1,41.66,35.38Z"
      />
      <path
        fill="#fff"
        d="M22.61,31.49a10.66,10.66,0,0,0-2.18,6.66v2.79a11,11,0,0,1-8.63-2V36.36A5.54,5.54,0,0,1,15,31.05a5.75,5.75,0,0,0,6.94,0A7.68,7.68,0,0,1,22.61,31.49Z"
      />
      <path
        fill="#fff"
        d="M49.77,26.54a3.66,3.66,0,0,1-3.71,3.64,3.58,3.58,0,0,1-3.68-3.64,3.7,3.7,0,0,1,7.39,0Z"
      />
      <path
        fill="#fff"
        d="M44.06,38.15a11.77,11.77,0,0,0-.45-3.28,10.21,10.21,0,0,0-1.73-3.4,5.84,5.84,0,0,1,.71-.43,5.73,5.73,0,0,0,6.94,0,5.57,5.57,0,0,1,3.16,5.32v2.56a11.06,11.06,0,0,1-8.63,2Z"
      />
      <path
        fill="#fff"
        d="M22.12,26.54a3.71,3.71,0,1,1-3.7-3.68A3.65,3.65,0,0,1,22.12,26.54Z"
      />
      <path
        fill="#fff"
        d="M37.9,24.27a5.76,5.76,0,0,1-5.67,5.59,5.59,5.59,0,1,1,5.67-5.59Z"
      />
    </SvgShell>
  );
}

TeamIcon.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "#519acc",
};

export default TeamIcon;
