import React from "react";

function ClientIcon({ height, width, svgStyles, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
    >
      <path
        d="M14.5 29H9.66667H5C2.23858 29 0 26.7614 0 24V5C0 2.23858 2.23858 0 5 0H24C26.7614 0 29 2.23858 29 5V24C29 26.7614 26.7614 29 24 29H19.3333H14.5ZM14.5 16.1111C16.0574 16.1111 17.3866 15.5606 18.4875 14.4597C19.5884 13.3588 20.1389 12.0296 20.1389 10.4722C20.1389 8.91481 19.5884 7.58565 18.4875 6.48472C17.3866 5.3838 16.0574 4.83333 14.5 4.83333C12.9426 4.83333 11.6134 5.3838 10.5125 6.48472C9.41157 7.58565 8.86111 8.91481 8.86111 10.4722C8.86111 12.0296 9.41157 13.3588 10.5125 14.4597C11.6134 15.5606 12.9426 16.1111 14.5 16.1111ZM3.22222 24.4727C3.22222 25.1935 3.80651 25.7778 4.52727 25.7778H24.4727C25.1935 25.7778 25.7778 25.1935 25.7778 24.4727C25.7778 24.1224 25.6387 23.7848 25.3805 23.548C24.0225 22.3025 22.4697 21.3074 20.7221 20.5626C18.8017 19.7442 16.7276 19.3344 14.5 19.3333C12.2713 19.3333 10.1967 19.7431 8.27628 20.5626C6.52884 21.3083 4.9766 22.3034 3.61955 23.548C3.36135 23.7848 3.22222 24.1224 3.22222 24.4727Z"
        fill="url(#paint0_linear_2033_4831)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2033_4831"
          x1="14.5"
          y1="0"
          x2="14.5"
          y2="29"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
      </defs>
    </svg>
  );
}

ClientIcon.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "#519acc",
};

export default ClientIcon;
