import React from "react";
import moment from "moment";

class NoTasksRow extends React.Component {
  constructor(props) {
    super(props);

    this.addTask = this.addTask.bind(this);
  }

  addTask() {
    const { userName } = this.props;
    const { userId } = this.props;
    const { date } = this.props;
    const { firstDay } = this.props;
    const duration = moment(date) - moment(firstDay);
    const diff = new moment.duration(duration);
    const duration_days = diff.asDays();
    if (duration_days > 6) {
      var cookieDate = moment(date)
        .startOf("week")
        .subtract(7, "d")
        .format("YYYY-MM-DD");
    } else {
      var cookieDate = moment(date).startOf("week").format("YYYY-MM-DD");
    }
    Cookies.set("project_week_start", cookieDate, { path: "/" });
    const displayStart = moment(date).format("D MMMM, YYYY");
    const $start = $("#task_start_date").datepicker();
    const $multi_start = $("#multitask_recurring_start").datepicker();
    const start_picker = $start.datepicker();
    const multi_start_picker = $multi_start.datepicker();
    start_picker.datepicker("setDate", date._d);
    multi_start_picker.datepicker("setDate", date._d);
    const $end = $("#task_end_date").datepicker();
    const $multi_end = $("#multitask_recurring_end").datepicker();
    const end_picker = $end.datepicker();
    const multi_end_picker = $multi_end.datepicker();
    end_picker.datepicker("setDate", date._d);
    multi_end_picker.datepicker("setDate", date._d);
    $("#new-task-modal").modal("open");
    $("#modal_user_name").text(`Schedule ${userName}`);
    $("#task_user_id").val(userId);
    $("#multitask_user_id").val(userId);
    $("#task_start_date").val(displayStart);
    $("#task_end_date").val(displayStart);
  }

  render() {
    return (
      <div
        className="row card z-depth-0 no-task-card hover"
        onClick={this.addTask}
      >
        <div className="col s12 center">-</div>
      </div>
    );
  }
}

export default NoTasksRow;
