import React from "react";
import styled from "styled-components";
import RoundDropdown from "../projects/round_dropdown.jsx";
import CalendarIcon from "../icons/calendar_icon.jsx";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import SingleSelectStyles from "../common/single_select_styles";
import SingleSelectWrapper from "../common/single_select_wrapper";
import Select from "react-select";
const today = moment(new Date());

const NavHeading = styled.div`
  width: 100%;
  padding: 20px;
  border-bottom: solid 1px #b9b9b9;
  display: flex;
  align-items: center;
  font-family: Arial;
  justify-content: space-between;
  position: relative;
  > div:first-child button {
    background-color: white;
    border: solid 1px lightgray;
    height: 30px;
    width: 30px;
    cursor: pointer;
  }
  #time-entry-picker > button {
    border: 1px solid lightgray;
    align-items: center;
    align-self: center;
    cursor: pointer;
    top: -5px;
    position: absolute;
    padding: 5px 5px 2px 5px;
    z-index: 100;
    border-radius: 3px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    background-color: white;
  }
  > div:first-child button:hover {
    background-color: #e1e1e1 !important;
  }
  svg {
    pointer-events: none;
  }
  p {
    font-size: 18px;
    font-family: Arial;
  }
  p > span {
    font-weight: 600;
  }
`;

class TimeTrackingDateNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: today,
      endDate: today,
      end_picker_focus: false,
      start_picker_focus: false,
      focusCounter: false,
      custom: false,
      submitted: false,
    };
    this.dateRangeOptions =
      this.props.page === "time-entry"
        ? [
            { value: "day", label: "Day" },
            { value: "week", label: "Week" },
            { value: "custom", label: "Custom" },
          ]
        : [
            { value: "week", label: "Week" },
            { value: "month", label: "Month" },
            { value: "quarter", label: "Quarter" },
            { value: "year", label: "Year" },
            { value: "custom", label: "Custom" },
          ];
  }

  changeStart = (e) => {
    this.setState({ startDate: e });
  };

  changeEnd = (e) => {
    this.setState({ endDate: e });
  };

  getStartEndDates = (rangeType, oldStart, oldEnd, modifier) => {
    var oldStart = moment(oldStart);
    var oldEnd = moment(oldEnd);
    var rangeType = rangeType === "day" ? "week" : rangeType;
    if (modifier) {
      switch (rangeType) {
        case "month":
          return {
            startDate: oldStart.isSame(moment(oldStart).startOf(rangeType))
              ? oldStart.add(modifier, rangeType).format("YYYY-MM-DD")
              : oldStart
                  .add(modifier, rangeType)
                  .startOf(rangeType)
                  .format("YYYY-MM-DD"),
            endDate: oldEnd.isSame(moment(oldEnd).startOf(rangeType))
              ? oldEnd.add(modifier, rangeType).format("YYYY-MM-DD")
              : oldEnd
                  .add(modifier, rangeType)
                  .endOf(rangeType)
                  .format("YYYY-MM-DD"),
          };
        case "quarter":
          return {
            startDate: oldStart.isSame(moment(oldStart).startOf(rangeType))
              ? oldStart.add(modifier, rangeType).format("YYYY-MM-DD")
              : oldStart
                  .add(modifier, rangeType)
                  .startOf(rangeType)
                  .format("YYYY-MM-DD"),
            endDate: oldEnd.isSame(moment(oldEnd).startOf(rangeType))
              ? oldEnd.add(modifier, rangeType).format("YYYY-MM-DD")
              : oldEnd
                  .add(modifier, rangeType)
                  .endOf(rangeType)
                  .format("YYYY-MM-DD"),
          };
        case "year":
          return {
            startDate: oldStart.isSame(moment(oldStart).startOf(rangeType))
              ? oldStart.add(modifier, rangeType).format("YYYY-MM-DD")
              : oldStart
                  .add(modifier, rangeType)
                  .startOf(rangeType)
                  .format("YYYY-MM-DD"),
            endDate: oldEnd.isSame(moment(oldEnd).startOf(rangeType))
              ? oldEnd.add(modifier, rangeType).format("YYYY-MM-DD")
              : oldEnd
                  .add(modifier, rangeType)
                  .endOf(rangeType)
                  .format("YYYY-MM-DD"),
          };
        default:
          return {
            startDate: oldStart.add(modifier, rangeType).format("YYYY-MM-DD"),
            endDate: oldEnd.add(modifier, rangeType).format("YYYY-MM-DD"),
          };
      }
    } else {
      return {
        startDate:
          rangeType === "week"
            ? moment().startOf(rangeType).add(1, "days").format("YYYY-MM-DD")
            : moment().startOf(rangeType).format("YYYY-MM-DD"),
        endDate:
          rangeType === "week"
            ? moment().endOf(rangeType).add(-1, "days").format("YYYY-MM-DD")
            : moment().endOf(rangeType).format("YYYY-MM-DD"),
      };
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.custom === true &&
      this.state.custom === false &&
      this.state.submitted === true
    ) {
      this.setState({ submitted: false });
    }
  }

  updateCustomRange = (e) => {
    startDate = this.state.startDate;
    endDate = this.state.endDate;
    this.props.updateRangeData("custom", startDate, endDate);
    this.state;
  };

  updateRangeData = (e) => {
    if (e.value || e.target.dataset.option) {
      var selectedOption =
        this.props.type === "buttons" ? JSON.parse(e.target.dataset.option) : e;
      if (selectedOption.value === "custom") {
        this.setState({ custom: true, submitted: false });
      } else if (
        this.props.selectedDateRangeOption.value !== selectedOption.value
      ) {
        var { startDate, endDate } = this.getStartEndDates(
          selectedOption.value,
          this.props.startDate,
          this.props.endDate,
          null
        );
        this.props.updateRangeData(selectedOption, startDate, endDate);
        this.setState({ custom: false });
        var elem = document.getElementById("aria-custom-status");
        if (elem && selectedOption.label) {
          elem.innerText = `${selectedOption.label} selected`;
        }
      }
    } else {
      var modifier = parseInt(e.target.dataset.modifier);
      if (
        this.props.page === "time-entry" &&
        this.props.selectedDateRangeOption.value === "day" &&
        moment(this.props.selectedDay)
          .add(modifier, "day")
          .isBetween(this.props.startDate, this.props.endDate, undefined, "[]")
      ) {
        this.props.setSelectedDay(
          moment(this.props.selectedDay)
            .add(modifier, "day")
            .format("YYYY-MM-DD")
        );
      } else {
        var { startDate, endDate } = this.getStartEndDates(
          this.props.selectedDateRangeOption.value,
          this.props.startDate,
          this.props.endDate,
          modifier
        );
        this.props.updateRangeData(
          this.props.selectedDateRangeOption,
          startDate,
          endDate,
          modifier > 0 ? startDate : endDate
        );
      }
    }
  };

  validateDate = (input) => {
    return moment(input, "YYYY-MM-DD").isValid();
  };

  handleStartFocus = (focused) => {
    if (
      this.state.start_picker_focus &&
      focused.focused &&
      this.state.focusCounter === false
    ) {
      this.setState({ start_picker_focus: true, focusCounter: true });
    } else if (
      this.state.start_picker_focus &&
      focused.focused &&
      this.state.focusCounter
    ) {
      this.setState({ start_picker_focus: false, focusCounter: false });
    } else {
      this.setState({
        start_picker_focus: focused.focused,
        focusCounter: false,
      });
    }
  };

  handleEndFocus = (focused) => {
    // idk why but on focus happens twice on open, the focusCounter gets around this
    if (
      this.state.end_picker_focus &&
      focused.focused &&
      this.state.focusCounter === false
    ) {
      this.setState({ end_picker_focus: true, focusCounter: true });
    } else if (
      this.state.end_picker_focus &&
      focused.focused &&
      this.state.focusCounter
    ) {
      this.setState({ end_picker_focus: false, focusCounter: false });
    } else {
      this.setState({ end_picker_focus: focused.focused, focusCounter: false });
    }
  };

  isWeekendBlocked = (day) => {
    return moment(day).day() === 0 || moment(day).day() === 6 ? true : false;
  };

  isDayBlockedPast = (day) => {
    return false;
  };

  isEndDayBlockedPast = (day) => {
    return moment(day).isBefore(this.state.startDate);
  };

  makeDateLabel = (today, selectedOption) => {
    var modifier;
    if (
      today.isBetween(
        this.props.startDate,
        this.props.endDate,
        selectedOption.value,
        "[]"
      )
    ) {
      modifier = "This ";
    } else if (
      today
        .subtract(1, selectedOption.value)
        .isBetween(
          this.props.startDate,
          this.props.endDate,
          selectedOption.value,
          "[]"
        )
    ) {
      modifier = "Last ";
    } else if (
      today
        .add(2, selectedOption.value)
        .isBetween(
          this.props.startDate,
          this.props.endDate,
          selectedOption.value,
          "[]"
        )
    ) {
      modifier = "Next ";
    } else {
      modifier = "";
    }
    return `${modifier}${selectedOption.label} - `;
  };

  submitData = (e) => {
    var start = this.state.startDate;
    var end = this.state.endDate;
    if (!this.validateDate(start)) {
      M.toast({
        html: `Invalid Start Date`,
        displayLength: 5000,
        classes: "red",
      });
      return;
    }
    if (!this.validateDate(end)) {
      M.toast({
        html: `Invalid End Date`,
        displayLength: 5000,
        classes: "red",
      });
      return;
    }
    this.props.updateRangeData(
      { value: "custom", label: "Custom" },
      start.format("YYYY/MM/DD"),
      end.format("YYYY/MM/DD")
    );
    this.setState({ submitted: true });
  };

  calendarClick = () => {
    var elem = document.getElementById("single_date_picker_label");
    if (elem) {
      elem.setAttribute("aria-hidden", false);
      elem.focus();
    }
  };

  hideCalendar = () => {
    this.props.setSingleFocus(false);
    var elem = document.getElementById("single_date_picker_label");
    if (elem) {
      elem.setAttribute("aria-hidden", true);
    }
  };

  render() {
    var {
      selectedDateRangeOption: selectedOption,
      todayInRange,
      startDate,
      endDate,
      type,
      selectedDay,
    } = this.props;
    var today = moment(new Date());
    return (
      <NavHeading>
        <div>
          {selectedOption.value != "custom" && (
            <>
              <button
                aria-hidden="true"
                id="time-tracking-date-nav-left-arrow"
                data-modifier={-1}
                onClick={this.updateRangeData}
              >
                <RoundDropdown
                  rotate="rotate(90deg)"
                  height={15}
                  width={15}
                  svgStyles={{ verticalAlign: "sub" }}
                />
              </button>
              <button
                aria-hidden="true"
                id="time-tracking-date-nav-right-arrow"
                data-modifier={1}
                onClick={this.updateRangeData}
              >
                <RoundDropdown
                  rotate="rotate(-90deg)"
                  height={15}
                  width={15}
                  svgStyles={{ verticalAlign: "sub" }}
                />
              </button>
            </>
          )}
          <p
            aria-label={
              selectedOption.value === "day"
                ? `Showing time entries for ${
                    todayInRange ? "Today, " : ""
                  } ${moment(selectedDay).format(
                    "dddd, MMMM Do, YYYY"
                  )}. Press shift+N or shift+P anywhere on the page to navigate to next or previous day, respectively.`
                : `Showing reports for ${
                    selectedOption.label
                  } range, ${`${moment(startDate).format("MMM DD")} - ${moment(
                    endDate
                  ).format("MMM DD YYYY")}`}
                  . ${
                    selectedOption.label !== "Custom"
                      ? `Press shift+N or shift+P anywhere on the page to navigate to next or previous ${selectedOption.label}, respectively.`
                      : ""
                  }`
            }
            style={{
              display: "inline-block",
              marginLeft: `${
                selectedOption.value === "custom" ? "-4px" : "20px"
              }`,
              fontFamily: "Manrope",
              verticalAlign: "middle",
            }}
          >
            {/* {todayInRange && ( <span aria-hidden="true" style={{ fontWeight: 600 }}> {selectedOption.value === "day" ? "Today - " : `This ${selectedOption.label} - `} </span> )} */}
            {selectedOption.value === "day" ? (
              <span aria-hidden="true">
                {" "}
                {moment(selectedDay).format("dddd, MMMM Do, YYYY")}{" "}
              </span>
            ) : (
              <>
                <span aria-hidden="true" style={{ fontWeight: 600 }}>
                  {selectedOption.value === "custom"
                    ? `${selectedOption.label} Range - `
                    : this.makeDateLabel(today, selectedOption)}
                </span>
                <span aria-hidden="true" style={{ fontWeight: 400 }}>{`${moment(
                  startDate
                ).format("MMM DD")} - ${moment(endDate).format(
                  "MMM DD YYYY"
                )}`}</span>
              </>
            )}
          </p>
          {this.props.page === "time-entry" && (
            <div id="time-entry-picker">
              <button
                aria-label="Custom date picker"
                onFocus={this.hideCalendar}
                onClick={this.calendarClick}
              >
                <CalendarIcon width="25px" height="25px" color="#519acc" />
              </button>
              <label
                id="single_date_picker_label"
                htmlFor="single_date_picker"
                aria-hidden={true}
                style={{ outline: "none " }}
              >
                <SingleDatePicker
                  date={moment(selectedDay)} // momentPropTypes.momentObj or null
                  onDateChange={this.props.setSelectedDayPicker} // PropTypes.func.isRequired
                  focused={this.props.singleDateFocus} // PropTypes.bool
                  onFocusChange={this.props.handleSingleFocus} // PropTypes.func.isRequired
                  numberOfMonths={1}
                  placeholder={"Select a date"}
                  orientation={"horizontal"}
                  daySize={30}
                  navPrev={
                    <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                      <RoundDropdown rotate={"rotate(90deg)"} />
                    </div>
                  }
                  navNext={
                    <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                      <RoundDropdown rotate={"rotate(-90deg)"} />
                    </div>
                  }
                  hideKeyboardShortcutsPanel
                  inputIconPosition={"after"}
                  customInputIcon={<RoundDropdown />}
                  isOutsideRange={this.isWeekendBlocked}
                  id="single_date_picker" // PropTypes.string.isRequired,
                />
              </label>
            </div>
          )}
        </div>
        <div>
          {type === "buttons" ? (
            <>
              <button
                aria-label={`Day view, ${
                  selectedOption.value === "day" ? "selected" : "unselected"
                }`}
                style={{
                  marginRight: "5px",
                  pointerEvents: `${
                    selectedOption.value === "day" ? "none" : "all"
                  }`,
                }}
                className={`${
                  selectedOption.value === "day"
                    ? "common-button-submit no-border"
                    : "common-button-cancel"
                }`}
                onClick={this.props.setWeekView}
                onFocus={this.hideCalendar}
              >
                Day
              </button>
              <button
                aria-label={`Week view, ${
                  selectedOption.value === "week" ? "selected" : "unselected"
                }`}
                style={{
                  marginLeft: "5px",
                  pointerEvents: `${
                    selectedOption.value === "week" ? "none" : "all"
                  }`,
                }}
                className={`${
                  selectedOption.value === "week"
                    ? "common-button-submit no-border"
                    : "common-button-cancel"
                }`}
                onClick={this.props.setWeekView}
              >
                Week
              </button>
            </>
          ) : (
            // HIDE FOR NOW
            // this.dateRangeOptions.map((o)=>{
            //   return(
            //     <button
            //       onClick={this.props.setSelectedDateRangeOption}
            //       key={o.value} style={{marginLeft:"10px"}}
            //       className={selectedOption.value === o.value ? "common-button-submit" : "common-button-cancel"}
            //       data-option={JSON.stringify(o)}
            //     >
            //       {o.label}
            //     </button>
            //   )
            // })

            <SingleSelectWrapper
              options={this.dateRangeOptions}
              onChange={this.updateRangeData}
              id="custom-select"
              blurInputOnSelect={true}
              value={selectedOption}
              ariaLabel={`Reporting date range dropdown, ${selectedOption.label} selected. Press down arrow to open menu`}
              customStyles={{
                container: (styles) => ({ ...styles, width: "150px" }),
                menu: (styles) => ({ ...styles, width: "175px" }),
              }}
            />
          )}
          {this.state.custom && this.state.submitted === false && (
            <div id="custom-range">
              <div className="checkbox-menu">
                <form style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{ marginLeft: "-25px" }}
                      className="flex-j-start flex-container flex-al-center"
                    >
                      <div
                        style={{
                          position: "relative",
                          left: "40px",
                          zIndex: 1,
                          top: "5px",
                        }}
                      >
                        <CalendarIcon
                          width="25px"
                          height="25px"
                          color="#519acc"
                        />
                      </div>
                      <SingleDatePicker
                        date={this.state.startDate} // momentPropTypes.momentObj or null
                        onDateChange={this.changeStart} // PropTypes.func.isRequired
                        focused={this.state.start_picker_focus} // PropTypes.bool
                        onFocusChange={this.handleStartFocus} // PropTypes.func.isRequired
                        numberOfMonths={1}
                        placeholder={"Select a date"}
                        orientation={"horizontal"}
                        daySize={30}
                        navPrev={
                          <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                            <RoundDropdown rotate={"rotate(90deg)"} />
                          </div>
                        }
                        navNext={
                          <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                            <RoundDropdown rotate={"rotate(-90deg)"} />
                          </div>
                        }
                        hideKeyboardShortcutsPanel
                        inputIconPosition={"after"}
                        customInputIcon={<RoundDropdown />}
                        isOutsideRange={this.isDayBlockedPast}
                        id="single_date_picker" // PropTypes.string.isRequired,
                      />
                    </div>
                    <div
                      style={{ marginLeft: "-25px" }}
                      className="flex-j-start flex-container flex-al-center"
                    >
                      <div
                        style={{
                          position: "relative",
                          left: "40px",
                          zIndex: 1,
                          top: "5px",
                        }}
                      >
                        <CalendarIcon
                          width="25px"
                          height="25px"
                          color="#519acc"
                        />
                      </div>
                      <SingleDatePicker
                        date={this.state.endDate} // momentPropTypes.momentObj or null
                        onDateChange={this.changeEnd} // PropTypes.func.isRequired
                        focused={this.state.end_picker_focus} // PropTypes.bool
                        onFocusChange={this.handleEndFocus} // PropTypes.func.isRequired
                        numberOfMonths={1}
                        placeholder={"Select a date"}
                        orientation={"horizontal"}
                        daySize={30}
                        navPrev={
                          <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                            <RoundDropdown rotate={"rotate(90deg)"} />
                          </div>
                        }
                        navNext={
                          <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                            <RoundDropdown rotate={"rotate(-90deg)"} />
                          </div>
                        }
                        hideKeyboardShortcutsPanel
                        inputIconPosition={"after"}
                        customInputIcon={<RoundDropdown />}
                        isOutsideRange={this.isEndDayBlockedPast}
                        id="single_date_picker" // PropTypes.string.isRequired,
                      />
                    </div>
                  </div>
                  <button
                    onClick={this.submitData}
                    type="button"
                    className="btn-flat"
                    disabled={this.state.startDate === ""}
                    style={{
                      cursor: `${
                        this.state.startDate === "" ? "intial" : "pointer"
                      }`,
                      paddingLeft: "10px",
                      height: "40px",
                    }}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </NavHeading>
    );
  }
}

export default TimeTrackingDateNav;
