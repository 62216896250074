import React from "react";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import ms from "./ModalStyled";
import AddTask from "./add_task";
import TimeEntry from "./time_entry";
import TaskNotesShow from "./task_notes_show";
import AddClient from "./add_client";
import EditClient from "./edit_client";
import AddClientProject from "./add_client_project";
import AddProject from "./add_project";
import EditProject from "./edit_project";
import AddClosure from "./add_closure";
import NewLead from "./new_lead";
import EditClosure from "./edit_closure";
import AddFilter from "./add_filter";
import EditFilter from "./edit_filter";
import ProjectRemoveUser from "./project_remove_user";
import ProjectAddUser from "./project_add_user";
import ProjectAddTask from "./project_add_task";
import ProjectEditTask from "./project_edit_task";
import AddUser from "./add_user";
import Help from "./help";
import TimeOffRequest from "./time_off_request";
import CurrentBooking from "./current_booking";
import AddRoomViewBooking from "./add_room_view_booking";
import ReportSickTime from "./report_sick_time";
import ProjectStageModal from "./project_stage_modal";
import ExitIcon from "../icons/exit_icon";
import TaskTransitionModal from "./task_transition_modal";
import InvoiceSubmission from "./invoice_submission";
import AddTimeEntryRow from "./add_time_entry_row";
import Meeting from "./meeting";
import FindRoom from "./find_room";
import CopyProject from "./copy_project";

class ReactModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      disableBackdropClick: false,
      customClass:
        this.props.data && this.props.data.customClass
          ? this.props.data.customClass
          : "",
    };
    this.backdropClick = this.backdropClick.bind(this);
    this.bodyElement = document.querySelector("body");
    this.setBackdropLoader = this.setBackdropLoader.bind(this);
    this.setDisableBackdropClick = this.setDisableBackdropClick.bind(this);
    this.handleEsc = (e) => {
      var key = e.keyCode;
      if (key === 27) {
        this.backdropClick();
      }
    };
    this.listenerPresent = false;
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data.customClass !== this.props.data.customClass &&
      this.props.page
    ) {
      this.setState({
        customClass: this.props.data.customClass
          ? this.props.data.customClass
          : "",
      });
    }

    if (this.props.isShowing) {
      document.getElementById("new-modal").focus();
    }
  }

  setPage() {
    switch (this.props.page) {
      case "add-task":
        return (
          <AddTask
            modalAction={this.props.modalAction}
            data={this.props.data}
            refreshTasks={this.props.refreshTasks}
            setBackdropLoader={this.setBackdropLoader}
            setDisableBackdropClick={this.setDisableBackdropClick}
            disableBackdropClick={this.state.disableBackdropClick}
          />
        );
      case "time-entry":
        return (
          <TimeEntry
            modalAction={this.props.modalAction}
            data={this.props.data}
            setBackdropLoader={this.setBackdropLoader}
          />
        );
      case "booking-details":
        return (
          <TaskNotesShow
            data={this.props.data}
            modalAction={this.props.modalAction}
          />
        );
      case "add-client":
        return (
          <AddClient
            modalAction={this.props.modalAction}
            refreshClients={this.props.refreshClients}
            data={this.props.data}
            setBackdropLoader={this.setBackdropLoader}
          />
        );
      case "edit-client":
        return (
          <EditClient
            modalAction={this.props.modalAction}
            data={this.props.data}
            setClient={this.props.setClient}
            setBackdropLoader={this.setBackdropLoader}
          />
        );
      case "add-client-project":
        return (
          <AddClientProject
            modalAction={this.props.modalAction}
            refreshProjects={this.props.refreshProjects}
            client_id={this.props.data}
            setBackdropLoader={this.setBackdropLoader}
          />
        );
      case "add-project":
        return (
          <AddProject
            modalAction={this.props.modalAction}
            refreshProjects={this.props.refreshProjects}
            data={this.props.data}
            setBackdropLoader={this.setBackdropLoader}
          />
        );
      case "new-lead":
        return (
          <NewLead
            modalAction={this.props.modalAction}
            refreshProjects={this.props.refreshProjects}
            data={this.props.data}
            setBackdropLoader={this.setBackdropLoader}
          />
        );
      case "edit-project":
        return (
          <EditProject
            modalAction={this.props.modalAction}
            refreshProject={this.props.refreshProject}
            data={this.props.data}
            setBackdropLoader={this.setBackdropLoader}
          />
        );
      case "add-closure":
        return (
          <AddClosure
            modalAction={this.props.modalAction}
            refreshClosures={this.props.refreshClosures}
            setBackdropLoader={this.setBackdropLoader}
            data={this.props.data}
          />
        );
      case "edit-closure":
        return (
          <EditClosure
            modalAction={this.props.modalAction}
            refreshClosures={this.props.refreshClosures}
            closure={this.props.data}
          />
        );
      case "add-filter":
        return (
          <AddFilter
            modalAction={this.props.modalAction}
            refreshFilters={this.props.refreshFilters}
            data={this.props.data}
          />
        );
      case "edit-filter":
        return (
          <EditFilter
            modalAction={this.props.modalAction}
            refreshFilters={this.props.refreshFilters}
            data={this.props.data}
          />
        );
      case "project-remove-user":
        return (
          <ProjectRemoveUser
            modalAction={this.props.modalAction}
            refreshUsers={this.props.refreshUsers}
            data={this.props.data}
          />
        );
      case "project-add-user":
        return (
          <ProjectAddUser
            modalAction={this.props.modalAction}
            refreshUsers={this.props.refreshUsers}
            data={this.props.data}
          />
        );
      case "project-add-task":
        return (
          <ProjectAddTask
            modalAction={this.props.modalAction}
            refreshUsers={this.props.refreshUsers}
            refreshTasks={this.props.refreshTasks}
            data={this.props.data}
          />
        );
      case "project-edit-task":
        return (
          <ProjectEditTask
            modalAction={this.props.modalAction}
            refreshUsers={this.props.refreshUsers}
            refreshTasks={this.props.refreshTasks}
            data={this.props.data}
          />
        );
      case "project-stage":
        return (
          <ProjectStageModal
            modalAction={this.props.modalAction}
            loadRegular={this.props.loadRegular}
            refreshProjects={this.props.refreshProjects}
            setBackdropLoader={this.setBackdropLoader}
            data={this.props.data}
          />
        );
      case "task-transition":
        return (
          <TaskTransitionModal
            modalAction={this.props.modalAction}
            refreshProjects={this.props.refreshProjects}
            data={this.props.data}
            setBackdropLoader={this.setBackdropLoader}
          />
        );
      case "add-user":
        return (
          <AddUser
            modalAction={this.props.modalAction}
            refreshUsers={this.props.refreshUsers}
            data={this.props.data}
          />
        );
      case "help":
        return <Help />;
      case "time-off-request":
        return (
          <TimeOffRequest
            modalAction={this.props.modalAction}
            data={this.props.data}
          />
        );
      case "current-booking":
        return (
          <CurrentBooking
            refreshBookings={this.props.refreshBookings}
            modalAction={this.props.modalAction}
            data={this.props.data}
          />
        );
      case "add-room-view-booking":
        return (
          <AddRoomViewBooking
            refreshBookings={this.props.refreshBookings}
            modalAction={this.props.modalAction}
            data={this.props.data}
          />
        );
      case "report-sick-time":
        return (
          <ReportSickTime
            modalAction={this.props.modalAction}
            refreshUserTasks={this.props.refreshUserTasks}
            data={this.props.data}
          />
        );
      case "invoice-submission":
        return (
          <InvoiceSubmission
            modalAction={this.props.modalAction}
            data={this.props.data}
          />
        );
      case "add-time-entry-row":
        return (
          <AddTimeEntryRow
            modalAction={this.props.modalAction}
            setBackdropLoader={this.setBackdropLoader}
            data={this.props.data}
          />
        );
      case "meeting":
        return (
          <Meeting
            modalAction={this.props.modalAction}
            setBackdropLoader={this.setBackdropLoader}
            data={this.props.data}
          />
        );
      case "find-room":
        return (
          <FindRoom
            modalAction={this.props.modalAction}
            setBackdropLoader={this.setBackdropLoader}
            data={this.props.data}
          />
        );
      case "copy-project":
        return (
          <CopyProject
            modalAction={this.props.modalAction}
            setBackdropLoader={this.setBackdropLoader}
            data={this.props.data}
          />
        );
    }
  }

  backdropClick() {
    var self = this;
    if (!this.state.disableBackdropClick) {
      if (this.props.data.backdropClickCheck) {
        Swal.fire({
          title: self.props.data.backdropClickCheck.title
            ? self.props.data.backdropClickCheck.title
            : "",
          text: self.props.data.backdropClickCheck.text,
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonAriaLabel: "Yes",
          cancelButtonAriaLabel: "Cancel",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            self.props.modalAction(false, "", {});
          }
        });
      } else if (this.props.data.required) {
        Swal.fire({
          title: "Actions Required",
          text: self.props.data.required.text,
          reverseButtons: true,
          showCancelButton: false,
          confirmButtonAriaLabel: "Yes",
          confirmButtonText: "Yes",
        });
      } else {
        self.props.modalAction(false, "", {});
      }
    } else {
      self.props.modalAction(false, "", {});
    }
  }

  closeModal = () => {
    this.props.modalAction(false, "", {});
  };

  setDisableBackdropClick(bool) {
    this.setState({
      disableBackdropClick: bool,
    });
  }

  setBackdropLoader(bool) {
    this.setState({
      loading: bool,
    });
  }

  render() {
    if (this.props.isShowing && !this.listenerPresent) {
      this.listenerPresent = true;
      window.addEventListener("keydown", this.handleEsc);
    } else if (!this.props.isShowing && this.listenerPresent) {
      this.listenerPresent = false;
      window.removeEventListener("keydown", this.handleEsc);
    }
    return ReactDOM.createPortal(
      <div ref={this.props.modalRef ? this.props.modalRef : null}>
        <div
          id={this.props.id ? this.props.id : "new-modal"}
          tabIndex="0"
          aria-live="polite"
          className={`new-modal ${this.state.customClass} ${
            this.props.isShowing ? "new-modal-open" : "new-modal-close"
          }`}
        >
          {this.props.isShowing && <div role="dialog">{this.setPage()}</div>}
        </div>
        <div
          id="react-modal-backdrop"
          className={`modal-backdrop ${
            this.props.isShowing
              ? "modal-backdrop-open"
              : "modal-backdrop-close"
          }`}
          onClick={this.backdropClick}
        ></div>
        <div
          className={`modal-backdrop-loader ${
            this.state.loading
              ? "modal-backdrop-loader-open"
              : "modal-backdrop-loader-close"
          }`}
        >
          {this.state.loading && <div className="page-load-spinner"></div>}
        </div>
        <div id="select-portal"></div>
      </div>,
      this.bodyElement
    );
  }
}

export default ReactModal;
