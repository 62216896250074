import React from "react";
import SingleSelectWrapper from "./single_select_wrapper";
import TimeZones from "./time_zones";

function TimeZoneSelect({
  onChange,
  value,
  placeholder,
  menuPlacement,
  customStyles,
}) {
  const options = TimeZones.map((x) => ({ value: x, label: x }));
  return (
    <SingleSelectWrapper
      options={options}
      onChange={onChange}
      ariaLabel={`Timezone select dropdown, ${
        value && value.label ? value.label : "none"
      } selected. Press down arrow to open menu`}
      isSearchable
      placeholder={placeholder}
      value={value}
      menuPlacement={menuPlacement}
      customStyles={customStyles}
    />
  );
}

export default TimeZoneSelect;
