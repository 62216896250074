import React from "react";
import AlphabetRow from "../common/clients_projects/alphabet_row";
import DynamicList, { createCache } from "react-window-dynamic-list";
import AutoSizer from "react-virtualized-auto-sizer";
import styled from "styled-components";
import RoundDropdown from "../projects/round_dropdown";
import InactiveCardGroup from "../common/clients_projects/inactive_card_group";

const cache = createCache();

const ProjectCardDiv = styled.div`
  width: 275px;
  height: 140px;
  padding: 15px;
  background-color: white;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 2px 3px 2px #cccccc;
  // margin-bottom: 20px;
  margin-right: 20px;
  position: relative;
  & .div-btn-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    background: transparent;
    border: none;
    padding: 0;
    top: 0px;
    left: 0px;
    z-index: 3;
  }
  label {
    font-family: "Manrope", sans-serif;
    font-size: 16px;
    color: #313131;
    font-weight: 700;
  }
  p {
    margin-top: 0px;
    color: #313131;
    margin-bottom: 0px;
  }
  .tag {
    min-width: 68px;
    padding: 5px 8px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    text-align: center;
    border-radius: 10px;
    font-size: 12px;
    display: inline-block;
    margin-right: 5px;
    color: white;
  }
  .tag-group {
    position: absolute;
    bottom: 15px;
    width: 220px;
    overflow: hidden;
    height: 28px;
  }
  .tag-plus {
    position: absolute;
    right: 15px;
    bottom: 18px;
    color: #2bb882;
  }
`;

class ClientDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client: this.props.client,
      projects: this.props.client.projects,
      search: "",
      searchData: [],
      selectedTag: [],
      selectedUser: [],
      selectedStatus: [],
    };
  }

  renderProjects() {
    var indexNode = [];
    indexNode.push({
      id: "back-button",
      node: (
        <div
          className="flex-container flex-al-center"
          style={{ width: "130px", height: "22px" }}
        >
          <RoundDropdown rotate={"rotate(90deg)"} />
          <button
            style={{
              padding: 0,
              marginLeft: "5px",
              fontFamily: "Manrope",
              fontWeight: 700,
            }}
            className="btn-flat"
            onClick={this.props.backToClients}
          >
            Back to Clients
          </button>
        </div>
      ),
    });
    if (Object.keys(this.props.alphaGroups).length > 0) {
      indexNode.push({
        id: 0,
        node: (
          <h4
            style={{
              display: "flex",
              alignItems: "center",
              margin: "20px 0px 20px",
            }}
          >
            <div
              style={{
                color: "#313131",
                whiteSpace: "nowrap",
                fontWeight: "600",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "20px",
                fontSize: "18px",
              }}
            >
              Active Projects
            </div>
            <div
              style={{
                height: "1px",
                flex: "1",
                marginRight: "30px",
                backgroundColor: "lightgray",
              }}
            />
          </h4>
        ),
      });
    }
    Object.entries(this.props.alphaGroups).forEach((entry, i) => {
      if (entry[0] !== "inactive") {
        indexNode.push({
          id: entry[0],
          node: (
            <AlphabetRow
              type="project"
              loadProject={this.props.loadProject}
              cardDiv={ProjectCardDiv}
              index={entry[0]}
              projects={entry[1]}
              key={i}
            />
          ),
        });
      }
    });
    if (this.props.alphaGroups["inactive"]) {
      indexNode.push({
        id: "inactive-group",
        node: (
          <InactiveCardGroup
            heading="Inactive Projects"
            type="project"
            loadProject={this.props.loadProject}
            cardDiv={ProjectCardDiv}
            projects={this.props.alphaGroups["inactive"]}
            key={"inactive-group"}
          />
        ),
      });
    }
    const row = ({ index, style }) => (
      <div style={style}>{indexNode[index].node}</div>
    );
    return (
      <AutoSizer>
        {({ height, width }) => (
          <DynamicList
            ref={this.props.dynamicListRef}
            recalculateItemsOnResize={{ width: false, height: true }}
            height={
              height - 150 + (this.props.search != "" ? Math.random() : 0)
            }
            width={width}
            cache={cache}
            data={indexNode}
          >
            {row}
          </DynamicList>
        )}
      </AutoSizer>
    );
  }

  onAddProjectClick = () => {
    this.props.setModal(true, "add-project", {
      tagOptions: this.props.tagOptions,
      leadOptions: this.props.leadOptions,
      clientId: this.props.client.id,
      currentUser: this.props.currentUser,
      closeButton: true,
      customClass: "project-modal",
    });
  };

  updateSearch = (event) => {
    var input = event.target.value;
    if (input && input.length > 0) {
      const searchTerm = input.toLowerCase();
      let filteredProjects = this.state.projects.filter((project) =>
        project.name.toLowerCase().includes(searchTerm) ? true : false
      );
      this.setState({ search: input, searchData: filteredProjects });
    } else if (input === "") {
      if (this.state.selectedTag.length + this.state.selectedUser.length >= 1) {
        var data = this.state.searchData;
      } else {
        var data = this.state.projects;
      }
      this.setState({ search: input, searchData: data });
    }
  };

  render() {
    return <div style={{ height: "100%" }}>{this.renderProjects()}</div>;
  }
}

export default ClientDetail;
