import React from "react";

class TimeObjectSelector extends React.Component {
  constructor(props) {
    super(props);
  }

  getSearchParamsUrl(option) {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    if (this.props.nested && this.props.nestedObject) {
      if (option.name.toLowerCase() != `${this.props.activeObjectName}s`) {
        if (
          (this.props.activeObjectName === "project" &&
            this.props.objectName === "user" &&
            option.name.toLowerCase() === "tasks" &&
            this.props.nestedObject.name != "clients") ||
          (this.props.activeObjectName === "project" &&
            this.props.objectName === "task" &&
            option.name.toLowerCase() === "tasks") ||
          (this.props.activeObjectName === "client" &&
            this.props.objectName === "project" &&
            option.name.toLowerCase() === "projects") ||
          (this.props.activeObjectName === "client" &&
            this.props.objectName === "task" &&
            option.name.toLowerCase() === "projects") ||
          (this.props.activeObjectName === "client" &&
            this.props.objectName === "user" &&
            option.name.toLowerCase() === "projects")
        ) {
          if (params.has("start_date") && params.has("end_date")) {
            return `/time_tracking/${this.props.activeObjectName}s/${
              this.props.nestedObject.id
            }?${params.toString()}`;
          }
          return `/time_tracking/${this.props.activeObjectName}s/${this.props.nestedObject.id}`;
        }
        if (params.has("start_date") && params.has("end_date")) {
          return `/time_tracking/${this.props.activeObjectName}s/${
            this.props.nestedObject.id
          }/${option.name.toLowerCase()}?${params.toString()}`;
        }
        return `/time_tracking/${this.props.activeObjectName}s/${
          this.props.nestedObject.id
        }/${option.name.toLowerCase()}`;
      }
      if (params.has("start_date") && params.has("end_date")) {
        return `/time_tracking/${option.name.toLowerCase()}/${
          this.props.nestedObject.id
        }?${params.toString()}`;
      }
      return `/time_tracking/${option.name.toLowerCase()}/${
        this.props.nestedObject.id
      }`;
    }
    if (params.has("start_date") && params.has("end_date")) {
      return `/time_tracking/${option.name.toLowerCase()}?${params.toString()}`;
    }
    return `/time_tracking/${option.name.toLowerCase()}`;
  }

  render() {
    return (
      <ul
        className="flex-container flex-al-center flex-space-even"
        style={{ marginBlockEnd: 0, marginBlockStart: 0 }}
        aria-label="Report view options"
      >
        {this.props.options.map((option) => (
          <li
            key={option.name}
            className={
              option.name[0].toLowerCase() === this.props.objectName[0]
                ? "activeSelectObject"
                : ""
            }
            style={{
              transition: "border-color .5s, background-color .5s",
              cursor: "pointer",
              width: "75px",
              fontFamily: "Arial",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a
              aria-label={`${option.name}${
                option.name[0].toLowerCase() === this.props.objectName[0]
                  ? ", Currently Active"
                  : ""
              } `}
              style={{ color: "#313131", padding: "20px" }}
              href={this.getSearchParamsUrl(option)}
            >
              {option.name}
            </a>
          </li>
        ))}
      </ul>
    );
  }
}

export default TimeObjectSelector;
