import AccordionClosedIcon from "../icons/accordion_closed_icon";
import AccordionOpenIcon from "../icons/accordion_open_icon";
import React from "react";
import styled from "styled-components";

class DropperContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open ? this.props.open : false,
    };
    this.toggleOpen = this.toggleOpen.bind(this);
    this.dropElem = React.createRef();
  }

  toggleOpen(e) {
    if (e) e.stopPropagation();
    if (this.state.open) {
      this.dropElem.current.style.overflow = "hidden";
    }
    this.setState({ open: !this.state.open }, () => {
      if (this.props.parentScrollId) {
        setTimeout(() => {
          const elem = document.getElementById(this.props.parentScrollId);
          if (elem) {
            elem.scrollTo({
              top: elem.scrollHeight,
              left: 0,
              behavior: "smooth",
            });
          }
        }, 275);
      }
      // set dropElem to overflow visible after expanding so react select menus aren't cut off
      setTimeout(() => {
        if (this.state.open) {
          this.dropElem.current.style.overflow = "visible";
        }
      }, 300);
    });
  }

  render() {
    return (
      <DropWrapper
        className={[
          "flex-container flex-wrap dropper",
          this.props.className || "",
        ].join(" ")}
      >
        <DropperButton
          aria-label={`${this.props.title}, Heading Level 2, button, ${
            this.state.open ? "expanded" : "collapsed"
          }`}
          aria-expanded={this.state.open}
          id={this.props.dropperId ? this.props.dropperId : ""}
          onClick={this.toggleOpen}
          dropperOpen={this.state.open}
        >
          <p
            className="manrope bold"
            style={{
              transition: "color .4s",
              color: `${this.state.open ? "#fff" : "#000"}`,
            }}
          >
            {this.props.title ? this.props.title : "Please add a title"}
          </p>
          <div style={{ width: "35px", height: "35px" }}>
            {this.state.open ? <AccordionOpenIcon /> : <AccordionClosedIcon />}
          </div>
        </DropperButton>

        <div
          aria-hidden={!this.state.open}
          ref={this.dropElem}
          style={{
            display: `${
              this.props.innerFlex && this.props.innerFlex === true
                ? "flex"
                : ""
            }`,
            flexWrap: `${
              this.props.innerFlex && this.props.innerFlex === true
                ? "wrap"
                : ""
            }`,
            overflow: "hidden",
            borderStyle: "solid",
            borderWidth: "1px",
            borderLeftColor: `${this.state.open ? "#e7e7e7" : "transparent"}`,
            borderRightColor: `${this.state.open ? "#e7e7e7" : "transparent"}`,
            borderBottomColor: `${this.state.open ? "#e7e7e7" : "transparent"}`,
            borderTopColor: "transparent",
            padding: "0px",
            transition: "max-height .2s ease-in-out .1s, border-color .3s",
            width: "100%",
            maxHeight: `${
              this.state.open
                ? this.props.maxHeight
                  ? this.props.maxHeight
                  : `1000px`
                : "0px"
            }`,
          }}
          onClick={(e) => e.stopPropagation()} // Prevents closing on inner element click
        >
          {this.props.children}
        </div>
      </DropWrapper>
    );
  }
}

const DropWrapper = styled.div`
  transition: all 0.4s ease-in-out 0.1s;
  margin-bottom: 8px;
  width: 100%;
  cursor: pointer;
`;

const DropperButton = styled.span`
  display: flex;
  width: 100%;
  height: 51px;
  flex: 1;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: ${({ dropperOpen }) =>
    dropperOpen ? "0px" : "5px"};
  border-bottom-right-radius: ${({ dropperOpen }) =>
    dropperOpen ? "0px" : "5px"};
  border: 1px solid #d7d7d7;
  background-color: ${({ dropperOpen }) => (dropperOpen ? "#519acc" : "white")};
  cursor: pointer;
  &:hover {
    border: 1px solid #519acc;
  }
`;

export default DropperContainer;
