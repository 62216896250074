import React from "react";
import EditIcon from "../icons/edit_icon.jsx";

class ContactCard extends React.Component {
  constructor(props) {
    super(props);
    const { contact, edit } = this.props;
    this.state = {
      name: contact.name,
      email: contact.email,
      edit,
    };
    this.txt1 = React.createRef();
    this.deleteContact = this.deleteContact.bind(this);
    this.submit = this.submit.bind(this);
    this.cancelClick = this.cancelClick.bind(this);
    this.submitClick = this.submitClick.bind(this);
    this.newEditClick = this.newEditClick.bind(this);
  }

  handleInputChange(e) {
    const { name } = e.target.name;
    this.setState({ [name]: e.target.value });
  }

  deleteContact() {
    const { deleteContact, contact } = this.props;
    deleteContact(contact);
  }

  emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  submit() {
    const { contact, submitHandler } = this.props;
    const { name, email } = this.state;
    if (contact.name !== name) {
      contact.name = name;
    }
    if (contact.email !== email) {
      if (this.emailIsValid(email)) {
        contact.email = email;
      } else {
        M.toast({ html: "Email is not valid", duration: 3000, classes: "red" });
        return;
      }
    }
    if (name === "") {
      M.toast({
        html: "Please enter the contact name",
        duration: 3000,
        classes: "red",
      });
      return;
    }
    if (email === "") {
      M.toast({
        html: "Please enter the contact email",
        duration: 3000,
        classes: "red",
      });
      return;
    }
    submitHandler(contact);
  }

  submitClick() {
    this.submit();
  }

  cancelClick() {
    const { firstEdit, deleteContact, contact, activeIndexHandler } =
      this.props;
    if (firstEdit) {
      deleteContact(contact);
    }
    activeIndexHandler(-1);
  }

  newEditClick() {
    const { active, activeIndexHandler, index } = this.props;

    if (active) {
      activeIndexHandler(-1);
    } else {
      activeIndexHandler(index);
    }
  }

  render() {
    const { active, contact } = this.props;
    const { name, email } = this.state;
    return (
      <div
        className="contact-card"
        style={{
          display: "grid",
          gridTemplateColumns: `${
            active
              ? "repeat(2, minmax(50px, 1fr)) 80px 80px"
              : "repeat(2, minmax(50px, 1fr)) 30px 30px"
          }`,
          gridTemplateRows: `${active ? "120px" : "52px"}`,
          height: "300px",
          gridGap: "5px",
          maxHeight: `${active ? "120px" : "52px"}`,
          transition: "max-height .5s, opacity .5s",
          borderRadius: "5px",
          padding: "0 10px",
          boxShadow: "0 1px 1px 1px #d1d1d1",
          marginBottom: "10px",
        }}
        key={contact.id}
      >
        {active ? (
          <>
            <div style={{ padding: "8px" }}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                ref={this.txt1}
                maxLength="19"
                onChange={this.handleInputChange}
                className="browser-default text-input-v2"
                value={name}
                autoComplete="off"
                name="name"
                aria-describedby="character-count"
              />
              <p
                id="character-count"
                aria-live="polite"
                className="input-counter"
              >{`${name.length} / 19`}</p>
            </div>
            <div style={{ padding: "8px" }}>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                onChange={this.handleInputChange}
                className="browser-default text-input-v2"
                autoComplete="off"
                value={email}
                name="email"
                maxLength="50"
                aria-describedby="character-count"
              />
              <p
                id="character-count"
                aria-live="polite"
                className="input-counter"
              >{`${email.length}/26`}</p>
            </div>
          </>
        ) : (
          <>
            <p
              style={{ padding: "5px", marginRight: "3px" }}
              className="flex-container flex-al-center flex-j-start truncate"
            >
              {contact.name}
            </p>
            <p
              style={{ padding: "5px", marginRight: "20px" }}
              className="flex-container flex-al-center flex-j-end truncate"
            >
              {contact.email}
            </p>
          </>
        )}
        {!active ? (
          <button
            type="button"
            onClick={this.newEditClick}
            style={{
              border: "1px solid lightgray",
              alignItems: "center",
              padding: 0,
              height: "24px",
              alignSelf: "center",
              cursor: "pointer",
              borderRadius: "3px",
              display: "flex",
              width: "27px",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <EditIcon height={20} width={20} />
          </button>
        ) : (
          <button
            type="button"
            onClick={this.submitClick}
            className="btn btn-flat"
          >
            Submit
          </button>
        )}
        {!active ? (
          <button
            type="button"
            onClick={this.deleteContact}
            style={{
              border: "1px solid lightgray",
              alignItems: "center",
              padding: 0,
              height: "24px",
              alignSelf: "center",
              cursor: "pointer",
              borderRadius: "3px",
              display: "flex",
              width: "27px",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <img
              alt="trash"
              src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/syncit/Icon__trash_1.svg"
              style={{ width: "20px", height: "20px" }}
            />
          </button>
        ) : (
          <button
            type="button"
            onClick={this.cancelClick}
            className="btn btn-flat"
          >
            Cancel
          </button>
        )}
      </div>
    );
  }
}

export default ContactCard;
