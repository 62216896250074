import React, { useContext } from "react";
import HelpContext from "./HelpContext";
import HelpIcon from "../icons/help_icon";
import {
  BannerTitleContainer,
  CreateHelpContainer,
  CreateHelpButton,
  HelpSearch,
  SearchIcon,
  HelpTitle,
  HelpIconContainer,
  HelpWrapper,
  HelpInnerContainer,
  SearchTitle,
  HelpGrid,
  HelpHeadline,
} from "./HelpStyled";

const HelpMain = () => {
  const {
    currentUser,
    handleCreate,
    search,
    updateSearch,
    tags,
    allTopics,
    setCurrentPage,
    viewMore,
  } = useContext(HelpContext);

  return (
    <HelpWrapper className="help-index">
      <HelpInnerContainer>
        <BannerTitleContainer>
          <HelpTitle>
            <HelpIconContainer>
              <HelpIcon width="30px" height="30px" />
            </HelpIconContainer>
            <h2>SyncIt Help</h2>
          </HelpTitle>
          {currentUser && currentUser.role_id >= 4 && (
            <CreateHelpContainer>
              <CreateHelpButton
                className="common-button-submit"
                type="button"
                onClick={handleCreate}
              >
                Create Help Page
              </CreateHelpButton>
            </CreateHelpContainer>
          )}
        </BannerTitleContainer>
        <SearchTitle>
          Use the search bar or select a topic to find an answer
        </SearchTitle>
        <div className="flex-container flex-al-center">
          <HelpSearch id="helpSearch">
            <SearchIcon aria-hidden="true" className="material-icons prefix">
              search
            </SearchIcon>
            <input
              id="help-search-input"
              className="browser-default big"
              type="text"
              onChange={updateSearch}
              placeholder="Search to locate a training resource"
              aria-live="polite"
              value={search}
              aria-label="Search"
            />
          </HelpSearch>
        </div>
        <HelpGrid>
          {search === ""
            ? Object.entries(tags).map((tag) => {
                return (
                  <div className="help-topic" key={tag[0]}>
                    <HelpHeadline>
                      {tag[0].replace(/[\[\]']+/g, "").replace(/['"]+/g, "")}
                    </HelpHeadline>
                    <div className="help-topics-list">
                      {tag[1].slice(0, 5).map((page, innie) => {
                        return (
                          <div className="help-item" key={innie}>
                            <button
                              type="button"
                              value={page.id}
                              onClick={() => setCurrentPage(page.id)}
                              className="help-topic-button"
                            >
                              {page.title === ""
                                ? "Unfinished Help Page"
                                : page.title}
                            </button>
                          </div>
                        );
                      })}
                      <div>
                        <button
                          className="help-topics-view-more-button"
                          type="button"
                          onClick={() => viewMore(tag[1][0].topic)}
                        >
                          <span>View All Topics</span>
                          <svg
                            width="7"
                            height="11"
                            viewBox="0 0 7 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.496011 2.55814C-0.0500887 1.99535 -0.149379 1.07442 0.39672 0.511628C0.694592 0.204651 1.09176 0 1.43927 0C1.78679 0 2.18395 0.153488 2.43218 0.409302L6.75133 4.86047C7.09885 5.2186 7.09885 5.73023 6.75133 6.08837L5.31161 7.57209L2.38254 10.5907C1.83644 11.1535 0.942819 11.1535 0.347074 10.5907C-0.199025 10.0279 -0.199025 9.10698 0.347074 8.49302L3.27615 5.47442C3.27615 5.47442 3.27615 5.47442 3.27615 5.42326L0.496011 2.55814Z"
                              fill="#519ACC"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            : Object.entries(tags).map((tag, index) => {
                const visible = tag[1].map((y) =>
                  y.tags.some((t) =>
                    t.toLowerCase().includes(search.toLowerCase())
                  )
                );
                return (
                  <div
                    style={{
                      display: `${
                        visible.some((x) => x === true) ? "block" : "none"
                      }`,
                    }}
                    className="help-topic"
                    key={index}
                  >
                    <p style={{ marginBottom: "15px" }}>
                      {tag[0].replace(/[\[\]']+/g, "").replace(/['"]+/g, "")}
                    </p>
                    {tag[1].map((page, innie) => {
                      const innerVis = page.tags.some((t) =>
                        t.toLowerCase().includes(search.toLowerCase())
                      );
                      return (
                        <div
                          style={{
                            display: `${innerVis ? "block" : "none"}`,
                          }}
                          className="help-item"
                          key={innie}
                        >
                          <button
                            type="button"
                            value={page.id}
                            onClick={() => setCurrentPage(page.id)}
                          >
                            {page.title === ""
                              ? "Unfinished Help Page"
                              : page.title}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
          {search !== "" &&
            allTopics.filter((x) =>
              x.toLowerCase().includes(search.toLowerCase())
            ).length === 0 && (
              <p className="no-results">No matching results for this query</p>
            )}
        </HelpGrid>
      </HelpInnerContainer>
    </HelpWrapper>
  );
};

export default HelpMain;
