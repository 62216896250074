import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import RoundDropdown from "../round_dropdown.jsx";
import CalendarIcon from "../../icons/calendar_icon.jsx";

const StageCard = styled.div`
  margin: 35px 0 20px;
  label {
    font-size: 14px !important;
    color: #313131 !important;
  }
`;

function ProjectConfirmed(props) {
  const [completeDate, setCompleteDate] = useState(
    props.project.completion_date
      ? moment(props.project.completion_date)
      : undefined
  );
  const [completeDateFocus, setCompleteDateFocus] = useState(false);
  const [focusCounter, setFocusCounter] = useState(0);

  function handleCompleteDateChange(date) {
    setCompleteDate(date);
  }

  function handleCompleteDateFocus(focused) {
    if (completeDateFocus && focused.focused && focusCounter === false) {
      setCompleteDateFocus(true);
      setFocusCounter(true);
    } else if (completeDateFocus && focused.focused && focusCounter) {
      setCompleteDateFocus(true);
      setFocusCounter(true);
    } else {
      setCompleteDateFocus(focused.focused);
      setFocusCounter(true);
    }
  }

  function save() {
    const project = {};
    if (completeDate) {
      project.completion_date = completeDate;
    }
    props.submitHandler(project, "completing", "testing", true);
  }

  function submit(e) {
    e.preventDefault();
    const project = {};
    if (completeDate) {
      project.completion_date = completeDate;
    } else {
      M.toast({
        html: "Please select a completion date to proceed",
        classes: "danger",
      });
    }
    props.submitHandler(project, "completing", "testing", false);
  }

  function isDayBlocked(day) {
    return !!(moment(day).day() === 0 || moment(day).day() === 6);
  }
  return (
    <StageCard>
      <form
        onSubmit={submit}
        className="flex-container flex-row flex-al-center"
      >
        <div
          style={{ marginLeft: "-26px", marginTop: "30px", zIndex: 0 }}
          className="flex-j-start flex-container flex-al-center"
        >
          <div
            style={{
              position: "relative",
              left: "40px",
              zIndex: 1,
              top: "2px",
            }}
          >
            <CalendarIcon width="22px" height="22px" color="#519acc" />
          </div>
          <SingleDatePicker
            date={completeDate} // momentPropTypes.momentObj or null
            onDateChange={handleCompleteDateChange} // PropTypes.func.isRequired
            focused={completeDateFocus} // PropTypes.bool
            onFocusChange={handleCompleteDateFocus} // PropTypes.func.isRequired
            numberOfMonths={1}
            appendToBody
            placeholder="Select a date"
            orientation="horizontal"
            daySize={30}
            navPrev={
              <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                <RoundDropdown rotate="rotate(90deg)" />
              </div>
            }
            navNext={
              <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                <RoundDropdown rotate="rotate(-90deg)" />
              </div>
            }
            hideKeyboardShortcutsPanel
            inputIconPosition="after"
            customInputIcon={<RoundDropdown />}
            isOutsideRange={isDayBlocked}
            id="single_date_picker" // PropTypes.string.isRequired,
          />
          <label
            style={{ left: "4px" }}
            className="date-labels"
            htmlFor="filter_filters"
          >
            Completion Date
          </label>
        </div>
        <div className="flex-container" style={{ marginLeft: "auto" }}>
          <button type="button" onClick={save} className="common-button-cancel">
            Save
          </button>
          {/* <button
            style={{ marginLeft: "20px" }}
            className="common-button-submit"
            type="submit"
          >
            Next Step
          </button> */}
        </div>
      </form>
    </StageCard>
  );
}

export default ProjectConfirmed;
