import React, {
  useState, useEffect, useRef, useReducer
} from "react";
import styled from "styled-components";
import Select, { components } from "react-select";
import SingleSelectStyles from "../../common/single_select_styles";
import RoundDropdown from "../round_dropdown";

const LeadCard = styled.div`
  margin: 20px 0 10px;
  .react-select__menu-list {
    max-height: 185px;
  }
`;

function ProspectStage(props) {
  const options = [
    { value: "website", label: "Website" },
    { value: "existing client", label: "Existing Client" },
    { value: "referral", label: "Referral" },
    { value: "linkedin", label: "LinkedIn" },
    { value: "other", label: "Other" },
  ];
  const [leadSource, setLeadSource] = useState(
    props.project.lead_source
      ? options.find((o) => o.value === props.project.lead_source)
      : null
  );

  function handleSourceChange(e) {
    setLeadSource(e);
  }

  function save() {
    const project = new FormData();
    if (leadSource) {
      project.append("project[lead_source]", leadSource.value);
    }
    props.updateProjectProspect({ lead_source: leadSource.value });
  }

  const roundDrop = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <RoundDropdown />
      </components.DropdownIndicator>
    );
  };

  return (
    <LeadCard className="transition">
      <div>
        <div
          style={{ marginTop: "20px", flexWrap: "wrap" }}
          className="flex-container flex-row flex-al-center"
        >
          <div
            style={{ width: "100%" }}
            className="flex-row flex-space-between flex-container"
          >
            <div style={{ marginRight: "0px" }} className="input-field">
              <Select
                classNamePrefix="react-select"
                options={[
                  { value: "website", label: "Website" },
                  { value: "existing client", label: "Existing Client" },
                  { value: "referral", label: "Referral" },
                  { value: "linkedin", label: "LinkedIn" },
                  { value: "other", label: "Other" },
                ]}
                onChange={handleSourceChange}
                components={{ DropdownIndicator: roundDrop }}
                value={leadSource}
                blurInputOnSelect
                styles={SingleSelectStyles}
              />
              <label className="select-labels" htmlFor="filter_filters">
                Lead Source
                <span className="base-color">*</span>
              </label>
            </div>
            <div
              className="flex-container"
              style={{
                marginLeft: "10px",
                marginRight: "auto",
                alignItems: "center",
              }}
            >
              <button
                type="button"
                style={{ marginRight: "10px" }}
                onClick={save}
                className="common-button-submit"
              >
                Save All Changes
              </button>
              <button
                type="button"
                onClick={props.moveToTaskboard}
                className="common-button-submit"
              >
                Move To Taskboard
              </button>
            </div>
          </div>
        </div>
      </div>
    </LeadCard>
  );
}

export default ProspectStage;
