import React from "react";
import axios from "axios";

class ProjectRemoveUser extends React.Component {
  constructor(props) {
    super(props);
    this.removeUser = this.removeUser.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
  }

  removeUser() {
    axios
      .get(
        `/api/v1/projects/${this.props.data.projectId}/remove_user/${this.props.data.userId}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        M.toast({
          html: `User Removed`,
          displayLength: 3000,
          classes: "green",
        });
        this.props.refreshUsers();
        this.props.modalAction(false, "", {});
      })
      .catch((err) => console.error(err));
  }

  onCancelClick() {
    this.props.modalAction(false, "", {});
  }

  render() {
    return (
      <div className="modal-content">
        <div className="row center">
          <p>Are you sure you want to remove this user from the project?</p>
          <p>Any future bookings will be removed.</p>
        </div>
        <div className="row center">
          <div className="col s12 m6">
            <button
              className="btn gray center modal-btn black-text"
              id="remove_user_button"
              onClick={this.removeUser}
            >
              Remove User
            </button>
          </div>
          <div className="col s12 m6">
            <div
              className="modal-action modal-close green btn white-text modal-btn"
              onClick={this.onCancelClick}
            >
              Cancel
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectRemoveUser;
