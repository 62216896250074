import React, { useState, useEffect } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import Swal from "sweetalert2";
import axios from "axios";
import EditIcon from "../icons/edit_icon";
import ModalTag from "../common/modal_tag";
import TrashIcon from "components/icons/trash_icon";
import styled from "styled-components";

function ProjectComment(props) {
  const [commentText, setCommentText] = useState(props.comment.text);
  const [editState, setEditState] = useState(false);

  function changeCommentText(e) {
    if (e.target.value != commentText) {
      setCommentText(e.target.value);
    }
  }

  function deleteComment() {
    Swal.fire({
      title: "Delete Comment",
      text: "Are you sure you want to delete this comment?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`/comments/${props.comment.id}.json`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            props.handler("delete", props.comment);
            if (props.prospect) {
              props.loadProspects(true);
            }
            M.toast({
              html: `Comment deleted`,
              displayLength: 3000,
              classes: "green",
            });
          })
          .catch((err) => console.error(err));
      }
    });
  }

  function toggleEditState() {
    if (editState === true && commentText != props.comment.text) {
      const newComment = props.comment;
      newComment.text = commentText;
      axios
        .patch(`/comments/${props.comment.id}.json`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          comment: {
            text: commentText,
          },
        })
        .then((res) => {
          props.handler("edit", newComment);
          if (props.prospect) {
            props.loadProspects(true);
          }
          M.toast({
            html: `Comment submitted`,
            displayLength: 3000,
            classes: "green",
          });
        })
        .catch((err) => console.error(err));
    }
    setEditState(!editState);
  }

  return (
    <div
      style={{
        height: "auto",
      }}
      key={props.comment.user.id}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          gap: "16px",
          alignSelf: "stretch",
          paddingBottom: "16px",
          borderBottom: "1px solid #D7D7D7",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: "stretch",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <img
              alt={`${props.comment.user.name}'s avatar`}
              height="40"
              width="40"
              style={{
                borderRadius: "50%",
                alignSelf: "flex-start",
              }}
              src={props.comment.user.avatar_url}
            />
            <div>
              <span
                className="truncate"
                style={{
                  textAlign: "left",
                  width: "100%",
                  fontSize: "13px",
                  fontFamily: "Manrope",
                  fontWeight: "600",
                }}
              >
                {props.comment.user.name}
              </span>
              <span
                aria-label={`comment date ${props.comment.comment_date}`}
                style={{
                  alignSelf: "flex-start",
                  fontSize: "13px",
                  fontFamily: "Arial",
                  fontWeight: "400",
                  marginTop: "0",
                  color: "#313131",
                  whiteSpace: "nowrap",
                  wordBreak: "break-word",
                }}
              >
                {props.comment.comment_date}
              </span>
            </div>
            <div style={{ display: "flex" }}>
              {props.currentUser.id === props.comment.user.id && (
                <>
                  <ActionButton
                    role="button"
                    aria-label={
                      editState ? "done editing comment" : "edit comment"
                    }
                    onClick={toggleEditState}
                    style={{ marginRight: "8px" }}
                  >
                    {editState ? (
                      <i
                        className="material-icons"
                        style={{ color: "#519acc" }}
                      >
                        done
                      </i>
                    ) : (
                      <EditIcon height={15} width={15} />
                    )}
                  </ActionButton>
                  <ActionButton
                    role="button"
                    onClick={deleteComment}
                    aria-label="delete instruction comment"
                  >
                    <TrashIcon height={15} width={15} />
                  </ActionButton>
                </>
              )}
            </div>
          </div>
          <div aria-label={`project status ${props.comment.progress_id}`}>
            {props.comment.progress_id && (
              <ModalTag commentTag column={props.comment.progress_id} />
            )}
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <SwitchTransition>
            <CSSTransition
              key={editState}
              timeout={{
                appear: 300,
                enter: 390,
                exit: 390,
              }}
              classNames="node-fade"
            >
              {editState ? (
                <textarea
                  className="browser-default text-input-v2"
                  style={{ flex: "1 1 100%" }}
                  value={commentText}
                  placeholder="Add a comment..."
                  onChange={changeCommentText}
                />
              ) : (
                <span
                  style={{
                    flex: "1 1 100%",
                    overflow: "auto",
                    whiteSpace: "pre-line",
                    lineHeight: "20px",
                    width: "500px",
                    wordBreak: "break-word",
                  }}
                  className="arial smaller-text"
                >
                  {`${props.comment.text}`}
                </span>
              )}
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
    </div>
  );
}

const ActionButton = styled.span`
  border: 1px solid #d7d7d7;
  align-items: center;
  padding: 4.5px;
  height: 30px;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  width: 30px;
  justify-content: center;
  background-color: white;

  &:hover {
    border: 1px solid #519acc;
  }
`;
export default ProjectComment;
