import React from "react";
import moment from "moment";

function LinkBox(props) {
  return (
    <section aria-label="User time entry and PTO stats" id="link-box">
      <div role="list">
        <p>Time Summary</p>
        <div style={{ minWidth: "470px" }}>
          {props.links.map((u, index) => {
            //  var alignMap = {0: 'left', 1: 'center', 2: 'right', 3: 'left', 4: 'center', 5: 'right'}
            const today = moment(new Date());
            const urlArray = [
              `?start_date=${today.format(
                "YYYY-MM-DD"
              )}&end_date=${today.format("YYYY-MM-DD")}`,
              `?start_date=${today
                .subtract(1, "days")
                .format("YYYY-MM-DD")}&end_date=${today.format("YYYY-MM-DD")}`,
              `?start_date=${today
                .startOf("week")
                .format("YYYY-MM-DD")}&end_date=${today
                .endOf("week")
                .format("YYYY-MM-DD")}`,
              `?start_date=${today
                .subtract(1, "weeks")
                .startOf("week")
                .format("YYYY-MM-DD")}&end_date=${today
                .endOf("week")
                .format("YYYY-MM-DD")}`,
              `?start_date=${today
                .startOf("month")
                .format("YYYY-MM-DD")}&end_date=${today
                .endOf("month")
                .format("YYYY-MM-DD")}`,
              `?start_date=${today
                .subtract(1, "months")
                .startOf("month")
                .format("YYYY-MM-DD")}&end_date=${today
                .endOf("month")
                .format("YYYY-MM-DD")}`,
            ];
            return (
              <a key={u.label} href={`/time_tracking/users/${urlArray[index]}`}>
                <div
                  role="text"
                  aria-label={`${u.label} ${u.value.toFixed(2)} hours`}
                >
                  <p>
                    {u.label}
                    <br />
                    <span>{u.value.toFixed(2)}</span>
                  </p>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default LinkBox;
