import React from "react";
import moment from "moment";
import styled from "styled-components";
import ClosureRow from "../closures/closure_row";
import Task from "./task";
import ProjectPlusIcon from "components/icons/project_plus_icon";

const DayColumn = styled.div.attrs((props) => ({ disabled: props.disabled }))`
  display: flex;
  flex-direction: column;
  gap: 4px;
  button {
    opacity: 0 ${(props) => (props.disabled ? " !important" : "")};
    background-color: #e0e0e0 !important;
    transition: opacity 0.2s;
  }

  :hover button {
    opacity: 0.5;
  }
  button:hover {
    opacity: 1;
  }
`;

class ScheduleDayColumn extends React.Component {
  constructor(props) {
    super(props);
    this.addTask = this.addTask.bind(this);
    this.onDragEnter = this.onDragEnter.bind(this);

    this.onDragDrop = this.onDragDrop.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onBottomDragEnter = this.onBottomDragEnter.bind(this);
    this.onBottomDragLeave = this.onBottomDragLeave.bind(this);
    this.onBottomDragDrop = this.onBottomDragDrop.bind(this);
    this.dragCounter = 0;
    this.dragLeaveTimer = null;
    this.dayColumn = React.createRef();
  }

  addTask() {
    const data = {
      userName: this.props.userName,
      userId: this.props.userId,
      date: this.props.day,
      currentUserId: this.props.currentUserId,
      currentUserRoleId: this.props.currentUserRoleId,
      closeButton: true,
      customClass: "add-task-modal",
      order:
        this.props.tasks.length > 0
          ? Math.max(...this.props.tasks.map((t) => t.order)) + 1
          : null,
      backdropClickCheck: {
        title: "Discard Booking",
        text: "Are you sure that you want to discard this booking?",
      },
    };
    this.props.modalAction(true, "add-task", data);
  }

  onDragEnter(e) {
    if (this.props.tasks.length === 0) {
      if (!e.target.classList.contains("task-card")) {
        e.target.classList.add("day-column-drag-enter");
      }
    } else if (this.dragCounter === 0) {
      if (this.dragLeaveTimer) {
        clearTimeout(this.dragLeaveTimer);
        this.dragLeaveTimer = null;
      }
      this.dragCounter += 1;
      this.dayColumn.current.style.height = `auto`;
      this.dayColumn.current.style.overflow = "hidden";
    } else {
      this.dragCounter += 1;
    }
  }

  // leaving this in just in case but doesn't seems to be needed
  // onDragLeave(e) {
  //   console.log(e);
  //   return;
  //   if (this.props.tasks.length === 0) {
  //     if (this.dayColumn?.current) {
  //       this.dayColumn.current.classList.remove("day-column-drag-enter");
  //     }
  //   } else {
  //     this.dragCounter -= 1;

  //     if (this.dragCounter === 0 && this.dayColumn?.current) {
  //       this.dragLeaveTimer = setTimeout(() => {
  //         this.dayColumn.current.style.height = "auto";
  //         this.dayColumn.current.style.overflow = "auto";
  //         this.dragLeaveTimer = null;
  //       }, 200);
  //     }
  //   }
  // }

  onBottomDragEnter(e) {
    const draggingItem = this.props.getDraggingItem();
    if (
      this.props.tasks.length > 0 &&
      (draggingItem.start_date !== this.props.day ||
        draggingItem.user_id !== this.props.userId)
    ) {
      e.target.children[0].style.height = "25px";
    }
  }

  onBottomDragLeave(e) {
    const draggingItem = this.props.getDraggingItem();
    if (
      this.props.tasks.length > 0 &&
      (draggingItem.start_date !== this.props.day ||
        draggingItem.user_id !== this.props.userId)
    ) {
      e.target.children[0].style.height = "0";
    }
  }

  onDragOver(e) {
    e.preventDefault();
  }

  onDragDrop(e) {
    if (this.props.tasks.length === 0) {
      e.preventDefault();
      if (!e.target.classList.contains("task-card")) {
        e.target.classList.remove("add-task-dash-drag-enter");
        const data = e.dataTransfer.getData("text");
        const newData = {
          userId: this.props.userId,
          userFullTime: this.props.userFullTime,
          date: this.props.day,
          order: 0,
          reorder_ids: [],
        };
        const copy = !!e.altKey;
        this.props.dragDropAddTask(data, newData, copy);
      }
    }
  }

  onDragDrop(e) {
    if (this.props.tasks.length === 0) {
      // Add task to an empty column
      e.preventDefault();
      if (!e.target.classList.contains("task-card")) {
        e.target.classList.remove("add-task-dash-drag-enter");
        const data = e.dataTransfer.getData("text");
        const newData = {
          userId: this.props.userId,
          userFullTime: this.props.userFullTime,
          date: this.props.day,
          order: 0,
          reorder_ids: [],
        };
        const copy = !!e.altKey;
        this.props.dragDropAddTask(data, newData, copy);
      }
    } else {
      // Duplicate the dragged task
      const taskId = e.dataTransfer.getData("text");
      const taskToDuplicate = this.props.tasks.find(
        (task) => task.id === taskId
      );
      if (taskToDuplicate) {
        const newData = {
          userId: this.props.userId,
          userFullTime: this.props.userFullTime,
          date: this.props.day,
          order: taskToDuplicate.order + 1, // Place the duplicated task below the original task
          reorder_ids: [],
        };
        this.props.dragDropAddTask(taskId, newData, true); // Always duplicate when dragging into an existing column
      }
    }
  }

  onBottomDragDrop(e) {
    const draggingItem = this.props.getDraggingItem();
    if (
      this.props.tasks.length > 0 &&
      (draggingItem.start_date !== this.props.day ||
        draggingItem.user_id !== this.props.userId)
    ) {
      e.preventDefault();
      const data = e.dataTransfer.getData("text");
      const largestOrder = Math.max(...this.props.tasks.map((t) => t.order));
      const newData = {
        userId: this.props.userId,
        userFullTime: this.props.userFullTime,
        date: this.props.day,
        order: largestOrder + 1,
        reorder_ids: [],
      };
      const copy = !!e.altKey;
      this.props.dragDropAddTask(data, newData, copy);
    }
  }

  render() {
    const { day, tasks } = this.props;
    if (this.props.closure) {
      return (
        <div
          style={{
            width: this.props.twoWeeks
              ? this.props.userSchedule
                ? "calc(10% - 15px)"
                : "9.09%"
              : this.props.userSchedule
              ? "calc(20% - 30px)"
              : "16.66%",
            padding: `0px 15px ${this.props.userSchedule ? "0px" : "35px"}`,
            position: "relative",
            borderRight:
              this.props.twoWeeks && this.props.currentWeekEnd
                ? "solid 1px lightgray"
                : "none",
          }}
          aria-label={`${moment(day).format(
            "dddd MMMM Do"
          )}, Office Closed for ${this.props.closure.name}`}
        >
          <ClosureRow
            twoWeeks={this.props.twoWeeks}
            name={this.props.closure.name}
          />
        </div>
      );
    }
    let totalHours = 0;
    let unconfirmedHours = 0;
    const disabled = moment(day) < this.props.cutoffDate;
    const currentOrders = tasks.map((t) => ({ id: t.id, order: t.order }));
    tasks.forEach((t) => {
      if (t.project_confirmation && t.part_time_over_confirmed) {
        totalHours += t.hours;
      } else {
        unconfirmedHours += t.hours;
      }
    });
    return (
      <DayColumn
        ref={this.dayColumn}
        disabled={disabled}
        className="day-column-list"
        role={tasks.length > 0 ? "list" : "group"}
        aria-label={`${moment(day).format("dddd MMMM Do")}, ${
          tasks.length > 0
            ? `${tasks.length} booking${
                tasks.length > 1 ? "s" : ""
              } scheduled, totaling ${totalHours + unconfirmedHours} hour${
                totalHours + unconfirmedHours > 1 ? "s" : ""
              }`
            : "No bookings scheduled"
        }`}
        id={`${this.props.day}-${this.props.userId}`}
        onDrop={this.onDragDrop}
        onDragOver={this.onDragOver}
        onDragEnter={this.onDragEnter}
        // onDragLeave={this.onDragLeave}
        style={{
          width: this.props.twoWeeks
            ? this.props.userSchedule
              ? "calc(10% - 15px)"
              : "9.09%"
            : this.props.userSchedule
            ? "calc(20% - 30px)"
            : "16.66%",
          padding: "8px",
          position: "relative",
          borderRight:
            this.props.twoWeeks && this.props.currentWeekEnd
              ? "solid 1px lightgray"
              : "none",
        }}
      >
        {tasks.length > 0 ? (
          tasks
            .sort((a, b) => a.order - b.order)
            .map((t) => {
              return (
                <Task
                  currentUserId={this.props.currentUserId}
                  userFullTime={this.props.userFullTime}
                  currentUserRoleId={this.props.currentUserRoleId}
                  viewOnly={this.props.viewOnly}
                  selectedProjects={this.props.selectedProjects}
                  notAllowed={
                    this.props.allowedProjectIds &&
                    this.props.allowedProjectIds.indexOf(t.project_id) === -1
                  }
                  disabled={disabled}
                  overbooked={totalHours > 8}
                  twoWeeks={this.props.twoWeeks}
                  date={this.props.day}
                  modalAction={this.props.modalAction}
                  key={t.id}
                  task={t}
                  bookedByMe={this.props.bookedByMe}
                  dragDropAddTask={this.props.dragDropAddTask}
                  setDraggingItem={this.props.setDraggingItem}
                  getDraggingItem={this.props.getDraggingItem}
                  currentOrders={currentOrders}
                />
              );
            })
        ) : (
          <p
            aria-hidden="true"
            style={{
              color: "#b3b3b3",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              margin: "0px",
              fontFamily: "Arial",
              fontSize: "13px",
              position: "relative",
              top: !this.props.userSchedule ? "10px" : "0px",
              fontWeight: "400",
            }}
          >
            Available
          </p>
        )}
        {!this.props.userSchedule && (
          <div
            className="bottom-drag-area"
            style={{ height: "24px" }}
            onDragOver={this.onDragOver}
            onDragEnter={this.onBottomDragEnter}
            onDragLeave={this.onBottomDragLeave}
            onDrop={this.onBottomDragDrop}
          >
            <div
              id="task-button-top-drag-area"
              style={{
                height: "0px",
                transition: "height 0.2s",
                // backgroundColor: "lightgray",
                pointerEvents: "none",
              }}
            />
          </div>
        )}
        {!this.props.userSchedule && (
          <div
            className="task-button-container"
            style={{
              textAlign: "center",
              padding: `0px ${this.props.twoWeeks ? "5px" : "8px"}`,
              height: "20px",
              position: "absolute",
              bottom: "8px",
              left: "0px",
              right: "0px",
            }}
            key={day}
          >
            <button
              style={{
                cursor: disabled ? "default" : "pointer",
                textAlign: "center",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                background: "#c0c0c0",
                width: "100%",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px",
                height: "20px",
              }}
              type="button"
              disabled={disabled}
              onClick={this.addTask}
              className="add-task-dash"
              aria-label="Add new booking"
            >
              <ProjectPlusIcon />
            </button>
          </div>
        )}
      </DayColumn>
    );
  }
}

ScheduleDayColumn.defaultProps = {
  userSchedule: false,
};

export default ScheduleDayColumn;
