import React from "react";
import moment from "moment";
import NameColumn from "./name_column";
import ProjectDayColumn from "./project_day_column";

class UserRow extends React.Component {
  constructor(props) {
    super(props);

    this.renderTasks = this.renderTasks.bind(this);
  }

  renderTasks() {
    const {
      id,
      userId,
      index,
      userName,
      modalAction,
      showRemoveUser,
      fullTime,
      userTasks,
      firstDay,
      closures,
      cardColor,
      createdBy,
      dragDropAddTask,
      twoWeeks,
    } = this.props;
    const nameNode = [];
    nameNode.push(
      <NameColumn
        modalAction={modalAction}
        showRemoveUser={showRemoveUser}
        name={userName}
        projectId={id}
        id={userId}
        key={userName + index}
        fullTime={fullTime}
      />
    );
    const tasks = userTasks;
    const taskNode = [];
    let offset = 0;
    const currentWeek = [];
    while (offset < 7) {
      currentWeek.push(
        moment(firstDay)
          .add((offset += 1), "d")
          .format("YYYY-MM-DD")
      );
    }
    const secondFirst = moment(firstDay).add(7, "d").format("YYYY-MM-DD");
    const secondWeek = [];
    const secondTaskNode = [];
    let secondOffset = 0;
    while (secondOffset < 7) {
      secondWeek.push(
        moment(secondFirst)
          .add((secondOffset += 1), "d")
          .format("YYYY-MM-DD")
      );
    }
    for (let i = 0; i < currentWeek.length; i += 1) {
      if (
        moment(currentWeek[i]).format("dddd") === "Monday"
        || moment(currentWeek[i]).format("dddd") === "Tuesday"
        || moment(currentWeek[i]).format("dddd") === "Wednesday"
        || moment(currentWeek[i]).format("dddd") === "Thursday"
        || moment(currentWeek[i]).format("dddd") === "Friday"
      ) {
        taskNode.push(
          <ProjectDayColumn
            dragDropAddTask={dragDropAddTask}
            modalAction={modalAction}
            day={currentWeek[i]}
            twoWeeks={twoWeeks}
            tasks={tasks}
            closures={closures}
            userId={userId}
            userName={userName}
            id={id}
            key={i}
            cardColor={cardColor}
            created_by={createdBy}
          />
        );
      }
    }
    for (let i = 0; i < secondWeek.length; i++) {
      if (
        moment(secondWeek[i]).format("dddd") === "Monday"
        || moment(secondWeek[i]).format("dddd") === "Tuesday"
        || moment(secondWeek[i]).format("dddd") === "Wednesday"
        || moment(secondWeek[i]).format("dddd") === "Thursday"
        || moment(secondWeek[i]).format("dddd") === "Friday"
      ) {
        secondTaskNode.push(
          <ProjectDayColumn
            dragDropAddTask={dragDropAddTask}
            modalAction={modalAction}
            day={secondWeek[i]}
            twoWeeks={twoWeeks}
            tasks={tasks}
            closures={closures}
            userId={userId}
            userName={userName}
            firstDay={firstDay}
            id={id}
            key={i}
            cardColor={cardColor}
          />
        );
      }
    }
    if (twoWeeks) {
      return (
        <div style={{ display: "flex" }}>
          {nameNode}
          <div className="col s12 m5 week-row right-border">{taskNode}</div>
          <div className="col s12 m5 week-row">{secondTaskNode}</div>
        </div>
      );
    }
    return (
      <div style={{ display: "flex" }}>
        {nameNode}
        {taskNode}
      </div>
    );
  }

  render() {
    const { colorIndex } = this.props;
    if (colorIndex % 2 !== 0) {
      return (
        <div className="row off-white white-row">{this.renderTasks()}</div>
      );
    }
    return <div className="row white-row">{this.renderTasks()}</div>;
  }
}

export default UserRow;
