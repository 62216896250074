import React from "react";
import SvgShell from "./svg_shell";

function MoneyBagsIcon({ height, width, svgStyles, color }) {
  return (
    <SvgShell height={height} width={width} svgStyles={svgStyles} color={color}>
      <path d="M31.7,7.27a25,25,0,1,0,25,25A25,25,0,0,0,31.7,7.27Z" />
      <path
        fill="#f1f2f2"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.5px"
        d="M28,33.5a3.41,3.41,0,0,1,2.76-3.35v-.72a.93.93,0,1,1,1.86,0v.74a3.7,3.7,0,0,1,2.47,2.14.93.93,0,0,1-1.72.72,1.81,1.81,0,0,0-1.68-1.12,1.65,1.65,0,0,0-1.82,1.59c0,.29,0,1.16,2,1.62h0c3,.7,3.45,2.45,3.45,3.43a3.42,3.42,0,0,1-2.76,3.35v.74a.93.93,0,1,1-1.86,0V41.9a3.68,3.68,0,0,1-2.47-2.14A.93.93,0,1,1,30,39l0,0a1.82,1.82,0,0,0,1.68,1.12,1.65,1.65,0,0,0,1.82-1.59c0-.29,0-1.15-2-1.62h0C28.46,36.24,28,34.49,28,33.5Zm6.71-12.78A.93.93,0,0,0,36,20.49l3.21-4.57A.93.93,0,0,0,39,14.66a11.79,11.79,0,0,0-14.69,0,.93.93,0,0,0-.17,1.25l3.21,4.57a.93.93,0,0,0,1.3.17.92.92,0,0,0,.22-1.24l-2.69-3.83a10,10,0,0,1,11,0l-2.69,3.83a.94.94,0,0,0,.23,1.3ZM19.15,38.86a21.2,21.2,0,0,1,7.53-16.14.94.94,0,0,1,.6-.22h8.83a.94.94,0,0,1,.6.22,21.16,21.16,0,0,1,7.53,16.14v2.65l.29.14A3.47,3.47,0,0,1,43,48.25H20.35a3.47,3.47,0,0,1-1.49-6.6l.29-.14Zm-.37,6.27a1.57,1.57,0,0,0,1.56,1.25H43a1.61,1.61,0,0,0,1.6-1.61,1.64,1.64,0,0,0-.91-1.44L42.91,43a.93.93,0,0,1-.52-.86V38.86a19.34,19.34,0,0,0-6.62-14.5H27.63A19.36,19.36,0,0,0,21,38.87V42.1a.93.93,0,0,1-.52.84l-.82.37a1.59,1.59,0,0,0-.88,1.82Z"
      />
    </SvgShell>
  );
}

MoneyBagsIcon.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "#519acc",
};

export default MoneyBagsIcon;
