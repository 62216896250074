import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import axios from "axios";
import AlphabetClosureRow from "./alphabet_closure_row.jsx";
import ReactModal from "../modals/react_modal";

class ClosureIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closures: this.props.closures,
      modalOpen: false,
      currentModal: "",
      modalData: {},
    };
    this.setModal = this.setModal.bind(this);
    this.getClosures = this.getClosures.bind(this);
    this.onAddClosureClick = this.onAddClosureClick.bind(this);
  }

  setModal(bool, page, data) {
    this.setState({
      modalOpen: bool,
      currentModal: page,
      modalData: data,
    });
  }

  getClosures() {
    axios
      .get(`/api/v1/closures`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.setState({
          closures: res.data,
        });
      })
      .catch((err) => console.error(err));
  }

  onAddClosureClick() {
    this.setModal(true, "add-closure", {});
  }

  render() {
    const { closures } = this.state;
    const indexes = [];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthNode = [];
    const indexNode = [];
    for (let w = 0; w < closures.length; w++) {
      indexes.push(moment(closures[w].start_date).format("MMMM"));
    }
    const sortedIndexes = indexes.sort((item, pos) => {
      return months.indexOf(item) > months.indexOf(pos);
    });
    const filteredIndexes = sortedIndexes.filter((item, pos) => {
      return sortedIndexes.indexOf(item) === pos;
    });
    for (let x = 0; x < filteredIndexes.length; x++) {
      monthNode.push({ index: filteredIndexes[x], closures: [] });
    }
    for (let y = 0; y < monthNode.length; y++) {
      for (let z = 0; z < closures.length; z++) {
        if (
          moment(closures[z].start_date).format("MMMM") === monthNode[y].index
        ) {
          monthNode[y].closures.push(closures[z]);
        }
      }
      indexNode.push(
        <AlphabetClosureRow
          index={monthNode[y].index}
          closures={monthNode[y].closures}
          key={y}
          modalAction={this.setModal}
        />
      );
    }
    return (
      <div>
        <div className="row">
          <h1>
            <a className="green-text" onClick={this.onAddClosureClick}>
              <i className="material-icons index-inline-icon">add_circle</i>
            </a>{" "}
            Office Closures
          </h1>
          <div className="divider gray z-depth-2 shadow-divider" />
        </div>
        <div className="row">{indexNode}</div>
        <ReactModal
          isShowing={this.state.modalOpen}
          data={this.state.modalData}
          page={this.state.currentModal}
          modalAction={this.setModal}
          refreshClosures={this.getClosures}
        />
      </div>
    );
  }
}

export default ClosureIndex;
