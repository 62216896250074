import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

const TimeWidgetDiv = styled.div`
  width: 375px;
  height: 214px;
  padding: 15px 25px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 1px 2px 1px #d5d2d2;
  margin: 0px 0px 0 20px;
  font-size: 16px;
  p:first-of-type {
    margin: 0 0 5px;
  }
  p {
    margin: 0px;
  }
  div.inner-div {
    grid-gap: 5px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-auto-rows: minmax(70px, auto);
    padding: 5px 0;
  }
  p.hours {
    font-size: 24px;
    font-weight: 500;
  }
  }
`;
function TimeWidget(props) {
  const [hours, setHours] = useState([0, 0, 0, 0]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      // You can await here
      const response = await axios(
        `/projects/${props.projectId}/time_widget.json`
      );
      setHours(response.data);
      setIsLoading(false);
    }
    fetchData();
  }, [props.projectId]);

  return (
    <TimeWidgetDiv>
      <p style={{ fontSize: "16px" }} className="manrope bold">
        Hours Statistics
      </p>
      <div className="inner-div">
        <div className="hours-count">
          {isLoading ? <p>Loading ...</p> : <p className="arial">Time Spent</p>}
          <p className="manrope hours">
            {hours[0]}
            <span style={{ fontSize: "13px" }} />
          </p>
        </div>
        <div className="hours-count">
          <p className="arial">Weekly Average</p>
          <p
            style={{
              transition: "color .45s",
              color: props.direction
                ? props.direction === "up"
                  ? "#2cb882"
                  : "red "
                : "",
            }}
            className="manrope hours"
          >
            {hours[1]}
            <span style={{ fontSize: "13px" }} />
            {props.direction ? (
              <span
                style={{
                  marginLeft: "15px",
                  width: 0,
                  height: 0,
                  transition: "border-bottom-color .45s, transform .45s",
                  borderBottomColor:
                    props.direction === "down" ? "red" : "#2cb882",
                  borderLeft: "5px solid transparent",
                  borderRight: "5px solid transparent",
                  borderBottomWidth: "13px",
                  borderBottomStyle: "solid",
                  transform:
                    props.direction === "down" ? "rotateX(180deg)" : "",
                }}
              />
            ) : null}
          </p>
        </div>
        <div className="hours-count">
          <p className="arial">Last Month</p>
          <p className="manrope hours">
            {hours[2]}
            <span style={{ fontSize: "13px" }} />
          </p>
        </div>
        <div className="hours-count">
          <p className="arial">This Month</p>
          <p className="manrope hours">
            {hours[3]}
            <span style={{ fontSize: "13px" }} />
          </p>
        </div>
      </div>
    </TimeWidgetDiv>
  );
}

export default TimeWidget;
