import React from "react";

const GreenRoomIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M18.5068 1.5C18.5068 7.5 5.00684 4.5 5.00684 16.5C5.00684 21 6.50684 22.5 6.50684 22.5H8.00684C8.00684 22.5 5.75684 21 5.75684 16.5C5.75684 13.5 12.5068 10.5 12.5068 10.5C12.5068 10.5 6.52184 13.746 6.52184 16.827C16.0716 18.3225 21.3583 7.2045 18.5061 1.5H18.5068Z"
        fill="white"
      />
    </svg>
  );
};

export default GreenRoomIcon;
