import React from "react";
import axios from "axios";
import { components } from "react-select";
import SingleSelectWrapper from "../common/single_select_wrapper";
import ExitIcon from "../icons/exit_icon";

const breakingH4 = {
  overflow: "hidden",
  wordBreak: "break-all",
  textOverflow: "ellipsis",
  outline: "none",
  fontFamily: "Manrope",
  fontSize: "18px",
  fontWeight: "600",
};

class AddClient extends React.Component {
  constructor(props) {
    super(props);
    var parentProject;
    if (
      this.props.data.client &&
      this.props.data.client.parent &&
      this.props.data.client.parent_id
    ) {
      parentProject = this.props.data.client.project_options.find(
        (x) => x.value === this.props.data.client.parent_id
      );
    } else {
      parentProject = null;
    }
    this.state = {
      clientName: this.props.data.client ? this.props.data.client.name : "",
      billingInterval: this.props.data.client
        ? {
            label: `${this.props.data.client.billing_interval} days`,
            value: this.props.data.client.billing_interval,
          }
        : { label: `30 days`, value: 30 },
      parent:
        this.props.data.client && this.props.data.client.parent
          ? this.props.data.client.parent
          : false,
      selectedProject: parentProject,
      leadSource: null,
    };
    this.submitButton = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.setToInactive = this.setToInactive.bind(this);
    this.handleParentChange = this.handleParentChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.labelInput = (props) => <components.Input {...props} maxLength={20} />;
  }

  handleParentChange(e) {
    this.setState({ parent: e.target.checked });
  }

  handleSelectChange(e) {
    this.setState({ selectedProject: e });
  }

  handleSourceChange = (e) => {
    this.setState({ leadSource: e });
  };

  handleInputChange(e) {
    var input = e.target.value;
    var name = e.target.name;
    this.setState({ [name]: input });
  }

  closeModal = () => {
    this.props.modalAction(false, "", {});
  };

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    this.props.data.setBackDrop;
    setTimeout(() => {
      var elem = document.querySelector("#add-client-modal h4");
      if (elem) {
        elem.focus();
      }
    }, 100);
  }

  componentWillUnmount() {
    if (this.props.data.client) {
      var elem = document.getElementById("page-nav-title");
      if (elem) {
        elem.focus();
      }
    } else {
      var elem = document.querySelector(".common-button-submit");
      if (elem) {
        elem.focus();
      }
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    var self = this;
    if (self.props.data.client) {
      var client = {
        name: self.state.clientName.trim(),
        billing_interval: self.state.billingInterval.value,
        parent: self.state.parent,
      };
      if (self.state.selectedProject && self.state.selectedProject) {
        client.parent_id = self.state.selectedProject.value;
      }
      axios
        .patch(`/api/v1/clients/${self.props.data.client.id}.json`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          client: client,
        })
        .then((res) => {
          M.toast({
            html: `${res.data.name} updated!`,
            displayLength: 3000,
            classes: "green",
          });
          self.props.data.clientsHandler("edit", res.data);
          self.props.modalAction(false, "", {});
        })
        .catch((err) => {
          M.toast({
            html: "Client already exists, choose a unique name.",
            classes: "red",
          });
          console.error(err);
        });
    } else if (self.props.data.prospect) {
      axios
        .post(`/api/v1/create_project_prospect.json`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          client: {
            name: self.state.clientName.trim(),
            prospect: true,
            lead_source: self.state.leadSource.value,
          },
        })
        .then((res) => {
          M.toast({
            html: `${res.data.name} created!`,
            displayLength: 3000,
            classes: "green",
          });
          self.props.data.prospectsHandler("create", res.data);
          self.props.modalAction(false, "", {});
        })
        .catch((err) => {
          M.toast({ html: "error", classes: "red" });
          console.error(err);
        });
    } else {
      axios
        .post(`/api/v1/clients.json`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          client: {
            name: self.state.clientName.trim(),
            billing_interval: self.state.billingInterval.value,
          },
        })
        .then((res) => {
          M.toast({
            html: `${res.data.name} created!`,
            displayLength: 3000,
            classes: "green",
          });
          self.props.data.clientsHandler("create", res.data);
          self.props.modalAction(false, "", {});
        })
        .catch((err) => {
          M.toast({ html: err.response.data, classes: "red" });
          console.error(err);
        });
    }
  }

  setToInactive() {
    axios
      .patch(`/api/v1/clients/${this.props.data.client.id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        client: { active: this.props.data.client.active ? false : true },
      })
      .then((res) => {
        M.toast({
          html: `${res.data.name} ${
            this.props.data.client.active ? "deactivated" : "activated"
          }`,
          displayLength: 3000,
          classes: "green",
        });
        this.props.data.clientsHandler("edit", res.data);
        this.props.modalAction(false, "", {});
      })
      .catch((err) => {
        M.toast({
          html: "Client already exists, choose a unique name.",
          classes: "red",
        });
        console.error(err);
      });
  }

  handleBillingIntervalChange = (billingInterval) => {
    this.setState({ billingInterval });
  };

  render() {
    return (
      <div
        role="dialog"
        aria-label="Add Client Modal"
        id="add-client-modal"
        className="modal-content"
      >
        <div className="flex-container flex-al-center flex-j-start">
          <span style={{ marginRight: "16px" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
            >
              <rect
                width="42"
                height="42"
                rx="21"
                fill="url(#paint0_linear_1857_4059)"
              />
              <path
                d="M20.9998 32.9412H17.0194H14.0586C11.2972 32.9412 9.05859 30.7026 9.05859 27.9412V14.0588C9.05859 11.2974 11.2972 9.05884 14.0586 9.05884H27.941C30.7024 9.05884 32.9409 11.2974 32.9409 14.0588V27.9412C32.9409 30.7026 30.7024 32.9412 27.9409 32.9412H24.9802H20.9998ZM20.9998 22.3268C22.2823 22.3268 23.3769 21.8735 24.2836 20.9668C25.1902 20.0602 25.6436 18.9656 25.6436 17.683C25.6436 16.4005 25.1902 15.3058 24.2836 14.3992C23.3769 13.4926 22.2823 13.0392 20.9998 13.0392C19.7172 13.0392 18.6226 13.4926 17.7159 14.3992C16.8093 15.3058 16.356 16.4005 16.356 17.683C16.356 18.9656 16.8093 20.0602 17.7159 20.9668C18.6226 21.8735 19.7172 22.3268 20.9998 22.3268ZM11.7122 29.2129C11.7122 29.8064 12.1934 30.2876 12.7869 30.2876H29.2126C29.8062 30.2876 30.2874 29.8064 30.2874 29.2129C30.2874 28.9243 30.1728 28.6463 29.9601 28.4513C28.8418 27.4256 27.563 26.6061 26.1239 25.9928C24.5423 25.3187 22.8343 24.9813 20.9998 24.9804C19.1644 24.9804 17.4559 25.3179 15.8744 25.9928C14.4353 26.6069 13.157 27.4264 12.0394 28.4513C11.8268 28.6463 11.7122 28.9243 11.7122 29.2129Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1857_4059"
                  x1="21"
                  y1="0"
                  x2="21"
                  y2="42"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#519ACC" />
                  <stop offset="1" stopColor="#2B88C8" />
                </linearGradient>
              </defs>
            </svg>
          </span>
          {this.props.data.client ? (
            <h4 tabIndex={0} style={breakingH4}>
              Edit Client
            </h4>
          ) : (
            <h4 tabIndex={0} style={breakingH4}>
              Add a{this.props.data.prospect ? " prospective" : ""} Client
            </h4>
          )}
          <button
            type="button"
            id="modalClose"
            aria-label="Close Modal"
            className="btn-icon"
            onClick={this.closeModal}
            style={{ position: "absolute", top: "41px", right: "26px" }}
          >
            <ExitIcon
              svgStyles={{ pointerEvents: "none" }}
              height="25px"
              width="25px"
            />
          </button>
        </div>
        {this.props.data.client &&
          this.props.data.currentUser &&
          this.props.data.currentUser.role_id >= 4 && (
            <a
              name="cancel"
              onClick={this.setToInactive}
              style={{
                fontFamily: "Arial",
                fontSize: "13px",
                fontWeight: "400px",
                position: "relative",
                top: "7px",
                color: "#519acc",
              }}
            >
              {this.props.data.client.active ? "Deactivate Client" : "Activate"}
            </a>
          )}
        <form
          className="new_client"
          id="new_client"
          onSubmit={this.handleSubmit}
        >
          <div style={{ margin: "25px 0 10px" }} className="input-field">
            <label
              style={{
                margin: "10px 0px",
                fontFamily: "manrope",
                fontSize: "13px",
                fontWeight: "600",
              }}
              htmlFor="client_name"
            >
              Client Name<span className="base-color">*</span>
            </label>
            <input
              required="required"
              placeholder="Client Name"
              type="text"
              style={{ margin: "21px 0 8px" }}
              value={this.state.clientName}
              name="clientName"
              id="client_name"
              onChange={this.handleInputChange}
              className="browser-default text-input-v2"
              maxLength={50}
              autoComplete="off"
              aria-describedby="character-count"
            />
            <p
              id="character-count"
              aria-live="polite"
              aria-hidden={true}
              className="input-counter"
            >{`${this.state.clientName.length}/50`}</p>
          </div>
          <div
            style={
              this.props.data.client &&
              this.props.data.client.project_options &&
              this.props.data.client.project_options.length >= 1
                ? { marginTop: "-35px", marginBottom: "40px" }
                : {}
            }
          >
            {this.props.data.client &&
              this.props.data.client.project_options &&
              this.props.data.client.project_options.length >= 1 && (
                <div className="input-field">
                  <label
                    className="custom-checkbox-menu"
                    style={{
                      position: "relative",
                      top: "10px",
                      boxShadow: "none",
                    }}
                  >
                    <input
                      checked={this.state.parent}
                      onChange={this.handleParentChange}
                      className="filled-in"
                      type="checkbox"
                    />
                    <span
                      style={{
                        lineHeight: "26px",
                        paddingLeft: "28px",
                        fontFamily: "Arial",
                      }}
                    >
                      Group by Client
                    </span>
                  </label>
                </div>
              )}
            {this.props.data.prospect && !this.props.data.client && (
              <div className="input-field">
                <SingleSelectWrapper
                  options={[
                    { value: "website", label: "Website" },
                    { value: "existing client", label: "Existing Client" },
                    { value: "referral", label: "Referral" },
                    { value: "linkedin", label: "LinkedIn" },
                    { value: "other", label: "Other" },
                  ]}
                  onChange={this.handleSourceChange}
                  name="leadSource"
                  placeholder={"Select a source"}
                  value={this.state.leadSource}
                  menuPlacement="top"
                  customStyles={{
                    container: (styles) => ({ ...styles, width: "175px" }),
                  }}
                />
                <label
                  className="select-labels transition-labels more-padding"
                  htmlFor="filter_filters"
                >
                  Lead Source<span className="base-color">*</span>
                </label>
              </div>
            )}
            {/* {this.state.parent && (
                <div style={{margin: "40px 0"}} className="input-field">
                  <label className="select-labels" htmlFor="filter_filters">
                    Select parent project
                  </label>
                  <SingleSelectWrapper 
                    options={this.props.data.client.project_options}
                    onChange={this.handleSelectChange}
                    placeholder={"Select Project"}
                    ariaLabel="Parent project dropdown"
                    value={this.state.selectedProject}
                    menuPlacement="auto"
                    customStyles={{ container: (styles) => ({ ...styles, width: "200px" }), menu: (styles) => ({ ...styles, width: "200px" }) }}
                  />
              </div>
              )} */}
          </div>
          {/* vvvv HIDING TIME TRACKING STUFF FOR NOW VVVVV */}
          {/* <div style={{marginTop:"-10px"}} className="flex-container">
            <div className="input-field">
              <label className="select-labels" htmlFor="filter_filters">
                Invoice Payment Term
              </label>
              <SingleSelectWrapper 
                options={[
                  {label:`30 days`, value: 30},
                  {label:`45 days`, value: 45},
                  {label:`60 days`, value: 60}
                ]}
                onChange={this.handleBillingIntervalChange}
                placeholder={"Select User"}
                ariaLabel="Payment term dropdown"
                value={this.state.billingInterval}
                menuPlacement="auto"
                customStyles={{ container: (styles) => ({ ...styles, width: "120px" }), menu: (styles) => ({ ...styles, width: "120px" }) }}
              />
            </div>
          </div> */}
          {/* ^^^^ HIDING TIME TRACKING STUFF FOR NOW ^^^^ */}
          <div style={{ marginTop: "30px" }} className="button-row right-align">
            <button
              type="button"
              name="cancel"
              onClick={this.closeModal}
              className="common-button-cancel"
            >
              Cancel
            </button>
            <button
              disabled={
                this.state.clientName === "" ||
                (!this.props.data.client &&
                  this.props.data.prospect &&
                  !this.state.leadSource)
              }
              type="submit"
              name="commit"
              className="common-button-submit"
              ref={this.submitButton}
              style={{ marginRight: "0px" }}
            >
              {this.props.data.client ? "Update" : "Create"} Client
            </button>
          </div>
        </form>
      </div>
    );
  }
}
export default AddClient;
