import React from "react";
import moment from "moment";
import CalendarIcon from "../icons/calendar_icon";
import RoundDropdown from "../projects/round_dropdown";
import { SingleDatePicker } from "react-dates";

class DayBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDay: moment().format("dddd, MMMM Do YYYY"),
      currentDayNum: moment(),
      pickerFocus: false,
    };
    this.handlePrevDay = this.handlePrevDay.bind(this);
    this.handleNextDay = this.handleNextDay.bind(this);
    this.renderDateDisplay = this.renderDateDisplay.bind(this);
    this.handleUpdateStateByCalendar =
      this.handleUpdateStateByCalendar.bind(this);
  }

  handlePrevDay() {
    var currentDay = this.state.currentDay;
    var currentDayNum = this.state.currentDayNum;
    var prevDay;
    var prevDayNum;
    if (currentDayNum.day() === 1) {
      prevDay = moment(currentDay, "dddd, MMMM Do YYYY")
        .subtract(3, "d")
        .format("dddd, MMMM Do YYYY");
      prevDayNum = moment(currentDayNum).subtract(3, "d");
    } else {
      prevDay = moment(currentDay, "dddd, MMMM Do YYYY")
        .subtract(1, "d")
        .format("dddd, MMMM Do YYYY");
      prevDayNum = moment(currentDayNum).subtract(1, "d");
    }
    this.setState({ currentDay: prevDay, currentDayNum: prevDayNum });
    this.onUpdateCurrentDate(prevDay);
  }

  handleNextDay() {
    var currentDay = this.state.currentDay;
    var currentDayNum = this.state.currentDayNum;
    var nextDay;
    var nextDayNum;
    if (currentDayNum.day() === 5) {
      nextDay = moment(currentDay, "dddd, MMMM Do YYYY")
        .add(3, "d")
        .format("dddd, MMMM Do YYYY");
      nextDayNum = moment(currentDayNum).add(3, "d");
    } else {
      nextDay = moment(currentDay, "dddd, MMMM Do YYYY")
        .add(1, "d")
        .format("dddd, MMMM Do YYYY");
      nextDayNum = moment(currentDayNum).add(1, "d");
    }
    this.setState({ currentDay: nextDay, currentDayNum: nextDayNum });
    this.onUpdateCurrentDate(nextDay);
  }

  onUpdateCurrentDate(newDate) {
    var newDay = newDate;
    var updateDate = moment(newDay, "dddd, MMMM Do YYYY").format("MM-DD-YYYY");
    this.props.onUpdateCurrentDate(updateDate);
  }

  handleUpdateStateByCalendar(newDate) {
    var newDay = moment(newDate, "MM-DD-YYYY").format("dddd, MMMM Do YYYY");
    this.setState({
      currentDay: newDay,
      currentDayNum: moment(newDate, "MM-DD-YYYY"),
    });
    this.onUpdateCurrentDate(newDay);
  }

  renderDateDisplay() {
    return (
      <div
        style={{ width: "fit-content" }}
        aria-label={`Showing schedule for ${this.state.currentDay}. Press shift+N or shift+P anywhere on the page to navigate to next or previous day, respectively.`}
        id="date-display"
      >
        {this.state.loading ? (
          <div
            aria-hidden="true"
            style={{ height: "30px", width: "30px" }}
            className="page-load-spinner"
          />
        ) : (
          <p aria-hidden="true" style={{ margin: "0px" }}>
            {this.state.currentDay}
          </p>
        )}
      </div>
    );
  }

  onFocusChangePicker = ({ focused }) => {
    this.setState({ pickerFocus: focused });
  };

  onDateChangePicker = (date) => {
    this.setState({ currentDayNum: date });
  };

  render() {
    return (
      <div id="date-nav-header">
        <button
          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            padding: "0px",
            marginRight: "16px",
          }}
          id="prevWeek"
          onClick={this.handlePrevDay}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="15"
            viewBox="0 0 10 15"
            fill="none"
            style={{ verticalAlign: "-3px" }}
          >
            <g clipPath="url(#clip0_2850_1302)">
              <path
                d="M9.29099 11.5116C10.0711 12.2791 10.213 13.5349 9.43284 14.3023C9.00731 14.7209 8.43993 15 7.94348 15C7.44702 15 6.87965 14.7907 6.52504 14.4419L0.354823 8.37209C-0.141631 7.88372 -0.141631 7.18605 0.354823 6.69768L2.41156 4.67442L6.59596 0.55814C7.3761 -0.209302 8.6527 -0.209302 9.50376 0.55814C10.2839 1.32558 10.2839 2.5814 9.50376 3.4186L5.31936 7.53488C5.31936 7.53488 5.31936 7.53488 5.31936 7.60465L9.29099 11.5116Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_2850_1302">
                <rect
                  width="10"
                  height="15"
                  fill="white"
                  transform="translate(10 15) rotate(180)"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
        {this.renderDateDisplay()}
        <button
          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            padding: "0px",
            marginLeft: "16px",
          }}
          id="upcomingWeek"
          onClick={this.handleNextDay}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="15"
            viewBox="0 0 10 15"
            fill="none"
            style={{ verticalAlign: "-3px" }}
          >
            <g clipPath="url(#clip0_2850_1305)">
              <path
                d="M0.709005 3.48837C-0.0711367 2.72093 -0.212981 1.46512 0.567161 0.697674C0.992693 0.27907 1.56007 0 2.05652 0C2.55298 0 3.12035 0.209302 3.47496 0.55814L9.64518 6.62791C10.1416 7.11628 10.1416 7.81395 9.64518 8.30233L7.58844 10.3256L3.40404 14.4419C2.6239 15.2093 1.3473 15.2093 0.496239 14.4419C-0.283903 13.6744 -0.283903 12.4186 0.496239 11.5814L4.68064 7.46512C4.68064 7.46512 4.68064 7.46512 4.68064 7.39535L0.709005 3.48837Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_2850_1305">
                <rect width="10" height="15" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
        <div
          id="start-date-container"
          style={{ position: "absolute", right: "8px" }}
          className="flex-j-start flex-container flex-al-center"
        >
          <div
            style={{
              position: "relative",
              left: "40px",
              zIndex: 1,
              top: "5px",
            }}
          >
            <CalendarIcon width="22px" height="22px" color="#519acc" />
          </div>
          <SingleDatePicker
            date={this.state.currentDayNum}
            onDateChange={this.handleUpdateStateByCalendar}
            focused={this.state.pickerFocus}
            onFocusChange={this.onFocusChangePicker}
            numberOfMonths={1}
            placeholder={"Select a date"}
            orientation={"horizontal"}
            daySize={30}
            navPrev={
              <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                <RoundDropdown rotate={"rotate(90deg)"} />
              </div>
            }
            navNext={
              <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                <RoundDropdown rotate={"rotate(-90deg)"} />
              </div>
            }
            hideKeyboardShortcutsPanel
            inputIconPosition={"after"}
            customInputIcon={<RoundDropdown width="22px" height="22px" />}
            isOutsideRange={this.isDayBlocked}
            id="single_date_picker"
          />
        </div>
      </div>
    );
  }
}

export default DayBar;
