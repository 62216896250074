import React from "react";
import ClockIcon from "../icons/clock_icon";
import DeleteIcon from "../icons/delete_icon";
import EditIcon from "../icons/edit_icon";
import TimeObjectSelector from "./time_object_selector";

class TimeEntryCard extends React.Component {
  constructor(props) {
    super(props);
    this.deleteEntry = this.deleteEntry.bind(this);
    this.editTimeEntry = this.editTimeEntry.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.displayButtons = this.displayButtons.bind(this);
  }

  editTimeEntry() {
    this.props.editTimeEntry(this.props.timeEntry);
  }

  deleteEntry() {
    this.props.deleteEntry(this.props.timeEntry.id);
  }

  onMouseEnter(e) {
    const target = e.target.parentNode.parentNode;
    const elem = document.getElementById("time-notes-floaty");
    const container = document.getElementById("time-tracking-container");
    const x = e.pageX;
    const y = e.pageY;
    if (
      target.scrollWidth > target.clientWidth &&
      elem &&
      container &&
      x &&
      y
    ) {
      elem.innerText = this.props.timeEntry.notes;
      const { height } = elem.getBoundingClientRect();
      if (container.offsetHeight - (height + y) < 0 && y - (height + 10) > 0) {
        elem.style.top = `${y - height - 10}px`;
      } else if (y - (height + 10) < 0) {
        elem.style.top = `${y - height / 2}px`;
      } else {
        elem.style.top = `${y + 10}px`;
      }
      elem.style.left = `${x + 10}px`;
      elem.style.opacity = 1;
    }
  }

  onMouseLeave(e) {
    const elem = document.getElementById("time-notes-floaty");
    if (elem) {
      elem.style.opacity = 0;
    }
  }

  buttons() {
    const t = this.props.timeEntry;
    return (
      <>
        <button
          aria-label={`Edit Project ${t.project_name} ${
            t.time_entry_task_name ? `Task ${t.time_entry_task_name}` : ""
          } Time Entry`}
          style={{ marginLeft: "40px" }}
          onClick={this.editTimeEntry}
        >
          <EditIcon
            height={20}
            width={20}
            color="#519acc"
            svgStyles={{ pointerEvent: "none" }}
          />
        </button>
        <button
          aria-label={`Delete ${t.project_name} ${
            t.time_entry_task_name ? `Task ${t.time_entry_task_name}` : ""
          } Time Entry`}
          style={{ marginLeft: "5px" }}
          onClick={this.deleteEntry}
        >
          <DeleteIcon
            height={20}
            width={20}
            color="#519acc"
            svgStyles={{ pointerEvent: "none" }}
          />
        </button>
      </>
    );
  }

  displayButtons() {
    if (this.props.reports === undefined) {
      return this.buttons();
    }
    if (this.props.reports && this.props.currentUser.role_id >= 4) {
      return this.buttons();
    }
    if (
      this.props.reports &&
      this.props.currentUser.id === this.props.timeEntry.user_id
    ) {
      return this.buttons();
    }
    return null;
  }

  render() {
    const t = this.props.timeEntry;
    return (
      <this.props.EntryCardDiv
        tabIndex={0}
        id={`time-entry-card-row-${t.id}`}
        aria-label={`Project ${t.project_name}, ${
          t.time_entry_task_name ? `Task ${t.time_entry_task_name}, ` : ""
        }${t.hours.toFixed(2)} hours logged.`}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            aria-hidden="true"
            style={{
              backgroundColor: t.color,
              overflow: "hidden",
              height: "34px",
              padding: "2px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0px 15px 0px 10px",
            }}
          >
            <ClockIcon height={30} width={30} color="white" />
          </div>
          <p aria-hidden="true">
            {t.project_name}
            <br />
            <span style={{ color: "#bfbfbf", cursor: "default" }}>
              {t.time_entry_task_name}
              {t.notes && (
                <span>
                  {" "}
                  -{" "}
                  <em
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                  >
                    {t.notes}
                  </em>
                </span>
              )}
            </span>
          </p>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ClockIcon
            height={20}
            width={20}
            color="#519acc"
            svgStyles={{ margin: "0px 5px" }}
          />
          <span aria-hidden="true">{t.hours.toFixed(2)}</span>
          {this.displayButtons()}
        </div>
      </this.props.EntryCardDiv>
    );
  }
}

export default TimeEntryCard;
