import React from "react";
import styled from "styled-components";
import TimeBar from "./time_bar";
import RoundDropdown from "../projects/round_dropdown";

const Title = styled.p`
  width: ${(props) =>
    (props.bar === false ? "78%" : props.objects ? "29%" : "39%")};
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 15px;
  white-space: pre;
`;

const CashMoney = styled.p`
  color: #313131;
  margin-right: auto;
  max-width: 100px;
  min-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
  white-space: pre;
`;

const InnerCashMoney = styled.p`
  color: #313131;
  margin-right: auto;
  max-width: 100px;
  min-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
  white-space: pre;
`;

const TimeLink = styled.a`
  position: absolute;
  width: 67%;
  height: 100%;
`;

const TasksList = styled.div`
  max-height: ${(props) => (props.dropped === true ? "0" : "10000px")};
  transition: 0.5s max-height, 0.5s margin-top;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 0 3px;
  margin-top: ${(props) => (props.dropped === true ? "0" : "8px")};
`;

const InnerTaskRow = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: white;
  border: none;
  position: relative;
  border-radius: 5px;
  box-shadow: rgb(209, 209, 209) 0px 1px 1px 1px;
  margin-bottom: 10px;
  margin-right: 20px;
  position: relative;
  & .div-btn-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    background: transparent;
    border: none;
    padding: 0;
    top: 0px;
    left: 0px;
    z-index: 3;
  }
`;

const TimeRowDiv = styled.div`
  width: 100%;
  height: ${(props) => (props.tasksPresent ? "auto" : "55px")};
  display: flex;
  transition: background-color 0.2s;
  align-items: center;
  border: solid 1px #d1d1d1;
  justify-content: space-between;
  background-color: white;
  flex-wrap: ${(props) => (props.tasksPresent ? "wrap" : "none")};
  position: relative;
  border-radius: 5px;
  box-shadow: rgb(209, 209, 209) 0px 1px 1px 1px;
  margin-bottom: 10px;
  margin-right: 10px;
  position: relative;
  &:hover {
    background-color: #dadada52;
  }
  & .div-btn-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    background: transparent;
    border: none;
    padding: 0;
    top: 0px;
    left: 0px;
    z-index: 3;
  }
`;

class TimeRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropped: true,
    };
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.screenReaderClick = this.screenReaderClick.bind(this);
  }

  dropdownToggle() {
    this.setState({ dropped: !this.state.dropped });
  }

  getHours() {
    if (this.props.object) {
      switch (this.props.objectName) {
        case "project":
          return [
            this.props.object.billable_hours,
            this.props.object.non_billable_hours,
          ];
        case "client":
          return [
            this.props.object.billable_hours,
            this.props.object.non_billable_hours,
          ];
        case "task":
          return [
            this.props.object.billable_hours,
            this.props.object.non_billable_hours,
          ];
        case "user":
          return [
            this.props.object.billable_hours,
            this.props.object.non_billable_hours,
          ];
      }
    } else {
      return [0, 0];
    }
  }

  getSearchParamsUrl() {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    if (params.has("start_date") && params.has("end_date")) {
      return `/time_tracking/${this.props.objectName}s/${
        this.props.object.id
      }?${params.toString()}`;
    }
    return `/time_tracking/${this.props.objectName}s/${this.props.object.id}`;
  }

  getUserSearchParamsUrl(task) {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    if (params.has("start_date") && params.has("end_date")) {
      return `/time_tracking/users/${task.id}}?${params.toString()}`;
    }
    return `/time_tracking/users/${task.id}`;
  }

  screenReaderClick(e) {
    const elem = e.target;
    if (elem.childNodes && elem.childNodes.length > 0) {
      const link = elem.querySelector("a");
      if (link) {
        link.click();
        if (
          elem.childNodes[elem.childNodes.length - 1].tagName.toLowerCase()
          === "div"
        ) {
          setTimeout(() => {
            elem.childNodes[elem.childNodes.length - 1].focus();
          }, 100);
        }
      }
    }
  }

  render() {
    const hours = this.getHours();
    if (this.props.hideBar) {
      return (
        <TimeRowDiv
          delay={this.props.delay ? this.props.delay : 0}
          className="card-slide"
          tasksPresent={this.props.taskObjects && this.props.object.tasks}
          role="link"
          onClick={this.screenReaderClick}
        >
          <Title
            role="text"
            aria-label={`Name ${
              this.props.object.name ? this.props.object.name : ""
            }, `}
            bar={false}
            index={this.props.index}
          >
            {this.props.object.name ? this.props.object.name : ""}
          </Title>
          {this.props.objectName === "project"
            && this.props.object.client_name && (
              <Title
                role="text"
                aria-label={`Client ${this.props.object.client_name}, `}
                bar={false}
                index={this.props.index}
              >
                {this.props.object.client_name}
              </Title>
          )}
          {this.props.currentUser.role_id === 5
            && this.props.object
            && this.props.object.dollars && (
              <CashMoney
                role="text"
                aria-label={`Billable amount ${this.props.object.dollars}, `}
                index={this.props.index}
              >
                {this.props.object.dollars}
              </CashMoney>
          )}
          <TimeBar
            billableBar={this.props.objectName != "task"}
            billable={hours[0]}
            hide={this.props.hideBar}
            nonBillable={hours[1]}
            objectName={this.props.objectName}
            total={hours[1] + hours[0]}
          />
          <TimeLink
            aria-hidden
            style={{ width: "100%" }}
            href={this.getSearchParamsUrl()}
          />
        </TimeRowDiv>
      );
    } if (this.props.object) {
      const expanded = this.props.taskObjects && this.props.object.tasks
        ? { "aria-expanded": !this.state.dropped ? "true" : "false" }
        : {};
      return (
        <TimeRowDiv
          delay={this.props.delay ? this.props.delay : 0}
          className="card-slide"
          tasksPresent={this.props.taskObjects && this.props.object.tasks}
          onClick={this.screenReaderClick}
          role={
            this.props.taskObjects && this.props.object.tasks
              ? "button"
              : "link"
          }
          {...expanded}
        >
          {this.props.taskObjects && this.props.object.tasks ? (
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                maxWidth: "36%",
              }}
            >
              <RoundDropdown
                rotate={this.state.dropped ? "rotate(180deg)" : ""}
                height={15}
                width={15}
                svgStyles={{ verticalAlign: "sub", margin: "0 10px 0 15px" }}
              />
              <div
                className="swatch"
                style={{
                  marginLeft: "40px",
                  marginRight: "-5px",
                  backgroundColor: this.props.object.color
                    ? this.props.object.color
                    : "#6caada",
                }}
              />
              <Title
                role="text"
                aria-label={`Name ${
                  this.props.object.name ? this.props.object.name : ""
                }, `}
                objects={this.props.object.tasks}
                taskObjects={this.props.taskObjects}
                index={this.props.index}
              >
                {this.props.object.name ? this.props.object.name : ""}
              </Title>
            </div>
          ) : (
            <Title
              role="text"
              aria-label={`Name ${
                this.props.object.name ? this.props.object.name : ""
              }, `}
              objects={this.props.object.tasks}
              taskObjects={this.props.taskObjects}
              index={this.props.index}
            >
              {this.props.object.name ? this.props.object.name : ""}
            </Title>
          )}

          {this.props.currentUser.role_id === 5
            && this.props.object
            && this.props.object.dollars && (
              <CashMoney
                role="text"
                aria-label={`Billable amount ${this.props.object.dollars}, `}
                index={this.props.index}
              >
                {this.props.object.dollars}
              </CashMoney>
          )}
          {this.props.taskObjects ? (
            <>
              <TimeBar
                // single={true}
                index={this.props.index}
                color="#6caada"
                objectName="task"
                billableBar
                billable={
                  this.props.object.billable_hours === null
                    ? 0
                    : this.props.object.billable_hours
                }
                total={
                  this.props.object.non_billable_hours
                  + this.props.object.billable_hours
                }
                nonBillable={
                  this.props.object.non_billable_hours === null
                    ? 0
                    : this.props.object.non_billable_hours
                }
                subtotal={this.props.object.non_billable_hours}
              />
              {this.props.object.tasks ? (
                <TimeLink
                  aria-hidden
                  onClick={this.dropdownToggle}
                />
              ) : (
                <TimeLink
                  aria-hidden
                  href={`/time_tracking/tasks/${this.props.object.id}`}
                />
              )}
            </>
          ) : (
            <>
              <TimeBar
                billableBar={this.props.objectName != "task"}
                billable={hours[0]}
                hide={this.props.hideBar}
                nonBillable={hours[1]}
                objectName={this.props.objectName}
                total={hours[1] + hours[0]}
              />
              <TimeLink
                aria-hidden
                href={this.getSearchParamsUrl()}
              />
            </>
          )}
          {this.props.object.tasks && (
            <>
              <div
                style={{
                  height: "2px",
                  width: "100%",
                  position: "absolute",
                  transition: `${
                    this.state.dropped
                      ? "max-width .25s"
                      : "max-width .5s ease .2s"
                  }`,
                  maxWidth: `${this.state.dropped ? "0%" : "100%"}`,
                  backgroundColor: "rgb(209, 209, 209)",
                  left: 0,
                  top: "50px",
                }}
              />
              <TasksList
                tabIndex={0}
                role="list"
                aria-label="task user list"
                aria-hidden={this.state.dropped}
                dropped={this.state.dropped}
              >
                {this.props.object.tasks.map((task) => (
                  <InnerTaskRow role="link" key={task.id}>
                    <Title
                      objects={this.props.object.tasks}
                      taskObjects={this.props.taskObjects}
                      style={{ width: "35.5%" }}
                    >
                      {task.name}
                    </Title>
                    {this.props.currentUser.role_id === 5 && task.dollars && (
                      <InnerCashMoney
                        role="text"
                        aria-label={`Billable amount ${task.dollars}, `}
                        index={this.props.index}
                      >
                        {task.dollars}
                      </InnerCashMoney>
                    )}
                    <TimeBar
                      billableBar
                      billable={
                        task.billable_hours === null ? 0 : task.billable_hours
                      }
                      subtotal={task.non_billable_hours}
                      nonBillable={
                        this.props.object.non_billable_hours === null
                          ? 0
                          : this.props.object.non_billable_hours
                      }
                      total={task.billable_hours + task.non_billable_hours}
                      objectName="task"
                      nested
                    />
                    <TimeLink
                      aria-hidden
                      href={this.getUserSearchParamsUrl(task)}
                    />
                  </InnerTaskRow>
                ))}
              </TasksList>
            </>
          )}
        </TimeRowDiv>
      );
    }
    return null;
  }
}

export default TimeRow;
