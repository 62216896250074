import React from "react";
import moment from "moment";
import axios from "axios";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";

class ProjectEditTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      singleBooking: !this.props.data.multitaskId,
      startDate: moment(this.props.data.date),
      endDate: moment(this.props.data.date),
      startPickerFocus: false,
      endPickerFocus: false,
      hours: "",
      project: this.props.data.taskName,
      datePickerOverlay: false,
      taskId: this.props.data.taskId,
      days: [],
      notes: "",
      createdBy: "",
      singleDay: false,
      originalStartDate: "",
      originalEndDate: "",
      projectConfirmation: false,
      originalData: {},
    };
    this.renderBookingType = this.renderBookingType.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.deleteTask = this.deleteTask.bind(this);
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.onStartFocusChange = this.onStartFocusChange.bind(this);
    this.onEndDateChange = this.onEndDateChange.bind(this);
    this.onEndFocusChange = this.onEndFocusChange.bind(this);
    this.setHourClick = this.setHourClick.bind(this);
    this.endPickerClick = this.endPickerClick.bind(this);
    this.startPickerClick = this.startPickerClick.bind(this);
    this.handleSingleSwitch = this.handleSingleSwitch.bind(this);
    this.handleProjectConfirmation = this.handleProjectConfirmation.bind(this);
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };

    axios
      .get(`/api/v1/tasks/${this.state.taskId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.setState({
          hours: res.data.hours,
          startDate: moment(res.data.start_date),
          endDate: moment(res.data.end_date),
          originalStartDate: moment(res.data.start_date),
          originalEndDate: moment(res.data.end_date),
          originalData: res.data,
          projectConfirmation: res.data.project_confirmation,
        });
        document.getElementById(`task_hours_${res.data.hours}`).checked = true;
        const displayStart = moment(res.data.start_date).format("D MMMM, YY");
        const displayEnd = moment(res.data.end_date).format("D MMMM, YY");
        document.getElementById("task_start_date").value = displayStart;
        document.getElementById("task_end_date").value = displayEnd;
        this.refs.task_notes.value = res.data.notes ? res.data.notes : "";
        if (res.data.notes) {
          this.refs.task_notes_label.classList.add("active");
        }
      })
      .catch((err) => console.error(err));
    if (this.props.data.created_by != null) {
      axios
        .get(`/api/v1/users/${this.props.data.created_by}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.setState({
            createdBy: res.data.name,
          });
        })
        .catch((err) => console.error(err));
    }
  }

  deleteTask() {
    this.refs.delete_task_button.disabled = true;
    this.refs.submit_button.disabled = true;
    const selectedDate = moment(this.props.data.date);
    if (this.state.singleBooking || !this.refs.multi_delete_switch.checked) {
      if (
        this.refs.single_delete_switch &&
        this.refs.single_delete_switch.checked
      ) {
        if (selectedDate.isSame(this.state.originalStartDate)) {
          axios
            .patch(`/api/v1/tasks/${this.state.taskId}`, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              start_date: this.state.originalStartDate.add(1, "days"),
              end_date: this.state.originalEndDate,
            })
            .then((res) => {
              M.toast({
                html: `Booking deleted`,
                displayLength: 3000,
                classes: "green",
              });
              this.props.data.projectName
                ? this.props.refreshTasks()
                : this.props.refreshUsers();
              this.props.modalAction(false, "", {});
            })
            .catch((err) => {
              M.toast({
                html: `Server Error`,
                displayLength: 3000,
                classes: "red",
              });
              console.error(err);
            });
        } else if (selectedDate.isSame(this.state.originalEndDate)) {
          axios
            .patch(`/api/v1/tasks/${this.state.taskId}`, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              start_date: this.state.originalStartDate,
              end_date: this.state.originalEndDate.subtract(1, "days"),
            })
            .then((res) => {
              M.toast({
                html: `Booking deleted`,
                displayLength: 3000,
                classes: "green",
              });
              this.props.data.projectName
                ? this.props.refreshTasks()
                : this.props.refreshUsers();
              this.props.modalAction(false, "", {});
            })
            .catch((err) => {
              M.toast({
                html: `Server Error`,
                displayLength: 3000,
                classes: "red",
              });
              console.error(err);
            });
        } else {
          // if selected delete date is in middle of single booking range, update original booking to be first slice and create new booking for second slice
          axios
            .patch(`/api/v1/tasks/${this.state.taskId}`, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              start_date: this.state.originalStartDate,
              end_date: moment(selectedDate).subtract(1, "days"),
            })
            .then((res) => {
              axios
                .post(`/api/v1/tasks`, {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  hours: this.state.originalData.hours,
                  start_date: moment(selectedDate).add(1, "days"),
                  end_date: this.state.originalEndDate,
                  notes: this.state.originalData.notes,
                  project_id: this.state.originalData.project_id,
                  user_id: this.props.data.userId,
                })
                .then((res) => {
                  M.toast({
                    html: `Booking deleted`,
                    displayLength: 3000,
                    classes: "green",
                  });
                  this.props.data.projectName
                    ? this.props.refreshTasks()
                    : this.props.refreshUsers();
                  this.props.modalAction(false, "", {});
                })
                .catch((err) => {
                  M.toast({
                    html: `Server Error`,
                    displayLength: 3000,
                    classes: "red",
                  });
                  console.error(err);
                });
            })
            .catch((err) => {
              M.toast({
                html: `Server Error`,
                displayLength: 3000,
                classes: "red",
              });
              console.error(err);
            });
        }
      } else {
        axios
          .delete(`/api/v1/tasks/${this.state.taskId}`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            M.toast({
              html: `Booking deleted`,
              displayLength: 3000,
              classes: "green",
            });
            this.props.data.projectName
              ? this.props.refreshTasks()
              : this.props.refreshUsers();
            this.props.modalAction(false, "", {});
          })
          .catch((err) => {
            M.toast({
              html: `Server Error`,
              displayLength: 3000,
              classes: "red",
            });
            console.error(err);
          });
      }
    } else {
      axios
        .delete(`/api/v1/multitasks/${this.props.data.multitaskId}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          M.toast({
            html: `Recurring booking deleted`,
            displayLength: 3000,
            classes: "green",
          });
          this.props.data.projectName
            ? this.props.refreshTasks()
            : this.props.refreshUsers();
          this.props.modalAction(false, "", {});
        })
        .catch((err) => {
          M.toast({
            html: `Server Error`,
            displayLength: 3000,
            classes: "red",
          });
          console.error(err);
        });
    }
  }

  handleProjectConfirmation() {
    this.setState({
      projectConfirmation: !this.state.projectConfirmation,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.refs.submit_button.disabled = true;
    this.refs.delete_task_button.disabled = true;
    const notes = this.refs.task_notes.value ? this.refs.task_notes.value : "";
    const selectedDate = moment(this.props.data.date);
    if (this.state.singleBooking || !this.refs.multi_edit_switch.checked) {
      if (
        this.refs.single_delete_switch &&
        this.refs.single_delete_switch.checked
      ) {
        if (selectedDate.isSame(this.state.originalStartDate)) {
          axios
            .patch(`/api/v1/tasks/${this.state.taskId}`, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              start_date: this.state.originalStartDate.add(1, "days"),
              end_date: this.state.originalEndDate,
            })
            .then((res) => {
              axios
                .post(`/api/v1/tasks`, {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  hours: this.state.hours,
                  start_date: selectedDate,
                  end_date: selectedDate,
                  notes,
                  project_id: this.state.originalData.project_id,
                  user_id: this.props.data.userId,
                })
                .then((res) => {
                  M.toast({
                    html: `Booking updated!`,
                    displayLength: 3000,
                    classes: "green",
                  });
                  this.props.data.projectName
                    ? this.props.refreshTasks()
                    : this.props.refreshUsers();
                  this.props.modalAction(false, "", {});
                })
                .catch((err) => {
                  M.toast({
                    html: `Server Error`,
                    displayLength: 3000,
                    classes: "red",
                  });
                  console.error(err);
                });
            })
            .catch((err) => {
              M.toast({
                html: `Server Error`,
                displayLength: 3000,
                classes: "red",
              });
              console.error(err);
            });
        } else if (selectedDate.isSame(this.state.originalEndDate)) {
          axios
            .patch(`/api/v1/tasks/${this.state.taskId}`, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              start_date: this.state.originalStartDate,
              end_date: this.state.originalEndDate.subtract(1, "days"),
            })
            .then((res) => {
              axios
                .post(`/api/v1/tasks`, {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  hours: this.state.hours,
                  start_date: selectedDate,
                  end_date: selectedDate,
                  notes,
                  project_id: this.state.originalData.project_id,
                  user_id: this.props.data.userId,
                })
                .then((res) => {
                  M.toast({
                    html: `Booking updated!`,
                    displayLength: 3000,
                    classes: "green",
                  });
                  this.props.data.projectName
                    ? this.props.refreshTasks()
                    : this.props.refreshUsers();
                  this.props.modalAction(false, "", {});
                })
                .catch((err) => {
                  M.toast({
                    html: `Server Error`,
                    displayLength: 3000,
                    classes: "red",
                  });
                  console.error(err);
                });
            })
            .catch((err) => {
              M.toast({
                html: `Server Error`,
                displayLength: 3000,
                classes: "red",
              });
              console.error(err);
            });
        } else {
          // if selected delete date is in middle of single booking range, update original booking to be first slice and create new booking for second slice
          axios
            .patch(`/api/v1/tasks/${this.state.taskId}`, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              start_date: this.state.originalStartDate,
              end_date: moment(selectedDate).subtract(1, "days"),
            })
            .then((res) => {
              axios
                .post(`/api/v1/tasks`, {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  hours: this.state.originalData.hours,
                  start_date: moment(selectedDate).add(1, "days"),
                  end_date: this.state.originalEndDate,
                  notes: this.state.originalData.notes,
                  project_id: this.state.originalData.project_id,
                  user_id: this.props.data.userId,
                })
                .then((res) => {
                  axios
                    .post(`/api/v1/tasks`, {
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                      hours: this.state.hours,
                      start_date: selectedDate,
                      end_date: selectedDate,
                      notes,
                      project_id: this.state.originalData.project_id,
                      user_id: this.props.data.userId,
                    })
                    .then((res) => {
                      M.toast({
                        html: `Booking updated!`,
                        displayLength: 3000,
                        classes: "green",
                      });
                      this.props.data.projectName
                        ? this.props.refreshTasks()
                        : this.props.refreshUsers();
                      this.props.modalAction(false, "", {});
                    })
                    .catch((err) => {
                      M.toast({
                        html: `Server Error`,
                        displayLength: 3000,
                        classes: "red",
                      });
                      console.error(err);
                    });
                })
                .catch((err) => {
                  M.toast({
                    html: `Server Error`,
                    displayLength: 3000,
                    classes: "red",
                  });
                  console.error(err);
                });
            })
            .catch((err) => {
              M.toast({
                html: `Server Error`,
                displayLength: 3000,
                classes: "red",
              });
              console.error(err);
            });
        }
      } else {
        axios
          .patch(`/api/v1/tasks/${this.state.taskId}`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            hours: this.state.hours,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
            notes,
            project_confirmation: this.state.projectConfirmation,
          })
          .then((res) => {
            M.toast({
              html: `Booking updated!`,
              displayLength: 3000,
              classes: "green",
            });
            this.props.data.projectName
              ? this.props.refreshTasks()
              : this.props.refreshUsers();
            this.props.modalAction(false, "", {});
          })
          .catch((err) => {
            M.toast({
              html: `Server Error`,
              displayLength: 3000,
              classes: "red",
            });
            console.error(err);
          });
      }
    } else {
      axios
        .patch(`/api/v1/tasks/${this.state.taskId}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          hours: this.state.hours,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
          notes,
          multitask_id: this.props.data.multitaskId,
        })
        .then((res) => {
          M.toast({
            html: `Recurring booking updated!`,
            displayLength: 3000,
            classes: "green",
          });
          this.props.data.projectName
            ? this.props.refreshTasks()
            : this.props.refreshUsers();
          this.props.modalAction(false, "", {});
        })
        .catch((err) => {
          M.toast({
            html: `Server Error`,
            displayLength: 3000,
            classes: "red",
          });
          console.error(err);
        });
    }
  }

  handleInputChange(e) {
    this.setState({ project: e });
  }

  multiHours() {
    if (this.state.singleBooking) {
      return <div />;
    }
    return (
      <div className="col s12 m4">
        <div
          className="margin-top margin-bottom"
          id="multi_select"
          style={{ display: "block" }}
        >
          <p>Edit hours for all bookings in series?</p>
          <div className="switch">
            <label>
              No
              <input
                type="checkbox"
                id="multi_edit_switch"
                ref="multi_edit_switch"
              />
              <span className="lever" />
              Yes
            </label>
          </div>
        </div>
      </div>
    );
  }

  multiDelete() {
    if (this.state.singleBooking) {
      return <div />;
    }
    return (
      <div
        className="margin-top margin-bottom"
        id="multi_delete"
        style={{ display: "block" }}
      >
        <p>Delete all bookings in series?</p>
        <div className="switch">
          <label>
            No
            <input
              type="checkbox"
              id="multi_delete_switch"
              ref="multi_delete_switch"
            />
            <span className="lever" />
            Yes
          </label>
        </div>
      </div>
    );
  }

  singleDelete() {
    if (this.state.singleBooking && this.state.endDate > this.state.startDate) {
      return (
        <div
          className="col s12 m12"
          id="single_delete"
          style={{ display: "block" }}
        >
          <p>Delete/Update single day?</p>
          <div className="switch">
            <label>
              No
              <input
                type="checkbox"
                id="single_delete_switch"
                ref="single_delete_switch"
                onClick={this.handleSingleSwitch}
              />
              <span className="lever" />
              Yes
            </label>
          </div>
        </div>
      );
    }
    return <div />;
  }

  handleSingleSwitch() {
    this.setState({
      singleDay: !this.state.singleDay,
    });
  }

  setHourClick(e) {
    this.setState({ hours: e.target.value });
  }

  startPickerClick() {
    this.setState({ startPickerFocus: true, datePickerOverlay: true });
  }

  endPickerClick() {
    this.setState({ endPickerFocus: true, datePickerOverlay: true });
  }

  renderBookingType() {
    const options = [];
    return (
      <div id="new_task_form">
        <form className="new_task" id="new_task" onSubmit={this.handleSubmit}>
          <p className="" id="">
            {this.props.data.projectName ? (
              ""
            ) : (
              <span>
                <label>Project: </label>
                <span id="project_name_master">{this.state.project}</span>
                <br />
              </span>
            )}
            <label>Booked by: </label>
            <span id="master_task_client">{this.state.createdBy}</span>
          </p>
          <input
            required="required"
            type="hidden"
            name="task[user_id]"
            id="task_user_id"
            value="1"
          />
          <div className={`col s12 ${this.state.singleBooking ? "m12" : "m8"}`}>
            <label htmlFor="task_hours">Hours</label>
            <p>
              <label className="task-label" htmlFor="task_hours_1">
                <input
                  required
                  type="radio"
                  value="1"
                  name="task[hours]"
                  id="task_hours_1"
                  onClick={this.setHourClick}
                />
                <span>1</span>
              </label>
              <label className="task-label" htmlFor="task_hours_2">
                <input
                  type="radio"
                  value="2"
                  name="task[hours]"
                  id="task_hours_2"
                  onClick={this.setHourClick}
                />
                <span>2</span>
              </label>
              <label className="task-label" htmlFor="task_hours_3">
                <input
                  type="radio"
                  value="3"
                  name="task[hours]"
                  id="task_hours_3"
                  onClick={this.setHourClick}
                />
                <span>3</span>
              </label>
              <label className="task-label" htmlFor="task_hours_4">
                <input
                  type="radio"
                  value="4"
                  name="task[hours]"
                  id="task_hours_4"
                  onClick={this.setHourClick}
                />
                <span>4</span>
              </label>
              <label className="task-label" htmlFor="task_hours_5">
                <input
                  type="radio"
                  value="5"
                  name="task[hours]"
                  id="task_hours_5"
                  onClick={this.setHourClick}
                />
                <span>5</span>
              </label>
              <label className="task-label" htmlFor="task_hours_6">
                <input
                  type="radio"
                  value="6"
                  name="task[hours]"
                  id="task_hours_6"
                  onClick={this.setHourClick}
                />
                <span>6</span>
              </label>
              <label className="task-label" htmlFor="task_hours_7">
                <input
                  type="radio"
                  value="7"
                  name="task[hours]"
                  id="task_hours_7"
                  onClick={this.setHourClick}
                />
                <span>7</span>
              </label>
              <label className="task-label" htmlFor="task_hours_8">
                <input
                  type="radio"
                  value="8"
                  name="task[hours]"
                  id="task_hours_8"
                  onClick={this.setHourClick}
                />
                <span>8</span>
              </label>
            </p>
          </div>
          {this.multiHours()}
          <div className="row datepicker-row">
            <div className="col s6">
              <label
                htmlFor="task_start_date"
                data-error="Add date"
                data-success="right"
                className=""
                ref="task_start_date_label"
              >
                Start date
              </label>
              <input
                className="startPicker"
                disabled={this.state.singleDay}
                onClick={this.startPickerClick}
                defaultValue={moment(this.state.startDate).format("DD MMM, YY")}
                required="required"
                type="text"
                name="task[start_date]"
                id="task_start_date"
                ref="task_start_date"
              />
            </div>
            <div className="col s6">
              <label
                htmlFor="task_end_date"
                data-error="Add date"
                data-success="right"
                className=""
                ref="task_end_date_label"
              >
                End date
              </label>
              <input
                className="endPicker"
                disabled={this.state.singleDay}
                required
                onClick={this.endPickerClick}
                defaultValue={moment(this.state.endDate).format("DD MMM, YY")}
                type="text"
                name="task[end_date]"
                id="task_end_date"
                ref="task_end_date"
              />
            </div>
          </div>
          <div className="input-field">
            <textarea
              className="materialize-textarea"
              name="task[notes]"
              id="task_notes"
              ref="task_notes"
            >
              {this.props.data.notes}
            </textarea>
            <label htmlFor="task_notes" ref="task_notes_label">
              Notes (optional)
            </label>
          </div>
          <div className="row center">
            <div className="col s12 m6">
              <div className="button-row row center">
                <input
                  type="button"
                  className="btn white dark-gray-text closure-button"
                  id="delete_task_button"
                  ref="delete_task_button"
                  value="Delete Booking"
                  onClick={this.deleteTask}
                />
              </div>
              {this.multiDelete()}
            </div>
            <div className="col s12 m6">
              <div className="button-row row center">
                {this.state.singleBooking && (
                  <div
                    id="single_delete"
                    style={{
                      display: "block",
                      position: "absolute",
                      top: "20px",
                      right: "20px",
                    }}
                  >
                    <p style={{ marginTop: "0px", marginBottom: "7px" }}>
                      Project confirmed?
                    </p>
                    <div className="switch" style={{ textAlign: "right" }}>
                      <label>
                        No
                        <input
                          type="checkbox"
                          checked={this.state.projectConfirmation}
                          onChange={this.handleProjectConfirmation}
                          id="project_confirmation_switch"
                          ref="project_confirmation_switch"
                        />
                        <span className="lever" />
                        Yes
                      </label>
                    </div>
                  </div>
                )}
                <input
                  type="submit"
                  className="common-button-submit white-text closure-button"
                  value="Update Booking"
                  id="edit_task_button"
                  ref="submit_button"
                />
              </div>
            </div>
            {this.singleDelete()}
          </div>
        </form>
      </div>
    );
  }

  onStartDateChange(date) {
    if (this.state.singleBooking) {
      if (
        !this.refs.task_end_date.value ||
        moment(date).isBefore(this.state.endDate) ||
        moment(date).isSame(this.state.endDate)
      ) {
        this.setState({ startDate: date });
        this.refs.task_start_date.value = moment(date).format("DD MMM, YY");
        this.refs.task_start_date_label.classList.add("active");
      } else {
        this.setState({ startDate: date, endDate: date });
        this.refs.task_start_date.value = moment(date).format("DD MMM, YY");
        this.refs.task_start_date_label.classList.add("active");
        this.refs.task_end_date.value = moment(date).format("DD MMM, YY");
        this.refs.task_end_date_label.classList.add("active");
        // M.toast({html: `Start date must be before end date.`, displayLength: 3000, classes: 'red' });
      }
    } else if (
      !this.refs.task_end_date.value ||
      moment(date).isBefore(this.state.endDate) ||
      moment(date).isSame(this.state.endDate)
    ) {
      this.setState({ startDate: date });
      this.refs.task_start_date.value = moment(date).format("DD MMM, YY");
      this.refs.task_start_date_label.classList.add("active");
    } else {
      this.setState({ startDate: date, endDate: date });
      this.refs.task_start_date.value = moment(date).format("DD MMM, YY");
      this.refs.task_start_date_label.classList.add("active");
      this.refs.task_end_date.value = moment(date).format("DD MMM, YY");
      this.refs.task_end_date_label.classList.add("active");
      // M.toast({html: `Start date must be before end date.`, displayLength: 3000, classes: 'red' });
    }
  }

  onStartFocusChange({ focused }) {
    this.setState({ startPickerFocus: focused, datePickerOverlay: focused });
  }

  onEndDateChange(date) {
    if (this.state.singleBooking) {
      if (
        !this.refs.task_start_date.value ||
        moment(date).isAfter(this.state.startDate) ||
        moment(date).isSame(this.state.startDate)
      ) {
        this.setState({ endDate: date });
        this.refs.task_end_date.value = moment(date).format("DD MMM, YY");
        this.refs.task_end_date_label.classList.add("active");
      } else {
        this.setState({ startDate: date, endDate: date });
        this.refs.task_start_date.value = moment(date).format("DD MMM, YY");
        this.refs.task_start_date_label.classList.add("active");
        this.refs.task_end_date.value = moment(date).format("DD MMM, YY");
        this.refs.task_end_date_label.classList.add("active");
        // M.toast({html: `End date must be after start date.`, displayLength: 3000, classes: 'red' });
      }
    } else if (
      !this.refs.task_start_date.value ||
      moment(date).isAfter(this.state.startDate) ||
      moment(date).isSame(this.state.startDate)
    ) {
      this.setState({ endDate: date });
      this.refs.task_end_date.value = moment(date).format("DD MMM, YY");
      this.refs.task_end_date_label.classList.add("active");
    } else {
      this.setState({ startDate: date, endDate: date });
      this.refs.task_start_date.value = moment(date).format("DD MMM, YY");
      this.refs.task_start_date_label.classList.add("active");
      this.refs.task_end_date.value = moment(date).format("DD MMM, YY");
      this.refs.task_end_date_label.classList.add("active");
      // M.toast({html: `End date must be after start date.`, displayLength: 3000, classes: 'red' });
    }
  }

  onEndFocusChange({ focused }) {
    this.setState({ endPickerFocus: focused, datePickerOverlay: focused });
  }

  isDayBlocked(day) {
    return !!(moment(day).day() === 0 || moment(day).day() === 6);
  }

  render() {
    return (
      <div className="modal-content task-modal-content">
        <div
          className={`picker-overlay ${
            this.state.datePickerOverlay
              ? "picker-overlay-on"
              : "picker-overlay-off"
          }`}
        />
        <SingleDatePicker
          verticalHeight={568}
          date={this.state.startDate} // momentPropTypes.momentObj or null
          onDateChange={this.onStartDateChange}
          focused={this.state.startPickerFocus} // PropTypes.bool
          onFocusChange={this.onStartFocusChange} // PropTypes.func.isRequired
          numberOfMonths={12}
          orientation="vertical"
          disableScroll={false}
          daySize={50}
          isOutsideRange={this.isDayBlocked}
          id="start_date_picker" // PropTypes.string.isRequired,
          verticalSpacing={0}
        />
        <SingleDatePicker
          verticalHeight={568}
          date={this.state.endDate} // momentPropTypes.momentObj or null
          onDateChange={this.onEndDateChange} // PropTypes.func.isRequired
          focused={this.state.endPickerFocus} // PropTypes.bool
          onFocusChange={this.onEndFocusChange} // PropTypes.func.isRequired
          numberOfMonths={12}
          orientation="vertical"
          daySize={50}
          isOutsideRange={this.isDayBlocked}
          id="end_date_picker" // PropTypes.string.isRequired,
          verticalSpacing={0}
        />
        <h4
          className=" project-edit"
          id="modal_user_name_master"
          style={{ marginBottom: "4px", paddingLeft: "0px" }}
        >
          {this.props.data.projectName
            ? `Update ${this.props.data.userName}'s ${
                this.state.singleDay
                  ? moment(this.props.data.date).format("MMMM Do")
                  : ""
              } ${this.props.data.projectName}`
            : `Edit ${
                this.state.singleDay
                  ? moment(this.props.data.date).format("MMMM Do")
                  : ""
              } booking for ${this.props.data.userName}`}
        </h4>
        {this.renderBookingType()}
      </div>
    );
  }
}

export default ProjectEditTask;
