import React from "react";
import styled from "styled-components";
import UserIconAccount from "../icons/user_icon_account";

const Header = ({ title }) => {
  return (
    <HeaderContainer>
      <UserIconAccount />
      <HeaderText> Manage {title}</HeaderText>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 29px 32px;
  border-bottom: 1px solid #d7d7d7;
`;

const HeaderText = styled.h1`
  margin-left: 16px;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
`;

export default Header;
