import React from "react";
import { CSSTransition } from "react-transition-group";
import ClientCard from "../../clients/client_card.jsx";
import TimeRow from "../../time_tracking/time_row";

class AlphabetRow extends React.Component {
  constructor(props) {
    super(props);
    this.renderCards = this.renderCards.bind(this);
  }

  renderCards() {
    if (this.props.type === "client") {
      return (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {this.props.clients.map((c, i) => (
            <ClientCard
              onEditClientClick={this.props.onEditClientClick}
              loadClient={this.props.loadClient}
              client={c}
              key={i}
            />
          ))}
        </div>
      );
    }

    if (this.props.type === "time_project") {
      return (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {this.props.projects.map((p, i) => (
            <CSSTransition
              in
              timeout={{
                enter: 300,
                exit: 300,
                appear: 500,
              }}
              appear
              key={i}
              classNames="card-slide"
            >
              <TimeRow
                delay={i * 0.2}
                length={this.props.length}
                cardDiv={this.props.cardDiv}
                objectName="project"
                currentUser={this.props.currentUser}
                object={p}
                index={i}
              />
            </CSSTransition>
          ))}
        </div>
      );
    }
    if (this.props.type === "time_client") {
      return (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {this.props.projects.map((p, i) => (
            <CSSTransition
              in
              timeout={{
                enter: 300,
                exit: 300,
                appear: 500,
              }}
              appear
              key={i}
              classNames="card-slide"
            >
              <TimeRow
                delay={i * 0.2}
                length={this.props.length}
                cardDiv={this.props.cardDiv}
                objectName="client"
                currentUser={this.props.currentUser}
                object={p}
                index={i}
              />
            </CSSTransition>
          ))}
        </div>
      );
    }
    if (this.props.type === "time_task") {
      return (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {this.props.projects.map((p, i) => (
            <TimeRow
              cardDiv={this.props.cardDiv}
              currentUser={this.props.currentUser}
              objectName="task"
              object={p}
              key={i}
              index={i}
            />
          ))}
        </div>
      );
    }
    if (this.props.type === "time_user") {
      return (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {this.props.projects.map((p, i) => (
            <CSSTransition
              in
              timeout={{
                enter: 300,
                exit: 300,
                appear: 500,
              }}
              appear
              key={i}
              classNames="card-slide"
            >
              <TimeRow
                cardDiv={this.props.cardDiv}
                objectName="user"
                object={p}
                key={i}
                index={i}
                currentUser={this.props.currentUser}
              />
            </CSSTransition>
          ))}
        </div>
      );
    }
  }

  render() {
    const { index } = this.props;
    return (
      <div className="alphabet-row" style={{ paddingLeft: "4px" }}>
        <h4
          role="heading"
          style={{
            display: "flex",
            alignItems: "center",
            margin: "12px 0px 12px",
            fontSize: "16px",
            fontWeight: "600",
            fontFamily: "Manrope",
          }}
        >
          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              backgroundColor: "#519acc",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "20px",
            }}
          >
            {index}
          </div>
          <div
            style={{
              height: "1px",
              width: "calc(100% - 90px)",
              backgroundColor: "lightgray",
            }}
          />
        </h4>
        <div>{this.renderCards()}</div>
      </div>
    );
  }
}

export default AlphabetRow;
