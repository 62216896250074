import React from "react";
import styled from "styled-components";
import Select from "react-select";
import { CSSTransition } from "react-transition-group";
import axios from "axios";
import Swal from "sweetalert2";
import SingleSelectStyles from "./single_select_styles";
import CancelIcon from "../icons/cancel_icon";
import PlusIcon from "../icons/plus_icon";

const MemberContainer = styled.div.attrs((props) => ({
  overflow: props.overflow,
}))`
  display: flex;
  width: 100%;

  > div:not(:last-child):after {
    content: attr(name);
    display: block;
    position: absolute;
    bottom: -25px;
    height: 20px;
    font-size: 13px;
    background-color: #519acc;
    color: white;
    transform: translateX(calc(-50% + 18px));
    opacity: 0;
    pointer-events: none;
    width: intrinsic;
    width: -moz-max-content;
    width: -webkit-max-content;
    // min-width: 100px;
    padding: 0px 10px;
    border-radius: 4px;
    transition: opacity 0.2s;
  }

  > div:not(:last-child):hover::after {
    opacity: 1;
  }

  ${(props) =>
    props.overflow
      ? ">div:first-child:not(:last-child):after{left: 100%;}"
      : ""}

  >div:hover {
    z-index: 2;
  }
`;

const UserCircle = styled.div`
  height: ${(props) => (props.detailView ? "32px" : "30px")};
  width: ${(props) => (props.detailView ? "32px" : "30px")};
  min-width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: #519acc;

  > .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #519acc;
    opacity: 0;
    background-color: #00000057;
    transition: opacity 0.3s;
    pointer-events: none;
    z-index: 1;
  }

  :hover {
    z-index: 2;
  }

  :hover:not(.owner) > .overlay {
    opacity: 1;
  }

  > img {
    height: 100%;
    width: 100%;
    pointer-events: none;
  }

  > span {
    color: white;
    pointer-events: none;
  }
`;

const AddCircle = styled.div`
  height: ${(props) => (props.detailView ? "32px" : "30px")};
  width: ${(props) => (props.detailView ? "32px" : "30px")};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #519acc;
  border: solid 1px transparent;
  background-color: #fafafa;
  transition: border 0.3s;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 1px 2px #c5c5c5;
  z-index: 1;

  > svg {
    pointer-events: none;
    background: none;
  }

  :hover {
    border: solid 1px #519acc;
  }

  > .circle-select {
    position: absolute;
    height: 0px;
    left: 0;
    bottom: 0;
  }

  > .circle-select > div:first-of-type {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
  }

  .react-select__menu {
    z-index: 11 !important;
  }
`;

class TeamAdd extends React.Component {
  constructor(props) {
    super(props);
    const projectLeadIds = this.props.projectLeads.map((u) => u.id);
    if (this.props.projectLeadOwner.id) {
      projectLeadIds.push(this.props.projectLeadOwner.id);
    }
    if (this.props.projectEstimateOwner.id) {
      projectLeadIds.push(this.props.projectEstimateOwner.id);
    }
    this.state = {
      projectLeads: this.props.projectLeads
        .map((u) => ({ value: u.id, label: u.name, avatar_url: u.avatar_url }))
        .sort((a, b) => a.label - b.label),
      projectEstimateOwner: this.props.projectEstimateOwner.id
        ? {
            value: this.props.projectEstimateOwner.id,
            label: this.props.projectEstimateOwner.name,
            avatar_url: this.props.projectEstimateOwner.avatar_url,
          }
        : null,
      projectLeadOwner: this.props.projectLeadOwner.id
        ? {
            value: this.props.projectLeadOwner.id,
            label: this.props.projectLeadOwner.name,
            avatar_url: this.props.projectLeadOwner.avatar_url,
          }
        : null,
      leadOptions: this.props.leadOptions.filter(
        (u) => projectLeadIds.indexOf(u.value) === -1 && u.role_id !== 1
      ),
      menuOpen: false,
      loading: false,
    };
    this.addCircleRef = React.createRef();
    this.closeMenu = (e) => {
      if (e.target.id !== "member-add-circle") {
        this.addCircleClick();
      }
    };
  }

  addCircleClick = () => {
    const { menuOpen } = this.state;
    if (menuOpen) {
      window.removeEventListener("click", this.closeMenu);
      this.setState({ menuOpen: false });
    } else {
      this.setState({ menuOpen: true }, () => {
        window.addEventListener("click", this.closeMenu);
      });
    }
  };

  handleRemoveLead = (e) => {
    if (!this.state.loading) {
      const id = parseInt(e.target.id);
      Swal.fire({
        title: `Remove Lead`,
        text: `Are you sure that you want to remove this lead from the ${
          this.props.prospect ? "prospective client" : "project"
        }?`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          const { projectLeads } = this.state;
          let { leadOptions } = this.state;
          const index = projectLeads.findIndex((u) => u.value === id);
          const user = projectLeads.splice(index, 1);
          leadOptions = [...leadOptions, ...user];
          leadOptions.sort((a, b) =>
            a.label.toLowerCase() < b.label.toLowerCase()
              ? -1
              : a.label.toLowerCase() > b.label.toLowerCase()
              ? 1
              : 0
          );
          const project = {};
          project.lead_ids = projectLeads.map((u) => u.value);
          axios
            .patch(`/remove_project_lead/${this.props.projectId}.json`, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              project,
            })
            .then((res) => {
              if (this.props.prospect) {
                this.props.loadProspects(true);
              } else if (this.props.projectHandler) {
                this.props.projectHandler(
                  this.props.stage,
                  this.props.stage,
                  res.data
                );
              }
              this.setState({ projectLeads, leadOptions, loading: false });
              M.toast({
                html: "Lead Removed",
                displayLength: 3000,
                classes: "green",
              });
            })
            .catch((err) => {
              this.setState({ loading: false });
              console.error(err);
            });
        }
      });
    }
  };

  handleAddLead = (u) => {
    if (!this.state.loading) {
      this.setState({ loading: true });
      const id = u.value;
      let { projectLeads } = this.state;
      const { leadOptions } = this.state;
      const index = leadOptions.findIndex((u) => u.value === parseInt(id));
      const user = leadOptions.splice(index, 1);
      projectLeads = [...projectLeads, ...user];
      projectLeads.sort((a, b) =>
        a.label.toLowerCase() < b.label.toLowerCase()
          ? -1
          : a.label.toLowerCase() > b.label.toLowerCase()
          ? 1
          : 0
      );
      const project = {};
      project.lead_ids = projectLeads.map((u) => u.value);
      axios
        .patch(`/projects/${this.props.projectId}.json`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          project,
        })
        .then((res) => {
          if (this.props.prospect) {
            this.props.loadProspects(true);
          } else if (this.props.projectHandler) {
            this.props.projectHandler(
              this.props.stage,
              this.props.stage,
              res.data
            );
          }
          this.setState({ projectLeads, leadOptions, loading: false });
          M.toast({
            html: "Lead Added",
            displayLength: 3000,
            classes: "green",
          });
        })
        .catch((err) => {
          console.error(err);
          this.setState({ loading: false });
        });
    }
  };

  render() {
    const estimateOwner = this.state.projectEstimateOwner;
    const leadOwner = this.state.projectLeadOwner;
    let { projectLeads } = this.state;
    if (estimateOwner) {
      projectLeads = projectLeads.filter(
        (l) => l.value !== estimateOwner.value
      );
    }
    const count =
      projectLeads.length +
      (this.state.projectEstimateOwner ? 1 : 0) +
      (this.state.projectLeadOwner ? 1 : 0);
    return (
      <div>
        <p
          className="manrope"
          style={{ margin: "0 0 8px 0", fontSize: "13px", fontWeight: 600 }}
        >
          Team:
        </p>
        <MemberContainer overflow={this.props.overflow}>
          {estimateOwner && (
            <div
              key={estimateOwner.value}
              name={
                estimateOwner.label +
                (leadOwner && leadOwner.value === estimateOwner.value
                  ? " - Estimate Owner and Lead Manager"
                  : " - Estimate Owner")
              }
              style={{
                position: "relative",
                height: "30px",
                borderRadius: "50%",
                marginRight: count > 16 ? "-12px" : count > 9 ? "-8px" : "4px",
              }}
            >
              <UserCircle
                detailView={this.props.detailView}
                name={estimateOwner.label}
                className="owner"
                id={estimateOwner.value}
              >
                <div className="overlay">
                  <CancelIcon width={12} />
                  {/* <i className="material-icons">remove</i>  */}
                </div>
                {!estimateOwner.avatar_url.includes("missing.png") ? (
                  <img src={estimateOwner.avatar_url} />
                ) : (
                  <span>
                    {estimateOwner.label
                      .split(" ")
                      .map((word, i) =>
                        i < 2 ? word.charAt(0).toUpperCase() : ""
                      )}
                  </span>
                )}
              </UserCircle>
            </div>
          )}
          {leadOwner &&
            (!estimateOwner || estimateOwner.value !== leadOwner.value) && (
              <div
                key={leadOwner.value}
                name={
                  leadOwner.label +
                  (this.props.prospect ? "" : " - Lead Manager")
                }
                style={{
                  position: "relative",
                  height: "30px",
                  borderRadius: "50%",
                  marginRight:
                    count > 16 ? "-12px" : count > 9 ? "-8px" : "4px",
                }}
              >
                <UserCircle
                  detailView={this.props.detailView}
                  name={leadOwner.label}
                  className="owner"
                  id={leadOwner.value}
                >
                  <div className="overlay">
                    <CancelIcon width={12} />
                    {/* <i className="material-icons">remove</i>  */}
                  </div>
                  {!leadOwner.avatar_url.includes("missing.png") ? (
                    <img src={leadOwner.avatar_url} />
                  ) : (
                    <span>
                      {leadOwner.label
                        .split(" ")
                        .map((word, i) =>
                          i < 2 ? word.charAt(0).toUpperCase() : ""
                        )}
                    </span>
                  )}
                </UserCircle>
              </div>
            )}
          {projectLeads.map((u) => {
            return (
              <div
                key={u.value}
                name={u.label + (this.props.prospect ? "" : " - Lead")}
                style={{
                  position: "relative",
                  height: "30px",
                  borderRadius: "50%",
                  marginRight:
                    count > 16 ? "-12px" : count > 9 ? "-8px" : "4px",
                }}
              >
                <UserCircle
                  detailView={this.props.detailView}
                  name={u.label}
                  count={projectLeads.length}
                  onClick={this.handleRemoveLead}
                  role="button"
                  id={u.value}
                >
                  <div className="overlay">
                    <CancelIcon width={12} />
                  </div>
                  {!u.avatar_url.includes("missing.png") ? (
                    <img src={u.avatar_url} />
                  ) : (
                    <span>
                      {u.label
                        .split(" ")
                        .map((word, i) =>
                          i < 2 ? word.charAt(0).toUpperCase() : ""
                        )}
                    </span>
                  )}
                </UserCircle>
              </div>
            );
          })}
          <AddCircle
            detailView={this.props.detailView}
            role="button"
            id="member-add-circle"
            onClick={this.addCircleClick}
          >
            <PlusIcon width={15} />
            <Select
              ref={this.addCircleRef}
              classNamePrefix="react-select"
              menuIsOpen={this.state.menuOpen}
              options={this.state.leadOptions}
              onChange={this.handleAddLead}
              placeholder="Select a source"
              value={this.state.leadSource}
              className="circle-select"
              styles={{
                ...SingleSelectStyles,
                ...{
                  container: (styles) => ({
                    ...styles,
                    width: "175px",
                    zIndex: "11",
                  }),
                },
              }}
            />
            <CSSTransition
              unmountOnExit
              in={this.state.loading}
              timeout={300}
              classNames="node-fade"
            >
              <div
                className="invoice-search-input-spinner"
                style={{ right: "4px", backgroundColor: "#ffffff" }}
              />
            </CSSTransition>
          </AddCircle>
        </MemberContainer>
      </div>
    );
  }
}

export default TeamAdd;
