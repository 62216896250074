import React from "react";

class NonProjectTaskRow extends React.Component {
  constructor(props) {
    super(props);

    this.renderStatus = this.renderStatus.bind(this);
    this.renderCard = this.renderCard.bind(this);
  }

  renderStatus() {
    const status = this.props.taskStatus;
    if (
      this.props.taskName === "Unavailability" ||
      this.props.taskName === "Vacation" ||
      this.props.taskName === "Unavailable" ||
      this.props.taskName === "PT Limit" ||
      this.props.taskName === "Sick Time" ||
      this.props.taskName === "Reoccurring: Unavailable"
    ) {
      return <div />;
    }
    if (status === null) {
      return <div className="status-col nil" />;
    }
    if (status === true) {
      return <div className="status-col true" />;
    }
    if (status === false) {
      return <div className="status-col false" />;
    }
    return <div />;
  }

  renderCard() {
    if (
      this.props.taskName === "Unavailability" ||
      this.props.taskName === "Vacation" ||
      this.props.taskName === "Unavailable" ||
      this.props.taskName === "PT Limit" ||
      this.props.taskName === "Sick Time" ||
      this.props.taskName === "Reoccurring: Unavailable"
    ) {
      return (
        <div className="row card day-card grey darken-3 white-text valign-wrapper">
          <div
            className={`col ${
              this.props.twoWeeks
                ? "s11 truncate card-proj-name-small"
                : "s9 m7 l9 truncate card-proj-name"
            }`}
          >
            {this.props.taskName}
          </div>
          <div
            className={`col s1 card-proj-name${
              this.props.twoWeeks ? "-small" : ""
            }`}
          >
            {this.props.taskHours}
          </div>
          {this.props.twoWeeks ? (
            ""
          ) : (
            <div className="col s2">{this.renderStatus()}</div>
          )}
        </div>
      );
    }
    return (
      <div className="row card day-card gray valign-wrapper">
        <div
          className={`col ${
            this.props.twoWeeks
              ? "s11 truncate card-proj-name-small"
              : "s9 m7 l9 truncate card-proj-name"
          }`}
        >
          {this.props.taskName}
        </div>
        {!this.props.projectConfirmation ? (
          <div
            className={`col s1 card-proj-name${
              this.props.twoWeeks ? "-small" : ""
            }`}
            style={{
              padding: `${this.props.twoWeeks ? "0 1rem" : "0 0.75rem"}`,
            }}
          >
            <i className="inline-icon material-icons white-text">
              help_outline
            </i>
          </div>
        ) : (
          ""
        )}
        <div
          className={`col s1 card-proj-name${
            this.props.twoWeeks ? "-small" : ""
          }`}
        >
          {this.props.taskHours}
        </div>
        {this.props.twoWeeks ? (
          ""
        ) : (
          <div className="col s2">{this.renderStatus()}</div>
        )}
      </div>
    );
  }

  render() {
    return this.renderCard();
  }
}

export default NonProjectTaskRow;
