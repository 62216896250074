import React from "react";
import Room from "./map/room";
import CraterIcon from "../icons/meeting_icons/CraterIcon";
import BlueRoomIcon from "components/icons/meeting_icons/BlueRoomIcon";
import GreenRoomIcon from "components/icons/meeting_icons/GreenRoomIcon";
import GreyRoomIcon from "components/icons/meeting_icons/GreyRoomIcon";
import OrangeRoomIcon from "components/icons/meeting_icons/OrangeRoomIcon";
import RedRoomIcon from "components/icons/meeting_icons/RedRoomIcon";
import YellowRoomIcon from "components/icons/meeting_icons/YellowRoomIcon";
import SciFiRoomIcon from "components/icons/meeting_icons/SciFiRoomIcon";

const Booking = ({
  bookings,
  bookingHandler,
  currentUser,
  currentDay,
  deleteHandler,
  editHandler,
  rooms,
  setModal,
  users,
}) => {
  return (
    <div className="mapTop">
      <div style={{ marginRight: "4px", flexGrow: "1" }}>
        <Room
          icon={<CraterIcon />}
          rooms={rooms[0]}
          bookings={bookings[0]}
          currentUser={currentUser}
          currentDay={currentDay}
          deleteHandler={deleteHandler}
          users={users}
          editHandler={editHandler}
          setModal={setModal}
          bookingHandler={bookingHandler}
          roomBackgroundColor={"#6F79B8"}
          roomClass={"crater-room-card"}
        />
      </div>

      <div style={{ marginRight: "4px", flexGrow: "1" }}>
        <Room
          icon={<GreenRoomIcon />}
          rooms={rooms[1]}
          bookings={bookings[1]}
          currentUser={currentUser}
          currentDay={currentDay}
          deleteHandler={deleteHandler}
          users={users}
          editHandler={editHandler}
          setModal={setModal}
          bookingHandler={bookingHandler}
          roomBackgroundColor={"#20835D"}
          roomClass={"green-room-card"}
        />
      </div>
      <div style={{ marginRight: "4px", flexGrow: "1" }}>
        <Room
          icon={<GreyRoomIcon />}
          rooms={rooms[2]}
          bookings={bookings[2]}
          currentUser={currentUser}
          currentDay={currentDay}
          deleteHandler={deleteHandler}
          users={users}
          editHandler={editHandler}
          setModal={setModal}
          bookingHandler={bookingHandler}
          roomBackgroundColor={"#444"}
          roomClass={"grey-room-card"}
        />
      </div>
      <div style={{ marginRight: "4px", flexGrow: "1" }}>
        <Room
          icon={<OrangeRoomIcon />}
          rooms={rooms[3]}
          bookings={bookings[3]}
          currentUser={currentUser}
          currentDay={currentDay}
          deleteHandler={deleteHandler}
          users={users}
          editHandler={editHandler}
          setModal={setModal}
          bookingHandler={bookingHandler}
          roomBackgroundColor={"#D76F23"}
          roomClass={"orange-room-card"}
        />
      </div>

      <div style={{ marginRight: "4px", flexGrow: "1" }}>
        <Room
          icon={<RedRoomIcon />}
          rooms={rooms[4]}
          bookings={bookings[4]}
          currentUser={currentUser}
          currentDay={currentDay}
          deleteHandler={deleteHandler}
          users={users}
          editHandler={editHandler}
          setModal={setModal}
          bookingHandler={bookingHandler}
          roomBackgroundColor={"#B82A23"}
          roomClass={"red-room-card"}
        />
      </div>
      <div style={{ marginRight: "4px", flexGrow: "1" }}>
        <Room
          icon={<YellowRoomIcon />}
          rooms={rooms[5]}
          bookings={bookings[5]}
          currentUser={currentUser}
          currentDay={currentDay}
          deleteHandler={deleteHandler}
          users={users}
          editHandler={editHandler}
          setModal={setModal}
          bookingHandler={bookingHandler}
          roomBackgroundColor={"#CB9326"}
          roomClass={"yellow-room-card"}
        />
      </div>
      <div style={{ marginRight: "4px", flexGrow: "1" }}>
        <Room
          icon={<BlueRoomIcon />}
          rooms={rooms[6]}
          bookings={bookings[6]}
          currentUser={currentUser}
          currentDay={currentDay}
          deleteHandler={deleteHandler}
          users={users}
          editHandler={editHandler}
          setModal={setModal}
          bookingHandler={bookingHandler}
          roomBackgroundColor={"#519ACC"}
          roomClass={"blue-room-card"}
        />
      </div>
      <div style={{ marginRight: "0px", flexGrow: "1" }}>
        <Room
          icon={<SciFiRoomIcon />}
          rooms={rooms[7]}
          bookings={bookings[7]}
          currentUser={currentUser}
          currentDay={currentDay}
          deleteHandler={deleteHandler}
          users={users}
          editHandler={editHandler}
          setModal={setModal}
          bookingHandler={bookingHandler}
          roomBackgroundColor={"#F55454"}
          roomClass={"sci-fi-room-card"}
        />
      </div>
    </div>
  );
};

export default Booking;
