import React from "react";
import ReactModal from "../modals/react_modal";
import { CSSTransition } from "react-transition-group";
import TimeContainerSubnav from "./time_container_subnav";
import TimeRow from "./time_row";
import ClockIcon from "../icons/clock_icon";
import moment from "moment";
import { List, AutoSizer } from "react-virtualized";
import styled from "styled-components";
import TimeTrackingDateNav from "./time_tracking_date_nav";
import TimeEntryCard from "./time_entry_card";
import { EntryCardDiv } from "./time_entry_list";
import RoundDropdown from "../projects/round_dropdown";
import { setAlphaGroups } from "../utils";

const TimeRowDiv = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: white;
  border: none;
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 2px 3px 2px #cccccc;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-left: 3px;
  position: relative;
  & .div-btn-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    background: transparent;
    border: none;
    padding: 0;
    top: 0px;
    left: 0px;
    z-index: 3;
  }
`;

const SortBar = styled.div`
  width: ${(props) => (props.objects.length >= 6 ? "98%" : "99%")};
  border-bottom: solid 1px #b9b9b9;
  height: 40px;
  margin-bottom: 10px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-right: 10px;
  button {
    font-family: "Arial" !important;
    font-size: 12px !important;
    color: #6e6e6e !important;
    font-weight: 500;
    transition: border-color 0.3s, background-color 0.3s;
    border: none;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: transparent;
    padding: 0 15px;
    display: flex;
    align-items: center;
    background-color: white;
    cursor: pointer;
  }
  button:hover {
    background-color: transparent !important;
  }
  button.active {
    border-bottom-color: #519acc;
    background-color: white;
  }
  > div:nth-child(1) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    button {
      text-align: left;
      height: 100%;
      width: 100px;
    }
    > div:nth-child(1) {
      margin-right: auto;
      width: ${(props) =>
        props.hideBar
          ? props.objectName === "project"
            ? props.roleId === 5
              ? "15%"
              : "24%"
            : "81%"
          : props.objectName === "task" && props.activeObjectName === "project"
          ? "27%"
          : "37%"};
      position: relative;
      left: ${(props) => (props.nested && props.task ? "68px" : "0")};
    }
    > div:nth-child(2) {
      margin-left: -5px;
      width: 100px;
      margin-right: auto;
      display: flex;
      align-items: center;
    }
  }
  > div:nth-child(2) {
    display: flex;
    flex: 1;
    width: 41%;
    justify-content: space-between;
    button {
      width: 100px;
    }
    button:nth-child(1) {
      justify-content: ${(props) =>
        props.hideBar ? "flex-end" : "flex-start"};
      padding-left: 10px;
      padding-right: ${(props) => (props.hideBar ? "22px" : "10px")};
    }
    button:nth-child(1) > svg {
      order: ${(props) => (props.hideBar ? "-1" : "1")};
      margin-right: ${(props) => (props.hideBar ? "5px" : "0")};
      margin-left: ${(props) => (props.hideBar ? "0" : "5px")};
    }
    button:nth-child(3) {
      justify-content: flex-end;
      padding-right: 22px;
    }
    button:nth-child(3) > svg {
      margin-right: 5px;
      order: -1;
    }
    div {
      min-width: ${(props) => (props.hideBar === true ? "0" : "170px")};
    }
  }
`;

class TimeObjectsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      currentModal: "",
      modalData: {},
      objects: this.props.objects,
      activeObject: this.props.activeObject,
      alphaGroups:
        this.props.objectName === "user" && this.props.show
          ? this.props.objects
          : setAlphaGroups(this.props.objects),
      selectedDateRangeOption: {},
      startDate: Date.now(),
      endDate: Date.now(),
      weekRange: [],
      projectHours: 666,
      selectedDay: "12/12/99",
      direction: "desc",
      sortColumn: "dollars",
      activeIndex: -1,
      timeEntries: [],
    };

    this.renderProjects = this.renderProjects.bind(this);
    this.getRowHeight = this.getRowHeight.bind(this);
    this.sortObjects = this.sortObjects.bind(this);
    this.setSort = this.setSort.bind(this);
    this.today = Date.now();
    this.loading = false;
    this.checkHeight = this.checkHeight.bind(this);
  }

  setModal = (bool, page, data) => {
    this.setState({ modalOpen: bool, currentModal: page, modalData: data });
  };

  letterClick = (e) => {
    var index = parseInt(e.target.dataset.index) - 1;
    this.setState({ activeIndex: index });
  };

  componentDidMount() {
    if (
      this.props.currentUser.role_id === 5 &&
      Array.isArray(this.props.objects)
    ) {
      this.sortObjects("dollars", this.props.objects, "asc");
    }
    window.addEventListener("resize", this.checkHeight);
    this.checkHeight();
  }

  checkHeight() {
    var elem = document.getElementById("task-objects-container");
    if (elem) {
      if (elem.scrollHeight === elem.clientHeight) {
        elem.style.paddingRight = "10px";
      } else {
        elem.style.paddingRight = "0px";
      }
    }
  }

  compareValues(key) {
    return function innerSort(a, b) {
      // if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      //   // property doesn't exist on either object
      //   return 0;
      // }
      if (key === "non_billable_hours") {
        var varA =
          parseFloat(
            a.billable_hours / (a.non_billable_hours + a.billable_hours)
          ).toFixed(2) * 100;
        var varB =
          parseFloat(
            b.billable_hours / (b.non_billable_hours + b.billable_hours)
          ).toFixed(2) * 100;
      } else if (key === "dollars") {
        var varA = Number(a.dollars.replace(/[^0-9.-]+/g, ""));
        var varB = Number(b.dollars.replace(/[^0-9.-]+/g, ""));
      } else {
        var varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
        var varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];
      }

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return comparison;
    };
  }

  sortObjects(key, objects, direction) {
    if (Array.isArray(objects)) {
      var sortedObjects = objects.sort(this.compareValues(key));
    } else {
      var arrayObjects = Object.entries(objects);
      var sortedObjects = arrayObjects.sort(this.compareValues(key));
    }
    if (direction === "asc") {
      sortedObjects = sortedObjects.reverse();
    }
    this.setState(
      { objects: sortedObjects, direction: direction, sortColumn: key },
      () => {
        if (this.props.dynamicListRef && this.props.dynamicListRef.current) {
          this.props.dynamicListRef.current.recomputeRowHeights();
        }
      }
    );
  }

  setSort(e) {
    var column = e.target.value;
    var direction = "asc";
    if (this.state.direction === "asc") {
      direction = "desc";
    } else {
      direction = "asc";
    }
    this.loading = true;
    this.sortObjects(column, this.props.objects, direction);
    this.loading = false;
  }

  renderProjects(props) {
    var indexNode = [];
    if (this.props.objectName === "user" && this.props.show) {
      Object.entries(this.props.objects).forEach((entry, i) => {
        var total = 0;
        indexNode.push({
          id: entry[0],
          node: (
            <div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    width: "150px",
                    fontSize: "14px",
                    height: "25px",
                    lineHeight: "25px",
                    backgroundColor: "#519acc",
                    textAlign: "center",
                    color: "white",
                    borderRadius: "8px",
                  }}
                >
                  {moment(entry[0]).format("dddd - MMM DD")}
                </p>
                <div
                  style={{
                    height: "1px",
                    width: "calc(100% - 172px)",
                    marginLeft: "10px",
                    backgroundColor: "#cccccc",
                  }}
                />
              </div>
              {entry[1].map((e, i) => {
                total += e.hours;
                return (
                  <TimeEntryCard
                    editTimeEntry={this.props.editTimeEntry}
                    deleteEntry={this.props.deleteEntry}
                    EntryCardDiv={EntryCardDiv}
                    reports={true}
                    currentUser={this.props.currentUser}
                    index={i}
                    timeEntry={e}
                    key={e.id}
                  />
                );
              })}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "130px",
                  margin: "18px 119px 0px auto",
                }}
              >
                <span style={{ fontSize: "14px", color: "#bfbfbf" }}>
                  Total:{" "}
                </span>
                <ClockIcon
                  height={20}
                  width={20}
                  color="#519acc"
                  svgStyles={{ margin: "0px 5px" }}
                />
                <span
                  style={{
                    fontSize: "21px",
                    fontWeight: "600",
                    fontFamily: "Manrope",
                  }}
                >
                  {total.toFixed(2)}
                </span>
              </div>
            </div>
          ),
        });
      });
      return (
        <div key={props.index} style={props.style}>
          {indexNode[props.index].node}
        </div>
      );
    } else {
      var type = `time_${this.props.objectName}`;
      var p = this.props.objects[props.index];
      var i = props.index;
      if (type === "time_project") {
        return (
          <div key={i} style={{ ...props.style, padding: "0 1px" }}>
            <TimeRow
              delay={i * 0.2}
              length={this.props.length}
              cardDiv={TimeRowDiv}
              objectName="project"
              hideBar={true}
              currentUser={this.props.currentUser}
              object={p}
              index={i}
              style={props.style}
            />
          </div>
        );
      } else if (type === "time_client") {
        return (
          <div key={i} style={{ ...props.style, padding: "0 1px" }}>
            <TimeRow
              delay={i * 0.2}
              length={this.props.length}
              cardDiv={TimeRowDiv}
              hideBar={true}
              objectName="client"
              currentUser={this.props.currentUser}
              object={p}
              index={i}
            />
          </div>
        );
      } else if (type === "time_task") {
        return (
          <div key={i} style={{ ...props.style, padding: "0 1px" }}>
            <TimeRow
              cardDiv={TimeRowDiv}
              currentUser={this.props.currentUser}
              objectName="task"
              object={p}
              key={i}
              index={i}
            />
          </div>
        );
      } else if (type === "time_user") {
        return (
          <div key={i} style={{ ...props.style, padding: "0 1px" }}>
            <TimeRow
              cardDiv={TimeRowDiv}
              objectName="user"
              object={p}
              key={i}
              index={i}
              currentUser={this.props.currentUser}
            />
          </div>
        );
      }
    }
  }

  getRowHeight({ index }) {
    if (this.props.objectName === "user" && this.props.show) {
      var key = Object.keys(this.props.objects)[index];
      var row = this.props.objects[key];
      var rowHeight = 95;
      return row.length * rowHeight + 86;
    } else {
      // height of 55 + margin bottom of 10
      return 65;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.objectName === "user" &&
      this.props.show &&
      prevProps.weekRange !== this.props.weekRange
    ) {
      this.setState({ alphaGroups: this.props.objects }, () => {
        if (this.props.dynamicListRef && this.props.dynamicListRef.current) {
          this.props.dynamicListRef.current.recomputeRowHeights();
        }
      });
    } else if (prevProps.objects.length != this.props.objects.length) {
      var alphaGroups = setAlphaGroups(this.props.objects);
      this.setState({ alphaGroups: alphaGroups }, () => {
        if (this.props.dynamicListRef && this.props.dynamicListRef.current) {
          this.props.dynamicListRef.current.recomputeRowHeights();
        }
        if (
          this.props.currentUser.role_id === 5 &&
          Array.isArray(this.props.objects)
        ) {
          this.sortObjects("dollars", this.props.objects, "asc");
        }
      });
      // catchs when lengths are equal but hours differ
    } else if (
      prevProps.objects.length &&
      this.props.objects.length &&
      prevProps.objects.length === this.props.objects.length
    ) {
      if (
        prevProps.objects.reduce(
          (total, obj) => obj.billable_hours + total,
          0
        ) !=
        this.props.objects.reduce((total, obj) => obj.billable_hours + total, 0)
      ) {
        var alphaGroups = setAlphaGroups(this.props.objects);
        this.setState({ alphaGroups: alphaGroups }, () => {
          if (this.props.dynamicListRef && this.props.dynamicListRef.current) {
            this.props.dynamicListRef.current.recomputeRowHeights();
          }
        });
      }
    }
  }

  render() {
    var userShow = this.props.objectName === "user" && this.props.show;
    return (
      <div id="time-tracking-container" style={{ backgroundColor: "#f2f2f2" }}>
        <ReactModal
          isShowing={this.state.modalOpen}
          page={this.state.currentModal}
          data={this.state.modalData}
          modalAction={this.setModal}
        />
        <div id="time-notes-floaty" />
        <div id="projects-container" style={{ padding: 0, maxWidth: "1440px" }}>
          <section
            style={{
              width: "100%",
              padding: "0px 0px 20px",
            }}
            aria-label="Client Directory"
          >
            <TimeTrackingDateNav
              // selectedDay={this.props.selectedDay}
              // setSelectedDay={this.setSelectedDay}
              startDate={this.props.weekRange[0]}
              endDate={this.props.weekRange[this.props.weekRange.length - 1]}
              selectedDateRangeOption={this.props.selectedDateRangeOption}
              setSelectedDateRangeOption={this.props.setSelectedDateRangeOption}
              todayInRange={this.state.selectedDay === this.today}
              updateRangeData={this.props.updateRangeData}
              // type="buttons"
              // page="time-entry"
            />
            <TimeContainerSubnav
              cameFromClients={this.props.cameFromClients}
              activeObject={this.props.activeObject}
              activeObjectName={this.props.activeObjectName}
              objectName={this.props.objectName}
              activeObjectHandler={this.setActiveObjext}
              letterClickHandler={this.letterClick}
              letterObjects={this.props.objects}
              usedLetters={Object.keys(this.state.alphaGroups)}
              show={false}
              nested={this.props.nested}
              nestedObject={this.props.nestedObject}
              hideLetters={true}
            />
            <section
              style={{
                boxShadow: "none",
                height: userShow ? "calc(100% - 275px)" : "calc(100% - 142px)",
              }}
            >
              {!userShow && (
                <SortBar
                  aria-label="sorting options"
                  roleId={this.props.currentUser.role_id}
                  objectName={this.props.objectName}
                  activeObjectName={this.props.activeObjectName}
                  nested={this.props.nested}
                  objects={this.props.objects}
                  task={this.props.objectName === "task"}
                  hideBar={
                    this.props.objectName != "task" &&
                    this.props.objectName != "user"
                  }
                >
                  <div>
                    <div>
                      <button
                        className={
                          this.state.sortColumn === "name" ? "active" : ""
                        }
                        value="name"
                        onClick={this.setSort}
                        type="button"
                        aria-pressed={this.state.sortColumn === "name"}
                        aria-label={`Name${
                          this.state.sortColumn === "name"
                            ? this.state.direction === "asc"
                              ? ", Ascending"
                              : ", Descending"
                            : ""
                        }`}
                      >
                        Name
                        {this.state.sortColumn === "name" && (
                          <RoundDropdown
                            svgStyles={{
                              pointerEvents: "none",
                              marginLeft: "5px",
                            }}
                            rotate={
                              this.state.direction === "asc"
                                ? "rotate(0deg)"
                                : "rotate(180deg)"
                            }
                          />
                        )}
                      </button>
                    </div>
                    {this.props.objectName === "project" && (
                      <div>
                        <button
                          className={
                            this.state.sortColumn === "client_name"
                              ? "active"
                              : ""
                          }
                          value="client_name"
                          onClick={this.setSort}
                          type="button"
                          style={{
                            visibility:
                              this.props.activeObjectName === "client"
                                ? "hidden"
                                : "visible",
                          }}
                          aria-pressed={this.state.sortColumn === "client_name"}
                          aria-label={`Client${
                            this.state.sortColumn === "client_name"
                              ? this.state.direction === "asc"
                                ? ", Ascending"
                                : ", Descending"
                              : ""
                          }`}
                        >
                          Clients
                          {this.state.sortColumn === "client_name" && (
                            <RoundDropdown
                              svgStyles={{
                                pointerEvents: "none",
                                marginLeft: "5px",
                              }}
                              rotate={
                                this.state.direction === "asc"
                                  ? "rotate(0deg)"
                                  : "rotate(180deg)"
                              }
                            />
                          )}
                        </button>
                      </div>
                    )}
                    <div
                      style={{
                        width: `${
                          this.props.currentUser.role_id === 5 &&
                          this.props.objectName === "project"
                            ? "110px"
                            : ""
                        }`,
                      }}
                    >
                      {this.props.currentUser.role_id === 5 ? (
                        <button
                          className={
                            this.state.sortColumn === "dollars" ? "active" : ""
                          }
                          value="dollars"
                          onClick={this.setSort}
                          type="button"
                          aria-pressed={this.state.sortColumn === "dollars"}
                          aria-label={`Amount${
                            this.state.sortColumn === "dollars"
                              ? this.state.direction === "asc"
                                ? ", Ascending"
                                : ", Descending"
                              : ""
                          }`}
                        >
                          Amount
                          {this.state.sortColumn === "dollars" && (
                            <RoundDropdown
                              svgStyles={{
                                pointerEvents: "none",
                                marginLeft: "5px",
                              }}
                              rotate={
                                this.state.direction === "asc"
                                  ? "rotate(0deg)"
                                  : "rotate(180deg)"
                              }
                            />
                          )}
                        </button>
                      ) : (
                        <button style={{ visibility: "hidden" }}></button>
                      )}
                    </div>
                  </div>
                  <div>
                    <button
                      className={
                        this.state.sortColumn === "billable_hours"
                          ? "active"
                          : ""
                      }
                      value="billable_hours"
                      style={{
                        position: "relative",
                        left: `${
                          this.props.objectName != "task" &&
                          this.props.objectName != "user"
                            ? "0px"
                            : 0
                        }`,
                      }}
                      onClick={this.setSort}
                      type="button"
                      aria-pressed={this.state.sortColumn === "billable_hours"}
                      aria-label={`Hours${
                        this.state.sortColumn === "billable_hours"
                          ? this.state.direction === "asc"
                            ? ", Ascending"
                            : ", Descending"
                          : ""
                      }`}
                    >
                      Hours
                      {this.state.sortColumn === "billable_hours" && (
                        <RoundDropdown
                          svgStyles={{
                            pointerEvents: "none",
                            marginLeft: "5px",
                          }}
                          rotate={
                            this.state.direction === "asc"
                              ? "rotate(0deg)"
                              : "rotate(180deg)"
                          }
                        />
                      )}
                    </button>
                    <div></div>
                    <button
                      className={
                        this.state.sortColumn === "non_billable_hours"
                          ? "active"
                          : ""
                      }
                      aria-hidden={true}
                      value="non_billable_hours"
                      onClick={this.setSort}
                      type="button"
                      style={{
                        display:
                          this.props.objectName === "task" ||
                          this.props.objectName === "user"
                            ? "visible"
                            : "none",
                      }}
                    >
                      {this.props.objectName === "task" ||
                      this.props.objectName === "user"
                        ? "Billable"
                        : "Budget"}
                      {this.state.sortColumn === "non_billable_hours" && (
                        <RoundDropdown
                          svgStyles={{
                            pointerEvents: "none",
                            marginLeft: "5px",
                          }}
                          rotate={
                            this.state.direction === "asc"
                              ? "rotate(0deg)"
                              : "rotate(180deg)"
                          }
                        />
                      )}
                    </button>
                  </div>
                </SortBar>
              )}
              {this.props.loading && (
                <CSSTransition
                  timeout={{
                    enter: 300,
                    exit: 300,
                    appear: 800,
                  }}
                  in={true}
                  appear={true}
                  classNames={"activity-fade"}
                >
                  <div className="green-loader"></div>
                </CSSTransition>
              )}
              {this.loading && (
                <CSSTransition
                  timeout={{
                    enter: 300,
                    exit: 300,
                    appear: 800,
                  }}
                  in={true}
                  appear={true}
                  classNames={"activity-fade"}
                >
                  <div className="green-loader"></div>
                </CSSTransition>
              )}
              {this.props.objectName === "task" ? (
                <AutoSizer>
                  {({ height, width }) => (
                    <div
                      id="task-objects-container"
                      className="task-objects-container"
                      style={{
                        padding: "0 1px",
                        // maxHeight: "calc(100% - 50px)",
                        maxHeight: this.props.nested
                          ? height - 145
                          : height - 50,
                        width: width,
                        overflow: "hidden auto",
                      }}
                    >
                      {this.props.objects.map((object, index) => (
                        <CSSTransition
                          key={object.name}
                          timeout={{
                            enter: 300,
                            exit: 300,
                            appear: 500,
                          }}
                          in={true}
                          appear={true}
                          classNames={"card-slide"}
                        >
                          <TimeRow
                            delay={index * 0.2}
                            cardDiv={TimeRowDiv}
                            objectName="task"
                            taskObjects={true}
                            currentUser={this.props.currentUser}
                            object={object}
                            index={index}
                          />
                        </CSSTransition>
                      ))}
                    </div>
                  )}
                </AutoSizer>
              ) : (
                <AutoSizer>
                  {({ height, width }) => (
                    <List
                      id="time-object-list"
                      ref={this.props.dynamicListRef}
                      rowHeight={this.getRowHeight}
                      rowCount={Object.keys(this.props.objects).length}
                      height={
                        userShow
                          ? height + 15
                          : this.props.nested
                          ? height - 140
                          : height - 50
                      }
                      overscanRowCount={5}
                      role={"section"}
                      aria-label={`${this.props.objectName} list, ${this.props.objects.length} items.`}
                      width={width}
                      style={{
                        paddingRight: `${
                          this.props.objects.length * 55 >= height
                            ? "0"
                            : "10px"
                        }`,
                      }}
                      rowRenderer={this.renderProjects}
                      scrollToIndex={this.state.activeIndex}
                      scrollToAlignment="start"
                    ></List>
                  )}
                </AutoSizer>
              )}
              {this.props.objects.length === 0 && (
                <p
                  style={{
                    border: "1px solid #519acc",
                    padding: "10px",
                    borderRadius: "10px",
                    textAlign: "center",
                  }}
                >
                  No {this.props.objectName}s for selected duration.
                </p>
              )}
            </section>
          </section>
        </div>
      </div>
    );
  }
}

export default TimeObjectsContainer;
