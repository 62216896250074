import React from "react";
import MoneyBagsIcon from "../icons/money_bags_icon";
import axios from "axios";

class InvoiceSubmission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceNumber: "",
    };
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  }

  closeModal = () => {
    this.props.modalAction(false, "", {});
  };

  handleInputChange = (e) => {
    this.setState({ invoiceNumber: e.target.value });
  };

  submit = () => {
    this.props.data.updateRowStatus(
      2,
      1,
      this.props.data.id,
      this.state.invoiceNumber
    );
    this.closeModal();
  };

  render() {
    var { data } = this.props;
    return (
      <div
        role="dialog"
        aria-label="Confirm Invoice Modal"
        style={{
          padding: "25px 25px 25px 25px",
          backgroundColor: "white",
          borderRadius: "10px",
        }}
      >
        <h2
          style={{
            fontSize: "18px",
            display: "flex",
            alignItems: "center",
            marginTop: "0px",
            marginBottom: `30px`,
            fontWeight: "600",
            fontFamily: "Manrope",
          }}
        >
          <div
            aria-hidden="true"
            style={{
              overflow: "hidden",
              borderRadius: "50%",
              backgroundColor: "#519acc",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0px 15px 0px 0px",
            }}
          >
            <MoneyBagsIcon height={42} width={42} color="#519acc" />
          </div>
          Confirm invoice submission
        </h2>
        <div>
          <p>
            <a
              target="_blank"
              href={`/time_tracking/clients/${data.client_id}`}
            >
              {data.client}
            </a>{" "}
            -{" "}
            <a
              target="_blank"
              href={`/time_tracking/projects/${data.project_id}`}
            >
              {data.project}
            </a>
            <br />
            PO#:{" "}
            <b style={{ fontWeight: !data.poNumber ? "400" : "600" }}>
              {!data.poNumber ? "None Provided" : data.poNumber}
            </b>
            <br />
            Payment Due:{" "}
            <b style={{ fontWeight: "600" }}>${data.paymentDue.toFixed(2)}</b>
          </p>
          <p style={{ marginTop: "25px" }}>
            Please enter an invoice number for this submission.
          </p>
          <input
            type="text"
            className="text-input-v2 browser-default"
            maxLength={60}
            onChange={this.handleInputChange}
            value={this.state.invoiceNumber}
            style={{ display: "block", width: "100%" }}
          />
          <div style={{ textAlign: "right", marginTop: "20px" }}>
            <button onClick={this.closeModal} className="common-button-cancel">
              Cancel
            </button>
            <button
              disabled={!this.state.invoiceNumber}
              onClick={this.submit}
              style={{ marginLeft: "10px" }}
              className="common-button-submit"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
      // </div>
    );
  }
}

export default InvoiceSubmission;
