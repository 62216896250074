import React from "react";
import { Button, Toast } from "react-materialize";
import moment from "moment";

class RoomResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      finalResults: "",
      resultId: "Id",
      description: "Probably something important.",
      meeting_name: "Meeting",
      booking_date: "03/24/1999",
      button_style: "gimmeSomeSpaceMan2",
      type: this.props.type,
    };
    this.getTheResults = this.getTheResults.bind(this);
    this.checkIt = this.checkIt.bind(this);
    this.getName = this.getName.bind(this);
    this.getBookingDate = this.getBookingDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendIt = this.sendIt.bind(this);
    this.submitEditUser = this.submitEditUser.bind(this);
  }

  sendIt(event) {
    this.handleSubmit(event);
  }

  getName() {
    const meeting_name = `${this.props.currentUser.name}'s Meeting`;
    this.setState({ meeting_name });
  }

  getBookingDate() {
    const today = this.props.currentDay;
    // var today = moment(new Date())
    const formatted_day = moment(today).format("YYYY/MM/DD");
    const booking_date = `${formatted_day} ${this.props.otherResults.startTime}`;
    this.setState({ booking_date });
  }

  submitEditUser(formData) {
    const object = this.props;
    const xhr = new XMLHttpRequest();
    xhr.open("POST", "/bookings.json", true);
    xhr.onload = function () {
      if (xhr.status === 200) {
        const resp = JSON.parse(xhr.response);
        object.bookingHandler(resp);
        object.goBack();
        document.querySelector(".modal-close").click();
      } else {
        alert("There was an issue");
      }
    };
    xhr.send(formData);
  }

  handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("booking[user_id]", this.props.otherResults.user_id);
    formData.append("booking[duration]", this.props.otherResults.duration);
    formData.append("booking[name]", this.state.meeting_name);
    formData.append("booking[booking_date]", this.state.booking_date);
    formData.append("booking[description]", this.state.description);
    formData.append("booking[room_id]", this.state.resultId);
    this.submitEditUser(formData);
  }

  getTheResults(event) {
    event.preventDefault();
    const finalResults = [];
    for (let i = 0; i < this.props.searchResults.length; i++) {
      const searchResults = this.props.searchResults[i].name;
      for (let j = 0; j < this.props.rooms.length; j++) {
        const rooms = this.props.rooms[j].name;
        if (searchResults === rooms) {
          finalResults.push(this.props.rooms[j]);
        }
      }
    }
    this.setState({ finalResults });
    this.setState({ button_style: "vanishButton" });
  }

  checkIt(resultId) {
    const room_id = resultId;
    this.getName();
    this.getBookingDate();
    this.setState({ resultId: room_id }, () => {
      this.sendIt(event);
    });
  }

  render() {
    var tv = "";
    var table = "";
    var clientFriendly = "";
    const finalList = [];
    const clientFriendlyList = [];
    for (let i = 0; i < this.props.searchResults.length; i++) {
      const result = this.props.searchResults[i];
      const resultId = result.id;
      if (result.tv === true) {
        var tv = "TV";
      } else {
        var tv = "";
      }
      if (result.table === true) {
        var table = "Table";
      } else {
        var table = "";
      }
      if (result.allow_client === true) {
        var clientFriendly = "Client Friendly";
        if (this.props.type === "client") {
          clientFriendlyList.push(
            <div key={result.id} className="findMeLayout">
              <div className={`room-picture${resultId}`} />
              <div>
                <h1 className="result-title">{result.name}</h1>
                <p>
                  Max Attendents:
                  {result.capacity}
                </p>
                <p>
                  {tv} {table}
                </p>
                <p>{clientFriendly}</p>
              </div>
              <div>
                <div onClick={this.checkIt.bind(null, resultId)}>
                  <Toast
                    options={{
                      html: `You booked the ${result.name} for ${
                        this.props.otherResults.duration
                      } minutes on ${this.props.currentDay.format("MMMM Do")}`,
                    }}
                  >
                    Book Room?
                  </Toast>
                </div>
              </div>
            </div>
          );
        }
      } else {
        var clientFriendly = "";
      }
      finalList.push(
        <div key={result.id} className="findMeLayout">
          <div className={`room-picture${resultId}`} />
          <div>
            <h1 className="result-title">{result.name}</h1>
            <p>
              Max Attendents:
              {result.capacity}
            </p>
            <p>
              {tv} {table}
            </p>
            <p>{clientFriendly}</p>
          </div>
          <div>
            <div onClick={this.checkIt.bind(null, resultId)}>
              <Toast
                options={{
                  html: `You booked the ${result.name} for ${
                    this.props.otherResults.duration
                  } minutes on ${this.props.currentDay.format("MMMM Do")}`,
                }}
              >
                Book Room?
              </Toast>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Button className="weGottaGoBack" onClick={this.props.searchGoBack}>
          Go Back to Search
        </Button>
        {this.props.searchResults.length > 0 ? (
          this.props.type === "client" ? (
            clientFriendlyList
          ) : (
            finalList
          )
        ) : (
          <h1 style={{ marginTop: "20px" }}>
            Sorry, no available rooms were found.
          </h1>
        )}
      </div>
    );
  }
}

export default RoomResults;
