import React from "react";
import TimeEntryDayColumn from "./time_entry_day_column";

class TimeEntryUserRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.renderTasks = this.renderTasks.bind(this);
  }

  renderTasks() {
    const { timeEntries } = this.props;
    const taskNode = [];
    const secondTaskNode = [];
    for (var i = 0; i < this.props.currentWeek.length; i++) {
      taskNode.push(
        <TimeEntryDayColumn
          selectedProjects={this.props.selectedProjects}
          userId={this.props.user.id}
          userName={this.props.user.name}
          dragDropAddTask={this.props.dragDropAddTask}
          modalAction={this.props.modalAction}
          day={this.props.currentWeek[i]}
          currentWeekEnd={i === this.props.currentWeek.length - 1}
          twoWeeks={this.props.twoWeeks}
          timeEntries={timeEntries[this.props.currentWeek[i]]}
          key={i}
          closure={this.props.currentWeekClosures[i]}
          fullTime={this.props.user.full_time}
          today={this.props.today}
          cutoffDate={this.props.cutoffDate}
          userSchedule={this.props.userSchedule}
          userShowPage={this.props.userShowPage}
        />
      );
    }
    for (var i = 0; i < this.props.secondWeek.length; i++) {
      secondTaskNode.push(
        <TimeEntryDayColumn
          selectedProjects={this.props.selectedProjects}
          userId={this.props.user.id}
          userName={this.props.user.name}
          dragDropAddTask={this.props.dragDropAddTask}
          modalAction={this.props.modalAction}
          day={this.props.secondWeek[i]}
          twoWeeks={this.props.twoWeeks}
          timeEntries={timeEntries[this.props.secondWeek[i]]}
          closure={this.props.secondWeekClosures[i]}
          fullTime={this.props.user.full_time}
          key={i}
          today={this.props.today}
          cutoffDate={this.props.cutoffDate}
          userSchedule={this.props.userSchedule}
          userShowPage={this.props.userShowPage}
        />
      );
    }
    return (
      <div style={{ display: "flex", width: "100%", minHeight: "100px" }}>
        <div style={{ width: "150px", alignSelf: "center" }}>
          <h3
            style={{
              paddingLeft: "20px",
              fontSize: "16px",
              fontWeight: "500",
              marginTop: "20px",
              fontFamily: "Manrope",
            }}
          >
            Logged Time
          </h3>
        </div>
        {taskNode}
        {this.props.twoWeeks && secondTaskNode}
      </div>
    );
  }

  render() {
    return (
      <div
        style={{ marginRight: this.props.userSchedule ? "10px" : "17px" }}
        className="off-white"
      >
        {this.renderTasks()}
      </div>
    );
  }
}

export default TimeEntryUserRow;
