import React, { useState, useCallback } from "react";
import styled from "styled-components";
import moment from "moment";
import ms from "components/modals/ModalStyled";
import { StageForm } from "./lead_stage";
import SingleDateWrapper from "components/common/single_date_wrapper";

const SentCard = styled.div``;

const EstimateSent = (props) => {
  const { project, submitHandler } = props;
  const [hours, setHours] = useState(
    project.budgeted_hours ? project.budgeted_hours : ""
  );
  const [estSendDate, setEstSendDate] = useState(
    project.est_send_date ? moment(project.est_send_date) : undefined
  );
  const [dateFocus, setDateFocus] = useState(false);

  const handleSendDateChange = useCallback((date) => {
    setEstSendDate(date);
  }, []);

  const handleDateFocus = useCallback((focused) => {
    setDateFocus(focused.focused);
  }, []);

  function handleInputChange(e) {
    setHours(e.target.value);
  }

  function roundInput(e) {
    let input = parseFloat(e.target.value);
    let value;
    if (input) {
      if (input < 0) {
        input = Math.abs(input);
      }
      if (input <= 0.12) {
        value = "0.25";
      } else {
        value = (Math.round(input * 4) / 4).toFixed(2);
      }
    } else {
      value = "0.00";
    }
    setHours(value);
  }

  function onPaste(e) {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("Text");
    const num = parseFloat(pastedData);
    if (num && num < 2000) {
      setHours(num.toFixed(2));
    }
  }

  function save(e) {
    e.preventDefault();
    const projectData = {};
    if (hours) {
      projectData.budgeted_hours = hours;
    }
    if (estSendDate) {
      projectData.est_send_date = estSendDate;
    }
    submitHandler(projectData, "est_sending", "project_confirming", true);
  }

  return (
    <SentCard className="transition">
      <StageForm>
        <ms.FormRowDouble style={{ alignItems: "flex-end" }}>
          <ms.FormItem>
            <ms.Label className="custom-labels" htmlFor="est_send_date">
              Estimate Send Date
              <span className="base-color">*</span>
            </ms.Label>
            <SingleDateWrapper
              date={estSendDate}
              onDateChange={handleSendDateChange}
              onFocusChange={handleDateFocus}
              focused={dateFocus}
              label={null}
              placeholder="Select a date"
            />
          </ms.FormItem>
          <ms.FormItem>
            <ms.Label className="custom-labels" htmlFor="budgeted_hours">
              Budget Hours
              <span className="base-color">*</span>
            </ms.Label>
            <input
              className="browser-default text-input-v2 hoursInput"
              name="hours"
              type="number"
              value={hours}
              placeholder="0.00"
              onPaste={onPaste}
              onBlur={roundInput}
              onChange={handleInputChange}
              min={0}
              max={1000000}
              step={0.25}
            />
          </ms.FormItem>
          <ms.FormItem>
            {" "}
            <button
              type="button"
              onClick={save}
              className="common-button-submit"
            >
              Save All Changes
            </button>
          </ms.FormItem>
        </ms.FormRowDouble>
      </StageForm>
    </SentCard>
  );
};

export default EstimateSent;
