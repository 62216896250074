import React from "react";
import RoundDropdown from "../projects/round_dropdown";
import ReactModal from "../modals/react_modal";
import EditIcon from "../icons/edit_icon";
import e from "trix";

class CheckboxMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      modalOpen: false,
      currentModal: "",
      modalData: {},
      active: this.props.active,
      search: null,
    };
    //transition speed is an int
    this.container = React.createRef();
    this.modalRef = React.createRef();
    this.inputRef = React.createRef();
    this.loading = false;
    this.randomNum = 0;
    this.checkboxRef = React.createRef();
    this.handleOutsideClick = (e) => {
      var self = this;
      if (
        self.container &&
        self.container.current &&
        self.container.current.contains(e.target)
      ) {
        // don't close clicked on dropdown container
      } else if (
        self.modalRef &&
        self.modalRef.current &&
        self.modalRef.current.contains(e.target)
      ) {
        //don't close clicked on modal
      } else if (
        e.target.classList.value.includes("react-multi-select__option")
      ) {
        //don't close clicked on react select option
      } else if (
        e.target.classList &&
        e.target.classList.value === "btn-icon"
      ) {
        // don't close clicked on modal X button
      } else if (
        e.target.classList &&
        e.target.classList.value === "react-multi-select__multi-value__remove"
      ) {
        // don't close clicked on weird little empty space next to x
      } else if (
        e.target.nodeName &&
        e.target.nodeName === "path" &&
        self.container &&
        self.container.current &&
        self.container.current.contains(e.target)
      ) {
        // don't close clicked on modal X svg
      } else if (
        e.target.nodeName &&
        e.target.nodeName === "svg" &&
        self.container &&
        self.container.current &&
        self.container.current.contains(e.target)
      ) {
        // don't close clicked on modal X svg, sometimes it's like this too
      } else if (
        e.target.classList &&
        e.target.classList.value.includes("swal2")
      ) {
        // don't close clicked on swal
      } else {
        this.setOpen();
      }
    };
    this.setOpen = this.setOpen.bind(this);
  }

  setModal = (bool, page, data) => {
    this.setState({ modalOpen: bool, currentModal: page, modalData: data });
  };

  onOpenClick = () => {
    this.setModal(true, this.props.modalButton.name, {
      filterOptions: [],
      async: true,
      type: this.props.modalButton.type,
      closeButton: true,
      customClass: "sm-modal",
      currentUser: this.props.modalButton.currentUser,
      handler: this.props.modalButton.handler,
      fastOpen: this.setFastOpen,
      backdropClickCheck: {
        title: "Discard New Filter",
        text: "Are you sure that you want to discard this filter?",
      },
    });
  };

  componentDidMount() {
    if (this.checkboxRef.current) {
      this.checkboxRef.current.style.width = `${this.checkboxRef.current.clientWidth}px`;
    }
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.options.length != prevProps.options.length &&
      this.state.open
    ) {
      var elem = document.getElementById(
        `custom-checkbox-menu-${this.props.id}`
      );
      if (elem && !this.props.searchEnabled) {
        var elems = elem.getElementsByClassName("user-option");
        var last_elem = elems[elems.length - 1];
        if (last_elem) {
          last_elem.style.transform = "translateX(0px)";
          last_elem.style.opacity = "1";
        }
      }
    }
    if (prevProps.count === 0 && this.props.count > 0) {
      if (this.checkboxRef.current) {
        this.checkboxRef.current.style.width = `${
          this.checkboxRef.current.clientWidth + 20
        }px`;
      }
    } else if (prevProps.count > 0 && this.props.count === 0) {
      if (this.checkboxRef.current) {
        this.checkboxRef.current.style.width = `${
          this.checkboxRef.current.clientWidth - 20
        }px`;
      }
    }
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 8 && this.state.search) {
      if (this.state.search.length === 1) {
        this.setState({ search: null });
      } else {
        this.setState({
          search: this.state.search.slice(0, this.state.search.length - 1),
        });
      }
    } else if ((e.keyCode >= 48 && e.keyCode <= 90) || e.keyCode === 32) {
      this.setState({
        search: this.state.search ? this.state.search + e.key : e.key,
      });
    }
  };

  handleClear = (e) => {
    e.stopPropagation();
    if (this.props.clearFunction) {
      this.props.clearFunction(this.props.name);
    }
  };

  setOpen() {
    if (!this.loading) {
      this.loading = true;
      if (!this.state.open === true) {
        window.addEventListener("click", this.handleOutsideClick);
        // window.addEventListener("keydown", this.handleKeyDown)
        this.setState(
          {
            open: true,
          },
          () => {
            var elem = document.getElementById(
              `custom-checkbox-menu-${this.props.id}`
            );
            setTimeout(() => {
              elem.style.maxHeight = this.props.maxHeight
                ? this.props.maxHeight
                : this.props.modalButton
                ? "290px"
                : "250px";
              elem.style.overflowY = "hidden";
              var optionElems = elem.getElementsByClassName("user-option");
              for (let i = 0; i < optionElems.length; i++) {
                var elm = optionElems[i];
                if (i < 6) {
                  var math = i * 0.1;
                  elm.style.transition = `transform .2s ease ${math}s, opacity .2s ease ${math}s`;
                }
                elm.style.transform = "translateX(0px)";
                elm.style.opacity = "1";
              }
            }, 50);
            setTimeout(() => {
              elem.style.transitionDelay = "0.2s";
              this.loading = false;
            }, 450);
          }
        );
      } else {
        window.removeEventListener("click", this.handleOutsideClick);
        // window.removeEventListener("keydown", this.handleKeyDown)
        var elem = document.getElementById(
          `custom-checkbox-menu-${this.props.id}`
        );
        elem.style.maxHeight = "0px";
        var optionElems = document.getElementsByClassName("user-option");
        for (let i = 0; i < optionElems.length; i++) {
          var elm = optionElems[i];
          var math = i * 0.1;
          elm.style.transition = `transform .4s ease ${math}s, opacity .4s ease ${math}s`;
          elm.style.transform = "translateX(-50px)";
          elm.style.opacity = "0";
        }
        setTimeout(() => {
          this.setState(
            {
              open: false,
              search: "",
            },
            () => {
              this.loading = false;
            }
          );
        }, 450);
      }
    }
  }

  handleInput = (e) => {
    this.setState({ search: e.target.value ? e.target.value : "" });
  };

  render() {
    var isArray = Array.isArray(this.props.options);
    var options = isArray
      ? this.props.options.filter((o) =>
          this.state.search
            ? o.label.toLowerCase().includes(this.state.search.toLowerCase())
            : true
        )
      : this.props.options;
    return (
      <div ref={this.checkboxRef} style={{ position: "relative" }}>
        <div
          ref={this.container}
          key={`checkbox_menu_container_${this.props.id}`}
          className="custom-checkbox-menu-container"
          style={{
            position: "relative",
            flex: 1,
          }}
        >
          <div
            className={`custom-checkbox-menu-selector${
              this.state.open ? " open" : " closed"
            }`}
            onClick={this.setOpen}
            style={{
              height: "35px",
              color: "initial",
              paddingLeft: "10px",
              paddingRight: "10px",
              cursor: "pointer",
              borderColor: this.props.active ? "#519acc" : "",
            }}
          >
            <span
              style={{
                fontSize: "13px",
                lineHeight: "33px",
                whiteSpace: "nowrap",
                display: "flex",
                justifyContent: this.state.search
                  ? "space-between"
                  : "space-around",
                alignItems: "center",
                height: "35px",
              }}
            >
              <div
                style={{
                  display: this.state.search ? "none" : "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  onClick={this.handleClear}
                  data-text={this.props.count}
                  className={
                    this.props.count >= 1 ? "blue-circle active" : "blue-circle"
                  }
                ></p>
              </div>
              <span
                style={{
                  padding: "0 10px",
                  verticalAlign: "middle",
                  color:
                    this.state.open && this.props.searchEnabled
                      ? "#989898"
                      : "#313131",
                }}
              >
                {this.state.open && this.props.searchEnabled ? (
                  <input
                    onChange={this.handleInput}
                    placeholder={this.props.title}
                    ref={this.inputRef}
                    autoComplete="false"
                    autoFocus={true}
                    maxLength={50}
                    style={{
                      height: "33px",
                      marginBottom: "0",
                      border: "none",
                      boxShadow: "none",
                      fontSize: "15px",
                      outline: "none",
                      ...this.props.inputStyle,
                    }}
                    value={this.state.search ? this.state.search : ""}
                  />
                ) : (
                  this.props.title
                )}
              </span>
              <RoundDropdown svgStyles={{ minWidth: "15px" }} />
            </span>
          </div>
          {this.state.open && (
            <div
              className="custom-checkbox-menu"
              id={`custom-checkbox-menu-${this.props.id}`}
              style={{
                position: "absolute",
                zIndex: "200",
                maxHeight: this.props.modalButton ? "290px" : "250px",
                overflowX: "hidden",
                overflowY: "hidden",
                transition: `max-height 0.5s`,
                right: this.props.width
                  ? "0px"
                  : this.props.last
                  ? "0%"
                  : this.props.right
                  ? this.props.right
                  : "-50px",
                top: "120%",
                padding: "0px 15px",
                width: this.props.width ? this.props.width : "240px",
              }}
            >
              <div
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  maxHeight: Array.isArray(this.props.options)
                    ? "240px"
                    : "205px",
                  marginBottom: Array.isArray(this.props.options) ? "5px" : 0,
                  width: "110%",
                  marginLeft: "-5px",
                  marginTop: "5px",
                }}
                className="new-scrollbar"
              >
                {Array.isArray(this.props.options) ? (
                  options.length > 0 ? (
                    options.map((option, index) => {
                      return (
                        <div
                          style={{
                            marginTop: index === 0 ? "10px" : "0px",
                            marginBottom:
                              index === this.props.options.length - 1
                                ? "10px"
                                : "0px",
                            transform:
                              this.props.searchEnabled &&
                              this.state.search !== null
                                ? "translateX(0px)"
                                : "translateX(-14px)",
                            opacity:
                              this.props.searchEnabled &&
                              this.state.search !== null
                                ? "1"
                                : "0",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "5px",
                            transition: `transform 0.2s ease-out .04s, opacity 0.2s ease 0.4s`,
                          }}
                          key={index}
                          className={option.classList}
                        >
                          <label className="custom-labels truncate">
                            <input
                              onChange={option.handler}
                              className="filled-in"
                              name={this.props.name}
                              checked={option.checked}
                              value={option.value}
                              type="checkbox"
                            />
                            <span
                              style={{
                                whiteSpace: "nowrap",
                                height: "100%",
                                width: "100%",
                                paddingLeft: "30px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                fontSize: "14px",
                                color: "#313131",
                              }}
                            >
                              {option.label}
                            </span>
                          </label>
                          {this.props.filter &&
                            this.props.filterModalAction != undefined && (
                              <button
                                data-filter-type={"team"}
                                data-filter-id={option.value}
                                type="button"
                                className="filter-btn btn btn-flat"
                                onClick={this.props.filterModalAction}
                              >
                                <EditIcon
                                  svgStyles={{ pointerEvents: "none" }}
                                  height={20}
                                  width={20}
                                />
                              </button>
                            )}
                          {this.props.optionFilter &&
                            option.user_id &&
                            this.props.currentUser.id === option.user_id &&
                            this.props.filterModalAction != undefined && (
                              <button
                                data-filter-type={"team"}
                                data-filter-id={option.value}
                                type="button"
                                className="filter-btn btn btn-flat"
                                onClick={this.props.filterModalAction}
                              >
                                <EditIcon
                                  svgStyles={{ pointerEvents: "none" }}
                                  height={20}
                                  width={20}
                                />
                              </button>
                            )}
                        </div>
                      );
                    })
                  ) : (
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "5px",
                        transition: `transform 0.2s ease-out .04s, opacity 0.2s ease 0.4s`,
                      }}
                    >
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          height: "100%",
                          width: "100%",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          fontSize: "14px",
                          color: "#bfbfbf",
                          textAlign: "center",
                        }}
                      >
                        No options found
                      </span>
                    </div>
                  )
                ) : (
                  this.props.options["groups"].map((group, index) => {
                    var groupOptions = group.options.filter((o) =>
                      this.state.search
                        ? o.name
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase())
                        : true
                    );
                    return (
                      <div className={group.classList} key={index}>
                        <p
                          style={{
                            fontSize: "13px",
                            textTransform: "uppercase",
                            marginLeft: "13px",
                            color: "gray",
                          }}
                        >
                          {group.name}
                        </p>
                        {groupOptions.length > 0 ? (
                          groupOptions.map((option, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  marginTop: index === 0 ? "10px" : "0px",
                                  marginBottom:
                                    index === this.props.options.length - 1
                                      ? "10px"
                                      : "0px",
                                  transform:
                                    this.props.searchEnabled &&
                                    this.state.search !== null
                                      ? "translateX(0px)"
                                      : "translateX(-14px)",
                                  opacity:
                                    this.props.searchEnabled &&
                                    this.state.search !== null
                                      ? "1"
                                      : "0",
                                  padding: "5px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  transition: `transform 0.2s ease-out .04s, opacity 0.2s ease 0.4s`,
                                }}
                                className={group.classList + " group-option"}
                              >
                                <label className="custom-labels truncate">
                                  <input
                                    onChange={group.handler}
                                    className="filled-in"
                                    name={this.props.name}
                                    checked={option.checked}
                                    value={option.id}
                                    type="checkbox"
                                  />
                                  <span
                                    style={{
                                      whiteSpace: "nowrap",
                                      height: "100%",
                                      width: "100%",
                                      paddingLeft: "30px",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      fontSize: "14px",
                                      color: "#313131",
                                    }}
                                  >
                                    {option.name}
                                  </span>
                                </label>
                                {this.props.filter &&
                                  this.props.filterModalAction != undefined &&
                                  group.name === "My Filters" && (
                                    <button
                                      data-filter-type={"not-team"}
                                      data-filter-id={option.id}
                                      type="button"
                                      className="filter-btn btn btn-flat"
                                      onClick={this.props.filterModalAction}
                                    >
                                      <EditIcon
                                        svgStyles={{ pointerEvents: "none" }}
                                        height={20}
                                        width={20}
                                      />
                                    </button>
                                  )}
                              </div>
                            );
                          })
                        ) : (
                          <div
                            style={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "5px",
                              transition: `transform 0.2s ease-out .04s, opacity 0.2s ease 0.4s`,
                            }}
                          >
                            <span
                              style={{
                                whiteSpace: "nowrap",
                                height: "100%",
                                width: "100%",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                fontSize: "14px",
                                color: "#bfbfbf",
                                textAlign: "center",
                              }}
                            >
                              No options found
                            </span>
                          </div>
                        )}
                      </div>
                    );
                  })
                )}
              </div>
              {this.props.modalButton && (
                <div
                  style={{
                    margin: "10px 3px",
                    background: "#ffffffba",
                    backdropFilter: "blur(3px)",
                    width: "225px",
                  }}
                >
                  <ReactModal
                    modalRef={this.modalRef}
                    isShowing={this.state.modalOpen}
                    page={this.state.currentModal}
                    data={this.state.modalData}
                    modalAction={this.setModal}
                  />
                  <button
                    style={{ fontSize: "14px" }}
                    className="btn-flat"
                    onClick={this.onOpenClick}
                  >
                    {this.props.modalButton.text}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

CheckboxMenu.defaultProps = {
  inputStyle: {},
};

export default CheckboxMenu;
