import React from "react";

function ProjectTimelineStepTwo({ height, width, svgStyles, color }) {
  return (
    <svg
      aria-hidden="true"
      height={height}
      width={width}
      fill={color}
      style={svgStyles}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 38 33"
    >
      <path
        d="M20.131 26.6667C20.131 20.7822 24.8705 16 30.7024 16C32.6405 16 34.4376 16.5333 35.9881 17.44V3.55556C35.9881 2.61256 35.6168 1.70819 34.956 1.0414C34.2952 0.374602 33.3989 0 32.4643 0H4.27381C2.3181 0 0.75 1.58222 0.75 3.55556V24.8889C0.75 25.8319 1.12126 26.7362 1.7821 27.403C2.44294 28.0698 3.33924 28.4444 4.27381 28.4444H20.2895C20.2014 27.8578 20.131 27.2711 20.131 26.6667ZM4.27381 7.11111V3.55556L18.369 12.4444L32.4643 3.55556V7.11111L18.369 16L4.27381 7.11111ZM32.4643 32V28.4444H25.4167V24.8889H32.4643V21.3333L37.75 26.6667L32.4643 32Z"
        fill={color}
      />
    </svg>
  );
}

ProjectTimelineStepTwo.defaultProps = {
  height: "33px",
  width: "38px",
  svgStyles: {},
  color: "#519acc",
};

export default ProjectTimelineStepTwo;
