import React from "react";
import styled from "styled-components";
import moment from "moment";

const DateHeader = styled.div.attrs((props) => ({
  twoWeeks: props.twoWeeks,
  today: props.today,
  userSchedule: props.userSchedule,
}))`
  width: ${(props) =>
    props.twoWeeks
      ? props.userSchedule
        ? "calc(10% - 15px)"
        : "9.09%"
      : props.userSchedule
      ? "calc(20% - 30px)"
      : "16.66%"};
  display: flex;
  height: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.today
      ? `background-color: rgba(211, 211, 211, 0.678);
       border-bottom: solid 4px #519acc;
       padding-top: 4px;`
      : ""}
  > p:first-child {
    margin-bottom: 0px;
    font-family: Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
  }
  > p:last-child {
    margin-top: 0px;
    font-family: Arial;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }
`;

class ScheduleDateHeaders extends React.PureComponent {
  render() {
    const { twoWeeks } = this.props;
    const currentDay = moment();
    const monday = moment(this.props.currentWeekStart);
    const week = [moment(monday).format("M/DD")];

    for (let i = 0; i < 9; i++) {
      week.push(moment(monday.add(1, "d")).format("M/DD"));
      if (i === 3 && twoWeeks) {
        monday.add(2, "d");
      } else if (i === 3) {
        break;
      }
    }
    return (
      <div style={{ padding: "0px" }} className="row frame-row dark-gray-text">
        <div
          style={{
            padding: "0px",
            backgroundColor: "white",
          }}
          className="row frame-row"
        >
          <div className="col s12" id="date-week-container">
            <div
              style={{
                width: this.props.userSchedule
                  ? "150px"
                  : twoWeeks
                  ? "9.09%"
                  : "16.66%",
              }}
              className="col s4 m2 left"
            >
              {/* Time Type */}
              {!this.props.userSchedule && (
                <p
                  style={{
                    margin: "13px 0px 0px",
                    paddingLeft: "10px",
                    fontFamily: "Manrope",
                    fontWeight: "600",
                    fontSize: "13px",
                  }}
                >
                  {this.props.userCount} Users
                </p>
              )}
              {this.props.showDuplicateButton && (
                <button
                  type="button"
                  style={{
                    display: "block",
                    textAlign: "left",
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                    color: "#519acc",
                    paddingLeft: "9px",
                  }}
                  onClick={this.props.copyProjectSchedule}
                >
                  Duplicate Schedule
                </button>
              )}
            </div>
            {week.map((d, i) => {
              const currentDate = moment().day(i > 4 ? i - 4 : i + 1);
              const formattedDate = currentDate.format(
                twoWeeks ? "ddd M/DD" : "dddd M/DD"
              );

              return (
                <DateHeader
                  userSchedule={this.props.userSchedule}
                  key={d}
                  twoWeeks={twoWeeks}
                  today={week.indexOf(currentDay.format("M/DD")) === i}
                  aria-label={formattedDate}
                >
                  <p>{currentDate.format(twoWeeks ? "ddd" : "dddd")}</p>
                  <p>{d}</p>
                </DateHeader>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default ScheduleDateHeaders;
