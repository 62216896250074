import React from "react";
import ClientDirectory from "./client_directory";
import ProjectDetail from "../projects/project_detail";
import ReactModal from "../modals/react_modal";
import PageNav from "../common/page_nav";
import axios from "axios";
import ActivityFeedContainer from "../activity/activity_feed_container";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import ProjectMembersBox from "../projects/project_members_box";
import PageDetail from "../common/page_detail";
import ProjectDirectory from "../projects/project_directory";
import * as Sentry from "@sentry/react";
import { sentryInit, setAlphaGroups } from "../utils";
import ClientIcon from "../icons/client_icon";

sentryInit();
class ClientsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: this.props.clients,
      client: this.props.client,
      project: this.props.project,
      // projects: this.props.client ? this.props.client.projects : [],
      projects: this.props.projects ? this.props.projects : [],
      search: "",
      searchData: [],
      selectedTag: [],
      selectedUser: [],
      selectedStatus: [this.props.client ? 4 : 1],
      projectTasks: [],
      projectMembers: this.props.projectMembers,
      timeOffProject: this.props.project
        ? this.props.project.pto ||
          this.props.project.holiday ||
          this.props.project.sick_time
        : false,
      modalOpen: false,
      currentModal: "",
      modalData: {},
      alphaGroups: setAlphaGroups(
        this.props.client ? this.props.client.projects : this.props.clients
      ),
      loading: false,
      currentView: this.props.project
        ? "project"
        : this.props.client
        ? "client_projects"
        : "clients",
      direction: "forward",
      activityEvents: this.props.activityEvents,
      activityFeedTitle: this.props.project
        ? this.props.project.name
        : this.props.client
        ? this.props.client.name
        : "All Clients",
    };
    this.dynamicListRef = React.createRef();
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.filterHandler = this.filterHandler.bind(this);
    this.scrollIntoView = this.scrollIntoView.bind(this);
    this.setDigestedData = this.setDigestedData.bind(this);
  }

  componentDidMount() {
    var url = new URL(location.href);
    if (!this.props.project) {
      if (url.searchParams.toString() && !this.state.client) {
        localStorage.setItem("clients_url_params", url.searchParams.toString());
      } else if (
        localStorage.getItem("clients_url_params") &&
        !this.state.client
      ) {
        var params = localStorage.getItem("clients_url_params").split("&");
        params.forEach((p) => {
          var param = p.split("=");
          url.searchParams.set(param[0], param[1].replace(/%2C/g, ","));
        });
        history.replaceState(
          history.state,
          null,
          "?" + url.searchParams.toString()
        );
      }
      this.setBaseFilters(url);
    }
    document.title = "Syncit - Clients";
  }

  setBaseFilters = (url) => {
    var filters = ["selectedUser", "selectedTag"];
    var filterObject = {};
    for (var x = 0; x < 2; x++) {
      var params = url.searchParams.get(filters[x]);
      if (params) {
        filterObject[filters[x]] = params.split(",").map((x) => parseInt(x));
      }
    }
    this.setState(filterObject, () => {
      this.setDigestedData("");
    });
  };

  scrollIntoView(e) {
    var row = e.target.dataset.index;
    if (this.dynamicListRef && this.dynamicListRef.current) {
      this.dynamicListRef.current.scrollToItem(row, "start");
    }
  }

  updateSearch = (event) => {
    var input = event.target.value;
    this.setDigestedData(input);
  };

  clearFilters = (name) => {
    if (typeof name === "string") {
      this.setState(
        {
          [name]: [],
        },
        () => {
          var url = new URL(location.href);
          url.searchParams.delete(name);
          if (url.searchParams.toString() && !this.state.client) {
            localStorage.setItem(
              "clients_url_params",
              url.searchParams.toString()
            );
          } else if (!this.state.client) {
            localStorage.removeItem("clients_url_params");
          }
          var stateObj = {};
          window.history.replaceState(window.history.state, "", url.href);
          this.setDigestedData(this.state.search);
        }
      );
    } else {
      this.setState(
        {
          selectedTag: [],
          selectedUser: [],
        },
        () => {
          var url = new URL(location.href);
          url.searchParams.delete("selectedUser");
          url.searchParams.delete("selectedTag");
          if (!this.state.client) {
            localStorage.removeItem("clients_url_params");
          }
          var stateObj = {};
          window.history.replaceState(window.history.state, "", url.href);
          this.setDigestedData(this.state.search);
        }
      );
    }
  };

  searchHandler = (clients, input) => {
    if (input && input.length > 0) {
      const searchTerm = input.toLowerCase();
      let filteredItems = clients.filter((i) => {
        if (i.name != null && i.name != "") {
          if (i.projects && i.projects.length >= 1) {
            var string =
              i.name.toLowerCase() + i.projects.join(",").toLowerCase();
            return string.includes(searchTerm);
          } else {
            return i.name.toLowerCase().includes(searchTerm);
          }
        }
      });
      CustomAriaAlert(`${filteredItems.length} search results`);
      return filteredItems;
    } else if (input === "") {
      var data = clients;
    }
    return data;
  };

  filterHandler() {
    var self = this;
    if (this.state.currentView === "client_projects") {
      var clients = this.state.projects;
    } else {
      var clients = this.state.clients;
    }
    if (this.state.selectedTag.length >= 1) {
      clients = clients.filter((client) =>
        this.state.currentView === "client_projects"
          ? self.state.selectedTag.includes(client.tag.id)
          : client.tag_ids.some((tag) => self.state.selectedTag.includes(tag))
      );
    }
    if (this.state.selectedStatus.length >= 1) {
      if (this.state.currentView === "client_projects") {
        if (this.state.selectedStatus[0] === 1) {
          clients = clients.filter(
            (project) => project.active && project.pending === false
          );
        } else if (this.state.selectedStatus[0] === 2) {
          clients = clients.filter((project) => project.active === false);
        } else if (this.state.selectedStatus[0] === 3) {
          clients = clients.filter((project) => project.pending);
        } else {
          // do nothing all projects
        }
      } else {
        if (this.state.selectedStatus.includes(4)) {
          // filter nothing all state
        } else if (this.state.selectedStatus.includes(1)) {
          clients = clients.filter((project) => project.active);
        } else {
          clients = clients.filter((project) => project.active === false);
        }
      }
    }

    if (this.state.selectedUser.length >= 1) {
      clients = clients.filter((client) => {
        var ids = client.lead_ids;
        return ids.some((lead) => self.state.selectedUser.includes(lead));
      });
    }
    return clients;
  }

  handleSelectChange(e) {
    var e = e;
    var name = e.target.name;
    var value = parseInt(e.target.value);
    var old = this.state[name];
    if (old.includes(value)) {
      old = old.filter((x) => x != value);
    } else {
      old.push(value);
    }
    this.setState({ [name]: old }, () => {
      this.filterUrlHandler(name, name, value);
      this.setDigestedData(this.state.search);
    });
  }

  filterUrlHandler = (param_key, state_key, filter_id) => {
    var url = new URL(location.href);
    var paramPresent = url.searchParams.has(param_key);
    var newStatusFilter = this.state[state_key];
    var append;
    if (newStatusFilter.includes(filter_id)) {
      if (paramPresent) {
        var ids = url.searchParams.get(param_key);
        filter_id = ids + "," + filter_id.toString();
      }
      append = true;
    } else {
      if (paramPresent) {
        var ids = url.searchParams.get(param_key).split(",");
        ids = ids.filter((x) => x != filter_id.toString());
        filter_id = ids.join(",");
        if (ids.length >= 1) {
          append = true;
        } else {
          append = false;
        }
      } else {
        append = false;
      }
    }
    this.filterUrlChange(param_key, filter_id, append);
  };

  filterUrlChange = (key, value, append) => {
    var url = new URL(location.href);
    var newUrl;
    if (append) {
      url.searchParams.set(key, value);
      if (!this.state.client) {
        localStorage.setItem("clients_url_params", url.searchParams.toString());
      }
    } else {
      url.searchParams.delete(key);
      if (url.searchParams.toString() && !this.state.client) {
        localStorage.setItem("clients_url_params", url.searchParams.toString());
      } else if (!this.state.client) {
        localStorage.removeItem("clients_url_params");
      }
    }
    window.history.replaceState(window.history.state, "", url.href);
  };

  handleSingleSelectChange = (e) => {
    var e = e;
    var name = e.target.name;
    var value = parseInt(e.target.value);
    this.setState({ [name]: [value] }, () => {
      this.setDigestedData(this.state.search);
    });
  };

  setDigestedData = (input) => {
    var filtered = this.filterHandler();
    var searched = this.searchHandler(filtered, input);
    this.setState(
      {
        search: input,
        searchData: searched,
        alphaGroups: setAlphaGroups(searched),
      },
      () => {
        window.dispatchEvent(new Event("resize"));
      }
    );
  };

  setModal = (bool, page, data) => {
    this.setState({ modalOpen: bool, currentModal: page, modalData: data });
  };

  backToClients = () => {
    this.setState({ direction: "back", loading: true }, () => {
      axios
        .get(`/api/v1/get_clients_activity`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.setState(
            {
              client: null,
              currentView: "clients",
              activityFeedTitle: "All Clients",
              alphaGroups: setAlphaGroups(this.state.clients),
              search: "",
              searchData: [],
              selectedTag: [],
              selectedType: [],
              selectedUser: [],
              selectedStatus: [],
              projectMembers: [],
              activityEvents: res.data,
              loading: false,
            },
            () => {
              setTimeout(() => {
                var elem = document.getElementById("page-nav-title");
                if (elem) {
                  elem.focus();
                }
              }, 600);
              if (localStorage.getItem("clients_url_params")) {
                var url = new URL(location.href);
                var params = localStorage
                  .getItem("clients_url_params")
                  .split("&");
                params.forEach((p) => {
                  var param = p.split("=");
                  url.searchParams.set(param[0], param[1].replace(/%2C/g, ","));
                });
                history.replaceState(
                  {},
                  null,
                  "?" + url.searchParams.toString()
                );
                this.setBaseFilters(url);
              } else {
                this.setDigestedData("");
                window.history.replaceState("page2", "Syncit", "/clients");
              }
            }
          );
        })
        .catch((err) => {
          console.error(err);
          this.setState({ loading: false });
        });
    });
  };

  backToProjects = (clientId) => {
    this.setState({ direction: "back" }, () => {
      this.setState({
        project: null,
        currentView: "client_projects",
        activityFeedTitle: this.state.client.name,
        alphaGroups: setAlphaGroups(this.state.projects),
        search: "",
        searchData: [],
        selectedTag: [],
        selectedUser: [],
        selectedStatus: [],
      });
      window.history.replaceState(
        "page2",
        "Syncit",
        "/clients/" + this.state.client.id
      );
    });
  };

  loadClient = (id) => {
    var start = Date.now();
    this.setState({ direction: "forward", loading: true });
    axios
      .get(`/api/v1/client_projects/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var data = res.data;
        if (Date.now() - start < 500) {
          setTimeout(() => {
            this.setState(
              {
                client: data.client,
                projects: data.projects,
                alphaGroups: setAlphaGroups(data.client.projects),
                currentView: "client_projects",
                activityFeedTitle: data.client.name,
                loading: false,
                activityEvents: data.activityEvents,
                search: "",
                searchData: [],
                selectedTag: [],
                selectedUser: [],
                selectedStatus: [4],
                projectMembers: data.projectMembers,
              },
              () => {
                setTimeout(() => {
                  var elem = document.getElementById("page-nav-title");
                  if (elem) {
                    elem.focus();
                  }
                }, 500);

                this.setDigestedData("");
              }
            );
          }, 500 - (Date.now() - start));
        } else {
          this.setState(
            {
              client: data.client,
              projects: data.projects,
              alphaGroups: setAlphaGroups(data.client.projects),
              currentView: "client_projects",
              activityFeedTitle: data.client.name,
              loading: false,
              activityEvents: data.activityEvents,
              search: "",
              searchData: [],
              selectedTag: [],
              selectedUser: [],
              selectedStatus: [1],
              projectMembers: data.projectMembers,
            },
            () => {
              setTimeout(() => {
                var elem = document.getElementById("page-nav-title");
                if (elem) {
                  elem.focus();
                }
              }, 500);
              this.setDigestedData("");
            }
          );
        }
        window.history.pushState(
          window.history.state,
          null,
          "/clients/" + data.client.id
        );
      })
      .catch((err) => console.error(err));
  };

  compare(a, b) {
    if (a.name && b.name) {
      var a = a.name.toLowerCase();
      var b = b.name.toLowerCase();
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    } else {
      return -1;
    }
  }

  clientsHandler = (action, client) => {
    var newClients = this.state.clients;
    if (action === "create") {
      newClients.push(client);
    } else if (action === "delete" || action === "edit") {
      for (var i = 0; i < newClients.length; i++) {
        if (newClients[i].id === client.id) {
          if (action === "delete") {
            newClients.splice(i, 1);
          } else if (action === "edit") {
            newClients.splice(i, 1, client);
          }
          break;
        }
      }
    }
    var sorted = newClients.sort(this.compare);
    var alpha = setAlphaGroups(sorted);
    this.setState(
      {
        clients: sorted,
        alphaGroups: alpha,
      },
      () => {
        if (this.state.search === "") {
          //stupid hack to get the rows to reszie correctly after client added or retired
          this.setDigestedData(" ");
          setTimeout(() => {
            this.setDigestedData("");
          }, 50);
        } else {
          this.setDigestedData(this.state.search);
        }
      }
    );
  };

  loadProject = (id) => {
    var start = Date.now();
    this.setState({ direction: "forward", loading: true });
    axios
      .get(`/api/v1/projects/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (this.state.currentView === "project") {
          this.backToProjects();
          setTimeout(() => {
            this.setState({
              project: res.data.project,
              currentView: "project",
              activityFeedTitle: res.data.project.name,
              timeOffProject:
                res.data.project.pto ||
                res.data.project.holiday ||
                res.data.project.sick_time,
              loading: false,
              search: "",
              projectTasks: res.data.projectTasks,
              searchData: [],
              selectedTag: [],
              selectedUser: [],
              selectedStatus: [],
              projectMembers: res.data.projectMembers,
            });
            document.title = `Syncit - ${res.data.project.name}`;
          }, 1000);
        } else {
          if (Date.now() - start < 500) {
            setTimeout(() => {
              this.setState({
                project: res.data.project,
                currentView: "project",
                activityFeedTitle: res.data.project.name,
                timeOffProject:
                  res.data.project.pto ||
                  res.data.project.holiday ||
                  res.data.project.sick_time,
                loading: false,
                search: "",
                projectTasks: res.data.projectTasks,
                searchData: [],
                selectedTag: [],
                selectedUser: [],
                selectedStatus: [],
                projectMembers: res.data.projectMembers,
              });
              document.title = `Syncit - ${res.data.project.name}`;
            }, 500 - (Date.now() - start));
          } else {
            this.setState({
              project: res.data.project,
              currentView: "project",
              activityFeedTitle: res.data.project.name,
              timeOffProject:
                res.data.project.pto ||
                res.data.project.holiday ||
                res.data.project.sick_time,
              loading: false,
              search: "",
              projectTasks: res.data.projectTasks,
              searchData: [],
              selectedTag: [],
              selectedUser: [],
              selectedStatus: [],
              projectMembers: res.data.projectMembers,
            });
            document.title = `Syncit - ${res.data.project.name}`;
          }
        }

        window.history.pushState(
          window.history.state,
          null,
          "/clients/" +
            res.data.project.client_id +
            "/projects/" +
            res.data.project.id
        );
      })
      .catch((err) => console.error(err));
  };

  renderCurrentView = () => {
    switch (this.state.currentView) {
      case "clients":
        return (
          <ClientDirectory
            onEditClientClick={this.onEditClientClick}
            filtersActive={
              this.state.selectedStatus.length +
                this.state.selectedTag.length +
                this.state.selectedUser.length >=
              1
            }
            dynamicListRef={this.dynamicListRef}
            alphaGroups={this.state.alphaGroups}
            search={this.state.search}
            searchData={this.state.searchData}
            setModal={this.setModal}
            loadClient={this.loadClient}
            tags={this.props.tags}
            clients={this.props.clients}
          />
        );
      case "client_projects":
        const {
          search,
          selectedStatus,
          selectedTag,
          selectedUser,
          searchData,
          projects,
          alphaGroups,
          client,
        } = this.state;
        const filtersActive =
          selectedStatus.length + selectedTag.length + selectedUser.length >= 1;
        const filteredProjects =
          search !== "" || filtersActive ? searchData : projects;

        return (
          <ProjectDirectory
            clientView={true}
            filtersActive={filtersActive}
            setModal={this.setModal}
            selectedStatus={selectedStatus}
            currentUser={this.props.currentUser}
            loadProject={this.loadProject}
            leadOptions={this.props.leadOptions}
            tagOptions={this.props.tagOptions}
            tags={this.props.tags}
            backToClients={this.backToClients}
            projects={filteredProjects}
            dynamicListRef={this.dynamicListRef}
            alphaGroupsRefresher={setAlphaGroups}
            alphaGroups={alphaGroups}
            search={search}
            searchData={searchData}
            client={client}
            handleClientClick={this.handleClientClick}
          />
        );
      case "project":
        return (
          <ProjectDetail
            backToProjects={this.backToProjects}
            setModal={this.setModal}
            tagOptions={this.props.tagOptions}
            leadOptions={this.props.leadOptions}
            userOptions={this.props.userOptions}
            projectTasks={this.state.projectTasks}
            copyProject={this.copyProject}
            tags={this.props.tags}
            project={this.state.project}
            currentUser={this.props.currentUser}
            timeOffProject={this.state.timeOffProject}
            page="clients"
          />
        );
    }
  };

  renderResultsFooter = () => {
    var filtersActive =
      this.state.selectedStatus.length +
        this.state.selectedTag.length +
        this.state.selectedUser.length >=
      1;
    switch (this.state.currentView) {
      case "clients":
        return (
          <div
            style={{
              position: "absolute",
              bottom: "4px",
              width: "100%",
              left: "0px",
              textAlign: "center",
            }}
          >
            {" "}
            Displaying{" "}
            {filtersActive
              ? this.state.searchData.length
              : this.state.search
              ? this.state.searchData.length
              : this.state.clients.length}{" "}
            of {this.state.clients.length} Clients
          </div>
        );
      case "client_projects":
        return (
          <div
            style={{
              position: "absolute",
              top: "32px",
              right: "32px",
              textAlign: "right",
            }}
          >
            {" "}
            Displaying{" "}
            {filtersActive
              ? this.state.searchData.length
              : this.state.search
              ? this.state.searchData.length
              : this.state.projects.length}{" "}
            of {this.state.projects.length} Projects
          </div>
        );
      case "project":
        return <div></div>;
    }
  };

  onAddClientClick = () => {
    this.setModal(true, "add-client", {
      tagOptions: this.props.tagOptions,
      closeButton: true,
      currentUser: this.props.currentUser,
      customClass: "sm-modal",
      clientsHandler: this.clientsHandler,
      backdropClickCheck: {
        title: "Discard New Client",
        text: "Are you sure that you want to discard this client?",
      },
    });
  };

  onEditClientClick = (client) => {
    this.setModal(true, "add-client", {
      closeButton: true,
      client: client,
      currentUser: this.props.currentUser,
      customClass: "sm-modal",
      clientsHandler: this.clientsHandler,
    });
  };

  onAddProjectClick = () => {
    this.setModal(true, "add-project", {
      clientOptions: this.state.clients,
      tagOptions: this.props.tagOptions,
      leadOptions: this.props.leadOptions,
      projectsHandler: this.projectsHandler,
      currentUser: this.props.currentUser,
      selectedClient: {
        value: this.state.client.id,
        label: this.state.client.name,
      },
      closeButton: true,
      customClass: "project-modal",
    });
  };

  projectsHandler = (action, project) => {
    var self = this;
    var project = project;
    // strip client name here because it's the client's page and the name messes with spacing on the cards
    // project.client_name = "";
    var newProjects = self.state.projects;
    if (action === "create") {
      newProjects.push(project);
    } else if (action === "delete" || action === "edit") {
      for (var i = 0; i < newProjects.length; i++) {
        if (newProjects[i].id === project.id) {
          if (action === "delete") {
            newProjects.splice(i, 1);
          } else if (action === "edit") {
            newProjects.splice(i, 1, project);
          }
          break;
        }
      }
    }
    var sorted = newProjects.sort(this.compare);
    var alpha = setAlphaGroups(sorted);
    self.setState(
      {
        project: project,
        projects: sorted,
        alphaGroups: alpha,
      },
      () => {
        if (action === "create") {
          this.loadProject(project.id);
        }
      }
    );
  };

  copyProject = (project) => {
    this.setModal(false, "", {});
    setTimeout(() => {
      this.setModal(true, "copy-project", {
        customClass: "taskTransitionModal",
        project: project,
        tagOptions: this.props.tagOptions,
        leadOptions: this.props.leadOptions,
        taskboard: false,
        projectsHandler: this.projectsHandler,
      });
    }, 300);
  };

  onEditProjectClick = () => {
    this.setModal(true, "edit-project", {
      active: this.state.active,
      tagOptions: this.props.tagOptions,
      closeButton: true,
      clientOptions: this.props.clients.map((client) => ({
        value: client.id,
        label: client.name,
      })),
      currentUser: this.props.currentUser,
      userOptions: this.props.leadOptions,
      project: this.state.project,
      projectHandler: this.projectsHandler,
      cardColor: this.state.card_color,
      customClass: "project-modal",
    });
  };

  render() {
    var showClearFilters =
      !this.state.project &&
      this.state.selectedUser.length + this.state.selectedTag.length > 0;
    var { currentView, direction } = this.state;
    var classNames = direction === "forward" ? "node-left" : "node-right";
    return (
      <div style={{ backgroundColor: "#f2f2f2", height: "calc(100vh - 64px)" }}>
        <ReactModal
          isShowing={this.state.modalOpen}
          page={this.state.currentModal}
          data={this.state.modalData}
          modalAction={this.setModal}
        />
        <CSSTransition
          unmountOnExit
          in={this.state.loading}
          timeout={300}
          classNames="node-fade"
        >
          <div className="page-loader">
            <div className="page-load-spinner" />
          </div>
        </CSSTransition>
        <div style={{ overflowX: "auto" }} id="clients-container">
          <section
            style={{
              width: "100%",
              marginRight: "8px",
              minWidth: "950px",
              maxWidth: "1062px",
            }}
            aria-label="Client Directory"
          >
            <button
              style={{
                display: "block",
                zIndex: "10",
                pointerEvents: showClearFilters ? "all" : "none",
                position: "absolute",
                transform: `translateY(${
                  this.state.currentView === "clients" ? "0px" : "28px"
                })`,
                transition: "opacity 0.5s, transform 0.5s 0.5s",
                top: "10px",
                right: "26px",
                opacity: showClearFilters ? "1" : "0",
              }}
              className="btn-flat"
              onClick={this.clearFilters}
              aria-hidden={showClearFilters ? "false" : "true"}
            >
              {" "}
              Clear All Filters{" "}
            </button>

            <SwitchTransition mode={"out-in"}>
              <CSSTransition
                timeout={{
                  enter: 300,
                  exit: 300,
                  appear: 1000,
                }}
                appear={true}
                key={currentView}
                classNames={"activity-fade"}
              >
                {!this.state.project ? (
                  <div style={{ padding: "32px" }}>
                    <PageNav
                      pageIcon={
                        currentView === "clients" ? (
                          <ClientIcon
                            width="22px"
                            height="22px"
                            color="#519acc"
                          />
                        ) : null
                      }
                      pageName={
                        currentView === "clients"
                          ? "Clients"
                          : currentView === "client_projects"
                          ? this.state.client.name
                          : this.state.project.name
                      }
                      searchKeyDownHandler={this.searchKeyDown}
                      clearFunction={this.clearFilters}
                      usedLetters={
                        currentView === "client_projects"
                          ? null
                          : Object.keys(this.state.alphaGroups)
                      }
                      checkboxes={[
                        {
                          name: "selectedStatus",
                          single: true,
                          objectTitle:
                            currentView === "client_projects"
                              ? "Projects"
                              : "Clients",
                          title: "Filter By Status",
                          handler: this.handleSingleSelectChange,
                          options:
                            currentView === "client_projects"
                              ? [
                                  {
                                    id: 1,
                                    name: "Active",
                                    count: this.state.projects.filter(
                                      (x) => x.active && x.pending === false
                                    ).length,
                                  },
                                  {
                                    id: 2,
                                    name: "Inactive",
                                    count: this.state.projects.filter(
                                      (x) => x.active === false
                                    ).length,
                                  },
                                  {
                                    id: 3,
                                    name: "Pending",
                                    count: this.state.projects.filter(
                                      (x) => x.pending
                                    ).length,
                                  },
                                  {
                                    id: 4,
                                    name: "All",
                                    count: this.state.projects.length,
                                  },
                                ]
                              : [
                                  {
                                    id: 1,
                                    name: "Active",
                                    count: this.props.clients.filter(
                                      (x) => x.active
                                    ).length,
                                  },
                                  {
                                    id: 2,
                                    name: "Inactive",
                                    count: this.props.clients.filter(
                                      (x) => x.active === false
                                    ).length,
                                  },
                                  {
                                    id: 4,
                                    name: "All",
                                    count: this.props.clients.length,
                                  },
                                ],
                          selected: this.state.selectedStatus,
                        },
                        {
                          name: "selectedUser",
                          title: "Lead",
                          handler: this.handleSelectChange,
                          options: this.props.leadOptions.map((x) => ({
                            id: x.value,
                            name: x.label,
                          })),
                          selected: this.state.selectedUser,
                        },
                        // {
                        //   name: "selectedStatus",
                        //   title: "Filter By Status",
                        //   handler: this.handleSelectChange,
                        //   options: [
                        //     { id: 1, name: "Active" },
                        //     { id: 2, name: "Inactive" },
                        //   ],
                        //   selected: this.state.selectedStatus,
                        // },
                        {
                          name: "selectedTag",
                          title: "Type",
                          handler: this.handleSelectChange,
                          options: this.props.tags,
                          selected: this.state.selectedTag,
                        },
                      ]}
                      scrollHandler={this.scrollIntoView}
                      addHandler={
                        currentView === "clients"
                          ? this.onAddClientClick
                          : this.onAddProjectClick
                      }
                      addText={
                        currentView === "clients"
                          ? "Add Client"
                          : currentView === "client_projects"
                          ? "Add Project"
                          : ""
                      }
                      search={this.state.search}
                      tags={this.props.tags}
                      updateSearchHandler={this.updateSearch}
                    />
                  </div>
                ) : (
                  <div style={{ padding: "32px" }}>
                    <PageDetail
                      link={{ text: "View Schedule", location: "/schedule" }}
                      startDate={this.state.project.start_date}
                      projectStatus={
                        this.state.project.project_status
                          ? this.state.project.project_status.aasm_state
                          : null
                      }
                      endDate={this.state.project.end_date}
                      detailTitles={[
                        this.state.project.client_name,
                        this.state.project.name,
                      ]}
                      tags={[this.state.project.tag]}
                      leads={this.state.project.leads}
                      clickHandler={this.onEditProjectClick}
                      projectHandler={this.projectHandler}
                    />
                  </div>
                )}
              </CSSTransition>
            </SwitchTransition>
            <div
              style={{
                width: "calc(100% + 40px)",
                position: "relative",
                // marginBottom: "20px",
                left: "-20px",
                height: "1px",
                backgroundColor: "lightgray",
              }}
            />
            <SwitchTransition mode={"out-in"}>
              <CSSTransition
                key={currentView}
                timeout={{
                  enter: 300,
                  exit: 300,
                  appear: 1000,
                }}
                appear={true}
                classNames={classNames}
              >
                {this.renderCurrentView()}
              </CSSTransition>
            </SwitchTransition>
            <SwitchTransition mode={"out-in"}>
              <CSSTransition
                key={currentView}
                timeout={{
                  enter: 300,
                  exit: 300,
                  appear: 1000,
                }}
                appear={true}
                classNames={"activity-fade"}
              >
                {this.renderResultsFooter()}
              </CSSTransition>
            </SwitchTransition>
          </section>
          <div
            role="region"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {/* client hours widget filler */}
            <div
              style={{
                width: "300px",
                maxHeight:
                  this.state.currentView !== "clients"
                    ? `calc(33.33% - 25px)`
                    : "0px",
                height: "calc(33.33% - 25px)",
                backgroundColor: "white",
                marginBottom:
                  this.state.currentView !== "clients" ? "8px" : "0px",
                borderRadius: "10px",
                overflow: "hidden",
                transition:
                  "max-height 0.2s, box-shadow 0.2s, margin-bottom 0.2s",
              }}
            >
              <SwitchTransition mode={"out-in"}>
                <CSSTransition
                  timeout={{ enter: 300, exit: 300, appear: 1000 }}
                  appear={true}
                  key={this.state.currentView}
                  classNames={"activity-fade"}
                >
                  <ProjectMembersBox
                    currentView={this.state.currentView}
                    projectMembers={this.state.projectMembers}
                  />
                </CSSTransition>
              </SwitchTransition>
            </div>
            <ActivityFeedContainer
              currentUser={this.props.currentUser}
              currentView={this.state.currentView}
              title={this.state.activityFeedTitle}
              activities={this.state.activityEvents}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ClientsContainer;
