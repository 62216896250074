import React from "react";

const syncit_logo_login = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="269"
      height="69"
      viewBox="0 0 112 29"
      fill="none"
    >
      <path
        d="M12.6435 24.7915C18.9621 24.7915 24.0844 19.5132 24.0844 13.0021C24.0844 6.49096 18.9621 1.21265 12.6435 1.21265C6.32489 1.21265 1.20264 6.49096 1.20264 13.0021C1.20264 19.5132 6.32489 24.7915 12.6435 24.7915Z"
        fill="url(#paint0_linear_5018_3526)"
      />
      <path
        d="M9.29947 10.372C10.3184 10.372 11.1444 9.52085 11.1444 8.47091C11.1444 7.42097 10.3184 6.56982 9.29947 6.56982C8.28057 6.56982 7.45459 7.42097 7.45459 8.47091C7.45459 9.52085 8.28057 10.372 9.29947 10.372Z"
        fill="white"
      />
      <path
        d="M15.9225 10.372C16.9414 10.372 17.7674 9.52085 17.7674 8.47091C17.7674 7.42097 16.9414 6.56982 15.9225 6.56982C14.9036 6.56982 14.0776 7.42097 14.0776 8.47091C14.0776 9.52085 14.9036 10.372 15.9225 10.372Z"
        fill="white"
      />
      <path
        d="M9.29947 19.4374C10.3184 19.4374 11.1444 18.5863 11.1444 17.5363C11.1444 16.4864 10.3184 15.6353 9.29947 15.6353C8.28057 15.6353 7.45459 16.4864 7.45459 17.5363C7.45459 18.5863 8.28057 19.4374 9.29947 19.4374Z"
        fill="white"
      />
      <path
        d="M15.9258 12.6997H15.9226C15.0207 12.6997 14.2896 13.4531 14.2896 14.3825V17.7547C14.2896 18.6841 15.0207 19.4375 15.9226 19.4375H15.9258C16.8277 19.4375 17.5588 18.6841 17.5588 17.7547V14.3825C17.5588 13.4531 16.8277 12.6997 15.9258 12.6997Z"
        fill="white"
      />
      <path
        d="M36.5912 21.9533C34.7333 21.9533 33.2317 21.5178 32.0866 20.6468C31.5146 20.2082 31.0348 19.6547 30.6762 19.0196C30.3177 18.3846 30.0878 17.6812 30.0005 16.9521L33.8043 16.3609C33.9312 17.034 34.2988 17.6334 34.8343 18.0403C35.4292 18.4648 36.142 18.6783 36.865 18.6483C37.4133 18.6831 37.9587 18.5423 38.4263 18.2452C38.6016 18.1222 38.7438 17.9553 38.8395 17.7603C38.9353 17.5653 38.9815 17.3485 38.9739 17.1301C38.9862 16.8688 38.9076 16.6115 38.7523 16.4046C38.4761 16.141 38.1397 15.9539 37.7744 15.8604C37.2681 15.6835 36.4826 15.4484 35.4178 15.1551C34.4181 14.9144 33.449 14.5548 32.5299 14.0836C31.8928 13.7699 31.3517 13.2819 30.9653 12.6729C30.6318 12.0654 30.4661 11.3754 30.4862 10.6778C30.4647 9.77282 30.7189 8.88366 31.213 8.13517C31.7261 7.39112 32.4353 6.81414 33.2567 6.47256C34.2439 6.06174 35.302 5.86287 36.3663 5.88812C37.4153 5.86804 38.4584 6.05384 39.44 6.43561C40.2781 6.7625 41.0234 7.30063 41.6076 8.00082C42.1698 8.6853 42.5275 9.52292 42.6376 10.4124L38.837 11.1178C38.8144 10.8552 38.7393 10.6003 38.6164 10.3691C38.4936 10.1379 38.3257 9.93538 38.1232 9.77427C37.6353 9.4168 37.0559 9.21582 36.4576 9.19655C35.893 9.12739 35.3209 9.22525 34.8083 9.47869C34.622 9.56786 34.4643 9.71014 34.354 9.8887C34.2437 10.0673 34.1853 10.2747 34.1857 10.4863C34.1819 10.6114 34.2038 10.7358 34.2498 10.8516C34.2959 10.9673 34.3651 11.0718 34.453 11.1581C34.783 11.4154 35.1599 11.6016 35.5612 11.7056C36.1218 11.8937 36.9758 12.1378 38.1232 12.4378C39.0639 12.6675 39.972 13.0209 40.8253 13.4891C41.4366 13.8215 41.9491 14.3183 42.3084 14.9267C42.6324 15.5448 42.7931 16.2397 42.7745 16.942C42.8053 17.6443 42.6735 18.344 42.3902 18.9835C42.1068 19.623 41.6799 20.1839 41.1448 20.6199C40.0452 21.5088 38.5274 21.9533 36.5912 21.9533Z"
        fill="#242424"
      />
      <path
        d="M58.9121 6.30786L50.5711 28.2947H47.1225L49.5541 21.4091L43.5957 6.30786H47.4517L51.3338 16.6631L55.193 6.30786H58.9121Z"
        fill="#242424"
      />
      <path
        d="M74.6658 12.9886V21.53H70.8913V14.3422C70.8913 14.0063 70.875 13.5495 70.8391 12.9987C70.7965 12.4434 70.6814 11.8966 70.4968 11.373C70.311 10.8375 69.9863 10.3647 69.5581 10.006C69.0262 9.60753 68.3761 9.41273 67.7197 9.45511C67.3724 9.46034 67.0273 9.51228 66.693 9.60962C66.3106 9.7241 65.9599 9.93012 65.6695 10.2108C65.3079 10.5729 65.0372 11.0202 64.8807 11.5141C64.652 12.2504 64.5483 13.022 64.5743 13.7947V21.52H60.7998V6.30791H64.1082V7.88319C64.4554 7.47997 64.8533 7.1264 65.2914 6.83188C66.373 6.14652 67.6277 5.80751 68.8964 5.85782C69.8737 5.81689 70.8457 6.02574 71.7257 6.46577C72.4078 6.82518 72.9933 7.35241 73.4304 8.00075C73.8222 8.59247 74.1152 9.24751 74.2974 9.93878C74.4611 10.5395 74.5702 11.1546 74.6234 11.7761C74.6429 12.3303 74.6658 12.7367 74.6658 12.9886Z"
        fill="#242424"
      />
      <path
        d="M84.457 21.9532C83.0702 21.9961 81.7005 21.6289 80.5097 20.8951C79.4243 20.2009 78.5518 19.204 77.9934 18.02C77.3951 16.742 77.0949 15.3376 77.1166 13.9189C77.0921 12.4874 77.4041 11.0711 78.026 9.79089C78.6046 8.60858 79.4933 7.61714 80.5912 6.92918C81.7748 6.21195 83.13 5.85121 84.5026 5.88795C86.0663 5.82917 87.6054 6.3012 88.8834 7.23148C90.0633 8.13378 90.8717 9.45943 91.1488 10.9463L87.4297 11.954C87.2503 11.2278 86.8492 10.5802 86.2856 10.1066C85.7668 9.67002 85.117 9.43258 84.4472 9.43486C83.759 9.40453 83.0804 9.61005 82.5176 10.0193C82.0057 10.4269 81.6215 10.9805 81.4126 11.6114C81.1656 12.3431 81.0453 13.114 81.0573 13.8886C81.0047 15.0361 81.3072 16.1711 81.9211 17.1299C82.4882 17.9528 83.3357 18.3626 84.457 18.3626C85.1443 18.4163 85.8269 18.2097 86.3768 17.7815C86.8469 17.3253 87.1853 16.7441 87.3547 16.1021L91.1553 16.9217C90.8134 18.4155 89.9707 19.737 88.7758 20.6533C87.5088 21.5528 85.9947 22.0085 84.457 21.9532Z"
        fill="#242424"
      />
      <path d="M93.7563 21.53V6.30786H97.4755V21.53H93.7563Z" fill="#242424" />
      <path
        d="M106.175 9.27038V14.8494C106.175 15.4405 106.175 15.9746 106.195 16.4482C106.189 16.8456 106.285 17.2375 106.475 17.5834C106.647 17.8522 106.88 18.0727 107.155 18.2252C107.43 18.3777 107.737 18.4572 108.05 18.4567C108.849 18.5007 109.65 18.4625 110.442 18.3425V21.5301C109.453 21.7227 108.447 21.8093 107.44 21.7887C106.54 21.7817 105.648 21.6005 104.813 21.2546C104.064 20.9406 103.444 20.3699 103.056 19.639C102.703 18.9902 102.506 18.2633 102.482 17.5196C102.463 16.8008 102.456 15.9847 102.456 15.0744V9.27038H99.9399V6.30791H102.456V2.08252H106.175V6.30791H110.442V9.27038H106.175Z"
        fill="#242424"
      />
      <path
        d="M95.6144 4.76618C96.6387 4.76618 97.4691 3.91052 97.4691 2.85501C97.4691 1.79951 96.6387 0.943848 95.6144 0.943848C94.5901 0.943848 93.7598 1.79951 93.7598 2.85501C93.7598 3.91052 94.5901 4.76618 95.6144 4.76618Z"
        fill="#242424"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5018_3526"
          x1="12.6435"
          y1="1.21265"
          x2="12.6435"
          y2="24.7915"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default syncit_logo_login;
