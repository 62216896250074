import React from "react";

const ExitIcon = ({ height, width, svgStyles, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 26"
      fill="none"
      {...svgStyles}
    >
      <g clipPath="url(#clip0_1856_3345)">
        <path
          d="M12.5 0.5C5.59552 0.5 0 6.09552 0 13C0 19.9045 5.59552 25.5 12.5 25.5C19.4045 25.5 25 19.9045 25 13C25 6.09552 19.4045 0.5 12.5 0.5Z"
          fill={color}
        />
        <path
          d="M17.4258 15.8627C17.9754 16.4122 18.0753 17.3265 17.5557 17.906C17.271 18.2258 16.8763 18.3856 16.4816 18.3856C16.1169 18.3856 15.7472 18.2458 15.4674 17.966L12.4948 14.9934L12.4598 15.0283L9.51716 17.966C8.95761 18.5255 8.04834 18.5255 7.48379 17.966C6.92923 17.4064 6.92923 16.4972 7.48379 15.9326L10.4114 13.01C10.4114 13.01 10.4214 12.985 10.4114 12.98L7.57871 10.1422C7.02416 9.59268 6.92424 8.68341 7.44382 8.09888C7.73359 7.77913 8.12328 7.61926 8.51796 7.61926C8.88766 7.61926 9.25237 7.75915 9.53715 8.03893L12.5098 11.0115L15.4824 8.03893C16.0469 7.48437 16.9512 7.48437 17.5158 8.03893C18.0753 8.60348 18.0753 9.50775 17.5158 10.0723L14.5881 13C14.5881 13 14.5881 13.0199 14.5881 13.0299L17.4258 15.8627Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1856_3345">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

ExitIcon.defaultProps = {
  height: "25",
  width: "26",
  svgStyles: {},
  color: "#519acc",
};

export default ExitIcon;
