import React from "react";
import styled from "styled-components";

class ToolTipCircle extends React.Component {
  constructor(props) {
    super(props);
    this.circleMouseEnter = this.circleMouseEnter.bind(this);
    this.circleMouseLeave = this.circleMouseLeave.bind(this);
  }

  circleMouseEnter(e) {
    const rect = e.target.getBoundingClientRect();
    const popUpElem = document.createElement("div");
    const triElem = document.createElement("div");
    const borderElem = document.createElement("div");
    popUpElem.setAttribute("id", `${e.target.dataset.id}-pop-up`);
    popUpElem.setAttribute("class", `time-bar-tt`);
    popUpElem.style.top = `${rect.top - 35}px`;
    popUpElem.innerHTML = `${this.props.toolTipText}`;
    popUpElem.style.color = `black`;
    popUpElem.style.fontFamily = `Arial`;
    popUpElem.style.fontSize = `13px`;
    popUpElem.style.border = "1px solid #519acc";
    popUpElem.style.zIndex = 10000;
    const element = document.querySelector("body");
    popUpElem.append(triElem);
    popUpElem.append(borderElem);
    element.appendChild(popUpElem);
    // need to set left after element is appended to parent element
    popUpElem.style.left = `${rect.left + (rect.width / 2 - 30)}px`;
  }

  circleMouseLeave(e) {
    const popUpElem = document.getElementById(`${e.target.dataset.id}-pop-up`);
    const element = document.querySelector("body");
    if (popUpElem) {
      element.removeChild(popUpElem);
    }
  }

  componentWillUnmount() {
    const elems = document.getElementsByClassName("time-bar-tt");
    if (elems.length >= 1) {
      const body = document.querySelector("body");
      for (const item of elems) {
        body.removeChild(item);
      }
    }
  }

  render() {
    const id = `circle-${Math.random()}`;
    return (
      <Circle
        ref={this.circleRef}
        style={this.props.style}
        id={id}
        data-id={id}
        onMouseEnter={this.circleMouseEnter}
        onMouseLeave={this.circleMouseLeave}
      >
        {this.props.children}
      </Circle>
    );
  }
}

ToolTipCircle.defaultProps = {
  style: {
    color: "white",
    backgroundColor: "white",
  },
};

const Circle = styled.div`
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: ${(props) => props.style.color};
  background-color: ${(props) => props.style.backgroundColor};
`;

export default ToolTipCircle;
