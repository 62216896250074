import React from "react";

export default function AnnexRoomList() {
  return (
    <div>
      <h4>Annex Rooms</h4>
      <ul>
        <li>Lego Room</li>
        <li>Chinook Room</li>
        <li>Shasta Room</li>
        <li>Orange Room</li>
        <li>Wasco Room</li>
      </ul>
    </div>
  );
}
