import React from "react";
import axios from "axios";
import Select from "react-select";

class EditFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      selectedUsers: [],
      defaultValues: [],
      options: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteFilter = this.deleteFilter.bind(this);
    this.getSelectTheme = this.getSelectTheme.bind(this);
    this.selectOnChange = this.selectOnChange.bind(this);
    this.customControl = (props) => {
      return (
        <div>
          <p className="custom-select-input">{props.selectProps.inputValue}</p>
          <components.Control {...props} />
        </div>
      );
    };
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };

    axios
      .get("/api/v1/filter_users", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const options = [];
        const defaultValues = [];
        res.data.map((user) => {
          options.push({ value: user.id, label: user.name });
          this.props.filter.user_ids.map((selectedUser) => {
            if (selectedUser === user.id) {
              defaultValues.push({ value: user.id, label: user.name });
            }
          });
        });
        this.setState({
          options,
          users: res.data,
          selectedUsers: defaultValues,
        });
      })
      .catch((err) => console.error(err));
  }

  handleSubmit(e) {
    e.preventDefault();
    const name = this.state.filterName;
    const users = this.state.selectedUsers.map((user) => user.value);
    users.unshift(0);
    axios
      .patch(`api/v1/filters/${this.props.filter.id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        filter: { name, user_id: this.props.userId, user_ids: users },
      })
      .then((res) => {
        M.toast({
          html: `${res.data.name} updated!`,
          displayLength: 3000,
          classes: "green",
        });
        this.props.refreshFilters();
        this.props.modalAction(false, "", {});
      })
      .catch((err) => console.error(err));
  }

  deleteFilter() {
    if (confirm("Are you sure you want to delete this filter?") === true) {
      axios
        .delete(`api/v1/filters/${this.props.filter.id}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          M.toast({
            html: `Filter deleted`,
            displayLength: 3000,
            classes: "green",
          });
          this.props.refreshFilters();
          this.props.modalAction(false, "", {});
        })
        .catch((err) => console.error(err));
    }
  }

  getSelectTheme(theme) {
    return {
      ...theme,
      colors: { ...theme.colors, primary: "#2bbbad", neutral0: "#fafafa" },
    };
  }

  selectOnChange(e) {
    this.setState({ selectedUsers: e });
  }

  render() {
    return (
      <div className="modal-content">
        <div className="flex-container flex-al-center flex-j-start">
          <img
            style={{ marginRight: "15px" }}
            className="icon-size"
            alt="project icon"
            src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/syncit/Icon_Users_1.svg"
          />
          <h4>Edit an existing filter</h4>
          <button
            className="s1 material-icons inline-icon red-text"
            id="filter_clear"
            onClick={this.deleteFilter}
          >
            delete
          </button>
        </div>
        <form
          className="new_filter"
          id="new_filter"
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <div
            className="input-field"
            style={{ width: "50%", marginTop: "30px" }}
          >
            <input
              required="required"
              className="browser-default text-input-v2"
              type="text"
              onChange={this.handleInputChange}
              name="filter[name]"
              id="filter_name"
              aria-describedby="character-count"
            />
            <label id="name" htmlFor="filters">
              Filter Name
              <span style={{ color: "#519acc" }}>*</span>
            </label>
            <p
              id="character-count"
              aria-live="polite"
              className="input-counter"
            >{`${this.state.filterName.length}/50`}</p>
          </div>
          <div
            className="input-field"
            style={{
              margin: "40px 0 15px",
              minHeight: `${this.state.selectedUsers.length * 10 + 50}px`,
              transition: "min-height .5s",
            }}
          >
            <label className="select-labels" htmlFor="filter_filters">
              Users
              <span style={{ color: "#519acc" }}>*</span>
            </label>
            <Select
              isMulti
              classNamePrefix="react-multi-select"
              menuShouldScrollIntoView
              isClearable={false}
              blurInputOnSelect
              menuPlacement="auto"
              placeholder="Search for users"
              components={{
                Control: this.customControl,
                Input: this.labelInput,
                DropdownIndicator: this.DropdownIndicator,
              }}
              aria-label="Tag dropdown for edit project form"
              closeMenuOnSelect={false}
              options={options}
              onChange={this.handleSelectChange}
              value={this.state.selectedUsers}
              styles={MultiSelectStyles}
            />
          </div>
          <div className="new-modal-footer">
            <div className="row center">
              <div className="col s12 input-field">
                <input
                  type="submit"
                  name="commit"
                  onChange
                  value="Create Filter"
                  className="common-button-submit white-text modal-btn"
                  data-disable-with="Create Filter"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
export default EditFilter;
