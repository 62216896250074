import React from "react";
import moment from "moment";
import axios from "axios";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";

class ProjectAddTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      singleBooking: true,
      startDate: this.props.data.date ? moment(this.props.data.date) : moment(),
      endDate: this.props.data.date ? moment(this.props.data.date) : moment(),
      startPickerFocus: false,
      endPickerFocus: false,
      hours: "",
      datePickerOverlay: false,
      projectConfirmation: true,
      days: [],
    };
    this.renderBookingType = this.renderBookingType.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.onStartFocusChange = this.onStartFocusChange.bind(this);
    this.onEndDateChange = this.onEndDateChange.bind(this);
    this.onEndFocusChange = this.onEndFocusChange.bind(this);
    this.singleBookingClick = this.singleBookingClick.bind(this);
    this.recurringBookingClick = this.recurringBookingClick.bind(this);
    this.setHourClick = this.setHourClick.bind(this);
    this.endPickerClick = this.endPickerClick.bind(this);
    this.startPickerClick = this.startPickerClick.bind(this);
    this.dayCheckBoxClick = this.dayCheckBoxClick.bind(this);
    this.handleProjectConfirmation = this.handleProjectConfirmation.bind(this);
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    this.refs.submit_button.disabled = true;
    const notes = this.refs.task_notes.value ? this.refs.task_notes.value : "";
    const { projectId } = this.props.data;
    if (this.state.singleBooking) {
      axios
        .post(`/api/v1/tasks`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          hours: this.state.hours,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
          notes,
          project_id: projectId,
          user_id: this.props.data.userId,
          project_confirmation: this.state.projectConfirmation,
        })
        .then((res) => {
          M.toast({
            html: `Booking created!`,
            displayLength: 3000,
            classes: "green",
          });
          this.props.data.projectName
            ? this.props.refreshTasks()
            : this.props.refreshUsers();
          this.props.modalAction(false, "", {});
        })
        .catch((err) => {
          M.toast({
            html: `Server Error`,
            displayLength: 3000,
            classes: "red",
          });
          console.error(err);
        });
    } else {
      axios
        .post(`/api/v1/multitasks`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          hours: this.state.hours,
          recurring_start: this.state.startDate,
          recurring_end: this.state.endDate,
          notes,
          project_id: projectId,
          user_id: this.props.data.userId,
          days: this.state.days,
        })
        .then((res) => {
          M.toast({
            html: `Recurring booking created!`,
            displayLength: 3000,
            classes: "green",
          });
          this.props.data.projectName
            ? this.props.refreshTasks()
            : this.props.refreshUsers();
          this.props.modalAction(false, "", {});
        })
        .catch((err) => {
          M.toast({
            html: `Server Error`,
            displayLength: 3000,
            classes: "red",
          });
          console.error(err);
        });
    }
  }

  setHourClick(e) {
    this.setState({ hours: e.target.value });
  }

  startPickerClick() {
    this.setState({ startPickerFocus: true, datePickerOverlay: true });
  }

  endPickerClick() {
    this.setState({ endPickerFocus: true, datePickerOverlay: true });
  }

  dayCheckBoxClick(e) {
    const { days } = this.state;
    const value = parseInt(e.target.value);
    if (e.target.checked) {
      days.push(value);
      this.setState({
        days,
      });
    } else {
      days.splice(days.indexOf(value), 1);
      this.setState({
        days,
      });
    }
  }

  handleProjectConfirmation() {
    this.setState({
      projectConfirmation: !this.state.projectConfirmation,
    });
  }

  renderBookingType() {
    if (this.state.singleBooking) {
      return (
        <div id="new_task_form">
          <p>Single Booking</p>
          <form className="new_task" id="new_task" onSubmit={this.handleSubmit}>
            <label htmlFor="task_hours">Hours</label>
            <p>
              <label className="task-label" htmlFor="task_hours_1">
                <input
                  required
                  type="radio"
                  value="1"
                  name="task[hours]"
                  id="task_hours_1"
                  onClick={this.setHourClick}
                />
                <span>1</span>
              </label>
              <label className="task-label" htmlFor="task_hours_2">
                <input
                  type="radio"
                  value="2"
                  name="task[hours]"
                  id="task_hours_2"
                  onClick={this.setHourClick}
                />
                <span>2</span>
              </label>
              <label className="task-label" htmlFor="task_hours_3">
                <input
                  type="radio"
                  value="3"
                  name="task[hours]"
                  id="task_hours_3"
                  onClick={this.setHourClick}
                />
                <span>3</span>
              </label>
              <label className="task-label" htmlFor="task_hours_4">
                <input
                  type="radio"
                  value="4"
                  name="task[hours]"
                  id="task_hours_4"
                  onClick={this.setHourClick}
                />
                <span>4</span>
              </label>
              <label className="task-label" htmlFor="task_hours_5">
                <input
                  type="radio"
                  value="5"
                  name="task[hours]"
                  id="task_hours_5"
                  onClick={this.setHourClick}
                />
                <span>5</span>
              </label>
              <label className="task-label" htmlFor="task_hours_6">
                <input
                  type="radio"
                  value="6"
                  name="task[hours]"
                  id="task_hours_6"
                  onClick={this.setHourClick}
                />
                <span>6</span>
              </label>
              <label className="task-label" htmlFor="task_hours_7">
                <input
                  type="radio"
                  value="7"
                  name="task[hours]"
                  id="task_hours_7"
                  onClick={this.setHourClick}
                />
                <span>7</span>
              </label>
              <label className="task-label" htmlFor="task_hours_8">
                <input
                  type="radio"
                  value="8"
                  name="task[hours]"
                  id="task_hours_8"
                  onClick={this.setHourClick}
                />
                <span>8</span>
              </label>
            </p>
            <div className="row datepicker-row">
              <div className="col s6">
                <label
                  htmlFor="task_start_date"
                  data-error="Add date"
                  data-success="right"
                  className=""
                  ref="task_start_date_label"
                >
                  Start date
                </label>
                <input
                  className="startPicker"
                  onClick={this.startPickerClick}
                  defaultValue={moment(this.state.startDate).format(
                    "DD MMM, YY"
                  )}
                  required="required"
                  type="text"
                  name="task[start_date]"
                  id="task_start_date"
                  ref="task_start_date"
                />
              </div>
              <div className="col s6">
                <label
                  htmlFor="task_end_date"
                  data-error="Add date"
                  data-success="right"
                  className=""
                  ref="task_end_date_label"
                >
                  End date
                </label>
                <input
                  className="endPicker"
                  required
                  onClick={this.endPickerClick}
                  defaultValue={moment(this.state.endDate).format("DD MMM, YY")}
                  type="text"
                  name="task[end_date]"
                  id="task_end_date"
                  ref="task_end_date"
                />
              </div>
            </div>
            <div className="input-field">
              <textarea
                className="materialize-textarea"
                name="task[notes]"
                id="task_notes"
                ref="task_notes"
              />
              <label htmlFor="task_notes">Notes (optional)</label>
            </div>
            <div className="button-row row center">
              <div
                id="single_delete"
                style={{
                  display: "block",
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                }}
              >
                <p style={{ marginTop: "0px", marginBottom: "7px" }}>
                  Project confirmed?
                </p>
                <div className="switch" style={{ textAlign: "right" }}>
                  <label>
                    No
                    <input
                      type="checkbox"
                      defaultChecked
                      onChange={this.handleProjectConfirmation}
                      id="project_confirmation_switch"
                      ref="project_confirmation_switch"
                    />
                    <span className="lever" />
                    Yes
                  </label>
                </div>
              </div>
              <input
                type="submit"
                name="commit"
                value="Create Booking"
                className="common-button-submit white-text"
                id="create_task_button"
                ref="submit_button"
              />
            </div>
          </form>
        </div>
      );
    }
    return (
      <div id="multi-task-form">
        <p>Recurring Booking</p>
        <form
          className="new_multitask"
          id="new_multitask"
          onSubmit={this.handleSubmit}
        >
          <label htmlFor="multitask_hours">Hours</label>
          <p>
            <label className="task-label" htmlFor="multitask_hours_1">
              <input
                required="required"
                type="radio"
                value="1"
                name="multitask[hours]"
                id="multitask_hours_1"
                onClick={this.setHourClick}
              />
              <span>1</span>
            </label>
            <label className="task-label" htmlFor="multitask_hours_2">
              <input
                type="radio"
                value="2"
                name="multitask[hours]"
                id="multitask_hours_2"
                onClick={this.setHourClick}
              />
              <span>2</span>
            </label>
            <label className="task-label" htmlFor="multitask_hours_3">
              <input
                type="radio"
                value="3"
                name="multitask[hours]"
                id="multitask_hours_3"
                onClick={this.setHourClick}
              />
              <span>3</span>
            </label>
            <label className="task-label" htmlFor="multitask_hours_4">
              <input
                type="radio"
                value="4"
                name="multitask[hours]"
                id="multitask_hours_4"
                onClick={this.setHourClick}
              />
              <span>4</span>
            </label>
            <label className="task-label" htmlFor="multitask_hours_5">
              <input
                type="radio"
                value="5"
                name="multitask[hours]"
                id="multitask_hours_5"
                onClick={this.setHourClick}
              />
              <span>5</span>
            </label>
            <label className="task-label" htmlFor="multitask_hours_6">
              <input
                type="radio"
                value="6"
                name="multitask[hours]"
                id="multitask_hours_6"
                onClick={this.setHourClick}
              />
              <span>6</span>
            </label>
            <label className="task-label" htmlFor="multitask_hours_7">
              <input
                type="radio"
                value="7"
                name="multitask[hours]"
                id="multitask_hours_7"
                onClick={this.setHourClick}
              />
              <span>7</span>
            </label>
            <label className="task-label" htmlFor="multitask_hours_8">
              <input
                type="radio"
                value="8"
                name="multitask[hours]"
                id="multitask_hours_8"
                onClick={this.setHourClick}
              />
              <span>8</span>
            </label>
          </p>
          <label>Select days to repeat on</label>
          <p id="checkbox-container">
            <label className="task-label" htmlFor="multitask1">
              <input
                type="checkbox"
                name="multitask[days][]"
                id="multitask1"
                value="1"
                onClick={this.dayCheckBoxClick}
              />
              <span>Monday</span>
            </label>
            <label className="task-label" htmlFor="multitask2">
              <input
                type="checkbox"
                name="multitask[days][]"
                id="multitask2"
                value="2"
                onClick={this.dayCheckBoxClick}
              />
              <span>Tuesday</span>
            </label>
            <label className="task-label" htmlFor="multitask3">
              <input
                type="checkbox"
                name="multitask[days][]"
                id="multitask3"
                value="3"
                onClick={this.dayCheckBoxClick}
              />
              <span>Wednesday</span>
            </label>
            <label className="task-label" htmlFor="multitask4">
              <input
                type="checkbox"
                name="multitask[days][]"
                id="multitask4"
                value="4"
                onClick={this.dayCheckBoxClick}
              />
              <span>Thursday</span>
            </label>
            <label className="task-label" htmlFor="multitask5">
              <input
                type="checkbox"
                name="multitask[days][]"
                id="multitask5"
                value="5"
                onClick={this.dayCheckBoxClick}
              />
              <span>Friday</span>
            </label>
          </p>
          <div className="row datepicker-row">
            <div className="col s6">
              <label
                htmlFor="multitask_recurring_start"
                ref="multitask_recurring_start_label"
                className="active"
              >
                Select starting week
              </label>
              <input
                className="startPicker"
                onClick={this.startPickerClick}
                defaultValue={moment(this.state.startDate).format(
                  "DD MMM, YY"
                )}
                required="required"
                type="text"
                name="multitask[recurring_start]"
                id="multitask_recurring_start"
                ref="multitask_recurring_start"
              />
            </div>
            <div className="col s6">
              <label
                htmlFor="multitask_recurring_end"
                ref="multitask_recurring_end_label"
                className="active"
              >
                Select ending week
              </label>
              <input
                className="endPicker"
                required
                onClick={this.endPickerClick}
                defaultValue={moment(this.state.endDate).format("DD MMM, YY")}
                type="text"
                name="multitask[recurring_end]"
                id="multitask_recurring_end"
                ref="multitask_recurring_end"
              />
            </div>
          </div>
          <div className="input-field" id="mt-notes-box">
            {/* <div className="right task-dropdown"><span id="notes-mt-hide-button"><i className="material-icons">arrow_drop_down</i></span></div> */}
            <textarea
              className="materialize-textarea"
              name="multitask[notes]"
              id="multitask_notes"
              ref="task_notes"
            />
            <label htmlFor="multitask_notes">
              Notes (optional, will be added to all bookings in series)
            </label>
          </div>
          <div className="button-row row center">
            <input
              type="submit"
              name="commit"
              value="Create Booking"
              className="common-button-submit white-text"
              id="multitask_create_button"
              data-disable-with="Create Booking"
              ref="submit_button"
            />
          </div>
        </form>
      </div>
    );
  }

  onStartDateChange(date) {
    if (this.state.singleBooking) {
      if (
        !this.refs.task_end_date.value
        || moment(date).isBefore(this.state.endDate)
        || moment(date).isSame(this.state.endDate)
      ) {
        this.setState({ startDate: date });
        this.refs.task_start_date.value = moment(date).format("DD MMM, YY");
        this.refs.task_start_date_label.classList.add("active");
      } else {
        this.setState({ startDate: date, endDate: date });
        this.refs.task_start_date.value = moment(date).format("DD MMM, YY");
        this.refs.task_start_date_label.classList.add("active");
        this.refs.task_end_date.value = moment(date).format("DD MMM, YY");
        this.refs.task_end_date_label.classList.add("active");
        // M.toast({html: `Start date must be before end date.`, displayLength: 3000, classes: 'red' });
      }
    } else if (
      !this.refs.multitask_recurring_end.value
        || moment(date).isBefore(this.state.endDate)
        || moment(date).isSame(this.state.endDate)
    ) {
      this.setState({ startDate: date });
      this.refs.multitask_recurring_start.value = moment(date).format("DD MMM, YY");
      this.refs.multitask_recurring_start_label.classList.add("active");
    } else {
      this.setState({ startDate: date, endDate: date });
      this.refs.multitask_recurring_start.value = moment(date).format("DD MMM, YY");
      this.refs.multitask_recurring_start_label.classList.add("active");
      this.refs.multitask_recurring_end.value = moment(date).format("DD MMM, YY");
      this.refs.multitask_recurring_end_label.classList.add("active");
      // M.toast({html: `Start date must be before end date.`, displayLength: 3000, classes: 'red' });
    }
  }

  onStartFocusChange({ focused }) {
    this.setState({ startPickerFocus: focused, datePickerOverlay: focused });
  }

  onEndDateChange(date) {
    if (this.state.singleBooking) {
      if (
        !this.refs.task_start_date.value
        || moment(date).isAfter(this.state.startDate)
        || moment(date).isSame(this.state.startDate)
      ) {
        this.setState({ endDate: date });
        this.refs.task_end_date.value = moment(date).format("DD MMM, YY");
        this.refs.task_end_date_label.classList.add("active");
      } else {
        this.setState({ startDate: date, endDate: date });
        this.refs.task_start_date.value = moment(date).format("DD MMM, YY");
        this.refs.task_start_date_label.classList.add("active");
        this.refs.task_end_date.value = moment(date).format("DD MMM, YY");
        this.refs.task_end_date_label.classList.add("active");
        // M.toast({html: `End date must be after start date.`, displayLength: 3000, classes: 'red' });
      }
    } else if (
      !this.refs.multitask_recurring_start.value
        || moment(date).isAfter(this.state.startDate)
        || moment(date).isSame(this.state.startDate)
    ) {
      this.setState({ endDate: date });
      this.refs.multitask_recurring_end.value = moment(date).format("DD MMM, YY");
      this.refs.multitask_recurring_end_label.classList.add("active");
    } else {
      this.setState({ startDate: date, endDate: date });
      this.refs.multitask_recurring_start.value = moment(date).format("DD MMM, YY");
      this.refs.multitask_recurring_start_label.classList.add("active");
      this.refs.multitask_recurring_end.value = moment(date).format("DD MMM, YY");
      this.refs.multitask_recurring_end_label.classList.add("active");
      // M.toast({html: `End date must be after start date.`, displayLength: 3000, classes: 'red' });
    }
  }

  onEndFocusChange({ focused }) {
    this.setState({ endPickerFocus: focused, datePickerOverlay: focused });
  }

  singleBookingClick() {
    this.setState({ singleBooking: true });
  }

  recurringBookingClick() {
    this.setState({ singleBooking: false });
  }

  isDayBlocked(day) {
    return !!(moment(day).day() === 0 || moment(day).day() === 6);
  }

  render() {
    return (
      <div className="modal-content task-modal-content">
        <div
          className={`picker-overlay ${
            this.state.datePickerOverlay
              ? "picker-overlay-on"
              : "picker-overlay-off"
          }`}
        />
        <SingleDatePicker
          verticalHeight={568}
          date={this.state.startDate} // momentPropTypes.momentObj or null
          onDateChange={this.onStartDateChange}
          focused={this.state.startPickerFocus} // PropTypes.bool
          onFocusChange={this.onStartFocusChange} // PropTypes.func.isRequired
          numberOfMonths={12}
          orientation="vertical"
          disableScroll={false}
          daySize={50}
          isOutsideRange={this.isDayBlocked}
          id="start_date_picker" // PropTypes.string.isRequired,
          verticalSpacing={0}
        />
        <SingleDatePicker
          verticalHeight={568}
          date={this.state.endDate} // momentPropTypes.momentObj or null
          onDateChange={this.onEndDateChange} // PropTypes.func.isRequired
          focused={this.state.endPickerFocus} // PropTypes.bool
          onFocusChange={this.onEndFocusChange} // PropTypes.func.isRequired
          numberOfMonths={12}
          orientation="vertical"
          daySize={50}
          isOutsideRange={this.isDayBlocked}
          id="end_date_picker" // PropTypes.string.isRequired,
          verticalSpacing={0}
        />
        <h4
          className=" project-edit"
          id="modal_user_name_master"
          style={{ marginBottom: "-8px", paddingLeft: "0px" }}
        >
          {this.props.data.projectName
            ? `Add ${this.props.data.projectName}`
            : `Schedule ${this.props.data.userName}`}
        </h4>
        <div id="standard-task-check" className="margin-top">
          <label htmlFor="standard_task_show">
            <input
              type="radio"
              name="booking_type_task_show"
              id="standard_task_show"
              value="1"
              defaultChecked
              onClick={this.singleBookingClick}
            />
            <span style={{ marginRight: "15px" }}>Single Booking</span>
          </label>
        </div>
        <div id="multi-task-check" className="margin-top">
          <label htmlFor="multi_task_show">
            <input
              type="radio"
              name="booking_type_task_show"
              id="multi_task_show"
              value="1"
              onClick={this.recurringBookingClick}
            />
            <span>Recurring Booking</span>
          </label>
        </div>
        {this.renderBookingType()}
      </div>
    );
  }
}

export default ProjectAddTask;
