import React from "react";
import moment from "moment";

class UnavailablityListItem extends React.Component {
  componentDidMount() {
    $("#edit-availability-modal").modal();
  }

  editTask() {
    $("#edit-availability-modal").modal("open");
    const { taskId } = this.props;
    const url = `/api/v1/tasks/${taskId}`;
    $.ajax({
      url,
      dataType: "json",
      type: "GET",
      data: {},
      success(data) {
        const displayStart = moment(data.start_date).format("D MMMM, YYYY");
        const displayEnd = moment(data.end_date).format("D MMMM, YYYY");
        $("#temp_data_avail").val(data.id);
        $("#update_hours").val(data.hours);
        $("#update_start_date").val(displayStart);
        $("#update_end_date").val(displayEnd);
        $("#update_notes").val(data.notes);
        $(`input[name="hours"][value="${data.hours}"]`).prop(
          "checked",
          true
        );
        const $start = $("#update_start_date").pickadate();
        const start_picker = $start.pickadate("picker");
        start_picker.set("select", data.start_date, { format: "yyyy-mm-dd" });
        const $end = $("#update_end_date").pickadate();
        const end_picker = $end.pickadate("picker");
        end_picker.set("select", data.end_date, { format: "yyyy-mm-dd" });
        $("#multi_select").hide();
        $("#multi_delete").hide();
      },
      error: function (xhr, status, err) {
        console.error(this.props.url, status, err.toString());
      }.bind(this),
    });
  }

  render() {
    const id = this.props.taskId;
    const { day } = this.props;
    const { displayDate } = this.props;
    const { hours } = this.props;
    return (
      <li className="collection-item hover avail-item" onClick={this.editTask}>
        <div className="row list-row">
          <div className="col s8">
            {day}
            ,
            {displayDate}
          </div>
          <div className="col s4">
            {hours}
            {' '}
            hours
          </div>
        </div>
      </li>
    );
  }
}

export default UnavailablityListItem;
