import React from "react";
import Select, { components } from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import ProgressRing from "../common/progress_ring";
import RoundDropdown from "./round_dropdown";
import TimeWidget from "../common/time_widget";
import DollarSignIcon from "../icons/dollar_sign_icon";
import SingleSelectWrapper from "../common/single_select_wrapper";
import { roundInput } from "../utils";

class ProjectData extends React.Component {
  constructor(props) {
    super(props);
    this.typeArray = [
      { label: "Monthly Recurring", value: 1 },
      { label: "Nonrecurring", value: 2 },
      { label: "Non-Billable", value: 0 },
      { label: "Non-Billable w/ Budget", value: 3 },
    ];
    this.state = {
      data: {},
      hours: (Math.round(this.props.project.budgeted_hours * 4) / 4).toFixed(2),
      selectedSpan: { label: "This Week", value: 1 },
      selectedType: this.typeArray.find(
        (x) => x.value === this.props.project.invoicing_type
      ),
      color: this.props.project.card_color,
      rate: this.props.project.rate
        ? {
            label: `$${this.props.project.rate / 100}`,
            value: this.props.project.rate,
          }
        : { label: "$70", value: 7000 },
    };
    this.DropdownIndicator = function (props) {
      return (
        <components.DropdownIndicator {...props}>
          <RoundDropdown />
        </components.DropdownIndicator>
      );
    };

    this.getData = this.getData.bind(this);
    this.handleSpanChange = this.handleSpanChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.submitData = this.submitData.bind(this);
    this.onPaste = this.onPaste.bind(this);
    this.roundInput = this.roundInput.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
  }

  submitData() {
    this.setState({ loading: true });
    const project = {};
    let billable = null;
    if (this.props.project.budgeted_hours != this.state.hours) {
      project.budgeted_hours = this.state.hours;
    }
    if (this.state.selectedType.value === 0 && this.props.billable) {
      project.billable = false;
      project.invoicing_type = this.state.selectedType.value;
      billable = false;
    } else if (this.state.selectedType.value === 3) {
      project.billable = false;
      project.invoicing_type = this.state.selectedType.value;
      billable = true;
    } else if (this.state.selectedType.value === 2) {
      project.billable = true;
      project.invoicing_type = this.state.selectedType.value;
      billable = true;
    } else if (this.props.project.type != this.state.selectedType.value) {
      project.invoicing_type = this.state.selectedType.value;
      project.billable = true;
      billable = true;
    } else if (this.state.selectedType.value !== 0 && !this.props.billable) {
      project.billable = true;
      project.invoicing_type = this.state.selectedType.value;
      billable = true;
    }
    if (this.props.project.card_color != this.state.color) {
      project.card_color = this.state.color;
    }
    axios
      .patch(`/projects/${this.props.project.id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        project,
      })
      .then((res) => {
        if (billable === true || billable === false) {
          this.props.setBillable(billable);
        }
        this.getData();
        this.setState({ loading: false });
        M.toast({
          html: "<span aria-live='assertive'>Project Updated</span>",
          displayLength: 3000,
          classes: "green",
        });
      })
      .catch((err) => {
        M.toast({ html: err.response.data, classes: "red" });
        console.error(err);
      });
  }

  getData() {
    axios
      .get(
        `/projects/${this.props.project.id}/project_detail_data.json?scope=${this.state.selectedSpan.value}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch((err) => console.error(err));
  }

  onPaste(e) {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("Text");
    const num = parseFloat(pastedData);
    if (num && num < 2000) {
      this.setState({ hours: num.toFixed(2) });
    }
  }

  roundInput(e) {
    let input = parseFloat(e.target.value);
    if (input !== this.state.hours) {
      let value;
      if (input) {
        if (input < 0) {
          input = Math.abs(input);
        }
        if (input <= 0.12) {
          value = "0.25";
        } else {
          value = (Math.round(input * 4) / 4).toFixed(2);
        }
      } else {
        value = "0.00";
      }
      this.setState({ hours: value }, () => {
        this.submitData();
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedSpan != prevState.selectedSpan) {
      this.getData();
    }
  }

  handleInputChange(e) {
    let input = e.target.value;
    if (input.length === 0 || (input.length === 1 && input === ".")) {
      input = "";
    } else if (input.length === 5 && !input.includes(".")) {
      if (input[4] !== "." && isNaN(input[4])) {
        e.preventDefault();
      } else if (!isNaN(input[4])) {
        input = `${input.slice(0, 4)}.${input[4]}`;
      }
    } else if (input.length > 7) {
      input = input.slice(0, input.length - 1);
    } else if (input.split(".")[1] && input.split(".")[1].length > 2) {
      input = input.slice(0, input.length - 1);
    }
    this.setState({ hours: input });
  }

  handleColorChange(e) {
    this.setState({ color: e.hex }, () => {
      this.submitData();
    });
  }

  handleSpanChange(e) {
    this.setState({ selectedSpan: e });
  }

  handleTypeChange(e) {
    if (e.value === 0 && this.props.project.booked_hours >= 1) {
      Swal.fire({
        title: "This Project has past billable entries",
        text: "This action will set those entries to non-billable, are you sure you wanna do that?",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.setState({ selectedType: e }, () => {
            this.submitData();
          });
        }
      });
    } else {
      this.setState({ selectedType: e }, () => {
        this.submitData();
      });
    }
  }

  componentDidMount() {
    this.getData();
  }

  render() {
    const { rate } = this.props;
    const hours = parseFloat(this.state.hours);
    const hoursBooked = this.state.data.hours_booked
      ? this.state.data.hours_booked
      : 0;
    const hoursRemaining = hours - hoursBooked;
    const percent = hoursBooked > 0 ? (hoursBooked / hours) * 100 : 0;
    const { billable } = this.props;
    const totalBudget =
      billable && this.state.selectedType.value === 3
        ? "n/a"
        : hours > 0 && billable
        ? (hours * (rate.value / 100))
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "n/a";
    return (
      <div
        className="project_data"
        style={{ display: "flex", flexWrap: "wrap", padding: "10px 20px 30px" }}
      >
        <div
          style={{ width: "100%", marginBottom: "20px" }}
          className="input-field"
        >
          <p style={{ fontSize: "15px", margin: "0 0 2px 0" }}>Budget Type</p>
          <SingleSelectWrapper
            options={this.typeArray}
            onChange={this.handleTypeChange}
            isDisabled={this.props.timeOffProject}
            blurInputOnSelect
            value={this.state.selectedType}
            ariaLabel={`Project type dropdown, ${this.state.selectedType.label} selected, press down to open menu`}
            // className="react-select__container"
            customStyles={{
              container: (styles) => ({
                ...styles,
                width: "185px",
                opacity: this.props.timeOffProject ? "0.5" : "1",
              }),
              menu: (styles) => ({ ...styles, width: "225px" }),
              valueContainer: (styles) => ({
                ...styles,
                height: "35px",
                marginRight: "-32px",
                fontSize: "14px",
              }),
            }}
          />
        </div>
        <div style={{ marginRight: "40px", marginTop: "28px" }}>
          <ProgressRing
            label="Budget Used"
            percent={percent}
            hidden={this.state.hours <= 0}
            noBudget={!this.props.billable}
          />
        </div>
        <div style={{ marginTop: "0px", width: "231px" }}>
          <div
            aria-hidden={!billable}
            style={{ display: "flex", opacity: billable ? "1" : "0.4" }}
          >
            <div>
              <p style={{ margin: "0 0 2px" }}>Budget Hours:</p>

              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  className="browser-default text-input-v2"
                  name="hours"
                  type="number"
                  value={billable ? this.state.hours : "0.00"}
                  onPaste={this.onPaste}
                  onBlur={this.roundInput}
                  onChange={this.handleInputChange}
                  min={0}
                  max={1000000}
                  step={0.25}
                  style={{ height: "40px", margin: 0 }}
                  disabled={!billable}
                />
              </div>
            </div>
            <div
              className="rate-select-container"
              aria-hidden={billable && this.state.selectedType.value === 3}
              style={{
                marginLeft: "20px",
                opacity:
                  billable && this.state.selectedType.value === 3 ? "0.4" : "1",
              }}
            >
              <p style={{ fontWeight: "400", margin: "0 0 2px" }}>Rate:</p>
              <SingleSelectWrapper
                options={[
                  { label: "$63", value: 6300 },
                  { label: "$65", value: 6500 },
                  { label: "$68", value: 6800 },
                  { label: "$70", value: 7000 },
                  { label: "$75", value: 7500 },
                ]}
                onChange={this.props.handleRateChange}
                isDisabled={
                  !billable || (billable && this.state.selectedType.value === 3)
                }
                name="project_rate"
                placeholder="Select Rate"
                blurInputOnSelect
                value={rate}
                ariaLabel={`Project rate dropdown, ${rate.label} selected, press down to open menu`}
                menuPlacement="top"
                className="react-select__container"
                customStyles={{
                  container: (styles) => ({ ...styles, width: "90px" }),
                  menu: (styles) => ({ ...styles, width: "90px" }),
                }}
              />
            </div>
          </div>
          <div
            style={{ margin: "15px 0 15px" }}
            className="flex-container flex-al-center grey-text text-darken-2"
          >
            <div
              style={{
                border: "1px solid #d1d1d1",
                borderRadius: "2px",
                backgroundColor: "#519acc",
                height: "20px",
                marginRight: "10px",
                width: "20px",
              }}
            />
            <p style={{ margin: "0" }}>
              {" "}
              {hoursBooked.toFixed(2)} Hours Logged{" "}
            </p>
          </div>
          <div
            aria-hidden={!(hours > 0 && billable)}
            style={{
              margin: "0 0 15px",
              opacity: hours > 0 && billable ? "1" : "0.4",
            }}
            className="flex-container flex-al-center grey-text text-darken-2"
          >
            <div
              style={{
                backgroundColor: "#d1d1d1",
                height: "20px",
                width: "20px",
                marginRight: "10px",
              }}
            />
            <p
              style={{
                margin: "0",
                whiteSpace: "nowrap",
                color: hoursRemaining < 0 && billable ? "red" : "#313131",
              }}
            >
              {hours > 0 && billable ? hoursRemaining.toFixed(2) : "0.00"} Hours
              Remaining
            </p>
          </div>
          <div
            aria-hidden={!(hours > 0 && billable)}
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
              opacity: hours > 0 && billable ? "1" : "0.4",
            }}
          >
            <DollarSignIcon
              aria-hidden
              svgStyles={{ marginLeft: "-8px", marginRight: "4px" }}
              width={35}
              height={35}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p
                className="manrope"
                style={{
                  fontSize: "16px",
                  color: "#313131",
                  fontWeight: "700",
                  margin: "0",
                }}
              >
                Total Budget:
              </p>
              <span
                role="text"
                aria-label={totalBudget === "n/a" ? "n/a" : `$${totalBudget}`}
                style={{
                  fontSize: "20px",
                  color: "#313131",
                  fontWeight: "600",
                }}
              >
                {totalBudget}
              </span>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <TimeWidget
            object="Daily"
            projectId={this.props.project.id}
            scope={this.state.selectedSpan}
          />
        </div>
      </div>
    );
  }
}

export default ProjectData;
