import React from "react";

class NoTasksRow extends React.Component {
  constructor(props) {
    super(props);

    this.addTask = this.addTask.bind(this);
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragDrop = this.onDragDrop.bind(this);
  }

  addTask() {
    const data = {
      userName: this.props.userName,
      userId: this.props.userId,
      date: this.props.date,
      projectId: this.props.id,
    };
    this.props.modalAction(true, "project-add-task", data);
  }

  onDragEnter(e) {
    e.target.classList.add("add-task-dash-drag-enter");
  }

  onDragLeave(e) {
    e.target.classList.remove("add-task-dash-drag-enter");
  }

  onDragOver(e) {
    e.preventDefault();
  }

  onDragDrop(e) {
    e.preventDefault();
    e.target.classList.remove("add-task-dash-drag-enter");
    const data = e.dataTransfer.getData("text");
    const newData = {
      userName: this.props.userName,
      userId: this.props.userId,
      date: this.props.date,
    };
    const copy = !!e.altKey;
    this.props.dragDropAddTask(data, newData, copy);
  }

  render() {
    return (
      <div
        className="row card z-depth-0 no-task-card hover"
        onClick={this.addTask}
      >
        <div
          className="col s12 center add-task-dash"
          onDrop={this.onDragDrop}
          onDragOver={this.onDragOver}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
        >
          -
        </div>
      </div>
    );
  }
}

export default NoTasksRow;
