import React from "react";

function ProjectTimelineStepThree({ height, width, svgStyles, color }) {
  return (
    <svg
      aria-hidden="true"
      height={height}
      width={width}
      fill={color}
      style={svgStyles}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.01 35"
    >
      <rect x="0.5" y="0.5" width="30" height="33" rx="3" fill={color} />
      <path
        d="M7.71867 9.92858C7.50196 9.9188 7.29625 9.82986 7.14011 9.67846L5.45653 8.07301C5.37372 7.99318 5.30735 7.89774 5.26122 7.79212C5.2151 7.68651 5.19011 7.5728 5.18769 7.45748C5.18528 7.34216 5.20547 7.22749 5.24713 7.12001C5.28879 7.01254 5.3511 6.91437 5.4305 6.83111C5.50989 6.74785 5.60482 6.68112 5.70986 6.63474C5.81491 6.58837 5.92801 6.56325 6.04271 6.56081C6.15741 6.55838 6.27146 6.57869 6.37835 6.62058C6.48525 6.66246 6.58289 6.72511 6.6657 6.80493L7.73602 7.85197L10.218 5.52523C10.2976 5.43001 10.3966 5.35302 10.5083 5.29943C10.62 5.24583 10.7417 5.21686 10.8654 5.21446C10.9892 5.21206 11.112 5.23629 11.2256 5.28552C11.3392 5.33475 11.4411 5.40784 11.5243 5.4999C11.6075 5.59195 11.6702 5.70084 11.7082 5.81925C11.7461 5.93767 11.7585 6.06287 11.7443 6.18646C11.7302 6.31006 11.6899 6.42919 11.6263 6.53585C11.5626 6.64252 11.4769 6.73427 11.3751 6.80493L8.30879 9.69591C8.14739 9.84405 7.9372 9.92692 7.71867 9.92858Z"
        fill="white"
      />
      <path
        d="M25.0933 8.99416H13.7986C13.5398 8.99416 13.2916 8.89262 13.1085 8.71187C12.9255 8.53113 12.8227 8.28599 12.8227 8.03037C12.8227 7.77476 12.9255 7.52962 13.1085 7.34888C13.2916 7.16813 13.5398 7.06659 13.7986 7.06659H25.0933C25.3522 7.06659 25.6004 7.16813 25.7834 7.34888C25.9665 7.52962 26.0693 7.77476 26.0693 8.03037C26.0693 8.28599 25.9665 8.53113 25.7834 8.71187C25.6004 8.89262 25.3522 8.99416 25.0933 8.99416Z"
        fill="white"
      />
      <path
        d="M7.79534 19.3571C7.57333 19.347 7.36258 19.255 7.20262 19.0984L5.47779 17.4198C5.3897 17.3383 5.31864 17.2397 5.26884 17.1298C5.21904 17.0199 5.19152 16.9009 5.18791 16.78C5.1843 16.6591 5.20467 16.5387 5.24782 16.4259C5.29097 16.3132 5.35601 16.2104 5.43907 16.1236C5.52214 16.0369 5.62154 15.968 5.73137 15.921C5.84119 15.874 5.9592 15.8499 6.07837 15.8502C6.19755 15.8504 6.31546 15.875 6.4251 15.9224C6.53473 15.9698 6.63386 16.0391 6.71658 16.1262L7.81312 17.2092L10.3559 14.8026C10.5331 14.6785 10.7486 14.6238 10.9624 14.6488C11.1763 14.6737 11.374 14.7766 11.5188 14.9383C11.6636 15.0999 11.7457 15.3095 11.7498 15.5279C11.754 15.7464 11.6799 15.959 11.5414 16.1262L8.39992 19.1165C8.23457 19.2697 8.01922 19.3554 7.79534 19.3571Z"
        fill="white"
      />
      <path
        d="M25.0933 18.2465H13.7986C13.5398 18.2465 13.2916 18.145 13.1085 17.9642C12.9255 17.7835 12.8227 17.5383 12.8227 17.2827C12.8227 17.0271 12.9255 16.782 13.1085 16.6012C13.2916 16.4205 13.5398 16.3189 13.7986 16.3189H25.0933C25.3522 16.3189 25.6004 16.4205 25.7834 16.6012C25.9665 16.782 26.0693 17.0271 26.0693 17.2827C26.0693 17.5383 25.9665 17.7835 25.7834 17.9642C25.6004 18.145 25.3522 18.2465 25.0933 18.2465Z"
        fill="white"
      />
      <path
        d="M25.1454 26.8435H13.8507C13.5919 26.8435 13.3436 26.7419 13.1606 26.5612C12.9776 26.3804 12.8748 26.1353 12.8748 25.8797C12.8748 25.6241 12.9776 25.3789 13.1606 25.1982C13.3436 25.0174 13.5919 24.9159 13.8507 24.9159H25.1454C25.4042 24.9159 25.6525 25.0174 25.8355 25.1982C26.0185 25.3789 26.1213 25.6241 26.1213 25.8797C26.1213 26.1353 26.0185 26.3804 25.8355 26.5612C25.6525 26.7419 25.4042 26.8435 25.1454 26.8435Z"
        fill="white"
      />
      <path
        d="M6.35557 28.3984C6.21943 28.3997 6.08459 28.3723 5.96005 28.318C5.8355 28.2637 5.72413 28.1837 5.63338 28.0835C5.54743 27.9895 5.48109 27.8796 5.43816 27.7603C5.39524 27.6409 5.37656 27.5143 5.38321 27.3878C5.38986 27.2613 5.42169 27.1373 5.4769 27.023C5.53211 26.9086 5.6096 26.8061 5.70495 26.7214L9.2378 23.5537C9.43274 23.4092 9.67539 23.3419 9.91802 23.3652C10.1606 23.3884 10.3857 23.5005 10.5488 23.6794C10.712 23.8582 10.8015 24.0909 10.7997 24.3316C10.7978 24.5724 10.7049 24.8037 10.539 24.9801L7.00618 28.1285C6.83064 28.2949 6.5988 28.3911 6.35557 28.3984Z"
        fill="white"
      />
      <path
        d="M9.8949 28.3984C9.65427 28.3963 9.42276 28.3072 9.24429 28.1478L5.70492 24.9801C5.59656 24.8998 5.50633 24.798 5.43998 24.6814C5.37362 24.5647 5.33259 24.4357 5.3195 24.3025C5.30642 24.1693 5.32156 24.0349 5.36396 23.9078C5.40636 23.7807 5.4751 23.6637 5.56579 23.5643C5.65649 23.4649 5.76715 23.3852 5.89074 23.3303C6.01432 23.2755 6.14812 23.2466 6.28362 23.2456C6.41911 23.2446 6.55333 23.2715 6.67773 23.3246C6.80213 23.3776 6.91398 23.4557 7.00616 23.5537L10.539 26.7214C10.6339 26.8066 10.7109 26.9094 10.7655 27.024C10.8201 27.1385 10.8514 27.2626 10.8574 27.3891C10.8635 27.5156 10.8442 27.6421 10.8007 27.7612C10.7573 27.8804 10.6904 27.9899 10.6041 28.0835C10.5145 28.1818 10.4052 28.2606 10.2831 28.3148C10.161 28.369 10.0288 28.3974 9.8949 28.3984Z"
        fill="white"
      />
    </svg>
  );
}

ProjectTimelineStepThree.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "#519acc",
};

export default ProjectTimelineStepThree;
