const baseColor = "#519acc";
const lightGrey = "#D7D7D7";
const medLightG = "#d1d1d1";
const MultiSelectStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: state.isFocused
      ? `1px 1px 1px 0px ${baseColor}`
      : `1px 1px 3px 0px ${lightGrey}`,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: state.menuIsOpen
      ? baseColor
      : state.isFocused
      ? baseColor
      : "#e2e2e2",
    minHeight: "42px",
    maxHeight: "42px",
    "&:hover": {
      color: `${baseColor}`,
    },
    "&:hover": {
      borderColor: `${baseColor}`,
    },
  }),
  container: (styles) => ({
    ...styles,
    width: "100%",
    fontFamily: "Arial",
    fontSize: "14px",
    margin: "8px 0",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    height: "100%",
    marginTop: "0",
    backgroundColor: "transparent",
  }),
  input: (styles) => ({
    ...styles,
    boxShadow: "none !important",
    margin: "0",
    padding: "0",
    color: "transparent",
  }),
  menu: (styles) => ({
    ...styles,
    transform: "translateX(-25px)",
    width: "300px",
    top: "35px",
    borderRadius: "5px",
    boxShadow: "0 0 9px 2px hsla(0,0%,0%,0.3), 0 4px 11px hsla(0,0%,0%,0.1)",
    zIndex: 2,
  }),
  placeholder: (styles, state) => ({
    ...styles,
    marginLeft: state.hasValue ? "30px" : "0",
    pointerEvents: "none",
    fontFamily: "Arial",
  }),
  menuList: (styles) => ({ ...styles, borderRadius: "5px" }),
  valueContainer: (styles, state) => ({
    ...styles,
    maxHeight: "300px",
    overflow: "hidden auto",
    order: 2,
    top: state.hasValue ? "37px" : "",
    left: state.hasValue ? "-10px" : "",
    position: state.hasValue ? "absolute" : "",
    width: "400px",
    display: "flex",
    marginTop: "16px",
    gap: "4px",
  }),
  indicatorsContainer: (styles, state) => ({
    ...styles,
    color: state.isFocused ? `${baseColor}` : `${medLightG}`,
    maxHeight: "42px",
    "&:hover": {
      color: `${baseColor}`,
    },
  }),
  dropdownIndicator: (styles, state) => {
    return {
      ...styles,
      color: state.isFocused
        ? `${baseColor} !important`
        : state.isHovered
        ? `${baseColor} !important`
        : "inherit",
      cursor: "pointer",
      "&:hover": {
        color: `${baseColor} !important`,
      },
    };
  },
  multiValue: (styles, state) => {
    return {
      ...styles,
      fontFamily: "Open Sans",
      fontWeight: "600",
      textAlign: "center",
      borderRadius: "20px",
      fontSize: "11px",
      color: "white",
      backgroundColor: baseColor,
    };
  },
  multiValueLabel: (styles, state) => {
    return {
      ...styles,
      color: "white",
      padding: "4px",
      paddingLeft: "12px",
    };
  },
  multiValueRemove: (styles, state) => {
    return {
      ...styles,
      borderRadius: "10px",
      color: "white",
      cursor: "pointer",
      backgroundColor: "transparent !important",
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? "white"
        : isSelected
        ? baseColor
        : isFocused
        ? baseColor
        : "white",
      color: isFocused ? "white" : isSelected ? "white" : "#313131",
      cursor: isDisabled ? "not-allowed" : "default",
      paddingLeft: "25px",
    };
  },
};

export default MultiSelectStyles;
