import React from "react";
import NoTasksRow from "./no_tasks_row.jsx";
import NonProjectTaskRow from "./non_project_task_row";
import ProjectTaskRow from "./project_task_row.jsx";
import OverbookedProjectRow from "./overbooked_project_row";
import ClosureRow from "../closures/closure_row";

class ProjectDayColumn extends React.Component {
  constructor(props) {
    super(props);

    this.addTask = this.addTask.bind(this);
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragDrop = this.onDrop.bind(this);
  }

  addTask() {
    const data = {
      userName: this.props.userName,
      userId: this.props.userId,
      date: this.props.day,
      projectId: this.props.id,
    };
    this.props.modalAction(true, "project-add-task", data);
  }

  onDragEnter(e) {
    e.target.classList.add("add-task-dash-drag-enter");
  }

  onDragLeave(e) {
    e.target.classList.remove("add-task-dash-drag-enter");
  }

  onDragOver(e) {
    e.preventDefault();
  }

  onDragDrop(e) {
    e.preventDefault();
    e.target.classList.remove("add-task-dash-drag-enter");
    const data = e.dataTransfer.getData("text");
    const newData = {
      userName: this.props.userName,
      userId: this.props.userId,
      date: this.props.day,
    };
    const copy = !!e.altKey;
    this.props.dragDropAddTask(data, newData, copy);
  }

  render() {
    const { id } = this.props;
    const { userName } = this.props;
    const { userId } = this.props;
    const date = this.props.day;
    const { tasks } = this.props;
    const { closures } = this.props;
    const { cardColor } = this.props;
    const userTasks = [];
    const projectTaskNode = [];
    const nonProjectTaskNode = [];
    let hasTasks = false;
    const dailyHours = [];
    const dailyTasks = [];
    const taskFormNode = [];
    const closuresNode = [];
    let hasClosure = false;
    for (let s = 0; s < closures.length; s++) {
      if (closures[s].start_date <= date && closures[s].end_date >= date) {
        closuresNode.push(
          <ClosureRow name={closures[s].name} key={closures[s].id} />
        );
        hasClosure = true;
      }
    }
    for (let y = 0; y < tasks.length; y++) {
      userTasks.push({ occurs: false, dayTasks: [], key: y });
      if (tasks[y][3] <= date && tasks[y][4] >= date) {
        userTasks[y].occurs = true;
        hasTasks = true;
        if (tasks[y][9]) {
          dailyHours.push(tasks[y][2]);
        }
        userTasks[y].dayTasks.push([
          tasks[y][0],
          tasks[y][1],
          tasks[y][2],
          tasks[y][3],
          tasks[y][4],
          tasks[y][5],
          tasks[y][6],
          tasks[y][7],
          tasks[y][8],
          tasks[y][9],
        ]);
      }
      if (userTasks[y].occurs === true) {
        dailyTasks.push(userTasks[y].dayTasks[0]);
      }
    }
    let total = 0;
    for (let b = 0; b < dailyHours.length; b++) {
      total += dailyHours[b];
    }

    for (let i = 0; i < dailyTasks.length; i++) {
      if (dailyTasks[i][5] === id && total > 8 && dailyTasks[i][9]) {
        projectTaskNode.push(
          <OverbookedProjectRow
            twoWeeks={this.props.twoWeeks}
            modalAction={this.props.modalAction}
            taskName={dailyTasks[i][1]}
            taskHours={dailyTasks[i][2]}
            startDate={dailyTasks[i][3]}
            endDate={dailyTasks[i][4]}
            userId={userId}
            key={dailyTasks[i][0]}
            taskId={dailyTasks[i][0]}
            taskStatus={dailyTasks[i][6]}
            multitaskId={dailyTasks[i][7]}
            date={date}
            userName={userName}
            cardColor={cardColor}
            created_by={dailyTasks[i][8]}
          />
        );
      } else if (dailyTasks[i][5] === id && (total <= 8 || !dailyTasks[i][9])) {
        projectTaskNode.push(
          <ProjectTaskRow
            twoWeeks={this.props.twoWeeks}
            modalAction={this.props.modalAction}
            taskName={dailyTasks[i][1]}
            taskHours={dailyTasks[i][2]}
            startDate={dailyTasks[i][3]}
            endDate={dailyTasks[i][4]}
            userId={userId}
            key={dailyTasks[i][0]}
            taskId={dailyTasks[i][0]}
            taskStatus={dailyTasks[i][6]}
            multitaskId={dailyTasks[i][7]}
            date={date}
            userName={userName}
            cardColor={cardColor}
            created_by={dailyTasks[i][8]}
            projectConfirmation={dailyTasks[i][9]}
          />
        );
      } else {
        nonProjectTaskNode.push(
          <NonProjectTaskRow
            twoWeeks={this.props.twoWeeks}
            taskName={dailyTasks[i][1]}
            taskHours={dailyTasks[i][2]}
            startDate={dailyTasks[i][3]}
            endDate={dailyTasks[i][4]}
            userId={userId}
            key={dailyTasks[i][0]}
            taskId={dailyTasks[i][0]}
            taskStatus={dailyTasks[i][6]}
            multitaskId={dailyTasks[i][7]}
            projectConfirmation={dailyTasks[i][9]}
            userName={userName}
          />
        );
      }
    }
    if (projectTaskNode.length === 0) {
      projectTaskNode.push(
        <NoTasksRow
          dragDropAddTask={this.props.dragDropAddTask}
          modalAction={this.props.modalAction}
          key={id}
          id={id}
          date={date}
          userName={userName}
          userId={userId}
        />
      );
    }
    if (hasClosure === true) {
      return (
        <div
          className={`col ${
            this.props.twoWeeks ? "day-col-sch" : "s12 m2"
          } center`}
        >
          {closuresNode}
        </div>
      );
    }
    if (hasTasks === true) {
      return (
        <div
          className={`col ${
            this.props.twoWeeks ? "day-col-sch" : "s12 m2"
          } center`}
        >
          {projectTaskNode}
          {nonProjectTaskNode}
        </div>
      );
    }
    return (
      <div
        className={`col ${
          this.props.twoWeeks ? "day-col-sch" : "s12 m2"
        } center hover`}
        onClick={this.addTask}
      >
        <p
          className="add-task-dash"
          onDrop={this.onDragDrop}
          onDragOver={this.onDragOver}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
        >
          -
        </p>
      </div>
    );
  }
}

export default ProjectDayColumn;
