import React from "react";
import styled from "styled-components";

const InvoiceCard = styled.div`
  margin-bottom: 20px;
`;

function InvoiceReady() {
  return <InvoiceCard />;
}

export default InvoiceReady;
