import React from "react";

class ClosureCard extends React.Component {
  constructor(props) {
    super(props);
    this.onClosureCardClick = this.onClosureCardClick.bind(this);
  }

  onClosureCardClick() {
    const data = {
      id: this.props.closurePath,
      name: this.props.closureName,
      startDate: this.props.closureStart,
      endDate: this.props.closureEnd,
    };
    this.props.modalAction(true, "edit-closure", data);
  }

  render() {
    const { closureName } = this.props;
    return (
      <div
        className="card green white-text hover project-card project-card-index col l3 m6 s12"
        onClick={this.onClosureCardClick}
      >
        <div className="white-text user-card-content">
          <p className="card-user-name truncate">{closureName}</p>
        </div>
      </div>
    );
  }
}

export default ClosureCard;
