import React from "react";
import moment from "moment";
import ReactDOM from "react-dom";
import {
  Button,
  Row,
  Textarea,
  Select,
  Range,
  MediaBox,
  Checkbox,
} from "react-materialize";
// import {MultiSelect} from './multi_select'

class BookingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.currentUser.name,
      meeting: "meeting name",
      startTime: "08:00:00",
      endTime: "08:30:00",
      users: 1,
      description: "yes",
      type: "team",
      duration: 30,
      trigger: false,
      booking_date:
        `${moment(this.props.currentDay, "YYYY/MM/DD")} ` + `08:00:00`,
    };
    this.getStartTime = this.getStartTime.bind(this);
    this.getEndTime = this.getEndTime.bind(this);
    this.getType = this.getType.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getDuration = this.getDuration.bind(this);
    this.preSubmit = this.preSubmit.bind(this);
    this.getMeetingName = this.getMeetingName.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.getDescription = this.getDescription.bind(this);
    this.getNewEndTime = this.getNewEndTime.bind(this);
    this.endSelect = React.createRef();
  }

  submitEditUser(formData) {
    const component = this;
    const object = this.props;
    const xhr = new XMLHttpRequest();
    xhr.open("POST", "/bookings.json", true);
    xhr.onload = function () {
      if (xhr.status === 200) {
        const resp = JSON.parse(xhr.response);
        if (object.action === "create") {
          component.props.bookingHandler(resp);
        }
        document.body.style.overflow = "";
        ReactDOM.unmountComponentAtNode(
          document.getElementById("modalContainer")
        );
      } else {
        alert("There was an issue");
      }
    };
    xhr.send(formData);
  }

  handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("booking[user_id]", this.props.currentUser.id);
    formData.append("booking[duration]", this.state.duration);
    formData.append("booking[name]", this.state.meeting);
    formData.append("booking[meeting_type]", this.state.type);
    formData.append("booking[booking_date]", this.state.booking_date);
    formData.append("booking[description]", this.state.description);
    formData.append("booking[room_id]", this.props.roomInfo.room_id);
    M.toast({
      html: `You booked the ${this.props.roomInfo.name} for ${this.state.duration} minutes on ${this.props.currentDay}`,
    });
    this.submitEditUser(formData);
  }

  getMeetingName(event) {
    this.setState({ meeting: event.target.value });
  }

  getDescription(event) {
    this.setState({ description: event.target.value });
  }

  getUsers(event) {
    this.setState({ users: parseInt(event.target.value) });
  }

  getStartTime(event) {
    this.setState({ startTime: event.target.value }, () => {
      this.getBookingDate(), this.getNewEndTime();
    });
  }

  getNewEndTime() {
    const dateValues = {
      "08:00:00": 0,
      "08:30:00": 1,
      "09:00:00": 2,
      "09:30:00": 3,
      "10:00:00": 4,
      "10:30:00": 5,
      "11:00:00": 6,
      "11:30:00": 7,
      "12:00:00": 8,
      "12:30:00": 9,
      "13:00:00": 10,
      "13:30:00": 11,
      "14:00:00": 12,
      "14:30:00": 13,
      "15:00:00": 14,
      "15:30:00": 15,
      "16:00:00": 16,
      "16:30:00": 17,
      "17:00:00": 18,
      "17:30:00": 19,
      "18:00:00": 20,
    };
    const endTimeValue = dateValues[this.state.startTime] + 1;
    const valueArray = {
      0: "08:00:00",
      1: "08:30:00",
      2: "09:00:00",
      3: "09:30:00",
      4: "10:00:00",
      5: "10:30:00",
      6: "11:00:00",
      7: "11:30:00",
      8: "12:00:00",
      9: "12:30:00",
      10: "13:00:00",
      11: "13:30:00",
      12: "14:00:00",
      13: "14:30:00",
      14: "15:00:00",
      15: "15:30:00",
      16: "16:00:00",
      17: "16:30:00",
      18: "17:00:00",
      19: "17:30:00",
      20: "18:00:00",
    };
    this.setState({ endTime: valueArray[endTimeValue] });
    const easyTime = {
      "08:00": "8:00",
      "08:30": "8:30",
      "09:00": "9:00",
      "09:30": "9:30",
      "10:00": "10:00",
      "10:30": "10:30",
      "11:00": "11:00",
      "11:30": "11:30",
      "12:00": "12:00",
      "12:30": "12:30",
      "13:00": "1:00",
      "13:30": "1:30",
      "14:00": "2:00",
      "14:30": "2:30",
      "15:00": "3:00",
      "15:30": "3:30",
      "16:00": "4:00",
      "16:30": "4:30",
      "17:00": "5:00",
      "17:30": "5:30",
      "18:00": "6:00",
    };
    this.endSelect.current._selectRef.parentElement.children[0].value =
      easyTime[valueArray[endTimeValue].substring(0, 5)];
    this.setState({ trigger: true }, () => {
      this.getDuration();
    });
  }

  getEndTime(event) {
    this.setState({ endTime: event.target.value }, () => {
      this.getDuration();
    });
  }

  getType(event) {
    this.setState({ type: event.target.value });
  }

  getDuration() {
    if (this.state.trigger === true) {
      const armyStrong = {
        "08:00:00": "08:00:00",
        "08:30:00": "08:30:00",
        "09:00:00": "09:00:00",
        "09:30:00": "09:30:00",
        "10:00:00": "10:00:00",
        "10:30:00": "10:30:00",
        "11:00:00": "11:00:00",
        "11:30:00": "11:30:00",
        "12:00:00": "12:00:00",
        "12:30:00": "12:30:00",
        "13:00:00": "13:00:00",
        "13:30:00": "13:30:00",
        "14:00:00": "14:00:00",
        "14:30:00": "14:30:00",
        "15:00:00": "15:00:00",
        "15:30:00": "15:30:00",
        "16:00:00": "16:00:00",
        "16:30:00": "16:30:00",
        "17:00:00": "17:00:00",
        "17:30:00": "17:30:00",
        "18:00:00": "18:00:00",
      };
      const endTime = armyStrong[this.state.endTime];
      const newTimes = {
        "08:00:00": 800,
        "08:30:00": 850,
        "09:00:00": 900,
        "09:30:00": 950,
        "10:00:00": 1000,
        "10:30:00": 1050,
        "11:00:00": 1100,
        "11:30:00": 1150,
        "12:00:00": 1200,
        "12:30:00": 1250,
        "13:00:00": 1300,
        "13:30:00": 1350,
        "14:00:00": 1400,
        "14:30:00": 1450,
        "15:00:00": 1500,
        "15:30:00": 1550,
        "16:00:00": 1600,
        "16:30:00": 1650,
        "17:00:00": 1700,
        "17:30:00": 1750,
        "18:00:00": 1800,
      };
      const duration =
        ((newTimes[endTime] - newTimes[this.state.startTime]) / 100) * 60;
      if (duration < 0) {
        M.toast({
          html: "Looks like the end time you've selected is before your start time, please select a valid end time.",
          classes: "danger",
        });
      } else {
        this.setState({ duration });
      }
    } else {
      const newTimes = {
        "08:00:00": 800,
        "08:30:00": 850,
        "09:00:00": 900,
        "09:30:00": 950,
        "10:00:00": 1000,
        "10:30:00": 1050,
        "11:00:00": 1100,
        "11:30:00": 1150,
        "12:00:00": 1200,
        "12:30:00": 1250,
        "13:00:00": 1300,
        "13:30:00": 1350,
        "14:00:00": 1400,
        "14:30:00": 1450,
        "15:00:00": 1500,
        "15:30:00": 1550,
        "16:00:00": 1600,
        "16:30:00": 1650,
        "17:00:00": 1700,
        "17:30:00": 1750,
        "18:00:00": 1800,
      };
      const duration =
        ((newTimes[this.state.endTime] - newTimes[this.state.startTime]) /
          100) *
        60;
      if (duration < 0) {
        M.toast({
          html: "Looks like the end time you've selected is before your start time, please select a valid end time.",
          classes: "danger",
        });
      } else {
        this.setState({ duration });
      }
    }
  }

  getBookingDate() {
    const today = moment(new Date());
    const formatted_day = moment(today, "YYYY/MM/DD");
    const booking_date = `${formatted_day} ${this.state.startTime}`;
    this.setState({ booking_date });
  }

  colorCell() {
    this.props.colorCell;
  }

  preSubmit(event) {
    event.preventDefault();
    const dateValues = {
      "08:00:00": 0,
      "08:30:00": 1,
      "09:00:00": 2,
      "09:30:00": 3,
      "10:00:00": 4,
      "10:30:00": 5,
      "11:00:00": 6,
      "11:30:00": 7,
      "12:00:00": 8,
      "12:30:00": 9,
      "13:00:00": 10,
      "13:30:00": 11,
      "14:00:00": 12,
      "14:30:00": 13,
      "15:00:00": 14,
      "15:30:00": 15,
      "16:00:00": 16,
      "16:30:00": 17,
      "17:00:00": 18,
      "17:30:00": 19,
      "18:00:00": 20,
    };
    const startTimeValue = dateValues[this.state.startTime];
    const endTimeValue = dateValues[this.state.endTime];
    var valueCounter = [];
    const dayValueCounter = [];
    if (this.props.bookings.length > 0) {
      for (let i = 0; i < this.props.bookings.length; i++) {
        const { booking_array } = this.props.bookings[i];
        for (let t = startTimeValue; t <= endTimeValue - 1; t++) {
          if (booking_array[t] === 1) {
            valueCounter.push(1);
          } else {
            valueCounter.push(0);
          }
          if (valueCounter.includes(1)) {
            dayValueCounter.push("bad");
            var valueCounter = [];
          } else {
            dayValueCounter.push("good");
            var valueCounter = [];
          }
        }
      }
      if (dayValueCounter.includes("bad")) {
        M.toast({
          html: "The time slot you have selected in already booked by somebody else, select another time and try again",
          classes: "danger",
        });
      } else {
        this.getBookingDate();
        var duration = this.getDuration();
        this.handleSubmit(event, duration);
      }
    } else {
      this.getBookingDate();
      var duration = this.getDuration();
      this.handleSubmit(event, duration);
    }
  }

  render() {
    const object = this.props;
    var deleteButton;
    if (object.action === "edit") {
      var deleteButton = (
        <Button className="danger" onClick={this.deleteBooking}>
          {" "}
          Delete
        </Button>
      );
    }
    const roomUrls = {
      "Crater Lake": "crater.jpg",
      "Green room": "green.jpg",
      "Grey room": "grey.jpg",
      "Orange room": "orange.jpg",
      "Red room": "red.jpg",
      "Yellow room": "yellow.jpg",
      "Blue room": "blue.jpg",
      "Chinook Room": "chinook.jpg",
      "Shasta Room": "shasta.jpg",
      "Wasco Room": "wasco.jpg",
      "Lego Room": "lego.jpg",
    };
    return (
      <div>
        <div>
          <MediaBox
            className="modal-picture materialboxed"
            src={`https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/syncit/${
              roomUrls[this.props.roomInfo.name]
            }`}
          />
        </div>
        <form onSubmit={this.preSubmit}>
          <div className="col s12 row">
            <p
              className="col s12 l3"
              style={{ marginTop: "17px", marginRight: "10px" }}
            >
              Meeting Owner:{" "}
            </p>
            <input
              style={{ marginTop: "10px" }}
              className="col s12 l3 pull-l1"
              placeholder="Booking For:"
              defaultValue={this.props.currentUser.name}
            />
            <p
              className="col s3 push-s3"
              style={{ fontSize: `${36}px`, margin: "0px" }}
            >
              {moment(this.props.currentDay).format("MMM Do")}{" "}
            </p>
          </div>

          <div style={{ display: "flex" }}>
            Start time:
            <Select
              className="select"
              style={{ appearance: "none" }}
              value={this.state.startTime.value}
              onChange={this.getStartTime}
              defaultValue="08:00:00"
            >
              <option value="08:00:00">8:00</option>
              <option value="08:30:00">8:30</option>
              <option value="09:00:00">9:00</option>
              <option value="09:30:00">9:30</option>
              <option value="10:00:00">10:00</option>
              <option value="10:30:00">10:30</option>
              <option value="11:00:00">11:00</option>
              <option value="11:30:00">11:30</option>
              <option value="12:00:00">12:00</option>
              <option value="12:30:00">12:30</option>
              <option value="13:00:00">1:00</option>
              <option value="13:30:00">1:30</option>
              <option value="14:00:00">2:00</option>
              <option value="14:30:00">2:30</option>
              <option value="15:00:00">3:00</option>
              <option value="15:30:00">3:30</option>
              <option value="16:00:00">4:00</option>
              <option value="16:30:00">4:30</option>
              <option value="17:00:00">5:00</option>
              <option value="17:30:00">5:30</option>
            </Select>
            End time:
            <Select
              ref={this.endSelect}
              className="select"
              value={this.state.endTime.value}
              onChange={this.getEndTime}
              defaultValue="08:30:00"
            >
              <option value="08:30:00">8:30</option>
              <option value="09:00:00">9:00</option>
              <option value="09:30:00">9:30</option>
              <option value="10:00:00">10:00</option>
              <option value="10:30:00">10:30</option>
              <option value="11:00:00">11:00</option>
              <option value="11:30:00">11:30</option>
              <option value="12:00:00">12:00</option>
              <option value="12:30:00">12:30</option>
              <option value="13:00:00">1:00</option>
              <option value="13:30:00">1:30</option>
              <option value="14:00:00">2:00</option>
              <option value="14:30:00">2:30</option>
              <option value="15:00:00">3:00</option>
              <option value="15:30:00">3:30</option>
              <option value="16:00:00">4:00</option>
              <option value="16:30:00">4:30</option>
              <option value="17:00:00">5:00</option>
              <option value="17:30:00">5:30</option>
              <option value="18:00:00">6:00</option>
            </Select>
          </div>
          <p>Number of Attendees:</p>
          <Range
            min="1"
            max={this.props.roomInfo.capacity.toString()}
            name="users"
            value={this.state.users.value}
            onChange={this.getUsers}
          />
          <input
            type="text"
            name="meeting"
            placeholder="Meeting Name (Optional)"
            value={this.state.meeting.value}
            onChange={this.getMeetingName}
          />
          <Textarea
            type="text"
            name="description"
            placeholder="Description (optional)"
            value={this.state.description.value}
            onChange={this.getDescription}
            s={12}
            m={12}
            l={12}
            xl={12}
          />
          <div className="row s12 valign-wrapper">
            <p className="col s8">Is this a client meeting?</p>
            <div className="col s3">
              <Checkbox
                id="client-meeting"
                name="type"
                label="Yes"
                value="client"
                onChange={this.getType}
              />
            </div>
          </div>

          <Button onClick={() => this.colorCell()} type="submit">
            Book
          </Button>
        </form>
      </div>
    );
  }
}

export default BookingForm;
