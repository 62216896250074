import React from "react";
import styled from "styled-components";

const ProjectCard = ({
  project,
  clientView,
  selectedClient,
  loadProject,
  handleClientClick,
}) => {
  const loadProjectHandler = () => {
    loadProject(project.id);
  };

  const loadClientHandler = () => {
    handleClientClick(project.client_id);
  };

  const { budgeted_hours, pending, active } = project;
  const prettyWordMap = {
    leading: "Lead Created",
    est_requesting: "Estimate Request",
    est_sending: "Estimate Sent",
    project_confirming: "Project Confirmed",
    testing: "Test In Progress",
    completing: "Project Complete",
    readying: "Ready for Invoice",
    invoicing: "Invoiced",
  };

  const projectWorkflowStatus =
    prettyWordMap[project.project_status?.aasm_state] || "Not on Taskboard";

  const projectStatus = active ? (pending ? "Pending" : "Active") : "Inactive";

  return (
    <ProjectCardDiv active={active} pending={pending}>
      <div
        className="project_name"
        style={{
          flex: "1 1 0%",
        }}
      >
        <ProjectNameWrapper>
          <ProjectName
            aria-label={`Open Project Details for ${project.name}`}
            role="button"
            className="project_button"
            onClick={loadProjectHandler}
            style={{
              fontFamily: "manrope",
              fontSize: "14px",
              fontWeight: "600",
              maxWidth: "200px",
            }}
          >
            {project.name}
          </ProjectName>
        </ProjectNameWrapper>
      </div>
      {!clientView && (
        <div className="client_name">
          <p
            aria-label={`Client: ${project.client_name}. Select to filter project list by this client`}
            disabled={selectedClient.includes(project.client_id)}
            onClick={loadClientHandler}
            data-client-id={project.client_id}
            className="project_button"
          >
            {project.client_name}
          </p>
        </div>
      )}
      <div
        style={{
          flex: "1 1 0%",
          position: "relative",
        }}
      >
        <div
          style={{
            maxWidth: clientView ? "34px" : "58px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {" "}
          <span
            style={{
              fontFamily: "manrope",
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            {budgeted_hours ? budgeted_hours.toFixed(2) : "00.00"}
          </span>
        </div>
      </div>
      <div
        className="project_tag"
        style={{ flex: "1 1 0%", maxWidth: "210px" }}
      >
        <div
          style={{
            maxWidth: "70px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {project.tag && (
            <span
              aria-label={`Project Type: ${project.tag.name}`}
              className="type_tag"
              style={{
                backgroundColor: project.tag.color,
                whiteSpace: "nowrap",
              }}
            >
              {project.tag.name}
            </span>
          )}
        </div>
      </div>

      <div
        style={{
          flex: "1 1 0% ",
          height: "25px",
          maxWidth: "225px",
        }}
      >
        <div
          style={{
            maxWidth: "117px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <span
            aria-label={`Project Workflow Status: ${projectWorkflowStatus}`}
            className="workflow_status_tag"
          >
            {projectWorkflowStatus}
          </span>
        </div>
      </div>

      <span
        aria-label={`Project Status: ${projectStatus}`}
        className="status_tag"
      >
        {projectStatus}
      </span>
    </ProjectCardDiv>
  );
};

const ProjectCardDiv = styled.div`
  height: 50px;
  padding: 15px;
  background-color: white;
  transition: background-color 0.2s;
  display: flex;
  border: none;
  border-radius: 6px;
  border: solid 1px lightgray;
  align-items: center;
  font-size: 14px;
  position: relative;

  :hover {
    background-color: #dadada52;
  }

  .project_name,
  .client_name,
  .project_tag {
    flex: 1;
    min-width: 0;
  }

  .project_name > a,
  .client_name > button {
    color: #242424;
    width: 100%;
    text-align: left; // Ensure text is aligned to the left
  }

  .project_name > a:hover,
  .client_name > button:hover {
    color: #519acc;
  }

  .type_tag {
    color: white;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    height: 25px;
    font-family: "Open Sans";
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
  }
  .workflow_status_tag {
    color: white;
    // display: flex;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    background: var(--background-colors-workflow-status-578-bc-2, #578bc2);
    width: fit-content;
    font-family: "Open Sans";
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    white-space: nowrap;
  }
  .status_tag {
    background-color: ${(props) =>
      props.active ? (props.pending ? "#d76f23" : "#2cb882") : "#BF0413"};
    color: white;
    width: 58px;
    height: 25px;
    border-radius: 20px;
    padding: 4px 6px;
    font-family: "Open Sans";
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    width: 58px;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
`;

const ProjectNameWrapper = styled.div`
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ProjectName = styled.a`
  color: #242424;
  width: 100%;
  text-align: left;
  :hover {
    color: #519acc;
  }
`;
export default ProjectCard;
