import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import ReactDOM from "react-dom";
import TableOfRooms from "./grid/TableOfRooms";
import Booking from "./booking";
import DayBar from "../date/DayBar";
import axios from "axios";
import ReactModal from "../modals/react_modal";
import { sentryInit } from "../utils";
import MeetingIcon from "components/icons/updated_meeting_icon";
import styled from "styled-components";

sentryInit();

const MeetingsContainer = ({ bookings, users, currentUser, rooms }) => {
  const [state, setState] = useState({
    currentDay: moment().format("MM-DD-YYYY"),
    bookings,
    todaysBookings: bookings,
    today: moment().format("MM-DD-YYYY"),
    results: [[], [], [], [], [], [], [], [], [], [], []],
    modalOpen: false,
    currentModal: "",
    modalData: {},
  });

  const bookingHandler = useCallback((booking) => {
    setState((prevState) => ({
      ...prevState,
      bookings: [...prevState.bookings, booking],
    }));
    closeModal();
  }, []);

  const handleUpdateCurrentDate = useCallback((newDate) => {
    let momentDate = newDate;
    let newDateRails = moment(newDate, "MM-DD-YYYY").format("YYYY/MM/DD");
    getBookings(newDateRails, momentDate);
  }, []);

  const getBookings = useCallback(
    async (date, momentDate) => {
      try {
        const res = await axios.get(`/booking_date.json?start_date=${date}`);
        const bookings = res.data;
        const newResults = getBookingsArrayResults(bookings);
        setState((prevState) => ({
          ...prevState,
          results: newResults,
          bookings,
          currentDay: momentDate,
          todaysBookings:
            momentDate === prevState.today
              ? bookings
              : prevState.todaysBookings,
        }));
      } catch (e) {
        console.log(e);
      }
    },
    [getBookingsArrayResults]
  );

  const getBookingsArrayResults = useCallback(
    (input) => {
      let roomBookings = {};

      rooms.forEach((room) => {
        roomBookings[room.id] = [];
      });

      input.forEach((booking) => {
        roomBookings[booking.room_id].push(booking);
      });

      let resultsArray = Object.values(roomBookings);

      for (let i = resultsArray.length; i < 8; i++) {
        resultsArray.push([]);
      }

      return resultsArray;
    },
    [rooms]
  );

  const deleteHandler = useCallback((bookingId) => {
    setState((prevState) => ({
      ...prevState,
      bookings: prevState.bookings.filter(
        (booking) => booking.id !== bookingId
      ),
    }));
    closeModal();
  }, []);

  const editHandler = useCallback((booking) => {
    setState((prevState) => ({
      ...prevState,
      bookings: prevState.bookings.map((prevBooking) =>
        prevBooking.id === booking.id ? booking : prevBooking
      ),
    }));
    closeModal();
  }, []);

  const closeModal = () => {
    document.body.style["overflow"] = "";
    ReactDOM.unmountComponentAtNode(document.getElementById("modalContainer"));
  };

  const setModal = useCallback((bool, page, data) => {
    setState((prevState) => ({
      ...prevState,
      modalOpen: bool,
      currentModal: page,
      modalData: data,
    }));
  }, []);

  const results = getBookingsArrayResults(state.bookings);
  const todaysResults = getBookingsArrayResults(state.todaysBookings);

  const handleFindRoom = () => {
    var data = {
      currentUser,
      currentDay: state.currentDay,
      users,
      bookingHandler,
      closeButton: true,
      customClass: "find-room-modal",
    };
    setModal(true, "find-room", data);
  };

  return (
    <div
      style={{ display: "center", margin: "24px 32px 0px", minWidth: "1200px" }}
    >
      <ReactModal
        isShowing={state.modalOpen}
        page={state.currentModal}
        data={state.modalData}
        modalAction={setModal}
      />
      <PageNav>
        <TitleContainer>
          <MeetingIcon />
          <Title
            style={{
              fontSize: "20px",
              fontWeight: "700",
              fontFamily: "Manrope",
              margin: "0px 0px 0px 16px",
            }}
          >
            Meetings
          </Title>
        </TitleContainer>
        <button onClick={handleFindRoom} className="common-button-submit">
          Find a Meeting Room
        </button>
      </PageNav>

      <DayBar
        onUpdateCurrentDate={handleUpdateCurrentDate}
        currentDay={state.currentDay}
        bookings={state.results}
        users={users}
        currentUser={currentUser}
        setModal={setModal}
        bookingHandler={bookingHandler}
      />
      <Booking
        bookingHandler={bookingHandler}
        bookings={todaysResults}
        currentUser={currentUser}
        currentDay={state.today}
        deleteHandler={deleteHandler}
        editHandler={editHandler}
        rooms={rooms}
        setModal={setModal}
        users={users}
      />

      <TableOfRooms
        bookingHandler={bookingHandler}
        deleteHandler={deleteHandler}
        editHandler={editHandler}
        bookings={results}
        rooms={rooms}
        currentDay={state.currentDay}
        currentUser={currentUser}
        users={users}
        setModal={setModal}
      />
    </div>
  );
};

const PageNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: 700;
  font-family: Manrope;
  margin: 0px 0px 0px 16px;
`;

export default MeetingsContainer;
