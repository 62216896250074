import React from "react";
import axios from "axios";
import UserShowSidebar from "./user_show_sidebar";
import UserHeader from "./UserHeader";
import UserForm from "./UserForm";
import { normalizePhone } from "components/utils";
import Table from "./Table";

const phoneProviderOptions = [
  { label: "T-Mobile", value: "@tmomail.net" },
  { label: "Verizon", value: "@vtext.com" },
  { label: "AT&T", value: "@txt.att.net" },
  { label: "Sprint", value: "@messaging.sprintpcs.com" },
  { label: "Cricket", value: "@mms.cricketwireless.net" },
  { label: "Visible", value: "@vtext.com" },
  { label: "Metro", value: "@mymetropcs.com" },
  { label: "Google Fi", value: "@msg.fi.google.com" },
  { label: "Mint Mobile", value: "@tmomail.net" },
  { label: "Consumer Cellular", value: "@mailmymobile.net" },
  { label: "Boost Mobile", value: "@sms.myboostmobile.com" },
  { label: "XFinity Mobile", value: "@vtext.com" },
  { label: "Straight Talk", value: "@vtext.com" },
];

class UserAccount extends React.Component {
  constructor(props) {
    super(props);
    var phone = props.user.phone ? normalizePhone(props.user.phone) : "";
    var phoneProvider = this.props.user.phone_carrier_email
      ? phoneProviderOptions.find(
          (p) => p.value === this.props.user.phone_carrier_email
        )
      : [];
    this.state = {
      user: this.props.user,
      userName: this.props.user.name,
      userImage: this.props.user.avatar_url,
      digest: this.props.user.receive_digest,
      confirmationType: this.props.user.confirmation_type,
      email: this.props.user.email,
      secondEmail: this.props.user.secondary_email || "",
      slackId: this.props.user.slack_id,
      phone: phone || "",
      imageFile: "",
      phoneCarrierEmail: phoneProvider ? phoneProvider : [],
      loading: false,
      currentView: 0,
      reminderStatus: this.props.user.time_entry_reminder,
      timeZone: {
        value: this.props.user.time_zone,
        label: this.props.user.time_zone,
      },
    };
    this.userReceiveDigest = React.createRef();
    this.userAvatar = React.createRef();
    // this.submitButton = React.createRef();
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    document.title = `Syncit - Account`;
  }

  handleNotificationSubmit = (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    var user = new FormData();
    if (
      !this.props.user.full_time &&
      this.state.confirmationType === "text" &&
      !(this.state.phoneCarrierEmail && this.state.phoneCarrierEmail.value)
    ) {
      M.toast({
        html: `Phone Carrier is required to recieve text confirmations`,
        displayLength: 3000,
        classes: "red",
      });
      this.setState({ loading: false });
      return;
    }
    user.append("receive_digest", this.state.digest);
    user.append("time_entry_reminder", this.state.reminderStatus);
    user.append("confirmation_type", this.state.confirmationType);
    if (this.state.phoneCarrierEmail && this.state.phoneCarrierEmail.value) {
      user.append("phone_carrier_email", this.state.phoneCarrierEmail.value);
    }
    axios
      .patch(`/api/v1/users/${this.props.user.id}`, user, {
        headers: {
          //   'Accept': 'application/json',
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        M.toast({
          html: `<span aria-live="assertive" >User updated!</span>`,
          displayLength: 3000,
          classes: "green",
        });
        this.setState({ loading: false });
      })
      .catch((err) => {
        M.toast({
          html: `<span aria-live="assertive" >Error updating user</span>`,
          displayLength: 3000,
          classes: "red",
        });
        this.setState({ loading: false });
        console.error(err);
      });
  };

  handleInfoSubmit = (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    if (this.state.phone.length < 14 && this.state.phone.length !== 0) {
      M.toast({
        html: `Enter a valid phone number`,
        displayLength: 3000,
        classes: "red",
      });
      this.setState({ loading: false });
      return;
    }
    if (this.state.secondEmail && !this.validateEmail(this.state.secondEmail)) {
      M.toast({
        html: `Enter a valid secondary email`,
        displayLength: 3000,
        classes: "red",
      });
      this.setState({ loading: false });
      return;
    }
    var user = new FormData();
    if (this.state.imageFile) {
      user.append("avatar", this.state.imageFile);
    }
    user.append("name", this.state.userName);
    user.append("email", this.state.email);
    user.append("time_zone", this.state.timeZone.value);
    user.append("secondary_email", this.state.secondEmail);
    user.append("phone", this.state.phone);
    user.append("slack_id", this.state.slackId);
    user.append("receive_digest", this.state.digest);
    user.append("confirmation_type", this.state.confirmationType);
    axios
      .patch(`/api/v1/users/${this.props.user.id}`, user, {
        headers: {
          //   'Accept': 'application/json',
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        M.toast({
          html: `<span aria-live="assertive" >User updated!</span>`,
          displayLength: 3000,
          classes: "green",
        });
        this.setState({
          loading: false,
          userImage: res.data.user.avatar_url,
          user: res.data.user,
        });
      })
      .catch((err) => {
        M.toast({
          html: `<span aria-live="assertive" >Error updating user</span>`,
          displayLength: 3000,
          classes: "red",
        });
        this.setState({ loading: false });
        console.error(err);
      });
  };

  setDigest = (e) => {
    this.setState({ digest: e.target.checked });
  };

  setBookingConfirm = (e) => {
    this.setState({ confirmationType: e.target.value });
  };

  handleTimeZoneChange = (e) => {
    this.setState({ timeZone: e }, () => {
      CustomAriaAlert(`${this.state.timeZone.label} selected`);
    });
  };

  handlePhoneChange = (e) => {
    var text = e.target.value;
    var newText = "";
    for (var x = 0; x < text.length; x++) {
      if (text.charCodeAt(x) >= 48 && text.charCodeAt(x) <= 57) {
        newText = newText + text[x];
      }
    }
    if (newText.length > 3 && newText.length < 7) {
      newText = `(${newText.substring(0, 3)})${newText.substring(3)}`;
    } else if (newText.length >= 7) {
      newText = `(${newText.substring(0, 3)}) ${newText.substring(
        3,
        6
      )}-${newText.substring(6)}`;
    }
    this.setState({
      phone: newText,
    });
  };

  handleInputChange = (e) => {
    var name = e.target.name;
    var input = e.target.value;
    this.setState({ [name]: input });
  };

  handleProfilePicChange = (e) => {
    var file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState(
        {
          userImage: e.target.result,
          imageFile: file,
        },
        () => {
          CustomAriaAlert("Profile picture updated");
          this.handleInfoSubmit(e);
        }
      );
    };
    reader.readAsDataURL(file);

    //once the file is read it will be set to the state and submit automatically
    // this.submitButton.current.click();
    // this.handleInfoSubmit(e);
  };

  handlePhoneCarrierChange = (carrier) => {
    this.setState({ phoneCarrierEmail: carrier }, () => {
      CustomAriaAlert(`${this.state.phoneCarrierEmail.label} selected`);
    });
  };

  handleReminderChange = (e) => {
    var reminderStatus = parseInt(e.target.value);
    this.setState({ reminderStatus });
  };

  getCurrentView = () => {
    switch (this.state.currentView) {
      case 0:
        return (
          <UserForm
            handleSubmit={this.handleInfoSubmit}
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            handleTimeZoneChange={this.handleTimeZoneChange}
            timeZone={this.state.timeZone}
            state={this.state}
            accountView={true}
            currentUserRoleId={this.props.currentUserRoleId}
          />
        );
      case 1:
        return (
          <div style={{ padding: "32px" }}>
            <p
              style={{
                margin: "0px 0px 21px",
                fontFamily: "Manrope",
                fontWeight: "600",
                fontSize: "16px",
              }}
            >
              {this.state.userName}'s Assigned Projects
            </p>
            <Table
              data={this.props.projects}
              headers={[
                { field: "name", label: "Project Name" },
                { field: "client_name", label: "Client Name" },
                { field: "tag_name", label: "Type" },
                { field: "active", label: "Status" },
              ]}
            />
          </div>
        );
      case 2:
        return (
          <div style={{ padding: "32px" }}>
            <form
              className="edit_user"
              id="edit_user_1"
              onSubmit={this.handleNotificationSubmit}
            >
              <p
                tabIndex={0}
                id="account-current-view-heading"
                style={{
                  margin: "0px 0px 32px",
                  fontWeight: "600",
                  fontSize: "16px",
                  fontFamily: "Manrope",
                }}
                className="section-header"
              >
                Notifications
              </p>
              <div>
                <span
                  htmlFor="user_receive_digest"
                  style={{
                    fontWeight: "600",
                    fontSize: "13px",
                    fontFamily: "Manrope",
                  }}
                >
                  Receive Daily Digest
                </span>
                <div className="switch">
                  <label>
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "13px",
                        fontFamily: "Arial",
                      }}
                      aria-hidden={true}
                    >
                      Off
                    </span>
                    <input
                      value={this.state.digest}
                      checked={this.state.digest === true}
                      onChange={this.setDigest}
                      ref={this.userReceiveDigest}
                      type="checkbox"
                      id="user_receive_digest"
                    />
                    <span className="lever"></span>
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "13px",
                        fontFamily: "Arial",
                      }}
                      aria-hidden={true}
                    >
                      On
                    </span>
                  </label>
                </div>
              </div>
              {/* {!this.props.user.full_time && (
              )} */}
              <div style={{ marginTop: "32px" }}>
                <div>
                  <label
                    htmlFor="user_receive_booking_confirm"
                    style={{
                      fontWeight: "600",
                      fontSize: "13px",
                      fontFamily: "Manrope",
                    }}
                  >
                    Booking Confirmations
                  </label>
                  <p
                    style={{
                      position: "relative",
                      left: "-8px",
                      marginTop: "2px",
                    }}
                  >
                    <label
                      htmlFor="user_receive_booking_confirm_email"
                      className="privilege-label"
                    >
                      <input
                        required="required"
                        type="radio"
                        className="with-gap"
                        value="email"
                        onChange={this.setBookingConfirm}
                        name="user[receive_booking_confirm]"
                        id="user_receive_booking_confirm_email"
                        checked={this.state.confirmationType === "email"}
                      />
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "13px",
                          fontFamily: "Arial",
                        }}
                      >
                        Email
                      </span>
                    </label>
                    <label
                      htmlFor="user_receive_booking_confirm_text"
                      className="privilege-label"
                    >
                      <input
                        type="radio"
                        className="with-gap"
                        value="text"
                        disabled={!this.state.phone}
                        onChange={this.setBookingConfirm}
                        name="user[receive_booking_confirm]"
                        id="user_receive_booking_confirm_text"
                        checked={this.state.confirmationType === "text"}
                      />
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "13px",
                          fontFamily: "Arial",
                        }}
                      >
                        Text
                      </span>
                    </label>
                  </p>
                </div>
              </div>
              <div style={{ margin: "40px 0 30px" }}>
                <input
                  type="submit"
                  name="commit"
                  disabled={this.state.loading}
                  value="Save Changes"
                  className="common-button-submit"
                />
              </div>
            </form>
          </div>
        );
      case 3:
        return <div>{/* integrations */}</div>;
      case 4:
        return (
          <div style={{ padding: "32px" }}>
            <p
              style={{
                margin: "0px 0px 21px",
                fontFamily: "Manrope",
                fontWeight: "600",
                fontSize: "16px",
              }}
            >
              Your Paid Time Off Requests
            </p>
            <Table
              data={this.props.requests}
              headers={[
                { field: "created_at", label: "Request Date" },
                { field: "start_date", label: "Start Date" },
                { field: "end_date", label: "End Date" },
                { field: "hours", label: "Hours" },
                { field: "approved", label: "Status" },
              ]}
            />
          </div>
        );
    }
  };

  setCurrentView = (currentView) => {
    this.setState({ currentView }, () => {
      var elem = document.getElementById("account-current-view-heading");
      if (elem) {
        elem.focus();
      }
    });
  };

  validateEmail(email) {
    if (
      email &&
      email.includes("@") &&
      email.includes(".") &&
      email.indexOf("@") !== 0 &&
      email.split("@").length === 2 &&
      email.split("@")[1].split(".").length === 2 &&
      email.split("@")[1].split(".")[0].length > 0 &&
      email.split("@")[1].split(".")[1].length > 1
    ) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <div
        style={{
          backgroundColor: "#f2f2f2",
          overflowX: "auto",
          padding: "16px",
          minHeight: "calc(100vh - 68px)",
        }}
        id="user-show-page"
      >
        <div
          style={{
            height: "calc(100vh - 96px)",
            minWidth: "1024px",
            borderRadius: "10px",
            border: "1px solid #d7d7d7",
            backgroundColor: "white",
            padding: "0px",
            overflow: "hidden",
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <UserShowSidebar
            fullTime={this.props.user.full_time}
            userImage={this.state.userImage}
            user={this.state.user}
            roleId={this.props.user.role_id}
            contractor={this.props.user.contractor}
            currentView={this.state.currentView}
            setCurrentView={this.setCurrentView}
            account={true}
            handleProfilePicChange={this.handleProfilePicChange}
          />
          <div className="user-show-section">
            <UserHeader title={"Account"} />
            {this.getCurrentView()}
          </div>
        </div>
      </div>
    );
  }
}

export default UserAccount;
