import React from "react";
import moment from "moment";
import ReactDOM from "react-dom";
import {
  Button,
  Row,
  Textarea,
  Select,
  MediaBox,
  Checkbox,
} from "react-materialize";
import axios from "axios";
import Swal from "sweetalert2";
import Slider from "react-rangeslider";
import MultiSelect from "./multi_select";

class GridFormModal extends React.Component {
  constructor(props) {
    super(props);
    this.timeIndex =
      (moment().hour() - 8) * 4 + Math.floor(moment().minute() / 15);
    this.valueArray = {
      0: "08:00:00",
      1: "08:15:00",
      2: "08:30:00",
      3: "08:45:00",
      4: "09:00:00",
      5: "09:15:00",
      6: "09:30:00",
      7: "09:45:00",
      8: "10:00:00",
      9: "10:15:00",
      10: "10:30:00",
      11: "10:45:00",
      12: "11:00:00",
      13: "11:15:00",
      14: "11:30:00",
      15: "11:45:00",
      16: "12:00:00",
      17: "12:15:00",
      18: "12:30:00",
      19: "12:45:00",
      20: "13:00:00",
      21: "13:15:00",
      22: "13:30:00",
      23: "13:45:00",
      24: "14:00:00",
      25: "14:15:00",
      26: "14:30:00",
      27: "14:45:00",
      28: "15:00:00",
      29: "15:15:00",
      30: "15:30:00",
      31: "15:45:00",
      32: "16:00:00",
      33: "16:15:00",
      34: "16:30:00",
      35: "16:45:00",
      36: "17:00:00",
      37: "17:15:00",
      38: "17:30:00",
      39: "17:45:00",
      40: "18:00:00",
    };
    this.state = {
      name: this.props.currentUser.name,
      meeting: this.props.booking ? this.props.booking.name : "",
      startTime:
        this.props.startTimeValue === undefined
          ? this.timeIndex >= 0 && this.timeIndex <= 39
            ? this.valueArray[this.timeIndex]
            : "08:00:00"
          : this.props.startTimeValue,
      endTime:
        this.props.endTimeValue === undefined
          ? this.timeIndex + 1 >= 1 && this.timeIndex + 1 <= 40
            ? this.valueArray[this.timeIndex + 1]
            : "08:15:00"
          : this.props.endTimeValue,
      description: this.props.booking ? this.props.booking.description : "",
      // temp fix for attendees error
      users: this.props.booking
        ? this.props.booking.attendees
          ? this.props.booking.attendees
          : 2
        : 2,
      type: this.props.booking ? this.props.booking.meeting_type : "team",
      client: false,
      duration: 30,
      time_value: 0,
      trigger: false,
      multi: this.props.booking
        ? this.props.booking.multi_booking_id !== null
        : false,
      weeks: 1,
      originalWeeks: 1,
      sliderValue: 2,
      multiBookings: [],
      existingMultiBookings: [],
      deleteAll: false,
      editAll: false,
    };
    this.getStartTime = this.getStartTime.bind(this);
    this.getEndTime = this.getEndTime.bind(this);
    this.getType = this.getType.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getDuration = this.getDuration.bind(this);
    this.getMeetingName = this.getMeetingName.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.getDescription = this.getDescription.bind(this);
    this.preSubmit = this.preSubmit.bind(this);
    this.weeksHandler = this.weeksHandler.bind(this);
    this.setMulti = this.setMulti.bind(this);
    this.getNewEndTime = this.getNewEndTime.bind(this);
    this.getNewStartTime = this.getNewStartTime.bind(this);
    this.endSelect = React.createRef();
    this.startSelect = React.createRef();
    this.checkThePast = this.checkThePast.bind(this);
    this.deleteBooking = this.deleteBooking.bind(this);
    // this.submitEditUser = this.submitEditUser.bind(this)
    this.setEditAll = this.setEditAll.bind(this);
    this.setDeleteAll = this.setDeleteAll.bind(this);
    this.validateSubmit = this.validateSubmit.bind(this);
    this.handleUsersChange = this.handleUsersChange.bind(this);
    this.today = moment(this.props.currentDay, "MM-DD-YYYY").isSame(
      moment().format("YYYY-MM-DD")
    );
    this.dateValues = {
      "08:00": 0,
      "08:15": 1,
      "08:30": 2,
      "08:45": 3,
      "09:00": 4,
      "09:15": 5,
      "09:30": 6,
      "09:45": 7,
      "10:00": 8,
      "10:15": 9,
      "10:30": 10,
      "10:45": 11,
      "11:00": 12,
      "11:15": 13,
      "11:30": 14,
      "11:45": 15,
      "12:00": 16,
      "12:15": 17,
      "12:30": 18,
      "12:45": 19,
      "13:00": 20,
      "13:15": 21,
      "13:30": 22,
      "13:45": 23,
      "14:00": 24,
      "14:15": 25,
      "14:30": 26,
      "14:45": 27,
      "15:00": 28,
      "15:15": 29,
      "15:30": 30,
      "15:45": 31,
      "16:00": 32,
      "16:15": 33,
      "16:30": 34,
      "16:45": 35,
      "17:00": 36,
      "17:15": 37,
      "17:30": 38,
      "17:45": 39,
      "18:00": 40,
    };
    this.dateValues2 = {
      "08:00:00": 0,
      "08:15:00": 1,
      "08:30:00": 2,
      "08:45:00": 3,
      "09:00:00": 4,
      "09:15:00": 5,
      "09:30:00": 6,
      "09:45:00": 7,
      "10:00:00": 8,
      "10:15:00": 9,
      "10:30:00": 10,
      "10:45:00": 11,
      "11:00:00": 12,
      "11:15:00": 13,
      "11:30:00": 14,
      "11:45:00": 15,
      "12:00:00": 16,
      "12:15:00": 17,
      "12:30:00": 18,
      "12:45:00": 19,
      "13:00:00": 20,
      "13:15:00": 21,
      "13:30:00": 22,
      "13:45:00": 23,
      "14:00:00": 24,
      "14:15:00": 25,
      "14:30:00": 26,
      "14:45:00": 27,
      "15:00:00": 28,
      "15:15:00": 29,
      "15:30:00": 30,
      "15:45:00": 31,
      "16:00:00": 32,
      "16:15:00": 33,
      "16:30:00": 34,
      "16:45:00": 35,
      "17:00:00": 36,
      "17:15:00": 37,
      "17:30:00": 38,
      "17:45:00": 39,
      "18:00:00": 40,
    };
    this.easyTime = {
      "08:00": "8:00",
      "08:15": "8:15",
      "08:30": "8:30",
      "08:45": "8:45",
      "09:00": "9:00",
      "09:15": "9:15",
      "09:30": "9:30",
      "09:45": "9:45",
      "10:00": "10:00",
      "10:15": "10:15",
      "10:30": "10:30",
      "10:45": "10:45",
      "11:00": "11:00",
      "11:15": "11:15",
      "11:30": "11:30",
      "11:45": "11:45",
      "12:00": "12:00",
      "12:15": "12:15",
      "12:30": "12:30",
      "12:45": "12:45",
      "13:00": "1:00",
      "13:15": "1:15",
      "13:30": "1:30",
      "13:45": "1:45",
      "14:00": "2:00",
      "14:15": "2:15",
      "14:30": "2:30",
      "14:45": "2:45",
      "15:00": "3:00",
      "15:15": "3:15",
      "15:30": "3:30",
      "15:45": "3:45",
      "16:00": "4:00",
      "16:15": "4:15",
      "16:30": "4:30",
      "16:45": "4:45",
      "17:00": "5:00",
      "17:15": "5:15",
      "17:30": "5:30",
      "17:45": "5:45",
      "18:00": "6:00",
    };
    this.armyStrong = {
      "08:00:00": "08:00:00",
      "08:15:00": "08:15:00",
      "08:30:00": "08:30:00",
      "08:45:00": "08:45:00",
      "09:00:00": "09:00:00",
      "09:15:00": "09:15:00",
      "09:30:00": "09:30:00",
      "09:45:00": "09:45:00",
      "10:00:00": "10:00:00",
      "10:15:00": "10:15:00",
      "10:30:00": "10:30:00",
      "10:45:00": "10:45:00",
      "11:00:00": "11:00:00",
      "11:15:00": "11:15:00",
      "11:30:00": "11:30:00",
      "11:45:00": "11:45:00",
      "12:00:00": "12:00:00",
      "12:15:00": "12:15:00",
      "12:30:00": "12:30:00",
      "12:45:00": "12:45:00",
      "13:00:00": "13:00:00",
      "13:15:00": "13:15:00",
      "13:30:00": "13:30:00",
      "13:45:00": "13:45:00",
      "14:00:00": "14:00:00",
      "14:15:00": "14:15:00",
      "14:30:00": "14:30:00",
      "14:45:00": "14:45:00",
      "15:00:00": "15:00:00",
      "15:15:00": "15:15:00",
      "15:30:00": "15:30:00",
      "15:45:00": "15:45:00",
      "16:00:00": "16:00:00",
      "16:15:00": "16:15:00",
      "16:30:00": "16:30:00",
      "16:45:00": "16:45:00",
      "17:00:00": "17:00:00",
      "17:15:00": "17:15:00",
      "17:30:00": "17:30:00",
      "17:45:00": "17:45:00",
      "18:00:00": "18:00:00",
    };
    this.newTimes = {
      "08:00:00": 800,
      "08:15:00": 825,
      "08:30:00": 850,
      "08:45:00": 875,
      "09:00:00": 900,
      "09:15:00": 925,
      "09:30:00": 950,
      "09:45:00": 975,
      "10:00:00": 1000,
      "10:15:00": 1025,
      "10:30:00": 1050,
      "10:45:00": 1075,
      "11:00:00": 1100,
      "11:15:00": 1125,
      "11:30:00": 1150,
      "11:45:00": 1175,
      "12:00:00": 1200,
      "12:15:00": 1225,
      "12:30:00": 1250,
      "12:45:00": 1275,
      "13:00:00": 1300,
      "13:15:00": 1325,
      "13:30:00": 1350,
      "13:45:00": 1375,
      "14:00:00": 1400,
      "14:15:00": 1425,
      "14:30:00": 1450,
      "14:45:00": 1475,
      "15:00:00": 1500,
      "15:15:00": 1525,
      "15:30:00": 1550,
      "15:45:00": 1575,
      "16:00:00": 1600,
      "16:15:00": 1625,
      "16:30:00": 1650,
      "16:45:00": 1675,
      "17:00:00": 1700,
      "17:15:00": 1725,
      "17:30:00": 1750,
      "17:45:00": 1775,
      "18:00:00": 1800,
    };
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    $("select").formSelect();
    if (this.props.booking && this.props.booking.multi_booking_id) {
      axios
        .get(
          `/recurring_booking_weeks/${this.props.booking.multi_booking_id}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.setState({
            weeks: res.data.length - 1,
            originalWeeks: res.data.length - 1,
            existingMultiBookings: res.data,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }

    const start = moment();
    const remainder = start.minute() % 15;
    const dateTime = moment(start)
      .subtract(remainder, "minutes")
      .format("HH:mm");
    const time_value = this.dateValues[dateTime];
    this.setState({ time_value });
  }

  handleSubmit(duration) {
    if (this.props.action === "edit") {
      if (this.state.weeks < this.state.originalWeeks) {
        if (
          confirm(
            "You have reduced the number of recurring weeks for this series. One or more bookings will be deleted. Continue?"
          ) === false
        ) {
          return;
        }
      }
      axios
        .patch(`/bookings/${this.props.booking.id}.json`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          booking: {
            // user_id: this.props.currentUser.id,
            duration,
            name: this.state.meeting,
            booking_date: this.getBookingDate(),
            meeting_type: this.state.type,
            description: this.state.description,
            room_id:
              typeof this.props.room === "object"
                ? this.props.room.room_id
                : this.props.room,
            multi_booking_id: this.props.booking.multi_booking_id,
            attendees: this.state.users,
          },
          multi_booking: this.state.editAll
            ? { weeks: this.state.weeks }
            : false,
        })
        .then((res) => {
          M.toast({
            html: `Booking${this.state.editAll ? "s" : ""} edited`,
            classes: "green",
          });
          if (res.data.hasOwnProperty("name")) {
            this.props.editHandler(res.data);
          } else {
            this.props.deleteHandler(res.data);
          }
          document.body.style.overflow = "";
          ReactDOM.unmountComponentAtNode(
            document.getElementById("modalContainer")
          );
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      axios
        .post(`/bookings.json`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          booking: {
            user_id: this.props.currentUser.id,
            duration,
            name: this.state.meeting,
            booking_date: this.getBookingDate(),
            meeting_type: this.state.type,
            description: this.state.description,
            room_id:
              typeof this.props.room === "object"
                ? this.props.room.room_id
                : this.props.room,
            multi_booking_id: this.state.multiBookingId,
            attendees: this.state.users,
          },
          multi_booking: this.state.multi ? { weeks: this.state.weeks } : false,
        })
        .then((res) => {
          M.toast({
            html: `Booking${this.state.multi ? "s" : ""} created`,
            classes: "green",
          });
          this.props.bookingHandler(res.data);
          document.body.style.overflow = "";
          ReactDOM.unmountComponentAtNode(
            document.getElementById("modalContainer")
          );
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  getMeetingName(event) {
    this.setState({ meeting: event.target.value });
  }

  getDescription(event) {
    this.setState({ description: event.target.value });
  }

  getUsers(value) {
    this.setState({ users: value });
  }

  getStartTime(event) {
    this.setState({ startTime: event.target.value }, () => {
      this.getNewEndTime();
    });
  }

  getNewStartTime() {
    if (this.state.endTime <= this.state.startTime) {
      const startTimeValue = this.dateValues2[this.state.endTime] - 1;
      this.setState({ startTime: this.valueArray[startTimeValue] });
      this.startSelect.current._selectRef.parentElement.children[0].value =
        this.easyTime[this.valueArray[startTimeValue].substring(0, 5)];
      this.startSelect.current.state.value = this.valueArray[startTimeValue];
      this.setState({ trigger: true });
    }
  }

  checkThePast() {
    if (
      moment(this.props.currentDay, "MM-DD-YYYY").isSame(
        moment().format("YYYY-MM-DD")
      )
    ) {
      if (this.state.time_value > this.dateValues[this.state.startTime]) {
        return true;
      }
      return false;
    }
    return false;
  }

  getNewEndTime() {
    if (this.state.startTime >= this.state.endTime) {
      const endTimeValue = this.dateValues2[this.state.startTime] + 1;
      this.setState({ endTime: this.valueArray[endTimeValue] });
      this.endSelect.current._selectRef.parentElement.children[0].value =
        this.easyTime[this.valueArray[endTimeValue].substring(0, 5)];
      this.endSelect.current.state.value = this.valueArray[endTimeValue];
      this.setState({ trigger: true });
    }
  }

  getEndTime(event) {
    this.setState({ endTime: event.target.value }, () => {
      this.getNewStartTime();
    });
  }

  getType(event) {
    const { checked } = event.target;
    if (checked) {
      if (this.props.roomObject.allow_client) {
        this.setState({ type: "client" });
      } else {
        Swal.fire({
          title: "",
          text: "This room is not usually booked for Clients, are you sure?",
          type: "question",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            this.setState({ type: "client" });
          }
        });
      }
    } else {
      this.setState({ type: "team" });
    }
  }

  setMulti(event) {
    const { checked } = event.target;
    this.setState({ multi: checked });
    if (checked) {
      axios
        .get("/recurring_booking", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            start_date: moment(this.props.currentDay),
            recurring_num: this.state.weeks,
            room_id: this.props.room,
          },
        })
        .then((res) => {
          this.setState({
            multiBookings: res.data,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  weeksHandler(weeks) {
    this.setState({ weeks });
  }

  getDuration() {
    if (this.state.trigger === true) {
      const endTime = this.armyStrong[this.state.endTime];
      const duration =
        ((this.newTimes[endTime] - this.newTimes[this.state.startTime]) / 100) *
        60;
      if (duration < 0) {
        M.toast({
          html: "Looks like the end time you've selected is before your start time, please select a valid end time.",
          classes: "danger",
        });
      } else {
        return duration;
      }
    } else {
      const duration =
        ((this.newTimes[this.state.endTime] -
          this.newTimes[this.state.startTime]) /
          100) *
        60;
      if (duration < 0) {
        M.toast({
          html: "Looks like the end time you've selected is before your start time, please select a valid end time.",
          classes: "danger",
        });
      } else {
        return duration;
      }
    }
  }

  getBookingDate() {
    const bookingDate = moment(this.props.currentDay, "MM-DD-YYYY").format(
      "YYYY/MM/DD"
    );
    const bookingTime = this.state.startTime;
    const booking_date = `${bookingDate} ${bookingTime}`;
    return booking_date;
  }

  validateSubmit(event) {
    event.preventDefault();
    const otherDate = moment(this.props.currentDay, "MM-DD-YYYY")
      .format("YYYY-MM-DD")
      .toString();
    const today = moment().format("YYYY-MM-DD").toString();
    if (!moment(otherDate).isSameOrAfter(today, "day")) {
      Swal.fire({
        title: "",
        text: "This Meeting will be scheduled in the past, are you sure you wanna do that?",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.preSubmit();
        }
      });
    } else if (this.checkThePast() === true) {
      Swal.fire({
        title: "",
        text: "This Meeting will be scheduled in the past, are you sure you wanna do that?",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.preSubmit();
        }
      });
    } else {
      this.preSubmit();
    }
  }

  preSubmit() {
    // event.preventDefault()

    if (this.state.multi) {
      axios
        .get("/recurring_booking", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            start_date: moment(this.props.currentDay),
            recurring_num: this.state.weeks,
            room_id: this.props.room,
          },
        })
        .then((res) => {
          const startTimeValue = this.dateValues2[this.state.startTime];
          const endTimeValue = this.dateValues2[this.state.endTime];
          var valueCounter = [];
          const dayValueCounter = [];
          const multiBookings = res.data;
          for (let i = 0; i < multiBookings.length; i++) {
            for (let j = 0; j < multiBookings[i].length; j++) {
              const { booking_array } = multiBookings[i][j];
              if (this.props.bookingId === multiBookings[i][j].id) {
              } else {
                for (let t = startTimeValue; t <= endTimeValue - 1; t++) {
                  const multi_booking_id = this.props.booking
                    ? this.props.booking.multi_booking_id
                    : 0;
                  if (
                    booking_array[t] === 1 &&
                    multiBookings[i][j].multi_booking_id != multi_booking_id
                  ) {
                    valueCounter.push(1);
                  } else {
                    valueCounter.push(0);
                  }
                  if (valueCounter.includes(1)) {
                    dayValueCounter.push("bad");
                    var valueCounter = [];
                  } else {
                    dayValueCounter.push("good");
                    var valueCounter = [];
                  }
                }
              }
            }
          }
          if (dayValueCounter.includes("bad")) {
            M.toast({
              html: "The time slot you have selected in already booked by somebody else, select another time and try again",
              classes: "danger",
            });
          } else {
            const duration = this.getDuration();
            this.handleSubmit(duration);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      const startTimeValue = this.dateValues2[this.state.startTime];
      const endTimeValue = this.dateValues2[this.state.endTime];
      var valueCounter = [];
      const dayValueCounter = [];
      if (this.props.bookings.length > 0) {
        for (let i = 0; i < this.props.bookings.length; i++) {
          const { booking_array } = this.props.bookings[i];
          if (this.props.bookingId === this.props.bookings[i].id) {
          } else {
            for (let t = startTimeValue; t <= endTimeValue - 1; t++) {
              if (booking_array[t] === 1) {
                valueCounter.push(1);
              } else {
                valueCounter.push(0);
              }
              if (valueCounter.includes(1)) {
                dayValueCounter.push("bad");
                var valueCounter = [];
              } else {
                dayValueCounter.push("good");
                var valueCounter = [];
              }
            }
          }
        }
        if (dayValueCounter.includes("bad")) {
          M.toast({
            html: "The time slot you have selected in already booked by somebody else, select another time and try again",
            classes: "danger",
          });
        } else {
          var duration = this.getDuration();
          this.handleSubmit(duration);
        }
      } else {
        var duration = this.getDuration();
        this.handleSubmit(duration);
      }
    }
  }

  deleteBooking() {
    if (this.state.deleteAll) {
      if (
        confirm("Are you sure you want to delete this series of bookings?") ===
        true
      ) {
        axios
          .delete(
            `/bookings/multi_destroy/${this.props.booking.multi_booking_id}.json`
          )
          .then((res) => {
            M.toast({
              html: `Bookings deleted`,
              displayLength: 3000,
              classes: "green",
            });
          })
          .catch((err) => console.error(err));
        this.props.deleteHandler(this.props.bookingId);
      }
    } else if (this.props.booking.multi_booking_id) {
      if (
        confirm(
          "Are you sure you want to delete this booking from the series?"
        ) === true
      ) {
        axios
          .delete(
            `/bookings/multi_single_destroy/${this.props.bookingId}/${this.props.booking.multi_booking_id}`
          )
          .then((res) => {
            M.toast({
              html: `Booking deleted`,
              displayLength: 3000,
              classes: "green",
            });
          })
          .catch((err) => console.error(err));
        this.props.deleteHandler(this.props.bookingId);
      }
    } else if (
      confirm("Are you sure you want to delete this booking?") === true
    ) {
      axios
        .delete(`/bookings/${this.props.bookingId}.json`)
        .then((res) => {
          M.toast({
            html: `Booking deleted`,
            displayLength: 3000,
            classes: "green",
          });
        })
        .catch((err) => console.error(err));
      this.props.deleteHandler(this.props.bookingId);
    }
  }

  setEditAll() {
    this.setState({
      editAll: !this.state.editAll,
      weeks: this.state.editAll ? this.state.originalWeeks : this.state.weeks,
    });
  }

  setDeleteAll() {
    this.setState({
      deleteAll: !this.state.deleteAll,
    });
  }

  handleUsersChange(value) {
    this.setState({ users: value });
  }

  render() {
    const object = this.props;
    var deleteButton;
    let submit = "Book Room";
    if (object.action === "edit") {
      submit = "Edit Booking";
      var deleteButton = (
        <div style={{ display: "inline-block" }}>
          <div className="btn danger" onClick={this.deleteBooking}>
            Delete
          </div>
          {this.props.booking.multi_booking_id ? (
            <label className="task-label" htmlFor="delete_all">
              <input
                type="checkbox"
                name="multitask[days][]"
                id="delete_all"
                value="1"
                onClick={this.setDeleteAll}
              />
              <span
                style={{
                  display: "block",
                  paddingLeft: "20px",
                  marginTop: "5px",
                }}
              >
                Delete all bookings in series
              </span>
            </label>
          ) : (
            ""
          )}
        </div>
      );
    }
    var form = (
      <form onSubmit={this.validateSubmit}>
        <div className="col s12 row">
          <p
            className="col s12 l6"
            style={{ marginTop: "17px", marginRight: "10px", width: "50%" }}
          >
            Meeting Owner:{" "}
            {this.props.action === "edit"
              ? this.props.booking.user.name
              : this.props.currentUser.name}
          </p>
          <p
            className="col s3 push-s3"
            style={{ fontSize: `${36}px`, margin: "0px" }}
          >
            {moment(this.props.currentDay, "MM-DD-YYYY").format("MMM Do")}{" "}
          </p>
        </div>
        <div className="col s12 row">
          <div className="col s valign-wrapper">
            <p className="left">Start time:</p>
            <select
              id="select-9"
              style={{ width: "27%", textAlign: "center" }}
              className="select"
              value={this.state.startTime}
              onChange={this.getStartTime}
            >
              <option
                disabled={this.today && this.timeIndex > 0}
                value="08:00:00"
              >
                8:00
              </option>
              <option
                disabled={this.today && this.timeIndex > 1}
                value="08:15:00"
              >
                8:15
              </option>
              <option
                disabled={this.today && this.timeIndex > 2}
                value="08:30:00"
              >
                8:30
              </option>
              <option
                disabled={this.today && this.timeIndex > 3}
                value="08:45:00"
              >
                8:45
              </option>
              <option
                disabled={this.today && this.timeIndex > 4}
                value="09:00:00"
              >
                9:00
              </option>
              <option
                disabled={this.today && this.timeIndex > 5}
                value="09:15:00"
              >
                9:15
              </option>
              <option
                disabled={this.today && this.timeIndex > 6}
                value="09:30:00"
              >
                9:30
              </option>
              <option
                disabled={this.today && this.timeIndex > 7}
                value="09:45:00"
              >
                9:45
              </option>
              <option
                disabled={this.today && this.timeIndex > 8}
                value="10:00:00"
              >
                10:00
              </option>
              <option
                disabled={this.today && this.timeIndex > 9}
                value="10:15:00"
              >
                10:15
              </option>
              <option
                disabled={this.today && this.timeIndex > 10}
                value="10:30:00"
              >
                10:30
              </option>
              <option
                disabled={this.today && this.timeIndex > 11}
                value="10:45:00"
              >
                10:45
              </option>
              <option
                disabled={this.today && this.timeIndex > 12}
                value="11:00:00"
              >
                11:00
              </option>
              <option
                disabled={this.today && this.timeIndex > 13}
                value="11:15:00"
              >
                11:15
              </option>
              <option
                disabled={this.today && this.timeIndex > 14}
                value="11:30:00"
              >
                11:30
              </option>
              <option
                disabled={this.today && this.timeIndex > 15}
                value="11:45:00"
              >
                11:45
              </option>
              <option
                disabled={this.today && this.timeIndex > 16}
                value="12:00:00"
              >
                12:00
              </option>
              <option
                disabled={this.today && this.timeIndex > 17}
                value="12:15:00"
              >
                12:15
              </option>
              <option
                disabled={this.today && this.timeIndex > 18}
                value="12:30:00"
              >
                12:30
              </option>
              <option
                disabled={this.today && this.timeIndex > 19}
                value="12:45:00"
              >
                12:45
              </option>
              <option
                disabled={this.today && this.timeIndex > 20}
                value="13:00:00"
              >
                1:00
              </option>
              <option
                disabled={this.today && this.timeIndex > 21}
                value="13:15:00"
              >
                1:15
              </option>
              <option
                disabled={this.today && this.timeIndex > 22}
                value="13:30:00"
              >
                1:30
              </option>
              <option
                disabled={this.today && this.timeIndex > 23}
                value="13:45:00"
              >
                1:45
              </option>
              <option
                disabled={this.today && this.timeIndex > 24}
                value="14:00:00"
              >
                2:00
              </option>
              <option
                disabled={this.today && this.timeIndex > 25}
                value="14:15:00"
              >
                2:15
              </option>
              <option
                disabled={this.today && this.timeIndex > 26}
                value="14:30:00"
              >
                2:30
              </option>
              <option
                disabled={this.today && this.timeIndex > 27}
                value="14:45:00"
              >
                2:45
              </option>
              <option
                disabled={this.today && this.timeIndex > 28}
                value="15:00:00"
              >
                3:00
              </option>
              <option
                disabled={this.today && this.timeIndex > 29}
                value="15:15:00"
              >
                3:15
              </option>
              <option
                disabled={this.today && this.timeIndex > 30}
                value="15:30:00"
              >
                3:30
              </option>
              <option
                disabled={this.today && this.timeIndex > 31}
                value="15:45:00"
              >
                3:45
              </option>
              <option
                disabled={this.today && this.timeIndex > 32}
                value="16:00:00"
              >
                4:00
              </option>
              <option
                disabled={this.today && this.timeIndex > 33}
                value="16:15:00"
              >
                4:15
              </option>
              <option
                disabled={this.today && this.timeIndex > 34}
                value="16:30:00"
              >
                4:30
              </option>
              <option
                disabled={this.today && this.timeIndex > 35}
                value="16:45:00"
              >
                4:45
              </option>
              <option
                disabled={this.today && this.timeIndex > 36}
                value="17:00:00"
              >
                5:00
              </option>
              <option
                disabled={this.today && this.timeIndex > 37}
                value="17:15:00"
              >
                5:15
              </option>
              <option
                disabled={this.today && this.timeIndex > 38}
                value="17:30:00"
              >
                5:30
              </option>
              <option
                disabled={this.today && this.timeIndex > 39}
                value="17:45:00"
              >
                5:45
              </option>
            </select>
          </div>
          <div
            className="col s6 valign-wrapper"
            style={{ paddingLeft: "=20px" }}
          >
            <p className="left">End time:</p>
            <select
              id="select-10"
              style={{ width: "27%" }}
              className="select"
              value={this.state.endTime}
              onChange={this.getEndTime}
            >
              <option
                disabled={this.today && this.timeIndex >= 1}
                value="08:15:00"
              >
                8:15
              </option>
              <option
                disabled={this.today && this.timeIndex >= 2}
                value="08:30:00"
              >
                8:30
              </option>
              <option
                disabled={this.today && this.timeIndex >= 3}
                value="08:45:00"
              >
                8:45
              </option>
              <option
                disabled={this.today && this.timeIndex >= 4}
                value="09:00:00"
              >
                9:00
              </option>
              <option
                disabled={this.today && this.timeIndex >= 5}
                value="09:15:00"
              >
                9:15
              </option>
              <option
                disabled={this.today && this.timeIndex >= 6}
                value="09:30:00"
              >
                9:30
              </option>
              <option
                disabled={this.today && this.timeIndex >= 7}
                value="09:45:00"
              >
                9:45
              </option>
              <option
                disabled={this.today && this.timeIndex >= 8}
                value="10:00:00"
              >
                10:00
              </option>
              <option
                disabled={this.today && this.timeIndex >= 9}
                value="10:15:00"
              >
                10:15
              </option>
              <option
                disabled={this.today && this.timeIndex >= 10}
                value="10:30:00"
              >
                10:30
              </option>
              <option
                disabled={this.today && this.timeIndex >= 11}
                value="10:45:00"
              >
                10:45
              </option>
              <option
                disabled={this.today && this.timeIndex >= 12}
                value="11:00:00"
              >
                11:00
              </option>
              <option
                disabled={this.today && this.timeIndex >= 13}
                value="11:15:00"
              >
                11:15
              </option>
              <option
                disabled={this.today && this.timeIndex >= 14}
                value="11:30:00"
              >
                11:30
              </option>
              <option
                disabled={this.today && this.timeIndex >= 15}
                value="11:45:00"
              >
                11:45
              </option>
              <option
                disabled={this.today && this.timeIndex >= 16}
                value="12:00:00"
              >
                12:00
              </option>
              <option
                disabled={this.today && this.timeIndex >= 17}
                value="12:15:00"
              >
                12:15
              </option>
              <option
                disabled={this.today && this.timeIndex >= 18}
                value="12:30:00"
              >
                12:30
              </option>
              <option
                disabled={this.today && this.timeIndex >= 19}
                value="12:45:00"
              >
                12:45
              </option>
              <option
                disabled={this.today && this.timeIndex >= 20}
                value="13:00:00"
              >
                1:00
              </option>
              <option
                disabled={this.today && this.timeIndex >= 21}
                value="13:15:00"
              >
                1:15
              </option>
              <option
                disabled={this.today && this.timeIndex >= 22}
                value="13:30:00"
              >
                1:30
              </option>
              <option
                disabled={this.today && this.timeIndex >= 23}
                value="13:45:00"
              >
                1:45
              </option>
              <option
                disabled={this.today && this.timeIndex >= 24}
                value="14:00:00"
              >
                2:00
              </option>
              <option
                disabled={this.today && this.timeIndex >= 25}
                value="14:15:00"
              >
                2:15
              </option>
              <option
                disabled={this.today && this.timeIndex >= 26}
                value="14:30:00"
              >
                2:30
              </option>
              <option
                disabled={this.today && this.timeIndex >= 27}
                value="14:45:00"
              >
                2:45
              </option>
              <option
                disabled={this.today && this.timeIndex >= 28}
                value="15:00:00"
              >
                3:00
              </option>
              <option
                disabled={this.today && this.timeIndex >= 29}
                value="15:15:00"
              >
                3:15
              </option>
              <option
                disabled={this.today && this.timeIndex >= 30}
                value="15:30:00"
              >
                3:30
              </option>
              <option
                disabled={this.today && this.timeIndex >= 31}
                value="15:45:00"
              >
                3:45
              </option>
              <option
                disabled={this.today && this.timeIndex >= 32}
                value="16:00:00"
              >
                4:00
              </option>
              <option
                disabled={this.today && this.timeIndex >= 33}
                value="16:15:00"
              >
                4:15
              </option>
              <option
                disabled={this.today && this.timeIndex >= 34}
                value="16:30:00"
              >
                4:30
              </option>
              <option
                disabled={this.today && this.timeIndex >= 35}
                value="16:45:00"
              >
                4:45
              </option>
              <option
                disabled={this.today && this.timeIndex >= 36}
                value="17:00:00"
              >
                5:00
              </option>
              <option
                disabled={this.today && this.timeIndex >= 37}
                value="17:15:00"
              >
                5:15
              </option>
              <option
                disabled={this.today && this.timeIndex >= 38}
                value="17:30:00"
              >
                5:30
              </option>
              <option
                disabled={this.today && this.timeIndex >= 39}
                value="17:45:00"
              >
                5:45
              </option>
              <option
                disabled={this.today && this.timeIndex >= 40}
                value="18:00:00"
              >
                6:00
              </option>
            </select>
          </div>
          <div className="col s12 row">
            <p className="s11">Number of Attendees: </p>

            <Slider
              min={1}
              max={this.props.roomCapacity}
              value={this.state.users}
              handleLabel={this.state.users.toString()}
              onChange={this.handleUsersChange}
            />
          </div>
          <input
            type="text"
            name="meeting"
            placeholder="Meeting Name (Optional)"
            value={this.state.meeting ? this.state.meeting : ""}
            onChange={this.getMeetingName}
          />
          <div className="col s12 row">
            <Row>
              <Textarea
                type="text"
                name="description"
                placeholder="Description (optional)"
                value={this.state.description ? this.state.description : ""}
                onChange={this.getDescription}
                s={12}
                m={12}
                l={12}
                xl={12}
              />
            </Row>
          </div>
          {object.action === "edit" ? (
            ""
          ) : (
            <div className="col s12 valign-wrapper">
              <p className="">Type of Meeting:</p>
              <div
                className=""
                style={{ marginLeft: "10px", marginRight: "20px" }}
              >
                <Checkbox
                  id="bookingSwitch"
                  label="Client Meeting"
                  value="client"
                  onChange={this.getType}
                />
              </div>
              <div className="">
                <Checkbox
                  id="weekSwitch"
                  label="Recurring"
                  value="recurring"
                  onChange={this.setMulti}
                />
              </div>
            </div>
          )}
          <div
            style={{ display: this.state.multi ? "initial" : "none" }}
            className="col s12"
          >
            {this.state.multi ? (
              <MultiSelect
                editAll={this.state.editAll}
                bookings={this.state.existingMultiBookings}
                multiId={
                  this.props.booking
                    ? this.props.booking.multi_booking_id !== null
                    : false
                }
                currentDay={this.props.currentDay}
                weeks={this.state.weeks}
                weeksHandler={this.weeksHandler}
              />
            ) : null}
          </div>
          <div
            style={{
              display:
                this.props.booking != undefined
                  ? this.props.currentUser.id === this.props.booking.user.id ||
                    this.props.booking.user.name === "Room View User"
                    ? "initial"
                    : "none"
                  : "initial",
              height: "33px",
            }}
            className="col s12"
          >
            {deleteButton}
            <div style={{ display: "inline-block", float: "right" }}>
              <Button className="right common-button-submit" type="submit">
                {submit}
              </Button>
              <div>
                <label className="task-label" htmlFor="edit_all">
                  <input
                    type="checkbox"
                    name="multitask[days][]"
                    id="edit_all"
                    value="1"
                    onClick={this.setEditAll}
                  />
                  {object.action === "edit" && this.state.multi ? (
                    <span
                      style={{
                        display: "block",
                        paddingLeft: "20px",
                        marginTop: "5px",
                        float: "right",
                      }}
                    >
                      Edit all bookings in series
                    </span>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
    if (
      object.action === "edit" &&
      this.props.currentUser.id != this.props.booking.user.id &&
      this.props.booking.user.name != "Room View User"
    ) {
      var form = (
        <form onSubmit={this.validateSubmit}>
          <div className="col s12 row">
            <p
              className="col s12 l6"
              style={{ marginTop: "17px", marginRight: "10px" }}
            >
              Meeting Owner:{" "}
              {this.props.action === "edit"
                ? this.props.booking.user.name
                : this.props.currentUser.name}
            </p>
            <p
              className="col s3 push-s3"
              style={{ fontSize: `${36}px`, margin: "0px" }}
            >
              {moment(this.props.currentDay, "MM-DD-YYYY").format("MMM Do")}{" "}
            </p>
          </div>
          <div className="col s12 row">
            <div className="col s valign-wrapper">
              <p className="left">Start time:</p>
              <Select
                disabled
                ref={this.startSelect}
                style={{ width: "27%" }}
                className="select"
                value={this.state.startTime}
                onChange={this.getStartTime}
              >
                <option
                  disabled={this.today && this.timeIndex > 0}
                  value="08:00:00"
                >
                  8:00
                </option>
                <option
                  disabled={this.today && this.timeIndex > 1}
                  value="08:15:00"
                >
                  8:15
                </option>
                <option
                  disabled={this.today && this.timeIndex > 2}
                  value="08:30:00"
                >
                  8:30
                </option>
                <option
                  disabled={this.today && this.timeIndex > 3}
                  value="08:45:00"
                >
                  8:45
                </option>
                <option
                  disabled={this.today && this.timeIndex > 4}
                  value="09:00:00"
                >
                  9:00
                </option>
                <option
                  disabled={this.today && this.timeIndex > 5}
                  value="09:15:00"
                >
                  9:15
                </option>
                <option
                  disabled={this.today && this.timeIndex > 6}
                  value="09:30:00"
                >
                  9:30
                </option>
                <option
                  disabled={this.today && this.timeIndex > 7}
                  value="09:45:00"
                >
                  9:45
                </option>
                <option
                  disabled={this.today && this.timeIndex > 8}
                  value="10:00:00"
                >
                  10:00
                </option>
                <option
                  disabled={this.today && this.timeIndex > 9}
                  value="10:15:00"
                >
                  10:15
                </option>
                <option
                  disabled={this.today && this.timeIndex > 10}
                  value="10:30:00"
                >
                  10:30
                </option>
                <option
                  disabled={this.today && this.timeIndex > 11}
                  value="10:45:00"
                >
                  10:45
                </option>
                <option
                  disabled={this.today && this.timeIndex > 12}
                  value="11:00:00"
                >
                  11:00
                </option>
                <option
                  disabled={this.today && this.timeIndex > 13}
                  value="11:15:00"
                >
                  11:15
                </option>
                <option
                  disabled={this.today && this.timeIndex > 14}
                  value="11:30:00"
                >
                  11:30
                </option>
                <option
                  disabled={this.today && this.timeIndex > 15}
                  value="11:45:00"
                >
                  11:45
                </option>
                <option
                  disabled={this.today && this.timeIndex > 16}
                  value="12:00:00"
                >
                  12:00
                </option>
                <option
                  disabled={this.today && this.timeIndex > 17}
                  value="12:15:00"
                >
                  12:15
                </option>
                <option
                  disabled={this.today && this.timeIndex > 18}
                  value="12:30:00"
                >
                  12:30
                </option>
                <option
                  disabled={this.today && this.timeIndex > 19}
                  value="12:45:00"
                >
                  12:45
                </option>
                <option
                  disabled={this.today && this.timeIndex > 20}
                  value="13:00:00"
                >
                  1:00
                </option>
                <option
                  disabled={this.today && this.timeIndex > 21}
                  value="13:15:00"
                >
                  1:15
                </option>
                <option
                  disabled={this.today && this.timeIndex > 22}
                  value="13:30:00"
                >
                  1:30
                </option>
                <option
                  disabled={this.today && this.timeIndex > 23}
                  value="13:45:00"
                >
                  1:45
                </option>
                <option
                  disabled={this.today && this.timeIndex > 24}
                  value="14:00:00"
                >
                  2:00
                </option>
                <option
                  disabled={this.today && this.timeIndex > 25}
                  value="14:15:00"
                >
                  2:15
                </option>
                <option
                  disabled={this.today && this.timeIndex > 26}
                  value="14:30:00"
                >
                  2:30
                </option>
                <option
                  disabled={this.today && this.timeIndex > 27}
                  value="14:45:00"
                >
                  2:45
                </option>
                <option
                  disabled={this.today && this.timeIndex > 28}
                  value="15:00:00"
                >
                  3:00
                </option>
                <option
                  disabled={this.today && this.timeIndex > 29}
                  value="15:15:00"
                >
                  3:15
                </option>
                <option
                  disabled={this.today && this.timeIndex > 30}
                  value="15:30:00"
                >
                  3:30
                </option>
                <option
                  disabled={this.today && this.timeIndex > 31}
                  value="15:45:00"
                >
                  3:45
                </option>
                <option
                  disabled={this.today && this.timeIndex > 32}
                  value="16:00:00"
                >
                  4:00
                </option>
                <option
                  disabled={this.today && this.timeIndex > 33}
                  value="16:15:00"
                >
                  4:15
                </option>
                <option
                  disabled={this.today && this.timeIndex > 34}
                  value="16:30:00"
                >
                  4:30
                </option>
                <option
                  disabled={this.today && this.timeIndex > 35}
                  value="16:45:00"
                >
                  4:45
                </option>
                <option
                  disabled={this.today && this.timeIndex > 36}
                  value="17:00:00"
                >
                  5:00
                </option>
                <option
                  disabled={this.today && this.timeIndex > 37}
                  value="17:15:00"
                >
                  5:15
                </option>
                <option
                  disabled={this.today && this.timeIndex > 38}
                  value="17:30:00"
                >
                  5:30
                </option>
                <option
                  disabled={this.today && this.timeIndex > 39}
                  value="17:45:00"
                >
                  5:45
                </option>
              </Select>
            </div>
            <div
              className="col s6 valign-wrapper"
              style={{ paddingLeft: "=20px" }}
            >
              <p className="left">End time:</p>
              <Select
                disabled
                ref={this.endSelect}
                style={{ width: "27%" }}
                className="select"
                value={this.state.endTime}
                onChange={this.getEndTime}
              >
                <option
                  disabled={this.today && this.timeIndex >= 1}
                  value="08:15:00"
                >
                  8:15
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 2}
                  value="08:30:00"
                >
                  8:30
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 3}
                  value="08:45:00"
                >
                  8:45
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 4}
                  value="09:00:00"
                >
                  9:00
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 5}
                  value="09:15:00"
                >
                  9:15
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 6}
                  value="09:30:00"
                >
                  9:30
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 7}
                  value="09:45:00"
                >
                  9:45
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 8}
                  value="10:00:00"
                >
                  10:00
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 9}
                  value="10:15:00"
                >
                  10:15
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 10}
                  value="10:30:00"
                >
                  10:30
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 11}
                  value="10:45:00"
                >
                  10:45
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 12}
                  value="11:00:00"
                >
                  11:00
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 13}
                  value="11:15:00"
                >
                  11:15
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 14}
                  value="11:30:00"
                >
                  11:30
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 15}
                  value="11:45:00"
                >
                  11:45
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 16}
                  value="12:00:00"
                >
                  12:00
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 17}
                  value="12:15:00"
                >
                  12:15
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 18}
                  value="12:30:00"
                >
                  12:30
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 19}
                  value="12:45:00"
                >
                  12:45
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 20}
                  value="13:00:00"
                >
                  1:00
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 21}
                  value="13:15:00"
                >
                  1:15
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 22}
                  value="13:30:00"
                >
                  1:30
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 23}
                  value="13:45:00"
                >
                  1:45
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 24}
                  value="14:00:00"
                >
                  2:00
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 25}
                  value="14:15:00"
                >
                  2:15
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 26}
                  value="14:30:00"
                >
                  2:30
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 27}
                  value="14:45:00"
                >
                  2:45
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 28}
                  value="15:00:00"
                >
                  3:00
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 29}
                  value="15:15:00"
                >
                  3:15
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 30}
                  value="15:30:00"
                >
                  3:30
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 31}
                  value="15:45:00"
                >
                  3:45
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 32}
                  value="16:00:00"
                >
                  4:00
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 33}
                  value="16:15:00"
                >
                  4:15
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 34}
                  value="16:30:00"
                >
                  4:30
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 35}
                  value="16:45:00"
                >
                  4:45
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 36}
                  value="17:00:00"
                >
                  5:00
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 37}
                  value="17:15:00"
                >
                  5:15
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 38}
                  value="17:30:00"
                >
                  5:30
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 39}
                  value="17:45:00"
                >
                  5:45
                </option>
                <option
                  disabled={this.today && this.timeIndex >= 40}
                  value="18:00:00"
                >
                  6:00
                </option>
              </Select>
            </div>
            <div className="col s12 row">
              <p className="s11">
                Number of Attendees:
                {this.state.users}
              </p>
            </div>
            <input
              disabled
              type="text"
              name="meeting"
              placeholder={
                this.props.booking.name ? this.props.booking.name : ""
              }
              value={this.props.booking.name ? this.props.booking.name : ""}
              onChange={this.getMeetingName}
            />
            <div className="col s12 row">
              <Row>
                <Textarea
                  disabled
                  type="text"
                  name="description"
                  placeholder={this.props.booking.description}
                  value={this.props.booking.description}
                  onChange={this.getDescription}
                  s={12}
                  m={12}
                  l={12}
                  xl={12}
                />
              </Row>
            </div>
            {this.state.type === "client" && <p>This is a client meeting</p>}
            <div
              style={{ display: this.state.multi ? "initial" : "none" }}
              className="col s12"
            >
              {this.state.multi &&
                this.state.existingMultiBookings.length > 0 && (
                  <p>
                    Recurring Meeting: Started{" "}
                    {moment(
                      this.state.existingMultiBookings[0].booking_date
                    ).format("MMM Do")}
                    , Recurring until{" "}
                    {moment(this.state.existingMultiBookings[0].booking_date)
                      .add(this.state.weeks, "w")
                      .format("MMM Do")}
                  </p>
                )}
            </div>
            <div
              style={{
                display:
                  this.props.booking != undefined
                    ? this.props.currentUser.id === this.props.booking.user.id
                      ? "initial"
                      : "none"
                    : "initial",
              }}
              className="col s12"
            >
              {deleteButton}
              <Button className="right common-button-submit" type="submit">
                {submit}
              </Button>
            </div>
          </div>
        </form>
      );
    }
    const roomUrls = {
      "Crater Lake": "crater.jpg",
      "Green room": "green.jpg",
      "Grey room": "grey.jpg",
      "Orange room": "orange.jpg",
      "Red room": "red.jpg",
      "Yellow room": "yellow.jpg",
      "Blue room": "blue.jpg",
      "Chinook Room": "chinook.jpg",
      "Shasta Room": "shasta.jpg",
      "Wasco Room": "wasco.jpg",
      "Lego Room": "lego.jpg",
    };
    return (
      <div>
        <div className="booking-modal">
          <div className="room-image-container">
            <MediaBox className="modal-picture materialboxed">
              <img
                alt="room"
                src={`https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/syncit/${
                  roomUrls[this.props.roomName]
                }`}
                width="650"
              />
            </MediaBox>
          </div>
        </div>
        {form}
      </div>
    );
  }
}

export default GridFormModal;
