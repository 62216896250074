import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Select from "react-select";
import SingleSelectStyles from "../../common/single_select_styles";

const LeadCard = styled.div`
  margin: 20px 0;
  padding: 0 10px;
  height: ;
`;

function ErrorStage(props) {
  return (
    <ErrorCard className="transition">
      <div />
    </ErrorCard>
  );
}

export default ErrorStage;
