import React from "react";
import ScheduleDateHeaders from "../master_schedule/schedule_date_headers";
import ReactModal from "../modals/react_modal";
import moment from "moment";
import ScheduleUserRow from "../master_schedule/schedule_user_row";
import RoundDropdown from "../projects/round_dropdown";
import PageNav from "../common/page_nav";
import { SingleDatePicker } from "react-dates";
import CalendarIcon from "../icons/calendar_icon.jsx";

const PersonalUserSchedule = ({
  twoWeeks,
  onWeekSwitchClick,
  renderDateDisplay,
  prevWeek,
  upcomingWeek,
  onDateChangePicker,
  onFocusChangePicker,
  pickerDate,
  pickerFocus,
  isDayBlocked,
  currentWeekStart,
  closures,
  tasks,
  user,
  setModal,
  modalOpen,
  currentModal,
  modalData,
}) => {
  return (
    <div>
      <PageNav
        twoWeeks={twoWeeks}
        weekHandler={onWeekSwitchClick}
        usedLetters={null}
        checkboxes={[]}
        scrollHandler={null}
        addHandler={null}
        addText={""}
        showSearch={false}
      />
      <div
        className="date-nav-wrapper"
        style={{ height: "140px", position: "relative" }}
      >
        <div className="date-nav">
          <div id="date-nav-header">
            <button
              aria-hidden="true"
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                width: "20px",
                marginRight: "16px",
              }}
              id="prevWeek"
              onClick={prevWeek}
            >
              <img
                style={{
                  transform: "rotate(90deg)",
                  verticalAlign: "-8px",
                }}
                width="20"
                src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/syncit/Icon_DropArrow_white.svg"
              />
            </button>
            <div>{renderDateDisplay()}</div>
            <button
              aria-hidden="true"
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                width: "20px",
                marginLeft: "16px",
              }}
              id="upcomingWeek"
              onClick={upcomingWeek}
            >
              <img
                style={{
                  transform: "rotate(-90deg)",
                  verticalAlign: "-8px",
                }}
                width="20"
                src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/syncit/Icon_DropArrow_white.svg"
              />
            </button>
            <div
              aria-hidden="true"
              id="start-date-container"
              style={{ position: "absolute", right: "10px" }}
              className="flex-j-start flex-container flex-al-center"
            >
              <div
                style={{
                  position: "relative",
                  left: "40px",
                  zIndex: 1,
                  top: "5px",
                }}
              >
                <CalendarIcon width="22px" height="22px" color="#519acc" />
              </div>
              <SingleDatePicker
                date={pickerDate}
                onDateChange={onDateChangePicker}
                focused={pickerFocus}
                onFocusChange={onFocusChangePicker}
                numberOfMonths={1}
                placeholder={"Select a date"}
                orientation={"horizontal"}
                daySize={30}
                navPrev={
                  <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                    <RoundDropdown rotate={"rotate(90deg)"} />
                  </div>
                }
                navNext={
                  <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                    <RoundDropdown rotate={"rotate(-90deg)"} />
                  </div>
                }
                hideKeyboardShortcutsPanel
                inputIconPosition={"after"}
                customInputIcon={<RoundDropdown />}
                isOutsideRange={isDayBlocked}
                id="single_date_picker"
              />
            </div>
          </div>
          <ScheduleDateHeaders
            userSchedule={true}
            copyProjectSchedule={null}
            userCount={1}
            twoWeeks={twoWeeks}
            currentWeekStart={currentWeekStart}
          />
        </div>
      </div>
      {renderTasks(
        tasks,
        user,
        closures,
        currentWeekStart,
        setModal,
        modalOpen,
        currentModal,
        modalData,
        twoWeeks
      )}
      <ReactModal
        refreshUserTasks={null}
        isShowing={modalOpen}
        page={currentModal}
        data={modalData}
        modalAction={setModal}
      />
    </div>
  );
};

const renderTasks = (
  tasks,
  user,
  closures,
  currentWeekStart,
  setModal,
  twoWeeks
) => {
  var firstDay = currentWeekStart;
  var cutoffDate = moment().day("sunday");
  var currentWeek = [...Array(5)].map((_, i) =>
    moment(firstDay).add(i, "d").format("YYYY-MM-DD")
  );
  var secondWeek = twoWeeks
    ? [...Array(5)].map((_, i) =>
        moment(firstDay)
          .add(i + 7, "d")
          .format("YYYY-MM-DD")
      )
    : [];
  var currentWeekClosures = Array(5).fill(false);
  var secondWeekClosures = Array(5).fill(false);
  if (closures.length > 0) {
    for (var x = 0; x < 5; x++) {
      for (var y = 0; y < closures.length; y++) {
        if (
          closures[y].start_date <= currentWeek[x] &&
          closures[y].end_date >= currentWeek[x]
        ) {
          currentWeekClosures[x] = closures[y];
        }
        if (
          twoWeeks &&
          closures[y].start_date <= secondWeek[x] &&
          closures[y].end_date >= secondWeek[x]
        ) {
          secondWeekClosures[x] = closures[y];
        }
      }
    }
  }
  return (
    <div>
      <ScheduleUserRow
        userSchedule={true}
        modalAction={setModal}
        user={user}
        userName={user.name}
        fullTime={user.full_time}
        userId={user.id}
        userTasks={tasks}
        index={0}
        cutoffDate={cutoffDate}
        twoWeeks={twoWeeks}
        currentWeek={currentWeek}
        secondWeek={secondWeek}
        currentWeekClosures={currentWeekClosures}
        secondWeekClosures={secondWeekClosures}
        viewOnly={true}
      />
    </div>
  );
};

export default PersonalUserSchedule;
