import React from "react";
import MonthChart from "./month_chart";
import WeekChart from "./week_chart";
import UsersChart from "./user_chart";

class ChartContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company_chart: true,
    };
    this.onWeekMonthClick = this.onWeekMonthClick.bind(this);
  }

  onWeekMonthClick() {
    this.setState({ company_chart: !this.state.company_chart });
  }

  render() {
    const isLoggedIn = this.state.company_chart;

    return (
      <div className="col s12" id="graph-container">
        {/* disabling month view for now */}
        {/* <div className="switch">
              <label>
                Week
                  <input type="checkbox" value="week" onChange = {this.onWeekMonthClick}/>
                  <span className="lever"></span>
                Month
              </label>
          </div> */}
        <div className="col s12">
          {isLoggedIn === true && <WeekChart redraw />}
          {isLoggedIn === false && <MonthChart redraw />}
        </div>
        <div className="divider" />
        <div>
          <UsersChart />
        </div>
      </div>
    );
  }
}

export default ChartContainer;
