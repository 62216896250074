import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { ReactTrixRTEInput } from "react-trix-rte";
import HelpContext from "./HelpContext";
import { EditField, EditFieldLabel } from "./HelpStyled";

const EditHelpPageForm = () => {
  const { activePage, toggleEditMode, patchHelpPage } = useContext(HelpContext);

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      topic: activePage.topic,
      title: activePage.title,
      tags: activePage.tags.join(","),
      body: activePage.body,
      priority: activePage.priority,
    },
  });

  const onSubmit = (data) => {
    const tags = data.tags.split(",").map((t) => t.trim());
    const formData = { ...data, tags };
    patchHelpPage(formData);
  };

  const onError = (fieldErrors) => {
    // eslint-disable-next-line no-undef
    M.toast({
      html: Object.values(fieldErrors)[0].message,
      displayLength: 3000,
      classes: "red",
    });
  };

  const onCancel = () => {
    reset();
    toggleEditMode();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} className="editable-true">
      <EditField>
        <EditFieldLabel className="custom-labels">
          Topic: Which section each article is placed into, ex: Client
        </EditFieldLabel>
        <input
          {...register("topic", {
            required: { value: true, message: "Help page topic is required" },
          })}
          type="text"
          style={{ flex: "1 1 100%" }}
          className="browser-default text-input-v2"
        />
      </EditField>
      <EditField>
        <EditFieldLabel className="custom-labels">Title:</EditFieldLabel>
        <input
          {...register("title", {
            required: { value: true, message: "Help page title is required" },
          })}
          type="text"
          style={{ flex: "1 1 100%" }}
          className="browser-default text-input-v2"
        />
      </EditField>
      <EditField>
        <EditFieldLabel className="custom-labels">
          Tags: these help users find articles, please separate each tag with a
          comma ex: pizza, eggrolls
        </EditFieldLabel>
        <input
          {...register("tags")}
          type="text"
          style={{ flex: "1 1 100%" }}
          className="browser-default text-input-v2"
        />
      </EditField>
      <EditField>
        <EditFieldLabel className="custom-labels">Priority: </EditFieldLabel>
        <input
          {...register("priority", {})}
          type="number"
          style={{ flex: "1 1 100%" }}
          className="browser-default text-input-v2"
        />
      </EditField>
      <div className="help-editable-body">
        <ReactTrixRTEInput {...register("body")} isRailsDirectUpload />
        <div className="flex-container flex-j-end">
          <button
            className="common-button-cancel"
            style={{ marginRight: "10px" }}
            onClick={onCancel}
            type="button"
          >
            Cancel
          </button>
          <button className="common-button-submit" type="submit">
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditHelpPageForm;
