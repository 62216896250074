import React from "react";
import moment from "moment";
import axios from "axios";
import "react-dates/initialize";
import ReactModal from "../modals/react_modal";
import RoundDropdown from "./round_dropdown.jsx";
// import ProjectData from "./project_data";
import Swal from "sweetalert2";
import styled from "styled-components";
import ProjectContacts from "./project_contacts";
import DropperContainer from "../common/dropper_container";
import ProjectComment from "./project_comment";
import CommentsContainer from "../common/comments_container";
import TaskSelectStyles from "../common/task_select_styles";
import Select, { components } from "react-select";
import SingleSelectWrapper from "../common/single_select_wrapper";
import Timer from "../common/timer.js";
import { CSSTransition } from "react-transition-group";
import ProjectDetailTimeline from "./project_detail_timeline";
import CustomColorPicker from "../common/custom_color_picker";
import CalendarIcon from "../icons/calendar_icon";
import TrashIcon from "components/icons/trash_icon";
import ProjectMemberIcon from "components/icons/project_member_icon";

class ProjectDetail extends React.Component {
  constructor(props) {
    super(props);
    this.typeArray = [
      { label: "Monthly Recurring", value: 1 },
      { label: "Nonrecurring", value: 2 },
      { label: "Non-Billable", value: 0 },
    ];
    var selectedPms = this.props.project.project_managers.map((x) => {
      if (this.props.userOptions.find((y) => y.value === x.id) != undefined) {
        return this.props.userOptions.find((y) => y.value === x.id).value;
      }
    });
    var selectedUsers = this.props.project.users.map((x) =>
      this.props.userOptions.find((y) => y.value === x.id)
    );
    selectedUsers.sort((a, b) =>
      selectedPms.indexOf(a.value) < selectedPms.indexOf(b.value)
        ? 1
        : selectedPms.indexOf(a.value) > selectedPms.indexOf(b.value)
        ? -1
        : 0
    );
    this.state = {
      modalOpen: false,
      comments: this.props.project.comments,
      currentModal: "",
      modalData: {},
      billable:
        this.props.project.invoicing_type === 3
          ? true
          : this.props.project.billable,
      selectedType:
        this.typeArray[
          this.props.project.billable
            ? this.props.project.invoicing_type - 1
            : 2
        ],
      contacts: this.props.project.contacts,
      commentInput: "",
      invoiceCommentInput: "",
      searchComments: [],
      estimateUrl:
        this.props.project.project_links.length >= 1 &&
        this.props.project.project_links.filter(
          (x) => x.link_type === "estimate"
        ).length >= 1
          ? this.props.project.project_links.filter(
              (x) => x.link_type === "estimate"
            )[0].location
          : "",
      urlEditable: false,
      commentsSearch: "",
      cardColor: this.props.project.card_color,
      selectedPms: selectedPms,
      selectedUsers: selectedUsers,
      selectedTasks: this.props.projectTasks.map((x) => ({
        value: x.id,
        label: x.name,
      })),
      rate: this.props.project.rate
        ? {
            label: `$${this.props.project.rate / 100}`,
            value: this.props.project.rate,
          }
        : { label: "$70", value: 7000 },
      poNumber: this.props.project.po_number
        ? this.props.project.po_number
        : "",
      invoice_notes: this.props.project.invoice_notes
        ? this.props.project.invoice_notes
        : "",
    };
    //limit resize to once per second
    this.urlParams = new URL(window.location.href).searchParams.toString();
    this.updateTimer = null;
    this.setModal = this.setModal.bind(this);
    this.instructionSubmit = this.instructionSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.contactHandler = this.contactHandler.bind(this);
    this.addContactHandler = this.addContactHandler.bind(this);
    this.handleUpdateTimer = this.handleUpdateTimer.bind(this);
    this.editContactHandler = this.editContactHandler.bind(this);
    this.deleteContactHandler = this.deleteContactHandler.bind(this);
    this.onResizeHandler = this.onResizeHandler.bind(this);
    this.handleUrlEditToggle = this.handleUrlEditToggle.bind(this);
    this.handleUrlBlur = this.handleUrlBlur.bind(this);
    this.handleUrlChange = this.handleUrlChange.bind(this);
    this.submitData = this.submitData.bind(this);
    this.roundInput = this.roundInput.bind(this);
    this.timerTriggered = false;
    this.pmClick = this.pmClick.bind(this);
    this.passFocus = this.passFocus.bind(this);
    this.setPms = this.setPms.bind(this);
    this.colors = [
      "#386ffe",
      "#30a6df",
      "#4f2a9e",
      "#7a51ad",
      "#f55454",
      "#fd6523",
      "#fcac15",
      "#ecc332",
      "#242424",
      "#444444",
      "#009177",
      "#15db4c",
    ];
    this.customControl = (props) => {
      return (
        <div>
          <p className="custom-select-input">{props.selectProps.inputValue}</p>
          <components.Control {...props} />
        </div>
      );
    };
    this.labelInput = (props) => {
      return <components.Input {...props} maxLength={20} />;
    };
    this.DropdownIndicator2 = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <RoundDropdown />
        </components.DropdownIndicator>
      );
    };
    this.DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator
          {...props}
          style={{ borderRadius: "5px" }}
        >
          <i
            className="material-icons base-color-hover"
            style={{ color: "#519acc" }}
          >
            search
          </i>
        </components.DropdownIndicator>
      );
    };
    this.MultiValueRemove = (props) => {
      return (
        <components.MultiValueRemove {...props}>
          <ActionButton>
            <TrashIcon height={15} width={15} />
          </ActionButton>
        </components.MultiValueRemove>
      );
    };
  }

  onResizeHandler() {
    var elem = document.getElementById("project-deets");
    if (elem) {
      var selectContainer = document.getElementsByClassName(
        "react-multi-select__value-container"
      );
      if (selectContainer.length >= 1) {
        for (var x = 0; x < selectContainer.length; x++) {
          selectContainer[x].style.width = elem.clientWidth + "px";
          var heightModifier = 23;
          var marginModifier = 60;
          if (elem.clientWidth <= 575) {
            heightModifier = 47;
            marginModifier = 20;
          }
          var length = selectContainer[x].childElementCount;
          if (length % 2 === 1) {
            length = length - 1;
            //increase height only when new row is started
          }
          var parent =
            selectContainer[x].parentElement.parentElement.parentElement;
          if (parent) {
            parent.style.minHeight =
              length * heightModifier + marginModifier + "px";
          }
        }
      }
    }
  }

  handleUpdateTimer() {
    if (this.updateTimer) {
      this.updateTimer.stopTimer();
      this.updateTimer = null;
    }
    if (this.state.submitted) {
      this.updateTimer = new Timer(1000, this.submitData);
    }
  }

  setBillable = (billable) => {
    this.setState({ billable });
  };

  componentDidMount() {
    this.onResizeHandler();
    window.addEventListener("resize", this.onResizeHandler);
    window.addEventListener("beforeunload", this.componentCleanup);
  }

  componentWillUnmount() {
    this.componentCleanup();
  }

  componentCleanup = (popstate) => {
    if (this.updateTimer) {
      this.updateTimer.stopTimer();
    }
    if (this.state.submitted) {
      this.submitData();
    }
    window.removeEventListener("resize", this.onResizeHandler);
    window.removeEventListener("beforeunload", this.componentCleanup);
  };

  submitData(updateObject) {
    var project = {};
    if (updateObject) {
      project = { ...updateObject };
    } else {
      var user_ids = [...new Set(this.state.selectedUsers.map((x) => x.value))];
      if (this.props.project.project_links.length >= 1) {
        var estimate_url = {
          id: this.props.project.project_links[0].id,
          project_id: this.props.project_id,
          location: this.state.estimateUrl,
          name: this.state.estimateUrl,
          user_id: this.props.currentUser.id,
          link_type: "estimate",
        };
      } else {
        var estimate_url = {
          location: this.state.estimateUrl,
          project_id: this.props.project_id,
          name: this.state.estimateUrl,
          user_id: this.props.currentUser.id,
          link_type: "estimate",
        };
      }
      project.user_ids = user_ids;
      project.project_links_attributes = [estimate_url];
      project.time_entry_task_ids = this.state.selectedTasks.map(
        (x) => x.value
      );
      if (this.props.project.po_number != this.state.poNumber) {
        project.po_number = this.state.poNumber;
      }
      if (this.props.project.invoice_notes != this.state.invoice_notes) {
        project.invoice_notes = this.state.invoice_notes;
      }
    }

    axios
      .patch(`/projects/${this.props.project.id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        project: project,
      })
      .then((res) => {
        this.setState({ submitted: false });
        M.toast({
          html: "<span aria-live='assertive'>Project Updated</span>",
          displayLength: 3000,
          classes: "green",
        });
      })
      .catch((err) => {
        M.toast({ html: err.response.data, classes: "red" });
        console.error(err);
      });
  }

  setModal(bool, page, data) {
    this.setState({
      modalOpen: bool,
      currentModal: page,
      modalData: data,
    });
  }

  handleRateChange = (rate) => {
    this.setState({ rate }, () => {
      this.submitData({ rate: this.state.rate.value });
    });
  };

  handleInputChange(e) {
    var name = e.target.name;
    this.setState({ [name]: e.target.value, submitted: true });
  }

  handleSelectChange = (e) => {
    this.setState(
      {
        selectedTasks: e ? e : [],
        submitted: true,
      },
      () => {
        this.onResizeHandler();
      }
    );
  };

  handleUserSelectChange = (e) => {
    this.setState(
      {
        selectedUsers: e ? e : [],
        submitted: true,
      },
      () => {
        this.onResizeHandler();
      }
    );
  };

  handlePmChange = (e) => {
    this.setState({
      selectedPms: e ? e : [],
      submitted: true,
    });
  };

  contactHandler(action, contact) {
    var newContacts = this.state.contacts;
    if (action === "create") {
      newContacts.push(contact);
    } else if (action === "delete" || action === "edit") {
      for (var i = 0; i < newContacts.length; i++) {
        if (newContacts[i].id === contact.id) {
          if (action === "delete") {
            newContacts.splice(i, 1);
          } else if (action === "edit") {
            newContacts.splice(i, 1, contact);
          }
          break;
        }
      }
    }
    this.setState({ contacts: newContacts, submitted: true });
  }

  instructionSubmit(e) {
    e.preventDefault();
    var self = this;
    if (this.state.invoiceCommentInput === "") {
      return;
    }
    axios
      .post(`/comments.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        comment: {
          instruction: true,
          text: self.state.invoiceCommentInput,
          user_id: self.props.currentUser.id,
          project_id: self.props.project.id,
        },
      })
      .then((res) => {
        this.setState({ invoiceCommentInput: "" });
        this.commentHandler("create", res.data);
        M.toast({
          html: `<span aria-live='assertive'>Instruction created</span>`,
          displayLength: 3000,
          classes: "green",
        });
        document.activeElement.blur();
      })
      .catch((err) => console.error(err));
  }

  editContactHandler(contact) {
    axios
      .patch(`/contacts/${contact.id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        contact: {
          name: contact.name,
          email: contact.email,
        },
      })
      .then((res) => {
        this.contactHandler("edit", res.data);
        M.toast({
          html: `<span aria-live='assertive'>Contact updated</span>`,
          displayLength: 3000,
          classes: "green",
        });
      })
      .catch((err) => console.error(err));
  }

  addContactHandler(contact) {
    var self = this;
    axios
      .post(`/contacts.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        contact: {
          // type: type,
          name: contact.name,
          email: contact.email,
          user_id: self.props.currentUser.id,
          project_id: self.props.project.id,
          invoicing: contact.type === "invoicing",
        },
      })
      .then((res) => {
        this.contactHandler("create", res.data);
        M.toast({
          html: `<span aria-live='assertive'>Contact updated</span>`,
          displayLength: 3000,
          classes: "green",
        });
      })
      .catch((err) => console.error(err));
  }

  deleteContactHandler(contact) {
    axios
      .delete(`/contacts/${contact.id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.contactHandler("delete", contact);
        M.toast({
          html: `<span aria-live='assertive'>Contact Deleted</span>`,
          displayLength: 3000,
          classes: "green",
        });
      })
      .catch((err) => console.error(err));
  }

  commentKey = (e) => {
    if (e.which === 13) {
      if (e.shiftKey) {
        e.preventDefault();
        var value = this.state.commentInput + "\n";
        this.setState({ commentInput: value });
      }
    }
  };

  setPms(ids) {
    var project = {};
    project.pm_ids = ids;
    axios
      .patch(`/projects/${this.props.project.id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        project: project,
      })
      .then((res) => {
        M.toast({
          html: "<span aria-live='assertive'>Project Managers Updated</span>",
          displayLength: 3000,
          classes: "green",
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  pmClick(e) {
    e.stopPropagation();
    var userId = parseInt(e.target.dataset.userId);
    var pms = this.state.selectedPms;
    if (pms.includes(userId)) {
      pms = pms.filter((pm) => pm != userId);
    } else {
      pms.push(userId);
    }
    this.setState({ selectedPms: pms }, () => {
      this.setPms(pms);
    });
  }

  passFocus(e) {
    if (e.target && e.target.previousSibling) {
      e.target.previousSibling.focus();
    }
  }

  handleUrlChange(e) {
    this.setState({ estimateUrl: e.target.value, submitted: true });
  }

  handleUrlBlur() {
    this.setState({ urlEditable: false });
    this.handleUpdateTimer();
  }

  handleUrlEditToggle() {
    this.setState({ urlEditable: !this.state.urlEditable });
  }

  roundInput(e) {
    var input = parseFloat(e.target.value);
    var value;
    if (input) {
      if (input < 0) {
        input = Math.abs(input);
      }
      if (input <= 0.12) {
        value = "0.25";
      } else {
        value = (Math.round(input * 4) / 4).toFixed(2);
      }
    } else {
      value = "0.00";
    }
    this.setState({ rate: value }, () => {
      this.handleUpdateTimer();
    });
  }

  handleTypeChange = (e) => {
    this.setState({ selectedType: e }, () => {
      var value = this.state.selectedType.value;
      this.submitData({ billable: value !== 0, invoicing_type: value });
    });
  };

  handleBulkUserAdd = (e) => {
    var value = e.target.dataset.value;
    Swal.fire({
      title: `Add ${
        value === "all" ? "All" : value === "pt" ? "All Part-Time" : "Full-Time"
      } Users`,
      text: `Are you sure you want to add ${
        value === "all"
          ? "all"
          : value === "pt"
          ? "all part-time"
          : "all full-time"
      } users to this project?`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        var oldUsers = this.state.selectedUsers.map((u) => u.value);
        var userGroup =
          value === "all"
            ? this.props.userOptions.filter(
                (u) => oldUsers.indexOf(u.value) === -1
              )
            : this.props.userOptions.filter(
                (u) =>
                  u.full_time === (value === "ft") &&
                  oldUsers.indexOf(u.value) === -1
              );
        this.setState(
          {
            selectedUsers: [...this.state.selectedUsers, ...userGroup],
          },
          () => {
            this.submitData({
              user_ids: this.state.selectedUsers.map((u) => u.value),
            });
            this.onResizeHandler();
          }
        );
      }
    });
  };

  isDayBlocked(day) {
    return moment(day).day() === 0 || moment(day).day() === 6 ? true : false;
  }

  copyProject = () => {
    this.props.copyProject(this.props.project);
  };

  handleColorChange = (e) => {
    this.setState({ cardColor: e.hex }, () => {
      this.submitData({ card_color: this.state.cardColor });
    });
  };

  render() {
    const MultiValueLabel = (props) => {
      let fullTime = props.data.full_time;
      return (
        <div
          className="gkrwgw"
          style={{ display: "flex", width: "100%" }}
          tabIndex={5}
        >
          <span style={{ marginLeft: "16px", height: "25px" }}>
            <ProjectMemberIcon />
          </span>
          <div
            style={{ flex: 1, paddingRight: "8px" }}
            className="flex-container flex-al-center flex-space-between"
          >
            <components.MultiValueLabel {...props} width={800} />

            <button
              data-user-id={props.data.value}
              type="button"
              onMouseDown={this.pmClick}
              style={{
                backgroundColor: `${fullTime ? "#519acc" : "#D76F23"}`,
                cursor: "pointer",
                border: "none",
                width: "68px",
                height: "23px",
                borderRadius: "20px",
                fontSize: "11px",
                fontFamily: "Open Sans",
                fontWeight: 600,
                color: "white",
                whiteSpace: "nowrap",
                padding: "4px 8px",
              }}
            >
              {fullTime ? "Full-Time" : "Part-Time"}
            </button>
          </div>
        </div>
      );
    };
    const instructions = this.state.comments.filter((x) => x.instruction);
    const comments = this.state.comments.filter((x) => !x.instruction);
    const onCLient = window.location.href.includes("clients");

    return (
      <div>
        <ReactModal
          refreshUsers={this.getProjectUsers}
          refreshProject={this.refreshProject}
          isShowing={this.state.modalOpen}
          page={this.state.currentModal}
          data={this.state.modalData}
          modalAction={this.setModal}
        />
        <ProjectContainer>
          <div>
            <ProjectBox>
              <div
                id="project-scroll-container"
                style={{
                  padding: "0px",
                  overflow: "hidden auto",
                  maxHeight: "calc(100vh - 308px)",
                  padding: "16px",
                }}
              >
                {/* BACK ARROW */}
                <div
                  style={{
                    margin: "0px 0px 33px",
                    position: "relative",
                  }}
                  className="flex-container flex-al-center"
                >
                  {onCLient && (
                    <BackToClientsContainer aria-label="breadcrumb navigation">
                      <StyledButton
                        className="btn-flat"
                        onClick={() => window.history.back()}
                        aria-label="Clients Breadcrumb"
                        role="button"
                      >
                        Clients
                      </StyledButton>
                      <StyledSpan gray>{` >> `} &nbsp;</StyledSpan>
                      <StyledSpanClient
                        marginLeft
                        onClick={() =>
                          (window.location.href =
                            "/clients/" + this.props.project.client.id)
                        }
                        aria-label="Current Client Name Breadcrumb"
                        role="button"
                      >
                        {this.props.project.client_name}
                      </StyledSpanClient>
                      <StyledSpan gray>{` >> `} &nbsp;</StyledSpan>
                      <StyledSpan marginLeft aria-label="Current Project Name">
                        {this.props.project.name}
                      </StyledSpan>
                    </BackToClientsContainer>
                  )}
                  {!onCLient && (
                    <BackToClientsContainer aria-label="breadcrumb navigation">
                      <StyledButton
                        onClick={() => (window.location.href = "/projects")}
                        className="btn-flat"
                        aria-label="Projects Breadcrumbs"
                        role="button"
                      >
                        Projects
                      </StyledButton>
                      <StyledSpan gray>{` >> `} &nbsp;</StyledSpan>

                      <StyledSpan
                        marginLeft
                        aria-label="Current Project Breadcrumb"
                      >
                        {this.props.project.name}
                      </StyledSpan>
                    </BackToClientsContainer>
                  )}

                  {this.props.project.test_start_date !== "Unknown" && (
                    <div
                      style={{
                        backgroundColor: "#578BC2",
                        position: "absolute",
                        right: "157px",
                        top: "0px",
                        borderRadius: "5px",
                        color: "white",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        padding: "0 15px",
                        fontSize: "13px",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: "white",
                          color: "#578BC2",
                          display: "block",
                          width: "18px",
                          marginRight: "10px",
                          textAlign: "center",
                          fontWeight: "800",
                        }}
                      >
                        i
                      </span>
                      <span style={{ fontWeight: "700", marginRight: "4px" }}>
                        Start Date:{" "}
                      </span>
                      {this.props.project.test_start_date}
                    </div>
                  )}
                  <ViewScheduleLink
                    target="_blank"
                    href={this.props.viewScheduleLink}
                  >
                    <CalendarIcon svgStyles={{ margin: "3px 8px 0 0" }} />
                    View Schedule
                  </ViewScheduleLink>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "16px",
                  }}
                >
                  {this.props.project.project_status && (
                    <ProjectDetailTimeline project={this.props.project} />
                  )}

                  <div
                    style={{
                      padding: "8px",
                      marginLeft: "10px",
                      backgroundColor: "#E5E5E5",
                      borderRadius: "10px",
                      height: "151px",
                      flex: "1",
                      maxWidth: "calc(100% - 550px)",
                    }}
                    className="flex-container flex-row"
                  >
                    <div
                      style={{ backgroundColor: "white", padding: "16px 20px" }}
                    >
                      <p
                        style={{
                          fontSize: "10px",
                          color: "#838383",
                          fontWeight: "400",
                          marginTop: "0px",
                          marginBottom: "8px",
                        }}
                      >
                        SELECT A COLOR:
                      </p>
                      <div aria-label="Color Swatch Group" tabIndex={"0"}>
                        <CustomColorPicker
                          color={this.state.cardColor}
                          onChange={this.handleColorChange}
                          colors={this.colors}
                          triangle="hide"
                          title="Project Color:"
                          styles={{
                            default: {
                              titleContainer: { display: "none" },
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        flex: "1",
                        backgroundColor: "white",
                        padding: "16px 20px 16px 0px",
                        maxWidth: "calc(100% - 220px)",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "10px",
                          color: "#838383",
                          fontWeight: "400",
                          marginTop: "0px",
                          marginBottom: "8px",
                        }}
                      >
                        PREVIEW
                      </p>
                      <div
                        style={{
                          height: "38px",
                          cursor: "default",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "flex",
                          paddingLeft: "8px",
                          alignItems: "center",
                          justifyContent: "space-between",
                          alignSelf: "stretch",
                          borderRadius: "10px",
                          border: "1px solid #d7d7d7",
                        }}
                      >
                        <div
                          style={{
                            maxWidth: "120px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {this.props.project.name}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            width: "39px",
                            height: "38px",
                            padding: "8px 9px 8px 8px",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: this.state.cardColor,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "#519acc",
                              background: "#fff",
                              borderRadius: "25px",
                              border: "1px solid #fff",
                              width: "22px",
                              height: "22px",
                            }}
                          >
                            8
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  {/* Client Contacts Dropper */}
                  {this.state.billable && (
                    <DropperContainer title="Client Contacts" role="button">
                      <div style={{ padding: "16px" }}>
                        <ProjectContacts
                          type="regular"
                          fullWidth={true}
                          deleteContactHandler={this.deleteContactHandler}
                          submitHandler={this.editContactHandler}
                          addContactHandler={this.addContactHandler}
                          contacts={this.state.contacts.filter(
                            (contact) => contact.invoicing === false
                          )}
                        />
                      </div>
                    </DropperContainer>
                  )}
                  {/* Invoicing Details Dropper */}
                  {this.state.billable && (
                    <DropperContainer title="Invoicing Details" role="button">
                      <div style={{ padding: "16px" }}>
                        <ProjectContacts
                          type="invoicing"
                          fullWidth={true}
                          deleteContactHandler={this.deleteContactHandler}
                          addContactHandler={this.addContactHandler}
                          submitHandler={this.editContactHandler}
                          contacts={this.state.contacts.filter(
                            (contact) => contact.invoicing
                          )}
                        />
                        <div
                          style={{
                            display: "flex",
                            gap: "32px",
                            alignSelf: "stretch",
                            marginTop:
                              this.state.contacts.length > 0 ? "32px" : "0px",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                fontFamily: "Manrope",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              PO Number
                            </span>
                            <input
                              required="required"
                              className="browser-default text-input-v2"
                              style={{
                                paddingLeft: "10px",
                                marginTop: "8px",
                              }}
                              type="text"
                              onBlur={this.handleUpdateTimer}
                              value={this.state.poNumber}
                              onChange={this.handleInputChange}
                              placeholder="Enter a PO Number"
                              name="poNumber"
                              maxLength={25}
                              autoComplete="off"
                              id="poNumber"
                            />
                          </div>
                          <div>
                            <span
                              style={{
                                fontFamily: "Manrope",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              Rate:
                            </span>
                            <div style={{ marginTop: "8px" }}>
                              <SingleSelectWrapper
                                options={[
                                  { label: "$63", value: 6300 },
                                  { label: "$65", value: 6500 },
                                  { label: "$68", value: 6800 },
                                  { label: "$70", value: 7000 },
                                  { label: "$75", value: 7500 },
                                ]}
                                height="39px"
                                onChange={this.handleRateChange}
                                name="project_rate"
                                placeholder={"Select Rate"}
                                blurInputOnSelect={true}
                                value={this.state.rate}
                                ariaLabel={`Project rate dropdown, ${this.state.rate.label} selected, press down to open menu`}
                                menuPlacement="top"
                                className="react-select__container"
                                style={{
                                  height: "39px",
                                  width: "100%",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "Manrope",
                                fontSize: "13px",
                                fontWeight: "600",
                                // marginBottom: "8px",
                              }}
                            >
                              Link To Estimate
                            </span>
                            {this.state.urlEditable ? (
                              <input
                                className="browser-default text-input-v2"
                                type="text"
                                autoFocus={true}
                                onChange={this.handleUrlChange}
                                onBlur={this.handleUrlBlur}
                                value={this.state.estimateUrl}
                              />
                            ) : (
                              <span
                                onClick={this.handleUrlEditToggle}
                                style={{
                                  cursor: "pointer",
                                  color: "#519acc",
                                  fontFamily: "Arial",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  marginTop: "8px",
                                }}
                              >
                                {this.state.estimateUrl === ""
                                  ? "Click here to add URL"
                                  : this.state.estimateUrl}
                              </span>
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            // gap: "16px",
                            cursor: "default",
                            marginTop: "32px",
                          }}
                        >
                          <span
                            className="manrope"
                            style={{
                              fontWeight: "600",
                              fontSize: "13px",
                              fontFamily: "manrope",
                              marginBottom: "8px",
                            }}
                          >
                            Invoice Instructions
                          </span>

                          <CommentsContainer
                            comments={instructions}
                            currentUser={this.props.currentUser}
                            project={this.props.project}
                            instruction={true}
                          />
                        </div>
                      </div>
                    </DropperContainer>
                  )}
                  {/* Project Members Dropper */}
                  <DropperContainer
                    maxHeight="2000px"
                    title="Project Members"
                    role="button"
                  >
                    <div
                      className="users_dropper"
                      style={{
                        flex: "1 1 100%",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        padding: "16px",
                        minHeight: "387px",
                        transition: "min-height .5s",
                        overflowY: "scroll",
                        cursor: "default",
                      }}
                    >
                      <Select
                        tabIndex={1}
                        isMulti
                        required
                        classNamePrefix="react-multi-select"
                        menuShouldScrollIntoView
                        onBlur={this.handleUpdateTimer}
                        isClearable={false}
                        menuPlacement="bottom"
                        placeholder="Search for a user"
                        components={{
                          Control: this.customControl,
                          Input: this.labelInput,
                          DropdownIndicator: this.DropdownIndicator,
                          MultiValueRemove: this.MultiValueRemove,
                          MultiValueLabel: MultiValueLabel,
                        }}
                        aria-label="User dropdown for edit project form"
                        closeMenuOnSelect={false}
                        options={this.props.userOptions}
                        onChange={this.handleUserSelectChange}
                        value={this.state.selectedUsers}
                        styles={TaskSelectStyles}
                      />
                      <div>
                        <button
                          tabIndex={2}
                          data-value="all"
                          onClick={this.handleBulkUserAdd}
                          className="common-button-submit-gray"
                        >
                          All Users
                        </button>
                        <button
                          tabIndex={3}
                          data-value="pt"
                          onClick={this.handleBulkUserAdd}
                          style={{
                            marginLeft: "10px",
                          }}
                          className="common-button-submit-gray"
                        >
                          PT Users
                        </button>
                        <button
                          tabIndex={4}
                          data-value="ft"
                          onClick={this.handleBulkUserAdd}
                          style={{
                            marginLeft: "10px",
                          }}
                          className="common-button-submit-gray"
                        >
                          FT Users
                        </button>
                      </div>
                    </div>
                  </DropperContainer>
                  {/* Comments Dropper */}
                  {!this.props.timeOffProject && (
                    <DropperContainer
                      dropperId="comments"
                      parentScrollId="project-detail"
                      title="Comments"
                    >
                      <CommentsContainer
                        comments={comments}
                        currentUser={this.props.currentUser}
                        project={this.props.project}
                      />
                    </DropperContainer>
                  )}
                </div>
                <button
                  style={{
                    paddingLeft: "0",
                    paddingRight: "0",
                    marginLeft: "0px",
                    marginTop: "16px",
                  }}
                  className="btn btn-flat"
                  onClick={this.copyProject}
                >
                  Duplicate Project
                </button>
              </div>
            </ProjectBox>
          </div>
        </ProjectContainer>
      </div>
    );
  }
}

const ProjectContainer = styled.div`
  width: 100%;
  background-color: #f2f2f2;
  overflow: hidden;  
  }
`;

const ProjectBox = styled.div`
  background-color: white;
  height: calc(100vh - 130px);
  box-shadow: 0px 2px 3px 2px #cccccc;
`;

const ViewScheduleLink = styled.a`
  height: 40px;
  color: #242424;
  display: flex;
  position: absolute;
  right: -1px;
  top: 0px;
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  align-items: center;
  border-radius: 5px;
  padding: 0 12px;
  line-height: 18px;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    border: 1px solid #519acc;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled.a`
  padding: 0;
  font-family: "Manrope";
  font-weight: 700;
  color: #519acc;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-decoration-line: underline;
`;

const StyledSpanClient = styled.a`
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: ${(props) => (props.gray ? "#C0C0C0" : "#519ACC")};
  letter-spacing: ${(props) => (props.gray ? "-4.2px" : "normal")};
  margin-left: ${(props) => (props.marginLeft ? "8px" : "4px")};
  white-space: nowrap;
  text-decoration-line: underline;
`;
const StyledSpan = styled.span`
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: ${(props) => (props.gray ? "#C0C0C0" : "#242424")};
  letter-spacing: ${(props) => (props.gray ? "-4.2px" : "normal")};
  margin-left: ${(props) => (props.marginLeft ? "8px" : "4px")};
  white-space: nowrap;
`;

const BackToClientsContainer = styled(FlexContainer)`
  width: 130px;
  height: 22px;
`;

const ActionButton = styled.button`
  border: 1px solid #d7d7d7;
  cursor: pointer;
  padding: 0;
  border-radius: 5px;
  display: flex;
  height: 30px;
  width: 30px;
  justify-content: center;
  background-color: white;
  align-items: center;
  border: 1px solid #d7d7d7;
  margin: 0px !important;
  &:hover {
    border: 1px solid #519acc;
  }
`;
export default ProjectDetail;
