import React from "react";
import axios from "axios";
import styled from "styled-components";
import TeamIcon from "../icons/team_icon";
import ExitIcon from "components/icons/exit_icon";

const AddUserModal = styled.div``;

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      roleId: 1,
      fullTime: true,
      avatar: null,
    };
    this.setRoleId = this.setRoleId.bind(this);
    this.setFullTime = this.setFullTime.bind(this);
    this.fileElem = React.createRef();
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  }

  setRoleId(event) {
    this.setState({ roleId: parseInt(event.target.value) });
  }

  setFullTime(event) {
    this.setState({ fullTime: event.target.value === "true" });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.refs.submit_button.disabled = true;
    var user = new FormData();
    if (this.state.avatar) {
      user.append("avatar", this.state.avatar);
    }
    user.append("name", this.refs.user_name.value);
    user.append("email", this.refs.user_email.value);
    user.append("role_id", this.state.roleId),
      user.append("secondary_email", this.refs.user_secondary_email.value);
    user.append("full_time", this.state.fullTime);
    axios
      .post(`/create_user.json`, user, {
        headers: {
          //   'Accept': 'application/json',
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        M.toast({
          html: `User created!`,
          displayLength: 3000,
          classes: "green",
        });
        this.refs.submit_button.disabled = false;
        window.location.reload();
        this.props.modalAction(false, "", {});
      })
      .catch((err) => {
        if (err.response.status === 422) {
          M.toast({
            html: err.response.data,
            displayLength: 3000,
            classes: "red",
          });
        } else {
          M.toast({
            html: `Error creating user`,
            displayLength: 3000,
            classes: "red",
          });
        }
        console.error(err);
        this.refs.submit_button.disabled = false;
      });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.props.modalAction(false, "", {});
  };

  avatarChange = (e) => {
    var file = e.target.files[0];
    if (file) {
      this.setState({ avatar: file });
    }
  };

  uploadAvatar = (e) => {
    e.preventDefault();
    this.fileElem.current.click();
  };

  render() {
    return (
      <AddUserModal className="modal-content">
        <div
          style={{ marginBottom: "32px" }}
          className="flex-container flex-al-center flex-j-start"
        >
          <div
            className="flex-al-center flex-j-center flex-container"
            style={{
              backgroundColor: "#519acc",
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              color: "white",
            }}
          >
            <TeamIcon height={34} width={34} color="#519acc" />
          </div>
          <h4
            style={{
              outline: "none",
              marginLeft: "16px",
              fontSize: "18px",
              fontFamily: "manrope",
              fontWeight: "600",
            }}
            id="time-off-request-heading"
            tabIndex={-1}
          >
            Add a User
          </h4>
          <button
            type="button"
            id="modalClose"
            aria-label="Close Modal"
            className="btn-icon"
            onClick={this.closeModal}
            style={{ position: "absolute", top: "41px", right: "26px" }}
          >
            <ExitIcon
              svgStyles={{ pointerEvents: "none" }}
              height="25px"
              width="25px"
            />
          </button>
        </div>
        <form className="new_user" id="new_user" onSubmit={this.handleSubmit}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "16px",
            }}
          >
            <div>
              <span htmlFor="user[name]" className="modal-field">
                Full Name <span style={{ color: "#519acc" }}>*</span>
              </span>
              <input
                className="browser-default text-input-v2"
                required={true}
                type="text"
                name="user[name]"
                id="user_name"
                ref="user_name"
                placeholder=" Enter user name"
              />
            </div>
            <div>
              <span htmlFor="user[email]" className="modal-field">
                Email <span style={{ color: "#519acc" }}>*</span>
              </span>
              <input
                className="browser-default text-input-v2"
                type="email"
                required={true}
                name="user[email]"
                id="user_email"
                ref="user_email"
                placeholder=" Enter email address"
              />
            </div>
            <div>
              <span htmlFor="user[secondary_email]" className="modal-field">
                Secondary Email
              </span>
              <input
                className="browser-default text-input-v2"
                type="email"
                name="user[secondary_email]"
                id="user_secondary_email"
                ref="user_secondary_email"
                placeholder=" Enter secondary email address"
              />
            </div>
          </div>
          <div style={{ marginTop: "16px" }}>
            <span className="modal-field">User Role</span>
            <div style={{ marginTop: "8px" }}>
              <label htmlFor="user_role_id_1" className="privilege-label">
                <input
                  className="with-gap"
                  defaultChecked={true}
                  required="required"
                  type="radio"
                  value="1"
                  name="user[role_id]"
                  id="user_role_id_1"
                  onClick={this.setRoleId}
                />
                <span style={{ marginRight: "5px" }}>Tester</span>
              </label>
              <label htmlFor="user_role_id_2" className="privilege-label">
                <input
                  className="with-gap"
                  type="radio"
                  value="2"
                  name="user[role_id]"
                  id="user_role_id_2"
                  onClick={this.setRoleId}
                />
                <span style={{ marginRight: "5px" }}>Lead</span>
              </label>
              <label htmlFor="user_role_id_3" className="privilege-label">
                <input
                  className="with-gap"
                  type="radio"
                  value="3"
                  name="user[role_id]"
                  id="user_role_id_3"
                  onClick={this.setRoleId}
                />
                <span style={{ marginRight: "5px" }}>PM</span>
              </label>
              <label htmlFor="user_role_id_4" className="privilege-label">
                <input
                  className="with-gap"
                  type="radio"
                  value="4"
                  name="user[role_id]"
                  id="user_role_id_4"
                  onClick={this.setRoleId}
                />
                <span style={{ marginRight: "5px" }}>Admin</span>
              </label>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: "16px",
              marginBottom: "16px",
            }}
          >
            <div>
              <span htmlFor="user_full_time" className="modal-field">
                Full Time?
              </span>
              <div>
                <label
                  htmlFor="user_full_time_true"
                  className="privilege-label"
                >
                  <input
                    className="with-gap"
                    required={true}
                    defaultChecked={true}
                    type="radio"
                    value="true"
                    name="user[full_time]"
                    id="user_full_time_true"
                    onClick={this.setFullTime}
                  />
                  <span>Yes</span>
                </label>
                <label
                  htmlFor="user_full_time_false"
                  className="privilege-label"
                >
                  <input
                    className="with-gap"
                    type="radio"
                    value="false"
                    name="user[full_time]"
                    id="user_full_time_false"
                    onClick={this.setFullTime}
                  />
                  <span>No</span>
                </label>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              borderRadius: "5px",
              border: "1px solid #d7d7d7",
              padding: "16px",
              height: "72px",
            }}
          >
            {this.state.avatar ? (
              <img
                src={URL.createObjectURL(this.state.avatar)}
                alt="avatar"
                style={{ height: "40px", width: "40px", borderRadius: "50%" }}
              />
            ) : (
              <div style={{ height: "40px", width: "40px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                >
                  <path
                    opacity="0.2"
                    d="M20.0341 40.0658C31.0986 40.0658 40.0681 31.406 40.0681 20.7237C40.0681 10.0413 31.0986 1.38159 20.0341 1.38159C8.96956 1.38159 0 10.0413 0 20.7237C0 31.406 8.96956 40.0658 20.0341 40.0658Z"
                    fill="#242424"
                  />
                  <path
                    d="M20.0341 38.6842C31.0986 38.6842 40.0681 30.0245 40.0681 19.3421C40.0681 8.65976 31.0986 0 20.0341 0C8.96956 0 0 8.65976 0 19.3421C0 30.0245 8.96956 38.6842 20.0341 38.6842Z"
                    fill="url(#paint0_linear_5178_804)"
                  />
                  <g opacity="0.2">
                    <path
                      opacity="0.2"
                      d="M20.0341 6.9079C16.8995 6.9079 14.3101 9.4079 14.3101 12.4342C14.3101 15.4605 16.8995 17.9605 20.0341 17.9605C23.1687 17.9605 25.7581 15.4605 25.7581 12.4342C25.7581 9.4079 23.1687 6.9079 20.0341 6.9079Z"
                      fill="#242424"
                    />
                    <path
                      opacity="0.2"
                      d="M20.0341 20.7237C10.017 20.7237 10.017 26.8421 10.017 26.8421V29.3421C10.017 29.3421 11.8569 33.1579 20.0341 33.1579C28.2112 33.1579 29.983 29.2763 29.983 29.2763V26.7763C29.983 26.7763 29.983 20.7237 20.0341 20.7237Z"
                      fill="#242424"
                    />
                  </g>
                  <path
                    d="M20.0341 5.52631C16.8995 5.52631 14.3101 8.02631 14.3101 11.0526C14.3101 14.0789 16.8995 16.5789 20.0341 16.5789C23.1687 16.5789 25.7581 14.0789 25.7581 11.0526C25.7581 8.02631 23.1687 5.52631 20.0341 5.52631Z"
                    fill="white"
                  />
                  <path
                    d="M20.0341 19.3421C10.017 19.3421 10.017 25.4605 10.017 25.4605V27.9605C10.017 27.9605 11.8569 31.7763 20.0341 31.7763C28.2112 31.7763 29.983 27.8947 29.983 27.8947V25.3947C29.983 25.4605 29.983 19.3421 20.0341 19.3421Z"
                    fill="white"
                  />
                  <path
                    opacity="0.2"
                    d="M20.0341 0C8.92675 0 0 8.61842 0 19.3421C0 19.6053 0 19.8684 0 20.1316C0.340715 9.67105 9.19932 1.38158 20.0341 1.38158C30.7325 1.38158 39.5911 9.53948 40 19.8684C40 19.6711 40 19.4737 40 19.2763C40 8.61842 31.0733 0 20.0341 0Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_5178_804"
                      x1="20.0341"
                      y1="0"
                      x2="20.0341"
                      y2="38.6842"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#519ACC" />
                      <stop offset="1" stopColor="#2B88C8" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            )}

            {!this.state.avatar ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "16px",
                }}
              >
                <span
                  style={{
                    marginTop: "0px",
                    fontFamily: "Arial",
                    fontSize: "13px",
                    fontWeight: "400",
                    marginBottom: "6px",
                  }}
                >
                  No image provided
                </span>
                <button
                  onClick={this.uploadAvatar}
                  style={{
                    border: "none",
                    background: "none",
                    color: "#519acc",
                    paddingLeft: "0px",
                    cursor: "pointer",
                    fontFamily: "Arial",
                    fontSize: "13px",
                    fontWeight: "400",
                    textAlign: "left",
                  }}
                >
                  Upload Image
                </button>
                <input
                  onChange={this.avatarChange}
                  accept="image/x-png,image/gif,image/jpeg"
                  style={{ width: "0px", height: "0px", overflow: "hidden" }}
                  type="file"
                  name="user[avatar]"
                  id="user_avatar"
                  ref={this.fileElem}
                />
              </div>
            ) : (
              <div
                style={{
                  marginLeft: "16px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    marginTop: "0px",
                    fontFamily: "Arial",
                    fontSize: "13px",
                    fontWeight: "400",
                  }}
                >
                  Image Uploaded!
                </span>
              </div>
            )}
          </div>
          <div style={{ marginTop: "32px" }} className="right-align">
            <button
              onClick={this.closeModal}
              className="common-button-cancel"
              style={{ marginRight: "10px" }}
            >
              Cancel
            </button>
            <input
              type="submit"
              name="commit"
              value="Create User"
              className="common-button-submit white-text"
              ref="submit_button"
            />
          </div>
        </form>
      </AddUserModal>
    );
  }
}
export default AddUser;
