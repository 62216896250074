import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ContactCard from "../users/contact_card";

function ProjectContacts(props) {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  function handleInputChange(e) {
    const { name } = e.target;
    switch (name) {
      case "name":
        setName(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
    }
  }

  function activeIndexHandler(index) {
    if (index != activeIndex) {
      setActiveIndex(index);
    } else {
      setActiveIndex(-1);
    }
  }

  const getWidth = () => {
    const div = document.getElementsByClassName("members-inner-div");
    if (div.length >= 1) {
      return div[0].clientWidth;
    }
    if (window.innerWidth > 1500) {
      return window.innerWidth;
    }
    return 0;
  };

  function emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  function submit() {
    let contact;
    if (activeIndex === -1) {
      contact = {};
    } else {
      contact = props.contacts.find((x) => x.id === activeIndex);
    }
    contact.name = name;
    if (emailIsValid(email)) {
      contact.email = email;
    } else {
      M.toast({
        html: "<span aria-live='assertive'>Email is not valid</span>",
        duration: 3000,
        classes: "red",
      });
      return;
    }
    if (name === "") {
      M.toast({
        html: "<span aria-live='assertive'>Please enter the contact name</span>",
        duration: 3000,
        classes: "red",
      });
      return;
    }
    if (email === "") {
      M.toast({
        html: "<span aria-live='assertive'>Please enter the contact email</span>",
        duration: 3000,
        classes: "red",
      });
      return;
    }
    contact.type = props.type;
    if (activeIndex != -1) {
      props.submitHandler(contact);
      setActiveIndex(-1);
    } else {
      props.addContactHandler(contact);
    }
    setName("");
    setEmail("");
  }

  function deleteHandler(contact) {
    setActiveIndex(-1);
    setName("");
    setEmail("");
    props.deleteContactHandler(contact);
  }

  function useCurrentWidth() {
    // save current window width in the state object
    const [width, setWidth] = useState(getWidth());

    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {
      const resizeListener = () => {
        // change width from the state object
        setWidth(getWidth());
      };
      // set resize listener
      setWidth(getWidth());
      window.addEventListener("resize", resizeListener);

      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener("resize", resizeListener);
      };
    }, []);

    return width;
  }
  const width = useCurrentWidth();
  return (
    <ProjectContactsBox
      fullWidth={!!(props.fullWidth && props.fullWidth === true)}
      key={props.contacts.length}
      style={props.details ? { marginBottom: "20px" } : {}}
    >
      <div
        style={
          props.scroll && props.scroll === true
            ? { maxHeight: "100px", padding: "2px", overflow: "auto" }
            : {}
        }
        className="members-inner-div"
      >
        <div
          style={{
            display: "flex",
            alignItems: "end",
            gap: "16px",
            flex: "1 0 0",
            marginBottom: "32px",
          }}
          role="form"
        >
          <form
            style={{
              display: "flex",
              gap: "8px",
              width: "100%",
            }}
          >
            <div style={{ flex: 1 }}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                maxLength="19"
                style={{ margin: "8px 0px 0px" }}
                placeholder="Enter a Name"
                onChange={handleInputChange}
                className="browser-default text-input-v2"
                value={name}
                autoComplete="off"
                name="name"
              />
            </div>
            <div style={{ flex: 1 }}>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                style={{ margin: "8px 0px 0px" }}
                onChange={handleInputChange}
                className="browser-default text-input-v2"
                autoComplete="off"
                placeholder="Enter a Valid Email Address"
                value={email}
                name="email"
                maxLength="50"
              />
            </div>
          </form>
          {props.details ? (
            <SubmitButton role="button" onClick={submit}>
              {`Add ${
                props.type === "invoicing" ? "Invoice" : "Client"
              } Contact`}
            </SubmitButton>
          ) : (
            <SubmitButton
              role="button"
              aria-label="Add Contact"
              onClick={submit}
            >
              Add Contact
            </SubmitButton>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: props.contacts.length === 0 ? "79px" : "0",
            marginBottom: props.contacts.length === 0 ? "0px" : "0px",
          }}
        >
          {props.contacts.length === 0 && (
            <p style={{ color: "#b4b4b4" }}>
              {`No ${
                props.type === "invoicing" ? "Invoicing" : "Client"
              } Contacts to Display`}
            </p>
          )}
        </div>
        <ContactRowWrapper aria-label={`Client Contacts List`} role="list">
          {props.contacts.map((contact, index) => {
            return (
              <ContactCard
                active={activeIndex === contact.id}
                activeIndexHandler={activeIndexHandler}
                submitHandler={props.submitHandler}
                deleteContact={deleteHandler}
                key={index}
                contact={contact}
              />
            );
          })}
        </ContactRowWrapper>
      </div>
    </ProjectContactsBox>
  );
}

const ProjectContactsBox = styled.div`
  cursor: default;
  flex: 1;

  width: ${(props) => (props.fullWidth ? "100%" : "60%")};
  p {
    font-size: 14px;
  }
  p.title-box {
    color: #e7e7e7;
    font-size: 14px;
  }
`;
const ContactRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const SubmitButton = styled.button`
  display: flex;
  max-width: 100px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 5px;
  border: none;
  background: #519acc;
  box-shadow: 0px 3px 0px 0px #578bc2;
  color: white;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background: #578bc2;
  }
`;
export default ProjectContacts;
