import React from "react";

const SciFiRoomIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M11.5 0C17.5744 0 22.5 4.37556 22.5 9.77778C22.5 15.18 15.1667 22 11.5 22C7.83333 22 0.5 15.18 0.5 9.77778C0.5 4.37556 5.42556 0 11.5 0ZM9.43445 9.69222C8.18778 7.68778 5.96333 6.82 4.47222 7.74889C2.98111 8.67778 2.78556 11.0611 4.03222 13.0656C5.29111 15.07 7.51556 15.9378 9.00667 15.0089C10.4978 14.0556 10.6811 11.6967 9.43445 9.69222ZM13.5656 9.69222C12.3189 11.6967 12.5022 14.0556 13.9933 15.0089C15.4844 15.9378 17.7089 15.07 18.9678 13.0656C20.2144 11.0611 20.0189 8.67778 18.5278 7.74889C17.0367 6.82 14.8122 7.68778 13.5656 9.69222ZM11.5 18.0278C9.05556 18.0278 8.44445 17.1111 8.44445 17.1111C8.44445 17.1478 9.05556 19.5556 11.5 19.5556C13.9444 19.5556 14.5556 17.1111 14.5556 17.1111C14.5556 17.1111 13.9444 18.0278 11.5 18.0278Z"
        fill="white"
      />
    </svg>
  );
};

export default SciFiRoomIcon;
