import React, { useState, useCallback } from "react";
import styled from "styled-components";
import ms from "components/modals/ModalStyled";
import ToolTipCircle from "../../common/tooltip_circle";
import RateInput from "../../inputs/rateInput";

const EstimateCard = styled.div``;

const EstRequestTransition = (props) => {
  const { project, cancelHandler, currentUser, submitHandler } = props;

  const [link, setLink] = useState(() => {
    if (
      Array.isArray(project.project_links) &&
      project.project_links.length >= 1
    ) {
      return project.project_links[0].location;
    }
    return "";
  });

  const [rate, setRate] = useState(
    project.rate ? project.rate / 100 : 7000 / 100
  );

  const [hours, setHours] = useState(
    project.budgeted_hours ? parseFloat(project.budgeted_hours).toFixed(2) : ""
  );

  function roundInput(e) {
    let input = parseFloat(e.target.value);
    let value;
    if (input) {
      if (input < 0) {
        input = Math.abs(input);
      }
      if (input <= 0.12) {
        value = "0.25";
      } else {
        value = (Math.round(input * 4) / 4).toFixed(2);
      }
    } else {
      value = "0.00";
    }
    setHours(value);
  }

  function onPaste(e) {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("Text");
    const num = parseFloat(pastedData);
    if (num && num < 2000) {
      setHours(num.toFixed(2));
    }
  }

  const handleRateChange = useCallback(
    (e) => {
      let value;
      if (e.target.value > 100 || e.target.value.length >= 3) {
        value = rate;
      } else if (e.target.value === "") {
        value = "";
      } else {
        value = Math.round(e.target.value);
      }
      setRate(value);
    },
    [rate]
  );

  function handleInputChange(e) {
    let input = e.target.value;
    if (input.length === 0 || (input.length === 1 && input === ".")) {
      input = "";
    } else if (input.length === 5 && !input.includes(".")) {
      if (input[4] !== "." && Number.isNaN(input[4])) {
        e.preventDefault();
      } else if (!Number.isNaN(input[4])) {
        input = `${input.slice(0, 4)}.${input[4]}`;
      }
    } else if (input.length > 7) {
      input = input.slice(0, input.length - 1);
    } else if (input.split(".")[1] && input.split(".")[1].length > 2) {
      input = input.slice(0, input.length - 1);
    }
    setHours(input);
  }

  function handleLinkChange(e) {
    setLink(e.target.value);
  }

  const submit = useCallback(
    (e) => {
      e.preventDefault();
      const errors = [];
      const projectData = {};

      if (hours) {
        projectData.budgeted_hours = hours;
      } else {
        errors.push("Please select an amount of booked hours");
      }

      if (rate) {
        projectData.rate = rate * 100;
      } else {
        errors.push("Please select a rate");
      }

      if (link) {
        const linkAttrs = {
          project_id: project.id,
          user_id: currentUser.id,
          location: link,
          name: link.split(".")[1],
          link_type: "estimate",
        };
        projectData.project_links_attributes = [linkAttrs];
      } else {
        errors.push("Please add an URL to the estimate");
      }

      if (errors.length === 0) {
        submitHandler(projectData);
      } else {
        // eslint-disable-next-line no-undef
        M.toast({
          html: errors.join(", "),
          classes: "danger",
        });
      }
    },
    [hours, rate, link, project, currentUser, submitHandler]
  );

  return (
    <EstimateCard>
      <ms.Form
        encType="multipart/formdata"
        onSubmit={submit}
        className="flex-container flex-column flex-al-center"
      >
        <ms.FormRow>
          <ms.FormItem>
            <ms.Label className="more-padding" style={{ position: "relative" }}>
              Estimate URL
              <span className="base-color">*</span>
              <ToolTipCircle
                style={{ left: "95px", position: "absolute", top: "-4px" }}
                toolTipText="Where can the estimate be found?"
              >
                <i
                  style={{ fontSize: "20px", color: "#b3b3b3" }}
                  className="material-icons"
                >
                  help
                </i>
              </ToolTipCircle>
            </ms.Label>
            <input
              className="browser-default text-input-v2"
              name="link"
              type="text"
              placeholder="Please provide a URL for the estimate"
              value={link}
              onChange={handleLinkChange}
            />
          </ms.FormItem>
        </ms.FormRow>
        <ms.FormHalfRow>
          <ms.FormItemFixedWidth $width="100px">
            <ms.Label className="more-padding">
              Budget Hours
              <span className="base-color">*</span>
            </ms.Label>
            <input
              className="browser-default text-input-v2"
              name="hours"
              type="number"
              value={hours}
              placeholder="0.00"
              onPaste={onPaste}
              onBlur={roundInput}
              onChange={handleInputChange}
              min={0}
              max={1000000}
              step={0.25}
            />
          </ms.FormItemFixedWidth>

          <ms.FormItemFixedWidth $width="100px">
            <RateInput
              rate={rate}
              handleRateChange={handleRateChange}
              labelClass="more-padding"
              customStyles={{
                label: { marginBottom: "8px", display: "block" },
                input: { height: "39px", width: "100%", outline: "none" },
              }}
            />
          </ms.FormItemFixedWidth>
        </ms.FormHalfRow>

        <ms.ControlsRow className="flex-container">
          <button
            type="button"
            onClick={cancelHandler}
            className="common-button-cancel"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={link === "" || hours === ""}
            className="common-button-submit"
          >
            Submit
          </button>
        </ms.ControlsRow>
      </ms.Form>
    </EstimateCard>
  );
};

export default EstRequestTransition;
