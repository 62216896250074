import React from "react";
import styled from "styled-components";
import ClockIcon from "../icons/clock_icon";

const TimeEntryCard = styled.a.attrs((props) => ({
  twoWeeks: props.twoWeeks,
}))`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 3px 2px #cccccc;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
  margin: 10px 0px;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  p {
    font-size: 14px;
    width: ${(props) => (props.twoWeeks ? "80px" : "145px")};
    padding-left: ${(props) => (props.twoWeeks ? "10px" : "0px")};
    font-family: Manrope;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p > span {
    color: lightgray;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Arial;
    white-space: nowrap;
  }
  button {
    background: none;
    border: solid 1px lightgray;
    border-radius: 3px;
    cursor: pointer;
    line-height: 13px;
    padding: 2px 4px;
  }
  button:hover {
    background-color: #e1e1e1;
  }
  > div:nth-child(2) span {
    font-family: "Manrope";
    font-size: 18px;
    width: 50px;
    font-weight: 500;
  }
`;

class TimeEntryColumnCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.editTask = this.editTask.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.taskCard = React.createRef();
  }

  editTask() {
    const { task } = this.props;
    const data = {
      taskId: task.id,
      taskName: task.name,
      userName: task.user_name,
      createdByName: task.created_by_name,
      createdById: task.created_by,
      date: this.props.date,
      userId: task.user_id,
      closeButton: true,
      customClass: "add-task-modal",
      disabled: this.props.disabled,
    };
    this.props.modalAction(true, "add-task", data);
  }

  onDragStart(e) {
    const elem = document.getElementById("tasks-section");
    if (elem) {
      elem.classList.add("dragging");
    }
    e.target.style.opacity = "0.5";
    const data = {
      taskId: this.props.task.id,
      userId: this.props.task.user_id,
      date: this.props.date,
    };
    e.dataTransfer.effectAllowed = "all";
    e.dataTransfer.setData("text", JSON.stringify(data));
  }

  onDragEnd(e) {
    const elem = document.getElementById("tasks-section");
    if (elem) {
      elem.classList.remove("dragging");
    }
    e.target.style.opacity = "1";
  }

  render() {
    const { timeEntry } = this.props;
    return (
      <TimeEntryCard
        role="link"
        tabIndex={0}
        twoWeeks={this.props.twoWeeks}
        href={
          this.props.userShowPage
            ? `/time_tracking/users/${timeEntry.user_id}`
            : `/time_tracking?selected_date=${this.props.date}&time_entry_id=${timeEntry.id}`
        }
        target="_blank"
        aria-label={`Project ${timeEntry.project_name}, ${
          timeEntry.time_entry_task_name
            ? `Task ${timeEntry.time_entry_task_name}, `
            : ""
        }${timeEntry.hours.toFixed(
          2
        )} hours logged. Activate to navigate to time entry page.`}
      >
        <div
          aria-hidden="true"
          style={{ display: "flex", alignItems: "center", width: "100%" }}
        >
          {!this.props.twoWeeks && (
            <div
              aria-hidden="true"
              style={{
                backgroundColor: timeEntry.color,
                overflow: "hidden",
                height: "34px",
                minWidth: "34px",
                padding: "2px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0px 10px 0px 10px",
              }}
            >
              <ClockIcon height={30} width={30} color="white" />
            </div>
          )}
          <p>
            {timeEntry.project_name}
            <br />
            <span style={{ color: "#bfbfbf" }}>
              {timeEntry.time_entry_task_name}
              <br />
              <span>
                {timeEntry.hours.toFixed(2)}
                {' '}
                hrs
              </span>
            </span>
          </p>
        </div>
      </TimeEntryCard>
    );
  }
}

export default TimeEntryColumnCard;
