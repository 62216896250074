import React from "react";
import CalendarIcon from "../icons/calendar_icon";

function CircleIconContainer(props) {
  let button;
  switch (props.icon) {
    case "calendar":
      button = (
        <CalendarIcon
          color={props.color}
          height={props.height}
          width={props.width}
        />
      );
      break;
    default:
      button = <i className="material-icons spin">build</i>;
      break;
  }
  return <div className="icon-container">{button}</div>;
}

CircleIconContainer.defaultProps = {
  color: "white",
  height: "40px",
  width: "40px",
};
export default CircleIconContainer;
