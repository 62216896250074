import React from "react";
import moment from "moment";
import "react-dates/initialize";
import { DayPickerSingleDateController } from "react-dates";
import omit from "lodash/omit";

class DatePick extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: true,
      currentDay: this.props.currentDay,
      showDatePicker: false,
    };
    this.onDateChange = this.onDateChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.handleShowCalendarToggle = this.handleShowCalendarToggle.bind(this);
    this.datePicker = React.createRef();
    this.isDayBlocked = this.isDayBlocked.bind(this);
  }

  componentDidUpdate(prevProps) {
    const newCurrentDay = moment(this.props.currentDay, "MM-DD-YYYY").format(
      "MM-DD-YYYY"
    );
    const prevCurrentDay = moment(prevProps.currentDay, "MM-DD-YYYY").format(
      "MM-DD-YYYY"
    );
    if (newCurrentDay !== prevCurrentDay) {
      this.setState({ currentDay: newCurrentDay });
    } else {
      return null;
    }
  }

  onDateChange(date) {
    const newCurrentDay = moment(date).format("MM-DD-YYYY");
    this.setState({ currentDay: date, showDatePicker: false });
    this.onUpdateStateByCalendar(newCurrentDay);
  }

  onFocusChange({ focused }) {
    this.setState({ focused });
  }

  onUpdateStateByCalendar(newDate) {
    this.props.onUpdateStateByCalendar(newDate);
  }

  handleShowCalendarToggle() {
    if (!this.state.showDatePicker) {
      this.setState({ showDatePicker: true });
    } else {
      this.setState({ showDatePicker: false });
    }
  }

  initialVisibleMonth() {
    return moment(new Date());
  }

  isDayBlocked(day) {
    return !!(moment().utc(-7).diff(day) >= 0
      || moment(day).day() === 0
      || moment(day).day() === 6);
  }

  render() {
    const { showInput, enableOutsideDays } = this.props;
    const { focused, date } = this.state;
    const props = omit(this.props, [
      "autoFocus",
      "initialDate",
      "showInput",
      "enableOutsideDays",
    ]);

    const dateString = moment(this.state.currentDay, "MM-DD-YYYY");
    return (
      <div>
        <div className=" m1 valign-wrapper ">
          <i
            style={{ cursor: "pointer" }}
            className="small material-icons navbar-dateIcon"
            onClick={this.handleShowCalendarToggle}
          >
            date_range
          </i>
        </div>
        <div className={this.state.showDatePicker ? "DayPicker_div" : ""}>
          {this.state.showDatePicker ? (
            <DayPickerSingleDateController
              date={dateString}
              numberOfMonths={12}
              orientation="verticalScrollable"
              onDateChange={this.onDateChange}
              onFocusChange={this.onFocusChange}
              focused={this.state.focused}
              isOutsideRange={this.isDayBlocked}
              initialVisibleMonth={this.initialVisibleMonth}
              // isDayBlocked={this.isDayBlocked}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default DatePick;
