import React from "react";

const MeetingIcon = () => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="42"
        height="42"
        rx="21"
        fill="url(#paint0_linear_5216_5142)"
      />
      <path
        d="M17.4805 18.2369L18.9889 17.5428C19.5671 17.9284 20.2709 18.1598 21.0251 18.1598C23.1117 18.1598 24.8211 16.4117 24.8211 14.2781C24.8211 12.1188 23.1368 10.3965 21.0251 10.3965C18.9385 10.3965 17.2291 12.1188 17.2291 14.2781C17.2291 15.0493 17.4554 15.7692 17.8325 16.3603L17.1538 17.9027C17.0783 18.1084 17.2794 18.3398 17.4805 18.2369V18.2369ZM23.0111 13.6868C23.338 13.6868 23.5894 13.9439 23.5894 14.2781C23.5894 14.6123 23.338 14.8694 23.0111 14.8694C22.6843 14.8694 22.4329 14.6123 22.4329 14.2781C22.4329 13.9439 22.7095 13.6868 23.0111 13.6868ZM21.0251 13.6868C21.352 13.6868 21.6033 13.9439 21.6033 14.2781C21.6033 14.6123 21.352 14.8694 21.0251 14.8694C20.6983 14.8694 20.4469 14.6123 20.4469 14.2781C20.4469 13.9439 20.7235 13.6868 21.0251 13.6868ZM19.0643 13.6868C19.3912 13.6868 19.6425 13.9439 19.6425 14.2781C19.6425 14.6123 19.3912 14.8694 19.0643 14.8694C18.7375 14.8694 18.4861 14.6123 18.4861 14.2781C18.4861 13.9439 18.7375 13.6868 19.0643 13.6868Z"
        fill="white"
      />
      <path
        d="M16.8018 23.8666H15.1175C14.9415 23.8666 14.7906 23.7124 14.7906 23.5324V19.7022C14.7906 18.2111 13.6594 16.9259 12.2265 16.8488C10.6426 16.7717 9.3606 18.057 9.3606 19.625V26.3087C9.3606 27.157 10.0393 27.8768 10.8941 27.8768H14.2878C14.4638 27.8768 14.6147 28.031 14.6147 28.211V31.7328C14.6147 32.4011 15.1426 32.9409 15.7962 32.9409H17.3296C17.9832 32.9409 18.5111 32.4011 18.5111 31.7328L18.5112 25.6146C18.5112 24.6635 17.7319 23.8667 16.8018 23.8667L16.8018 23.8666Z"
        fill="white"
      />
      <path
        d="M15.0922 12.9671C15.0922 14.6707 13.7415 16.0518 12.0755 16.0518C10.4095 16.0518 9.05882 14.6707 9.05882 12.9671C9.05882 11.2635 10.4095 9.88232 12.0755 9.88232C13.7415 9.88232 15.0922 11.2635 15.0922 12.9671Z"
        fill="white"
      />
      <path
        d="M29.9245 16.8488C28.4412 16.8488 27.2094 18.0828 27.2094 19.6251V23.5325C27.2094 23.7124 27.0586 23.8667 26.8826 23.8667H25.1983C24.2682 23.8667 23.5141 24.6379 23.5141 25.589V31.7328C23.5141 32.4012 24.042 32.941 24.6955 32.941H26.229C26.8826 32.941 27.4105 32.4012 27.4105 31.7328L27.4106 28.211C27.4106 28.0311 27.5614 27.8768 27.7374 27.8768H31.1312C31.9608 27.8768 32.6647 27.1828 32.6647 26.3088V19.6251C32.6395 18.0827 31.4328 16.8488 29.9245 16.8488L29.9245 16.8488Z"
        fill="white"
      />
      <path
        d="M32.9412 12.9671C32.9412 14.6707 31.5905 16.0518 29.9245 16.0518C28.2585 16.0518 26.9078 14.6707 26.9078 12.9671C26.9078 11.2635 28.2585 9.88232 29.9245 9.88232C31.5905 9.88232 32.9412 11.2635 32.9412 12.9671Z"
        fill="white"
      />
      <path
        d="M24.72 22.4784C25.1977 22.4784 25.5748 22.0928 25.5748 21.6044C25.5748 21.1159 25.1977 20.7303 24.72 20.7303H17.2538C16.7761 20.7303 16.399 21.1159 16.399 21.6044C16.399 22.0928 16.7761 22.4784 17.2538 22.4784H20.1447V32.144C20.1447 32.6324 20.5218 33.018 20.9995 33.018C21.4772 33.018 21.8542 32.6324 21.8542 32.144V22.4784H24.72Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5216_5142"
          x1="21"
          y1="0"
          x2="21"
          y2="42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MeetingIcon;
