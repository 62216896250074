import React from "react";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import RoundDropdown from "../projects/round_dropdown";
import SingleSelectStyles from "./single_select_styles";

class SingleCreateableSelectWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open ? this.props.open : false,
    };
    this.roundDrop = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <RoundDropdown />
        </components.DropdownIndicator>
      );
    };
  }

  render() {
    return (
      <CreatableSelect
        classNamePrefix="react-select"
        options={this.props.options}
        onChange={this.props.onChange}
        value={this.props.value}
        blurInputOnSelect
        isSearchable
        isDisabled={this.props.isDisabled}
        styles={{
          ...SingleSelectStyles,
          ...this.props.customStyles,
        }}
        components={{ DropdownIndicator: this.roundDrop }}
        isValidNewOption={(inputValue, selectValue, selectOptions) => {
          let returnValue = true;
          selectOptions.forEach((option) => {
            if (!option.label) {
              // skip blanks
            } else if (
              option.label.toLowerCase().includes(inputValue.toLowerCase())
            ) {
              returnValue = false;
            }
          });
          return returnValue;
        }}
        formatCreateLabel={(inputValue) => (
          <div className="no-option-msg">
            <p
              style={{
                position: "relative",

                maxWidth: "155px",
                float: "left",
              }}
            >
              No client found for {inputValue}
            </p>
            <p
              className="base-color"
              style={{ position: "relative", left: "-39px" }}
            >
              Create New Client
            </p>
          </div>
        )}
      />
    );
  }
}

export default SingleCreateableSelectWrapper;
