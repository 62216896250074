import React from "react";

class InvoiceHeader extends React.Component {
  static formatNumber(num) {
    return num.length > 6 ? num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : num;
  }

  render() {
    return (
      <div className="invoice-heading">
        <div>
          <h1>Invoices</h1>
          {this.props.newTotal > 0 && (
            <span>
              {this.props.newTotal} new invoice
              {this.props.newTotal > 1 ? "s" : ""}
            </span>
          )}
        </div>
        <div>
          <p>Ready for Invoice</p>
          <p>
            <span style={{ fontWeight: "500" }}>$ </span>
            {this.props.readyTotal
              ? this.formatNumber(this.props.readyTotal.toFixed(2))
              : "0.00"}
          </p>
        </div>
        <div>
          <p>Pending Payment</p>
          <p>
            <span style={{ fontWeight: "500" }}>$ </span>
            {this.props.pendingTotal
              ? this.formatNumber(this.props.pendingTotal.toFixed(2))
              : "0.00"}
          </p>
        </div>
        <div>
          <p>Overdue</p>
          <p style={{ color: "red" }}>
            <span style={{ color: "red", fontWeight: "500" }}>$ </span>
            {this.props.ovedueTotal
              ? this.formatNumber(this.props.ovedueTotal.toFixed(2))
              : "0.00"}
          </p>
        </div>
        <div>
          <p>Total Paid</p>
          <p style={{ color: "#519acc" }}>
            <span style={{ color: "#519acc", fontWeight: "500" }}>$ </span>
            {this.props.totalPaid
              ? this.formatNumber(this.props.totalPaid.toFixed(2))
              : "0.00"}
          </p>
        </div>
      </div>
    );
  }
}

export default InvoiceHeader;
