import React, { useState, useEffect, useRef } from "react";
import SyncitLogo from "../icons/syncit_logo";

const Navbar = ({ role, name, additional_permissions, avatar }) => {
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(null);
  const [clientsDropdownOpen, setClientsDropdownOpen] = useState(false);
  const [scheduleDropdownOpen, setScheduleDropdownOpen] = useState(false);
  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
  const [ariaHidden, setAriaHidden] = useState(true);
  const [tabIndex, setTabIndex] = useState(-1);

  const handleKeyDown = (e, setDropdownOpen) => {
    if (e.keyCode === 32) {
      setDropdownOpen(true);
      setAriaHidden(false);
      setTabIndex(0);
    }
  };

  const toggleNav = () => {
    setSideNavOpen(!sideNavOpen);
  };

  const clientsDropdownRef = useRef(null);
  const scheduleDropdownRef = useRef(null);
  const accountDropdownRef = useRef(null);

  const handleBlur = (e, dropdownRef, setDropdownOpen) => {
    if (!dropdownRef.current.contains(e.relatedTarget)) {
      setDropdownOpen(false);
      setAriaHidden(true);
      setTabIndex(-1);
    }
  };

  useEffect(() => {
    const path = window.location.pathname;
    setActiveLink(path);
  }, []);

  return (
    <div>
      <nav
        style={{
          boxShadow: "none",
          borderBottom: "1px solid #d7d7d7",
          height: "64px",
        }}
      >
        <div className="nav-wrapper white">
          <a
            aria-label="Sync it Logo"
            style={{ marginLeft: "32px", position: "relative", top: "9px" }}
            href="/"
          >
            <SyncitLogo />
          </a>

          <a onClick={toggleNav} className="hide-on-large-only right nav-right">
            <i className="material-icons black-text">menu</i>
          </a>
          <ul className="right nav-right hide-on-med-and-down">
            <li>
              <a
                className={`nav-list ${
                  activeLink === "/" ? "active-link" : ""
                }`}
                href="/"
              >
                Home
              </a>
            </li>

            {role === 1 && (
              <li>
                <a
                  className={`nav-list ${
                    activeLink === "/meetings" ? "active-link" : ""
                  }`}
                  data-turbolinks="false"
                  href="/meetings"
                >
                  Meetings
                </a>
              </li>
            )}

            {role > 1 && (
              <li
                className={`nav-dropdown one-item ${
                  clientsDropdownOpen ? "active-dropdown" : ""
                }`}
                onBlur={(e) =>
                  handleBlur(e, clientsDropdownRef, setClientsDropdownOpen)
                }
                onKeyDown={(e) => handleKeyDown(e, setClientsDropdownOpen)}
                style={{ position: "relative" }}
                id="clients_nav"
                ref={clientsDropdownRef}
              >
                <a
                  aria-label="press space to open menu, press control + option + space to activate the link"
                  className={`nav-list ${
                    activeLink === "/clients" ? "active-link" : ""
                  }`}
                  href="/clients"
                >
                  Clients
                </a>
                <div>
                  <ul style={{ cursor: "pointer" }}>
                    <li style={{ padding: "0px 0px" }}>
                      <a
                        className={`nav-list ${
                          activeLink === "/projects" ? "active-link" : ""
                        }`}
                        style={{ padding: "0px 12px" }}
                        href="/projects"
                        onClick={() => setActiveLink("/projects")}
                        aria-hidden={ariaHidden}
                        tabIndex={tabIndex}
                      >
                        Projects
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            )}

            {(additional_permissions.includes("taskboard") ||
              role === 4 ||
              role === 5) && (
              <li>
                <a
                  className={`nav-list ${
                    activeLink === "/taskboard" ? "active-link" : ""
                  }`}
                  href="/taskboard"
                >
                  Taskboard
                </a>
              </li>
            )}

            {role > 1 && (
              <li
                aria-label="press space to open menu, press control + option + space to activate the link"
                className={`nav-dropdown ${
                  scheduleDropdownOpen ? "active-dropdown" : ""
                } ${
                  role === 2
                    ? "one-item"
                    : role === 3
                    ? "two-items"
                    : "three-items"
                }`}
                onBlur={(e) =>
                  handleBlur(e, scheduleDropdownRef, setScheduleDropdownOpen)
                }
                onKeyDown={(e) => handleKeyDown(e, setScheduleDropdownOpen)}
                style={{ position: "relative" }}
                id="schedule_nav"
                ref={scheduleDropdownRef}
              >
                <a
                  href="/schedule"
                  className="nav-list "
                  style={{ padding: "0px 16px" }}
                >
                  Schedule
                </a>
                <div>
                  <ul style={{ pointer: "cursor", padding: "0px" }}>
                    {role >= 3 && (
                      <li style={{ padding: "0px 0px", width: "100%" }}>
                        <a
                          className={`nav-list ${
                            activeLink === "/users" ? "active-link" : ""
                          }`}
                          style={{ paddingLeft: "0px 12px" }}
                          href="/users"
                          aria-hidden={ariaHidden}
                          tabIndex={tabIndex}
                        >
                          Users
                        </a>
                      </li>
                    )}

                    {role >= 4 && (
                      <li style={{ padding: "0px 0px", width: "100%" }}>
                        <a
                          className={`nav-list ${
                            activeLink === "/dashboard" ? "active-link" : ""
                          }`}
                          aria-label="list item"
                          style={{ padding: "0px 12px" }}
                          href="/dashboard"
                          aria-hidden={ariaHidden}
                          tabIndex={tabIndex}
                        >
                          Capacity
                        </a>
                      </li>
                    )}

                    <li style={{ padding: "0px 0px", width: "100%" }}>
                      <a
                        className={`nav-list ${
                          activeLink === "/meetings" ? "active-link" : ""
                        }`}
                        aria-label="list item"
                        style={{ padding: "0px 12px" }}
                        data-turbolinks="false"
                        href="/meetings"
                        aria-hidden={ariaHidden}
                        tabIndex={tabIndex}
                      >
                        Meetings
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            )}

            <li
              className={`nav-dropdown three-items  ${
                accountDropdownOpen ? "active-dropdown" : ""
              }`}
              onBlur={(e) =>
                handleBlur(e, accountDropdownRef, setAccountDropdownOpen)
              }
              onKeyDown={(e) => handleKeyDown(e, setAccountDropdownOpen)}
              style={{ position: "relative" }}
              id="account_nav"
              ref={accountDropdownRef}
            >
              <div
                className="nav-list-account"
                style={{
                  paddingRight: "32px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                tabIndex={0}
                aria-label="press space to open Account dropdown menu"
              >
                <img
                  style={{ height: "25px", width: "25px", borderRadius: "50%" }}
                  alt="user_image"
                  src={avatar}
                  role="user avatar"
                />
                <span
                  className="user-name-reset"
                  style={{
                    marginLeft: "16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {name}
                </span>
              </div>
              <div>
                <ul>
                  <li
                    style={{ padding: "0px 0px", width: "100%" }}
                    aria-hidden={ariaHidden}
                  >
                    <a
                      href="/account"
                      className={`nav-list ${
                        activeLink === "/account" ? "active-link" : ""
                      }`}
                      aria-hidden={ariaHidden}
                      tabIndex={tabIndex}
                    >
                      Account
                    </a>
                  </li>
                  <li
                    style={{ padding: "0px 0px", width: "100%" }}
                    aria-hidden={ariaHidden}
                  >
                    <a
                      className={`nav-list ${
                        activeLink === "/help" ? "active-link" : ""
                      }`}
                      href="/help"
                      aria-hidden={ariaHidden}
                      tabIndex={tabIndex}
                    >
                      Help
                    </a>
                  </li>
                  <li
                    style={{ padding: "0px 0px", width: "100%" }}
                    aria-hidden={ariaHidden}
                  >
                    <a
                      href="/logout"
                      className="nav-list"
                      aria-hidden={ariaHidden}
                      tabIndex={tabIndex}
                    >
                      Sign Out
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>

          <div
            className="sidenav hide-on-large-only"
            style={{ width: `${sideNavOpen ? "250px" : "0px"}` }}
            id="side-nav"
          >
            <a onClick={toggleNav} className="full-width right closebtn">
              <i className="material-icons black-text inline-icon closebtn-icon">
                close
              </i>
            </a>
            <a
              href="/"
              className={`${activeLink === "/" ? "active-link" : ""}`}
            >
              Home
            </a>
            <a href="/meetings">Meetings</a>
            {role > 1 && (
              <a href="/clients" className="nav-list">
                Clients
              </a>
            )}
            {role > 1 && (
              <a href="/projects" className="nav-list">
                Projects
              </a>
            )}
            <a href="/taskboard" className="nav-list">
              Taskboard
            </a>
            <a href="/schedule" className="nav-list">
              Schedule
            </a>
            {role >= 3 && (
              <a href="/users" className="nav-list">
                Users
              </a>
            )}
            {role >= 4 && (
              <a href="/dashboard" className="nav-list">
                Capacity
              </a>
            )}
            <a href="/meetings" className="nav-list">
              Meetings
            </a>
            <a href="/account" className="nav-list">
              Account
            </a>
            <a href="/help" className="nav-list">
              Help
            </a>
            <a href="/logout" className="nav-list">
              Sign Out
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
