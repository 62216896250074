import React from "react";
import LeadSourceFilledIcon from "../icons/lead_source_filled_icon";
import LeadTypeFilledIcon from "../icons/lead_type_filled_icon";
import FolderIcon from "../icons/folder_icon";
import DropperContainer from "../common/dropper_container";
import ProjectDetailTimeline from "./project_detail_timeline";
import styled from "styled-components";

class ProjectDeets extends React.Component {
  constructor(props) {
    super(props);
    this.stages = {
      leading: 0,
      est_requesting: 1,
      est_sending: 2,
      project_confirming: 3,
      testing: 4,
      completing: 5,
      readying: 6,
      invoicing: 7,
    };
  }

  render() {
    const { project } = this.props;
    const stage = this.stages[this.props.stage];

    return (
      <DropperContainer title="Project Details">
        <DetailTypeIconContainer>
          <IconContainer>
            <LeadSourceFilledIcon color="#519acc" width={40} height={40} />
            <TextDescription>
              <HeaderSource>Lead Source:</HeaderSource>
              <SubheaderSource>
                {project.lead_source ? project.lead_source : "Unknown"}
              </SubheaderSource>
            </TextDescription>
          </IconContainer>
          <IconContainer>
            <LeadTypeFilledIcon color="#519acc" width={40} height={40} />
            <TextDescription>
              <HeaderSource>Project Type:</HeaderSource>
              <SubheaderSource>
                {project.tag ? project.tag.name : "Unknown"}
              </SubheaderSource>
            </TextDescription>
          </IconContainer>
        </DetailTypeIconContainer>
        <TimelineContainer
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <ProjectDetailTimeline
            project={this.props.project}
            fullWidth={true}
          />
        </TimelineContainer>
      </DropperContainer>
    );
  }
}

const DetailTypeIconContainer = styled.div`
  width: 100%;
  padding: 32px;
  gap: 32px;
  display: flex;
  border-bottom: 1px solid #e7e7e7;
  cursor: auto;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const TextDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
`;

const HeaderSource = styled.span`
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.12px;
  text-align: left;
`;

const SubheaderSource = styled.span`
  font-family: Arial;
  font-size: 13px;
  font-weight: 400;
  line-height: 14.95px;
  text-align: left;
  text-transform: capitalize;
`;

const TimelineContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 16px;
`;
export default ProjectDeets;
