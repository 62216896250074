import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        filters: [],
      },
    };
    this.getFilters = this.getFilters.bind(this);
  }

  getFilters() {
    const url = "/get_filters";
    axios
      .get("/get_filters", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.setState({ filters: res.data });
      })
      .catch((err) => console.error(err));
  }

  componentDidMount() {
    document.querySelector(".react-preload").style.display = "none";
    this.getFilters();
  }

  renderUserFilters(filter) {
    return (
      <li key={filter}>
        <a>{filter.name}</a>
      </li>
    );
  }

  render() {
    if (!this.state.filters) {
      return null;
    }

    const userFilters = this.state.filters[0];
    return (
      <div id="filters-container" className="col s3 offset-s9">
        <h5>Filters</h5>
        <ul className="filter-list">
          {userFilters.map(this.renderUserFilters)}
        </ul>
      </div>
    );
  }
}

export default Filter;
