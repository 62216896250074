import React from "react";

const GreyRoomIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M15.7006 7.34961C15.4112 7.34977 15.1307 7.45028 14.9071 7.63402C14.6835 7.81775 14.5304 8.07335 14.4741 8.35725C14.4178 8.64116 14.4616 8.93581 14.5982 9.19101C14.7348 9.4462 14.9556 9.64615 15.2231 9.75679C15.4905 9.86743 15.7881 9.88191 16.065 9.79776C16.3419 9.71362 16.5811 9.53606 16.7418 9.29533C16.9025 9.0546 16.9748 8.7656 16.9463 8.47757C16.9178 8.18954 16.7903 7.92029 16.5856 7.71571C16.4695 7.59937 16.3315 7.50713 16.1797 7.4443C16.0278 7.38147 15.865 7.34929 15.7006 7.34961Z"
        fill="white"
      />
      <path
        d="M22.6658 2.34646C22.6656 2.34506 22.6656 2.34364 22.6658 2.34224C22.6262 2.16848 22.5388 2.00926 22.4135 1.88253C22.2882 1.75581 22.1299 1.66663 21.9566 1.62505C20.5597 1.28427 18.3622 1.64755 15.9271 2.62255C13.4727 3.60693 11.173 5.06005 9.61865 6.61724C9.12043 7.11281 8.65758 7.6427 8.23349 8.20302C7.18677 8.15614 6.26474 8.30521 5.48802 8.64411C2.78146 9.83521 2.00849 12.8835 1.80271 14.1351C1.77294 14.3129 1.78459 14.4952 1.83678 14.6678C1.88897 14.8404 1.98028 14.9986 2.10361 15.1301C2.22693 15.2617 2.37895 15.363 2.54782 15.4261C2.71669 15.4893 2.89786 15.5127 3.07724 15.4944H3.08334L6.09834 15.1654C6.10209 15.2038 6.10631 15.239 6.10959 15.2713C6.14831 15.6387 6.31215 15.9816 6.57365 16.2426L8.04506 17.7149C8.30561 17.9768 8.64846 18.1408 9.01584 18.1794L9.11662 18.1902L8.78849 21.2015V21.2076C8.77172 21.3697 8.78898 21.5336 8.83916 21.6888C8.88934 21.8439 8.97134 21.9868 9.07991 22.1085C9.18849 22.2301 9.32125 22.3277 9.46971 22.3951C9.61816 22.4625 9.77905 22.4982 9.94209 22.4999C10.0073 22.4999 10.0723 22.4946 10.1366 22.484C11.3952 22.2815 14.4421 21.5179 15.6299 18.7968C15.966 18.0252 16.1146 17.1069 16.0729 16.0621C16.6357 15.6389 17.1675 15.176 17.6643 14.6769C19.2313 13.1179 20.6891 10.8369 21.6632 8.41818C22.633 6.01068 22.9982 3.79677 22.6658 2.34646ZM17.6474 10.544C17.2622 10.9295 16.7714 11.1922 16.2369 11.2987C15.7024 11.4052 15.1484 11.3508 14.6448 11.1424C14.1413 10.934 13.7109 10.5809 13.408 10.1278C13.1052 9.67472 12.9436 9.14198 12.9436 8.597C12.9436 8.05202 13.1052 7.51929 13.408 7.0662C13.7109 6.6131 14.1413 6.26001 14.6448 6.05159C15.1484 5.84316 15.7024 5.78877 16.2369 5.8953C16.7714 6.00183 17.2622 6.26449 17.6474 6.65005C17.9056 6.90433 18.1106 7.20741 18.2505 7.54166C18.3904 7.87591 18.4625 8.23465 18.4625 8.597C18.4625 8.95936 18.3904 9.3181 18.2505 9.65235C18.1106 9.9866 17.9056 10.2897 17.6474 10.544Z"
        fill="white"
      />
      <path
        d="M7.87587 18.1143C7.69449 18.0918 7.51113 18.1363 7.36025 18.2395C7.06071 18.4443 6.75978 18.6468 6.45603 18.8442C5.8415 19.2435 5.1065 18.5423 5.47165 17.9067L6.04118 16.9223C6.12261 16.8032 6.16795 16.6631 6.17176 16.5189C6.17558 16.3746 6.13772 16.2324 6.06271 16.1091C5.9877 15.9859 5.87873 15.8869 5.74887 15.824C5.619 15.7611 5.47376 15.737 5.33056 15.7546C4.69845 15.8346 4.11089 16.1225 3.6604 16.5731C3.48884 16.7451 2.96712 17.2673 2.68634 19.2571C2.60642 19.8287 2.55604 20.404 2.5354 20.9807C2.53284 21.0808 2.55034 21.1804 2.58688 21.2736C2.62342 21.3669 2.67826 21.4518 2.74816 21.5235C2.81806 21.5952 2.90161 21.6522 2.99388 21.6911C3.08615 21.73 3.18527 21.75 3.2854 21.7499H3.30415C3.88134 21.7295 4.4571 21.6794 5.02915 21.5999C7.01993 21.3187 7.54212 20.7965 7.71368 20.6249C8.16635 20.1743 8.45358 19.5839 8.52884 18.9496C8.55294 18.7523 8.4977 18.5534 8.37526 18.3968C8.25282 18.2402 8.0732 18.1386 7.87587 18.1143Z"
        fill="white"
      />
    </svg>
  );
};

export default GreyRoomIcon;
