import React from "react";
import TimeEntryWeekCell from "./time_entry_week_cell";
import DeleteIcon from "../icons/delete_icon";
import styled from "styled-components";
import ClockIcon from "../icons/clock_icon";
import moment from "moment";

export const EntryRowDiv = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-wrap: nowrap;
  border-bottom: solid 1px lightgray;
  > div:first-child {
    width: calc(100% - 630px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  > div:last-child {
    width: 630px;
    display: flex;
  }
  > div:last-child > div:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 120px;
  }
  > div:last-child > div:last-child > span {
    font-family: "Manrope";
    font-size: 21px;
    width: 55px;
    font-weight: 500;
    text-align: right;
  }
  > div:last-child > div:last-child > button {
    background: none;
    border: solid 1px lightgray;
    border-radius: 3px;
    cursor: pointer;
    line-height: 13px;
    padding: 2px 4px;
    margin-left: 25px;
  }
`;

class TimeEntryWeekList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveWeekEnabled: false,
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    var elem = document.getElementById("time-entry-week-list");
    if (elem && elem.scrollHeight > elem.clientHeight) {
      elem = document.getElementById("time-entry-week-heading");
      if (elem) {
        elem.style.left = "-10px";
      }
    }
  }

  deleteEntryRow = (e) => {
    var id = parseInt(e.target.dataset.id);
    this.props.deleteEntryRow(id);
  };

  saveUpdatedWeekEntries = () => {
    this.setState({ saveWeekEnabled: false });
    this.props.saveUpdatedWeekEntries();
  };

  setSaveWeekEnabled = (bool) => {
    this.setState({ saveWeekEnabled: bool });
  };

  render() {
    var timeEntries = this.props.timeEntries;
    var allTotalHours = 0;
    var timeEntryCount = 0;
    if (
      Object.entries(this.props.timeEntries) &&
      Object.entries(this.props.timeEntries).length >= 1
    ) {
      timeEntryCount = Object.entries(this.props.timeEntries)
        .map((t) => t[1].length)
        .reduce((a, b) => a + b, 0);
    }
    return (
      <div
        id="time-entry-week-list"
        style={{
          borderTop: "solid 1px lightgray",
          margin: "0 10px 0 20px",
          paddingRight: "10px",
          maxHeight: "calc(100vh - 356px)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        aria-label={`Entries for week of ${moment(
          this.props.weekRange[0]
        ).format("MMMM Do")} through ${moment(
          this.props.weekRange[this.props.weekRange.length - 1]
        ).format("MMMM Do")} 
          , ${this.props.timeEntryWeeks.length} project row${
          this.props.timeEntryWeeks.length === 1 ? "" : "s"
        } ${
          timeEntryCount > 0
            ? `${timeEntryCount} time ${
                timeEntryCount > 1 ? "entries" : "entry"
              } logged`
            : "No time entries logged"
        }. Use button at the bottom of this section to save changes.`}
      >
        {this.props.timeEntryWeeks.map((tw) => {
          var totalHours = 0;
          return (
            <EntryRowDiv
              aria-label={`Project ${tw.project_name} ${
                tw.time_entry_task_name
                  ? `, Task ${tw.time_entry_task_name}`
                  : ""
              }`}
              key={tw.id}
              style={{
                width: "100%",
                height: "100px",
                display: "flex",
                flexWrap: "nowrap",
                borderBottom: "solid 1px lightgray",
              }}
            >
              <div
                aria-hidden={true}
                style={{
                  width: "calc(100% - 620px)",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <span style={{ fontFamily: "manrope" }}>{tw.project_name}</span>
                {tw.time_entry_task_name && (
                  <span style={{ color: "#bfbfbf" }}>
                    {tw.time_entry_task_name}
                  </span>
                )}
              </div>
              <div style={{ width: "620px", display: "flex" }}>
                {this.props.weekRange.map((d) => {
                  var timeEntry = timeEntries[d]
                    ? timeEntries[d].find((t) => t.time_entry_week_id === tw.id)
                    : null;
                  totalHours += timeEntry ? timeEntry.hours : 0;
                  allTotalHours += timeEntry ? timeEntry.hours : 0;
                  return (
                    <TimeEntryWeekCell
                      addUpdatedWeekEntries={this.props.addUpdatedWeekEntries}
                      updatedWeekEntries={this.props.updatedWeekEntries}
                      timeEntry={timeEntry}
                      setSaveWeekEnabled={this.setSaveWeekEnabled}
                      saveWeekEnabled={this.state.saveWeekEnabled}
                      timeEntryWeek={tw}
                      key={`${timeEntry ? timeEntry.id : "new"}-${d}-${tw.id}-${
                        tw.project_id
                      }-${
                        tw.time_entry_task_id ? tw.time_entry_task_id : "none"
                      }`}
                      day={d}
                    />
                  );
                })}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span
                    aria-label={`${totalHours.toFixed(
                      2
                    )} total hours logged this week for Project ${
                      tw.project_name
                    } ${
                      tw.time_entry_task_name
                        ? `, Task ${tw.time_entry_task_name}`
                        : ""
                    }. (This does not include unsaved changes)`}
                  >
                    {totalHours.toFixed(2)}
                  </span>
                  <button
                    data-id={tw.id}
                    aria-label="Delete this row of time entries"
                    onClick={this.deleteEntryRow}
                  >
                    <DeleteIcon
                      height={20}
                      width={20}
                      color="#519acc"
                      svgStyles={{ pointerEvents: "none" }}
                    />
                  </button>
                </div>
              </div>
            </EntryRowDiv>
          );
        })}
        <div style={{ marginBottom: "20px" }}>
          <button
            onClick={this.props.timeEntryWeekModal}
            style={{ fontSize: "13px", margin: "30px 20px 0 0" }}
            className="common-button-submit"
          >
            New Row
          </button>
          <button
            disabled={!this.state.saveWeekEnabled}
            onClick={this.saveUpdatedWeekEntries}
            style={{ fontSize: "13px", margin: "30px 0 0 0" }}
            className="common-button-submit"
          >
            Save Changes
          </button>
          <div
            aria-hidden="true"
            style={{
              display: "flex",
              float: "right",
              alignItems: "center",
              width: "130px",
              margin: "20px 46px 0px auto",
            }}
          >
            <span style={{ fontSize: "14px", color: "#bfbfbf" }}>Total: </span>
            <ClockIcon
              height={20}
              width={20}
              color="#519acc"
              svgStyles={{ margin: "0px 5px" }}
            />
            <span
              style={{
                fontSize: "21px",
                fontWeight: "600",
                fontFamily: "Manrope",
              }}
            >
              {allTotalHours.toFixed(2)}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default TimeEntryWeekList;
