import {
  shape, string, number, bool, arrayOf, any
} from "prop-types";

const userTypes = shape({
  active: bool,
  additional_permissions: arrayOf(string),
  availability: any, // fix this
  avatar_content_type: string,
  avatar_file_size: string,
  avatar_updated_at: string,
  confirmation_type: string,
  contractor: bool,
  created_at: string,
  current_sign_in_at: string,
  current_sign_in_ip: string,
  default_work_from_home: bool,
  email: string,
  encrypted_password: string,
  full_time: bool,
  id: number,
  last_sign_in_at: string,
  last_sign_in_ip: string,
  name: string,
  phone: string,
  phone_carrier_email: string,
  project_lead_id: number,
  project_schedule_id: number,
  pto_hours: number,
  receive_confirmation: bool,
  receive_digest: bool,
  remember_created_at: string,
  reset_password_sent_at: string,
  reset_password_token: string,
  role_id: number,
  secondary_email: string,
  sick_time_accrue_date: string,
  sick_time_hours: number,
  sign_in_count: number,
  slack_id: string,
  time_entry_remainder: number,
  time_zone: string,
  updated_at: string,
});

export default userTypes;
