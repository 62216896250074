const baseColor = "#519acc";
const medLightG = "#d1d1d1";
const colors = [
  "#519acc",
  "#d76f23",
  "#b82a23",
  "#6f79b8",
  "#20835d",
  "#d4487a",
  "#246995",
  "#cb9326",
  "#1bb3d4",
];
const TagSelectStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "white",
    borderRadius: "15px",
    transition: "border-color .5s",
    borderColor: state.isFocused
      ? `${baseColor} !important`
      : state.isHovered
      ? `${baseColor} !important`
      : "#d1d1d1",
    minHeight: "42px",
    boxShadow: "1px 1px 3px 0px #f0f0f0;",
    maxHeight: "42px",
    "&:hover": {
      borderColor: `${baseColor}`,
    },
  }),
  container: (styles) => ({
    ...styles,
    width: "250px",
    fontFamily: "Arial",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    height: "100%",
    marginTop: "0",
    backgroundColor: "transparent",
  }),
  input: (styles) => ({
    ...styles,
    boxShadow: "none !important",
    margin: "0",
    padding: "0",
    height: "25px",
    color: "transparent",
    width: "0",
  }),
  menu: (styles) => ({
    ...styles,
    transform: "translateX(-25px)",
    width: "300px",
    borderRadius: "5px",
    boxShadow: "0 0 9px 2px hsla(0,0%,0%,0.3), 0 4px 11px hsla(0,0%,0%,0.1)",
    zIndex: 2,
  }),
  placeholder: (styles, state) => ({
    ...styles,
    fontFamily: "Arial",
    marginLeft: state.hasValue ? "30px" : "0",
    pointerEvents: "none",
  }),
  menuList: (styles) => ({ ...styles, borderRadius: "5px" }),
  valueContainer: (styles, state) => ({
    ...styles,
    maxHeight: "300px",
    paddingTop: state.hasValue ? "10px" : "",
    order: 2,
    top: state.hasValue ? "30px" : "",
    left: state.hasValue ? "-10px" : "",
    position: state.hasValue ? "absolute" : "",
    overflow: "visible",
    width: "400px",
  }),
  indicatorsContainer: (styles, state) => ({
    ...styles,
    color: state.isFocused ? `${baseColor}` : `${medLightG}`,
    maxHeight: "42px",
    "&:hover": {
      color: `${baseColor}`,
    },
  }),
  dropdownIndicator: (styles, state) => {
    return {
      ...styles,
      color: state.isFocused
        ? `${baseColor} !important`
        : state.isHovered
        ? `${baseColor} !important`
        : "inherit",
      cursor: "pointer",
      "&:hover": {
        color: `${baseColor} !important`,
      },
    };
  },
  multiValue: (styles, state) => {
    // lol
    let color;
    if (state.data.__isNew__) {
      color = colors[state.index % 8];
      let prevColor = state.selectProps.value[state.index - 1];
      let nextColor = state.selectProps.value[state.index + 1];
      if (prevColor && nextColor) {
        prevColor = prevColor.color;
        nextColor = nextColor.color;
        if (prevColor === color && nextColor === color) {
          color = colors[state.index + (1 % 8)];
        }
      } else if (prevColor) {
        prevColor = prevColor.color;
        if (prevColor === color) {
          color = colors[state.index + (1 % 8)];
        }
      } else if (nextColor) {
        nextColor = nextColor.color;
        if (nextColor === color) {
          color = colors[state.index + (1 % 8)];
        }
      }
    } else {
      color = state.data.color;
    }
    return {
      ...styles,
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "400",
      textAlign: "center",
      borderRadius: "10px",
      fontSize: "13px",
      color: "white",
      backgroundColor: color,
    };
  },
  multiValueLabel: (styles) => {
    return {
      ...styles,
      color: "white",
      padding: "4px",
      paddingLeft: "12px",
    };
  },
  multiValueRemove: (styles) => {
    return {
      ...styles,
      borderRadius: "10px",
      backgroundColor: "transparent !important",
    };
  },
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? "white"
        : isSelected
        ? baseColor
        : isFocused
        ? baseColor
        : "white",
      color: isFocused ? "white" : isSelected ? "white" : "#313131",
      cursor: isDisabled ? "not-allowed" : "default",
      paddingLeft: "25px",
    };
  },
};

export default TagSelectStyles;
