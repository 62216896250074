import React from "react";

export default function HqRooms() {
  return (
    <div>
      <h4>HQ Rooms</h4>
      <ul>
        <li>Crater Lake Room</li>
        <li>Green Room</li>
        <li>Grey Room</li>
        <li>Orange Room</li>
        <li>Red Room</li>
        <li>Yellow Room</li>
        <li>Blue Room</li>
      </ul>
    </div>
  );
}
