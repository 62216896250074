import React, { useState, useEffect } from "react";
import styled from "styled-components";

const InvoiceCard = styled.div`
  margin-bottom: 20px;
`;

function InvoiceSend() {
  return <InvoiceCard />;
}

export default InvoiceSend;
