import React from "react";

class InactiveProjectCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { projectName } = this.props;
    const { projectPath } = this.props;
    return (
      <div className="card light-gray off-black-text project-card project-card-index col l3 m6 s12">
        <a href={`/projects/${projectPath}`}>
          <div className="off-black-text user-card-content">
            <p className="card-user-name truncate">{projectName}</p>
          </div>
        </a>
      </div>
    );
  }
}

export default InactiveProjectCard;
