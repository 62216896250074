import React from "react";
import moment from "moment";
import axios from "axios";
import Select, { components } from "react-select";
import "react-dates/initialize";
import SingleSelectWrapper from "../common/single_select_wrapper";
import SingleSelectStyles from "../common/single_select_styles";
import RoundDropdown from "../projects/round_dropdown.jsx";
import Swal from "sweetalert2";
import AddProjectModalIcon from "../icons/add_project_icon.jsx";
import ExitIcon from "../icons/exit_icon";
import styled from "styled-components";

class EditProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      selectedClient: this.props.data.project.client
        ? {
            label: this.props.data.project.client.name,
            value: this.props.data.project.client.id,
          }
        : {},
      project_name: this.props.data.project.name,
      description: this.props.data.project.description,
      lead_user: this.props.data.project.lead_owner_id
        ? {
            value: this.props.data.project.lead_owner_id,
            label: this.props.data.project.lead_owner_name,
          }
        : {},
      start_date: this.props.data.project.start_date.includes("?")
        ? undefined
        : moment(this.props.data.project.start_date),
      end_date: this.props.data.project.end_date.includes("?")
        ? undefined
        : moment(this.props.data.project.end_date_string),
      start_picker_focus: false,
      deleteable: false,
      end_picker_focus: false,
      focusCounter: false,
      parentProject: this.props.data.project.parent_project
        ? this.props.data.project.parent_project
        : false,
      tag: this.props.data.project.tag
        ? {
            label: this.props.data.project.tag.name,
            value: this.props.data.project.tag.id,
            color: this.props.data.project.tag.color,
          }
        : null,
      cardColor: this.props.data.project.card_color,
      bugTrackerTp: this.props.data.project.bug_tracker_tp,
      estimateUrl:
        this.props.data.project.project_links.length > 0
          ? this.props.data.project.project_links[0].location
          : "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleParentChange = this.handleParentChange.bind(this);
    this.handleLeadChange = this.handleLeadChange.bind(this);
    this.handleClientChange = this.handleClientChange.bind(this);
    this.getSelectTheme = this.getSelectTheme.bind(this);
    this.handleStartFocus = this.handleStartFocus.bind(this);
    this.handleEndFocus = this.handleEndFocus.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.submitButton = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteProject = this.deleteProject.bind(this);
    this.archiveProject = this.archiveProject.bind(this);
    this.setProjectStatus = this.setProjectStatus.bind(this);

    this.colors = [
      "#115b7f",
      "#1b92cc",
      "#3f3d71",
      "#78479c",
      "#f06d34",
      "#e58536",
      "#d9a74a",
      "#ddd25d",
      "#181818",
      "#3d3d3d",
    ];

    this.labelInput = (props) => <components.Input {...props} maxLength={20} />;
    this.roundDrop = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <RoundDropdown />
        </components.DropdownIndicator>
      );
    };
    this.CustomValueContainer = ({ children, ...props }) => {
      return (
        <components.ValueContainer {...props}>
          <div
            style={
              props.selectProps.inputValue && props.selectProps.inputValue
                ? {
                    position: "absolute",
                    width: "200px",
                    top: "-37px",
                    left: "51px",
                  }
                : {
                    position: "absolute",
                    width: "200px",
                    top: "-11px",
                    left: "20px",
                  }
            }
          >
            {props.selectProps.inputValue &&
            props.selectProps.inputValue != "" ? (
              <p>{props.selectProps.inputValue}</p>
            ) : (
              <components.Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
              </components.Placeholder>
            )}
          </div>
          {React.Children.map(children, (child) =>
            child && child.type !== components.Placeholder ? child : null
          )}
        </components.ValueContainer>
      );
    };
    this.DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <i
            style={{ color: "inherit" }}
            className="material-icons base-color-hover"
          >
            search
          </i>
        </components.DropdownIndicator>
      );
    };
  }

  setProjectStatus() {
    this.setState({ project_status: !this.state.project_status });
  }

  handleTagSelectChange = (selectedOption, e) => {
    this.setState({
      tag: selectedOption ? selectedOption : [],
    });
  };

  handleLeadChange(e) {
    this.setState({
      lead_user: e ? e : [],
    });
  }

  handleParentChange(e) {
    this.setState({ parentProject: e.target.checked });
  }

  handleClientChange(e) {
    this.setState({
      selectedClient: e ? e : [],
    });
  }

  getSelectTheme(theme) {
    return {
      ...theme,
      colors: { ...theme.colors, primary: "#2bbbad", neutral0: "#fafafa" },
    };
  }

  handleStartFocus(focused) {
    if (
      this.state.start_picker_focus &&
      focused.focused &&
      this.state.focusCounter === false
    ) {
      this.setState({ start_picker_focus: true, focusCounter: true });
    } else if (
      this.state.start_picker_focus &&
      focused.focused &&
      this.state.focusCounter
    ) {
      this.setState({ start_picker_focus: false, focusCounter: false });
    } else {
      this.setState({
        start_picker_focus: focused.focused,
        focusCounter: false,
      });
    }
  }

  handleEndFocus(focused) {
    // idk why but on focus happens twice on open, the focusCounter gets around this
    if (
      this.state.end_picker_focus &&
      focused.focused &&
      this.state.focusCounter === false
    ) {
      this.setState({ end_picker_focus: true, focusCounter: true });
    } else if (
      this.state.end_picker_focus &&
      focused.focused &&
      this.state.focusCounter
    ) {
      this.setState({ end_picker_focus: false, focusCounter: false });
    } else {
      this.setState({ end_picker_focus: focused.focused, focusCounter: false });
    }
  }

  handleStartDateChange(date) {
    this.setState({ start_date: date });
  }

  handleEndDateChange(date) {
    this.setState({ end_date: date });
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    setTimeout(() => {
      var elem = document.querySelector("#edit-project-modal h4");
      if (elem) {
        elem.focus();
      }
    }, 100);
  }

  componentWillUnmount() {
    var elem = document.querySelector(
      "#projects-container .common-button-cancel"
    );
    if (elem) {
      elem.focus();
    }
  }

  closeModal = () => {
    this.props.modalAction(false, "", {});
  };

  handleSubmit(e) {
    e.preventDefault();
    if (this.submitButton && this.submitButton.current) {
      this.submitButton.current.disabled = true;
    }
    var errors = [];
    if (this.state.project_name === "") {
      errors.push("a project name");
    }
    if (!this.props.data.clientId && !this.state.selectedClient) {
      errors.push("a client");
    }

    if (errors.length >= 1) {
      M.toast({
        html: `Please select ${errors
          .join(", ")
          .replace(/, ([^,]*)$/, " and $1")}`,
        displayLength: 3000,
        classes: "red",
      });
      return;
    }
    var name = this.state.project_name;
    var project = {
      name: name,
      parent_project: this.state.parentProject,
      description: this.state.description,
      client_id: this.state.selectedClient.value,
      tag_id: this.state.tag.value,
      bug_tracker_tp: this.state.bugTrackerTp,
      // card_color:this.state.cardColor
    };
    if (this.state.estimateUrl) {
      if (this.props.data.project.project_links.length > 0) {
        var estimate_url = {
          id: this.props.data.project.project_links[0].id,
          location: this.state.estimateUrl,
          name: this.state.estimateUrl,
        };
      } else {
        var estimate_url = {
          project_id: this.props.data.project.id,
          user_id: this.props.data.currentUser.id,
          location: this.state.estimateUrl,
          name: this.state.estimateUrl,
          link_type: "estimate",
        };
      }
      project.project_links_attributes = [estimate_url];
    }
    if (this.state.project_status) {
      project.project_status_attributes = { aasm_state: "leading" };
    }
    axios
      .patch(`/projects/${this.props.data.project.id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        project: project,
      })
      .then((res) => {
        M.toast({
          html: `${res.data.name} Updated`,
          displayLength: 3000,
          classes: "green",
        });
        this.props.data.projectHandler("edit", res.data);
        this.props.modalAction(false, "", { closeButton: false });
      })
      .catch((err) => {
        if (this.submitButton && this.submitButton.current) {
          this.submitButton.current.disabled = false;
        }
        M.toast({
          html: "Project already exists, choose a unique name.",
          classes: "red",
        });
        console.error(err);
      });
  }

  handleColorChange = (e) => {
    this.setState({ cardColor: e.hex });
  };

  archiveProject() {
    var text;
    if (this.props.data.project.active) {
      text = `Are you sure that you want to archive this Project?`;
    } else {
      text = `Are you sure that you want to reactivate this Project?`;
    }
    Swal.fire({
      title: `Archive Project`,
      text: text,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        axios
          .patch(`/projects/${this.props.data.project.id}`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            project: {
              active: !this.props.data.project.active,
            },
          })
          .then((res) => {
            M.toast({
              html: `Project Archived`,
              displayLength: 3000,
              classes: "green",
            });
            this.props.modalAction(false, "", {});
            window.location.href = "/projects";
          })
          .catch((err) => console.error(err));
      }
    });
  }

  deleteProject() {
    Swal.fire({
      title: `Delete Project`,
      text: `Are you sure that you want to delete this Project?`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`/api/v1/projects/${this.props.data.project.id}/fur_real`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            M.toast({
              html: `Project Deleted`,
              displayLength: 3000,
              classes: "green",
            });
            this.props.modalAction(false, "", {});
            window.location.href = "/projects";
          })
          .catch((err) => console.error(err));
      }
    });
  }

  handleInputChange(e) {
    var input = e.target.value;
    var name = e.target.name;
    this.setState({ [name]: input });
  }

  isDayBlocked(day) {
    return moment(day).day() === 0 || moment(day).day() === 6 ? true : false;
  }

  handleBugTrackerChange = (e) => {
    this.setState({ bugTrackerTp: e.target.value === "true" });
  };

  render() {
    return (
      <div
        id="edit-project-modal"
        style={{ backgroundColor: "white", borderRadius: "10px" }}
        aria-label="edit project modal"
        role="dialog"
        className="modal-content"
      >
        <div className="flex-container flex-al-center flex-j-start">
          <AddProjectModalIcon />
          <h4
            style={{
              fontSize: "18px",
              fontWeight: "600",
              fontFamily: "Manrope",
              marginLeft: "16px",
            }}
            tabIndex={0}
          >
            Edit Project
          </h4>
          <button
            type="button"
            id="modalClose"
            aria-label="Close Modal"
            className="btn-icon"
            onClick={this.closeModal}
            style={{ position: "absolute", top: "40px", right: "26px" }}
          >
            <ExitIcon
              svgStyles={{ pointerEvents: "none" }}
              height="25px"
              width="25px"
            />
          </button>
        </div>
        <form
          className="new_project"
          id="new_project"
          onSubmit={this.handleSubmit}
        >
          <div
            className=""
            style={{ width: "50%", marginBottom: "16px", marginTop: "32px" }}
          >
            <label
              className="select-labels"
              htmlFor="clients"
              style={{
                fontFamily: "manrope",
                fontSize: "13px",
                fontWeight: "600",
              }}
            >
              Client
            </label>
            <Select
              classNamePrefix="react-select"
              name="clients"
              menuShouldScrollIntoView
              menuPlacement="top"
              blurInputOnSelect={true}
              components={{ DropdownIndicator: this.roundDrop }}
              options={this.props.data.clientOptions}
              onChange={this.handleClientChange}
              theme={this.getSelectTheme}
              styles={{
                ...SingleSelectStyles,
                ...{
                  container: (styles) => ({
                    ...styles,
                    width: "360px",
                    marginTop: "8px",
                  }),
                },
              }}
              value={this.state.selectedClient}
            />
          </div>
          <div style={{ width: "360px" }} className="">
            <label
              htmlFor="project_name"
              style={{
                fontFamily: "manrope",
                fontSize: "13px",
                fontWeight: "600",
              }}
            >
              Project Name<span className="base-color">*</span>
            </label>
            <input
              required="required"
              type="text"
              name="project_name"
              id="project_name"
              maxLength="50"
              className="browser-default text-input-v2"
              onChange={this.handleInputChange}
              value={this.state.project_name}
              style={{ marginTop: "8px", marginBottom: "8px" }}
              aria-describedby="character-count"
            />

            <p
              id="character-count"
              aria-live="polite"
              className="input-counter"
              style={{ marginBottom: "16px" }}
            >{`${this.state.project_name.length}/50`}</p>
          </div>
          <div style={{ marginTop: "0px" }} className="flex-container">
            <div className="">
              <label
                className="select-labels"
                htmlFor="filter_filters"
                style={{
                  fontFamily: "manrope",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                Project Type
              </label>
              <SingleSelectWrapper
                options={this.props.data.tagOptions}
                onChange={this.handleTagSelectChange}
                placeholder={"Select Type"}
                value={this.state.tag}
                menuPlacement="top"
                ariaLabel="Project type dropdown"
                customStyles={{
                  container: (styles) => ({
                    ...styles,
                    width: "179px",
                    marginTop: "8px",
                    marginBottom: "16px",
                  }),
                }}
              />
            </div>
          </div>
          {this.props.data.project.project_status === null && (
            <div style={{ margin: "0px 0px 16px" }} className="flex-container">
              <div className="">
                <label className="task-label" htmlFor="projectTb">
                  <input
                    checked={this.state.project_status}
                    type="checkbox"
                    name="project"
                    id="projectTb"
                    value={this.state.project_status}
                    onClick={this.setProjectStatus}
                  />
                  <span
                    style={{
                      lineHeight: "26px",
                      paddingLeft: "26px",
                      fontFamily: "Manrope",
                      fontSize: "13px",
                      fontWeight: "600",
                    }}
                  >
                    Add Project To Taskboard?
                  </span>
                </label>
              </div>
            </div>
          )}
          <div
            className="recurring-label-container"
            style={{ marginRight: "29px", position: "relative" }}
          >
            <p
              style={{
                marginBottom: "8px",
                fontFamily: "Manrope",
                fontSize: "13px",
                fontWeight: "600",
                marginTop: "0px",
              }}
            >
              Bug Tracker<span className="base-color">*</span>
            </p>
            <label
              style={{ marginRight: "10px", marginLeft: "-4px" }}
              htmlFor="recurring-bi-weekly-edit"
            >
              <input
                onChange={this.handleBugTrackerChange}
                className="with-gap"
                type="radio"
                name="recurring-edit"
                id="recurring-bi-weekly-edit"
                value={"true"}
                checked={this.state.bugTrackerTp}
              />
              <span
                style={{
                  fontFamily: "Arial",
                  fontSize: "13px",
                  fontWeight: "400",
                }}
              >
                Test Platform
              </span>
            </label>
            <label htmlFor="recurring-yes-edit">
              <input
                onChange={this.handleBugTrackerChange}
                className="with-gap"
                type="radio"
                name="recurring-edit"
                id="recurring-yes-edit"
                value={"false"}
                checked={this.state.bugTrackerTp === false}
              />
              <span
                style={{
                  fontFamily: "Arial",
                  fontSize: "13px !important",
                  fontWeight: "400",
                }}
              >
                Client Provided
              </span>
            </label>
          </div>
          {this.state.bugTrackerTp && (
            <a
              target="_blank"
              href="https://testplatform.plusqa.com/admin_panel?new_project=create"
              style={{
                marginTop: "8px",
                marginBottom: "16px",
                display: "block",
              }}
              className="btn-flat"
            >
              Click here to create Test Platform project
            </a>
          )}
          {this.props.data.project.project_status !== null && (
            <div
              style={{
                margin: `${this.state.bugTrackerTp ? "0px" : "16px"} 0px 0 0`,
                width: "100%",
              }}
            >
              <label
                htmlFor="estimateUrl"
                style={{
                  fontFamily: "Manrope",
                  fontSize: "13px !important",
                  fontWeight: "600",
                }}
              >
                Link to Estimate
              </label>
              <input
                className="browser-default text-input-v2"
                name="estimateUrl"
                type="text"
                value={this.state.estimateUrl}
                placeholder="Please provide a URL for the estimate"
                style={{ margin: "0" }}
                onChange={this.handleInputChange}
              />
            </div>
          )}
          <div style={{ marginTop: "30px" }} className="button-row right-align">
            {this.props.data.currentUser &&
              this.props.data.currentUser.role_id >= 4 && (
                <>
                  {this.props.data.project.deletable && (
                    <button
                      type="button"
                      name="delete"
                      onClick={this.deleteProject}
                      className="common-button-delete"
                    >
                      Delete
                    </button>
                  )}
                  {this.props.data.project.active && (
                    <button
                      type="button"
                      name="archive"
                      onClick={this.archiveProject}
                      className="common-button-delete"
                    >
                      {" "}
                      Archive
                    </button>
                  )}
                </>
              )}
            <button
              type="button"
              name="cancel"
              onClick={this.closeModal}
              style={!this.props.data.project.active ? { width: "90px" } : {}}
              className="common-button-cancel"
            >
              Cancel
            </button>
            {!this.props.data.project.active && (
              <button
                type="button"
                name="archive"
                onClick={this.archiveProject}
                className="common-button-submit"
              >
                Reactivate
              </button>
            )}
            {this.props.data.project.active && (
              <input
                disabled={
                  this.state.tag === null || this.state.bugTrackerTp === null
                }
                type="submit"
                name="commit"
                value="Update Project"
                className="common-button-submit"
                ref={this.submitButton}
              />
            )}
          </div>
        </form>
      </div>
    );
  }
}

const ArchiveButton = styled.button`
  display: flex;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: var(--Project-Type-A11y-Audit, #b82a23);
  box-shadow: 0px 3px 0px 0px #85201b;
`;
export default EditProject;
