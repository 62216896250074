import React from "react";

const ClockSolidIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <g clipPath="url(#clip0_3278_578)">
        <mask
          id="mask0_3278_578"
          // style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="26"
          height="26"
        >
          <path
            d="M13 23.6373C18.875 23.6373 23.6373 18.875 23.6373 13C23.6373 7.12509 18.875 2.36279 13 2.36279C7.12509 2.36279 2.36279 7.12509 2.36279 13C2.36279 18.875 7.12509 23.6373 13 23.6373Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.81543 13C0.81543 6.27057 6.27045 0.815552 12.9999 0.815552C19.7294 0.815552 25.1844 6.27057 25.1844 13C25.1844 19.7295 19.7294 25.1845 12.9999 25.1845C6.27045 25.1845 0.81543 19.7295 0.81543 13ZM12.9999 3.91003C7.97948 3.91003 3.9099 7.9796 3.9099 13C3.9099 18.0205 7.97948 22.0901 12.9999 22.0901C18.0204 22.0901 22.0899 18.0205 22.0899 13C22.0899 7.9796 18.0204 3.91003 12.9999 3.91003Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.0043 5.07043C13.8588 5.07043 14.5515 5.76316 14.5515 6.61767V12.3645L18.6081 16.4215C19.2123 17.0258 19.2122 18.0055 18.6079 18.6097C18.0037 19.2139 17.024 19.2138 16.4198 18.6095L11.9101 14.0993C11.62 13.8092 11.457 13.4157 11.457 13.0053V6.61767C11.457 5.76316 12.1498 5.07043 13.0043 5.07043Z"
            fill="black"
          />
        </mask>
        <g mask="url(#mask0_3278_578)">
          <path
            d="M0.235352 0.235352H25.7648V25.7648H0.235352V0.235352Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3278_578">
          <rect
            width="25.5294"
            height="25.5294"
            fill="white"
            transform="translate(0.235352 0.235352)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ClockSolidIcon;
