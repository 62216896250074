import React from "react";
import moment from "moment";
import ReactDOM from "react-dom";
import { Button, Modal } from "react-materialize";
import axios from "axios";
import GridFormModal from "../gridModal/GridFormModal";

class BookedRoom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matched: false,
      booking_id: this.props.current_booking.id,
    };
    this.deleteBooking = this.deleteBooking.bind(this);
    this.openModal = this.openModal.bind(this);
    this.valueArray = {
      0: "08:00:00",
      1: "08:15:00",
      2: "08:30:00",
      3: "08:45:00",
      4: "09:00:00",
      5: "09:15:00",
      6: "09:30:00",
      7: "09:45:00",
      8: "10:00:00",
      9: "10:15:00",
      10: "10:30:00",
      11: "10:45:00",
      12: "11:00:00",
      13: "11:15:00",
      14: "11:30:00",
      15: "11:45:00",
      16: "12:00:00",
      17: "12:15:00",
      18: "12:30:00",
      19: "12:45:00",
      20: "13:00:00",
      21: "13:15:00",
      22: "13:30:00",
      23: "13:45:00",
      24: "14:00:00",
      25: "14:15:00",
      26: "14:30:00",
      27: "14:45:00",
      28: "15:00:00",
      29: "15:15:00",
      30: "15:30:00",
      31: "15:45:00",
      32: "16:00:00",
      33: "16:15:00",
      34: "16:30:00",
      35: "16:45:00",
      36: "17:00:00",
      37: "17:15:00",
      38: "17:30:00",
      39: "17:45:00",
      40: "18:00:00",
    };
  }

  componentDidMount() {
    if (this.props.current_booking.user.id === this.props.currentUser.id)
      this.setState({ matched: true });
  }

  deleteBooking() {
    axios.delete(`/bookings/${this.state.booking_id}.json`);
    this.props.deleteHandler(this.state.booking_id);
  }

  closeModal() {
    document.body.style.overflow = "";
    ReactDOM.unmountComponentAtNode(document.getElementById("modalContainer"));
  }

  openModal() {
    this.props.setModal(true, "meeting", this.props.data);
    this.closeModal();
    // var start = this.props.current_booking.booking_array.findIndex(function (val) {
    //   return val[0] > 0 || val[1] > 0;
    // });
    // var endTimeValue;
    // for(var i = start; i <= this.props.current_booking.booking_array.length; i++){
    //       var indexValue = this.props.current_booking.booking_array[i]
    //       if(indexValue===0){ break }
    //         endTimeValue = parseInt(i) + 1
    // }

    //  ReactDOM.render(
    //       <Modal
    //         actions={[]}
    //         className={"gridModal open"}
    //         header={this.props.room.name}
    //         open={true}
    //         options={{endingTop: "5%", onCloseEnd: this.closeModal }}
    //         >
    //           <GridFormModal
    //           action={"edit"}
    //           roomCapacity={this.props.roomCapacity}
    //           deleteHandler={this.props.deleteHandler}
    //           editHandler={this.props.editHandler}
    //           currentUser={this.props.currentUser}
    //           room={this.props.room}
    //           currentDay={this.props.currentDay}
    //           bookingId={this.props.current_booking.id}
    //           bookings={this.props.bookings}
    //           roomName={this.props.room.name}
    //           booking={this.props.current_booking}
    //           startTimeValue={this.valueArray[start]}
    //           endTimeValue={this.valueArray[endTimeValue]}
    //           />
    //         </Modal>,
    //       document.getElementById('modalContainer'),
    //     )
  }

  render() {
    const object = this.props;
    var deleteButton;
    if (object.action === "edit") {
      var deleteButton = (
        <Button className="danger" onClick={this.deleteBooking}>
          {" "}
          Delete
        </Button>
      );
    }
    const goodtime = moment(this.props.current_booking.booking_date).format(
      "MMMM Do YYYY"
    );
    const { matched } = this.state;
    let udButtons;
    let trigger;
    return (
      <div>
        <h5 className="modal-time">{goodtime}</h5>
        <h5 className="modal-time">
          Meeting Owner: {this.props.current_booking.user.name}
        </h5>
        <h1 className="current-booking-header">Current Booking: </h1>
        <div className="findMeLayout">
          <div className={`room-picture ${this.props.room.room_id}`} />
          <div>
            <h1 className="result-title">{this.props.current_booking.name}</h1>
            <p>
              Description:
              {this.props.current_booking.description}
            </p>
            <p>
              Duration:
              {this.props.current_booking.duration} minutes
            </p>
            {this.props.currentUser.id ===
            this.props.current_booking.user.id ? (
              <div>
                <Button className="editButton" onClick={this.openModal}>
                  EDIT
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default BookedRoom;
