import React, { useState, useEffect } from "react";
import moment from "moment";
import ProjectTimelineStepOne from "../icons/project_timeline_step_one";
import ProjectTimelineStepTwo from "../icons/project_timeline_step_two";
import ProjectTimelineStepThree from "../icons/project_timeline_step_three";
import ProjectTimelineStepFour from "../icons/project_timeline_step_four";
import StageGrayIcon from "components/icons/stage_gray_icon";
import styled from "styled-components";
import UpdatedCheckmarkIcon from "components/icons/updated_checkmark_icon";

const stages = {
  leading: 0,
  est_requesting: 1,
  est_sending: 2,
  project_confirming: 3,
  testing: 4,
  completing: 5,
  readying: 6,
  invoicing: 7,
};

function ProjectDetailTimeline(props) {
  const stage = stages[props.project.project_status.aasm_state];
  return (
    <div
      className="project-detail-timeline"
      style={{ width: props.fullWidth ? "100%" : "540px" }}
    >
      <div className="status-bar">
        <div
          className="status-bar-section"
          style={{
            width: "8%",
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
            backgroundColor: "#519acc",
          }}
        />
        <div
          className="status-bar-section"
          style={{
            width: "23%",
            backgroundColor: stage > 1 ? "#519acc" : "#d7d7d7",
          }}
        >
          <div className="" style={{ backgroundColor: "#d7d7d7" }}>
            <ProjectTimelineStepOne
              color={"#519acc"}
              svgStyles={{
                position: "absolute",
                width: "30px",
                top: "-50px",
                height: "auto",
                left: "-8px",
              }}
            />
            <StageGrayIconHolder>
              <UpdatedCheckmarkIcon
                svgStyles={{
                  position: "absolute",
                  width: "25px",
                  left: "-6px",
                  top: "-6px",
                }}
              />
            </StageGrayIconHolder>
            <p className="status-bar-text">
              Project Created: <br />{" "}
              {moment(props.project.created_at).format("MM/DD/YYYY")}
            </p>
          </div>
        </div>
        <div
          className="status-bar-section"
          style={{
            width: "23%",
            backgroundColor: stage >= 2 ? "#519acc" : "#d7d7d7",
          }}
        >
          <div className="" style={{ backgroundColor: "#d7d7d7" }}>
            <ProjectTimelineStepTwo
              color={stage >= 2 ? "#519acc" : "#d7d7d7"}
              svgStyles={{
                position: "absolute",
                top: "-48px",
                left: "-12px",
              }}
            />
            {stage >= 2 ? (
              <StageGrayIconHolder>
                <UpdatedCheckmarkIcon
                  svgStyles={{
                    position: "absolute",
                    width: "25px",
                    left: "-6px",
                    top: "-6px",
                  }}
                />
              </StageGrayIconHolder>
            ) : (
              <StageGrayIconHolder>
                <StageGrayIcon />
              </StageGrayIconHolder>
            )}
            <p
              className="status-bar-text"
              style={{
                backgroundColor:
                  props.project.est_send_date && stage >= 2
                    ? "#578BC2"
                    : "#d7d7d7",
                color:
                  props.project.est_send_date && stage >= 2 ? "white" : "black",
              }}
            >
              Estimate Sent: <br />{" "}
              {props.project.est_send_date && stage >= 2
                ? moment(props.project.est_send_date).format("MM/DD/YYYY")
                : "Incomplete"}
            </p>
          </div>
        </div>
        <div
          className="status-bar-section"
          style={{
            width: "23%",
            backgroundColor: stage >= 4 ? "#519acc" : "#d7d7d7",
          }}
        >
          <div className="" style={{ backgroundColor: "#d7d7d7" }}>
            <ProjectTimelineStepThree
              color={stage >= 4 ? "#519acc" : "#d7d7d7"}
              svgStyles={{
                position: "absolute",
                width: "35px",
                top: "-52px",
                height: "auto",
                left: "-11px",
              }}
            />
            {stage >= 4 ? (
              <StageGrayIconHolder>
                <UpdatedCheckmarkIcon
                  svgStyles={{
                    position: "absolute",
                    width: "25px",
                    left: "-6px",
                    top: "-6px",
                  }}
                />
              </StageGrayIconHolder>
            ) : (
              <StageGrayIconHolder>
                <StageGrayIcon />
              </StageGrayIconHolder>
            )}
            <p
              className="status-bar-text"
              style={{
                backgroundColor: stage >= 4 ? "#578BC2" : "#D7d7d7",
                color: stage >= 3 ? "white" : "black",
              }}
            >
              Test Start: <br />{" "}
              {stage >= 4 ? props.project.test_start_date : "Incomplete"}
            </p>
          </div>
        </div>
        <div
          className="status-bar-section"
          style={{
            width: "25%",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
            lineHeight: "10px",
            backgroundColor: stage >= 7 ? "#519acc" : "#d7d7d7",
          }}
        >
          <div className="" style={{ backgroundColor: "#d7d7d7" }}>
            <ProjectTimelineStepFour
              color={stage >= 5 ? "#519acc" : "#d7d7d7"}
              svgStyles={{
                position: "absolute",
                width: "35px",
                top: "-51px",
                height: "auto",
                left: "-11px",
              }}
            />
            {stage >= 5 ? (
              <StageGrayIconHolder>
                <UpdatedCheckmarkIcon
                  svgStyles={{
                    position: "absolute",
                    width: "25px",
                    left: "-6px",
                    top: "-6px",
                  }}
                />
              </StageGrayIconHolder>
            ) : (
              <StageGrayIconHolder>
                <StageGrayIcon />
              </StageGrayIconHolder>
            )}
            <p
              className="status-bar-text"
              style={{
                backgroundColor: stage >= 5 ? "#578BC2" : "#D7d7d7",
                color: stage >= 5 ? "white" : "black",
              }}
            >
              <span style={{ whiteSpace: "nowrap" }}>Project Complete:</span>{" "}
              <br />
              {stage >= 5
                ? moment(props.project.completion_date).format("MM/DD/YYYY")
                : "Incomplete"}
            </p>
          </div>
          <span
            style={{
              fontSize: "10px",
              color: stage >= 7 ? "#fff" : " #C0C0C0",
              fontFamily: "manrope",
              fontWeight: "800",
              lineHeight: "15px",
              paddingLeft: "10px",
            }}
          >
            INVOICED
          </span>
        </div>
      </div>
    </div>
  );
}

const StageGrayIconHolder = styled.span`
  position: absolute;
  left: -11px;
  top: -9px;
`;
export default ProjectDetailTimeline;
