import React from "react";
import styled from "styled-components";

class TimeBar extends React.Component {
  constructor(props) {
    super(props);
  }

  // <TimeRow cardDiv={this.props.cardDiv} objectName="project" project={p} clientPath={p.id} key={i} />
  getTitle() {
    switch (this.props.objectName) {
      case "project":
        return this.props.project.name;
    }
  }

  barMouseEnter = (e) => {
    var rect = e.target.getBoundingClientRect();
    var popUpElem = document.createElement("div");
    var triElem = document.createElement("div");
    var borderElem = document.createElement("div");
    popUpElem.setAttribute("id", `${e.target.dataset.id}-pop-up`);
    popUpElem.setAttribute("class", `time-bar-tt`);
    popUpElem.style.top = `${rect.top - 35}px`;
    popUpElem.innerHTML = `${e.target.dataset.text}`;
    popUpElem.style.color = `black`;
    popUpElem.style.fontFamily = `Arial`;
    popUpElem.style.fontSize = `13px`;
    popUpElem.style.borderColor = "#519acc";
    var element = document.querySelector("body");
    popUpElem.append(triElem);
    popUpElem.append(borderElem);
    element.appendChild(popUpElem);
    //need to set left after element is appended to parent element
    popUpElem.style.left = `${rect.left + (rect.width / 2 - 30)}px`;
  };

  barMouseLeave = (e) => {
    var popUpElem = document.getElementById(`${e.target.dataset.id}-pop-up`);
    var element = document.querySelector("body");
    if (popUpElem) {
      element.removeChild(popUpElem);
    }
  };

  render() {
    if (this.props.hide) {
      return (
        <div className="time-bar flex-container flex-al-center">
          <p
            className={"hours"}
            style={{
              width: "82px",
              textAlign: "right",
            }}
            aria-label={`Billable hours ${parseFloat(
              this.props.billable + this.props.nonBillable
            ).toFixed(2)}`}
          >
            {parseFloat(this.props.billable + this.props.nonBillable).toFixed(
              2
            )}
          </p>
        </div>
      );
    } else if (this.props.single) {
      return (
        <div className="time-bar flex-container flex-al-center">
          <p
            className={"hours"}
            style={{ padding: "10px" }}
            aria-label={`Billable hours ${
              this.props.total
                ? parseFloat(this.props.total).toFixed(2)
                : `0.00`
            }`}
          >
            {this.props.total
              ? parseFloat(this.props.total).toFixed(2)
              : `0.00`}
          </p>
          <div
            style={{
              display: "flex",
              transition: "flex .5s",
              backgroundColor: "transparent",
              minWidth: "275px",
              borderRadius: "4px",
              height: "12px",
              border: "solid 1px gray",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                backgroundColor: `${this.props.color}`,
                transition: "flex .5s",
                flex: `1`,
                height: "100%",
                position: "relative",
                display: "inline-flex",
                justifyContent: "center",
                verticalAlign: "top",
              }}
            ></div>
          </div>
        </div>
      );
    } else if (this.props.billableBar) {
      return (
        <div
          style={{
            marginRight: `${this.props.nested ? "-5px" : "0"}`,
            paddingRight: `${this.props.nested ? "0" : "15px"}`,
          }}
          className="time-bar flex-container flex-al-center"
        >
          <p
            className={"hours"}
            style={{
              width: "50px",
              textAlign: "right",
              marginRight: "10px",
            }}
            aria-label={`Billable hours ${parseFloat(this.props.total).toFixed(
              2
            )}, `}
          >
            {parseFloat(this.props.total).toFixed(2)}
          </p>
          <div
            style={{
              display: "flex",
              transition: "flex .5s",
              backgroundColor: "transparent",
              minWidth: "250px",
              borderRadius: "4px",
              height: "12px",
              border: "solid 1px gray",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            <div
              onMouseEnter={this.barMouseEnter}
              onMouseLeave={this.barMouseLeave}
              data-id={this.props.total}
              data-text={`${
                parseFloat(this.props.billable / this.props.total).toFixed(2) *
                100
              }% Billable`}
              style={{
                backgroundColor: "#519acc",
                transition: "flex .5s",
                flex: `${this.props.billable / this.props.total}`,
                height: "100%",
                position: "relative",
                display: "inline-flex",
                zIndex: 100,
                justifyContent: "center",
                verticalAlign: "top",
              }}
            ></div>
            <div
              onMouseEnter={this.barMouseEnter}
              onMouseLeave={this.barMouseLeave}
              data-id={this.props.total}
              data-color="white"
              data-text={`${
                parseFloat(this.props.nonBillable / this.props.total).toFixed(
                  2
                ) * 100
              }% Non-billable`}
              style={{
                backgroundColor: "white",
                transition: "flex .5s",
                flex: `${this.props.nonBillable / this.props.total}`,
                height: "100%",
                position: "relative",
                zIndex: 100,
                display: "inline-flex",
                justifyContent: "center",
                verticalAlign: "top",
              }}
            ></div>
          </div>
          <p
            className={
              this.props.objectName === "user" ||
              this.props.objectName === "task"
                ? "billable"
                : "budget"
            }
            aria-label={`${`${Math.round(
              parseFloat(this.props.billable / this.props.total).toFixed(2) *
                100
            )}%`} billable`}
            style={{ padding: "10px", width: "50px" }}
          >
            {`${Math.round(
              parseFloat(this.props.billable / this.props.total).toFixed(2) *
                100
            )}%`}
          </p>
        </div>
      );
    } else {
      return (
        <div className="time-bar flex-container flex-al-center">
          <p
            className={"hours"}
            style={{
              marginRight: "10px",
              width: "0px",
              textAlign: "right",
            }}
          >
            {this.props.subtotal
              ? parseFloat(this.props.subtotal).toFixed(2)
              : "0.00"}
          </p>
          <div
            style={{
              display: "flex",
              transition: "flex .5s",
              backgroundColor: "transparent",
              minWidth: "275px",
              borderRadius: "4px",
              height: "12px",
              border: "solid 1px gray",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            <div
              onMouseEnter={this.barMouseEnter}
              onMouseLeave={this.barMouseLeave}
              data-id={`${isNaN(this.props.total) ? "0" : this.props.total}`}
              data-text={`${
                this.props.subtotal / this.props.total === Infinity
                  ? "100"
                  : `${
                      parseFloat(
                        this.props.subtotal / this.props.total
                      ).toFixed(2) * 100
                    }`
              }% Billable`}
              style={{
                backgroundColor: "#519acc",
                transition: "flex .5s",
                flex: `${
                  this.props.subtotal / this.props.total === Infinity
                    ? 0
                    : isNaN(this.props.subtotal / this.props.total)
                    ? 0
                    : this.props.subtotal / this.props.total
                }`,
                height: "100%",
                position: "relative",
                zIndex: 100,
                display: "inline-flex",
                justifyContent: "center",
                verticalAlign: "top",
              }}
            ></div>
            <div
              onMouseEnter={this.barMouseEnter}
              onMouseLeave={this.barMouseLeave}
              data-color="#519acc"
              data-id={`${isNaN(this.props.total) ? "0" : this.props.total}`}
              data-text={`${
                this.props.subtotal / this.props.total <= 1
                  ? `${
                      Math.abs(this.props.subtotal / this.props.total - 1) * 100
                    }% Left`
                  : isNaN(this.props.subtotal / this.props.total)
                  ? "100% Non-billable"
                  : 0
              }`}
              style={{
                backgroundColor: "#d1d1d1",
                transition: "flex .5s",
                flex: `${
                  this.props.subtotal / this.props.total <= 1
                    ? Math.abs(this.props.subtotal / this.props.total - 1)
                    : isNaN(this.props.subtotal / this.props.total)
                    ? 1
                    : 0
                }`,
                height: "100%",
                position: "relative",
                zIndex: 100,
                display: "inline-flex",
                justifyContent: "center",
                verticalAlign: "top",
              }}
            ></div>
            <div
              onMouseEnter={this.barMouseEnter}
              onMouseLeave={this.barMouseLeave}
              data-id={`${isNaN(this.props.total) ? "0" : this.props.total}`}
              data-text={`${
                this.props.subtotal / this.props.total > 1 &&
                this.props.subtotal / this.props.total < Infinity
                  ? (this.props.subtotal / this.props.total) % 1
                  : 0
              }`}
              style={{
                backgroundColor: "red",
                transition: "flex .5s",
                zIndex: 100,
                flex: `${
                  this.props.subtotal / this.props.total > 1 &&
                  this.props.subtotal / this.props.total < Infinity
                    ? (this.props.subtotal / this.props.total) % 1
                    : this.props.subtotal / this.props.total === Infinity
                    ? 1
                    : 0
                }`,
                height: "100%",
                position: "relative",
                display: "inline-flex",
                justifyContent: "center",
                verticalAlign: "top",
              }}
            ></div>
          </div>
          <p
            className={
              this.props.objectName === "user" ||
              this.props.objectName === "task"
                ? "billable"
                : "budget"
            }
            style={{ padding: "10px", width: "60px" }}
          >{`${
            isNaN(this.props.total)
              ? "0"
              : parseFloat(this.props.total).toFixed(2)
          }`}</p>
        </div>
      );
    }
  }
}

export default TimeBar;
