import React from "react";
import moment from "moment";
import Select from "react-select";
import Swal from "sweetalert2";
import ClockIcon from "../icons/clock_icon";
import FolderIcon from "../icons/folder_icon";
import SingleSelectStyles from "../common/single_select_styles";

class InvoiceRow extends React.Component {
  static formatNumber(num) {
    return num.length > 6 ? num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : num;
  }

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      notes: this.props.invoice.notes,
    };
    this.updateRowStatus = this.updateRowStatus.bind(this);
    this.handleNotesBlur = this.handleNotesBlur.bind(this);
    this.floatyRef = React.createRef();
    this.showFloaty = this.showFloaty.bind(this);
    this.hideFloaty = this.hideFloaty.bind(this);
    this.expand = this.expand.bind(this);
    this.rowRef = React.createRef();
  }

  hideFloaty(e) {
    if (this.floatyRef.current) {
      this.floatyRef.current.style.opacity = "0";
    }
  }

  expand() {
    if (!this.expanding) {
      this.expanding = true;
      if (!this.state.expanded) {
        this.setState({ expanded: true }, () => {
          this.rowRef.current.style.overflow = "hidden";
          this.rowRef.current.style.height = `${this.rowRef.current.scrollHeight}px`;
          setTimeout(() => {
            this.expanding = false;
          }, 300);
        });
      } else {
        this.rowRef.current.style.height = `auto`;
        setTimeout(() => {
          this.setState({ expanded: false });
          this.expanding = false;
          this.rowRef.current.style.overflow = "visible";
        }, 300);
      }
    }
  }

  handleNotesBlur(e) {
    const notes = e.target.value;
    this.props.handleInvoiceNotesUpdate(notes, this.props.invoice.id);
    this.setState({ notes });
  }

  showFloaty(e) {
    const { percent, name } = e.target.dataset;
    if (this.floatyRef.current) {
      const textWidth =
        this.props.canvas.measureText(
          `${name} - ${Math.round(parseInt(percent, 10))}%`
        ).width + 25;
      const left =
        e.target.offsetLeft + e.target.clientWidth / 2 - textWidth / 2;
      // var floatyX = this.floatyRef.current.getBoundingClientRect()["x"]
      this.floatyRef.current.innerText = `${name} - ${Math.round(
        parseInt(percent, 10)
      )}%`;
      this.floatyRef.current.style.opacity = "1";
      this.floatyRef.current.style.left = `${left}px`;
    }
  }

  updateRowStatus(status) {
    if (this.state.expanded) {
      this.expand();
    }
    const { invoice } = this.props;
    if (status.value === 2 && invoice.invoice_status === 1) {
      if (invoice.inv_number) {
        Swal.fire({
          title: "Update Invoice Number",
          text: `This Invoice already has an invoice number(${invoice.inv_number}). Would you like to update it?`,
          showCancelButton: true,
          confirmButtonAriaLabel: "Yes",
          cancelButtonAriaLabel: "no",
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            this.props.openConfirmInvoiceModal(
              invoice,
              (invoice.rate / 100) * invoice.tracked_hours
            );
          } else {
            this.props.updateRowStatus(
              status.value,
              invoice.invoice_status,
              invoice.id
            );
          }
        });
      } else {
        this.props.openConfirmInvoiceModal(
          invoice,
          (invoice.rate / 100) * invoice.tracked_hours
        );
      }
    } else if (status.value !== invoice.invoice_status) {
      this.props.updateRowStatus(
        status.value,
        invoice.invoice_status,
        invoice.id
      );
    }
  }

  render() {
    const i = this.props.invoice;
    const selectedOption = this.props.statusOptions.find(
      (o) => o.value === i.invoice_status
    );
    return (
      <div ref={this.rowRef} className="invoice-row-container">
        <div className="invoice-row">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              aria-hidden="true"
              style={{
                backgroundColor: "white",
                overflow: "hidden",
                height: "46px",
                padding: "0px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0px 15px 0px 10px",
              }}
            >
              <FolderIcon height={46} width={46} color={i.project_color} />
            </div>
            <p>
              <a
                target="_blank"
                href={`/time_tracking/clients/${i.client_id}`}
                style={{ color: "#519acc" }}
                rel="noreferrer"
              >
                {i.client_name}
              </a>{" "}
              -{" "}
              <a
                target="_blank"
                href={`/time_tracking/projects/${i.project_id}`}
                style={{ color: "#519acc" }}
                rel="noreferrer"
              >
                {i.project_name}
              </a>
              <br />
              {i.invoice_status === 1 ? (
                <span style={{ whiteSpace: "pre" }}>
                  Generated: {i.created_at}
                </span>
              ) : i.invoice_status === 2 ? (
                <span style={{ whiteSpace: "pre" }}>
                  Submitted: {moment(i.submitted_date).format("M/D/YYYY")} - Due
                  Date:{" "}
                  {moment(i.submitted_date)
                    .add(i.client_interval, "days")
                    .format("M/D/YYYY")}
                </span>
              ) : (
                <span style={{ whiteSpace: "pre" }}>
                  Completed: {moment(i.completed_date).format("M/D/YYYY")}{" "}
                </span>
              )}
              <br />
              <span>
                {i.invoice_status != 1 ? (
                  <span style={{ paddingRight: "7px", whiteSpace: "pre" }}>
                    INV#:{" "}
                    <span style={{ fontWeight: i.inv_number ? "600" : "400" }}>
                      {i.inv_number ? i.inv_number : "None Provided"}{" "}
                    </span>
                  </span>
                ) : (
                  ""
                )}
                {i.invoice_status === 1 || i.po_number ? (
                  <span style={{ paddingRight: "10px", whiteSpace: "pre" }}>
                    {" "}
                    PO#:{" "}
                    <span style={{ fontWeight: i.po_number ? "600" : "400" }}>
                      {i.po_number ? i.po_number : "None Provided"}
                    </span>
                  </span>
                ) : (
                  ""
                )}
                <button
                  type="button"
                  style={{ paddingLeft: "0px" }}
                  onClick={this.expand}
                >
                  {this.state.expanded ? "Hide Details" : "View Details"}
                </button>
              </span>
            </p>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            <p>
              Rate
              <br />
              <span>
                <span>$</span> {i.rate / 100}
              </span>
            </p>
            <p>
              {i.invoice_status === 1 ? "Payment Due" : "Invoiced"}
              <br />
              <span>
                <span>$</span>{" "}
                {this.formatNumber(
                  ((i.rate / 100) * i.tracked_hours).toFixed(2)
                )}
              </span>
            </p>
            <p>
              Tracked Hours
              <br />
              <ClockIcon
                height={20}
                width={20}
                color={i.tracked_hours > i.budgeted_hours ? "red" : "#519acc"}
              />
              <span>
                {" "}
                {this.formatNumber(
                  i.tracked_hours ? i.tracked_hours.toFixed(2) : "0.00"
                )}
              </span>
            </p>
            <p>
              Budgeted Hours
              <br />
              <ClockIcon height={20} width={20} color="#519acc" />
              <span> {this.formatNumber(i.budgeted_hours.toFixed(2))}</span>
            </p>
            <Select
              isSearchable={false}
              classNamePrefix="react-select"
              options={
                i.invoice_status === 1
                  ? this.props.statusOptions.slice(0, 2)
                  : this.props.statusOptions
              }
              onChange={this.updateRowStatus}
              value={selectedOption}
              blurInputOnSelect
              styles={{
                ...SingleSelectStyles,
                ...{
                  container: (styles) => ({
                    ...styles,
                    width: "160px",
                    fontSize: "13px",
                    marginLeft: "10px",
                    fontFamily: "Arial",
                  }),
                  menu: (styles) => ({
                    ...styles,
                    width: "180px",
                    left: "-10px",
                  }),
                },
              }}
              placeholder="Select a Range"
            />
          </div>
        </div>
        {this.state.expanded && (
          <div className="invoice-details">
            {i.estimate_url && i.estimate_url.length > 0 && (
              <a
                href={
                  i.estimate_url[0].location.includes("http")
                    ? i.estimate_url[0].location
                    : `http://${i.estimate_url[0].location}`
                }
                target="_blank"
                style={{
                  display: "block",
                  fontSize: "13px",
                  lineHeight: "45px",
                }}
                className="common-button-submit"
                rel="noreferrer"
              >
                View Project Estimate
              </a>
            )}
            {i.invoice_status === 1 ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "nowrap",
                    minHeight: "75px",
                    alignItems: "center",
                    marginTop: "12px",
                  }}
                >
                  <div style={{ width: i.tasks ? "40%" : "100%" }}>
                    <p style={{ marginTop: "0px", marginBottom: "8px" }}>
                      <span
                        style={{
                          fontFamily: "Manrope",
                          fontSize: "14px",
                          fontWeight: "600",
                          display: "block",
                          marginBottom: "20px",
                        }}
                      >
                        Invoicing Instructions:
                      </span>
                      {i.invoice_notes ? (
                        i.invoice_notes
                      ) : (
                        <span style={{ color: "gray" }}>
                          No instructions provided
                        </span>
                      )}
                    </p>
                  </div>
                  {i.tasks && (
                    <div
                      style={{
                        width: "60%",
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                        position: "relative",
                        paddingLeft: "55px",
                      }}
                    >
                      <div
                        ref={this.floatyRef}
                        id={`floaty-${i.id}`}
                        className="invoice-floaty"
                      >
                        <div className="floaty-point" />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: "32px",
                          borderRadius: "10px",
                          overflow: "hidden",
                          display: "flex",
                          flexWrap: "nowrap",
                        }}
                      >
                        {i.tasks.task_array.map((t) => {
                          return (
                            <div
                              key={t.task_name}
                              onMouseEnter={this.showFloaty}
                              onMouseLeave={this.hideFloaty}
                              data-percent={
                                (t.hours / i.tasks.total_task_hours) * 100
                              }
                              data-name={t.task_name}
                              style={{
                                backgroundColor: t.task_color,
                                width: `${
                                  (t.hours / i.tasks.total_task_hours) * 100
                                }%`,
                              }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
                {i.tasks && (
                  <div>
                    <p>Tasks (Task Name / Hours):</p>
                    <div
                      className="invoice-task-section"
                      style={{ display: "flex", flexWrap: "wrap" }}
                    >
                      {i.tasks.task_array.map((t, index) => {
                        return (
                          <div
                            key={`${t.task_name}-card`}
                            className="invoice-task-card"
                            style={{
                              width:
                                index % 2 === 0 ? "calc(50% - 10px)" : "50%",
                              marginRight: index % 2 === 0 ? "10px" : "0px",
                            }}
                          >
                            <div>
                              <div
                                aria-hidden="true"
                                style={{ backgroundColor: t.task_color }}
                              />
                              <p>{t.task_name}</p>
                            </div>
                            <div>
                              <p>{this.formatNumber(t.hours.toFixed(2))}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <p style={{ margin: "25px 0px 0px 0px" }}>Notes:</p>
                <textarea
                  maxLength={2000}
                  className="browser-default text-input-v2"
                  name="notes"
                  type="text"
                  defaultValue={this.state.notes}
                  onBlur={this.handleNotesBlur}
                />
              </div>
            )}

            <div style={{ width: "100%", display: "flex", flexWrap: "nowrap" }}>
              <div style={{ width: "50%", paddingRight: "10px" }}>
                <p>Invoice Contacts:</p>
                {i.contacts
                  .filter((c) => c.invoicing)
                  .map((c, index) => {
                    return (
                      <div
                        key={`${c.name}-invoice-contact-${index}`}
                        className="invoice-task-card"
                      >
                        <div>
                          <p>{c.name}</p>
                        </div>
                        <div>
                          <p>{c.email}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div style={{ width: "50%" }}>
                <p>Project Contacts:</p>
                {i.contacts
                  .filter((c) => !c.invoicing)
                  .map((c, index) => {
                    return (
                      <div
                        key={`${c.name}-project-contact-${index}`}
                        className="invoice-task-card"
                      >
                        <div>
                          <p>{c.name}</p>
                        </div>
                        <div>
                          <p>{c.email}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default InvoiceRow;
