import { func } from "prop-types";
import React from "react";

const RateInput = ({ rate, handleRateChange, labelClass, customStyles }) => {
  return (
    <>
      <label
        className={labelClass}
        style={customStyles.label}
        htmlFor="filter_filters"
      >
        Rate
        <span className="base-color">*</span>
      </label>
      <div className="text-rate-v2">
        <span>$</span>
        <input
          className="hoursInput browser-default text-input-v2"
          name="rate"
          placeholder="00"
          type="number"
          style={customStyles.input}
          value={rate}
          onChange={handleRateChange}
          min={0}
          max={99}
          step={1}
        />
      </div>
    </>
  );
};

RateInput.defaultProps = {
  rate: "0000",
  handleRateChange: func,
  labelClass: "",
  customStyles: { label: {}, input: {} },
};

export default RateInput;
