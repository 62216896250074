import React from "react";
import axios from "axios";
import SingleSelectWrapper from "../common/single_select_wrapper";
import Swal from "sweetalert2";
import TaskboardCircle from "../icons/taskboard_circle";
import ExitIcon from "../icons/exit_icon";
import styled from "styled-components";

class CopyProject extends React.Component {
  constructor(props) {
    super(props);
    this.options = [
      { value: "website", label: "Website" },
      { value: "existing client", label: "Existing Client" },
      { value: "referral", label: "Referral" },
      { value: "linkedin", label: "LinkedIn" },
      { value: "other", label: "Other" },
    ];
    this.state = {
      name: "Copy of " + this.props.data.project.name,
      rate: true,
      clientContacts: true,
      invoiceContacts: true,
      invoiceDetails: true,
      color: true,
      leadSource: this.options.find(
        (o) => o.value === this.props.data.project.lead_source
      ),
      leadUser: this.props.data.leadOptions.find(
        (o) => o.value === this.props.data.project.lead_owner_id
      ),
      tag: this.props.data.project.tag
        ? this.props.data.tagOptions.find(
            (o) => o.value === this.props.data.project.tag.id
          )
        : null,
    };
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  }

  closeModal = () => {
    this.props.modalAction(false, "", {});
  };

  handleInputChange = (e) => {
    this.setState({ name: e.target.value ? e.target.value : "" });
  };

  handleCheckBoxes = (e) => {
    var name = e.target.name;
    this.setState({ [name]: !this.state[name] });
  };

  handleSelectChange = (option, event) => {
    var name = event.name;
    this.setState({ [name]: option });
  };

  submit = () => {
    this.props.setBackdropLoader(true);
    var copy_project = {
      name: this.state.name,
      client_id: this.props.data.project.client_id,
      tag_id: this.state.tag.value,
      lead_owner_id: this.state.leadUser.value,
    };
    if (this.props.data.taskboard) {
      copy_project.lead_source = this.state.leadSource.value;
    }
    if (this.state.rate) {
      copy_project.rate = this.props.data.project.rate;
    }
    if (this.state.clientContacts) {
      copy_project.client_contacts = true;
    }
    if (this.state.invoiceContacts) {
      copy_project.invoice_contacts = true;
    }
    if (this.state.invoiceDetails) {
      copy_project.invoice_notes = true;
    }
    if (this.state.color) {
      copy_project.card_color = true;
    }
    if (this.props.data.taskboard) {
      copy_project.taskboard = true;
    }
    axios
      .post(`/projects/${this.props.data.project.id}/copy_project.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        copy_project,
      })
      .then((res) => {
        if (res.data.id === null) {
          M.toast({
            html: `Project already exists, choose a unique name.`,
            displayLength: 3000,
            classes: "red",
          });
          this.props.setBackdropLoader(false);
          if (this.submitButton && this.submitButton.current) {
            this.submitButton.current.disabled = false;
          }
        } else {
          if (this.props.data.taskboard) {
            Swal.fire({
              icon: "success",
              customClass: {
                container: "tb-swal",
                title: "tb-swal-title",
                icon: "tb-swal-icon",
                content: "tb-swal-content",
                confirmButton: "tb-swal-confirm",
              },
              title: "Lead Complete!",
              html: "<p>Your new project was created successfully and has been added to the <strong>New Project</strong> column on the Taskboard.</p><p>The next step is to access the newly created card and add any more known details about the project.</p>",
              showCancelButton: false,
              confirmButtonAriaLabel: "Next Step",
              confirmButtonText: "Next Step",
            });
          } else {
            M.toast({
              html: `${res.data.name} created!`,
              displayLength: 3000,
              classes: "green",
            });
          }
          this.props.setBackdropLoader(false);
          this.props.data.projectsHandler(res.data);
          this.props.modalAction(false, "", {});
        }
      })
      .catch((err) => {
        console.error(err);
        this.props.setBackdropLoader(false);
        Swal.fire({
          title: "Project Error",
          text: err.response.data,
          confirmButtonText: "OK",
        });
      });
  };

  render() {
    var { name, leadSource, leadUser, tag } = this.state;
    return (
      <div
        role="dialog"
        style={{
          padding: "32px",
          backgroundColor: "white",
          borderRadius: "10px",
        }}
      >
        <div
          style={{ position: "relative", justifyContent: "space-between" }}
          className="flex-container flex-al-center flex-j-start"
        >
          <h2
            style={{
              fontSize: "18px",
              display: "flex",
              alignItems: "center",
              marginTop: "0px",
              marginBottom: `32px`,
              fontWeight: "600",
              fontFamily: "Manrope",
            }}
          >
            <div
              aria-hidden="true"
              style={{
                overflow: "hidden",
                borderRadius: "50%",
                backgroundColor: "#519acc",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0px 15px 0px 0px",
              }}
            >
              <TaskboardCircle />
            </div>
            Duplicate Project
          </h2>
          <button
            type="button"
            id="modalClose"
            aria-label="Close Modal"
            className="btn-icon"
            style={{
              padding: 0,
              position: "relative",
              top: "-15px",
              right: 0,
            }}
            onClick={this.closeModal}
          >
            <ExitIcon
              svgStyles={{ pointerEvents: "none" }}
              height="25px"
              width="25px"
            />
          </button>
        </div>
        <div
          style={{
            background: "none",
            backdropFilter: "none",
            boxShadow: "none",
            borderRadius: "0px",
          }}
          className="custom-checkbox-menu"
        >
          <div>
            <p className="modal-labels">
              New Project Name<span className="base-color">*</span>
            </p>
            <input
              type="text"
              className="text-input-v2 browser-default"
              maxLength={50}
              onChange={this.handleInputChange}
              value={this.state.name}
              style={{
                display: "block",
                width: "100%",
                marginTop: "0px",
                marginBottom: "16px",
                fontSize: "13px",
              }}
            />
            {this.props.data.taskboard && (
              <div
                style={{
                  margin: "0 0 16px",
                  justifyContent: "space-between",
                  gap: "16px",
                }}
                className="flex-container flex-row flex-al-center"
              >
                <div style={{ width: "100%" }}>
                  <p htmlFor="filter_filters" className="modal-labels">
                    Lead Source<span className="base-color">*</span>
                  </p>
                  <SingleSelectWrapper
                    options={this.options}
                    onChange={this.handleSelectChange}
                    name="leadSource"
                    placeholder={"Select a source"}
                    value={this.state.leadSource}
                    menuPlacement="bottom"
                    customStyles={{
                      container: (styles) => ({ ...styles, width: "100%" }),
                    }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <p htmlFor="filter_filters" className="modal-labels">
                    Lead Manager<span className="base-color">*</span>
                  </p>
                  <SingleSelectWrapper
                    options={this.props.data.leadOptions}
                    onChange={this.handleSelectChange}
                    placeholder={"Select a user"}
                    name="leadUser"
                    value={this.state.leadUser}
                    menuPlacement="bottom"
                    customStyles={{
                      container: (styles) => ({ ...styles, width: "100%" }),
                    }}
                  />
                </div>
              </div>
            )}
            <div
              style={{
                margin: "0 0 16px",
                justifyContent: "space-between",
                gap: "16px",
              }}
              className="flex-container flex-row flex-al-center"
            >
              {!this.props.data.taskboard && (
                <div style={{ width: "50%" }}>
                  <p htmlFor="filter_filters" className="modal-labels">
                    Project Lead<span className="base-color">*</span>
                  </p>
                  <SingleSelectWrapper
                    options={this.props.data.leadOptions}
                    onChange={this.handleSelectChange}
                    name="leadUser"
                    placeholder={"Select a user"}
                    value={this.state.leadUser}
                    menuPlacement="bottom"
                    customStyles={{
                      container: (styles) => ({ ...styles, width: "175px" }),
                    }}
                  />
                </div>
              )}
              <div
                style={{ width: !this.props.data.taskboard ? "50%" : "184px" }}
              >
                <p htmlFor="filter_filters" className="modal-labels">
                  Project Type<span className="base-color">*</span>
                </p>
                <SingleSelectWrapper
                  options={this.props.data.tagOptions}
                  onChange={this.handleSelectChange}
                  name="tag"
                  placeholder={"Select a type"}
                  value={this.state.tag}
                  menuPlacement="bottom"
                  customStyles={{
                    container: (styles) => ({ ...styles, width: "100%" }),
                  }}
                />
              </div>
            </div>
            <p className="modal-labels">Information to copy:</p>
            <CheckboxContainer>
              <label className="custom-labels truncate">
                <input
                  onChange={this.handleCheckBoxes}
                  className="filled-in"
                  name="clientContacts"
                  checked={this.state.clientContacts}
                  type="checkbox"
                />
                <CheckboxSpan>Client Contacts</CheckboxSpan>
              </label>

              <label className="custom-labels truncate">
                <input
                  onChange={this.handleCheckBoxes}
                  className="filled-in"
                  name="invoiceContacts"
                  checked={this.state.invoiceContacts}
                  type="checkbox"
                />
                <CheckboxSpan>Invoice Contacts</CheckboxSpan>
              </label>
              <label className="custom-labels truncate">
                <input
                  onChange={this.handleCheckBoxes}
                  className="filled-in"
                  name="invoiceDetails"
                  checked={this.state.invoiceDetails}
                  type="checkbox"
                />
                <CheckboxSpan>Invoice Details</CheckboxSpan>
              </label>

              <label className="custom-labels truncate">
                <input
                  onChange={this.handleCheckBoxes}
                  className="filled-in"
                  name="color"
                  checked={this.state.color}
                  type="checkbox"
                />
                <CheckboxSpan>Color</CheckboxSpan>
              </label>
            </CheckboxContainer>
          </div>
          <div style={{ textAlign: "right", marginTop: "32px" }}>
            <button onClick={this.closeModal} className="common-button-cancel">
              Cancel
            </button>
            <button
              disabled={
                !name ||
                (this.props.data.taskboard && !leadSource) ||
                !leadUser ||
                !tag
              }
              onClick={this.submit}
              style={{ marginLeft: "8px" }}
              className="common-button-submit"
            >
              Duplicate Project
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: 144px 144px;
  grid-template-rows: 21px;
  gap: 8px;
  max-height: 56px;
`;

const CheckboxSpan = styled.span`
  whitespace: nowrap;
  height: 100%;
  width: 100%;
  padding-left: 25px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #313131;
  font-family: Manrope !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  text-align: left;
`;

export default CopyProject;
