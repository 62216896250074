import React from "react";

const ProjectsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="30"
      viewBox="0 0 27 30"
      fill="none"
    >
      <path
        d="M23.2865 2.95555H17.2029C16.9049 2.09179 16.3499 1.3434 15.6146 0.813907C14.8793 0.284417 14.0001 0 13.0987 0C12.1972 0 11.318 0.284417 10.5827 0.813907C9.84747 1.3434 9.29243 2.09179 8.99442 2.95555H2.91082C2.13953 2.95789 1.40049 3.26999 0.855103 3.8237C0.309718 4.37742 0.00230351 5.12774 0 5.9108V26.5975C0.00230351 27.3806 0.309718 28.1309 0.855103 28.6846C1.40049 29.2383 2.13953 29.5505 2.91082 29.5528H23.2865C24.0578 29.5505 24.7969 29.2383 25.3422 28.6846C25.8876 28.1309 26.195 27.3806 26.1973 26.5975V5.9108C26.195 5.12774 25.8876 4.37742 25.3422 3.8237C24.7969 3.26999 24.0578 2.95789 23.2865 2.95555ZM13.0987 2.95555C13.3865 2.95555 13.6679 3.04221 13.9073 3.20457C14.1466 3.36694 14.3331 3.59771 14.4433 3.86771C14.5534 4.13771 14.5823 4.43481 14.5261 4.72144C14.47 5.00807 14.3313 5.27136 14.1278 5.47801C13.9243 5.68466 13.6649 5.82539 13.3826 5.88241C13.1003 5.93942 12.8077 5.91016 12.5417 5.79832C12.2758 5.68648 12.0485 5.49709 11.8885 5.2541C11.7286 5.0111 11.6433 4.72542 11.6433 4.43317C11.6444 4.04164 11.7981 3.66648 12.0708 3.38963C12.3435 3.11277 12.713 2.95672 13.0987 2.95555ZM10.1879 10.3437L14.2484 14.4722L17.9161 10.7485L16.0095 8.86605H21.8311V14.7765L19.974 12.8394L14.2514 18.6538L10.1879 14.5253L6.42417 18.3435L4.36622 16.2542L10.1879 10.3437ZM23.2865 25.1199H2.91082V22.1647H23.2865V25.1199Z"
        fill="url(#paint0_linear_2033_4833)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2033_4833"
          x1="13.0987"
          y1="0"
          x2="13.0987"
          y2="29.5528"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ProjectsIcon;
