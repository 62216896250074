import React from "react";
import SvgShell from "./svg_shell";

function CommentIcon({
  height, width, svgStyles, color
}) {
  return (
    <SvgShell height={height} width={width} svgStyles={svgStyles} color={color}>
      <path d="M18.46,53.73A3.27,3.27,0,0,1,17,53.41a3.44,3.44,0,0,1-2-3.24l.3-8.68H8.27a6.91,6.91,0,0,1-6.9-6.9V7.87A6.91,6.91,0,0,1,8.27,1H55.73a6.91,6.91,0,0,1,6.9,6.89V34.59a6.91,6.91,0,0,1-6.9,6.9h-22L20.6,52.9A3.15,3.15,0,0,1,18.46,53.73Zm-.21-3.45h0ZM8.27,4.49A3.39,3.39,0,0,0,4.89,7.87V34.59A3.39,3.39,0,0,0,8.27,38H19L18.55,50,32.44,38H55.73a3.39,3.39,0,0,0,3.38-3.38V7.87a3.39,3.39,0,0,0-3.38-3.38Z" />
    </SvgShell>
  );
}

CommentIcon.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "black",
};

export default CommentIcon;
