import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Select, { components } from "react-select";
import axios from "axios";
import ExitIcon from "../../icons/exit_icon";
import TaskSelectStyles from "../../common/task_select_styles";

const TestCard = styled.div`
  padding: 0 10px;
`;
const customControl = (props) => {
  return (
    <div>
      <p className="custom-select-input">{props.selectProps.inputValue}</p>
      <components.Control {...props} />
    </div>
  );
};
const labelInput = (props) => <components.Input {...props} maxLength={20} />;
function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <i
        className="material-icons base-color-hover"
        style={{ color: "#e7e7e7" }}
      >
        search
      </i>
    </components.DropdownIndicator>
  );
}
function MultiValueRemove(props) {
  return (
    <components.MultiValueRemove {...props}>
      <button
        type="button"
        style={{
          border: "1px solid lightgray",
          cursor: "pointer",
          paddingRight: "7px",
          borderRadius: "3px",
          display: "flex",
          width: "24px",
          justifyContent: "center",
          backgroundColor: "white",
        }}
      >
        <img
          alt="trash"
          src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/syncit/Icon__trash_1.svg"
          style={{ width: "20px", height: "20px" }}
        />
      </button>
    </components.MultiValueRemove>
  );
}

function ConfirmedTransition(props) {
  const selectRef = useRef(null);
  const [selectedUsers, setSelectedUsers] = useState(
    props.project.users.map((x) =>
      props.userOptions.find((y) => y.value === x.id)
    )
  );
  const [selectedPms, setSelectedPms] = useState(
    props.project.project_managers.map((x) => {
      if (props.userOptions.find((y) => y.value === x.id) != undefined) {
        return props.userOptions.find((y) => y.value === x.id).value;
      }
      return [];
    })
  );

  function submit(e) {
    e.preventDefault();
    const errors = [];
    const project = {};
    if (selectedUsers.length >= 1) {
      var user_ids = [
        ...new Set(selectedUsers.filter((x) => x).map((x) => x.value)),
      ];
      project.user_ids = user_ids;
    } else {
      var user_ids = [];
      project.user_ids = user_ids;
    }
    if (errors.length === 0) {
      props.submitHandler(project, "testing", "completing");
    } else {
      M.toast({
        html: errors.join(", "),
        classes: "danger",
      });
    }
  }

  function handleUserSelectChange(e) {
    setSelectedUsers(e || []);
  }

  function setPms(ids) {
    const project = {};
    project.pm_ids = ids;
    axios
      .patch(`/projects/${props.project.id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        project,
      })
      .then((res) => {
        M.toast({
          html: "Project Managers Updated",
          displayLength: 3000,
          classes: "green",
        });
        if (selectRef && selectRef.current) {
          selectRef.current.select.forceUpdate();
        }
      })
      .catch((err) => {
        console.error(err);
        M.toast({ html: err.response.data, classes: "red" });
      });
  }

  function pmClick(e) {
    e.stopPropagation();
    const userId = parseInt(e.target.dataset.userId);
    let pms = selectedPms;
    if (pms.includes(userId)) {
      pms = pms.filter((pm) => pm != userId);
    } else {
      pms.push(userId);
    }
    setSelectedPms(pms);
    setPms(pms);
  }

  function MultiValueLabel(props) {
    const included = selectedPms.includes(props.data.value);
    return (
      <div
        style={{ flex: 1, paddingRight: "10px" }}
        className="flex-container flex-al-center flex-space-between"
      >
        <components.MultiValueLabel {...props} />
        <button
          data-user-id={props.data.value}
          type="button"
          onMouseDown={pmClick}
          style={{
            backgroundColor: `${included ? "#519acc" : "#d1d1d1"}`,
            cursor: "pointer",
            border: "none",
            width: "50px",
            height: "20px",
            borderRadius: "5px",
            fontSize: "12px",
            fontFamily: "Arial",
            fontWeight: 600,
            color: "white",
          }}
        >
          {included ? "PM" : "USER"}
        </button>
      </div>
    );
  }

  return (
    <TestCard>
      <button
        id="modalClose"
        onClick={props.cancelHandler}
        aria-label="Close Modal"
        className="btn-icon"
        style={{
          position: "absolute",
          top: "35px",
          right: "20px",
        }}
      >
        <ExitIcon
          svgStyles={{ pointerEvents: "none" }}
          height="30px"
          width="30px"
        />
      </button>
      <form
        onSubmit={submit}
        style={{
          flexDirection: "column",
          marginTop: "35px",
          alignItems: "flex-start",
        }}
        className="flex-container flex-row flex-al-center"
      >
        <div
          className="input-field"
          style={{
            flex: "1 1 100%",
            margin: "35px 0 0",
            minHeight: `${
              selectedUsers.length < 3 ? selectedUsers.length * 110 : 225
            }px`,
            transition: "min-height .5s",
          }}
        >
          <label className="select-labels" htmlFor="filter_filters">
            Add Users and PMs for Time Tracking
          </label>
          <Select
            isMulti
            required
            ref={selectRef}
            classNamePrefix="react-multi-select"
            menuShouldScrollIntoView
            isClearable={false}
            blurInputOnSelect
            menuPlacement="auto"
            placeholder="Search for a user"
            components={{
              Control: customControl,
              Input: labelInput,
              DropdownIndicator,
              MultiValueRemove,
              MultiValueLabel,
            }}
            aria-label="User dropdown for edit project form"
            closeMenuOnSelect={false}
            options={props.userOptions}
            onChange={handleUserSelectChange}
            value={selectedUsers}
            styles={{
              ...TaskSelectStyles,
              valueContainer: (styles, state) => ({
                ...styles,
                paddingTop: state.hasValue ? "10px" : "",
                order: 2,
                top: state.hasValue ? "30px" : "",
                left: state.hasValue ? "-10px" : "",
                position: state.hasValue ? "absolute" : "",
                width: `${selectedUsers.length < 4 ? "385px" : "375px"}`,
                padding: "0 0 0 10px",
                overflow: state.hasValue ? "auto" : "",
                display: "flex",
                minHeight: `${
                  selectedUsers.length < 4 ? selectedUsers.length * 60 : 180
                }px`,
                maxHeight: "150px",
                marginTop: "15px",
              }),
              multiValueRemove: (styles) => ({
                ...styles,
                paddingRight: "12px",
              }),
              multiValue: (styles, state) => {
                return {
                  ...styles,
                  fontFamily: "Arial",
                  fontWeight: "400",
                  textAlign: "center",
                  borderRadius: "3px",
                  fontSize: "14px",
                  flex: "100%",
                  maxWidth: `${selectedUsers.length >= 4 ? 95 : 100}%`,
                  marginRight: "10px",
                  marginBottom: "5px",
                  height: "40px",
                  alignItems: "center",
                  boxShadow: "0 1px 1px 1px #d1d1d1",
                  justifyContent: "space-between",
                  backgroundColor: "white",
                };
              },
            }}
          />
        </div>
        <div style={{ marginTop: "40px", textAlign: "right", width: "100%" }}>
          <button
            onClick={props.cancelHandler}
            type="button"
            className="common-button-cancel"
            style={{ marginRight: "13px" }}
          >
            Cancel
          </button>
          <button className="common-button-submit">Move</button>
        </div>
      </form>
    </TestCard>
  );
}

export default ConfirmedTransition;
