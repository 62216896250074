import React from "react";
import moment from "moment";
import axios from "axios";
import ReactModal from "../modals/react_modal";

class BasementView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDay: moment().format("MM-DD-YYYY"),
      bookings: this.props.bookings,
      bookingsArray: [[], [], [], [], [], []],
      currentTime: moment().format("h:mm"),
      currentTimeIndex: 0,
      greenAvailable: true,
      greyAvailable: true,
      orangeAvailable: true,
      redAvailable: true,
      blueAvailable: true,
      redAvailable: true,
      nextBookingArray: [36, 36, 36, 36, 36, 36],
      modalOpen: false,
      currentModal: "",
      modalData: {},
    };
    this.counter = 0;
    this.timeArray = [
      "9:00",
      "9:15",
      "9:30",
      "9:45",
      "10:00",
      "10:15",
      "10:30",
      "10:45",
      "11:00",
      "11:15",
      "11:30",
      "11:45",
      "12:00",
      "12:15",
      "12:30",
      "12:45",
      "1:00",
      "1:15",
      "1:30",
      "1:45",
      "2:00",
      "2:15",
      "2:30",
      "2:45",
      "3:00",
      "3:15",
      "3:30",
      "3:45",
      "4:00",
      "4:15",
      "4:30",
      "4:45",
      "5:00",
      "5:15",
      "5:30",
      "5:45",
    ];
    this.renderRoomRows = this.renderRoomRows.bind(this);
    this.renderGreenRow = this.renderGreenRow.bind(this);
    this.renderGreyRow = this.renderGreyRow.bind(this);
    this.renderOrangeRow = this.renderOrangeRow.bind(this);
    this.renderRedRow = this.renderRedRow.bind(this);
    this.renderYellowRow = this.renderYellowRow.bind(this);
    this.renderBlueRow = this.renderBlueRow.bind(this);
    this.handleRoomCardClick = this.handleRoomCardClick.bind(this);
    this.setModal = this.setModal.bind(this);
    this.refreshBookings = this.refreshBookings.bind(this);
    this.refreshRows = this.refreshRows.bind(this);
  }

  componentDidMount() {
    const elem = document.querySelector("header");
    elem.parentNode.removeChild(elem);
    const container = document.getElementsByClassName("container");
    if (container[0]) {
      container[0].style.paddingLeft = "1%";
      container[0].style.paddingRight = "1%";
    }

    setInterval(() => {
      if (moment().format("h:mm") !== this.state.currentTime) {
        const dayMinutes = moment().diff(moment().startOf("day"), "minutes") - 540;
        if (dayMinutes >= 0) {
          const timeIndex = Math.floor(dayMinutes / 15);
          if (timeIndex < 36) {
            let greenAvailable = true;
            let greyAvailable = true;
            let orangeAvailable = true;
            let redAvailable = true;
            let yellowAvailable = true;
            let blueAvailable = true;
            const nextBookingArray = [36, 36, 36, 36, 36, 36];
            for (let x = timeIndex; x < 36; x++) {
              if (
                this.state.bookingsArray[0][x] === "1"
                && nextBookingArray[0] === 36
              ) {
                nextBookingArray[0] = x;
              }
              if (
                this.state.bookingsArray[1][x] === "1"
                && nextBookingArray[1] === 36
              ) {
                nextBookingArray[1] = x;
              }
              if (
                this.state.bookingsArray[2][x] === "1"
                && nextBookingArray[2] === 36
              ) {
                nextBookingArray[2] = x;
              }
              if (
                this.state.bookingsArray[3][x] === "1"
                && nextBookingArray[3] === 36
              ) {
                nextBookingArray[3] = x;
              }
              if (
                this.state.bookingsArray[4][x] === "1"
                && nextBookingArray[4] === 36
              ) {
                nextBookingArray[4] = x;
              }
              if (
                this.state.bookingsArray[5][x] === "1"
                && nextBookingArray[5] === 36
              ) {
                nextBookingArray[5] = x;
              }
            }
            if (this.state.bookingsArray[0][timeIndex] === "1") {
              greenAvailable = false;
            }
            if (this.state.bookingsArray[1][timeIndex] === "1") {
              greyAvailable = false;
            }
            if (this.state.bookingsArray[2][timeIndex] === "1") {
              orangeAvailable = false;
            }
            if (this.state.bookingsArray[3][timeIndex] === "1") {
              redAvailable = false;
            }
            if (this.state.bookingsArray[4][timeIndex] === "1") {
              yellowAvailable = false;
            }
            if (this.state.bookingsArray[5][timeIndex] === "1") {
              blueAvailable = false;
            }
            this.setState({
              currentTimeIndex: timeIndex,
              nextBookingArray,
              greenAvailable,
              greyAvailable,
              orangeAvailable,
              redAvailable,
              yellowAvailable,
              blueAvailable,
            });
          }
        }
        this.setState({
          currentTime: moment().format("h:mm"),
        });
        this.refreshBookings();
      }
    }, 3000);

    this.refreshRows();
  }

  renderGreenRow(time, index) {
    return this.renderRoomRows(time, index, 0);
  }

  renderGreyRow(time, index) {
    return this.renderRoomRows(time, index, 1);
  }

  renderOrangeRow(time, index) {
    return this.renderRoomRows(time, index, 2);
  }

  renderRedRow(time, index) {
    return this.renderRoomRows(time, index, 3);
  }

  renderYellowRow(time, index) {
    return this.renderRoomRows(time, index, 4);
  }

  renderBlueRow(time, index) {
    return this.renderRoomRows(time, index, 5);
  }

  renderRoomRows(time, index, roomIndex) {
    if (this.counter > 0) {
      this.counter -= 1;
      return;
    }
    if (time === "0") {
      return (
        <div key={`${roomIndex}-${index}`} className="room-view-cell-open">
          -
        </div>
      );
    }
    for (
      let x = index;
      x < this.state.bookingsArray[roomIndex].length - 1;
      x++
    ) {
      if (this.state.bookingsArray[roomIndex][x + 1] === "1") {
        this.counter += 1;
      } else {
        break;
      }
    }
    return (
      <div
        key={`${roomIndex}-${index}`}
        className="room-view-cell-booked"
        style={{ width: `${2.35 * (this.counter + 1)}%` }}
      />
    );
  }

  handleRoomCardClick(e) {
    const dayMinutes = moment().diff(moment().startOf("day"), "minutes") - 540;
    const extendBooking = dayMinutes % 15 > 0;
    if (e.target.id === "green-room-card") {
      if (this.state.greenAvailable) {
        this.setModal(true, "add-room-view-booking", {
          room_id: 2,
          room_name: "Green Room",
          bookingsArray: this.state.bookingsArray[0],
          extendBooking,
          currentTimeIndex: this.state.currentTimeIndex,
        });
      } else {
        var selectedBooking = {};
        this.state.bookings.forEach((booking) => {
          if (
            booking.room.name === "Green room"
            && booking.booking_array[this.state.currentTimeIndex + 4] === "1"
          ) {
            selectedBooking = booking;
          }
        });
        var updatedBookingsArray = this.state.bookingsArray[0].map((x, index) =>
          (x === selectedBooking.booking_array[index + 4] ? "0" : x));
        this.setModal(true, "current-booking", {
          selectedBooking,
          bookingsArray: updatedBookingsArray,
          extendBooking,
          currentTimeIndex: this.state.currentTimeIndex,
        });
      }
    } else if (e.target.id === "grey-room-card") {
      if (this.state.greyAvailable) {
        this.setModal(true, "add-room-view-booking", {
          room_id: 3,
          room_name: "Grey Room",
          bookingsArray: this.state.bookingsArray[1],
          extendBooking,
          currentTimeIndex: this.state.currentTimeIndex,
        });
      } else {
        var selectedBooking = {};
        this.state.bookings.forEach((booking) => {
          if (
            booking.room.name === "Grey room"
            && booking.booking_array[this.state.currentTimeIndex + 4] === "1"
          ) {
            selectedBooking = booking;
          }
        });
        var updatedBookingsArray = this.state.bookingsArray[1].map((x, index) =>
          (x === selectedBooking.booking_array[index + 4] ? "0" : x));
        this.setModal(true, "current-booking", {
          selectedBooking,
          bookingsArray: updatedBookingsArray,
          extendBooking,
          currentTimeIndex: this.state.currentTimeIndex,
        });
      }
    } else if (e.target.id === "orange-room-card") {
      if (this.state.orangeAvailable) {
        this.setModal(true, "add-room-view-booking", {
          room_id: 4,
          room_name: "Orange Room",
          bookingsArray: this.state.bookingsArray[2],
          extendBooking,
          currentTimeIndex: this.state.currentTimeIndex,
        });
      } else {
        var selectedBooking = {};
        this.state.bookings.forEach((booking) => {
          if (
            booking.room.name === "Orange room"
            && booking.booking_array[this.state.currentTimeIndex + 4] === "1"
          ) {
            selectedBooking = booking;
          }
        });
        var updatedBookingsArray = this.state.bookingsArray[2].map((x, index) =>
          (x === selectedBooking.booking_array[index + 4] ? "0" : x));
        this.setModal(true, "current-booking", {
          selectedBooking,
          bookingsArray: updatedBookingsArray,
          extendBooking,
          currentTimeIndex: this.state.currentTimeIndex,
        });
      }
    } else if (e.target.id === "red-room-card") {
      if (this.state.redAvailable) {
        this.setModal(true, "add-room-view-booking", {
          room_id: 5,
          room_name: "Red Room",
          bookingsArray: this.state.bookingsArray[3],
          extendBooking,
          currentTimeIndex: this.state.currentTimeIndex,
        });
      } else {
        var selectedBooking = {};
        this.state.bookings.forEach((booking) => {
          if (
            booking.room.name === "Red room"
            && booking.booking_array[this.state.currentTimeIndex + 4] === "1"
          ) {
            selectedBooking = booking;
          }
        });
        var updatedBookingsArray = this.state.bookingsArray[3].map((x, index) =>
          (x === selectedBooking.booking_array[index + 4] ? "0" : x));
        this.setModal(true, "current-booking", {
          selectedBooking,
          bookingsArray: updatedBookingsArray,
          extendBooking,
          currentTimeIndex: this.state.currentTimeIndex,
        });
      }
    } else if (e.target.id === "yellow-room-card") {
      if (this.state.yellowAvailable) {
        this.setModal(true, "add-room-view-booking", {
          room_id: 6,
          room_name: "Yellow Room",
          bookingsArray: this.state.bookingsArray[4],
          extendBooking,
          currentTimeIndex: this.state.currentTimeIndex,
        });
      } else {
        var selectedBooking = {};
        this.state.bookings.forEach((booking) => {
          if (
            booking.room.name === "Yellow room"
            && booking.booking_array[this.state.currentTimeIndex + 4] === "1"
          ) {
            selectedBooking = booking;
          }
        });
        var updatedBookingsArray = this.state.bookingsArray[4].map((x, index) =>
          (x === selectedBooking.booking_array[index + 4] ? "0" : x));
        this.setModal(true, "current-booking", {
          selectedBooking,
          bookingsArray: updatedBookingsArray,
          extendBooking,
          currentTimeIndex: this.state.currentTimeIndex,
        });
      }
    } else if (e.target.id === "blue-room-card") {
      if (this.state.blueAvailable) {
        this.setModal(true, "add-room-view-booking", {
          room_id: 7,
          room_name: "Blue Room",
          bookingsArray: this.state.bookingsArray[5],
          extendBooking,
          currentTimeIndex: this.state.currentTimeIndex,
        });
      } else {
        var selectedBooking = {};
        this.state.bookings.forEach((booking) => {
          if (
            booking.room.name === "Blue room"
            && booking.booking_array[this.state.currentTimeIndex + 4] === "1"
          ) {
            selectedBooking = booking;
          }
        });
        var updatedBookingsArray = this.state.bookingsArray[5].map((x, index) =>
          (x === selectedBooking.booking_array[index + 4] ? "0" : x));
        this.setModal(true, "current-booking", {
          selectedBooking,
          bookingsArray: updatedBookingsArray,
          extendBooking,
          currentTimeIndex: this.state.currentTimeIndex,
        });
      }
    }
  }

  refreshBookings() {
    axios
      .get("/meetings/basement", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.setState(
          {
            bookings: res.data,
          },
          this.refreshRows
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  refreshRows() {
    const bookingsArray = [];
    for (var x = 0; x < 6; x++) {
      bookingsArray.push([
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
      ]);
    }
    for (var x = 0; x < this.state.bookings.length; x++) {
      if (this.state.bookings[x].room.name === "Green room") {
        this.state.bookings[x].booking_array.forEach((time, index) => {
          if (time === "1") {
            bookingsArray[0][index] = "1";
          }
        });
      }
      if (this.state.bookings[x].room.name === "Grey room") {
        this.state.bookings[x].booking_array.forEach((time, index) => {
          if (time === "1") {
            bookingsArray[1][index] = "1";
          }
        });
      }
      if (this.state.bookings[x].room.name === "Orange room") {
        this.state.bookings[x].booking_array.forEach((time, index) => {
          if (time === "1") {
            bookingsArray[2][index] = "1";
          }
        });
      }
      if (this.state.bookings[x].room.name === "Red room") {
        this.state.bookings[x].booking_array.forEach((time, index) => {
          if (time === "1") {
            bookingsArray[3][index] = "1";
          }
        });
      }
      if (this.state.bookings[x].room.name === "Yellow room") {
        this.state.bookings[x].booking_array.forEach((time, index) => {
          if (time === "1") {
            bookingsArray[4][index] = "1";
          }
        });
      }
      if (this.state.bookings[x].room.name === "Blue room") {
        this.state.bookings[x].booking_array.forEach((time, index) => {
          if (time === "1") {
            bookingsArray[5][index] = "1";
          }
        });
      }
    }
    bookingsArray[0].shift();
    bookingsArray[0].shift();
    bookingsArray[1].shift();
    bookingsArray[1].shift();
    bookingsArray[2].shift();
    bookingsArray[2].shift();
    bookingsArray[3].shift();
    bookingsArray[3].shift();
    bookingsArray[4].shift();
    bookingsArray[4].shift();
    bookingsArray[5].shift();
    bookingsArray[5].shift();
    bookingsArray[0].shift();
    bookingsArray[0].shift();
    bookingsArray[1].shift();
    bookingsArray[1].shift();
    bookingsArray[2].shift();
    bookingsArray[2].shift();
    bookingsArray[3].shift();
    bookingsArray[3].shift();
    bookingsArray[4].shift();
    bookingsArray[4].shift();
    bookingsArray[5].shift();
    bookingsArray[5].shift();

    const dayMinutes = moment().diff(moment().startOf("day"), "minutes") - 540;
    if (dayMinutes >= 0) {
      const timeIndex = Math.floor(dayMinutes / 15);
      if (timeIndex < 36) {
        let greenAvailable = true;
        let greyAvailable = true;
        let orangeAvailable = true;
        let redAvailable = true;
        let yellowAvailable = true;
        let blueAvailable = true;
        const nextBookingArray = [36, 36, 36, 36, 36, 36];
        for (var x = timeIndex; x < 36; x++) {
          if (bookingsArray[0][x] === "1" && nextBookingArray[0] === 36) {
            nextBookingArray[0] = x;
          }
          if (bookingsArray[1][x] === "1" && nextBookingArray[1] === 36) {
            nextBookingArray[1] = x;
          }
          if (bookingsArray[2][x] === "1" && nextBookingArray[2] === 36) {
            nextBookingArray[2] = x;
          }
          if (bookingsArray[3][x] === "1" && nextBookingArray[3] === 36) {
            nextBookingArray[3] = x;
          }
          if (bookingsArray[4][x] === "1" && nextBookingArray[4] === 36) {
            nextBookingArray[4] = x;
          }
          if (bookingsArray[5][x] === "1" && nextBookingArray[5] === 36) {
            nextBookingArray[5] = x;
          }
        }
        if (bookingsArray[0][timeIndex] === "1") {
          greenAvailable = false;
        }
        if (bookingsArray[1][timeIndex] === "1") {
          greyAvailable = false;
        }
        if (bookingsArray[2][timeIndex] === "1") {
          orangeAvailable = false;
        }
        if (bookingsArray[3][timeIndex] === "1") {
          redAvailable = false;
        }
        if (bookingsArray[4][timeIndex] === "1") {
          yellowAvailable = false;
        }
        if (bookingsArray[5][timeIndex] === "1") {
          blueAvailable = false;
        }
        this.setState({
          currentTimeIndex: timeIndex,
          nextBookingArray,
          greenAvailable,
          greyAvailable,
          orangeAvailable,
          redAvailable,
          yellowAvailable,
          blueAvailable,
        });
      }
    }

    this.setState({
      bookingsArray,
    });
  }

  setModal(bool, page, data) {
    this.setState({
      modalOpen: bool,
      currentModal: page,
      modalData: data,
    });
  }

  render() {
    return (
      <div>
        <ReactModal
          refreshBookings={this.refreshBookings}
          isShowing={this.state.modalOpen}
          page={this.state.currentModal}
          data={this.state.modalData}
          modalAction={this.setModal}
        />
        <div>
          <div
            style={{
              display: "center",
              margin: "5px",
              height: "100%",
              position: "relative",
            }}
          >
            <div
              className="room-grid-outline"
              style={{
                width: "50%",
                minWidth: "70%",
                display: "inline-block",
                border: "none",
                marginTop: "0px",
              }}
            >
              <span
                style={{
                  width: "100%",
                  display: "block",
                  textAlign: "center",
                  fontSize: "30px",
                }}
              >
                Today's Schedule
              </span>
              <div id="schedule-heading" style={{ width: "100%" }}>
                <div
                  style={{
                    display: "inline-block",
                    height: "30px",
                    width: "15%",
                  }}
                />
                <div
                  className="time-label-hour-border-basement"
                  style={{
                    display: "inline-block",
                    height: "50px",
                    width: "9.4%",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  9am
                </div>
                <div
                  className="time-label-hour-border-basement"
                  style={{
                    display: "inline-block",
                    height: "50px",
                    width: "9.4%",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  10am
                </div>
                <div
                  className="time-label-hour-border-basement"
                  style={{
                    display: "inline-block",
                    height: "50px",
                    width: "9.4%",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  11am
                </div>
                <div
                  className="time-label-hour-border-basement"
                  style={{
                    display: "inline-block",
                    height: "50px",
                    width: "9.4%",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  12pm
                </div>
                <div
                  className="time-label-hour-border-basement"
                  style={{
                    display: "inline-block",
                    height: "50px",
                    width: "9.4%",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  1pm
                </div>
                <div
                  className="time-label-hour-border-basement"
                  style={{
                    display: "inline-block",
                    height: "50px",
                    width: "9.4%",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  2pm
                </div>
                <div
                  className="time-label-hour-border-basement"
                  style={{
                    display: "inline-block",
                    height: "50px",
                    width: "9.4%",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  3pm
                </div>
                <div
                  className="time-label-hour-border-basement"
                  style={{
                    display: "inline-block",
                    height: "50px",
                    width: "9.4%",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  4pm
                </div>
                <div
                  style={{
                    display: "inline-block",
                    height: "50px",
                    width: "9.4%",
                    textAlign: "center",
                  }}
                >
                  5pm
                </div>
              </div>
              <div id="green-row" style={{ width: "100%", height: "50px" }}>
                <div className="room-view-row-label">
                  <div
                    className="color-2"
                    style={{ display: "inline-block" }}
                  />
                  Green
                </div>
                {this.state.bookingsArray[0].map(this.renderGreenRow)}
              </div>
              <div id="grey-row" style={{ width: "100%", height: "50px" }}>
                <div className="room-view-row-label">
                  <div
                    className="color-3"
                    style={{ display: "inline-block" }}
                  />
                  Grey
                </div>
                {this.state.bookingsArray[1].map(this.renderGreyRow)}
              </div>
              <div id="orange-row" style={{ width: "100%", height: "50px" }}>
                <div className="room-view-row-label">
                  <div
                    className="color-4"
                    style={{ display: "inline-block" }}
                  />
                  Orange
                </div>
                {this.state.bookingsArray[2].map(this.renderOrangeRow)}
              </div>
              <div id="red-row" style={{ width: "100%", height: "50px" }}>
                <div className="room-view-row-label">
                  <div
                    className="color-5"
                    style={{ display: "inline-block" }}
                  />
                  Red
                </div>
                {this.state.bookingsArray[3].map(this.renderRedRow)}
              </div>
              <div id="yellow-row" style={{ width: "100%", height: "50px" }}>
                <div className="room-view-row-label">
                  <div
                    className="color-6"
                    style={{ display: "inline-block" }}
                  />
                  Yellow
                </div>
                {this.state.bookingsArray[4].map(this.renderYellowRow)}
              </div>
              <div id="blue-row" style={{ width: "100%", height: "50px" }}>
                <div className="room-view-row-label">
                  <div
                    className="color-7"
                    style={{ display: "inline-block" }}
                  />
                  Blue
                </div>
                {this.state.bookingsArray[5].map(this.renderBlueRow)}
              </div>
            </div>
            <div id="room-current-time-container">
              <span>{this.state.currentTime}</span>
            </div>
            <div id="room-view-booking-container">
              <div>
                <div
                  id="green-room-card"
                  className="room-view-room-card"
                  onClick={this.handleRoomCardClick}
                >
                  <div
                    className={`room-view-card-overlay ${
                      this.state.greenAvailable
                        ? "room-view-card-overlay-open"
                        : "room-view-card-overlay-booked"
                    }`}
                  >
                    <span>
                      {this.state.greenAvailable
                        ? this.state.nextBookingArray[0] === 36
                          ? "Open"
                          : `Open until ${
                            this.timeArray[this.state.nextBookingArray[0]]
                          }`
                        : "In Use"}
                    </span>
                  </div>
                </div>
                <span>Green Room</span>
              </div>
              <div>
                <div
                  id="grey-room-card"
                  className="room-view-room-card"
                  onClick={this.handleRoomCardClick}
                >
                  <div
                    className={`room-view-card-overlay ${
                      this.state.greyAvailable
                        ? "room-view-card-overlay-open"
                        : "room-view-card-overlay-booked"
                    }`}
                  >
                    <span>
                      {this.state.greyAvailable
                        ? this.state.nextBookingArray[1] === 36
                          ? "Open"
                          : `Open until ${
                            this.timeArray[this.state.nextBookingArray[1]]
                          }`
                        : "In Use"}
                    </span>
                  </div>
                </div>
                <span>Grey Room</span>
              </div>
              <div>
                <div
                  id="orange-room-card"
                  className="room-view-room-card"
                  onClick={this.handleRoomCardClick}
                >
                  <div
                    className={`room-view-card-overlay ${
                      this.state.orangeAvailable
                        ? "room-view-card-overlay-open"
                        : "room-view-card-overlay-booked"
                    }`}
                  >
                    <span>
                      {this.state.orangeAvailable
                        ? this.state.nextBookingArray[2] === 36
                          ? "Open"
                          : `Open until ${
                            this.timeArray[this.state.nextBookingArray[2]]
                          }`
                        : "In Use"}
                    </span>
                  </div>
                </div>
                <span>Orange Room</span>
              </div>
              <div>
                <div
                  id="red-room-card"
                  className="room-view-room-card"
                  onClick={this.handleRoomCardClick}
                >
                  <div
                    className={`room-view-card-overlay ${
                      this.state.redAvailable
                        ? "room-view-card-overlay-open"
                        : "room-view-card-overlay-booked"
                    }`}
                  >
                    <span>
                      {this.state.redAvailable
                        ? this.state.nextBookingArray[3] === 36
                          ? "Open"
                          : `Open until ${
                            this.timeArray[this.state.nextBookingArray[3]]
                          }`
                        : "In Use"}
                    </span>
                  </div>
                </div>
                <span>Red Room</span>
              </div>
              <div>
                <div
                  id="yellow-room-card"
                  className="room-view-room-card"
                  onClick={this.handleRoomCardClick}
                >
                  <div
                    className={`room-view-card-overlay ${
                      this.state.yellowAvailable
                        ? "room-view-card-overlay-open"
                        : "room-view-card-overlay-booked"
                    }`}
                  >
                    <span>
                      {this.state.yellowAvailable
                        ? this.state.nextBookingArray[4] === 36
                          ? "Open"
                          : `Open until ${
                            this.timeArray[this.state.nextBookingArray[4]]
                          }`
                        : "In Use"}
                    </span>
                  </div>
                </div>
                <span>Yellow Room</span>
              </div>
              <div>
                <div
                  id="blue-room-card"
                  className="room-view-room-card"
                  onClick={this.handleRoomCardClick}
                >
                  <div
                    className={`room-view-card-overlay ${
                      this.state.blueAvailable
                        ? "room-view-card-overlay-open"
                        : "room-view-card-overlay-booked"
                    }`}
                  >
                    <span>
                      {this.state.blueAvailable
                        ? this.state.nextBookingArray[5] === 36
                          ? "Open"
                          : `Open until ${
                            this.timeArray[this.state.nextBookingArray[5]]
                          }`
                        : "In Use"}
                    </span>
                  </div>
                </div>
                <span>Blue Room</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BasementView;
