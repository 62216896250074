import React from "react";
import SvgShell from "./svg_shell";

function DollarSignIcon({ height, width, svgStyles, color }) {
  return (
    <SvgShell height={height} width={width} svgStyles={svgStyles} color={color}>
      <path d="M46.16,43.69c0-7.46-4.46-9.66-11.21-13l-3.71-1.84c-6.7-3.3-8.45-4.34-8.45-8.55C22.79,14.9,27.55,13,32,13c2.12,0,9,.53,9,7.34a2.48,2.48,0,1,0,5,0C46,14.1,42,9.65,35.66,8.4V4.74a2.48,2.48,0,1,0-5,0V8.11C23,8.6,17.84,13.35,17.84,20.31c0,7.46,4.46,9.66,11.2,13l3.72,1.84c6.7,3.31,8.45,4.35,8.45,8.55,0,6.73-7.05,7.25-9.21,7.25s-9.21-.52-9.21-7.25a2.48,2.48,0,1,0-4.95,0c0,6.92,5.14,11.63,12.87,12.11v3.46a2.48,2.48,0,0,0,5,0V55.52c6.39-1.22,10.49-5.65,10.49-11.83Z" />
    </SvgShell>
  );
}

DollarSignIcon.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "#519acc",
};

export default DollarSignIcon;
