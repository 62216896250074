import React from "react";

class CraterView extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const elem = document.querySelector("header");
    elem.parentNode.removeChild(elem);
    const container = document.getElementsByClassName("container");
    if (container && container[0]) {
      container[0].style.paddingLeft = "1%";
      container[0].style.paddingRight = "1%";
    }
    history.pushState(null, null, location.href);
    window.onpopstate = function (event) {
      history.go(1);
    };
  }

  handleRedirect(e) {
    window.location = `${window.location.origin}/meetings/${e.target.id}`;
  }

  render() {
    return (
      <div>
        <h2 style={{ textAlign: "center" }}>Select Room View</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "100px",
          }}
        >
          <button
            className="btn common-button-submit"
            style={{
              width: "200px",
              height: "100px",
              border: "solid grey 1px",
              borderRadius: "5px",
              textAlign: "center",
              fontSize: "22px",
              lineHeight: "100px",
            }}
            id="annex"
            onClick={this.handleRedirect}
          >
            Annex
          </button>
          <button
            className="btn common-button-submit"
            style={{
              width: "200px",
              height: "100px",
              border: "solid grey 1px",
              borderRadius: "5px",
              textAlign: "center",
              fontSize: "22px",
              lineHeight: "100px",
            }}
            id="crater"
            onClick={this.handleRedirect}
          >
            HQ Upstairs
          </button>
          <button
            className="btn common-button-submit"
            style={{
              width: "200px",
              height: "100px",
              border: "solid grey 1px",
              borderRadius: "5px",
              textAlign: "center",
              fontSize: "22px",
              lineHeight: "100px",
            }}
            id="basement"
            onClick={this.handleRedirect}
          >
            HQ Basement
          </button>
        </div>
      </div>
    );
  }
}

export default CraterView;
