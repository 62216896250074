import React from "react";
import moment from "moment";
import BookedRoom from "../map/booked_room";

class BookedModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      thisBooking: {},
      user: {},
      bookedRoomIsGo: false,
    };
    this.getBookingById = this.getBookingById.bind(this);
    this.getUser = this.getUser.bind(this);
  }

  componentDidMount() {
    this.getBookingById(this.props.bookingId);
    // this.getUser()
  }

  getBookingById(bookingId) {
    const thisBooking = this.props.bookings.find(
      (booking) => booking.id === bookingId
    );
    const { user } = thisBooking;
    this.setState({ thisBooking, user }, () => {
      this.goTime();
    });
  }

  getUser() {}

  goTime() {
    this.setState({ bookedRoomIsGo: true });
  }

  render() {
    var bookedRoom;
    const booking = moment(this.state.thisBooking.booking_date)
      .utc()
      .format("LLLL");
    if (this.state.bookedRoomIsGo === true) {
      var bookedRoom = (
        <div>
          <BookedRoom
            editHandler={this.props.editHandler}
            deleteHandler={this.props.deleteHandler}
            currentUser={this.props.currentUser}
            roomInfo={this.state.thisBooking}
            current_booking={this.state.thisBooking}
            currentDay={this.props.currentDay}
            bookings={this.props.bookings}
          />
        </div>
      );
    }

    return <div>{bookedRoom}</div>;
  }
}

export default BookedModal;
