import React from "react";
import SvgShell from "./svg_shell";

function UserIcon({ height, width, svgStyles, color }) {
  return (
    <SvgShell height={height} width={width} svgStyles={svgStyles} color={color}>
      <path d="M32,7A25,25,0,1,0,57,32,25,25,0,0,0,32,7Z" />
      <path
        fill="#fff"
        d="M43.79,39.28v4.66c-7.95,5.42-15.54,5.43-23.58,0V39.28a10,10,0,0,1,5.94-9.64A9.16,9.16,0,0,0,32,31.75a9.31,9.31,0,0,0,5.88-2.09A10,10,0,0,1,43.79,39.28Z"
      />
      <path
        fill="#fff"
        d="M32,16a7.09,7.09,0,0,1,7.19,7.09A7.29,7.29,0,0,1,32,30.2,7.1,7.1,0,1,1,32,16Z"
      />
    </SvgShell>
  );
}

UserIcon.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "#519acc",
};

export default UserIcon;
