import React from "react";
import ModalTag from "./modal_tag";
import styled from "styled-components";

class PageDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const prettyWordMap = {
      leading: "Lead Created",
      est_requesting: "Estimate Request",
      est_sending: "Estimate Sent",
      project_confirming: "Project Confirmed",
      testing: "Test In Progress",
      completing: "Project Complete",
      readying: "Ready for Invoice",
      invoicing: "Invoiced",
    };

    const projectWorkflowStatus =
      prettyWordMap[this.props.projectStatus] || "Not on Taskboard";

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "100%" }} className="flex-container flex-column">
          <div>
            <h4
              id="page-nav-title"
              tabIndex={0}
              className="taskCardTitle"
              style={{ flex: 1 }}
            >
              <p
                className="manrope"
                style={{
                  fontSize: "16px",
                  lineHeight: "16px",
                  fontWeight: 500,
                  margin: 0,
                  marginBottom: "15px",
                }}
              >
                {this.props.detailTitles[0]}
              </p>
              <p
                className="manrope semi-bold"
                style={{
                  fontSize: "22px",
                  lineHeight: "22px",
                  margin: !this.props.timeOffProject ? 0 : "0 0 20px 0",
                }}
              >
                {this.props.detailTitles[1]}
              </p>
            </h4>
            {!this.props.timeOffProject && (
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  flexDirection: "row",
                  marginTop: "15px",
                }}
              >
                <ProjectStatusTag>{projectWorkflowStatus}</ProjectStatusTag>
                <ModalTag
                  column={this.props.projectStatus}
                  tag={this.props.tags[0]}
                  bugTrackerTp={this.props.bugTrackerTp}
                  style={{ marginTop: "0px" }}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className="flex-container flex-j-end"
          style={{ flex: 1, alignItems: "center" }}
        >
          {this.props.link.location && (
            <a
              target="_blank"
              className="common-button-submit-detail"
              style={{
                marginRight: "10px",
                color: "white",
              }}
              href={this.props.link.location}
              rel="noreferrer"
            >
              {this.props.link.text}
            </a>
          )}
          <button
            style={{ width: "120px" }}
            onClick={this.props.clickHandler}
            className="common-button-cancel"
          >
            Edit Project
          </button>
        </div>
      </div>
    );
  }
}

const ProjectStatusTag = styled.span`
  display: flex;
  padding: 3px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: var(--background-colors-workflow-status-578-bc-2, #578bc2);
  font-family: "Open Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  color: white;
  margin-right: 4px;
`;
export default PageDetail;
