import styled from "styled-components";

const maxWidth = "1376px";

export const HelpHeadline = styled.h3`
  font-size: 18px;
  font-family: Manrope;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 16px;
`;

export const BannerTitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const HelpTitle = styled.div`
  display: flex;
  align-items: center;
  height: 24px;

  h2 {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 700;
    color: #242424;
    margin: 0;
  }
`;

export const HelpIconContainer = styled.div`
  height: 28px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

export const OuterHelpContainer = styled.div`
  width: 100%;
  max-width: 1408px;
  min-height: calc(100vh - 96px);
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid lightgrey;
`;

export const HelpHeaderContainer = styled.div`
  width: 100%;
  padding: 28px 32px 24px 32px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid lightgrey;
`;

export const HelpWrapper = styled.div`
  padding: 32px;
  padding-top: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const HelpInnerContainer = styled.div`
  width: 100%;
  max-width: ${maxWidth};
  position: relative;
  padding-top: 16px;
`;

export const HelpDetailWrapper = styled(HelpWrapper)`
  min-height: calc(100vh - 64px);
  background-color: #f5f5f5;
  justify-content: unset;
  padding-bottom: 0;
`;

export const HelpDetailsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
`;

export const HelpDetailsBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 75%;
  flex-grow: 1;
`;

export const BreadcrumbsContainer = styled.div`
  margin-top: 16px;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #242424;

  button {
    font-family: Manrope;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    text-decoration-line: underline;
    padding: 0;
    color: #519acc;
    margin-right: 4px;

    &:focus,
    &:hover {
      outline: none;
      border: none;
      background-color: transparent;
    }
  }
`;

export const HelpTopicsList = styled.div`
  flex-basis: 25%;
  max-width: 289px;
  padding: 24px 32px;
  border-right: 1px solid lightgrey;
`;

export const TopicsCount = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const HelpTopicHeaderOuterContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid lightgrey;
  padding: 28px 16px;
`;

export const HelpTopicHeaderInnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const HelpTopicHeadline = styled(HelpHeadline)`
  margin-top: 0;
  margin-bottom: 0;
`;

export const HelpTopicUpdatedAt = styled.p`
  font-family: Arial;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  padding: 0;
  margin-top: 8px;
  margin-bottom: 0;
`;

export const HelpTopicContent = styled.div`
  padding: 16px;
`;

export const EditControls = styled.div`
  display: flex;
  gap: 8px;

  button {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    background-color: transparent;
    cursor: pointer;
    transition-property: border;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;

    &:hover {
      border: 1px solid #519acc;
    }
  }
`;

export const CreateHelpContainer = styled.div``;

export const CreateHelpButton = styled.button``;

export const SearchTitle = styled(HelpHeadline)`
  text-align: center;
  margin-top: 16px;
`;

const searchIconHeight = "27px";
export const SearchIcon = styled.i`
  position: absolute;
  pointer-events: none;
  left: 16px;
  top: 50%;
  bottom: 50%;
  margin-top: auto;
  margin-bottom: auto;
  color: #519acc;
  height: ${searchIconHeight};
  line-height: ${searchIconHeight};
  font-size: ${searchIconHeight};
`;

export const HelpSearch = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

export const HelpGrid = styled.div`
  display: inline-grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
  width: 100%;
  padding: 0;
  padding-top: 32px;
`;

export const EditField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const EditFieldLabel = styled.label`
  margin-bottom: 8px;
`;
