import React, { useState, useEffect } from "react";
import DropperContainer from "../common/dropper_container";
import styled from "styled-components";
import "react-dates/initialize";
import axios from "axios";
// import TransitionContactRow from "../projects/transitions/transition_contact_row";
import ContactCard from "../users/contact_card";

const InvoiceDetails = (props) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [contacts, setContacts] = useState(
    props.project.contacts.filter((c) => c.invoicing)
  );
  const [notes, setNotes] = useState(props.project.invoice_notes);
  const [updated, setUpdated] = useState(false);
  const [newContactName, setNewContactName] = useState("");
  const [newContactEmail, setNewContactEmail] = useState("");

  useEffect(() => {
    setContacts(props.contacts.filter((c) => c.invoicing));
  }, [props.contacts]);

  function activeIndexHandler(index) {
    if (index != activeIndex) {
      setActiveIndex(index);
    } else {
      setActiveIndex(-1);
    }
  }

  const handleNotes = (e) => {
    setNotes(e.target.value || "");
    setUpdated(true);
  };

  const handleUpdateInvoice = () => {
    props.setBackdropLoader(true);
    const project = {};

    if (notes !== props.project.invoice_notes) {
      project.invoice_notes = notes;
    }

    axios
      .patch(`/projects/${props.project.id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        project,
      })
      .then((res) => {
        M.toast({ html: "Invoicing Details Updated", classes: "green" });
        props.setBackdropLoader(false);
      })
      .catch((err) => {
        console.error(err);
        props.setBackdropLoader(false);
      });
  };
  const submit = () => {
    if (!newContactName || !newContactEmail) {
      M.toast({
        html: "<span aria-live='assertive'>Please enter all contact details</span>",
        duration: 3000,
        classes: "red",
      });
      return;
    }

    if (!validateEmail(newContactEmail)) {
      M.toast({
        html: "<span aria-live='assertive'>Email is not valid</span>",
        duration: 3000,
        classes: "red",
      });
      return;
    }

    const contact = {
      name: newContactName,
      email: newContactEmail,
      type: "invoicing",
    };

    props.addContactHandler(contact);
    setNewContactName("");
    setNewContactEmail("");
  };

  const handleNewContactInput = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setNewContactName(value);
    } else if (name === "email") {
      setNewContactEmail(value);
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
  function deleteHandler(contact) {
    setActiveIndex(-1);
    props.deleteContactHandler(contact);
  }

  return (
    <DropperContainer title="Invoicing Details">
      <div className="invoice-deets-container">
        <div style={{ paddingLeft: "10px", marginBottom: "25px" }}>
          <p
            style={{
              marginRight: "20px",
              fontFamily: "manrope",
              fontWeight: "600",
            }}
          >
            Invoice Contacts
          </p>
          <div
            style={{
              display: "flex",
              width: "100%",
              margin: "10px 0 20px",
              alignItems: "flex-end",
            }}
          >
            <div style={{ paddingRight: "15px" }}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                maxLength="19"
                style={{ margin: 0 }}
                placeholder="Enter a name"
                onChange={handleNewContactInput}
                className="browser-default text-input-v2"
                value={newContactName}
                autoComplete="off"
                name="name"
              />
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                style={{ margin: 0 }}
                onChange={handleNewContactInput}
                className="browser-default text-input-v2"
                autoComplete="off"
                placeholder="Enter a valid email address"
                value={newContactEmail}
                name="email"
                maxLength="50"
              />
            </div>
            <button
              style={{ marginLeft: "15px", whiteSpace: "nowrap" }}
              disabled={!newContactName || !newContactEmail}
              onClick={submit}
              className="common-button-submit"
            >
              Add Contact
            </button>
          </div>
          <ContactRowWrapper aria-label={`Client Contacts List`} role="list">
            {contacts.length > 0 ? (
              contacts.map((contact, index) => (
                <ContactCard
                  active={activeIndex === contact.id}
                  activeIndexHandler={activeIndexHandler}
                  submitHandler={props.submitHandler}
                  deleteContact={deleteHandler}
                  key={index}
                  contact={contact}
                />
              ))
            ) : (
              <p style={{ textAlign: "center", color: "#b9b9b9" }}>
                No Invoicing Contacts to Display
              </p>
            )}
          </ContactRowWrapper>
        </div>
        <div
          style={{
            marginLeft: "10px",
            borderTop: "solid 1px lightgray",
            paddingTop: "2px",
          }}
        >
          <p
            style={{
              marginBottom: "0px",
              fontFamily: "manrope",
              fontSize: "13px",
              fontWeight: "600",
            }}
          >
            Invoice Notes:
          </p>
          <div style={{ marginTop: "0px" }} className="input-field">
            <textarea
              className="browser-default text-input-v2"
              name="notes"
              type="text"
              value={notes}
              onChange={handleNotes}
              maxLength={2000}
              style={{
                padding: "5px 10px",
                minHeight: "100px !important",
                marginTop: "3px",
                height: "200px",
              }}
            />
          </div>
        </div>
        {updated && (
          <button
            onClick={handleUpdateInvoice}
            className="common-button-submit"
            style={{
              marginLeft: "auto",
              display: "block",
              marginBottom: "30px",
            }}
          >
            Save Invoicing Changes
          </button>
        )}
      </div>
    </DropperContainer>
  );
};

const ContactRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export default InvoiceDetails;
