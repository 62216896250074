import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const ClosureRow = ({ twoWeeks, name }) => {
  return (
    <ClosureCard aria-hidden className="task-card" twoWeeks={twoWeeks}>
      Office Closed - {name}
    </ClosureCard>
  );
};

const ClosureCard = styled.div`
  height: 40px;
  width: 100%;
  opacity: 1;
  pointer-events: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 1px #cccccc;
  margin: 10px 0px;
  cursor: default;
  position: relative;
  padding: 11px 10px 10px 10px;
  font-size: 13px;
  color: black;
  background-color: lightgray;
`;

ClosureRow.propTypes = {
  twoWeeks: PropTypes.bool,
  name: PropTypes.string,
};

export default ClosureRow;
