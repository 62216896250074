import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

const Link = styled.li`
  height: ;
`;

const RowForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`;

function ProjectLinks(props) {
  const [links, setLinks] = useState(props.links ? props.links : []);
  const [linkName, setLinkName] = useState("");
  const [linkLocation, setLinkLocation] = useState("");

  function addNewLink() {
    if (links.length >= 1) {
      var last = links[links.length - 1];
    } else {
      var last = { id: -1 };
    }
    const { project } = props;
    project.project_links.push({ id: last.id + 1, name: "", location: "" });
    props.projectUpdater(project);
    props.activeLinkHandler(last.id + 1);
  }

  useEffect(() => {
    if (props.activeLink != -1) {
      const active = props.links.find(
        (x) => x.id === parseInt(props.activeLink)
      );
      if (active) {
        setLinkName(active.name);
        setLinkLocation(active.location);
      }
    }
  }, [props.activeLink, props.links]);

  function handleLinkClick(e) {
    const { id } = e.target.dataset;
    props.activeLinkHandler(id);
  }

  function textHandler(e) {
    const { value } = e.target;
    if (e.target.name === "name") {
      setLinkName(value);
    } else {
      setLinkLocation(value);
    }
  }

  function submitLink(e) {
    e.preventDefault();
    if (linkName === "") {
      M.toast({ html: "Please provide a link name", classes: "error" });
      return false;
    }
    if (linkLocation === "") {
      M.toast({ html: "Please provide a link location", classes: "error" });
      return false;
    }
    axios
      .post(`/projects/${props.project.id}/project_links.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        project_link: {
          name: linkName,
          location: linkLocation,
          link_type: props.type,
        },
      })
      .then((res) => {
        props.projectUpdater(res.data);
        props.activeLinkHandler(0);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function deleteLink(e) {
    e.preventDefault();
    const { id } = e.target.dataset;
    axios
      .delete(`/projects/${props.project.id}/project_links/${id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        props.projectUpdater(res.data);
        props.activeLinkHandler(0);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <div>
      <ul>
        {props.links.map((link, index) => {
          if (props.activeLink === link.id) {
            return (
              <RowForm key={index}>
                <div style={{ flex: "1 1 100%" }}>
                  <input
                    name="name"
                    onChange={textHandler}
                    type="text"
                    style={{ marginRight: "20px", flex: 1 }}
                    placeholder="url name (Airbnb)"
                    className="browser-default text-input-v2"
                    value={linkName}
                  />
                </div>
                <div style={{ flex: "1 1 100%" }}>
                  <input
                    onChange={textHandler}
                    type="text"
                    placeholder="url location (www.airbnb.com)"
                    className="browser-default text-input-v2"
                    value={linkLocation}
                  />
                </div>
                <button
                  style={{ flex: 0.1 }}
                  onClick={submitLink}
                  className="btn-flat"
                  type="button"
                >
                  Save
                </button>
              </RowForm>
            );
          }
          return (
            <Link key={index} className="project-file-card">
              <div>
                <a
                  style={{ color: "#519acc" }}
                  href={
                    link.location.includes("http") ||
                    link.location.includes("https")
                      ? link.location
                      : `http://${link.location}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.name}
                </a>
              </div>
              <div>
                <button
                  type="button"
                  data-id={link.id}
                  onClick={deleteLink}
                  style={{
                    border: "1px solid lightgray",
                    cursor: "pointer",
                    borderRadius: "3px",
                    display: "flex",
                    width: "27px",
                    justifyContent: "center",
                    backgroundColor: "white",
                  }}
                >
                  <img
                    data-id={link.id}
                    alt="trash"
                    src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/syncit/Icon__trash_1.svg"
                    style={{ width: "20px", height: "20px" }}
                  />
                </button>
              </div>
            </Link>
          );
        })}
      </ul>
      {props.activeLink < 1 && (
        <button type="button" onClick={addNewLink} className="btn-flat">
          Add Link
        </button>
      )}
    </div>
  );
}

export default ProjectLinks;
