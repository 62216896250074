import React from "react";

const NewProjectIcon = () => {
  return (
    <svg
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page Title - Task Board">
        <path
          id="Vector"
          d="M1.08968 0.529419H5.85148C6.42076 0.529419 6.88232 1.09078 6.88232 1.54591V2.59702C6.88232 3.15838 6.42076 3.61351 5.85148 3.61351H1.08968C0.520398 3.61351 0.0588379 3.05215 0.0588379 2.59702V1.54591C0.0588379 0.984554 0.520398 0.529419 1.08968 0.529419Z"
          fill="white"
        />
        <path
          id="Vector_2"
          d="M9.61922 0.529419H14.381C14.9503 0.529419 15.4119 1.09078 15.4119 1.54591V2.59702C15.4119 3.15838 14.9503 3.61351 14.381 3.61351H9.61922C9.04994 3.61351 8.58838 3.05215 8.58838 2.59702V1.54591C8.58838 0.984554 9.04994 0.529419 9.61922 0.529419Z"
          fill="white"
        />
        <path
          id="Vector_3"
          d="M18.1485 0.529419H22.9103C23.4796 0.529419 23.9412 1.09078 23.9412 1.54591V2.59702C23.9412 3.15838 23.4796 3.61351 22.9103 3.61351H18.1485C17.5792 3.61351 17.1177 3.05215 17.1177 2.59702V1.54591C17.1177 0.984554 17.5792 0.529419 18.1485 0.529419Z"
          fill="white"
        />
        <path
          id="Vector_4"
          d="M1.08968 4.59436H5.85148C6.42076 4.59436 6.88232 5.15572 6.88232 5.61085V8.0634C6.88232 8.62475 6.42076 9.07989 5.85148 9.07989H1.08968C0.520398 9.07989 0.0588379 8.51853 0.0588379 8.0634V5.61085C0.0588379 5.0495 0.520398 4.59436 1.08968 4.59436Z"
          fill="white"
        />
        <path
          id="Vector_5"
          d="M9.61922 4.59436H14.381C14.9503 4.59436 15.4119 5.15572 15.4119 5.61085V8.0634C15.4119 8.62475 14.9503 9.07989 14.381 9.07989H9.61922C9.04994 9.07989 8.58838 8.51853 8.58838 8.0634V5.61085C8.58838 5.0495 9.04994 4.59436 9.61922 4.59436Z"
          fill="white"
        />
        <path
          id="Vector_6"
          d="M18.1485 4.59436H22.9103C23.4796 4.59436 23.9412 5.15572 23.9412 5.61085V8.0634C23.9412 8.62475 23.4796 9.07989 22.9103 9.07989H18.1485C17.5792 9.07989 17.1177 8.51853 17.1177 8.0634V5.61085C17.1177 5.0495 17.5792 4.59436 18.1485 4.59436Z"
          fill="white"
        />
        <path
          id="Vector_7"
          d="M1.08968 10.2018H5.85148C6.42076 10.2018 6.88232 10.7631 6.88232 11.2183V13.6708C6.88232 14.2322 6.42076 14.6873 5.85148 14.6873H1.08968C0.520398 14.6873 0.0588379 14.126 0.0588379 13.6708V11.2183C0.0588379 10.6569 0.520398 10.2018 1.08968 10.2018Z"
          fill="white"
        />
        <path
          id="Vector_8"
          d="M9.61922 10.2018H14.381C14.9503 10.2018 15.4119 10.7631 15.4119 11.2183V13.6708C15.4119 14.2322 14.9503 14.6873 14.381 14.6873H9.61922C9.04994 14.6873 8.58838 14.126 8.58838 13.6708V11.2183C8.58838 10.6569 9.04994 10.2018 9.61922 10.2018Z"
          fill="white"
        />
        <path
          id="Vector_9"
          d="M18.1485 10.2018H22.9103C23.4796 10.2018 23.9412 10.7631 23.9412 11.2183V13.6708C23.9412 14.2322 23.4796 14.6873 22.9103 14.6873H18.1485C17.5792 14.6873 17.1177 14.126 17.1177 13.6708V11.2183C17.1177 10.6569 17.5792 10.2018 18.1485 10.2018Z"
          fill="white"
        />
        <path
          id="Vector_10"
          d="M1.08968 15.8086H5.85148C6.42076 15.8086 6.88232 16.37 6.88232 16.8251V19.2776C6.88232 19.839 6.42076 20.2941 5.85148 20.2941H1.08968C0.520398 20.2941 0.0588379 19.7328 0.0588379 19.2776V16.8251C0.0588379 16.2637 0.520398 15.8086 1.08968 15.8086Z"
          fill="white"
        />
        <path
          id="Vector_11"
          d="M9.61922 15.8086H14.381C14.9503 15.8086 15.4119 16.37 15.4119 16.8251V19.2776C15.4119 19.839 14.9503 20.2941 14.381 20.2941H9.61922C9.04994 20.2941 8.58838 19.7328 8.58838 19.2776V16.8251C8.58838 16.2637 9.04994 15.8086 9.61922 15.8086Z"
          fill="white"
        />
        <path
          id="Vector_12"
          d="M18.1485 15.8086H22.9103C23.4796 15.8086 23.9412 16.37 23.9412 16.8251V19.2776C23.9412 19.839 23.4796 20.2941 22.9103 20.2941H18.1485C17.5792 20.2941 17.1177 19.7328 17.1177 19.2776V16.8251C17.1177 16.2637 17.5792 15.8086 18.1485 15.8086Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default NewProjectIcon;
