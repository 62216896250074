import React, { useState, useCallback } from "react";
import styled from "styled-components";
import moment from "moment";
import ms from "components/modals/ModalStyled";
import { StageForm } from "./lead_stage";
import SingleDateWrapper from "../../common/single_date_wrapper";

const StageCard = styled.div``;

const ProjectConfirmed = (props) => {
  const { project, submitHandler } = props;

  const [testStartDate, setTestStartDate] = useState(
    project.test_start_date !== "Unknown"
      ? moment(project.test_start_date)
      : undefined
  );
  const [startDateFocus, setStartDateFocus] = useState(false);

  const handleStartDateChange = useCallback((date) => {
    setTestStartDate(date);
  }, []);

  const handleStartDateFocus = useCallback((focused) => {
    setStartDateFocus(focused.focused);
  }, []);

  function save(e) {
    e.preventDefault();
    const projectData = {};

    if (testStartDate) {
      projectData.test_start_date = moment(testStartDate).format("YYYY-MM-DD");
    }
    submitHandler(projectData, "project_confirming", "testing", true);
  }

  return (
    <StageCard>
      <StageForm>
        <ms.FormRowDouble style={{ alignItems: "flex-end" }}>
          <ms.FormItem>
            <ms.Label className="custom-labels" htmlFor="test_start_date">
              Test Start Date
              <span className="base-color">*</span>
            </ms.Label>
            <SingleDateWrapper
              date={testStartDate}
              onDateChange={handleStartDateChange}
              focused={startDateFocus}
              onFocusChange={handleStartDateFocus}
              placeholder="Select a date"
              label={null}
            />
          </ms.FormItem>
          <ms.FormItem>
            <button
              type="button"
              onClick={save}
              className="common-button-submit"
            >
              Save All Changes
            </button>
          </ms.FormItem>
        </ms.FormRowDouble>
      </StageForm>
    </StageCard>
  );
};

export default ProjectConfirmed;
