import React from "react";
import styled from "styled-components";
import moment from "moment";
import MultiProgressRing from "../common/multi_progress_ring";
import TimeEntryRangeBox from "./time_entry_range_box";

const SidebarDiv = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: #00000014 0px 0px 4px 2px;
  width: 400px;
  height: calc(100vh - 130px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;

  > ul {
    width: 100%;
    border-top: solid 1px lightgray;
    border-bottom: solid 1px lightgray;
    padding: 20px 0px;
  }

  li {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    height: 30px;
  }

  li > p {
    font-size: 14px;
  }

  li > div {
    width: 15px;
    height: 15px;
    border: solid 1px lightgray;
    border-radius: 3px;
  }

  li > p:nth-child(2) {
    width: 240px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: auto;
    margin-left: 10px;
  }

  .data-section {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .data-section > div {
    width: 50%;
  }

  .data-section p {
    font-size: 13px;
    margin-bottom: 0px;
  }

  .data-section span {
    font-family: "Manrope";
    font-size: 20px;
    margin-top: 5px;
    display: block;
    font-weight: 500;
  }
`;

class TimeEntrySidebar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.floatyRef = React.createRef();
    this.showFloaty = this.showFloaty.bind(this);
    this.hideFloaty = this.hideFloaty.bind(this);
    this.canvas = document.createElement("canvas").getContext("2d");
    this.canvas.font = `13px Arial`;
  }

  showFloaty(e) {
    const { percent, name } = e.target.dataset;
    if (this.floatyRef.current) {
      const textWidth =
        this.canvas.measureText(`${name} - ${Math.round(parseInt(percent))}%`)
          .width + 25;
      const left =
        e.target.offsetLeft + e.target.clientWidth / 2 - textWidth / 2;
      // var floatyX = this.floatyRef.current.getBoundingClientRect()["x"]
      this.floatyRef.current.innerText = `${name} - ${Math.round(
        parseInt(percent)
      )}%`;
      this.floatyRef.current.style.opacity = "1";
      this.floatyRef.current.style.left = `${left + 30}px`;
    }
  }

  hideFloaty(e) {
    if (this.floatyRef.current) {
      this.floatyRef.current.style.opacity = "0";
    }
  }

  render() {
    const groups = Object.entries(this.props.groupHours).sort((a, b) =>
      a[1].name > b[1].name ? 1 : a[1].name < b[1].name ? -1 : 0
    );
    if (this.props.secondGroup) {
      var secondGroups = Object.entries(this.props.secondGroup).sort((a, b) =>
        a[1].name > b[1].name ? 1 : a[1].name < b[1].name ? -1 : 0
      );
    }
    let totalNumber = 0;
    groups.forEach((p) => (totalNumber += p[1].hours));

    return (
      <div id="time-entry-sidebar">
        <div
          ref={this.floatyRef}
          id="floaty"
          style={{ top: "14px", zIndex: "100" }}
          className="invoice-floaty"
        >
          <div className="floaty-point" />
        </div>
        <section aria-label="Time entry week stats" className="top-section">
          <div
            style={{
              maxHeight:
                this.props.page === "time-entry"
                  ? `calc(100vh - ${
                      this.props.userSickPto.length > 1 ? 456 : 404
                    }px)`
                  : "calc(100vh - 311px)",
            }}
          >
            {this.props.page === "time-entry" ? (
              <div
                style={{
                  width: "100%",
                  alignItems: "center",
                  position: "relative",
                  margin: `10px 0 ${groups.length > 0 ? "30px" : "15px"}`,
                }}
              >
                <h3
                  style={{
                    fontFamily: "Arial",
                    fontSize: "16px",
                    textAlign: "center",
                    margin: "0 0 10px",
                  }}
                >
                  <b style={{ fontSize: "22px", fontFamily: "Manrope" }}>
                    {totalNumber.toFixed(2)}{" "}
                  </b>
                  Hours Worked
                </h3>
                <div
                  style={{
                    width: "100%",
                    height: "28px",
                    borderRadius: "10px",
                    overflow: "hidden",
                    display: "flex",
                    flexWrap: "nowrap",
                    backgroundColor: "lightgray",
                  }}
                >
                  {groups.map((p, index) => {
                    const percent =
                      (p[1].hours / (totalNumber > 40 ? totalNumber : 40)) *
                      100;
                    return (
                      <div
                        key={p[1].name}
                        onMouseEnter={this.showFloaty}
                        onMouseLeave={this.hideFloaty}
                        data-percent={percent}
                        data-name={p[1].name}
                        style={{
                          backgroundColor: p[1].color,
                          width: `${percent}%`,
                          transition: "0.3s width",
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            ) : (
              <MultiProgressRing
                text={<p>{this.props.text}</p>}
                totalNumber={totalNumber}
                totalLimit={this.props.week ? 40 : Infinity}
                multiProgress={groups.map((p) => {
                  return {
                    color: p[1].color,
                    percent:
                      (p[1].hours /
                        (this.props.week === false ? totalNumber : 40)) *
                      100,
                    dateRange: p[1].date_range,
                    name: p[1].name,
                  };
                })}
              />
            )}

            {this.props.secondGroup &&
              secondGroups &&
              secondGroups.length > 0 && (
                <ul
                  className="billing"
                  style={{
                    border: "none",
                    marginBottom: "20px",
                    marginTop: "0px",
                    textAlign: "center",
                  }}
                  aria-label="Project break down"
                >
                  {secondGroups.map((p) => {
                    return (
                      <li
                        role="text"
                        aria-label={`Project ${p[1].name}, ${p[1].hours} billable amount`}
                        key={p[0]}
                      >
                        <p aria-hidden="true">Billable Amount</p>
                        <p
                          style={{ width: "100%", textAlign: "center" }}
                          aria-hidden="true"
                        >
                          {p[1].hours === "$0.00" ? "N/A" : p[1].hours}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              )}
            {groups.length > 0 && (
              <ul
                className="nonBilling"
                style={{ paddingBottom: "0" }}
                aria-label="Project break down"
              >
                {groups.map((p) => {
                  return (
                    <li
                      role="text"
                      aria-label={`Project ${p[1].name}, ${p[1].hours.toFixed(
                        2
                      )} hours`}
                      key={p[0]}
                    >
                      <div style={{ backgroundColor: p[1].color }} />
                      <p aria-hidden="true">{p[1].name}</p>
                      <p aria-hidden="true" style={{ fontWeight: 700 }}>
                        {this.props.percent && this.props.percent === true
                          ? `${(
                              (p[1].hours /
                                (totalNumber > 40 ? totalNumber : 40)) *
                              100
                            ).toFixed(2)} %`
                          : `${p[1].hours.toFixed(2)} hrs`}
                      </p>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </section>

        {this.props.activeObjectName !== "user" ? (
          <section
            aria-label="User time entry and PTO stats"
            style={{
              height:
                this.props.page === "time-entry"
                  ? this.props.userSickPto.length > 1
                    ? "296px"
                    : "244px"
                  : "150px",
            }}
            className="bottom-section"
          >
            <div role="list">
              {this.props.dataSection.map((u, index) => {
                const today = moment(new Date());
                const urlArray = [
                  `?start_date=${today.format(
                    "YYYY-MM-DD"
                  )}&end_date=${today.format("YYYY-MM-DD")}`,
                  `?start_date=${today
                    .subtract(1, "days")
                    .format("YYYY-MM-DD")}&end_date=${today.format(
                    "YYYY-MM-DD"
                  )}`,
                  `?start_date=${today
                    .startOf("month")
                    .format("YYYY-MM-DD")}&end_date=${today
                    .endOf("month")
                    .format("YYYY-MM-DD")}`,
                  `?start_date=${today
                    .subtract(1, "months")
                    .startOf("month")
                    .format("YYYY-MM-DD")}&end_date=${today
                    .endOf("month")
                    .format("YYYY-MM-DD")}`,
                ];
                if (this.props.userSickPto) {
                  return (
                    <div
                      style={{ paddingLeft: `${index % 2 != 0 ? "60px" : ""}` }}
                      key={`${u.label}`}
                      role="text"
                      aria-label={`${u.label} ${u.value.toFixed(2)} hours`}
                    >
                      <p>
                        {u.label}
                        <br />
                        <span>{u.value.toFixed(2)}</span>
                      </p>
                    </div>
                  );
                }
                return (
                  <a
                    style={{ paddingLeft: `${index % 2 != 0 ? "60px" : ""}` }}
                    className={`${
                      index >= 2 ? "" : "flex-al-start"
                    } flex-container`}
                    key={u.label}
                    href={urlArray[index]}
                  >
                    <div
                      role="text"
                      aria-label={`${u.label} ${u.value.toFixed(2)} hours`}
                    >
                      <p>
                        {u.label}
                        <br />
                        <span>{u.value.toFixed(2)}</span>
                      </p>
                    </div>
                  </a>
                );
              })}
            </div>
            {this.props.userSickPto && (
              <div
                style={{
                  padding: "20px",
                  margin: "0 10px",
                  borderTop: "solid 1px lightgray",
                }}
              >
                {this.props.userSickPto.map((u, i) => {
                  return (
                    <div
                      style={{ marginTop: i > 0 ? "13px" : "0px" }}
                      role="text"
                      aria-label={`${u.label}, ${u.value.toFixed(
                        2
                      )} hours used, ${(u.total - u.value).toFixed(
                        2
                      )} hours available`}
                      key={u.label}
                    >
                      <p aria-hidden="true">{u.label}</p>
                      <div
                        aria-hidden="true"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginTop: "5px",
                        }}
                      >
                        <span
                          style={{
                            width: " 35px",
                            whiteSpace: "nowrap",
                            fontSize: "14px",
                            marginTop: "0px",
                          }}
                        >
                          {u.value.toFixed(2)}
                        </span>
                        <div
                          style={{
                            width: "220px",
                            height: "20px",
                            backgroundColor: "lightgray",
                            position: "relative",
                            borderRadius: "3px",
                            overflow: "hidden",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              left: "0px",
                              top: "0px",
                              width: `${(u.value / u.total) * 100}%`,
                              height: "20px",
                              backgroundColor: "#519acc",
                            }}
                          />
                        </div>
                        <span
                          aria-hidden="true"
                          style={{
                            width: " 35px",
                            whiteSpace: "nowrap",
                            fontSize: "14px",
                            marginTop: "0px",
                          }}
                        >
                          {(u.total - u.value).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {this.props.eodButton && (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "30px",
                }}
              >
                <button className="common-button-submit">
                  Send EOD Report
                </button>
              </div>
            )}
          </section>
        ) : (
          <TimeEntryRangeBox
            selectedDateRangeOption={this.props.selectedDateRangeOption}
            totalNumber={totalNumber}
            groupHours={this.props.groupHours}
          />
        )}
      </div>
    );
  }
}

TimeEntrySidebar.defaultProps = {
  dataSection: [],
};

export default TimeEntrySidebar;
