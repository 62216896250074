import React from "react";
import styled, { css } from "styled-components";

const NumberCounter = (props) => {
  const { value, step, min, max, disabled, updateValue } = props;

  const onChange = (e) => {
    if (
      Number.isNaN(e.target.value) ||
      parseInt(e.target.value, 10) > max ||
      parseInt(e.target.value, 10) < min
    ) {
      e.preventDefault();
    } else {
      updateValue(parseInt(e.target.value, 10));
    }
  };

  const incrementValue = () => {
    if (value + step <= max) {
      updateValue(value + step);
    }
  };

  const decrementValue = () => {
    if (value - step >= min) {
      updateValue(value - step);
    }
  };

  const onBlur = () => {
    if (!value) {
      updateValue(min);
    }
  };

  const highlightText = (e) => {
    e.target.select();
  };

  return (
    <CounterContainer>
      <DecrementButton
        type="button"
        className="number-counter-button"
        disabled={disabled || value <= min}
        onClick={decrementValue}
        $fade={value <= min}
      >
        -
      </DecrementButton>
      <ValueInput
        className="browser-default"
        disabled={disabled}
        onFocus={highlightText}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        min={min}
        max={max}
        step={step}
        type="number"
      />
      <IncrementButton
        type="button"
        className="number-counter-button"
        disabled={disabled || value >= max}
        onClick={incrementValue}
        $fade={value >= max}
      >
        +
      </IncrementButton>
    </CounterContainer>
  );
};

const buttonCommon = css`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
`;

const CounterContainer = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  gap: 4px;
  text-align: center;

  .hide-focus:focus {
    box-shadow: none !important;
    outline: none !important;
  }

  & > button.number-counter-button:focus {
    outline: none;
  }
`;

const ValueInput = styled.input`
  ${buttonCommon}

  width: 35px;
  height: 35px;
  border: 1px solid #d7d7d7;
  border-color: #d7d7d7;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Manrope";
  text-align: center;
`;

const HandleButton = styled.button`
  ${buttonCommon}

  cursor: pointer;
  outline: none;
  font-size: 18px;
  border-width: 1px;
  border-style: solid;
  transition-property: opacity, border-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;

  ${(props) =>
    props.$fade &&
    `
    opacity: 0.2; 
  `}

  &:hover {
    border-color: ${(props) => (props.$fade ? "#d7d7d7" : "#519acc")};
    color: #519acc;
    background-color: #ffffff;
  }

  &:not(:hover) {
    border-color: #d7d7d7;
    color: #519acc;
    background-color: #ffffff;
  }
`;

const IncrementButton = styled(HandleButton)``;

const DecrementButton = styled(HandleButton)``;

NumberCounter.defaultProps = {
  min: 1,
  max: 8,
  step: 1,
};

export default NumberCounter;
