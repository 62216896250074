import React from "react";

const project_status_board_icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="26"
      viewBox="0 0 31 26"
      fill="none"
    >
      <path
        d="M1.33806 0H7.51902C8.25796 0 8.85708 0.738452 8.85708 1.33717V2.71988C8.85708 3.45833 8.25796 4.05705 7.51902 4.05705H1.33806C0.599119 4.05705 0 3.3186 0 2.71988V1.33717C0 0.59872 0.599119 0 1.33806 0Z"
        fill="url(#paint0_linear_2033_4860)"
      />
      <path
        d="M12.4097 0H18.5906C19.3296 0 19.9287 0.738452 19.9287 1.33717V2.71988C19.9287 3.45833 19.3296 4.05705 18.5906 4.05705H12.4097C11.6707 4.05705 11.0716 3.3186 11.0716 2.71988V1.33717C11.0716 0.59872 11.6707 0 12.4097 0Z"
        fill="url(#paint1_linear_2033_4860)"
      />
      <path
        d="M23.481 0H29.662C30.4009 0 31 0.738452 31 1.33717V2.71988C31 3.45833 30.4009 4.05705 29.662 4.05705H23.481C22.7421 4.05705 22.1429 3.3186 22.1429 2.71988V1.33717C22.1429 0.59872 22.7421 0 23.481 0Z"
        fill="url(#paint2_linear_2033_4860)"
      />
      <path
        d="M1.33806 5.34741H7.51902C8.25796 5.34741 8.85708 6.08586 8.85708 6.68458V9.91085C8.85708 10.6493 8.25796 11.248 7.51902 11.248H1.33806C0.599119 11.248 0 10.5096 0 9.91085V6.68458C0 5.94613 0.599119 5.34741 1.33806 5.34741Z"
        fill="url(#paint3_linear_2033_4860)"
      />
      <path
        d="M12.4097 5.34741H18.5906C19.3296 5.34741 19.9287 6.08586 19.9287 6.68458V9.91085C19.9287 10.6493 19.3296 11.248 18.5906 11.248H12.4097C11.6707 11.248 11.0716 10.5096 11.0716 9.91085V6.68458C11.0716 5.94613 11.6707 5.34741 12.4097 5.34741Z"
        fill="url(#paint4_linear_2033_4860)"
      />
      <path
        d="M23.481 5.34741H29.662C30.4009 5.34741 31 6.08586 31 6.68458V9.91085C31 10.6493 30.4009 11.248 29.662 11.248H23.481C22.7421 11.248 22.1429 10.5096 22.1429 9.91085V6.68458C22.1429 5.94613 22.7421 5.34741 23.481 5.34741Z"
        fill="url(#paint5_linear_2033_4860)"
      />
      <path
        d="M1.33806 12.7239H7.51902C8.25796 12.7239 8.85708 13.4623 8.85708 14.061V17.2873C8.85708 18.0258 8.25796 18.6245 7.51902 18.6245H1.33806C0.599119 18.6245 0 17.886 0 17.2873V14.061C0 13.3226 0.599119 12.7239 1.33806 12.7239Z"
        fill="url(#paint6_linear_2033_4860)"
      />
      <path
        d="M12.4097 12.7239H18.5906C19.3296 12.7239 19.9287 13.4623 19.9287 14.061V17.2873C19.9287 18.0258 19.3296 18.6245 18.5906 18.6245H12.4097C11.6707 18.6245 11.0716 17.886 11.0716 17.2873V14.061C11.0716 13.3226 11.6707 12.7239 12.4097 12.7239Z"
        fill="url(#paint7_linear_2033_4860)"
      />
      <path
        d="M23.481 12.7239H29.662C30.4009 12.7239 31 13.4623 31 14.061V17.2873C31 18.0258 30.4009 18.6245 29.662 18.6245H23.481C22.7421 18.6245 22.1429 17.886 22.1429 17.2873V14.061C22.1429 13.3226 22.7421 12.7239 23.481 12.7239Z"
        fill="url(#paint8_linear_2033_4860)"
      />
      <path
        d="M1.33806 20.0994H7.51902C8.25796 20.0994 8.85708 20.8378 8.85708 21.4365V24.6628C8.85708 25.4013 8.25796 26 7.51902 26H1.33806C0.599119 26 0 25.2615 0 24.6628V21.4365C0 20.6981 0.599119 20.0994 1.33806 20.0994Z"
        fill="url(#paint9_linear_2033_4860)"
      />
      <path
        d="M12.4097 20.0994H18.5906C19.3296 20.0994 19.9287 20.8378 19.9287 21.4365V24.6628C19.9287 25.4013 19.3296 26 18.5906 26H12.4097C11.6707 26 11.0716 25.2615 11.0716 24.6628V21.4365C11.0716 20.6981 11.6707 20.0994 12.4097 20.0994Z"
        fill="url(#paint10_linear_2033_4860)"
      />
      <path
        d="M23.481 20.0994H29.662C30.4009 20.0994 31 20.8378 31 21.4365V24.6628C31 25.4013 30.4009 26 29.662 26H23.481C22.7421 26 22.1429 25.2615 22.1429 24.6628V21.4365C22.1429 20.6981 22.7421 20.0994 23.481 20.0994Z"
        fill="url(#paint11_linear_2033_4860)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2033_4860"
          x1="4.42854"
          y1="0"
          x2="4.42854"
          y2="4.05705"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2033_4860"
          x1="15.5001"
          y1="0"
          x2="15.5001"
          y2="4.05705"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2033_4860"
          x1="26.5715"
          y1="0"
          x2="26.5715"
          y2="4.05705"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2033_4860"
          x1="4.42854"
          y1="5.34741"
          x2="4.42854"
          y2="11.248"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2033_4860"
          x1="15.5001"
          y1="5.34741"
          x2="15.5001"
          y2="11.248"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2033_4860"
          x1="26.5715"
          y1="5.34741"
          x2="26.5715"
          y2="11.248"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2033_4860"
          x1="4.42854"
          y1="12.7239"
          x2="4.42854"
          y2="18.6245"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2033_4860"
          x1="15.5001"
          y1="12.7239"
          x2="15.5001"
          y2="18.6245"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_2033_4860"
          x1="26.5715"
          y1="12.7239"
          x2="26.5715"
          y2="18.6245"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_2033_4860"
          x1="4.42854"
          y1="20.0994"
          x2="4.42854"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_2033_4860"
          x1="15.5001"
          y1="20.0994"
          x2="15.5001"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_2033_4860"
          x1="26.5715"
          y1="20.0994"
          x2="26.5715"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default project_status_board_icon;
