import React from "react";
import SvgShell from "./svg_shell";

function ClockIcon({ height, width, svgStyles, color }) {
  return (
    <SvgShell height={height} width={width} svgStyles={svgStyles} color={color}>
      <path d="M32,6.45A25.55,25.55,0,1,0,57.55,32V32A25.57,25.57,0,0,0,32,6.45m0,47.47A21.92,21.92,0,1,1,53.94,32,21.92,21.92,0,0,1,32,53.92h0" />
      <path d="M34.2,31.59V16.14a2.19,2.19,0,0,0-4.38,0V32.48a2.24,2.24,0,0,0,.15.81h0a2.07,2.07,0,0,0,.48.72l6.92,6.92a2.18,2.18,0,0,0,3.07-3.06Z" />
    </SvgShell>
  );
}

ClockIcon.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "#519acc",
};

export default ClockIcon;
