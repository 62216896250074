import React from "react";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import TagSelectStyles from "./tag_select_styles";

class TagsSelectWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.customControl = (props) => {
      return (
        <div>
          <p className="custom-select-input">{props.selectProps.inputValue}</p>
          <components.Control {...props} />
        </div>
      );
    };
    this.labelInput = (props) => <components.Input {...props} maxLength={20} />;
    this.DropdownIndicator = function (props) {
      return (
        <components.DropdownIndicator {...props}>
          <i
            style={{ color: "inherit" }}
            className="material-icons base-color-hover"
          >
            search
          </i>
        </components.DropdownIndicator>
      );
    };
  }

  formatCreateLabel(inputValue) {
    return (
      <div className="no-option-msg">
        <p>
          No tags found for "
          {inputValue}
          "
        </p>
        <p className="base-color">Create New Tag</p>
      </div>
    );
  }

  render() {
    return (
      <CreatableSelect
        classNamePrefix="react-multi-select"
        isMulti
        isClearable={false}
        name="tags"
        menuPlacement="auto"
        placeholder="Search for or create a tag"
        components={{
          Control: this.customControl,
          Input: this.labelInput,
          DropdownIndicator: this.DropdownIndicator,
        }}
        aria-label="Tag dropdown for edit project form"
        closeMenuOnSelect={false}
        options={this.props.options}
        onChange={this.props.onChange}
        value={this.props.value}
        styles={TagSelectStyles}
        formatCreateLabel={this.formatCreateLabel}
      />
    );
  }
}

export default TagsSelectWrapper;
