import React from "react";
import PropTypes from "prop-types";
import RoundDropdown from "../projects/round_dropdown";
import ReactModal from "../modals/react_modal";

class SingleCheckboxMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      modalOpen: false,
      currentModal: "",
      modalData: {},
      active: this.props.active,
    };
    //transition speed is an int
    this.container = React.createRef();
    this.modalRef = React.createRef();
    this.loading = false;
    this.randomNum = 0;
    this.handleOutsideClick = (e) => {
      var self = this;
      if (
        self.modalRef &&
        self.modalRef.current &&
        self.modalRef.current.contains(e.target)
      ) {
        //don't close clicked on modal
      } else if (
        e.target.classList.value.includes("react-multi-select__option")
      ) {
        //don't close clicked on react select option
      } else if (
        e.target.classList &&
        e.target.classList.value === "btn-icon"
      ) {
        // don't close clicked on modal X button
      } else if (
        e.target.classList &&
        e.target.classList.value === "react-multi-select__multi-value__remove"
      ) {
        // don't close clicked on weird little empty space next to x
      } else if (
        e.target.nodeName &&
        e.target.nodeName === "path" &&
        self.container &&
        self.container.current &&
        self.container.current.contains(e.target)
      ) {
        // don't close clicked on modal X svg
      } else if (
        e.target.nodeName &&
        e.target.nodeName === "svg" &&
        self.container &&
        self.container.current &&
        self.container.current.contains(e.target)
      ) {
        // don't close clicked on modal X svg, sometimes it's like this too
      } else {
        this.setOpen();
      }
    };
    this.setOpen = this.setOpen.bind(this);
  }

  setModal = (bool, page, data) => {
    this.setState({ modalOpen: bool, currentModal: page, modalData: data });
  };

  onOpenClick = () => {
    this.setModal(true, this.props.modalButton.name, {
      filterOptions: [],
      async: true,
      type: this.props.modalButton.type,
      closeButton: true,
      customClass: "sm-modal",
      currentUser: this.props.modalButton.currentUser,
      handler: this.props.modalButton.handler,
      fastOpen: this.setFastOpen,
      backdropClickCheck: {
        title: "Discard New Filter",
        text: "Are you sure that you want to discard this filter?",
      },
    });
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.options.length != prevProps.options.length &&
      this.state.open
    ) {
      var elem = document.getElementById(
        `custom-checkbox-menu-${this.props.id}`
      );
      if (elem) {
        var elems = elem.getElementsByClassName("user-option");
        var last_elem = elems[elems.length - 1];
        if (last_elem) {
          last_elem.style.transform = "translateX(0px)";
          last_elem.style.opacity = "1";
        }
      }
    }
  };

  setOpen() {
    if (!this.loading) {
      this.loading = true;
      if (!this.state.open === true) {
        window.addEventListener("click", this.handleOutsideClick);
        this.setState(
          {
            open: true,
          },
          () => {
            var elem = document.getElementById(
              `custom-checkbox-menu-${this.props.id}`
            );
            if (elem) {
              setTimeout(() => {
                elem.style.maxHeight = this.props.maxHeight
                  ? this.props.maxHeight
                  : this.props.modalButton
                  ? "240px"
                  : "200px";
                elem.style.overflowY = "hidden";
                var optionElems =
                  document.getElementsByClassName("user-option");
                for (let i = 0; i < optionElems.length; i++) {
                  var elm = optionElems[i];
                  if (i < 6) {
                    var math = i * 0.1;
                    elm.style.transition = `transform .2s ease ${math}s, opacity .2s ease ${math}s`;
                  }
                  elm.style.transform = "translateX(0px)";
                  elm.style.opacity = "1";
                }
              }, 50);
              setTimeout(() => {
                elem.style.transitionDelay = "0.2s";
                this.loading = false;
              }, 450);
            }
          }
        );
      } else {
        window.removeEventListener("click", this.handleOutsideClick);
        var elem = document.getElementById(
          `custom-checkbox-menu-${this.props.id}`
        );
        if (elem) {
          elem.style.maxHeight = "0px";
          var optionElems = document.getElementsByClassName("user-option");
          for (let i = 0; i < optionElems.length; i++) {
            var elm = optionElems[i];
            if (i < 6) {
              var math = i * 0.11;
              elm.style.transition = `transform .4s ease ${math}s, opacity .4s ease ${math}s`;
            }
            elm.style.transform = "translateX(-100px)";
            elm.style.opacity = "0";
          }
          setTimeout(() => {
            this.setState(
              {
                open: false,
              },
              () => {
                this.loading = false;
              }
            );
          }, 450);
        }
      }
    }
  }

  render() {
    var active = false;
    if (this.props.options.filter((x) => x.checked).length >= 1) {
      active = this.props.options.filter((x) => x.checked)[0];
    }
    return (
      <div style={{ position: "relative" }}>
        <div
          ref={this.container}
          key={`checkbox_menu_container_${this.props.id}`}
          className="custom-checkbox-menu-container"
          style={{
            position: "relative",
            flex: 1,
            minWidth: "175px",
          }}
        >
          <div
            className={`custom-checkbox-menu-selector${
              this.state.open ? " open" : " closed"
            }`}
            aria-label="Open Filter Menu"
            onClick={this.setOpen}
            style={{
              height: "35px",
              color: "initial",
              paddingLeft: "10px",
              paddingRight: "10px",
              cursor: "pointer",
              borderColor: this.props.active ? "#519acc" : "",
            }}
          >
            <span
              style={{
                fontSize: "13px",
                lineHeight: "33px",
                whiteSpace: "nowrap",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                height: "35px",
              }}
            >
              <span
                style={{
                  padding: "10px",
                }}
              >
                {active
                  ? `${active.label} ${this.props.objectTitle} (${active.count})`
                  : this.props.title}
              </span>
              <RoundDropdown />
            </span>
          </div>
          {this.state.open && (
            <div
              className="custom-checkbox-menu"
              role="listbox"
              id={`custom-checkbox-menu-${this.props.id}`}
              style={{
                position: "absolute",
                zIndex: "200",
                maxHeight: this.props.modalButton ? "240px" : "200px",
                overflowX: "hidden",
                overflowY: "hidden",
                transition: `max-height 0.5s`,
                right: this.props.width ? "0px" : "0px",
                top: "110%",
                padding: "0px 15px",
                width: this.props.width ? this.props.width : "100%",
              }}
            >
              <div
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  maxHeight: Array.isArray(this.props.options)
                    ? "190px"
                    : "165px",
                  marginBottom: Array.isArray(this.props.options) ? "5px" : 0,
                  width: "110%",
                  marginLeft: "-5px",
                  marginTop: "5px",
                }}
                className="new-scrollbar"
              >
                {Array.isArray(this.props.options)
                  ? this.props.options.map((option, index) => {
                      return (
                        <div
                          role="option"
                          style={{
                            marginTop: index === 0 ? "10px" : "0px",
                            marginBottom:
                              index === this.props.options.length - 1
                                ? "10px"
                                : "0px",
                            transform: "translateX(-14px)",
                            opacity: "0",
                            padding: "5px",
                            transition: `transform 0.2s, opacity 0.2s`,
                          }}
                          key={index}
                          className={option.classList}
                        >
                          <label>
                            <input
                              style={{ visibility: "hidden" }}
                              onChange={option.handler}
                              name={this.props.name}
                              className="hidden"
                              checked={option.checked}
                              value={option.value}
                              type="checkbox"
                            />
                            <span
                              style={{
                                color: `${
                                  option.checked ? "#519acc" : "#313131"
                                }`,
                                paddingLeft: 0,
                                whiteSpace: "nowrap",
                                height: "100%",
                                width: "100%",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                fontSize: "14px",
                              }}
                            >
                              {option.label} {this.props.objectTitle} (
                              {option.count})
                            </span>
                          </label>
                        </div>
                      );
                    })
                  : this.props.options["groups"].map((group, index) => {
                      return (
                        <div className={group.classList} key={index}>
                          <p style={{ fontSize: "14px" }}>{group.name}</p>
                          {group.options.map((option, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  marginTop: index === 0 ? "10px" : "0px",
                                  marginBottom:
                                    index === this.props.options.length - 1
                                      ? "10px"
                                      : "0px",
                                  transform: "translateX(-14px)",
                                  opacity: "0",
                                  padding: "5px",
                                  transition: `transform 0.2s, opacity 0.2s`,
                                }}
                                className={group.classList}
                              >
                                <label>
                                  <input
                                    onChange={group.handler}
                                    className="filled-in"
                                    name={this.props.name}
                                    checked={option.checked}
                                    value={option.id}
                                    type="checkbox"
                                  />
                                  <span
                                    style={{
                                      whiteSpace: "nowrap",
                                      height: "100%",
                                      width: "100%",
                                      paddingLeft: "30px",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      fontSize: "14px",
                                      color: "#313131",
                                    }}
                                  >
                                    {option.name}
                                  </span>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
              </div>
              {this.props.modalButton && (
                <div
                  style={{
                    margin: "10px 0",
                    background: "#ffffffba",
                    backdropFilter: "blur(3px)",
                    width: "190px",
                  }}
                >
                  <ReactModal
                    modalRef={this.modalRef}
                    isShowing={this.state.modalOpen}
                    page={this.state.currentModal}
                    data={this.state.modalData}
                    modalAction={this.setModal}
                  />
                  <button
                    style={{ fontSize: "14px" }}
                    className="btn-flat"
                    onClick={this.onOpenClick}
                  >
                    {this.props.modalButton.text}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SingleCheckboxMenu;
