import React from "react";
import ClosureCard from "./closure_card.jsx";

class AlphabetClosureRow extends React.Component {
  constructor(props) {
    super(props);

    this.renderClosures = this.renderClosures.bind(this);
  }

  renderClosures() {
    const { closures } = this.props;
    const closureNode = [];
    for (let i = 0; i < closures.length; i++) {
      closureNode.push(
        <ClosureCard
          closureName={closures[i].name}
          closureStart={closures[i].start_date}
          closureEnd={closures[i].end_date}
          modalAction={this.props.modalAction}
          closurePath={closures[i].id}
          key={i}
        />
      );
    }
    return <div>{closureNode}</div>;
  }

  render() {
    const { index } = this.props;
    return (
      <div>
        <h4 className="open-sans bold ">{index}</h4>
        <div className="divider" />
        <div className="row">{this.renderClosures()}</div>
      </div>
    );
  }
}

export default AlphabetClosureRow;
