import React from "react";
import SvgShell from "./svg_shell";

function HelpIcon() {
  return (
    <svg
      width="28"
      height="31"
      viewBox="0 0 28 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      ,
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 24.8H4.68533C3.96667 24.8186 3.11111 25.1023 3.11111 26.35C3.11111 27.5978 3.96667 27.8814 4.68533 27.9H28V31H4.66667C2.79067 31 0 29.7616 0 26.35V4.65C0 1.23845 2.79067 0 4.66667 0H24.8889C25.714 0 26.5053 0.326606 27.0888 0.907969C27.6722 1.48933 28 2.27783 28 3.1V24.8ZM7.93044 6.13124C9.36579 4.18904 11.194 3.1 14.0167 3.1C17.0183 3.1 20.2223 5.30638 20.2222 8.215C20.2222 10.6171 18.5088 11.5482 17.2132 12.2523C16.4256 12.6803 15.7924 13.0244 15.7924 13.564V13.7563C15.7924 14.1977 15.4124 14.5555 14.9437 14.5555H12.3809C11.9121 14.5555 11.5322 14.1977 11.5322 13.7563V13.4303C11.5322 11.4206 13.1114 10.5884 14.3519 9.93471C14.3823 9.91868 14.4125 9.90276 14.4425 9.88694C15.5167 9.31982 16.175 8.93413 16.175 8.18313C16.175 7.18971 14.8294 6.53038 13.7415 6.53038C12.3579 6.53038 11.7008 7.13206 10.815 8.17707C10.5278 8.51588 10.0059 8.57712 9.63904 8.3152L8.11355 7.22597C7.75034 6.96662 7.67006 6.48356 7.93044 6.13124ZM11.2127 17.8433C11.2127 16.5713 12.3116 15.5365 13.6623 15.5365C15.013 15.5365 16.1119 16.5713 16.1119 17.8432C16.1119 19.1152 15.013 20.15 13.6623 20.15C12.3116 20.15 11.2127 19.1152 11.2127 17.8433Z"
        fill="url(#paint0_linear_3026_3521)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3026_3521"
          x1="14"
          y1="0"
          x2="14"
          y2="31"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default HelpIcon;
