import React from "react";

class Help extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="row modal-content" id="help-container">
        <div className="col m12 l12">
          <div className="center">
            <img
              src="/images/syncit-text-logo.png"
              className="responsive-img"
            />
          </div>
          <h1 className="center">User Guide</h1>
          {/* <div className="center">
            <a className="common-button-submit white-text" id="expand_all"><i className="material-icons left">fullscreen</i>Expand All</a>
            <a className="common-button-submit white-text" id="collapse_all"><i className="material-icons left">fullscreen_exit</i>Collapse All</a>
          </div> */}
          <ul className="">
            <li>
              <div className="-header">
                <i className="material-icons">filter_drama</i>
                Intro
              </div>
              <div className="-body">
                <p>
                  Welcome to SyncIt! SyncIt is a desktop web program that allows
                  PLUS QA employees to view their schedules and enables project
                  managers and leads to book employees on projects. To learn
                  more about how SyncIt works, click on a section below.
                </p>
              </div>
            </li>
            <li>
              <div className="-header">
                <i className="material-icons">lock_open</i>
                Logging In
              </div>
              <div className="-body">
                <p>
                  Access to SyncIt is limited to your @plusqa.com email address.
                  Sign-in will be handled by GoogleAuth, so any 2-factor
                  authentication rules on your account will be present during
                  sign-in.
                </p>
                <div>
                  <img
                    src="/images/login-screen.png"
                    className="responsive-img"
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="-header">
                <i className="material-icons">home</i>
                Dashboard (Leads, PMs and
                Admins)
              </div>
              <div className="-body">
                <p>
                  The dashboard for leads, PMs and admins shows every active
                  project the user is added to under "My Projects". "My
                  Schedule" below that shows the user's personal schedule.
                </p>
                <div>
                  <img
                    src="/images/pm-dashboard.png"
                    className="responsive-img"
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="-header">
                <i className="material-icons">home</i>
                Dashboard (All Other
                Users)
              </div>
              <div className="-body">
                <p>
                  The tester dashboard shows a user's schedule. You can see
                  which projects you are booked for in a given week using the
                  date navigation. Booking confirmation will be shown for each
                  booking with following color code: gray (unconfirmed), green
                  (available) or red (unavailable).
                </p>
                <div>
                  <img
                    src="/images/tester-dashboard.png"
                    className="responsive-img"
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="-header">
                <i className="material-icons">account_circle</i>
                Account
              </div>
              <div className="-body">
                <p>
                  Here you can edit your name, email and user profile.
                  Optionally you can add a secondary email address for receiving
                  email notifications at a personal inbox. Full-time employees
                  can edit email notification preferences in the account view as
                  well.
                </p>
                <div>
                  <img
                    alt="account"
                    src="/images/account-page.png"
                    className="responsive-img"
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="-header">
                <i className="material-icons">create_new_folder</i>
                Creating a
                New Project (PMs and Admins)
              </div>
              <div className="-body">
                <p>
                  To create a new project, navigate to the projects index and
                  click the + icon at the top of the page. Once a project is
                  created, you will need to add all testers, leads and PMs
                  associated with that project. You are now ready to start
                  scheduling users.
                </p>
                <div>
                  <img
                    src="/images/new-project.png"
                    className="responsive-img"
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="-header">
                <i className="material-icons">person_add</i>
                Adding Users to a
                Project
              </div>
              <div className="-body">
                <p>
                  From the project view, clicking "Add Users" will bring up a
                  modal for adding users to the project. Typing in a user's name
                  will bring up available matches. You can add multiple users at
                  a time.
                </p>
                <div>
                  <img
                    src="/images/add-users.png"
                    className="responsive-img"
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="-header">
                <i className="material-icons">remove_circle_outline</i>
                Removing
                Users from a Project
              </div>
              <div className="-body">
                <p>
                  From the project view, clicking the edit icon next to the
                  "User" column heading will bring up the option to select users
                  to remove from the project.
                </p>
                <div>
                  <img
                    src="/images/remove-users.png"
                    className="responsive-img"
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="-header">
                <i className="material-icons">folder</i>
                Project View
              </div>
              <div className="-body">
                <p>
                  Access a project from either the "My Projects" dashboard or
                  the "Projects" tab in the navbar. The project view shows an
                  interactive schedule with all users who have been added to the
                  project. Click the edit icon next to the project name to
                  change the project name or archive the project.
                </p>
                <div>
                  <img
                    src="/images/project-view.png"
                    className="responsive-img"
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="-header">
                <i className="material-icons">folder_shared</i>
                Master Schedule
                View
              </div>
              <div className="-body">
                <p>
                  Access the full company schedule from the "Schedule" tab in
                  the navbar. This view will display all the company's users by
                  default. There is an option to create filters featuring
                  certain users if you want to limit the list shown in this
                  view.
                </p>
                <div>
                  <img
                    src="/images/master-schedule.png"
                    className="responsive-img"
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="-header">
                <i className="material-icons">perm_contact_calendar</i>
                Booking a
                Tester
              </div>
              <div className="-body">
                <p>
                  From the project view or master schedule, click on the day you
                  would like to book the employee. A scheduling modal will pop
                  up. From here you can set options for the amount of hours and
                  number of days for the booking. There is also an option create
                  recurring bookings for ongoing projects. Click "Create
                  Booking" to complete the process.
                </p>
                <div>
                  <img
                    src="/images/book-tester.png"
                    className="responsive-img"
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="-header">
                <i className="material-icons">edit</i>
                Editing or Deleting a
                Booking
              </div>
              <div className="-body">
                <p>
                  From the project or master calendar, click on any booking. An
                  edit modal will pop up. For a one-time booking you can edit
                  the hours and start or end date. For a recurring booking, you
                  can edit the entire series by moving the "Edit all bookings"
                  slider to YES or the particular booking you clicked on by
                  moving the slider to NO (NO is the default). After edits have
                  been made, click "Update Booking" to save changes. The delete
                  option can be found at bottom of modal. For recurring
                  bookings, you have the option delete the entire series or the
                  single booking you clicked on.
                </p>
                <div>
                  <img
                    src="/images/edit-booking.png"
                    className="responsive-img"
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="-header">
                <i className="material-icons">contacts</i>
                Clients Index (PMs and
                Admins)
              </div>
              <div className="-body">
                <p>
                  From the navbar, click "Clients". All active clients will be
                  listed in alphabetical order on this page. Archived clients
                  will be found at the bottom of the page. Click into any client
                  to edit their details or active status.
                </p>
                <div>
                  <img
                    src="/images/clients-index.png"
                    className="responsive-img"
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="-header">
                <i className="material-icons">folder</i>
                Projects Index (PMs and
                Admins)
              </div>
              <div className="-body">
                <p>
                  From the navbar, click "Projects". All active projects will be
                  listed in alphabetical order on this page. Archived projects
                  will be found at the bottom of the page.
                </p>
                <div>
                  <img
                    src="/images/projects-index.png"
                    className="responsive-img"
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="-header">
                <i className="material-icons">people</i>
                Users Index (PMs and
                Admins)
              </div>
              <div className="-body">
                <p>
                  From the navbar, click "Users". You can add new users by
                  clicking the + icon at the top of the page. Select an existing
                  user to edit their site privileges, full or part-time status,
                  active or inactive status and email preferences (see Email
                  Notifications section for more detail).
                </p>
                <div>
                  <img
                    src="/images/users-index.png"
                    className="responsive-img"
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="-header">
                <i className="material-icons">email</i>
                Email Notifications
              </div>
              <div className="-body">
                <p>
                  Part-time (PT) testers will receive an email notification for
                  any unconfirmed tasks the day before they are booked, around
                  3pm. The email will ask them to confirm the booking. If the
                  employee marks themselves as available, the booking
                  confirmation dot will show as green. If the employee marks
                  themselves as unavailable, the booking confirmation dot will
                  show as red. Only PM or admin users can turn these
                  notifications off for PT testers.
                </p>
                <p>
                  Full-time (FT) users can receive a daily digest email of the
                  project(s) they are booked on for the day. The email will
                  arive around 2am daily. All bookings for FT employees will
                  show the confirmed green dot at time of booking. FT users can
                  turn off email notifications if they wish from their account
                  page.
                </p>
                <div>
                  <img
                    src="/images/email-notifications.png"
                    className="responsive-img"
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="-header">
                <i className="material-icons">highlight_off</i>
                Office Closures
                (Admins Only)
              </div>
              <div className="-body">
                <p>
                  Admins will see the "Closures" tab in the navbar. From this
                  page, company-wide closures can be added. Closures will be
                  noted on all schedules, inlcuding the project, master and user
                  views.
                </p>
                <div>
                  <img
                    src="/images/office-closures.png"
                    className="responsive-img"
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="collapsible-header">
                <i className="material-icons">event_busy</i>
                Unavailability
              </div>
              <div className="collapsible-body">
                <p>
                  When a tester is unavailable, there are 2 time off options. 1.
                  Unavailable - this should be used to mark people’s normal
                  unavailable options, like days they can’t work, are in school,
                  have dr appt, etc. (this can be booked as recurring too) 2.
                  Vacation - this would be planned vacation/PTO time
                </p>
                <p>
                  You can book these from the master schedule by selecting the
                  project names “Unavailable” or “Vacation”. You can also book
                  these from a users’ profile page by clicking the + for either
                  unavailable option.
                </p>
                <p>
                  The users’ profile page lists upcoming Unavailable and
                  Vacation hours planned that are scheduled. (PMs and Admins
                  Only) From the master schedule there is a switch to show just
                  users’ unavailability. These special projects will display as
                  black in the schedule, and are the only projects that will use
                  this color.
                </p>
                {/* <div>
                  <img src="/images/unavailability.png" className="responsive-img"></img>
                </div> */}
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Help;
