import React from "react";
import moment from "moment";
import styled from "styled-components";
import ClockIcon from "../icons/clock_icon";
import SingleSelectStyles from "../common/single_select_styles";
import Select, { components } from "react-select";
import TimeEntryHoursCounter from "../time_tracking/time_entry_hours_counter";
import axios from "axios";
import ExitIcon from "../icons/exit_icon";
import RoundDropdown from "../projects/round_dropdown.jsx";
import SingleSelectWrapper from "../common/single_select_wrapper";
import Swal from "sweetalert2";

const TimeEntryCard = styled.div`
  border: solid 1px lightgray;
  border-radius: 5px;
  padding: 10px 20px 20px;
  min-height: 100px;
  position: relative;
  margin-bottom: 10px;
  width: 485px;
  .react-select__value-container > div:nth-child(2) {
    top: -1px;
  }
  .react-select__input > input {
    box-shadow: none !important;
    border-bottom: none !important;
    height: 48px !important;
    margin-left: 2px;
    font-family: Arial !important;
    color: transparent !important;
    text-shadow: 0px 0px #313131;
    padding-left: 5px !important;
  }
  .react-select__placeholder,
  .react-select__single-value {
    padding-top: 3px;
    padding-left: 5px !important;
    font-family: Arial !important;
  }
  .react-select__control--is-disabled {
    // background-color: #dcdcdc;
    opacity: 0.45;
  }

  .react-select__option--is-disabled {
    color: lightgray !important;
  }

  .react-select__menu {
    transform: translateX(-10px);
  }
`;

const CounterContainer = styled.div`
  display: flex;
  width: 170px;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  button {
    background-color: white;
    border: solid 1px lightgray;
    height: 34px;
    width: 34px;
    cursor: pointer;
  }
  button:hover {
    background-color: #e1e1e1;
  }

  > img {
    height: 20px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input {
    height: 42px !important;
    width: 57px !important;
    margin: 0px !important;
    font-size: 18px !important;
    text-align: left;
    padding-left: 9px !important;
    font-family: "Manrope";
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 2px 0px 0px transparent;
    border: 1px solid lightgray;
    color: #313131;
    transition: box-shadow 0.3s;
  }
`;

class TimeEntry extends React.Component {
  constructor(props) {
    super(props);
    var timeEntry = this.props.data.timeEntry;
    var taskOptions = {};
    props.data.projects.forEach((p) => {
      taskOptions[p.id] = p.time_entry_tasks.map((t) => ({
        value: t.id,
        label: t.name,
      }));
    });
    var projectOptions = this.props.data.clientOptions;
    var combinedOptions = projectOptions.map((p) => p.options);
    combinedOptions = [].concat.apply([], combinedOptions);
    // var baseProjectOptions = this.props.data.projects.map((p)=>({value: p.id, label: p.name, noTasks: p.pto||p.sick_time||p.holiday}))
    var selectedProject = timeEntry
      ? combinedOptions.find((p) => p.value === timeEntry.project_id)
      : [];
    this.state = {
      projectOptions,
      entries: [
        timeEntry && selectedProject
          ? {
              project: selectedProject,
              task: selectedProject.noTasks
                ? []
                : {
                    value: timeEntry.time_entry_task_id,
                    label: timeEntry.time_entry_task_name,
                  },
              hours: timeEntry.hours,
              notes: timeEntry.notes,
              key: 0,
            }
          : { project: [], task: [], hours: 4, notes: "", key: 0 },
      ],
      taskOptions,
    };
    this.projectSelectRef = React.createRef();
    this.roundDrop = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <RoundDropdown />
        </components.DropdownIndicator>
      );
    };
    this.option = (props) => {
      var disabled = this.state.entries.some(
        (x) => x.project.value === props.data.value
      );
      return <components.Option {...props} isDisabled={disabled} />;
    };
    this.singleValue = ({ children, ...props }) => {
      return (
        <div aria-hidden={true}>
          <components.SingleValue {...props}>
            <div>
              <p
                style={{
                  margin: 0,
                  fontSize: "11px",
                  textTransform: "uppercase",
                }}
              >
                {props.data.clientLabel}
              </p>
              <p style={{ margin: 0, fontSize: "15px" }}>{children}</p>
            </div>
          </components.SingleValue>
        </div>
      );
    };

    this.createTimeEntries = this.createTimeEntries.bind(this);
    this.editTimeEntry = this.editTimeEntry.bind(this);
    this.uniqueKeyCounter = 0;
    this.currentEntries = {};
    this.props.data.currentEntries.forEach((c) => {
      this.currentEntries[c.project_id] = this.currentEntries[c.project_id]
        ? [...this.currentEntries[c.project_id], c.time_entry_task_id]
        : [c.time_entry_task_id];
    });
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    setTimeout(() => {
      var elem = document.querySelector(".time-entry-modal h2");
      if (elem) {
        elem.focus();
      }
    }, 100);

    if (!this.props.data.timeEntry && this.props.data.scheduleTasks) {
      var autoEntries = [];
      var currentEntryIds = this.props.data.currentEntries.map(
        (c) => c.project_id
      );
      var index = this.getUniqueKey();
      var combinedOptions = this.state.projectOptions.map((p) => p.options);
      combinedOptions = [].concat.apply([], combinedOptions);
      this.props.data.scheduleTasks.forEach((t) => {
        if (currentEntryIds.indexOf(t.project_id) === -1) {
          autoEntries.push({
            project: combinedOptions.find((p) => p.value === t.project_id),
            task: [],
            hours: t.hours,
            notes: "",
            key: index,
          });
          index = this.getUniqueKey();
        }
      });
      if (autoEntries.length > 0) {
        this.setState({ entries: autoEntries, auto: true });
      }
    }
  }

  getUniqueKey = () => {
    this.uniqueKeyCounter += 1;
    return this.uniqueKeyCounter;
  };

  handleProjectChange = (index) => (value) => {
    var value = value;
    var tasks = this.state.taskOptions[value.value];
    var task = tasks && tasks.length === 1 ? tasks[0] : [];
    this.setState(
      (prevState) => ({
        entries: prevState.entries.map((entry, i) => {
          if (index === i) {
            return { ...entry, ...{ project: value, task: task } };
          } else {
            return entry;
          }
        }),
      }),
      () => {
        CustomAriaAlert(`${value.label} selected`);
      }
    );
  };

  handleTaskChange = (index) => (value) => {
    var value = value;
    this.setState(
      (prevState) => ({
        entries: prevState.entries.map((entry, i) => {
          if (index === i) {
            return { ...entry, ...{ task: value } };
          } else {
            return entry;
          }
        }),
      }),
      () => {
        CustomAriaAlert(`${value.label} selected`);
      }
    );
  };

  addTimeEntryCard = () => {
    var entries = this.state.entries;
    if (this.state.entries >= 8) {
      M.toast({ html: `Cannot add more than 8 time entries`, classes: "red" });
    } else {
      var key = this.getUniqueKey();
      entries.push({ project: [], task: [], hours: 4, notes: "", key: key });
      this.setState({ entries }, () => {
        var elem = document.querySelector(`.extra-task-heading-${key}`);
        if (elem) {
          elem.focus();
        }
      });
    }
  };

  removeTimeEntryCard = (e) => {
    var index = parseInt(e.target.dataset.index);
    if (index || index === 0) {
      this.setState((prevState) => ({
        entries: prevState.entries.filter((entry, i) => i !== index),
      }));
    }
  };

  handleHoursChange = (value, index) => {
    if (value < 0) {
      value = Math.abs(value);
    }
    this.setState((prevState) => ({
      entries: prevState.entries.map((entry, i) => {
        if (index === i) {
          return { ...entry, ...{ hours: value } };
        } else {
          return entry;
        }
      }),
    }));
  };

  async createTimeEntries() {
    var data = [];
    var entries = this.state.entries;
    var userSickPto = this.props.data.userSickPto;
    var report_pto = false;
    var report_sick_time = false;
    for (var x = 0; x < entries.length; x++) {
      if (
        !entries[x].project.value ||
        (!entries[x].task.value && !entries[x].project.noTasks)
      ) {
        M.toast({
          html: `One or more incomplete time entries`,
          classes: "red",
        });
        return;
      }

      if (
        entries[x].project.pto &&
        userSickPto[0].total - userSickPto[0].value - entries[x].hours < 0
      ) {
        report_pto = await Swal.fire({
          title: `Insufficient PTO`,
          text: "This submission exceeds your current PTO budget. Continue with submission? (HR will be notified once this is submitted)",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          return result.value;
        });
        if (!report_pto) {
          return;
        }
      }
      if (
        entries[x].project.sick_time &&
        userSickPto[userSickPto.length - 1].total -
          userSickPto[userSickPto.length - 1].value -
          entries[x].hours <
          0
      ) {
        report_sick_time = await Swal.fire({
          title: `Insufficient Sick Time`,
          text: "This submission exceeds your current Sick Time budget. Continue with submission? (HR will be notified once this is submitted)",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          return result.value;
        });
        if (!report_sick_time) {
          return;
        }
      }
      data.push({
        entry_date: this.props.data.date,
        project_id: entries[x].project.value,
        user_id: this.props.data.userId,
        time_entry_task_id: entries[x].task.value,
        hours: entries[x].hours,
        notes: entries[x].notes,
      });
    }
    this.props.data.setLoading(true);
    axios
      .post("/time_tracking", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        time_entries: { entries: data },
        report_pto,
        report_sick_time,
      })
      .then((res) => {
        this.props.data.updateEntries(res.data, "create");
        M.toast({
          html: `<span aria-live="assertive">Time ${
            entries.length > 1 ? "entries" : "entry"
          } added</span>`,
          classes: "green",
        });
        this.props.modalAction(false, "", {});
        var elem = document.getElementById("new-time-entry-button");
        if (elem) {
          elem.focus();
        }
      })
      .catch((err) => {
        this.props.data.setLoading(false);
        console.error(err);
      });
  }

  async editTimeEntry() {
    var entry = this.state.entries[0];
    var report_pto = false;
    var report_sick_time = false;
    var userSickPto = this.props.data.userSickPto;
    if (
      entry.project.pto &&
      userSickPto[0].total - userSickPto[0].value - entry.hours < 0
    ) {
      report_pto = await Swal.fire({
        title: `Insufficient PTO`,
        text: "This submission exceeds your current PTO budget. Continue with submission? (HR will be notified once this is submitted)",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        return result.value;
      });
      if (!report_pto) {
        return;
      }
    }
    if (
      entry.project.sick_time &&
      userSickPto[userSickPto.length - 1].total -
        userSickPto[userSickPto.length - 1].value -
        entry.hours <
        0
    ) {
      report_sick_time = await Swal.fire({
        title: `Insufficient Sick Time`,
        text: "This submission exceeds your current Sick Time budget. Continue with submission? (HR will be notified once this is submitted)",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        return result.value;
      });
      if (!report_sick_time) {
        return;
      }
    }
    this.props.data.setLoading(true);
    axios
      .patch(`/time_tracking/${this.props.data.timeEntry.id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        time_entry: {
          entry_date: this.props.data.timeEntry.entry_date,
          project_id: entry.project.value,
          user_id: this.props.data.timeEntry.user_id,
          time_entry_task_id: entry.task.value,
          hours: entry.hours,
          notes: entry.notes,
          page: this.props.data.page,
        },
        report_sick_time,
        report_pto,
      })
      .then((res) => {
        this.props.data.updateEntries(
          {
            timeEntry: res.data.time_entry,
            newTimeEntryWeek: res.data.new_time_entry_week,
            oldHours: this.props.data.timeEntry.hours,
            oldProjectId: this.props.data.timeEntry.project_id,
            userSidebarData: res.data.user_sidebar_data,
            userSickPto: res.data.user_sick_pto,
          },
          "update"
        );
        M.toast({
          html: `<span aria-live="assertive">Time Entry updated</span>`,
          classes: "green",
        });
        this.props.modalAction(false, "", {});
        var elem = document.getElementById(
          `time-entry-card-row-${this.props.data.timeEntry.id}`
        );
        if (elem) {
          elem.focus();
        }
      })
      .catch((err) => {
        this.props.data.setLoading(false);
        console.error(err);
      });
  }

  handleNotesChange = (e) => {
    var index = parseInt(e.target.dataset.index);
    var value = e.target.value;
    if (this.notesTimer) {
      clearTimeout(this.notesTimer);
      this.notesTimer = null;
    }
    this.notesTimer = setTimeout(() => {
      this.setState((prevState) => ({
        entries: prevState.entries.map((entry, i) => {
          if (index === i) {
            return { ...entry, ...{ notes: value } };
          } else {
            return entry;
          }
        }),
      }));
    }, 500);
  };

  closeModal = () => {
    this.props.modalAction(false, "", {});
  };

  onSelectKeyDown = (e) => {
    //simplify readout of options for screen readers
    if (e.keyCode === 38 || e.keyCode === 40) {
      setTimeout(() => {
        var elem = document.querySelector(
          ".react-select__menu .react-select__option--is-focused"
        );
        if (elem) {
          var client =
            elem.parentElement.parentElement.childNodes[0].innerText.toLowerCase();
          var list =
            this.projectSelectRef.current.select.state.menuOptions.focusable.map(
              (i) => i.label
            );
          var index = list.indexOf(elem.innerText);
          CustomAriaAlert(
            `option ${elem.innerText}, ${client}. ${index + 1} of ${
              list.length
            } options`
          );
        }
      }, 50);
    } else if (e.keyCode === 8 && !e.target.value) {
      e.preventDefault();
    }
  };

  render() {
    var timeEntry = this.props.data.timeEntry;
    var userSickPto = this.props.data.userSickPto;
    return (
      <div
        role="dialog"
        aria-label="Time Entry Modal"
        style={{
          padding: "25px 0px 25px 26px",
          backgroundColor: "white",
          position: "relative",
        }}
      >
        <h2
          tabIndex={0}
          style={{
            fontSize: "18px",
            outline: "none",
            display: "flex",
            alignItems: "center",
            marginTop: "0px",
            marginBottom: `${this.state.auto ? "0px" : "30px"}`,
            fontWeight: "600",
            fontFamily: "Manrope",
          }}
        >
          <div
            aria-hidden="true"
            style={{
              backgroundColor: "#519acc",
              overflow: "hidden",
              padding: "2px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0px 20px 0px 0px",
            }}
          >
            <ClockIcon height={34} width={34} color="white" />
          </div>
          {timeEntry ? "Edit time entry" : "Create a time entry"} -{" "}
          <span style={{ color: "#519acc", paddingLeft: "4px" }}>
            {moment(
              this.props.data.timeEntry
                ? this.props.data.timeEntry.entry_date
                : this.props.data.date
            ).format("dddd, MMM Do")}
          </span>
        </h2>
        {this.state.auto && (
          <p
            style={{
              fontSize: "14px",
              paddingLeft: "58px",
              marginTop: "5px",
              marginBottom: "30px",
            }}
          >
            Please verify the booking(s) shown below are correct.
          </p>
        )}
        <div
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            maxHeight: `calc(90vh - ${this.state.auto ? "130px" : "105px"})`,
            padding: "0 10px 5px 0",
            marginRight: "6px",
          }}
        >
          {this.state.entries.map((e, i) => {
            var i = i;
            return (
              <TimeEntryCard
                style={{ marginTop: i > 0 ? "40px" : "0" }}
                key={e.key}
              >
                {i > 0 && (
                  <h3
                    tabIndex={0}
                    className={`extra-task-heading-${e.key}`}
                    style={{
                      fontWeight: "600",
                      fontFamily: "Manrope",
                      fontSize: "16px",
                      position: "absolute",
                      top: "-54px",
                      left: "0",
                      outline: "none",
                    }}
                  >
                    Entry #{i + 1}
                  </h3>
                )}
                <div
                  className="select-aria-class"
                  style={{
                    display: "flex",
                    marginBottom: "20px",
                    position: "relative",
                  }}
                >
                  <Select
                    classNamePrefix="react-select"
                    options={this.state.projectOptions}
                    // onChange={(p)=> {this.handleProjectChange({target: { label:p.label, value: p.value, index: i}})}}
                    onChange={this.handleProjectChange(i)}
                    value={e.project}
                    ref={this.projectSelectRef}
                    aria-label={`Project dropdown, ${
                      e.project && e.project.label ? e.project.label : "none"
                    } selected. Type to refine list or press down to open menu`}
                    theme={this.getSelectTheme}
                    blurInputOnSelect={true}
                    onKeyDown={this.onSelectKeyDown}
                    styles={{
                      ...SingleSelectStyles,
                      ...{
                        control: (styles, state) => ({
                          ...styles,
                          height: state.hasValue ? "60px" : "42px",
                        }),
                        indicatorsContainer: (styles, state) => ({
                          ...styles,
                          height: state.hasValue ? "60px" : "42px",
                        }),
                        container: (styles) => ({ ...styles, width: "240px" }),
                      },
                    }}
                    components={{
                      DropdownIndicator: this.roundDrop,
                      SingleValue: this.singleValue,
                      Option: this.option,
                    }}
                    placeholder="Select a Project"
                    // isDisabled={this.props.data.disabled}
                  />

                  <TimeEntryHoursCounter
                    index={i}
                    handleHoursChange={this.handleHoursChange}
                    hours={e.hours}
                    counterContainer={CounterContainer}
                  />
                </div>
                <div style={{ position: "relative" }}>
                  {/* <Select
                      classNamePrefix="react-select"
                      options={e.project && this.state.taskOptions[e.project.value]?
                        this.state.taskOptions[e.project.value].map((o)=>({
                          ...o, 
                          isDisabled: this.currentEntries[e.project.value] ? 
                            this.currentEntries[e.project.value].indexOf(o.value) !== -1 :
                            this.state.entries.find((entry)=>entry.key !== e.key && entry.project.value === e.project.value && entry.task && entry.task.value === o.value)?
                            true : false 
                        })):
                        []}
                      // onChange={(p)=> {this.handleProjectChange({target: { label:p.label, value: p.value, index: i}})}}
                      onChange={this.handleTaskChange(i)}
                      value={e.task}
                      blurInputOnSelect={true}
                      theme={this.getSelectTheme}
                      styles={{...SingleSelectStyles, ...{container: (styles) => ({ ...styles, width: "240px", })}}}
                      components={{DropdownIndicator: this.roundDrop}}
                      placeholder="Select a Task"
                      isDisabled={!e.project || e.project.length===0||e.project.noTasks}
                    /> */}
                  <SingleSelectWrapper
                    classNamePrefix="react-select"
                    options={
                      e.project && this.state.taskOptions[e.project.value]
                        ? this.state.taskOptions[e.project.value].map((o) => ({
                            ...o,
                            isDisabled: this.currentEntries[e.project.value]
                              ? this.currentEntries[e.project.value].indexOf(
                                  o.value
                                ) !== -1
                              : this.state.entries.find(
                                  (entry) =>
                                    entry.key !== e.key &&
                                    entry.project.value === e.project.value &&
                                    entry.task &&
                                    entry.task.value === o.value
                                )
                              ? true
                              : false,
                          }))
                        : []
                    }
                    ariaLabel={
                      !e.project || e.project.length === 0 || e.project.noTasks
                        ? "Task Dropdown, disabled, not applicable for selected project"
                        : `Task dropdown, ${
                            e.task && e.task.label ? e.task.label : "none"
                          } selected. Type to refine list or press down to open menu`
                    }
                    // onChange={(p)=> {this.handleProjectChange({target: { label:p.label, value: p.value, index: i}})}}
                    onChange={this.handleTaskChange(i)}
                    value={e.task}
                    customStyles={{
                      ...SingleSelectStyles,
                      ...{
                        container: (styles) => ({ ...styles, width: "240px" }),
                      },
                    }}
                    placeholder="Select a Task"
                    isDisabled={
                      !e.project || e.project.length === 0 || e.project.noTasks
                    }
                  />
                  {e.project &&
                    (e.project.pto || e.project.sick_time) &&
                    userSickPto && (
                      <div
                        style={{
                          right: e.project.pto ? "15px" : "-10px",
                          top: "12px",
                          position: "absolute",
                        }}
                      >
                        <span>
                          {e.project.pto ? "PTO" : "Sick Time"} Hours Available:{" "}
                          <span style={{ color: "#519acc" }}>
                            {userSickPto.length > 0
                              ? e.project.pto
                                ? (
                                    userSickPto[0].total - userSickPto[0].value
                                  ).toFixed(2)
                                : (
                                    userSickPto[userSickPto.length - 1].total -
                                    userSickPto[userSickPto.length - 1].value
                                  ).toFixed(2)
                              : 0.0}
                          </span>
                        </span>
                      </div>
                    )}
                </div>
                <div
                  id={`notes_container${i}`}
                  className="input-field"
                  style={{
                    width: "100%",
                    marginTop: "30px",
                    marginBottom: "0px",
                  }}
                >
                  <label htmlFor={`notes_${i}`}>Notes</label>
                  <textarea
                    maxLength={800}
                    style={{ minHeight: "110px", marginBottom: "-2px" }}
                    data-index={i}
                    name="notes"
                    id={`notes_${i}`}
                    className="browser-default text-input-v2"
                    onChange={this.handleNotesChange}
                    defaultValue={e.notes}
                  />
                </div>
                {this.state.entries.length > 1 && (
                  <button
                    data-index={i}
                    aria-label="remove time entry"
                    className="btn-icon cancel"
                    style={{ position: "absolute", top: "5px", right: "0px" }}
                    onClick={this.removeTimeEntryCard}
                  >
                    <ExitIcon
                      svgStyles={{ pointerEvents: "none" }}
                      height="25px"
                      width={"25px"}
                      color="#cecece"
                    />
                  </button>
                )}
              </TimeEntryCard>
            );
          })}
          {!timeEntry && (
            <div style={{ marginTop: "16px" }}>
              <button onClick={this.addTimeEntryCard} className="btn btn-flat">
                Add another time entry
              </button>
            </div>
          )}
          <div style={{ textAlign: "right", marginTop: "20px" }}>
            <button onClick={this.closeModal} className="common-button-cancel">
              Cancel
            </button>
            <button
              onClick={timeEntry ? this.editTimeEntry : this.createTimeEntries}
              style={{ marginLeft: "10px" }}
              className="common-button-submit"
            >
              {timeEntry ? "Save" : "Confirm Entries"}
            </button>
          </div>
        </div>
        <button
          className="btn-icon"
          onClick={this.closeModal}
          aria-label="close-modal"
          id="modalClose"
          style={{ position: "absolute", right: "20px", top: "25px" }}
        >
          <ExitIcon
            svgStyles={{ pointerEvents: "none" }}
            height="30px"
            width={"30px"}
          />
        </button>
      </div>
      // </div>
    );
  }
}

export default TimeEntry;
