import React from "react";
import AlphabetUserRow from "./alphabet_user_row";
import ReactModal from "../modals/react_modal";
import PageNav from "../common/page_nav";
import UsersIcon from "components/icons/users_icon";

const roleMap = [
  { id: 1, name: "Tester" },
  { id: 2, name: "Lead" },
  { id: 3, name: "PM" },
  { id: 4, name: "HR" },
  { id: 5, name: "Admin" },
];

const statusMap = [
  { id: 1, name: "Full-time" },
  { id: 2, name: "Part-time" },
  { id: 3, name: "Contractor" },
];
class UserIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: this.props.users,
      searchData: [],
      modalOpen: false,
      currentModal: "",
      modalData: {},
      search: "",
      selectedTag: [],
      selectedStatus: [],
      selectedRole: [],
      selectedUser: [],
    };
    this.setModal = this.setModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.setUsersData = this.setUsersData.bind(this);
    this.filterHandler = this.filterHandler.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
  }

  updateSearch = (event) => {
    var input = event.target.value.toLowerCase();
    this.setUsersData(input);
  };

  componentDidMount() {
    document.title = `Syncit - Users`;
  }

  setUsersData = (input) => {
    var filtered = this.filterHandler();
    var searched = this.searchHandler(filtered, input);
    if (searched.length >= 0) {
      this.setState({ search: input, searchData: searched }, async () => {
        var elem = document.getElementById("bug-search-input");
        if (elem) {
          elem.setAttribute(
            "aria-label",
            `${searched.length} users${searched.length === 1 ? "" : "'s"} found`
          );
        }
      });
    } else if (input === "") {
      var data = this.state.users;
      this.setState({ search: input, searchData: data }, async () => {
        var elem = document.getElementById("bug-search-input");
        if (elem) {
          elem.setAttribute("aria-label", `Search`);
        }
      });
    }
  };

  handleClearFilter = () => {
    this.setState({ selectedStatus: [], selectedRole: [] }, () => {
      this.setUsersData(this.state.search);
    });
  };

  filterHandler() {
    const users = this.state.users;
    const selectedStatus = this.state.selectedStatus;
    const selectedRole = this.state.selectedRole;
    let filteredUsers = [];
    let filteredRoles = [];
    if (selectedStatus.length > 0) {
      if (selectedStatus.includes(1))
        filteredUsers = filteredUsers.concat(
          users.filter((user) => user.full_time && user.contractor === false)
        );
      if (selectedStatus.includes(2))
        filteredUsers = filteredUsers.concat(
          users.filter(
            (user) => user.full_time === false && user.contractor === false
          )
        );
      if (selectedStatus.includes(3))
        filteredUsers = filteredUsers.concat(
          users.filter((user) => user.contractor === true)
        );
    } else {
      filteredUsers = users;
    }
    if (selectedRole.length > 0) {
      filteredRoles = filteredUsers.filter(
        (u) => selectedRole.indexOf(u.role_id) !== -1
      );
    } else {
      filteredRoles = filteredUsers;
    }
    filteredRoles = filteredRoles.sort((a, b) => a.name.localeCompare(b.name));
    filteredUsers = [...new Set(filteredRoles)];
    return filteredUsers;
  }

  searchHandler = (users, input) => {
    const searchTerm = input;
    let filteredUsers = users.filter((user) =>
      user.name.toLowerCase().includes(searchTerm)
    );
    return filteredUsers;
  };

  setModal(bool, page, data) {
    this.setState({
      modalOpen: bool,
      currentModal: page,
      modalData: data,
    });
  }

  openModal() {
    this.setModal(true, "add-user", { customClass: "add-user-modal" });
  }

  scrollIntoView(e) {
    var id = e.target.value;
    var elem = document.getElementById(id);
    if (elem) {
      elem.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  handleSelectChange(e) {
    var e = e;
    var name = e.target.name;
    var value = parseInt(e.target.value);
    var old = this.state[name];
    if (old.includes(value)) {
      old = old.filter((x) => x != value);
    } else {
      old.push(value);
    }
    this.setState({ [name]: old }, () => {
      this.setUsersData(this.state.search);
    });
  }

  render() {
    const selectedRoleName =
      this.state.selectedRole.length > 0
        ? roleMap.find((x) => x.id === this.state.selectedRole[0]).name
        : "";

    const selectedStatusName =
      this.state.selectedStatus.length > 0
        ? statusMap.find((x) => x.id === this.state.selectedStatus[0]).name
        : "";

    var indexes = [];
    if (
      this.state.search != "" ||
      this.state.selectedStatus.length >= 1 ||
      this.state.selectedRole.length >= 1
    ) {
      var users = this.state.searchData;
    } else {
      var users = this.state.users;
    }
    var filteredIndexes = [];
    var alphabetNode = [];
    var indexNode = [];
    for (var w = 0; w < users.length; w++) {
      var firstChar = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g.test(
        users[w].name.charAt(0)
      )
        ? "#"
        : /\p{Emoji_Presentation}/gu.test(users[w].name)
        ? "#"
        : isNaN(users[w].name.toUpperCase().charAt(0))
        ? users[w].name.toUpperCase().charAt(0)
        : "#";
      if (firstChar === "#" && !indexes.includes("#")) {
        indexes.push("#");
      }
      indexes.push(firstChar);
    }
    filteredIndexes = indexes.filter(function (item, pos) {
      return indexes.indexOf(item) === pos;
    });
    for (var x = 0; x < filteredIndexes.length; x++) {
      alphabetNode.push({ index: filteredIndexes[x], users: [] });
    }
    for (var y = 0; y < alphabetNode.length; y++) {
      for (var z = 0; z < users.length; z++) {
        var innerFirstChar = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g.test(
          users[z].name.charAt(0)
        )
          ? "#"
          : /\p{Emoji_Presentation}/gu.test(users[z].name)
          ? "#"
          : isNaN(users[z].name.toUpperCase().charAt(0))
          ? users[z].name.toUpperCase().charAt(0)
          : "#";
        if (innerFirstChar === alphabetNode[y].index) {
          alphabetNode[y].users.push(users[z]);
        }
      }
      indexNode.push(
        <AlphabetUserRow
          index={alphabetNode[y].index}
          users={alphabetNode[y].users}
          key={y}
        />
      );
    }
    var usedLetters = alphabetNode.map((x) => x.index);
    return (
      <div
        className="user-index"
        style={{
          backgroundColor: "#f2f2f2",
          overflowX: "auto",
          padding: "16px",
          minHeight: "calc(100vh - 68px)",
        }}
      >
        <div
          style={{
            height: "calc(100vh - 97px)",
            border: "1px solid #d7d7d7",
            borderRadius: "10px",
            backgroundColor: "white",
            padding: "0px",
            overflow: "hidden",
          }}
        >
          <ReactModal
            isShowing={this.state.modalOpen}
            page={this.state.currentModal}
            data={this.state.modalData}
            modalAction={this.setModal}
          />
          <div
            style={{
              borderBottom: "solid 1px lightgray",
              padding: "32px",
            }}
          >
            <PageNav
              pageIcon={<UsersIcon />}
              pageName={"Users"}
              usedLetters={usedLetters}
              searchKeyDownHandler={this.searchKeyDown}
              clearFunction={this.handleClearFilter}
              checkboxes={[
                {
                  name: "selectedStatus",
                  title: "Status",
                  handler: this.handleSelectChange,
                  options: statusMap,
                  selected: this.state.selectedStatus,
                },
                {
                  name: "selectedRole",
                  title: "Role",
                  handler: this.handleSelectChange,
                  options: roleMap,
                  selected: this.state.selectedRole,
                },
              ]}
              scrollHandler={this.scrollIntoView}
              addHandler={this.openModal}
              search={this.state.search}
              tags={this.props.tags}
              updateSearchHandler={this.updateSearch}
            />
          </div>
          <div
            style={{
              paddingLeft: "16px",
              paddingRight: "0px",
              marginTop: "16px",
              height: "calc(100vh - 280px)",
              overflowY: "auto",

              marginRight: "16px",
            }}
            className="row"
          >
            {indexNode.length === 0 ||
            (this.state.search != "" && indexNode.length === 0) ? (
              <p
                style={{
                  height: "93%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No Users found for &quot;
                {this.state.search || selectedRoleName || selectedStatusName}
                &quot;
              </p>
            ) : (
              indexNode
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default UserIndex;
