/* eslint-disable react/prop-types */
import React from "react";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import RoundDropdown from "../projects/round_dropdown";
import CalendarIcon from "../icons/calendar_icon";

const SingleDateWrapper = ({
  containerMargin,
  startDateFocus,
  date,
  focused,
  onDateChange,
  onFocusChange,
  subLabel,
  label,
  required,
}) => {
  return (
    <div
      style={{
        flex: 1,
        maxWidth: "200px",
        margin: `${containerMargin}`,
      }}
      className="flex-j-start flex-container flex-al-center"
    >
      <div
        style={{
          position: "relative",
          left: "16px",
          zIndex: startDateFocus ? 0 : 1,
          top: "6px",
        }}
      >
        <CalendarIcon width="22px" height="22px" color="#519acc" />
      </div>
      <SingleDatePicker
        date={date}
        onDateChange={onDateChange}
        focused={focused}
        onFocusChange={onFocusChange}
        numberOfMonths={1}
        appendToBody
        // placeholder={placeholder}
        placeholder="Select a Date"
        orientation="horizontal"
        daySize={30}
        navPrev={
          <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
            <RoundDropdown rotate="rotate(90deg)" />
          </div>
        }
        navNext={
          <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
            <RoundDropdown rotate="rotate(-90deg)" />
          </div>
        }
        hideKeyboardShortcutsPanel
        inputIconPosition="after"
        customInputIcon={<RoundDropdown />}
        id="single_date_picker"
      />
      <label
        style={{ left: "2px", marginBottom: "10px" }}
        className="transition-labels date-labels"
        htmlFor="filter_filters"
      >
        {label}
        {required && required === true && <span className="base-color">*</span>}
        {subLabel && (
          <span
            style={{
              fontSize: "13px",
              color: "#6e6e6e9c",
              marginLeft: "5px",
            }}
          >
            {subLabel}
          </span>
        )}
      </label>
    </div>
  );
};

export default SingleDateWrapper;
