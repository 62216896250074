import React from "react";

const MenuContactIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
    >
      <path
        d="M12.5 25.5H8.33333H5C2.23858 25.5 0 23.2614 0 20.5V5.5C0 2.73858 2.23858 0.5 5 0.5H20C22.7614 0.5 25 2.73858 25 5.5V20.5C25 23.2614 22.7614 25.5 20 25.5H16.6667H12.5ZM12.5 14.3889C13.8426 14.3889 14.9884 13.9144 15.9375 12.9653C16.8866 12.0162 17.3611 10.8704 17.3611 9.52778C17.3611 8.18518 16.8866 7.03935 15.9375 6.09028C14.9884 5.1412 13.8426 4.66667 12.5 4.66667C11.1574 4.66667 10.0116 5.1412 9.0625 6.09028C8.11343 7.03935 7.63889 8.18518 7.63889 9.52778C7.63889 10.8704 8.11343 12.0162 9.0625 12.9653C10.0116 13.9144 11.1574 14.3889 12.5 14.3889ZM2.77778 21.5972C2.77778 22.2185 3.28147 22.7222 3.90282 22.7222H21.0972C21.7185 22.7222 22.2222 22.2185 22.2222 21.5972C22.2222 21.2952 22.1023 21.0041 21.8797 20.8C20.709 19.7263 19.3704 18.8684 17.8639 18.2264C16.2083 17.5208 14.4204 17.1676 12.5 17.1667C10.5787 17.1667 8.79028 17.5199 7.13472 18.2264C5.62831 18.8692 4.29017 19.7271 3.1203 20.8C2.89771 21.0041 2.77778 21.2952 2.77778 21.5972Z"
        fill="url(#paint0_linear_4974_4088)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4974_4088"
          x1="12.5"
          y1="0.5"
          x2="12.5"
          y2="25.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MenuContactIcon;
