import React from "react";
import axios from "axios";
import Select from "react-select";

class ProjectAddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUsers: [],
      options: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getSelectTheme = this.getSelectTheme.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };

    axios
      .get(`/find_users_basic/${this.props.data.projectId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const options = [];
        res.data.map((user) => {
          options.push({ value: user.id, label: user.name });
        });
        this.setState({
          options,
          users: res.data,
        });
      })
      .catch((err) => console.error(err));
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.selectedUsers.length === 0) {
      M.toast({
        html: `Please select user(s)`,
        displayLength: 3000,
        classes: "red",
      });
      return;
    }
    this.refs.submit_button.disabled = true;
    axios
      .patch(`/api/v1/projects/${this.props.data.projectId}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        project: {
          user_ids: this.state.selectedUsers,
        },
      })
      .then((res) => {
        M.toast({
          html: `User(s) Added!`,
          displayLength: 3000,
          classes: "green",
        });
        this.props.refreshUsers();
        this.props.modalAction(false, "", {});
      })
      .catch((err) => console.error(err));
  }

  handleInputChange(e) {
    const users = [];
    e.map((user) => users.push(user.value));
    // users.unshift(0)
    this.setState({
      selectedUsers: users,
    });
  }

  getSelectTheme(theme) {
    return {
      ...theme,
      colors: { ...theme.colors, primary: "#2bbbad", neutral0: "#fafafa" },
    };
  }

  onCancelClick() {
    this.props.modalAction(false, "", {});
  }

  render() {
    return (
      <div className="modal-content">
        <div className="row">
          <h4>
            Update
            {this.props.data.projectName} Users
          </h4>
          <form
            className="edit_project"
            id="edit_project_13"
            onSubmit={this.handleSubmit}
          >
            <label htmlFor="project_user_ids-selectized">User(s)</label>
            <Select
              options={this.state.options}
              onChange={this.handleInputChange}
              isMulti
              theme={this.getSelectTheme}
              closeMenuOnSelect={false}
            />
            <div className="new-modal-footer">
              <div className="row center input-field">
                <div className="col s12 m6">
                  <input
                    type="submit"
                    name="commit"
                    value="Update Users"
                    className="btn green modal-btn"
                    ref="submit_button"
                  />
                </div>
                <div className="col s12 m6">
                  <div
                    className="modal-action modal-close gray btn black-text modal-btn"
                    onClick={this.onCancelClick}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ProjectAddUser;
