import React from "react";

const StageGrayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <rect x="1.5" y="1.5" width="29" height="29" rx="14.5" fill="white" />
      <rect
        x="1.5"
        y="1.5"
        width="29"
        height="29"
        rx="14.5"
        stroke="#D1D1D1"
        strokeWidth="3"
      />
      <path
        d="M22.6149 12.03C22.4354 11.8538 22.194 11.7551 21.9424 11.7551C21.6909 11.7551 21.4494 11.8538 21.2699 12.03L14.9499 18.5L11.8549 15.18C11.6834 14.9958 11.4464 14.8862 11.1949 14.875H11.1599C10.9754 14.8755 10.795 14.9297 10.6408 15.031C10.4866 15.1324 10.3652 15.2764 10.2915 15.4455C10.2178 15.6147 10.1949 15.8017 10.2257 15.9836C10.2565 16.1655 10.3396 16.3346 10.4649 16.47L14.2399 20.5C14.3269 20.5941 14.4319 20.6696 14.5488 20.722C14.6656 20.7744 14.7919 20.8027 14.9199 20.805C15.1754 20.8038 15.4199 20.7013 15.5999 20.52L22.5999 13.36C22.776 13.1844 22.8762 12.9468 22.879 12.6981C22.8818 12.4494 22.787 12.2096 22.6149 12.03Z"
        fill="white"
      />
      <circle cx="16" cy="16" r="5" fill="#C4C4C4" />
    </svg>
  );
};

export default StageGrayIcon;
