import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import MemberCard from "../users/member_card";

const MembersBox = styled.div`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #d7d7d7;
  margin-bottom: 20px;
  height: 100%;
  flex: 1;
  font-size: 16px;
  div.members-inner-div {
    overflow: hidden auto;
    height: calc(100% - 45px);
  }
  p.title-box {
    font-family: Manrope;
    font-size: 18px;
    font-weight: 600;
    margin: 0px;
    white-space: pre;
    padding: 24px 16px;
    border-bottom: 1px solid #d1d1d1;
  }
`;
function ProjectMembersBox(props) {
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      // You can await here
      const response = await axios(
        `/projects/${props.projectId}/project_members.json`
      );
      setMembers(response.data);
      setIsLoading(false);
    }
    if (
      props.projectId &&
      !props.projectMembers &&
      !(props.currentView === "clients" || props.currentView === "projects")
    ) {
      fetchData();
    }
  }, [props.projectId]);

  const projectMembers = props.projectMembers ? props.projectMembers : members;
  function Row({ index, style }) {
    return (
      <MemberCard
        key={index}
        user={props.projectMembers[index]}
        style={style}
      />
    );
  }
  return (
    <MembersBox>
      {!(
        props.currentView === "clients" || props.currentView === "projects"
      ) && (
        <>
          <p className="title-box manrope semi-bold" role="heading">
            {props.currentView === "client_projects" ? "Client" : "Project"}{" "}
            Members
          </p>
          {projectMembers.length > 0 ? (
            <div className="members-inner-div">
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    width={width}
                    height={height}
                    itemCount={projectMembers.length}
                    itemSize={75}
                  >
                    {Row}
                  </List>
                )}
              </AutoSizer>
            </div>
          ) : (
            <p style={{ color: "#b1b1b1", textAlign: "center" }}>
              No members present
            </p>
          )}
        </>
      )}
    </MembersBox>
  );
}

export default ProjectMembersBox;
