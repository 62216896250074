import React, { useState, useEffect } from "react";
import EditIcon from "../icons/edit_icon";
import TrashIcon from "components/icons/trash_icon";
import styled from "styled-components";
import MenuContactIcon from "components/icons/menu_contact_icon";

function ContactCard({
  contact,
  active,
  activeIndexHandler,
  submitHandler,
  deleteContact,
  key,
}) {
  const [name, setName] = useState(contact.name);
  const [email, setEmail] = useState(contact.email);

  useEffect(() => {
    setName(contact.name);
    setEmail(contact.email);
  }, [contact]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
    } else if (name === "email") {
      setEmail(value);
    }
  };

  const emailIsValid = (input) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
  };

  const submit = () => {
    if (!name.trim() || !email.trim()) {
      alert("Please enter the contact name and email.");
      return;
    }
    if (!emailIsValid(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    contact.name = name;
    contact.email = email;
    activeIndexHandler(-1);
    submitHandler(contact);
  };

  const newEditClick = () => {
    activeIndexHandler(active ? -1 : contact.id);
  };

  return (
    <ContactCardWrapper
      active={active}
      tabIndex={0}
      aria-label={`${contact.name}`}
    >
      <ContactIconName>
        {active ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <MenuContactIcon />
            <div>
              <input
                type="text"
                id="name"
                maxLength="19"
                onChange={handleInputChange}
                value={name}
                autoComplete="off"
                name="name"
                style={{ height: "30px" }}
                className="browser-default text-input-v2"
              />
            </div>
            <div>
              <input
                type="email"
                id="email"
                onChange={handleInputChange}
                value={email}
                autoComplete="off"
                name="email"
                maxLength="26"
                style={{ height: "30px" }}
                className="browser-default text-input-v2"
              />
            </div>
          </div>
        ) : (
          <>
            <MenuContactIcon />
            <p style={{ fontSize: "13px" }}>{contact.name}</p>
          </>
        )}
      </ContactIconName>
      <ContactEdit>
        {active && (
          <div style={{ display: "flex", gap: "4px" }}>
            <ActionButton
              role="button"
              aria-label="Cancel Edit Contact"
              style={{ padding: "0px" }}
              onClick={() => activeIndexHandler(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect width="24" height="24" rx="2" fill="#D7D7D7" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.7349 12L6 7.26512L7.26512 6L12 10.7349L16.7349 6L18 7.26512L13.2651 12L18 16.7349L16.7349 18L12 13.2651L7.26512 18L6 16.7349L10.7349 12Z"
                  fill="#242424"
                />
              </svg>
            </ActionButton>
            <ActionButton
              role="button"
              aria-label="Save Contact Edit"
              style={{ padding: "0px" }}
              onClick={submit}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect width="24" height="24" rx="2" fill="#519ACC" />
                <path
                  d="M9.76695 15.3226L6.28331 11.667L6.21117 11.5913L6.13877 11.6668L4.92785 12.9285L4.86163 12.9975L4.92761 13.0668L9.69452 18.069L9.76691 18.145L9.83931 18.069L20.0724 7.33073L20.1381 7.26174L20.0724 7.19276L18.87 5.93101L18.7976 5.85508L18.7253 5.93098L9.76695 15.3226Z"
                  fill="white"
                  stroke="white"
                  strokeWidth="0.2"
                />
              </svg>
            </ActionButton>
          </div>
        )}
        {!active && (
          <>
            <ContactEmail>{contact.email}</ContactEmail>
            <ActionButton
              style={{ marginRight: "4px" }}
              role="button"
              aria-label="Edit Contact "
              onClick={newEditClick}
            >
              <EditIcon height={15} width={15} />
            </ActionButton>
            <ActionButton
              role="button"
              aria-label="Delete Contact "
              onClick={() => deleteContact(contact)}
            >
              <TrashIcon height={15} width={15} />
            </ActionButton>
          </>
        )}
      </ContactEdit>
    </ContactCardWrapper>
  );
}

const ContactCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  background: #fff;
  height: 46px;
`;

const ContactIconName = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ContactEmail = styled.p`
  color: #313131;
  font-family: "Arial";
  font-size: 13px;
  font-weight: 400;
  margin: 0;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 16px;
`;

const ContactEdit = styled.div`
  display: flex;
  align-items: center;
  // gap: 16px;
`;

const ActionButton = styled.button`
  border: 1px solid lightgray;
  align-items: center;
  padding: 4.5px;
  height: 30px;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  width: 30px;
  justify-content: center;
  background-color: white;

  &:hover {
    border: 1px solid #519acc;
  }
`;

export default ContactCard;
