import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ScheduleNameColumn = ({
  userSchedule,
  twoWeeks,
  id,
  user,
  hoursAvailable,
}) => {
  if (userSchedule) {
    return (
      <NameColumnContainer userSchedule={userSchedule}>
        <ScheduleTitle>Booked Time</ScheduleTitle>
      </NameColumnContainer>
    );
  }

  return (
    <NameColumnContainer userSchedule={userSchedule} twoWeeks={twoWeeks}>
      <ScheduleName>
        <a
          href={`/users/${id}`}
          style={{ textDecoration: "none", color: "black" }}
        >
          {user.name}
        </a>
      </ScheduleName>
      <Availability>
        Hours Available:
        {hoursAvailable}
      </Availability>
      <UserTypeBadge user={user}>
        {user.contractor ? "C" : user.full_time ? "FT" : "PT"}
      </UserTypeBadge>
    </NameColumnContainer>
  );
};

const NameColumnContainer = styled.div`
  width: ${(props) =>
    props.userSchedule ? "150px" : props.twoWeeks ? "9.09%" : "16.66%"};
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 16px;
`;

const ScheduleTitle = styled.h3`
  font-size: 13px;
  font-weight: 600;
  font-family: "Manrope" !important;

  margin: 0px;
`;

const ScheduleName = styled.p`
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Manrope;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  color: #242424;
`;

const Availability = styled.p`
  margin: 0px;
  color: #242424;
  font-size: 13px;
  font-family: Arial;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
`;

const UserTypeBadge = styled.p`
  color: white;
  background-color: ${(props) =>
    props.user.contractor
      ? "#F55454"
      : props.user.full_time
      ? "#578BC2"
      : "#D76F23"};
  margin-top: 4px;
  text-align: center;
  width: ${(props) => (props.user.contractor ? "23px" : "29px")};
  border-radius: 20px;
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 600;
`;

ScheduleNameColumn.propTypes = {
  userSchedule: PropTypes.bool,
  twoWeeks: PropTypes.bool,
  id: PropTypes.number,
  user: PropTypes.object,
  hoursAvailable: PropTypes.number,
};

export default ScheduleNameColumn;
