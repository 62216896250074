import React from "react";

function ContactIcon({ height, width, svgStyles, color, ariaHidden }) {
  return (
    <svg
      height={height}
      width={width}
      fill={color}
      style={svgStyles}
      aria-hidden={ariaHidden || true}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
    >
      <path d="M55.64,2.45H8.36A5.93,5.93,0,0,0,2.45,8.36V55.64a5.93,5.93,0,0,0,5.91,5.91H55.64a5.93,5.93,0,0,0,5.91-5.91V8.36A5.93,5.93,0,0,0,55.64,2.45ZM32,14.27a8.7,8.7,0,0,1,8.86,8.87,8.86,8.86,0,0,1-17.72,0A8.7,8.7,0,0,1,32,14.27ZM49.73,49.73H14.27V45.89A4.22,4.22,0,0,1,16,42.34c2.37-1.77,7.39-4.43,16-4.43s13.59,2.66,16,4.14a4.19,4.19,0,0,1,1.77,3.54Z" />
    </svg>
  );
}

ContactIcon.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "#519acc",
};

export default ContactIcon;
