import React from "react";
import RoundDropdown from "../projects/round_dropdown";
import ClockIcon from "../icons/clock_icon";

class TimeEntryHoursCounter extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.onBlur = this.onBlur.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
  }

  onChange(e) {
    let input = e.target.value;
    if (parseFloat(input) < 0) {
      input = Math.abs(parseFloat(input)).toString();
    }
    if (input.length === 0 || (input.length === 1 && input === ".")) {
      e.target.value = "";
    } else if (input.length === 2) {
      if (input[1] !== "." && isNaN(input[1])) {
        e.preventDefault();
      } else if (!isNaN(input[1]) && parseInt(input) > 24) {
        e.target.value = `${input[0]}.${input[1]}`;
      }
    } else if (input.length === 3 && !input.includes(".")) {
      e.target.value = `${input.slice(0, 2)}.${input[2]}`;
    } else if (input.length > 4 && input.split(".")[0].length === 1) {
      e.target.value = e.target.value.slice(0, e.target.value.length - 1);
    } else if (input.length > 5) {
      e.target.value = e.target.value.slice(0, e.target.value.length - 1);
    } else {
      e.target.value = input;
    }
  }

  onKeyDown(e) {
    if (!e.target.value && e.keyCode === 190) {
      e.preventDefault();
    }
  }

  onBlur(e) {
    const input = parseFloat(e.target.value);
    let value;
    if (input) {
      if (input > 24) {
        value = "24.00";
      } else if (input % 0.25 === 0) {
        value = input.toFixed(2);
      } else {
        const decimal = input - Math.floor(input);
        if (input <= 0.12) {
          value = "0.25";
        } else {
          value = (Math.round(input * 4) / 4).toFixed(2);
        }
      }
    } else {
      value = "4.00";
    }
    e.target.value = value;
    this.props.handleHoursChange(parseFloat(value), this.props.index);
  }

  highlightText(e) {
    e.target.select();
  }

  onClick(e) {
    const modifier = parseFloat(e.target.dataset.modifier);
    if (modifier) {
      if (!this.holdEventStarted) {
        const hours = parseFloat(this.inputRef.current.value) + modifier;
        if (hours > 0 && hours <= 24) {
          this.inputRef.current.value = hours.toFixed(2);
          if (this.clickTimer) {
            clearTimeout(this.clickTimer);
            this.clickTimer = null;
          }
          this.clickTimer = setTimeout(() => {
            this.props.handleHoursChange(hours, this.props.index);
            CustomAriaAlert(`hours set to ${hours}`);
          }, 500);
        }
      } else {
        this.holdEventStarted = false;
      }
    }
  }

  onMouseDown(e) {
    const modifier = parseFloat(e.target.dataset.modifier);
    this.mouseDownInterval = setInterval(() => {
      this.holdEventStarted = true;
      if (modifier) {
        const hours = parseFloat(this.inputRef.current.value) + modifier;
        if (hours > 0 && hours <= 24) {
          this.inputRef.current.value = hours.toFixed(2);
        }
      }
    }, 180);
  }

  onMouseUp(e) {
    clearInterval(this.mouseDownInterval);
    this.mouseDownInterval = null;
    const hours = parseFloat(this.inputRef.current.value);
    if (hours > 0 && hours <= 24) {
      if (this.clickTimer) {
        clearTimeout(this.clickTimer);
        this.clickTimer = null;
      }
      this.clickTimer = setTimeout(() => {
        this.props.handleHoursChange(hours, this.props.index);
      }, 500);
    }
  }

  onPaste(e) {
    e.preventDefault();
  }

  render() {
    return (
      <this.props.counterContainer>
        <ClockIcon height={24} width={24} color="#313131" />
        <input
          aria-label="Time entry hours"
          className="browser-default text-input-v2"
          onPaste={this.onPaste}
          ref={this.inputRef}
          onFocus={this.highlightText}
          onKeyDown={this.onKeyDown}
          onMouseUp={this.highlightText}
          onBlur={this.onBlur}
          defaultValue={this.props.hours.toFixed(2)}
          onChange={this.onChange}
          min={0.25}
          max={8}
          step={0.25}
          type="number"
        />
        <div style={{ marginLeft: "4px" }}>
          <button
            aria-label="Decrease hours by 0.25"
            data-modifier={-0.25}
            onClick={this.onClick}
            onMouseDown={this.onMouseDown}
            onMouseUp={this.onMouseUp}
          >
            <RoundDropdown
              rotate="rotate(90deg)"
              height={15}
              width={15}
              svgStyles={{ verticalAlign: "sub", pointerEvents: "none" }}
            />
          </button>
          <button
            aria-label="Increase hours by 0.25"
            data-modifier={0.25}
            onClick={this.onClick}
            onMouseDown={this.onMouseDown}
            onMouseUp={this.onMouseUp}
          >
            <RoundDropdown
              rotate="rotate(-90deg)"
              height={15}
              width={15}
              svgStyles={{ verticalAlign: "sub", pointerEvents: "none" }}
            />
          </button>
        </div>
      </this.props.counterContainer>
    );
  }
}

export default TimeEntryHoursCounter;
