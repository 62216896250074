import React from "react";
import map from "lodash/map";
import merge from "lodash/merge";
import PropTypes from "prop-types";
import reactCSS from "reactcss";
import { CustomPicker } from "react-color";

const { Swatch } = require("react-color/lib/components/common");

export function CustomColorPicker({
  onChange,
  onSwatchHover,
  colors,
  color,
  width,
  triangle,
  title,
  styles: passedStyles = {},
  className = "",
}) {
  const styles = reactCSS(
    merge(
      {
        default: {
          card: {
            width,
            background: "#fff",
            border: "0 solid rgba(0,0,0,0.25)",
            position: "relative",
          },
          body: {},
          label: {
            fontSize: "18px",
            color: "#fff",
          },
          title: {
            fontSize: "12px",
            color: "#313131",
          },
          titleContainer: {
            flex: 1,
            display: "flex",
            flexWrap: "wrap",
            paddingRight: "6px",
          },
          triangle: {
            width: "0px",
            height: "0px",
            borderStyle: "solid",
            borderWidth: "0 9px 10px 9px",
            borderColor: "transparent transparent #fff transparent",
            position: "absolute",
          },
          triangleShadow: {
            width: "0px",
            height: "0px",
            borderStyle: "solid",
            borderWidth: "0 9px 10px 9px",
            borderColor: "transparent transparent rgba(0,0,0,.1) transparent",
            position: "absolute",
          },
          hash: {
            flex: "1 1 100%",
            background: `${color}`,
            height: "30px",
            width: "30px",
            marginTop: "5px",
            marginBottom: "20px",
            borderRadius: "4px",
            float: "left",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          input: {
            width: "100px",
            fontSize: "14px",
            color: "#313131",
            border: "0px",
            outline: "none",
            height: "28px",
            boxShadow: "inset 0 0 0 1px #F0F0F0",
            boxSizing: "content-box",
            borderRadius: "0 4px 4px 0",
            float: "left",
            paddingLeft: "8px",
          },
          swatch: {
            width: "30px",
            height: "30px",
            float: "left",
            borderRadius: "4px",
            margin: "0 6px 6px 0",
          },
          clear: {
            clear: "both",
          },
        },
        "hide-triangle": {
          triangle: {
            display: "none",
          },
          triangleShadow: {
            display: "none",
          },
        },
        "top-left-triangle": {
          triangle: {
            top: "-10px",
            left: "12px",
          },
          triangleShadow: {
            top: "-11px",
            left: "12px",
          },
        },
        "top-right-triangle": {
          triangle: {
            top: "-10px",
            right: "12px",
          },
          triangleShadow: {
            top: "-11px",
            right: "12px",
          },
        },
      },
      passedStyles
    ),
    {
      "hide-triangle": triangle === "hide",
      "top-left-triangle": triangle === "top-left",
      "top-right-triangle": triangle === "top-right",
    }
  );

  const handleChange = (hexcode, e) => {
    onChange(
      {
        hex: hexcode,
        source: "hex",
      },
      e
    );
  };

  return (
    <div style={styles.card} className={` ${className}`}>
      <div style={styles.triangleShadow} />
      <div style={styles.triangle} />

      <div style={styles.body}>
        <div style={styles.titleContainer}>
          <p style={styles.title}>{title}</p>
          <div style={styles.hash} />
        </div>
        {map(colors, (c, i) => {
          return (
            <Swatch
              key={i}
              color={c}
              hex={c}
              style={
                color === c
                  ? {
                      ...styles.swatch,
                      border: "solid 2px white",
                      boxShadow: "0 0 0 3px #519acc",
                    }
                  : styles.swatch
              }
              onClick={handleChange}
              onHover={onSwatchHover}
            />
          );
        })}
        <div style={styles.clear} />
      </div>
    </div>
  );
}

CustomColorPicker.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  triangle: PropTypes.oneOf(["hide", "top-left", "top-right"]),
  colors: PropTypes.arrayOf(PropTypes.string),
  styles: PropTypes.object,
  title: PropTypes.string,
};

CustomColorPicker.defaultProps = {
  width: 216,
  colors: [
    "#386FFE",
    "#30A6DF",
    "#4F2A9E",
    "#7A51AD",
    "#F55454",
    "#FD6523",
    "#FCAC15",
    "#ECC332",
    "#242424",
    "#444",
    "#009177",
    "#15db4c",
  ],
  triangle: "top-left",
  styles: {},
  title: "Selected Color",
};

export default CustomPicker(CustomColorPicker);
