import React from "react";
import axios from "axios";
import moment from "moment";
import ReactModal from "../modals/react_modal";
import InvoiceHeader from "./invoice_header";
import InvoiceRow from "./invoice_row";
import InvoiceSideBar from "./invoice_sidebar";
import { CSSTransition } from "react-transition-group";
import { sentryInit } from "../utils";

sentryInit();
class InvoiceContainer extends React.Component {
  constructor(props) {
    super(props);
    var readyTotal = 0;
    var pendingTotal = 0;
    var overdueTotal = 0;
    var totalPaid = 0;
    this.props.resp.ready_for_invoice.forEach((i) => {
      readyTotal += (i.rate / 100) * i.tracked_hours;
    });
    this.props.resp.pending_payment.forEach((i) => {
      pendingTotal += (i.rate / 100) * i.tracked_hours;
    });
    this.props.resp.pending_payment.forEach((i) => {
      if (moment(i.due_date) > moment()) {
        overdueTotal += (i.rate / 100) * i.tracked_hours;
      }
    });
    this.props.resp.payment_cleared.forEach((i) => {
      totalPaid += (i.rate / 100) * i.tracked_hours;
    });
    this.state = {
      readyForInvoice: this.props.resp.ready_for_invoice,
      pendingPayment: this.props.resp.pending_payment,
      paymentCleared: this.props.resp.payment_cleared,
      readyForInvoiceBase: this.props.resp.ready_for_invoice,
      pendingPaymentBase: this.props.resp.pending_payment,
      paymentClearedBase: this.props.resp.payment_cleared,
      readyTotal,
      pendingTotal,
      overdueTotal,
      totalPaid,
      readyTotalBase: readyTotal,
      pendingTotalBase: pendingTotal,
      overdueTotalBase: overdueTotal,
      totalPaidBase: totalPaid,
      newTotal: this.props.resp.ready_for_invoice.filter((i) => i.new_invoice)
        .length,
      clientOptions: [
        { label: "All Clients", value: "all" },
        ...this.props.resp.client_options,
      ],
      selectedInvoiceRange: { label: "This Month", value: "month" },
      selectedStatus: { label: "All Statuses", value: "all" },
      selectedClient: { label: "All Clients", value: "all" },
      customStartDate: moment().startOf("month"),
      customEndDate: moment(),
      startPickerFocus: false,
      endPickerFocus: false,
      search: "",
      currentModal: "",
      modalData: {},
      modalOpen: false,
      loading: false,
      searchLoading: false,
    };
    this.statusOptions = [
      { label: "Ready for Invoice", value: 1 },
      { label: "Pending Payment", value: 2 },
      { label: "Payment Cleared", value: 3 },
    ];
    this.statusVarNames = {
      1: "readyForInvoiceBase",
      2: "pendingPaymentBase",
      3: "paymentClearedBase",
    };
    this.canvas = document.createElement("canvas").getContext("2d");
    this.canvas.font = `13px Arial`;
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    window.addEventListener("beforeunload", this.updateNewInvoices);
  }

  componentWillUnmount() {
    this.updateNewInvoices();
  }

  setModal = (bool, page, data) => {
    this.setState({
      modalOpen: bool,
      currentModal: page,
      modalData: data,
    });
  };

  updateSelectedInvoiceRange = (range) => {
    if (range.value !== "custom") {
      this.setState({ loading: true });
      var range = range;
      var start_date =
        range.value === "last_year"
          ? moment().startOf("year").subtract(1, "year").format("YYYY-MM-DD")
          : moment().startOf(range.value).format("YYYY-MM-DD");
      var end_date =
        range.value === "last_year"
          ? moment().startOf("year").subtract(1, "day").format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD");
      axios
        .post(`/invoices/date_range.json`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          start_date,
          end_date,
        })
        .then((res) => {
          this.setState(
            {
              readyForInvoiceBase: res.data.ready_for_invoice,
              pendingPaymentBase: res.data.pending_payment,
              paymentClearedBase: res.data.payment_cleared,
              selectedInvoiceRange: range,
              customStartDate: moment(start_date),
              customEndDate: moment(end_date),
              loading: false,
            },
            () => {
              this.combinedFilters();
            }
          );
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.error(err);
        });
    } else {
      this.setState({ selectedInvoiceRange: range });
    }
  };

  updateSelectedCustomRange = (start, end) => {
    var start_date = start;
    var end_date = end;
    this.setState({ loading: true });
    axios
      .post(`/invoices/date_range.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        start_date,
        end_date,
      })
      .then((res) => {
        this.setState(
          {
            readyForInvoiceBase: res.data.ready_for_invoice,
            pendingPaymentBase: res.data.pending_payment,
            paymentClearedBase: res.data.payment_cleared,
            customStartDate: start_date,
            customEndDate: end_date,
            loading: false,
          },
          () => {
            this.combinedFilters();
          }
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });
  };

  handleStartDateChange = (date) => {
    if (moment(date).isValid()) {
      this.updateSelectedCustomRange(
        date,
        date > this.state.customEndDate ? date : this.state.customEndDate
      );
    }
  };

  handleEndDateChange = (date) => {
    if (moment(date).isValid()) {
      this.updateSelectedCustomRange(
        date < this.state.customStartDate ? date : this.state.customStartDate,
        date
      );
    }
  };

  onStartFocusChange = ({ focused }) => {
    this.setState({ startPickerFocus: focused, datePickerOverlay: focused });
  };

  onEndFocusChange = ({ focused }) => {
    this.setState({ endPickerFocus: focused, datePickerOverlay: focused });
  };

  updateSelectedStatus = (status) => {
    this.combinedFilters(null, null, status);
  };

  updateSelectedClient = (client) => {
    this.combinedFilters(null, client, null);
  };

  searchUpdate = (search) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
      this.searchTimer = null;
    }
    if (!this.state.searchLoading) {
      this.setState({ searchLoading: true });
    }
    this.searchTimer = setTimeout(() => {
      this.combinedFilters(search, null, null);
    }, 600);
  };

  updateNewInvoices = () => {
    var newInvoices = this.state.readyForInvoiceBase
      .filter((i) => i.new_invoice)
      .map((i) => i.id);
    if (newInvoices.length > 0) {
      axios
        .patch(`/invoices/0`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          ids: newInvoices,
        })
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        });
    }
  };

  handleInvoiceNotesUpdate = (notes, id) => {
    axios
      .patch(`/invoices/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        invoice: { notes },
      })
      .then((res) => {
        var i = res.data;
        var varName = this.statusVarNames[i.invoice_status];
        this.setState((prevState) => ({
          [varName]: prevState[varName].map((invoice) => {
            if (invoice.id === i.id) {
              return i;
            } else {
              return invoice;
            }
          }),
        }));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  updateRowStatus = (newStatus, oldStatus, id, invNumber) => {
    var oldStatus = oldStatus;
    var newInvoice = newInvoice;
    var paramsObject = { invoice_status: newStatus };
    var loadStart = Date.now();
    if (oldStatus === 1 && newStatus === 2) {
      paramsObject.submitted_date = moment().format("YYYY-MM-DD");
      paramsObject.new_invoice = false;
      if (invNumber) {
        paramsObject.inv_number = invNumber;
      }
    } else if (oldStatus === 2 && newStatus === 3) {
      paramsObject.completed_date = moment().format("YYYY-MM-DD");
    }
    this.setState({ loading: true });
    axios
      .patch(`/invoices/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        invoice: paramsObject,
      })
      .then((res) => {
        var invoice = res.data;
        var oldGroup = this.state[this.statusVarNames[oldStatus]];
        var newGroup = this.state[this.statusVarNames[invoice.invoice_status]];
        oldGroup = oldGroup.filter((i) => i.id !== invoice.id);
        newGroup = [...newGroup, ...[invoice]];
        this.setState(
          {
            [this.statusVarNames[oldStatus]]: oldGroup,
            [this.statusVarNames[invoice.invoice_status]]: newGroup,
          },
          () => {
            var elapsedTime = Date.now() - loadStart;
            if (elapsedTime > 500) {
              this.combinedFilters();
              M.toast({ html: `Invoice Updated`, classes: "green" });
            } else {
              setTimeout(() => {
                this.combinedFilters();
                M.toast({ html: `Invoice Updated`, classes: "green" });
              }, 500 - elapsedTime);
            }
          }
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });
  };

  openConfirmInvoiceModal = (invoice, paymentDue) => {
    this.setModal(true, "invoice-submission", {
      id: invoice.id,
      client_id: invoice.client_id,
      client: invoice.client_name,
      project_id: invoice.project_id,
      project: invoice.project_name,
      poNumber: invoice.po_number,
      paymentDue,
      closeButton: true,
      customClass: "confirm-invoice-modal",
      updateRowStatus: this.updateRowStatus,
    });
  };

  combinedFilters = (search, client, status) => {
    var readyForInvoice = this.state.readyForInvoiceBase;
    var pendingPayment = this.state.pendingPaymentBase;
    var paymentCleared = this.state.paymentClearedBase;
    var readyTotal = 0;
    var pendingTotal = 0;
    var overdueTotal = 0;
    var totalPaid = 0;
    var search = search || search === "" ? search : this.state.search;
    var selectedClient = client ? client : this.state.selectedClient;
    var selectedStatus = status ? status : this.state.selectedStatus;
    var newTotal = 0;
    if (search) {
      readyForInvoice = readyForInvoice.filter((i) =>
        (
          i.client_name +
          " " +
          i.project_name +
          " " +
          `${i.inv_number ? i.inv_number : ""}`
        )
          .toLowerCase()
          .includes(search.toLowerCase())
      );
      pendingPayment = pendingPayment.filter((i) =>
        (
          i.client_name +
          " " +
          i.project_name +
          " " +
          `${i.inv_number ? i.inv_number : ""}`
        )
          .toLowerCase()
          .includes(search.toLowerCase())
      );
      paymentCleared = paymentCleared.filter((i) =>
        (
          i.client_name +
          " " +
          i.project_name +
          " " +
          `${i.inv_number ? i.inv_number : ""}`
        )
          .toLowerCase()
          .includes(search.toLowerCase())
      );
    }
    if (selectedClient.value !== "all") {
      readyForInvoice = readyForInvoice.filter(
        (i) => i.client_id === selectedClient.value
      );
      pendingPayment = pendingPayment.filter(
        (i) => i.client_id === selectedClient.value
      );
      paymentCleared = paymentCleared.filter(
        (i) => i.client_id === selectedClient.value
      );
    }
    if (selectedStatus.value !== "all") {
      readyForInvoice = readyForInvoice.filter(
        (i) => i.invoice_status === selectedStatus.value
      );
      pendingPayment = pendingPayment.filter(
        (i) => i.invoice_status === selectedStatus.value
      );
      paymentCleared = paymentCleared.filter(
        (i) => i.invoice_status === selectedStatus.value
      );
    }
    readyForInvoice.forEach((i) => {
      readyTotal += (i.rate / 100) * i.tracked_hours;
    });
    pendingPayment.forEach((i) => {
      pendingTotal += (i.rate / 100) * i.tracked_hours;
      if (moment(i.due_date) > moment()) {
        overdueTotal += (i.rate / 100) * i.tracked_hours;
      }
    });
    paymentCleared.forEach((i) => {
      totalPaid += (i.rate / 100) * i.tracked_hours;
    });
    newTotal = readyForInvoice.filter((i) => i.new_invoice).length;
    this.setState({
      readyForInvoice,
      pendingPayment,
      paymentCleared,
      newTotal,
      readyTotal,
      pendingTotal,
      overdueTotal,
      totalPaid,
      search,
      selectedClient,
      selectedStatus,
      loading: false,
      searchLoading: false,
    });
  };

  render() {
    var {
      readyForInvoice,
      pendingPayment,
      paymentCleared,
      selectedStatus,
      selectedInvoiceRange,
      selectedClient,
      readyTotal,
      pendingTotal,
      overdueTotal,
      totalPaid,
      newTotal,
      search,
    } = this.state;
    const empty =
      readyForInvoice.length + pendingPayment.length + paymentCleared.length ===
      0;
    return (
      <div style={{ height: "calc(100vh - 67px)" }}>
        <ReactModal
          isShowing={this.state.modalOpen}
          page={this.state.currentModal}
          data={this.state.modalData}
          modalAction={this.setModal}
          id="taskboard-modal"
        />
        <InvoiceSideBar
          statusOptions={this.statusOptions}
          clientOptions={this.state.clientOptions}
          selectedStatus={selectedStatus}
          selectedInvoiceRange={selectedInvoiceRange}
          selectedClient={selectedClient}
          search={search}
          searchUpdate={this.searchUpdate}
          updateSelectedClient={this.updateSelectedClient}
          updateSelectedInvoiceRange={this.updateSelectedInvoiceRange}
          updateSelectedStatus={this.updateSelectedStatus}
          searchLoading={this.state.searchLoading}
          customStartDate={this.state.customStartDate}
          customEndDate={this.state.customEndDate}
          handleStartDateChange={this.handleStartDateChange}
          handleEndDateChange={this.handleEndDateChange}
          startPickerFocus={this.state.startPickerFocus}
          endPickerFocus={this.state.endPickerFocus}
          onStartFocusChange={this.onStartFocusChange}
          onEndFocusChange={this.onEndFocusChange}
        />
        <section
          style={{
            width: "calc(100% - 300px)",
            marginLeft: "auto",
            height: "100%",
            position: "relative",
          }}
        >
          <CSSTransition
            unmountOnExit
            in={this.state.loading}
            timeout={500}
            classNames="node-fade"
          >
            <div className="page-loader">
              <div className="page-load-spinner" />
            </div>
          </CSSTransition>
          <InvoiceHeader
            newTotal={newTotal}
            readyTotal={readyTotal}
            pendingTotal={pendingTotal}
            totalPaid={totalPaid}
            overdueTotal={overdueTotal}
          />
          {empty ? (
            <p style={{ textAlign: "center", fontSize: "20px" }}>
              No Invoices Found
            </p>
          ) : (
            <div
              style={{
                paddingLeft: "25px",
                height: "calc(100vh - 187px)",
                overflowY: "auto",
                paddingBottom: "20px",
                marginRight: "10px",
              }}
            >
              {readyForInvoice.length > 0 &&
                (selectedStatus.value === "all" ||
                  selectedStatus.value === 1) && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <p
                        style={{
                          width: "150px",
                          fontSize: "14px",
                          height: "25px",
                          lineHeight: "25px",
                          backgroundColor: "#519acc",
                          textAlign: "center",
                          color: "white",
                          borderRadius: "8px",
                        }}
                      >
                        Ready for Invoice
                      </p>
                      <div
                        style={{
                          height: "1px",
                          width: "calc(100% - 172px)",
                          marginLeft: "10px",
                          backgroundColor: "#cccccc",
                        }}
                      />
                    </div>
                    {readyForInvoice.map((r) => {
                      return (
                        <div key={r.id} style={{ position: "relative" }}>
                          {r.new_invoice && (
                            <div className="new-invoice-heading">New</div>
                          )}
                          <InvoiceRow
                            canvas={this.canvas}
                            openConfirmInvoiceModal={
                              this.openConfirmInvoiceModal
                            }
                            updateRowStatus={this.updateRowStatus}
                            updateNewInvoice={this.updateNewInvoice}
                            statusOptions={this.statusOptions}
                            invoice={r}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              {pendingPayment.length > 0 &&
                (selectedStatus.value === "all" ||
                  selectedStatus.value === 2) && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <p
                        style={{
                          width: "150px",
                          fontSize: "14px",
                          height: "25px",
                          lineHeight: "25px",
                          backgroundColor: "#519acc",
                          textAlign: "center",
                          color: "white",
                          borderRadius: "8px",
                        }}
                      >
                        Pending Payment
                      </p>
                      <div
                        style={{
                          height: "1px",
                          width: "calc(100% - 172px)",
                          marginLeft: "10px",
                          backgroundColor: "#cccccc",
                        }}
                      />
                    </div>
                    {pendingPayment.map((r) => {
                      return (
                        <InvoiceRow
                          canvas={this.canvas}
                          openConfirmInvoiceModal={this.openConfirmInvoiceModal}
                          updateRowStatus={this.updateRowStatus}
                          updateNewInvoice={this.updateNewInvoice}
                          statusOptions={this.statusOptions}
                          key={r.id}
                          invoice={r}
                          handleInvoiceNotesUpdate={
                            this.handleInvoiceNotesUpdate
                          }
                        />
                      );
                    })}
                  </div>
                )}
              {paymentCleared.length > 0 &&
                (selectedStatus.value === "all" ||
                  selectedStatus.value === 3) && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <p
                        style={{
                          width: "150px",
                          fontSize: "14px",
                          height: "25px",
                          lineHeight: "25px",
                          backgroundColor: "#519acc",
                          textAlign: "center",
                          color: "white",
                          borderRadius: "8px",
                        }}
                      >
                        Payment Cleared
                      </p>
                      <div
                        style={{
                          height: "1px",
                          width: "calc(100% - 172px)",
                          marginLeft: "10px",
                          backgroundColor: "#cccccc",
                        }}
                      />
                    </div>
                    {paymentCleared.map((r) => {
                      return (
                        <InvoiceRow
                          canvas={this.canvas}
                          openConfirmInvoiceModal={this.openConfirmInvoiceModal}
                          updateRowStatus={this.updateRowStatus}
                          updateNewInvoice={this.updateNewInvoice}
                          statusOptions={this.statusOptions}
                          key={r.id}
                          invoice={r}
                          handleInvoiceNotesUpdate={
                            this.handleInvoiceNotesUpdate
                          }
                        />
                      );
                    })}
                  </div>
                )}
            </div>
          )}
        </section>
      </div>
    );
  }
}

export default InvoiceContainer;
