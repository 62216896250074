import React from "react";
import SvgShell from "./svg_shell";

function ExpandIcon({ height, width, svgStyles, color }) {
  return (
    <SvgShell height={height} width={width} svgStyles={svgStyles} color={color}>
      <path d="M1.4,12.49V57.38A5.22,5.22,0,0,0,6.56,62.6H51.44a5.23,5.23,0,0,0,3.69-1.53,5.23,5.23,0,0,0,1.53-3.69V30a3.26,3.26,0,0,0-.95-2.31,3.3,3.3,0,0,0-2.31-1A3.24,3.24,0,0,0,50.14,30V56.07H7.93V13.86H34a3.24,3.24,0,0,0,3.26-3.26,3.3,3.3,0,0,0-1-2.31A3.26,3.26,0,0,0,34,7.34H6.56A5.16,5.16,0,0,0,1.4,12.49Z" />
      <path d="M59.34,21.82a3.26,3.26,0,0,0,3.26-3.26V2.73A1.34,1.34,0,0,0,61.27,1.4H45.37a3.27,3.27,0,0,0,0,6.53h6.07l-9.33,9.33a3.12,3.12,0,0,0-.73,1,3.18,3.18,0,0,0-.26,1.25,3.1,3.1,0,0,0,.26,1.24,3.06,3.06,0,0,0,.73,1,3.24,3.24,0,0,0,2.29,1,3.21,3.21,0,0,0,1.44-.19,3.25,3.25,0,0,0,1.23-.79l9-9.26v6a3.26,3.26,0,0,0,3.27,3.26Z" />
    </SvgShell>
  );
}

ExpandIcon.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "#519acc",
};

export default ExpandIcon;
