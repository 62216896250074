import React from "react";
import SvgShell from "./svg_shell";

function ProjectPlusIcon({ height, width, svgStyles, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 30 30"
      fill="none"
      style={{
        position: "relative",
        top: "1.4px",
      }}
    >
      <path
        d="M12.5 0C5.59552 0 0 5.59552 0 12.5C0 19.4045 5.59552 25 12.5 25C19.4045 25 25 19.4045 25 12.5C25 5.59552 19.4045 0 12.5 0Z"
        fill="#519ACC"
      />
      <path
        d="M13.959 18.0075C13.959 18.7847 13.3832 19.5018 12.606 19.5442C12.1785 19.569 11.7864 19.4029 11.5073 19.1238C11.2494 18.866 11.0869 18.5056 11.0869 18.11L11.0869 13.906L11.0375 13.906L6.87946 13.9025C6.08814 13.9025 5.44518 13.2595 5.44165 12.4647C5.44518 11.6769 6.08814 11.0339 6.87946 11.0269L11.0163 11.0304C11.0163 11.0304 11.041 11.0198 11.0375 11.0092L11.041 6.99959C11.0375 6.21886 11.6098 5.50525 12.3905 5.45933C12.8215 5.43813 13.2101 5.60064 13.4892 5.87972C13.7506 6.14114 13.9096 6.49794 13.9131 6.89714L13.9131 11.1011L18.117 11.1011C18.9083 11.1081 19.5478 11.7475 19.5548 12.5389C19.5513 13.3337 18.9119 13.9732 18.117 13.9767L13.9767 13.9767C13.9767 13.9767 13.9625 13.9908 13.9555 13.9979L13.959 18.0075Z"
        fill="white"
      />
    </svg>
  );
}

ProjectPlusIcon.defaultProps = {
  height: "16px",
  width: "16px",
  svgStyles: {},
  color: "#519acc",
};

export default ProjectPlusIcon;
