import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ProjectComment from "../projects/project_comment";
import axios from "axios";

const CommentsContainer = ({
  comments,
  currentUser,
  project,
  instruction = false,
}) => {
  const [commentInput, setCommentInput] = useState("");
  const [commentsState, setCommentsState] = useState(comments);
  const [commentsSearch, setCommentsSearch] = useState("");
  const [searchedCommentsResults, setSearchedCommentsResults] =
    useState(comments);

  useEffect(() => {
    const filteredComments = commentsState.filter((comment) =>
      `${comment.text.toLowerCase()} ${comment.user.name.toLowerCase()}`.includes(
        commentsSearch.toLowerCase()
      )
    );
    setSearchedCommentsResults(filteredComments);
  }, [commentsState, commentsSearch]);

  const handleUncontrolledInputChange = (e) => {
    setCommentInput(e.target.value);
  };

  const handleSearchChange = (e) => {
    setCommentsSearch(e.target.value);
  };

  const commentHandler = (action, comment) => {
    let newComments = [...commentsState];
    if (action === "create") {
      newComments.unshift(comment);
    } else if (action === "delete" || action === "edit") {
      for (let i = 0; i < newComments.length; i++) {
        if (newComments[i].id === comment.id) {
          if (action === "delete") {
            newComments.splice(i, 1);
          } else if (action === "edit") {
            newComments.splice(i, 1, comment);
          }
          break;
        }
      }
    }
    setCommentsState(newComments);

    const filteredComments = newComments.filter((c) =>
      `${c.text.toLowerCase()} ${c.user.name.toLowerCase()}`.includes(
        commentsSearch.toLowerCase()
      )
    );
    setSearchedCommentsResults(filteredComments);
  };

  const commentSubmit = (e) => {
    e.preventDefault();

    if (commentInput === "") {
      return;
    }

    axios
      .post(`/comments.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        comment: {
          instruction: instruction,
          text: commentInput,
          user_id: currentUser.id,
          project_id: project.id,
        },
      })
      .then((res) => {
        setCommentInput("");
        commentHandler("create", res.data);
        M.toast({
          html: `<span aria-live='assertive'>Comment created</span>`,
          displayLength: 3000,
          classes: "green",
        });
        document.activeElement.blur();
      })
      .catch((err) => console.error(err));
  };

  return (
    <Container instruction={instruction}>
      <SearchContainer id="ticketSearch">
        <SearchIcon aria-hidden="true" className="material-icons prefix">
          search
        </SearchIcon>
        <SearchInput
          id="bug-search-input"
          className="browser-default"
          type="text"
          onChange={handleSearchChange}
          placeholder=" "
          aria-live="polite"
          value={commentsSearch}
          aria-label="Search"
        />
      </SearchContainer>
      <FormContainer id="modal-comments-form" onSubmit={commentSubmit}>
        <TextAreaContainer>
          <CommentTextArea
            resize="none"
            name="commentInput"
            rows="4"
            placeholder="Add a comment..."
            onChange={handleUncontrolledInputChange}
            value={commentInput}
          />
          <SubmitButton
            aria-hidden={commentInput === ""}
            tabIndex={commentInput === "" ? -1 : 0}
            type="submit"
            show={commentInput !== ""}
          >
            Submit
          </SubmitButton>
        </TextAreaContainer>
      </FormContainer>
      <CommentsList>
        {searchedCommentsResults.map((comment) => (
          <div key={comment.id}>
            <ProjectComment
              handler={commentHandler}
              comment={comment}
              currentUser={currentUser}
            />
          </div>
        ))}
        {searchedCommentsResults.length === 0 && commentsSearch !== "" && (
          <NoCommentsMessage>No comments for that query</NoCommentsMessage>
        )}
      </CommentsList>
    </Container>
  );
};

export default CommentsContainer;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 800px;
  overflow: auto;
  padding: ${(props) => (props.instruction ? "0px" : "16px")};
`;

const SearchContainer = styled.div`
  cursor: auto;
  position: relative;
  margin-bottom: 16px;
`;

const SearchIcon = styled.i`
  position: absolute;
  pointer-events: none;
  left: 7px;
  top: 6px;
  color: #ababab;
`;

const SearchInput = styled.input`
  width: 100%;
`;

const FormContainer = styled.form`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 16px;
`;

const TextAreaContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

const CommentTextArea = styled.textarea`
  flex: 1;
  overflow: auto;
  min-height: ${(props) => (props.value !== "" ? "100px" : "40px")};
  transition: all 0.4s;
  border-radius: 5px;
  padding-right: 100px;
  resize: none;
  font-family: Arial;
  font-size: 15px;
  padding: 13px 94px 12px 10px;
  border: 1px solid lightgray;

  &:focus {
    box-shadow: 0px 2px 0px 0px #519acc;
    outline: none;
  }
`;

const SubmitButton = styled.button`
  opacity: ${(props) => (props.show ? "1" : "0")};
  position: absolute;
  bottom: 26px;
  right: 16px;
  padding: 14px 20px;
  color: white;
  background-color: #519acc;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.4s;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 0px 0px #578bc2;
  border: none;
  font-family: arial;
  font-size: 13px;
  font-weight: 400;
`;

const CommentsList = styled.div`
  display: flex;
  flex: 1 1 auto;
  gap: 16px;
  flex-direction: column;
`;

const NoCommentsMessage = styled.p`
  color: lightgray;
  padding: 20px 0 0 5px;
`;
