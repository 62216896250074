import React from "react";
import axios from "axios";
import ReactModal from "../modals/react_modal";
import TimeEntrySidebar from "./time_entry_sidebar";
import TimeEntryList from "./time_entry_list";
import TimeEntryWeekList from "./time_entry_week_list";
import TimeEntryNav from "./time_entry_nav";
import moment from "moment";
import Swal from "sweetalert2";
import { CSSTransition } from "react-transition-group";
import { sentryInit } from "../utils";

sentryInit();
class TimeTrackingContainer extends React.Component {
  constructor(props) {
    super(props);
    var url = new URL(window.location.href);
    this.state = {
      modalOpen: false,
      currentModal: "",
      modalData: {},
      selectedDateRangeOption: { value: "day", label: "Day" },
      dateRangeOptions: [
        { value: "day", label: "Day" },
        { value: "week", label: "Week" },
      ],
      timeEntries: this.props.time_entries,
      scheduleTasks: this.props.schedule_tasks,
      userSidebarData: this.props.user_sidebar_data,
      userSickPto: this.props.user_sick_pto,
      projectHours: this.props.project_hours,
      projects: this.props.projects,
      weekRange: this.props.week_range,
      timeEntryWeeks: this.props.time_entry_weeks,
      singleDateFocus: false,
      updatedWeekEntries: [],
      loading: false,
      weekView: false,
      selectedDay: url.searchParams.get("selected_date")
        ? url.searchParams.get("selected_date")
        : this.props.week_range.indexOf(moment().format("YYYY-MM-DD")) !== -1
        ? moment().format("YYYY-MM-DD")
        : this.props.week_range[0],
    };
    this.dayChangeKeyDown = (e) => {
      if (e.keyCode === 78 && e.shiftKey && !this.state.modalOpen) {
        var elem = document.getElementById(
          "time-tracking-date-nav-right-arrow"
        );
        if (elem) {
          elem.click();
        }
      } else if (e.keyCode === 80 && e.shiftKey && !this.state.modalOpen) {
        var elem = document.getElementById("time-tracking-date-nav-left-arrow");
        if (elem) {
          elem.click();
        }
      }
    };
    this.sidebarRef = React.createRef();
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    var url = new URL(window.location.href);
    var selectedDay = url.searchParams.get("selected_date");
    var timeEntryId = url.searchParams.get("time_entry_id");
    var newEntry = url.searchParams.get("new_entry");
    if (selectedDay && timeEntryId && this.state.timeEntries[selectedDay]) {
      var timeEntry = this.state.timeEntries[selectedDay].find(
        (t) => t.id === timeEntryId
      );
      if (timeEntry) {
        this.editTimeEntryModal(timeEntry);
      }
    } else if (selectedDay && newEntry === "true") {
      this.timeEntryModal();
    }
    document.addEventListener("keydown", this.dayChangeKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.dayChangeKeyDown);
  }

  setModal = (bool, page, data) => {
    this.setState({ modalOpen: bool, currentModal: page, modalData: data });
  };

  setSelectedDay = (selectedDay) => {
    this.setState({ selectedDay });
  };

  timeEntryWeekModal = () => {
    var data = {
      userId: this.props.current_user.id,
      projects: this.state.projects,
      clientOptions: this.props.client_options,
      weekRange: this.state.weekRange,
      timeEntryWeeks: this.state.timeEntryWeeks,
      closeButton: true,
      customClass: "sm-modal",
      addEmptyTimeEntryWeek: this.addEmptyTimeEntryWeek,
    };
    this.setModal(true, "add-time-entry-row", data);
  };

  timeEntryModal = () => {
    var data = {
      userId: this.props.current_user.id,
      projects: this.state.projects,
      clientOptions: this.props.client_options,
      date: this.state.selectedDay,
      scheduleTasks: this.state.scheduleTasks[this.state.selectedDay],
      currentEntries: this.state.timeEntries[this.state.selectedDay]
        ? this.state.timeEntries[this.state.selectedDay]
        : [],
      updateEntries: this.updateEntries,
      setLoading: this.setLoading,
      userSickPto: this.state.userSickPto,
      backdropClickCheck: {
        title: "Discard Time Entries",
        text: "Close window without submitting time entries?",
      },
      customClass: "time-entry-modal",
    };
    this.setModal(true, "time-entry", data);
  };

  editTimeEntryModal = (entry) => {
    var data = {
      timeEntry: entry,
      userId: entry.user_id,
      projects: this.state.projects,
      clientOptions: this.props.client_options,
      date: entry.entry_date,
      currentEntries: this.state.timeEntries[entry.entry_date]
        ? this.state.timeEntries[entry.entry_date]
        : [],
      updateEntries: this.updateEntries,
      setLoading: this.setLoading,
      userSickPto: this.state.userSickPto,
      customClass: "time-entry-modal",
      page: "time-entry",
    };
    this.setModal(true, "time-entry", data);
  };

  updateRangeData = (
    selectedDateRangeOption,
    startDate,
    endDate,
    selectedDay
  ) => {
    this.setState({ loading: true });
    axios
      .get("/get_time_entries/", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: { start_date: startDate, end_date: endDate },
      })
      .then((res) => {
        this.setState({
          weekRange: res.data.week_range,
          selectedDateRangeOption,
          timeEntries: res.data.time_entries,
          timeEntryWeeks: res.data.time_entry_weeks,
          projectHours: res.data.project_hours,
          scheduleTasks: res.data.schedule_tasks,
          selectedDay: selectedDay,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(error);
      });
  };

  setLoading = (bool) => {
    this.setState({ loading: bool });
  };

  updateEntries = (data, action, promise) => {
    if (action === "create") {
      try {
        var entries = data.time_entries;
        var projectHours = this.state.projectHours;
        var timeEntryWeeks = this.state.timeEntryWeeks;
        entries.data.forEach((n) => {
          if (projectHours[n.project_id]) {
            projectHours[n.project_id].hours += n.hours;
          } else {
            projectHours[n.project_id] = {
              color: n.color,
              name: n.project_name,
              hours: n.hours,
              time_entry_task_name: n.time_entry_task_name,
            };
          }
        });
        var newEntries = this.state.timeEntries;
        newEntries[entries.entry_date] = newEntries[entries.entry_date]
          ? [...newEntries[entries.entry_date], ...entries.data]
          : (newEntries[entries.entry_date] = entries.data);
        if (data.new_time_entry_weeks.length > 0) {
          timeEntryWeeks = [...timeEntryWeeks, ...data.new_time_entry_weeks];
          timeEntryWeeks.sort((a, b) => {
            if (a.project_name.toLowerCase() > b.project_name.toLowerCase()) {
              return 1;
            } else if (
              a.project_name.toLowerCase() < b.project_name.toLowerCase()
            ) {
              return -1;
            } else if (
              a.time_entry_task_name.toLowerCase() >
              b.time_entry_task_name.toLowerCase()
            ) {
              return 1;
            } else if (
              a.time_entry_task_name.toLowerCase() <
              b.time_entry_task_name.toLowerCase()
            ) {
              return -1;
            } else {
              return 0;
            }
          });
        }
        this.setState(
          {
            timeEntries: newEntries,
            timeEntryWeeks,
            projectHours,
            userSidebarData: data.user_sidebar_data,
            userSickPto: data.user_sick_pto,
            loading: false,
          },
          () => {
            if (this.sidebarRef.current) {
              this.sidebarRef.current.forceUpdate();
            }
          }
        );
      } catch (err) {
        console.error(err);
        this.setState({ loading: false });
        M.toast({
          html: `<span aria-live="">Time Entry created but there was an error updating the page</span>`,
          classes: "red",
        });
      }
    } else if (action === "update") {
      try {
        var entries = this.state.timeEntries;
        var projectHours = this.state.projectHours;
        var timeEntry = data.timeEntry;
        var timeEntryWeeks = this.state.timeEntryWeeks;
        var newTimeEntryWeek = data.newTimeEntryWeek;
        if (entries[timeEntry.entry_date]) {
          var index = entries[timeEntry.entry_date]
            .map((e) => e.id)
            .indexOf(timeEntry.id);
          if (index !== -1) {
            entries[timeEntry.entry_date] = [
              ...entries[timeEntry.entry_date].slice(0, index),
              data.timeEntry,
              ...entries[timeEntry.entry_date].slice(index + 1),
            ];
            if (data.oldProjectId !== timeEntry.project_id) {
              projectHours[data.oldProjectId].hours -= data.oldHours;
              if (projectHours[data.oldProjectId].hours <= 0) {
                delete projectHours[data.oldProjectId];
              }
            }
            if (projectHours[timeEntry.project_id]) {
              projectHours[timeEntry.project_id].hours +=
                data.oldProjectId !== timeEntry.project_id
                  ? timeEntry.hours
                  : timeEntry.hours - data.oldHours;
            } else {
              projectHours[timeEntry.project_id] = {
                color: timeEntry.color,
                name: timeEntry.project_name,
                hours: timeEntry.hours,
                time_entry_task_name: timeEntry.time_entry_task_name,
              };
            }
            if (newTimeEntryWeek) {
              timeEntryWeeks = [...timeEntryWeeks, ...[newTimeEntryWeek]];
              if (timeEntryWeeks.length > 1) {
                timeEntryWeeks.sort((a, b) => {
                  if (
                    a.project_name.toLowerCase() > b.project_name.toLowerCase()
                  ) {
                    return 1;
                  } else if (
                    a.project_name.toLowerCase() < b.project_name.toLowerCase()
                  ) {
                    return -1;
                  } else if (
                    a.time_entry_task_name.toLowerCase() >
                    b.time_entry_task_name.toLowerCase()
                  ) {
                    return 1;
                  } else if (
                    a.time_entry_task_name.toLowerCase() <
                    b.time_entry_task_name.toLowerCase()
                  ) {
                    return -1;
                  } else {
                    return 0;
                  }
                });
              }
            }
            this.setState(
              {
                timeEntries: entries,
                projectHours,
                timeEntryWeeks,
                userSidebarData: data.userSidebarData,
                userSickPto: data.userSickPto,
                loading: false,
              },
              () => {
                if (this.sidebarRef.current) {
                  this.sidebarRef.current.forceUpdate();
                }
              }
            );
          }
        }
      } catch (err) {
        console.error(err);
        this.setState({ loading: false });
        M.toast({
          html: `Time Entry updated but there was an error updating the page`,
          classes: "red",
        });
      }
    } else if (action === "delete") {
      try {
        if (this.state.timeEntries[data.entry_date]) {
          var entries = this.state.timeEntries;
          entries[data.entry_date] = entries[data.entry_date].filter(
            (e) => e.id !== data.time_entry_id
          );
          if (entries[data.entry_date].length === 0) {
            delete entries[data.entry_date];
          }
          var projectHours = this.state.projectHours;
          if (projectHours[data.project_id]) {
            projectHours[data.project_id].hours -= data.hours;
            if (projectHours[data.project_id].hours <= 0) {
              delete projectHours[data.project_id];
            }
          }
          this.setState(
            {
              timeEntries: entries,
              projectHours,
              userSidebarData: data.user_sidebar_data,
              userSickPto: data.user_sick_pto,
              loading: false,
            },
            () => {
              if (this.sidebarRef.current) {
                this.sidebarRef.current.forceUpdate();
              }
            }
          );
        }
      } catch (err) {
        console.error(err);
        this.setState({ loading: false });
        M.toast({
          html: `Entry deleted but there was an error updating the page`,
          classes: "red",
        });
      }
    } else if (action === "week-update") {
      var entries = this.state.timeEntries;
      var updatedEntries = data.time_entries;
      var deletedEntries = data.deleted_entries;
      var projectHours = this.state.projectHours;
      if (updatedEntries.length > 0) {
        updatedEntries.forEach((e) => {
          var index = entries[e.entry_date]
            ? entries[e.entry_date].map((e) => e.id).indexOf(e.id)
            : -1;
          if (projectHours[e.project_id]) {
            if (index !== -1) {
              var diff = e.hours - entries[e.entry_date][index].hours;
              projectHours[e.project_id].hours += diff;
            } else {
              projectHours[e.project_id].hours += e.hours;
            }
          } else {
            projectHours[e.project_id] = {
              color: e.color,
              name: e.project_name,
              hours: e.hours,
              time_entry_task_name: e.time_entry_task_name,
            };
          }
          if (entries[e.entry_date]) {
            if (index !== -1) {
              entries[e.entry_date].splice(index, 1, e);
            } else {
              entries[e.entry_date].push(e);
              if (entries[e.entry_date].length > 1) {
                entries[e.entry_date].sort((a, b) => {
                  if (
                    a.project_name.toLowerCase() > b.project_name.toLowerCase()
                  ) {
                    return 1;
                  } else if (
                    a.project_name.toLowerCase() < b.project_name.toLowerCase()
                  ) {
                    return -1;
                  } else if (
                    a.time_entry_task_name.toLowerCase() >
                    b.time_entry_task_name.toLowerCase()
                  ) {
                    return 1;
                  } else if (
                    a.time_entry_task_name.toLowerCase() <
                    b.time_entry_task_name.toLowerCase()
                  ) {
                    return -1;
                  } else {
                    return 0;
                  }
                });
              }
            }
          } else {
            entries[e.entry_date] = [e];
          }
        });
      }
      if (deletedEntries.length > 0) {
        deletedEntries.forEach((d) => {
          if (entries[d.entry_date]) {
            entries[d.entry_date] = entries[d.entry_date].filter(
              (e) => e.id !== d.id
            );
            if (entries[d.entry_date].length === 0) {
              delete entries[d.entry_date];
            }
            if (projectHours[d.project_id]) {
              projectHours[d.project_id].hours -= d.hours;
              if (projectHours[d.project_id].hours <= 0) {
                delete projectHours[d.project_id];
              }
            }
          }
        });
      }
      this.setState(
        {
          timeEntries: entries,
          projectHours,
          userSidebarData: data.user_sidebar_data,
          userSickPto: data.user_sick_pto,
          loading: false,
          updatedWeekEntries: [],
        },
        () => {
          if (this.sidebarRef.current) {
            this.sidebarRef.current.forceUpdate();
          }
          if (promise) {
            promise("done");
          }
        }
      );
    } else if (action === "week-delete") {
      var id = data.time_entry_week_id;
      var deletedEntries = data.deleted_entries;
      var timeEntryWeeks = this.state.timeEntryWeeks;
      var timeEntries = this.state.timeEntries;
      var index = timeEntryWeeks.map((tw) => tw.id).indexOf(id);
      if (index !== -1) {
        timeEntryWeeks.splice(index, 1);
      }
      if (deletedEntries.length > 0) {
        deletedEntries.forEach((e) => {
          if (timeEntries[e[1]]) {
            timeEntries[e[1]] = timeEntries[e[1]].filter((t) => t.id !== e[0]);
            if (timeEntries[e[1]].length === 0) {
              delete timeEntries[e[1]];
            }
          }
        });
      }
      var projectHours = this.state.projectHours;
      if (projectHours[data.project_id]) {
        projectHours[data.project_id].hours -= data.hours;
        if (projectHours[data.project_id].hours <= 0) {
          delete projectHours[data.project_id];
        }
      }
      this.setState(
        {
          timeEntries,
          projectHours,
          timeEntryWeeks,
          userSidebarData: data.user_sidebar_data,
          userSickPto: data.user_sick_pto,
          loading: false,
        },
        () => {
          if (this.sidebarRef.current) {
            this.sidebarRef.current.forceUpdate();
          }
          var elem = document.getElementById("time-entry-week-list");
          if (elem && !(elem.scrollHeight > elem.clientHeight)) {
            elem = document.getElementById("time-entry-week-heading");
            if (elem) {
              elem.style.left = "0px";
            }
          }
        }
      );
    }
  };

  deleteEntry = (id) => {
    Swal.fire({
      title: `Delete Time Entry`,
      text: `Are you sure you would like to delete this Time Entry?`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.setState({ loading: true });
        axios
          .delete(`/time_tracking/${id}`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            data: {
              page: "time_entry",
            },
          })
          .then((res) => {
            this.updateEntries(res.data, "delete");
            CustomAriaAlert(`Row deleted`);
          })
          .catch((err) => {
            this.setState({ loading: false });
            console.error(err);
          });
      }
    });
  };

  setWeekView = () => {
    if (this.state.weekView && this.state.updatedWeekEntries.length > 0) {
      Swal.fire({
        title: `Save Changes`,
        text: `Would you like to save your unsaved Time Entry changes?`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          var updatePromise = new Promise((resolve, reject) => {
            this.saveUpdatedWeekEntries(resolve);
          });
          updatePromise.then((message) => {
            this.setState({ weekView: false });
          });
        } else {
          this.setState({ weekView: false });
        }
      });
    } else {
      this.setState({ weekView: !this.state.weekView });
    }
  };

  deleteEntryRow = (id) => {
    Swal.fire({
      title: `Delete Time Entries`,
      text: `This action will delete every time entry in this row. Are you sure you would like to continue?`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.setState({ loading: true });
        axios
          .delete(`/delete_time_entry_week/${id}`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            data: {
              page: "time_entry",
            },
          })
          .then((res) => {
            this.updateEntries(res.data, "week-delete");
          })
          .catch((err) => {
            this.setState({ loading: false });
            console.error(err);
          });
      }
    });
  };

  addEmptyTimeEntryWeek = (tw) => {
    if (this.state.timeEntryWeeks.length < 40) {
      this.setState(
        { timeEntryWeeks: [...this.state.timeEntryWeeks, ...[tw]] },
        () => {
          var elem = document.getElementById("time-entry-week-list");
          if (elem && elem.scrollHeight > elem.clientHeight) {
            elem = document.getElementById("time-entry-week-heading");
            if (elem) {
              elem.style.left = "-10px";
            }
          }
        }
      );
    } else {
      M.toast({ html: `Row limit reached`, classes: "red" });
    }
  };

  addUpdatedWeekEntries = (entry) => {
    var updatedWeekEntries = this.state.updatedWeekEntries;
    var index = updatedWeekEntries.map((t) => t.id).indexOf(entry.id);
    if (index !== -1 && isNaN(entry.id) && entry.hours === 0) {
      updatedWeekEntries.splice(index, 1);
      this.setState({ updatedWeekEntries });
    } else if (index !== -1) {
      updatedWeekEntries.splice(index, 1, entry);
      this.setState({ updatedWeekEntries });
    } else {
      this.setState({
        updatedWeekEntries: [...updatedWeekEntries, ...[entry]],
      });
    }
  };

  saveUpdatedWeekEntries = (promise) => {
    this.setState({ loading: true });
    axios
      .post("/create_week_entries", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        time_entries: { entries: this.state.updatedWeekEntries },
        report_pto: false,
        report_sick_time: false,
      })
      .then((res) => {
        this.updateEntries(res.data, "week-update", promise);
        M.toast({
          html: `Time ${
            this.state.updatedWeekEntries.length > 1 ? "entries" : "entry"
          } updated`,
          classes: "green",
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        M.toast({
          html: " There was an error updating time entries",
          classes: "red",
        });
        console.error(err);
      });
  };

  handleSingleFocus = (data) => {
    this.setState({ singleDateFocus: data.focused });
  };

  setSingleFocus = (bool) => {
    this.setState({ singleDateFocus: bool });
  };

  render() {
    return (
      <div id="time-tracking-container" style={{ backgroundColor: "#f2f2f2" }}>
        <ReactModal
          isShowing={this.state.modalOpen}
          page={this.state.currentModal}
          data={this.state.modalData}
          modalAction={this.setModal}
        />
        <div id="time-notes-floaty" />
        <CSSTransition
          unmountOnExit
          in={this.state.loading}
          timeout={500}
          classNames="node-fade"
        >
          <div className="page-loader">
            <div className="page-load-spinner" />
          </div>
        </CSSTransition>
        <div id="clients-container" style={{ maxWidth: "1440px" }}>
          <section
            style={{
              width: "100%",
              minWidth: "780px",
              marginRight: "30px",
              padding: "0 0 20px 0",
            }}
            aria-label="Time Entries"
          >
            <TimeEntryNav
              selectedDateRangeOption={this.state.selectedDateRangeOption}
              setSelectedDay={this.setSelectedDay}
              updateRangeData={this.updateRangeData}
              timeEntryModal={this.timeEntryModal}
              selectedDay={this.state.selectedDay}
              timeEntries={this.state.timeEntries}
              timeEntryWeeks={this.state.timeEntryWeeks}
              weekRange={this.state.weekRange}
              weekView={this.state.weekView}
              setWeekView={this.setWeekView}
              handleSingleFocus={this.handleSingleFocus}
              setSingleFocus={this.setSingleFocus}
              singleDateFocus={this.state.singleDateFocus}
            />
            {this.state.weekView ? (
              <TimeEntryWeekList
                addUpdatedWeekEntries={this.addUpdatedWeekEntries}
                updatedWeekEntries={this.state.updatedWeekEntries}
                weekRange={this.state.weekRange}
                setSelectedDay={this.setSelectedDay}
                deleteEntryRow={this.deleteEntryRow}
                timeEntryWeeks={this.state.timeEntryWeeks}
                timeEntries={this.state.timeEntries}
                saveUpdatedWeekEntries={this.saveUpdatedWeekEntries}
                timeEntryWeekModal={this.timeEntryWeekModal}
              />
            ) : (
              <TimeEntryList
                selectedDay={this.state.selectedDay}
                editTimeEntry={this.editTimeEntryModal}
                deleteEntry={this.deleteEntry}
                timeEntries={this.state.timeEntries[this.state.selectedDay]}
              />
            )}
          </section>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TimeEntrySidebar
              dataSection={this.state.userSidebarData}
              // hiding for now
              // eodButton={true}
              userSickPto={this.state.userSickPto}
              groupHours={this.state.projectHours}
              text="Hours Worked"
              page="time-entry"
              week={
                this.state.selectedDateRangeOption.value === "day" ||
                this.state.selectedDateRangeOption.value === "week"
              }
              ref={this.sidebarRef}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TimeTrackingContainer;
