import React, { useContext } from "react";
import HelpIcon from "components/icons/help_icon";
import RoundDropdown from "components/projects/round_dropdown";
import EditIcon from "components/icons/edit_icon";
import DeletePlainIcon from "components/icons/delete_plain_icon";
import HelpContext from "./HelpContext";
import EditHelpPageForm from "./EditHelpPageForm";
import {
  OuterHelpContainer,
  HelpHeaderContainer,
  HelpTitle,
  HelpIconContainer,
  HelpDetailsContainer,
  HelpTopicsList,
  HelpDetailsBody,
  HelpTopicHeaderOuterContainer,
  HelpTopicHeaderInnerContainer,
  EditControls,
  HelpTopicContent,
  HelpDetailWrapper,
  BreadcrumbsContainer,
  TopicsCount,
  HelpTopicHeadline,
  HelpTopicUpdatedAt,
} from "./HelpStyled";

const HelpDetail = () => {
  const {
    activePage,
    handleBack,
    pages,
    setCurrentPage,
    isEditing,
    currentUser,
    toggleEditMode,
    deleteClick,
  } = useContext(HelpContext);

  return (
    <HelpDetailWrapper className="help-show">
      <OuterHelpContainer>
        <HelpHeaderContainer>
          <HelpTitle>
            <HelpIconContainer>
              <HelpIcon width="30px" height="30px" />
            </HelpIconContainer>
            <h2>SyncIt Help</h2>
          </HelpTitle>
          <BreadcrumbsContainer
            className="flex-container flex-al-center"
            style={{ marginTop: "16px" }}
          >
            <button onClick={handleBack} type="button">
              Help
            </button>
            <RoundDropdown
              color="lightgrey"
              height="10px"
              width="10px"
              rotate="rotate(270deg)"
            />
            <RoundDropdown
              color="lightgrey"
              height="10px"
              width="10px"
              rotate="rotate(270deg) translateY(-4px)"
            />
            <div>{activePage.topic}</div>
          </BreadcrumbsContainer>
        </HelpHeaderContainer>
        <HelpDetailsContainer>
          <HelpTopicsList>
            <TopicsCount className="help-manrope">
              {pages.filter((x) => x.topic === activePage.topic).length} Topics
            </TopicsCount>
            <div className="help-scroll">
              {pages
                .filter((x) => x.topic === activePage.topic)
                .map((page, index) => {
                  return (
                    <div className="help-item innie" key={index}>
                      <button
                        type="button"
                        style={{
                          fontStyle: "italic",
                          color: `${
                            activePage.id === page.id ? "#519acc" : "#242424"
                          }`,
                        }}
                        value={page.id}
                        onClick={() => setCurrentPage(page.id)}
                      >
                        {page.title === ""
                          ? "Unfinished Help Page"
                          : page.title}
                      </button>
                    </div>
                  );
                })}
            </div>
          </HelpTopicsList>
          <HelpDetailsBody>
            {isEditing === false && (
              <div className="editable-false">
                <HelpTopicHeaderOuterContainer className="flex-container flex-al-center">
                  <HelpTopicHeaderInnerContainer>
                    {activePage && activePage.id !== -1 ? (
                      <div>
                        <HelpTopicHeadline>
                          {activePage.title}
                        </HelpTopicHeadline>
                        <HelpTopicUpdatedAt>
                          Last Updated: {activePage.updated_at}
                        </HelpTopicUpdatedAt>
                      </div>
                    ) : (
                      <div>
                        <p>Choose an article on the left</p>
                      </div>
                    )}
                    {currentUser &&
                      currentUser.role_id >= 4 &&
                      activePage &&
                      activePage.id !== -1 && (
                        <EditControls>
                          <button onClick={toggleEditMode} type="button">
                            <EditIcon height={20} width={20} />
                          </button>
                          <button onClick={deleteClick} type="button">
                            <DeletePlainIcon height={20} width={20} />
                          </button>
                        </EditControls>
                      )}
                  </HelpTopicHeaderInnerContainer>
                </HelpTopicHeaderOuterContainer>
                <HelpTopicContent
                  dangerouslySetInnerHTML={{
                    __html: activePage.body,
                  }}
                  className="trix-Datacontent"
                />
              </div>
            )}
            {isEditing === true && <EditHelpPageForm />}
          </HelpDetailsBody>
        </HelpDetailsContainer>
      </OuterHelpContainer>
    </HelpDetailWrapper>
  );
};

export default HelpDetail;
