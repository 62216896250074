import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import BookingDetailsIcon from "components/icons/booking_details_icon";
import ExitIcon from "../icons/exit_icon";

const TaskNotesShow = ({ data, modalAction }) => {
  const headerRef = useRef(null);

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.focus();
    }
    return () => {
      const elem = data.cardRef;
      if (elem && elem.current) {
        elem.current.focus();
      }
    };
  }, [data.cardRef]);

  const closeModal = () => {
    modalAction(false, "", {});
  };

  return (
    <ModalContent id="task-notes-modal">
      <HeaderIconWrapper>
        <IconTextWrapper>
          <IconWrapper aria-hidden="true">
            <BookingDetailsIcon />
          </IconWrapper>
          <TextWrapper>
            <Heading tabIndex={0} ref={headerRef} id="booking_name_header">
              Booking Details
            </Heading>
            <Details>
              Created by {data.createdBy} for {data.userName}
            </Details>
          </TextWrapper>
        </IconTextWrapper>

        <button
          type="button"
          id="modalClose"
          aria-label="Close Modal"
          className="btn-icon"
          onClick={() => closeModal()}
          style={{ padding: "0px" }}
        >
          <ExitIcon
            svgStyles={{ pointerEvents: "none" }}
            height="25px"
            width="25px"
          />
        </button>
      </HeaderIconWrapper>

      <DetailWrapper className="col s12">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {data.clientName && (
            <p className="detail">
              <span>Client:</span> <br />
              {data.clientName}
            </p>
          )}
          <p className="detail">
            <span>Project:</span> <br />
            {data.name}
          </p>
          <p className="detail">
            <span>Hours:</span> <br />
            {data.hours}
          </p>
        </div>

        <p className="detail">
          <span>Notes:</span> <br /> {data.notes}
        </p>
        <p className="detail">
          <span>Booking Status:</span> <br />
          {data.projectConfirmation ? "Confirmed" : "Unconfirmed"}
        </p>
      </DetailWrapper>
    </ModalContent>
  );
};

export default TaskNotesShow;

const ModalContent = styled.div`
  padding: 32px;
  border-radius: 10px;
  opacity: 0px;
`;

const HeaderIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 32px;
`;

const IconTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;
const IconWrapper = styled.div`
  background-color: #519acc;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Heading = styled.h2`
  font-size: 18px;
  margin: 0;
  font-family: Manrope;
  font-weight: 600;
  outline: none;
`;
const Details = styled.p`
  font-size: 13px;
  color: #000;
  font-weight: 400;
  margin: 0;
`;

const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 0;
  .detail {
    margin: 0px !important;
    font-family: Arial;
    font-size: 13px;
    font-style: italic;
    font-weight: 400;

    span {
      font-family: Manrope;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
    }
  }
`;
