import React from "react";
import axios from "axios";

class UserTaskWeekRow extends React.Component {
  constructor(props) {
    super(props);
    this.viewNotes = this.viewNotes.bind(this);
  }

  renderStatus() {
    const status = this.props.taskStatus;
    const { created_by } = this.props;
    if (
      this.props.taskName === "Unavailability" ||
      this.props.taskName === "Vacation" ||
      this.props.taskName === "Unavailable" ||
      this.props.taskName === "PT Limit" ||
      this.props.taskName === "Sick Time"
    ) {
      return <div />;
    }
    if (status === null) {
      return <div className="nil-status-col" />;
    }
    if (status === true) {
      return <div className="true-status-col" />;
    }
    if (status === false) {
      return <div className="false-status-col" />;
    }
    return <div />;
  }

  viewNotes() {
    const { created_by } = this.props;
    if (created_by) {
      axios
        .get(`/api/v1/users/${created_by}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const data = {
            createdBy: res.data.name,
            hours: `${this.props.taskHours} hours`,
            id: this.props.taskId,
            clientName: this.props.client_name,
            name: this.props.taskName,
            notes: this.props.notes
              ? this.props.notes
              : "No notes for this booking.",
            projectConfirmation: this.props.project_confirmation,
          };
          this.props.modalAction(true, "booking-details", data);
        })
        .catch((err) => console.error(err));
    } else {
      const data = {
        createdBy: "",
        hours: `${this.props.taskHours} hours`,
        id: this.props.taskId,
        clientName: this.props.client_name,
        name: this.props.taskName,
        notes: this.props.notes
          ? this.props.notes
          : "No notes for this booking.",
        projectConfirmation: this.props.project_confirmation,
      };
      this.props.modalAction(true, "booking-details", data);
    }
  }

  render() {
    const name = this.props.taskName;
    let cardColor;
    if (
      name === "Vacation" ||
      name === "Unavailable" ||
      this.props.taskName === "PT Limit" ||
      this.props.taskName === "Sick Time"
    ) {
      cardColor = "#444444";
    } else if (name === "Reoccurring: Unavailable") {
      cardColor = "#6b6a6a";
    } else {
      cardColor = this.props.cardColor;
    }
    const textColor = "#FFFFFF";
    return (
      <div
        role="button"
        tabIndex={-1}
        id={`task-${this.props.taskId}`}
        aria-label={`${this.props.taskName}, ${this.props.taskHours} ${
          this.props.taskHours > 1 ? "hours" : "hour"
        }${this.props.project_confirmation ? "" : ", unconfirmed"}`}
        className="row card day-card valign-wrapper"
        onClick={this.viewNotes}
        style={{
          backgroundColor: cardColor,
          color: textColor,
          cursor: "pointer",
          outline: "none",
        }}
      >
        <div
          aria-hidden
          className={`col ${
            this.props.twoWeeks
              ? "s11 truncate card-proj-name-small"
              : "s9 m7 l9 truncate card-proj-name"
          }`}
        >
          {this.props.taskName}
        </div>
        {!this.props.project_confirmation ? (
          <div
            className={`col s1 card-proj-name${
              this.props.twoWeeks ? "-small" : ""
            }`}
            style={{
              padding: `${this.props.twoWeeks ? "0 1rem" : "0 0.75rem"}`,
            }}
          >
            <i className="inline-icon material-icons white-text">
              help_outline
            </i>
          </div>
        ) : (
          ""
        )}
        <div
          aria-hidden
          className={`col s1 card-proj-name${
            this.props.twoWeeks ? "-small" : ""
          }`}
        >
          {this.props.taskHours}
        </div>
        {this.props.twoWeeks ? (
          ""
        ) : (
          <div className="col s2">{this.renderStatus()}</div>
        )}
      </div>
    );
  }
}

export default UserTaskWeekRow;
