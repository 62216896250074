import React from "react";
import axios from "axios";
import ReactModal from "../modals/react_modal";
import TimeEntrySidebar from "./time_entry_sidebar";
import { CSSTransition } from "react-transition-group";
import moment from "moment";
import TimeObjectsContainer from "./time_objects_container";
import Swal from "sweetalert2";
import { sentryInit } from "../utils";

sentryInit();

class ReportsContainer extends React.Component {
  constructor(props) {
    var searchUrl = new URL(window.location.href);
    var params = searchUrl.searchParams;
    var initialRange = { value: "week", label: "Week" };
    if (params.has("start_date") && params.has("end_date")) {
      var a = moment(params.get("end_date"));
      var b = moment(params.get("start_date"));
      var diff = a.diff(b, "days") + 1;
      var weekDiff = a.diff(b, "weeks");
      if (diff === 5) {
        initialRange = { value: "week", label: "Week" };
      } else if (weekDiff === 4) {
        initialRange = { label: "Month", value: "month" };
      } else if (weekDiff >= 12 && weekDiff <= 13) {
        initialRange = { label: "Quarter", value: "quarter" };
      } else if (weekDiff === 52) {
        initialRange = { label: "Year", value: "year" };
      } else {
        initialRange = { label: "Custom", value: "custom" };
      }
    }
    super(props);
    this.state = {
      modalOpen: false,
      currentModal: "",
      modalData: {},
      selectedDateRangeOption: initialRange,
      dateRangeOptions: [
        { value: "day", label: "Day" },
        { value: "week", label: "Week" },
      ],
      timeEntries: this.props.time_entries,
      objectHours: this.props.objectHours,
      secondGroup: this.props.secondGroup,
      percent: this.props.percent,
      objects: this.props.objects,
      objectName: this.props.objectName,
      loading: false,
      projects: this.props.projects,
      weekRange: this.props.week_range,
      userSidebarData: this.props.user_sidebar_data,
      selectedDay:
        this.props.week_range.indexOf(moment().format("YYYY-MM-DD")) !== -1
          ? moment().format("YYYY-MM-DD")
          : this.props.week_range[0],
    };
    this.sidebarRef = React.createRef();
    this.dynamicListRef = React.createRef();
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  }

  setModal = (bool, page, data) => {
    this.setState({ modalOpen: bool, currentModal: page, modalData: data });
  };

  setSelectedDay = (selectedDay) => {
    this.setState({ selectedDay });
  };

  setSelectedDateRangeOption = (selectedRange) => {
    this.setState({ selectedDateRangeOption: selectedRange });
  };

  setLoading = (bool) => {
    this.setState({ loading: bool });
  };

  updateRangeData = (selectedDateRangeOption, startDate, endDate) => {
    var url;
    if (this.props.show) {
      url = `/time_tracking/${this.props.activeObjectName}s/${this.props.activeObject.id}/report_time_entry.json`;
    } else if (this.props.nested) {
      if (
        this.props.objectName === "user" &&
        this.props.nestedObject.name === "clients"
      ) {
        url = `/time_tracking/${this.props.activeObjectName}s/${this.props.activeObject.id}/users/report_time_entry.json`;
      } else if (this.props.objectName === "user") {
        url = `/time_tracking/${this.props.activeObjectName}s/${this.props.activeObject.id}/${this.props.nestedObject.name}/report_time_entry.json`;
      } else {
        url = `/time_tracking/${this.props.objectName}s/${this.props.activeObject.id}/${this.props.nestedObject.name}/report_time_entry.json`;
      }
    } else {
      url = `/time_tracking/${this.props.objectName}s/report_time_entries.json`;
    }
    var searchUrl = new URL(window.location.href);
    var params = searchUrl.searchParams;
    params.set("start_date", startDate);
    params.set("end_date", endDate);
    history.pushState({}, null, "?" + params.toString());
    this.setState({ loading: true });
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: { start_date: startDate, end_date: endDate },
      })
      .then((res) => {
        this.setState({
          weekRange: res.data.week_range,
          selectedDateRangeOption,
          secondGroup: res.data.secondGroup,
          objects: res.data.objects,
          objectHours: res.data.object_hours,
          loading: false,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  updateEntries = (data, action) => {
    if (action === "update") {
      var entries = this.state.objects;
      var objectHours = this.props.objectHours;
      var timeEntry = data.timeEntry;
      if (entries[timeEntry.entry_date]) {
        var index = entries[timeEntry.entry_date]
          .map((e) => e.id)
          .indexOf(timeEntry.id);
        if (index !== -1) {
          entries[timeEntry.entry_date] = [
            ...entries[timeEntry.entry_date].slice(0, index),
            data.timeEntry,
            ...entries[timeEntry.entry_date].slice(index + 1),
          ];
          if (data.oldProjectId !== timeEntry.project_id) {
            objectHours[data.oldProjectId].hours -= data.oldHours;
            if (objectHours[data.oldProjectId].hours <= 0) {
              delete objectHours[data.oldProjectId];
            }
          }
          if (objectHours[timeEntry.project_id]) {
            objectHours[timeEntry.project_id].hours +=
              data.oldProjectId !== timeEntry.project_id
                ? timeEntry.hours
                : timeEntry.hours - data.oldHours;
          } else {
            objectHours[timeEntry.project_id] = {
              color: timeEntry.color,
              name: timeEntry.project_name,
              hours: timeEntry.hours,
              time_entry_task_name: timeEntry.time_entry_task_name,
            };
          }
          this.setState(
            {
              objects: entries,
              objectHours,
              userSidebarData: data.userSidebarData,
              loading: false,
            },
            () => {
              if (this.dynamicListRef && this.dynamicListRef.current) {
                this.dynamicListRef.current.recomputeRowHeights();
              }
              if (this.sidebarRef.current) {
                this.sidebarRef.current.forceUpdate();
              }
            }
          );
        }
      }
    } else if (action === "delete") {
      if (this.state.objects[data.entry_date]) {
        var entries = this.state.objects;
        entries[data.entry_date] = entries[data.entry_date].filter(
          (e) => e.id !== data.time_entry_id
        );
        if (entries[data.entry_date].length === 0) {
          delete entries[data.entry_date];
        }
        var objectHours = this.props.objectHours;
        if (objectHours[data.project_id]) {
          objectHours[data.project_id].hours -= data.hours;
          if (objectHours[data.project_id].hours <= 0) {
            delete objectHours[data.project_id];
          }
        }
        this.setState(
          {
            objects: entries,
            objectHours,
            userSidebarData: data.user_sidebar_data,
            loading: false,
          },
          () => {
            if (this.dynamicListRef && this.dynamicListRef.current) {
              this.dynamicListRef.current.recomputeRowHeights();
            }
            if (this.sidebarRef.current) {
              this.sidebarRef.current.forceUpdate();
            }
          }
        );
      }
    }
  };

  editTimeEntry = (entry) => {
    var data = {
      timeEntry: entry,
      userId: entry.used_id,
      projects: this.props.projects,
      clientOptions: this.props.client_options,
      date: entry.entry_date,
      currentEntries: this.state.objects[entry.entry_date]
        ? this.state.objects[entry.entry_date]
        : [],
      updateEntries: this.updateEntries,
      setLoading: this.setLoading,
      customClass: "time-entry-modal",
      page: "reports",
    };
    this.setModal(true, "time-entry", data);
  };

  deleteEntry = (id) => {
    Swal.fire({
      title: `Delete Time Entry`,
      text: `Are you sure you would like to delete this Time Entry?`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`/reports_delete_entry/${id}`)
          .then((res) => {
            this.updateEntries(res.data, "delete");
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  setLoading = (option) => {
    this.setState({ loading: option });
  };

  render() {
    return (
      <CSSTransition
        timeout={{
          enter: 300,
          exit: 300,
          appear: 800,
        }}
        in={true}
        appear={true}
        classNames={"activity-fade"}
      >
        <div style={{ backgroundColor: "#f2f2f2" }}>
          <ReactModal
            isShowing={this.state.modalOpen}
            page={this.state.currentModal}
            data={this.state.modalData}
            modalAction={this.setModal}
          />
          <div
            id="clients-container"
            style={{ maxWidth: "1440px", overflowX: "auto" }}
          >
            <section
              style={{
                width: "100%",
                marginRight: "30px",
                minWidth: "950px",
                padding: "0 0 20px 0",
              }}
              aria-label="Reports"
              tabIndex={0}
              id="page-nav-title"
            >
              <TimeObjectsContainer
                cameFromClients={this.props.cameFromClients}
                currentUser={this.props.current_user}
                loading={this.state.loading}
                selectedDateRangeOption={this.state.selectedDateRangeOption}
                setSelectedDay={this.setSelectedDay}
                activeObject={this.props.activeObject}
                activeObjectName={this.props.activeObjectName}
                objects={this.state.objects}
                objectName={this.state.objectName}
                setSelectedRange={this.setSelectedRange}
                setSelectedDateRangeOption={this.setSelectedDateRangeOption}
                updateRangeData={this.updateRangeData}
                selectedDay={this.state.selectedDay}
                weekRange={this.state.weekRange}
                show={this.props.show}
                nested={this.props.nested}
                nestedObject={this.props.nestedObject}
                setLoading={this.setLoading}
                projects={this.state.projects}
                setObjectHours={this.setObjectHours}
                objectHours={this.state.objectHours}
                deleteEntry={this.deleteEntry}
                editTimeEntry={this.editTimeEntry}
                updateEntries={this.updateEntries}
                dynamicListRef={this.dynamicListRef}
              />
            </section>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TimeEntrySidebar
                ref={this.sidebarRef}
                dataSection={this.state.userSidebarData}
                groupHours={this.state.objectHours}
                secondGroup={this.state.secondGroup}
                selectedDateRangeOption={this.state.selectedDateRangeOption}
                text="Hours Worked"
                week={false}
                percent={this.state.percent}
                userSickPto={this.props.user_sick_pto}
                activeObjectName={this.props.activeObjectName}
              />
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  }
}

export default ReportsContainer;
