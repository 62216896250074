import React from "react";
import { List, AutoSizer } from "react-virtualized";
import styled from "styled-components";
import RoundDropdown from "../projects/round_dropdown";
import ProjectCard from "./project_card";

class ProjectIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: this.props.projects,
      clients: this.props.clients,
      inactiveProjects: this.props.inactiveProjects,
      sortColumn: this.props.clientView ? "project" : "client",
      direction: "asc",
    };
    this.randNum = this.props.randNum ? this.props.randNum : 0;
  }

  componentDidMount() {
    if (this.props.clientView) {
      this.sortProjects();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.projects.length != prevProps.projects.length) {
      this.sortProjects();
    }
    if (this.props.selectedStatus != prevProps.selectedStatus) {
      this.setState({
        direction: "asc",
        sortColumn: this.props.clientView ? "project" : "client",
      });
    }
  }

  handleSort = (sortColumn) => {
    var direction =
      this.state.sortColumn === sortColumn
        ? this.state.direction === "desc"
          ? "asc"
          : "desc"
        : this.state.direction;

    this.setState({ sortColumn, direction }, () => {
      this.sortProjects();
    });
  };

  alphaSort = (items, key1, key2) => {
    var direction = this.state.direction;
    items.sort((a, b) => {
      var a = key2
        ? a[key1]
          ? a[key1][key2].toLowerCase()
          : ""
        : a[key1].toLowerCase();
      var b = key2
        ? b[key1]
          ? b[key1][key2].toLowerCase()
          : ""
        : b[key1].toLowerCase();
      return a > b
        ? direction === "asc"
          ? 1
          : -1
        : a < b
        ? direction === "asc"
          ? -1
          : 1
        : 0;
    });
  };

  numSort = (items, key) => {
    var direction = this.state.direction;
    items.sort((a, b) =>
      direction === "asc" ? a[key] - b[key] : b[key] - a[key]
    );
  };

  clientSort = (a, b) => {
    var direction = this.state.direction;
    if (a.client_name === b.clientPath) {
      var a = a.name.toLowerCase();
      var b = b.name.toLowerCase();
      return a > b ? 1 : a < b ? -1 : 0;
    } else {
      var a = a.client_name.toLowerCase();
      var b = b.client_name.toLowerCase();
      return a > b
        ? direction === "asc"
          ? 1
          : -1
        : a < b
        ? direction === "asc"
          ? -1
          : 1
        : 0;
    }
  };

  remainingSort = (a, b) => {
    var direction = this.state.direction;
    var a =
      a.budgeted_hours === 0 || a.invoicing_type === 0
        ? -1000
        : (a.budgeted_hours - a.tracked_hours) / a.budgeted_hours;
    var b =
      b.budgeted_hours === 0 || b.invoicing_type === 0
        ? -1000
        : (b.budgeted_hours - b.tracked_hours) / b.budgeted_hours;
    return direction === "asc" ? a - b : b - a;
  };

  statusSort = (a, b) => {
    var direction = this.state.direction;
    var aStatus = a.pending ? 3 : a.active ? 1 : 2;
    var bStatus = b.pending ? 3 : b.active ? 1 : 2;
    if (aStatus === bStatus) {
      var a = a.name.toLowerCase();
      var b = b.name.toLowerCase();
      return a > b ? 1 : a < b ? -1 : 0;
    } else {
      return direction === "asc" ? aStatus - bStatus : bStatus - aStatus;
    }
  };

  customSort = (items, key1, key2) => {
    const prettyWordMap = {
      leading: "Lead Created",
      est_requesting: "Estimate Request",
      est_sending: "Estimate Sent",
      project_confirming: "Project Confirmed",
      testing: "Test In Progress",
      completing: "Project Complete",
      readying: "Ready for Invoice",
      invoicing: "Invoiced",
    };

    let direction = this.state.direction;
    items.sort((a, b) => {
      var aVal =
        a[key1] && a[key1][key2]
          ? prettyWordMap[a[key1][key2]]
          : "Not on Taskboard";
      var bVal =
        b[key1] && b[key1][key2]
          ? prettyWordMap[b[key1][key2]]
          : "Not on Taskboard";

      return aVal.localeCompare(bVal) * (direction === "asc" ? 1 : -1);
    });
  };

  sortProjects = () => {
    var projects = this.props.projects;

    switch (this.state.sortColumn) {
      case "client":
        projects.sort(this.clientSort);
        break;
      case "project":
        this.alphaSort(projects, "name");
        break;
      case "type":
        this.alphaSort(projects, "tag", "name");
        break;
      case "budget":
        this.numSort(projects, "budgeted_hours");
        break;
      case "logged":
        this.numSort(projects, "tracked_hours");
        break;
      case "workflow":
        this.customSort(projects, "project_status", "aasm_state");
        break;
      case "remaining":
        projects.sort(this.remainingSort);
        break;
      case "status":
        projects.sort(this.statusSort);
        break;
    }
    this.setState({ projects });
  };

  renderProjects = (props) => {
    var p = this.state.projects[props.index];
    if (
      this.props.dynamicListRef &&
      this.props.dynamicListRef.current &&
      this.props.dynamicListRef.current.Grid &&
      this.props.dynamicListRef.current.Grid._scrollingContainer
    ) {
      this.props.dynamicListRef.current.Grid._scrollingContainer.removeAttribute(
        "aria-label"
      );
      this.props.dynamicListRef.current.Grid._scrollingContainer.removeAttribute(
        "role"
      );
    }
    return (
      <div
        aria-label={`Project ${p.name}`}
        key={props.key}
        style={props.style}
        className="project_table"
      >
        <ProjectCard
          selectedClient={this.props.selectedClient}
          handleClientClick={this.props.handleClientClick}
          clientView={this.props.clientView}
          loadProject={this.props.loadProject}
          project={p}
          clientPath={p.id}
        />
      </div>
    );
  };

  noRows() {
    return (
      <p
        style={{
          textAlign: "center",
          marginTop: "40px",
          fontSize: "16px",
          color: "#757575",
        }}
      >
        No Projects Found
      </p>
    );
  }

  renderHeaderButton = (label, field) => {
    const isActive = this.state.sortColumn === field;
    const isDescending = this.state.direction === "desc";
    const rotation = isDescending ? "rotate(180deg)" : "rotate(0deg)";

    return (
      <button
        aria-live="assertive"
        aria-label={`${label}${
          isActive
            ? `, active${isDescending ? ", descending" : ", ascending"}`
            : ""
        }`}
        onClick={() => this.handleSort(field)}
        className={`${isActive ? "active" : ""} column_header`}
        id={`${field}_field`}
      >
        {label}
        {isActive && <RoundDropdown rotate={rotation} />}
      </button>
    );
  };

  render() {
    const headerFields = [
      { field: "project", label: "Project Name" },
      !this.props.clientView && { field: "client", label: "Client Name" },
      { field: "budget", label: "Budget" },
      { field: "type", label: "Type" },
      { field: "workflow", label: "Taskboard Status" },
      { field: "status", label: "Status" },
    ].filter(Boolean);
    return (
      <div style={{ height: "100%", padding: "16px" }}>
        {this.props.clientView && (
          <BackToClientsContainer aria-label="breadcrumb navigation">
            <StyledButton
              role="button"
              className="btn-flat"
              onClick={() => window.history.back()}
              aria-label="Clients Breadcrumb"
            >
              Clients
            </StyledButton>
            <StyledSpan gray>{` >> `} &nbsp;</StyledSpan>
            <StyledSpan marginLeft aria-label="Current Client Name Breadcrumb">
              {this.props.client.name}
            </StyledSpan>
          </BackToClientsContainer>
        )}

        <ProjectsColumnHeaders
          aria-label="project list sorting options"
          style={{ padding: "15px 34.5px 15px 25px", display: "flex" }}
          key="project-headers"
        >
          {headerFields.map(({ field, label }) => (
            <span
              key={field}
              style={{
                flex: 1,
                display: "flex",
                justifyContent: field === "status" ? "end" : "normal",
                paddingLeft: field === "budget" ? "8px" : "0",
                position: "relative",
                left: this.props.clientView && field === "type" ? "20px" : "0",
                // maxWidth: field === "type" ? "20px" : "initial",
              }}
            >
              {this.renderHeaderButton(label, field)}
            </span>
          ))}
        </ProjectsColumnHeaders>

        <div
          key="project-list"
          aria-label={`Displaying ${this.state.projects.length} Projects`}
          style={{
            height: `calc(100% - ${this.props.clientView ? "208px" : "192px"})`,
            border: "1px solid #d7d7d7",
            padding: "8px 1px 8px 8px",
          }}
        >
          <AutoSizer>
            {({ height, width }) => (
              <List
                ref={this.props.dynamicListRef}
                rowHeight={54}
                rowCount={this.state.projects.length}
                height={height}
                overscanRowCount={5}
                width={width}
                noRowsRenderer={this.noRows}
                style={{
                  paddingRight:
                    this.state.projects.length * 60 >= height ? "0" : "0px",
                  overflowY: "scroll",
                }}
                rowRenderer={this.renderProjects}
                scrollToAlignment="start"
              />
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

const ProjectsColumnHeaders = styled.div`
  display: flex;
  color: #6b6b6b;

  font-family: Arial;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  padding: 15px 15px 15px 15px;
  align-items: center;
  height: 40px;
  width: calc(100% - 12px);

  //apply flex 0 1 0% to last span to prevent it from growing
  > span:last-child {
    flex: 0 1 0% !important;
  }
  svg {
    pointer-events: none;
    position: absolute;
    display: block;
    right: -20px;
    bottom: 0px;
  }

  button {
    position: relative;
    border: none;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: transparent;
    background-color: transparent !important;
    cursor: pointer;
    color: #6b6b6b;
    text-align: left;
    padding: 0px;
    z-index: 2;
    transition: border-color 0.3s;
  }

  button::before {
    content: "";
    height: 40px;
    width: 100px;
    top: -15px;
    display: block;
    position: absolute;
    background-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: transparent;
    z-index: -1;
    left: -15px;
    transition: border-color 0.3s, background-color 0.3s;
  }

  button.active::before {
    border-bottom-color: #519acc;
  }

  button:hover::before {
    background-color: #e6e6e6;
  }

  button#project_field::before {
    width: 103px;
  }
  button#project_field.active::before {
    width: 113px;
  }

  button#client_field::before {
    width: 95px;
  }
  button#client_field.active::before {
    width: 105px;
  }

  #type_field {
    margin-left: 10px;
  }
  button#type_field::before {
    width: 59px;
  }
  button#type_field.active::before {
    width: 69px;
  }

  button#budget_field::before {
    width: 70px;
  }
  button#budget_field.active::before {
    width: 80px;
  }

  button#workflow_field::before {
    width: 120px;
  }
  button#workflow_field.active::before {
    width: 138px;
  }

  button#status_field:disabled::before {
    background-color: white !important;
  }
  button#status_field:disabled {
    cursor: initial;
  }
  button#status_field::before {
    width: 66px;
  }
  button#status_field.active::before {
    width: 76px;
  }

  > div.header_left_section {
    width: calc(100% - 518px);
    display: flex;
    align-items: center;
  }
  > div.header_left_section > span:nth-child(1),
  > div.header_left_section > span:nth-child(2) {
    width: 40%;
    min-width: 165px;
  }
  > div.header_left_section > span:nth-child(3) {
    width: 20%;
    min-width: 115px;
    text-align: center;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled.button`
  padding: 0;
  font-family: "Manrope";
  font-weight: 700;
  color: #519acc;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-decoration-line: underline;
`;

const StyledSpan = styled.span`
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: ${(props) => (props.gray ? "#C0C0C0" : "#242424")};
  letter-spacing: ${(props) => (props.gray ? "-4.2px" : "normal")};
  margin-left: ${(props) => (props.marginLeft ? "8px" : "4px")};
  white-space: nowrap;
`;

const BackToClientsContainer = styled(FlexContainer)`
  width: 130px;
  height: 22px;
`;
export default ProjectIndex;
