import React, { useState } from "react";
import styled from "styled-components";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import DynamicList, { createCache } from "react-window-dynamic-list";
import AutoSizer from "react-virtualized-auto-sizer";
import ActivityCard from "./activity_card";

const cache = createCache();

const ActivityFeed = styled.div.attrs((props) => ({
  page: props.page,
}))`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #d7d7d7;
  overflow: hidden;
  flex: 2;
  width: 300px;
  max-height: ${(props) =>
    props.page === "clients" || props.page === "projects" ? "100%" : "70%"};
  transition: height 0.3s;
  >div{height: calc(100% - 76px)}
  div.inner-div {
    height: 100%;
    overflow: auto;
  }
  p.title-box {
    margin: 0 0 10px;
    font-size: 16px;
    margin: 0px;
    white-space: pre;
    padding: 24px 16px;
    border-bottom: 1px solid #d1d1d1;
    
  }
  p.title-box > span {
    color:#519acc;
    max-width: 165px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: top;
  }
  }
`;

function ActivityFeedContainer({
  activities,
  currentUser,
  currentView,
  title,
}) {
  const renderList = () => {
    const indexNode = [];
    if (activities.length > 0) {
      activities.forEach((a) => {
        indexNode.push({
          id: a.id,
          node: (
            <ActivityCard currentUser={currentUser} key={a.id} activity={a} />
          ),
        });
      });
      const row = ({ index, style }) => (
        <div style={style}>{indexNode[index].node}</div>
      );
      return (
        <AutoSizer>
          {({ height, width }) => (
            <DynamicList
              height={height}
              width={width}
              cache={cache}
              data={indexNode}
            >
              {row}
            </DynamicList>
          )}
        </AutoSizer>
      );
    }
    return (
      <p style={{ color: "#b1b1b1", textAlign: "center" }}>
        No activity present
      </p>
    );
  };
  return (
    <ActivityFeed page={currentView}>
      <p
        className="title-box manrope semi-bold"
        style={{ fontFamily: "manrope", fontWeight: "600", fontSize: "18px" }}
        role="heading"
      >
        Activity Feed
        {" - "}
        <SwitchTransition mode="out-in">
          <CSSTransition
            timeout={300}
            key={currentView}
            classNames="activity-fade"
          >
            <span>{title}</span>
          </CSSTransition>
        </SwitchTransition>
      </p>
      <SwitchTransition mode="out-in">
        <CSSTransition
          timeout={300}
          key={currentView}
          classNames="activity-fade"
        >
          <div style={{ padding: "16px 24px 16px" }}>{renderList()}</div>
        </CSSTransition>
      </SwitchTransition>
    </ActivityFeed>
  );
}

export default ActivityFeedContainer;
