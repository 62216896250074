import React from "react";
import ReactDOM from "react-dom";
import Cell from "./Cell";

class RoomRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      props: this.props,
      bookings: this.props.bookings,
      roomName: this.props.roomName,
      id: this.props.id,
      bookingArray: new Array(40).fill(0),
      idArray: new Array(40).fill(0),
    };
    this.openModal = this.openModal.bind(this);
    this.cellSkipCount = 0;
  }

  digestBookings() {
    const array = new Array(40).fill("0");
    const idArray = new Array(40).fill("0");
    const { bookings } = this.props;
    for (let y = 0; y < bookings.length; y++) {
      const booking = bookings[y];
      const { booking_array } = booking;
      for (let i = 0; i < booking_array.length; i++) {
        const inner = booking_array[i];
        if (inner != "0") {
          array[i] = inner;
          idArray[i] = booking.id;
        }
      }
    }
    return [array, idArray];
  }

  componentDidMount() {}

  readBooking(input) {
    if (input === "1") {
      return true;
    }
    return false;
  }

  readId(id) {
    if (id != 0 || id != "0") {
      return id;
    }
  }

  closeModal() {
    document.body.style.overflow = "";
    ReactDOM.unmountComponentAtNode(document.getElementById("modalContainer"));
  }

  createBorders(current, prev, next) {
    if (current === prev && current === next) {
      return "cell-middle";
    }
    if (current != next && current != prev) {
      return "cell-full";
    }
    if (prev === current) {
      return "cell-end";
    }
    if (current === next) {
      return "cell-start";
    }
  }

  openModal() {
    const data = {
      action: "create",
      roomCapacity: this.props.room.capacity,
      bookings: this.digestBookings(),
      bookingHandler: this.props.bookingHandler,
      currentUser: this.props.currentUser,
      roomName: this.props.roomName,
      room: this.props.room.id,
      roomObject: this.props.room,
      currentDay: this.props.currentDay,
      users: this.props.users,
      customClass: "meeting-modal",
    };
    this.props.setModal(true, "meeting", data);
  }

  getBookingById(bookingId) {
    const thisBooking = this.props.bookings.find(
      (booking) => booking.id === bookingId
    );
    return thisBooking;
  }

  render() {
    const results = this.digestBookings();
    const { cellIds } = this.props;

    return (
      <div>
        <div className="room-row-grid">
          <div className="test">
            <div className={`color-${this.state.id}`} />
            <span
              onClick={this.openModal}
              className="room-name-hover field-title"
              style={{ textTransform: "capitalize" }}
            >
              {this.state.roomName}
            </span>
          </div>
          {cellIds.map((cellId, index) => {
            if (this.cellSkipCount > 0) {
              this.cellSkipCount -= 1;
            } else {
              const booking = this.getBookingById(
                this.readId(results[1][index])
              );
              if (booking) {
                if (booking.duration > 15) {
                  this.cellSkipCount = booking.duration / 15 - 1;
                }
              }
              if (index === 40) {
                return <div key={index} />;
              }
              return (
                <Cell
                  roomCapacity={this.props.room.capacity}
                  position={this.createBorders(
                    results[1][index],
                    results[1][index - 1],
                    results[1][index + 1]
                  )}
                  bookingHandler={this.props.bookingHandler}
                  deleteHandler={this.props.deleteHandler}
                  editHandler={this.props.editHandler}
                  bookingId={this.readId(results[1][index])}
                  bookings={this.digestBookings()}
                  booking={this.getBookingById(this.readId(results[1][index]))}
                  currentDay={this.props.currentDay}
                  currentUser={this.props.currentUser}
                  users={this.props.users}
                  id={this.state.id + cellId}
                  isBooked={this.readBooking(results[0][index])}
                  postion="start"
                  room={this.state.id}
                  roomObject={this.props.room}
                  roomName={this.state.roomName}
                  key={index}
                  setModal={this.props.setModal}
                  theKey={index}
                />
              );
            }
          })}
        </div>
      </div>
    );
  }
}

export default RoomRow;
