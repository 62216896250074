import React from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";

class LoadingButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render({
    loading, handler, type, classList, buttonText
  }) {
    return (
      <SwitchTransition mode="out-in">
        <CSSTransition timeout={300} key={loading} classNames="loading-btn">
          {loading ? (
            <button type="button">
              <div className="page-load-spinner" />
            </button>
          ) : (
            <button
              onClick={handler}
              type={type.toString()}
              className={classList}
            >
              {buttonText}
            </button>
          )}
        </CSSTransition>
      </SwitchTransition>
    );
  }
}

export default LoadingButton;
