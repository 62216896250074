import React from "react";
import axios from "axios";

class EditClient extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteClient = this.deleteClient.bind(this);
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };

    if (this.props.client.active) {
      this.refs.client_active.checked = true;
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const name = this.refs.client_name.value;
    const active = !!this.refs.client_active.checked;
    axios
      .patch(`/api/v1/clients/${this.props.client.id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        client: { name, active },
      })
      .then((res) => {
        this.props.setClient(res.data);
        M.toast({
          html: `Client updated!`,
          displayLength: 3000,
          classes: "green",
        });
        this.props.modalAction(false, "", {});
      })
      .catch((err) => {
        M.toast({
          html: "Client already exists, choose a unique name.",
          classes: "red",
        });
        console.error(err);
      });
  }

  deleteClient() {
    if (confirm("Are you sure you want to delete this client?") === true) {
      axios
        .delete(`/api/v1/clients/${this.props.client.id}.json`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          M.toast({
            html: `Client deleted`,
            displayLength: 3000,
            classes: "green",
          });
          this.props.modalAction(false, "", {});
          window.location.href = "/clients";
        })
        .catch((err) => {
          M.toast({ html: err.response.data, classes: "red" });
          console.error(err);
        });
    }
  }

  render() {
    return (
      <div className="modal-content">
        <h4 className="margin-bottom">Edit Client</h4>
        <form
          className="edit_client"
          id="edit_client_1"
          onSubmit={this.handleSubmit}
        >
          <div className="input-field">
            <input
              required="required"
              type="text"
              defaultValue={this.props.client.name}
              name="client[name]"
              id="client_name"
              ref="client_name"
            />
            <label htmlFor="client_name" className="active">
              Name
            </label>
          </div>
          <div>
            <label htmlFor="client_active">Client status</label>
            <div className="switch status-switch">
              <label>
                Inactive
                <input
                  type="checkbox"
                  value={this.props.client.active ? "1" : "0"}
                  name="client[active]"
                  id="client_active"
                  ref="client_active"
                />
                <span className="lever" />
                Active
              </label>
            </div>
          </div>
          <div className="button-row row center">
            <button
              type="submit"
              name="commit"
              className="btn"
              data-disable-with="Update Client"
            >
              Update Client
            </button>
          </div>
          <div className="row button-row center">
            <div
              className="btn white dark-gray-text"
              id="delete_client_button"
              onClick={this.deleteClient}
            >
              Delete Client
            </div>
          </div>
        </form>
      </div>
    );
  }
}
export default EditClient;
