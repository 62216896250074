import React from "react";
import moment from "moment";
import { Button, Select } from "react-materialize";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";

class EditBooking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meeting_name: this.props.current_booking.name,
      startTime: moment(
        this.props.current_booking.booking_date,
        "YYYY-MM-DD, hh:mm:ss"
      ).format("HH:mm:ss"),
      endTime: moment(
        this.props.current_booking.booking_date,
        "YYYY-MM-DD, hh:mm:ss"
      )
        .add(this.props.current_booking.duration, "minutes")
        .format("HH:mm:ss"),
      description: this.props.current_booking.description,
      duration: this.props.current_booking.duration,
      booking_date: moment(
        this.props.current_booking.booking_date,
        "YYYY-MM-DD"
      ).format("YYYY/MM/DD"),
    };
    this.getDuration = this.getDuration.bind(this);
    this.getStartTime = this.getStartTime.bind(this);
    this.getEndTime = this.getEndTime.bind(this);
    this.getMeetingName = this.getMeetingName.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getDescription = this.getDescription.bind(this);
    this.getBookingDate = this.getBookingDate.bind(this);
  }

  getDuration() {
    const newTimes = {
      "08:00:00": 800,
      "08:30:00": 850,
      "09:00:00": 900,
      "09:30:00": 950,
      "10:00:00": 1000,
      "10:30:00": 1050,
      "11:00:00": 1100,
      "11:30:00": 1150,
      "12:00:00": 1200,
      "12:30:00": 1250,
      "13:00:00": 1300,
      "13:30:00": 1350,
      "14:00:00": 1400,
      "14:30:00": 1450,
      "15:00:00": 1500,
      "15:30:00": 1550,
      "16:00:00": 1600,
      "16:30:00": 1650,
      "17:00:00": 1700,
      "17:30:00": 1750,
      "18:00:00": 1800,
    };
    const duration =
      ((newTimes[this.state.endTime] - newTimes[this.state.startTime]) / 100) *
      60;
    if (duration < 0) {
      M.toast({
        html: "Looks like the end time you've selected is before your start time, please select a valid end time.",
      });
    } else {
      this.setState({ duration });
    }
  }

  getStartTime(event) {
    this.setState({ startTime: event.target.value }, () => {
      this.getBookingDate();
    });
  }

  getEndTime(event) {
    this.setState({ endTime: event.target.value }, () => {
      this.getDuration();
    });
  }

  getMeetingName(event) {
    this.setState({ meeting_name: event.target.value });
  }

  getDescription(event) {
    this.setState({ description: event.target.value });
  }

  getBookingDate() {
    const today = this.props.currentDay;
    const formatted_day = moment(today, "YYYY/MM/DD");
    const booking_date = `${formatted_day} ${this.state.startTime}`;
    this.setState({ booking_date });
  }

  handleSubmit() {
    event.preventDefault();
    this.updateBooking(event);
  }

  updateBooking(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("booking[user_id]", this.props.currentUser.id);
    formData.append("booking[duration]", this.state.duration);
    formData.append("booking[name]", this.state.meeting_name);
    formData.append("booking[booking_date]", this.state.booking_date);
    formData.append("booking[description]", this.state.description);
    formData.append("booking[room_id]", this.props.roomInfo.room_id);
    this.submitEditUser(formData);
  }
  //
  // submitEditUser(formData) {
  //   let component = this
  //   let object = this.props
  //   let xhr = new XMLHttpRequest();
  //   xhr.open('POST', '/bookings.json', true);
  //   xhr.onload = function () {
  //     if (xhr.status === 200) {
  //       var resp = JSON.parse(xhr.response)
  //       object.editHandler(resp)
  //     } else {
  //       alert('There was an issue');
  //     }
  //   };
  //   xhr.send(formData);
  // }

  submitEditUser(formData) {
    const component = this;
    const object = this.props;
    const xhr = new XMLHttpRequest();

    if (object.action === "edit") {
      xhr.open("PATCH", `/bookings/${object.booking.id}.json`, true);
    } else {
      xhr.open("POST", "/bookings.json", true);
    }
    xhr.onload = function () {
      if (xhr.status === 200) {
        const resp = JSON.parse(xhr.response);
        if (object.action === "create") {
          M.toast({
            html: `You booked the ${object.roomName} for ${resp.duration} minutes on ${object.currentDay}`,
          });
          component.props.bookingHandler(resp);
        } else if (object.action === "edit") {
          M.toast({
            html: `You updated the booking for ${object.roomName} for ${resp.duration} minutes on ${object.currentDay}`,
          });
          component.props.editHandler(resp);
        }
        document.body.style.overflow = "";
        ReactDOM.unmountComponentAtNode(
          document.getElementById("modalContainer")
        );
      } else {
        alert("There was an issue");
      }
    };

    if (
      !moment(this.props.currentDay).isSameOrAfter(
        moment().format("MM-DD-YYYY")
      )
    ) {
      Swal.fire({
        title: "This Meeting will be scheduled in the past",
        text: "Are you sure you wanna do that?",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          xhr.send(formData);
        }
      });
    } else if (this.checkThePast() === true) {
      Swal.fire({
        title: "This Meeting will be scheduled in the past",
        text: "Are you sure you wanna do that?",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          xhr.send(formData);
        }
      });
    } else {
      xhr.send(formData);
    }
  }

  checkThePast() {
    const dateValues = {
      "08:00:00": 0,
      "08:30:00": 1,
      "09:00:00": 2,
      "09:30:00": 3,
      "10:00:00": 4,
      "10:30:00": 5,
      "11:00:00": 6,
      "11:30:00": 7,
      "12:00:00": 8,
      "12:30:00": 9,
      "13:00:00": 10,
      "13:30:00": 11,
      "14:00:00": 12,
      "14:30:00": 13,
      "15:00:00": 14,
      "15:30:00": 15,
      "16:00:00": 16,
      "16:30:00": 17,
      "17:00:00": 18,
      "17:30:00": 19,
      "18:00:00": 20,
    };
    if (this.state.time_value > dateValues[this.state.startTime]) {
      Swal.fire(
        "The This Meeting will be scheduled in the past",
        "Are you sure you wanna do that?",
        "question"
      );
    }
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <p>
            Date:
            {this.props.currentDay}
          </p>
          <input
            type="text"
            name="meeting"
            placeholder={this.state.meeting_name}
            value={this.state.meeting_name.value}
            onChange={this.getMeetingName}
          />
          <div style={{ display: "flex" }}>
            Start time:
            <Select
              className="select"
              style={{ appearance: "none" }}
              value={this.state.startTime}
              onChange={this.getStartTime}
              defaultValue={this.state.startTime}
            >
              <option value="08:00:00">8:00</option>
              <option value="08:30:00">8:30</option>
              <option value="09:00:00">9:00</option>
              <option value="09:30:00">9:30</option>
              <option value="10:00:00">10:00</option>
              <option value="10:30:00">10:30</option>
              <option value="11:00:00">11:00</option>
              <option value="11:30:00">11:30</option>
              <option value="12:00:00">12:00</option>
              <option value="12:30:00">12:30</option>
              <option value="13:00:00">1:00</option>
              <option value="13:30:00">1:30</option>
              <option value="14:00:00">2:00</option>
              <option value="14:30:00">2:30</option>
              <option value="15:00:00">3:00</option>
              <option value="15:30:00">3:30</option>
              <option value="16:00:00">4:00</option>
              <option value="16:30:00">4:30</option>
              <option value="17:00:00">5:00</option>
              <option value="17:30:00">5:30</option>
            </Select>
            End time:
            <Select
              className="select"
              value={this.state.endTime}
              onChange={this.getEndTime}
              defaultValue={this.state.endTime}
            >
              <option value="08:30:00">8:30</option>
              <option value="09:00:00">9:00</option>
              <option value="09:30:00">9:30</option>
              <option value="10:00:00">10:00</option>
              <option value="10:30:00">10:30</option>
              <option value="11:00:00">11:00</option>
              <option value="11:30:00">11:30</option>
              <option value="12:00:00">12:00</option>
              <option value="12:30:00">12:30</option>
              <option value="13:00:00">1:00</option>
              <option value="13:30:00">1:30</option>
              <option value="14:00:00">2:00</option>
              <option value="14:30:00">2:30</option>
              <option value="15:00:00">3:00</option>
              <option value="15:30:00">3:30</option>
              <option value="16:00:00">4:00</option>
              <option value="16:30:00">4:30</option>
              <option value="17:00:00">5:00</option>
              <option value="17:30:00">5:30</option>
              <option value="18:00:00">6:00</option>
            </Select>
          </div>
          <input
            type="text"
            name="description"
            placeholder="Description (optional)"
            value={this.state.description.value}
            onChange={this.getDescription}
          />

          <Button type="submit">Update Booking!</Button>
        </form>
      </div>
    );
  }
}

export default EditBooking;
