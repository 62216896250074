import React from "react";
import axios from "axios";

class UnavailablityListItem extends React.Component {
  constructor(props) {
    super(props);
    this.editTask = this.editTask.bind(this);
  }

  editTask() {
    const { taskId } = this.props;
    const url = `/api/v1/tasks/${taskId}`;
    axios
      .get(`/api/v1/tasks/${taskId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const data = {
          taskId: res.data.id,
          multitaskId: res.data.multitask_id,
          taskName: res.data.name,
          userName: this.props.userName,
          date: this.props.selectedDate,
          created_by: res.data.created_by,
          projectName: res.data.name,
          userId: res.data.user_id,
        };
        this.props.modalAction(true, "project-edit-task", data);
      })
      .catch((err) => console.error(err));
  }

  render() {
    const id = this.props.taskId;
    const { day } = this.props;
    const { displayDate } = this.props;
    const { hours } = this.props;
    return (
      <li className="collection-item hover avail-item" onClick={this.editTask}>
        <div className="row list-row">
          <div className="col s8">
            {day}
            ,
            {displayDate}
          </div>
          <div className="col s4">
            {hours}
            {' '}
            hours
          </div>
        </div>
      </li>
    );
  }
}

export default UnavailablityListItem;
