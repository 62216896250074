import React from "react";
import moment from "moment";
import axios from "axios";
import Chart from "chart.js/auto";
import { Tooltip } from "chart.js";
import { Bar } from "react-chartjs-2";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import datalabels from "chartjs-plugin-datalabels";
import RoundDropdown from "../projects/round_dropdown";
import CalendarIcon from "../icons/calendar_icon";

Chart.register(datalabels);
Tooltip.positioners.custom = function (elements, eventPosition) {
  /** @type {Chart.Tooltip} */
  /* ... */
  return {
    x: eventPosition.x,
    y: eventPosition.y,
  };
};
Chart.defaults.font.family = "Arial";
Chart.defaults.color = "#313131";
Chart.defaults.font.size = 16;
Chart.defaults.animation.duration = 1000;

class UsersChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentWeekStart: moment().startOf("week").format("YYYY-MM-DD"),
      currentWeekEnd: moment().endOf("week").format("YYYY-MM-DD"),
      pickerFocus: false,
      pickerDate: moment(new Date()),
      users: "",
      fullTime: "all",
      userCount: 0,
      filtered: false,
      filters: [
        [
          {
            id: 1,
            name: "Test",
            user_id: 1,
            user_ids: [[], []],
          },
        ],
        [],
      ],
      chartData: {
        labels: ["User"],
        datasets: [
          {
            label: "Utilization",
            barThickness: 20,
            backgroundColor: "rgba(21, 91, 219,0.2)",
            strokeColor: "rgba(21, 91, 219,1)",
            pointColor: "rgba(21, 91, 219,1)",
            pointStrokeColor: "#fff",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "rgba(220,220,220,1)",
            data: [20],
          },
          {
            label: "Utilization",
            barThickness: 20,
            backgroundColor: "rgba(21, 91, 219,0.2)",
            strokeColor: "rgba(21, 91, 219,1)",
            pointColor: "rgba(21, 91, 219,1)",
            pointStrokeColor: "#fff",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "rgba(220,220,220,1)",
            data: [20],
          },
          {
            label: "Utilization",
            barThickness: 20,
            backgroundColor: "rgba(21, 91, 219,0.2)",
            strokeColor: "rgba(21, 91, 219,1)",
            pointColor: "rgba(21, 91, 219,1)",
            pointStrokeColor: "#fff",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "rgba(220,220,220,1)",
            data: [20],
          },
          {
            label: "Utilization",
            barThickness: 20,
            backgroundColor: "rgba(21, 91, 219,0.2)",
            strokeColor: "rgba(21, 91, 219,1)",
            pointColor: "rgba(21, 91, 219,1)",
            pointStrokeColor: "#fff",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "rgba(220,220,220,1)",
            data: [0],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        indexAxis: "y",
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        gridLines: {
          display: false,
        },
        tooltips: {
          display: true,
          enabled: false,
          barThickness: 100,
          filter(tooltipItem, data) {
            if (tooltipItem.value === 0) {
              return false;
            }
            return true;
          },
          position: "custom",
          custom: function (tooltipModel) {
            // Tooltip Element
            let tooltipEl = document.getElementById("chartjs-tooltip");
            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement("div");
              tooltipEl.id = "chartjs-tooltip";
              tooltipEl.innerHTML = "<table></table>";
              document.body.appendChild(tooltipEl);
            }
            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }
            // Set caret Position
            tooltipEl.classList.remove("above", "below", "no-transform");
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add("no-transform");
            }
            function getBody(bodyItem) {
              return bodyItem.lines;
            }
            // Set Text
            if (tooltipModel.body) {
              const titleLines = tooltipModel.title || [];
              const bodyLines = tooltipModel.body.map(getBody);

              let innerHtml = "<thead >";
              const _this = this;
              titleLines.forEach((title) => {
                const overBookedText = _this.state.chartData.overbooked_array[
                  _this.state.chartData.labels.indexOf(title)
                ]
                  ? '<span style="color: lightgray; font-size: 10px;"> *user may be overbooked</span>'
                  : "";
                const color = _this.state.chartData.full_time[
                  _this.state.chartData.labels.indexOf(title)
                ]
                  ? "#ff7c3f"
                  : "#729ef7";
                const circle =
                  _this.state.fullTime === "all"
                    ? `<span id="full-time-circle"  ` +
                      `style="height: 13px; width: 13px; border-radius: 50%; background-color: ${color}; display: inline-block; margin-top: 5px; margin-left: 2px; vertical-align: -2px;"></span>`
                    : "";
                innerHtml += `<tr><th style="padding: 0px 5px 0px 5px">${title}${circle}${overBookedText} </th></tr>`;
              });
              innerHtml += "</thead><tbody>";
              bodyLines.forEach((body, i) => {
                const colors = tooltipModel.labelColors[i];
                let squareColor;
                if (_this.state.fullTime === "all") {
                  squareColor = colors.backgroundColor;
                } else if (_this.state.fullTime === "fullTime") {
                  if (i === 0) {
                    squareColor = "rgb(255, 124, 63, 0.8)";
                  } else {
                    squareColor = colors.backgroundColor;
                  }
                } else if (i === 0) {
                  squareColor = "rgb(114, 158, 247, 0.8)";
                } else {
                  squareColor = colors.backgroundColor;
                }
                let style = `background:${colors.backgroundColor}`;
                // style += '; border-color:' + colors.borderColor;
                style += "; padding: 0px";
                const span = `<span style="${style}"></span>`;
                const square = `<span style="height: 14px; width: 14px; background-color: ${squareColor}; border: 1px solid white; display: inline-block; margin-right: 5px; vertical-align: -2px;"></span>`;
                innerHtml += `<tr><td style="padding: 0px 5px 0px 5px">${square}${span}${body}</td></tr>`;
              });
              innerHtml += "</tbody>";

              const tableRoot = tooltipEl.querySelector("table");
              tableRoot.innerHTML = innerHtml;
            }
            // `this` will be the overall tooltip
            const position =
              this.chart.current.chartInstance.canvas.getBoundingClientRect();
            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = "absolute";
            tooltipEl.style.left = `${
              position.left + window.pageXOffset + tooltipModel.caretX
            }px`;
            tooltipEl.style.top = `${
              position.top + window.pageYOffset + tooltipModel.caretY
            }px`;
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = `${tooltipModel.bodyFontSize}px`;
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding = "0px";
            tooltipEl.style.pointerEvents = "none";
            tooltipEl.style.backgroundColor = "rgb(0,0,0,0.75)";
            tooltipEl.style.color = "white";
            tooltipEl.style.borderRadius = "5px";
            tooltipEl.style.transition = "opacity 0.3s, top 0.2s, left 0.2s";
            tooltipEl.style.transitionTimingFunction = "ease-out";
          }.bind(this),
          callbacks: {
            label(tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || "";
              const div_total =
                data.datasets[0].data[tooltipItem.index] +
                data.datasets[0].data[tooltipItem.index];
              label += ` : ${tooltipItem.value}`;
              return label;
            },
            labelColor(tooltipItem, chart) {
              if (tooltipItem.datasetIndex === 0) {
                return {
                  borderColor: "rgba(0, 0, 0, 0)",
                  backgroundColor: "rgba(44, 184, 130, 0.8)",
                };
              }
              if (tooltipItem.datasetIndex === 1) {
                return {
                  borderColor: "rgba(128,0,128,0.0)",
                  backgroundColor: "rgba(108, 170, 218, 0.8)",
                };
              }
              if (tooltipItem.datasetIndex === 2) {
                return {
                  borderColor: "rgba(0, 0, 0, 0)",
                  backgroundColor: "rgba(180, 180, 180, 0.8)",
                };
              }
              if (tooltipItem.datasetIndex === 3) {
                return {
                  borderColor: "rgba(0, 0, 0, 0)",
                  backgroundColor: "rgba(100, 100, 100, 0.8)",
                };
              }
            },
          },
        },
        plugins: {
          datalabels: {
            display(context) {
              return context.dataset.data[context.dataIndex] >= 1;
            },
            anchor: "start",
            align: "end",
            formatter(value, context) {
              const weekTotal =
                context.chart.config.data.datasets[3].data[context.dataIndex];
              return `${Math.round((value / 40) * 100)}%`;
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              color: "black",
            },
            categorySpacing: 0,
            max: 40,
            min: 0,
            beginAtZero: true,
            ticks: {
              min: 0,
              stepSize: 20,
            },
          },
          y: {
            beginAtZero: true,
            stacked: true,
            grid: {
              display: false,
            },
          },
        },
      },
    };

    this.handleDateChange = this.handleDateChange.bind(this);
    this.getFilters = this.getFilters.bind(this);
    this.getWeeklyUsers = this.getWeeklyUsers.bind(this);
    this.resetWeeklyUsers = this.resetWeeklyUsers.bind(this);
    this.resetFilterWeeklyUsers = this.resetFilterWeeklyUsers.bind(this);
    this.chooseFilter = this.chooseFilter.bind(this);
    this.updateWeeklyUsers = this.updateWeeklyUsers.bind(this);
    this.updateFilteredWeeklyUsers = this.updateFilteredWeeklyUsers.bind(this);
    this.renderDateDisplay = this.renderDateDisplay.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.chart = React.createRef();
    this.updateSlide = this.updateSlide.bind(this);
    this.clickPrevWeek = this.clickPrevWeek.bind(this);
    this.clickUpcomingWeek = this.clickUpcomingWeek.bind(this);
    this.clickResetWeek = this.clickResetWeek.bind(this);
    this.onFocusChangePicker = this.onFocusChangePicker.bind(this);
    this.onDateChangePicker = this.onDateChangePicker.bind(this);
    this.displayTeamFilters = this.displayTeamFilters.bind(this);
    this.onFilterClick = this.onFilterClick.bind(this);
  }

  updateSlide(value) {
    if (value === 0) {
      this.setState({ fullTime: "all" });
      this.toggleSlider
        .querySelector(".noUi-handle")
        .classList.remove("noUi-ft");
      this.toggleSlider
        .querySelector(".noUi-handle")
        .classList.remove("noUi-pt");
    } else if (value === 1) {
      this.toggleSlider
        .querySelector(".noUi-handle")
        .classList.remove("noUi-ft");
      this.toggleSlider.querySelector(".noUi-handle").classList.add("noUi-pt");
      this.setState({ fullTime: "partTime" });
    } else if (value === 2) {
      this.toggleSlider
        .querySelector(".noUi-handle")
        .classList.remove("noUi-pt");
      this.toggleSlider.querySelector(".noUi-handle").classList.add("noUi-ft");
      this.setState({ fullTime: "fullTime" });
    }
  }

  onScroll() {
    const dn = document.querySelector("#user-chart-nav");
    const dns = "users-date-nav-scrolled";
    const hdr =
      document.querySelector("#week-schedule-container").clientHeight +
      document.querySelector("header").clientHeight +
      300;
    if (window.scrollY > hdr) {
      dn.classList.add(dns);
    } else {
      dn.classList.remove(dns);
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll, false);
    this.getWeeklyUsers();
    this.getFilters();
    this.toggleSlider = document.getElementById("slider-toggle");
    noUiSlider.create(this.toggleSlider, {
      range: {
        min: 0,
        "50%": 1,
        max: 2,
      },
      snap: true,
      start: [0],
    });
    this.toggleSlider.noUiSlider.on("set.one", () => {
      this.updateSlide(this.toggleSlider.noUiSlider.get());
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, false);
  }

  handleDateChange(date) {
    const today = moment(date);
    const weekStart = moment(today).startOf("week").format("YYYY-MM-DD");
    const weekEnd = moment(today).endOf("week").format("YYYY-MM-DD");
    this.setState({ currentWeekStart: weekStart, currentWeekEnd: weekEnd });
    if (this.state.filtered) {
      this.updateFilteredWeeklyUsers(weekStart, weekEnd, this.state.users);
    } else {
      this.updateWeeklyUsers(weekStart, weekEnd);
    }
  }

  getWeeklyUsers() {
    const { currentWeekStart } = this.state;
    const { currentWeekEnd } = this.state;
    const { users } = this.state;

    axios
      .get(`/users_dash/${currentWeekStart}/${currentWeekEnd}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: { users },
      })
      .then((res) => {
        this.setState({
          chartData: res.data,
          userCount: res.data.labels.length,
          loading: false,
        });
      })
      .catch((err) => console.log(err));
  }

  resetWeeklyUsers() {
    const currentWeekStart = moment().startOf("week").format("YYYY-MM-DD");
    const currentWeekEnd = moment().endOf("week").format("YYYY-MM-DD");
    const users = "";
    const inputs = document.getElementsByClassName("with-gap");
    const inputArray = [...inputs];
    inputArray.map((x) => (x.checked = false));

    axios
      .get(`/users_dash/${currentWeekStart}/${currentWeekEnd}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: { users },
      })
      .then((res) => {
        this.setState({
          chartData: res.data,
          userCount: res.data.labels.length,
          filtered: false,
          users,
          loading: false,
        });
      })
      .catch((err) => console.error(err));
  }

  updateWeeklyUsers(week_start, week_end) {
    axios
      .get(`/users_dash/${week_start}/${week_end}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: { users: this.state.users },
      })
      .then((res) => {
        this.setState({
          chartData: res.data,
          userCount: res.data.labels.length,
          loading: false,
        });
      })
      .catch((err) => console.log(err));
  }

  renderDateDisplay() {
    const displayDateStart = moment(this.state.currentWeekStart)
      .add(1, "day")
      .format("M/DD/YY");
    const displayDateEnd = moment(this.state.currentWeekEnd)
      .subtract(1, "day")
      .format("M/DD/YY");
    return (
      <div id="date-display">
        {this.state.loading ? (
          <div
            style={{ height: "30px", width: "30px" }}
            className="page-load-spinner"
          />
        ) : (
          <p>
            {displayDateStart} -{displayDateEnd}
          </p>
        )}
      </div>
    );
  }

  prevWeek() {
    const newWeekStart = moment(this.state.currentWeekStart.slice())
      .subtract(7, "d")
      .format("YYYY-MM-DD");
    const newWeekEnd = moment(this.state.currentWeekEnd.slice())
      .subtract(7, "d")
      .format("YYYY-MM-DD");
    this.setState({
      currentWeekStart: newWeekStart,
      currentWeekEnd: newWeekEnd,
    });
    this.updateWeeklyUsers(newWeekStart, newWeekEnd);
  }

  upcomingWeek() {
    const newWeekStart = moment(this.state.currentWeekStart.slice())
      .add(7, "d")
      .format("YYYY-MM-DD");
    const newWeekEnd = moment(this.state.currentWeekEnd.slice())
      .add(7, "d")
      .format("YYYY-MM-DD");
    this.setState({
      currentWeekStart: newWeekStart,
      currentWeekEnd: newWeekEnd,
    });
    this.updateWeeklyUsers(newWeekStart, newWeekEnd);
  }

  resetWeek() {
    this.setState({
      currentWeekStart: moment().startOf("week").format("YYYY-MM-DD"),
      currentWeekEnd: moment().endOf("week").format("YYYY-MM-DD"),
    });
    this.resetWeeklyUsers();
  }

  getFilters() {
    axios
      .get("/get_filters", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => this.setState({ filters: res.data }))
      .catch((err) => console.log(err));
  }

  chooseFilter(users) {
    const week_start = this.state.currentWeekStart;
    const week_end = this.state.currentWeekEnd;
    axios
      .get(`/users_dash/${week_start}/${week_end}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: { users },
      })
      .then((res) => {
        this.setState({
          chartData: res.data,
          userCount: res.data.labels.length,
          filtered: true,
          users,
          loading: false,
        });
      })
      .catch((err) => console.error(err));
  }

  filterPrevWeek() {
    const newWeekStart = moment(this.state.currentWeekStart.slice())
      .subtract(7, "d")
      .format("YYYY-MM-DD");
    const newWeekEnd = moment(this.state.currentWeekEnd.slice())
      .subtract(7, "d")
      .format("YYYY-MM-DD");
    this.setState({
      currentWeekStart: newWeekStart,
      currentWeekEnd: newWeekEnd,
    });
    this.updateFilteredWeeklyUsers(newWeekStart, newWeekEnd, this.state.users);
  }

  filterUpcomingWeek() {
    const newWeekStart = moment(this.state.currentWeekStart.slice())
      .add(7, "d")
      .format("YYYY-MM-DD");
    const newWeekEnd = moment(this.state.currentWeekEnd.slice())
      .add(7, "d")
      .format("YYYY-MM-DD");
    this.setState({
      currentWeekStart: newWeekStart,
      currentWeekEnd: newWeekEnd,
    });
    this.updateFilteredWeeklyUsers(newWeekStart, newWeekEnd, this.state.users);
  }

  filterResetWeek() {
    const newWeekStart = moment().startOf("week").format("YYYY-MM-DD");
    const newWeekEnd = moment().endOf("week").format("YYYY-MM-DD");
    this.setState({
      filtered: true,
      currentWeekStart: newWeekStart,
      currentWeekEnd: newWeekEnd,
    });
    this.updateFilteredWeeklyUsers(newWeekStart, newWeekEnd, this.state.users);
  }

  updateFilteredWeeklyUsers(week_start, week_end, users) {
    axios
      .get(`/users_dash/${week_start}/${week_end}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: { users },
      })
      .then((res) => {
        this.setState({
          chartData: res.data,
          userCount: res.data.labels.length,
          filtered: true,
          users,
          loading: false,
        });
      })
      .catch((err) => console.error(err));
  }

  resetFilterWeeklyUsers() {
    const currentWeekStart = moment().startOf("week").format("YYYY-MM-DD");
    const currentWeekEnd = moment().endOf("week").format("YYYY-MM-DD");

    axios
      .get(`/users_dash/${currentWeekStart}/${currentWeekEnd}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: { users },
      })
      .then((res) => {
        this.setState({
          chartData: res.data,
          userCount: res.data.labels.length,
          filtered: false,
          users,
          loading: false,
        });
      })
      .catch((err) => console.error(err));
  }

  clickPrevWeek() {
    this.state.filtered ? this.filterPrevWeek() : this.prevWeek();
  }

  clickUpcomingWeek() {
    this.state.filtered ? this.filterUpcomingWeek() : this.upcomingWeek();
  }

  clickResetWeek() {
    this.state.filtered ? this.filterResetWeek() : this.resetWeek();
  }

  onFocusChangePicker(event) {
    this.setState({ pickerFocus: event.focused });
  }

  onDateChangePicker(date) {
    this.handleDateChange(date);
    this.setState({ pickerDate: date });
  }

  onFilterClick(e) {
    this.chooseFilter(JSON.parse(e.target.value));
  }

  displayTeamFilters(filter) {
    return (
      <div className="inline-dogs" key={filter.id}>
        <label className="team-label" htmlFor={filter.id}>
          <input
            className="with-gap"
            name="group1"
            type="radio"
            value={JSON.stringify(filter.user_ids)}
            id={filter.id}
            onClick={this.onFilterClick}
          />
          <span>{filter.name}</span>
        </label>
      </div>
    );
  }

  isDayBlocked(day) {
    return !!(moment(day).day() === 0 || moment(day).day() === 6);
  }

  render() {
    const displayDateStart = moment(this.state.currentWeekStart).add(1, "day");
    const displayDateEnd = moment(this.state.currentWeekEnd).subtract(1, "day");
    const today = moment(new Date());
    const todayIsInRange = today.isBetween(
      displayDateStart,
      displayDateEnd,
      "days",
      "[]"
    );
    const userFilters = this.state.filters[0];
    const teamFilters = this.state.filters[1];
    const users = JSON.parse(JSON.stringify(this.state.chartData));
    if (this.state.fullTime !== "all") {
      users.labels = [];
      users.full_time = [];
      users.datasets[0].data = [];
      users.datasets[1].data = [];
      users.datasets[2].data = [];
      users.datasets[3].data = [];
      if (this.state.fullTime === "fullTime") {
        for (var x = 0; x < this.state.chartData.labels.length; x++) {
          if (this.state.chartData.full_time[x] === true) {
            users.labels.push(this.state.chartData.labels[x]);
            users.full_time.push(this.state.chartData.full_time[x]);
            users.datasets[0].data.push(
              this.state.chartData.datasets[0].data[x]
            );
            users.datasets[1].data.push(
              this.state.chartData.datasets[1].data[x]
            );
            users.datasets[2].data.push(
              this.state.chartData.datasets[2].data[x]
            );
            users.datasets[3].data.push(
              this.state.chartData.datasets[3].data[x]
            );
            users.datasets[0].backgroundColor = "rgb(255, 124, 63, 0.8)";
            // users.datasets[1].backgroundColor = "rgb(211,211,211, 0.8)"
          }
        }
      } else {
        for (var x = 0; x < this.state.chartData.labels.length; x++) {
          if (this.state.chartData.full_time[x] === false) {
            users.labels.push(this.state.chartData.labels[x]);
            users.full_time.push(this.state.chartData.full_time[x]);
            users.datasets[0].data.push(
              this.state.chartData.datasets[0].data[x]
            );
            users.datasets[1].data.push(
              this.state.chartData.datasets[1].data[x]
            );
            users.datasets[2].data.push(
              this.state.chartData.datasets[2].data[x]
            );
            users.datasets[3].data.push(
              this.state.chartData.datasets[3].data[x]
            );
            users.datasets[0].backgroundColor = "rgb(114, 158, 247, 0.8)";
            // users.datasets[1].backgroundColor = "rgb(211,211,211, 0.8)"
          }
        }
      }
    }
    const userCount = users.labels.length;
    return (
      <div id="chart-user-schedule-container">
        <div
          id="slider-container"
          className="row"
          style={{ paddingLeft: "0px", marginBottom: "0px", marginTop: "5px" }}
        >
          <div>
            <p className="col s4" style={{ marginTop: "18px" }}>
              All
            </p>
            <p className="col s4" style={{ marginTop: "18px" }}>
              Part-Time
            </p>
            <p className="col s4" style={{ marginTop: "18px" }}>
              Full-Time
            </p>
          </div>
          <div id="slider-toggle" />
        </div>
        <div className="row">
          <div id="filters-container" className="col s12 valign-wrapper">
            <div className="col s12 valign-wrapper">
              <div className="col s1" id="team-box">
                <span>Teams:</span>
              </div>
              <div className="col s9">
                <form id="team-radios" action="#">
                  {teamFilters.map(this.displayTeamFilters)}
                </form>
              </div>
              <div className="col s2 push-s1">
                <span className="black-text">
                  <a className="black-text" onClick={this.resetWeeklyUsers}>
                    Clear
                  </a>
                  <i className="material-icons inline-icon">refresh</i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div id="user-chart-nav" className="col s12">
          <div id="date-nav-header" className="valign-wrapper">
            <div className="flex-j-start flex-container flex-al-center">
              <div
                style={{
                  position: "relative",
                  left: "40px",
                  zIndex: 1,
                  top: "5px",
                }}
              >
                <CalendarIcon width="22px" height="22px" color="#519acc" />
              </div>
              <SingleDatePicker
                date={this.state.pickerDate} // momentPropTypes.momentObj or null
                onDateChange={this.onDateChangePicker} // PropTypes.func.isRequired
                focused={this.state.pickerFocus} // PropTypes.bool
                onFocusChange={this.onFocusChangePicker} // PropTypes.func.isRequired
                numberOfMonths={1}
                placeholder="Select a date"
                orientation="horizontal"
                daySize={30}
                navPrev={
                  <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                    <RoundDropdown rotate="rotate(90deg)" />
                  </div>
                }
                navNext={
                  <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                    <RoundDropdown rotate="rotate(-90deg)" />
                  </div>
                }
                hideKeyboardShortcutsPanel
                inputIconPosition="after"
                customInputIcon={<RoundDropdown />}
                isOutsideRange={this.isDayBlocked}
                id="single_date_picker" // PropTypes.string.isRequired,
              />
            </div>
            <div
              style={{ flex: 1, marginLeft: "-161px" }}
              className="flex-container flex-al-center flex-j-center valign-wrapper"
            >
              <button
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
                id="prevWeek"
                onClick={this.clickPrevWeek}
              >
                <img
                  alt="left chevron"
                  style={{
                    transform: "rotate(90deg)",
                    marginRight: "15px",
                    verticalAlign: "-8px",
                  }}
                  width="20"
                  src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/syncit/Icon_DropArrow_white.svg"
                />
              </button>
              <div>{this.renderDateDisplay()}</div>
              <button
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
                id="upcomingWeek"
                onClick={this.clickUpcomingWeek}
              >
                <img
                  alt="right chevron"
                  style={{
                    transform: "rotate(-90deg)",
                    marginLeft: "15px",
                    verticalAlign: "-8px",
                  }}
                  width="20"
                  src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/syncit/Icon_DropArrow_white.svg"
                />
              </button>
            </div>
            <div
              style={{
                marginLeft: "auto",
                height: "60px",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                top: "5px",
              }}
              className="col s2 m2 l1 center"
              id="resetWeekContainer"
            >
              {!todayIsInRange && (
                <a id="todayBtn" className="adjusted" onClick={this.resetWeek}>
                  <i
                    style={{ pointerEvents: "none" }}
                    className="material-icons white-text"
                  >
                    today
                  </i>
                </a>
              )}
            </div>
          </div>
        </div>
        <div
          id="user-chart"
          style={{
            height: userCount <= 3 ? "10vh" : `${userCount * 5}vh`,
            position: "relative",
          }}
        >
          <div style={{ fontSize: "18px", textAlign: "center" }}>
            <span>
              Hours Booked: {users.datasets[1].data.reduce((a, b) => a + b, 0)}
            </span>
            <span style={{ marginLeft: "20px" }}>
              Hours Available:{" "}
              {users.datasets[0].data.reduce((a, b) => a + b, 0)}
            </span>
          </div>
          {!this.state.loading ? (
            <Bar
              ref={this.chart}
              data={users}
              options={this.state.chartOptions}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default UsersChart;
