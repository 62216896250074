import React, { useState, useEffect } from "react";
import { List, AutoSizer } from "react-virtualized";
import styled from "styled-components";
import RoundDropdown from "../projects/round_dropdown";

const Table = ({ data, headers }) => {
  const [sortColumn, setSortColumn] = useState("name");
  const [direction, setDirection] = useState("asc");

  const handleSort = (field) => {
    setSortColumn(field);

    setDirection((prevDirection) => (prevDirection === "asc" ? "desc" : "asc"));
  };

  const sortedData = [...data].sort((a, b) => {
    if (direction === "asc") {
      return a[sortColumn] < b[sortColumn] ? -1 : 1;
    } else {
      return a[sortColumn] > b[sortColumn] ? -1 : 1;
    }
  });
  return (
    <div style={{ height: " calc(100vh - 351px)" }}>
      <TableHeaders
        headers={headers}
        handleSort={handleSort}
        sortColumn={sortColumn}
        direction={direction}
      />
      <TableWrapper
        key="table-body"
        aria-label={`Displaying ${data.length} items`}
      >
        <AutoSizer>
          {({ height, width }) => (
            <List
              style={{ overflowY: "scroll" }}
              rowHeight={54}
              rowCount={data.length}
              height={height}
              overscanRowCount={5}
              width={width}
              rowRenderer={({ index, key, style }) => {
                const rowData = sortedData[index];
                return (
                  <Wrap style={style} key={key}>
                    <ProjectCardDiv
                      aria-label={`Row ${index}`}
                      role="link"
                      key={key}
                    >
                      {headers.map(({ field }, columnIndex) => (
                        <div
                          key={`${field}-${index}`}
                          style={{
                            flex:
                              columnIndex === headers.length - 1 ? "0 0 0%" : 1,
                          }}
                        >
                          {field === "tag_name" && rowData[field] ? (
                            <div
                              style={{
                                maxWidth: "104px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <TypeTag tag_color={rowData["tag_color"]}>
                                {rowData[field]}
                              </TypeTag>
                            </div>
                          ) : field === "active" ? (
                            <StatusTag status_color={rowData[field]}>
                              {rowData[field] ? "Active" : "Inactive"}
                            </StatusTag>
                          ) : field === "approved" ? (
                            <StatusTag status_color={rowData[field]}>
                              {rowData[field] ? "Approved" : "Denied"}
                            </StatusTag>
                          ) : field === "hours" ? (
                            <FieldDataHours>{rowData[field]}</FieldDataHours>
                          ) : (
                            <FieldData>{rowData[field]}</FieldData>
                          )}
                        </div>
                      ))}
                    </ProjectCardDiv>
                  </Wrap>
                );
              }}
              scrollToAlignment="start"
            />
          )}
        </AutoSizer>
      </TableWrapper>
    </div>
  );
};

const TableHeaders = ({ headers, handleSort, sortColumn, direction }) => {
  return (
    <div
      aria-label="Table sorting options"
      style={{ padding: "0px 40px 0px 22px", display: "flex" }}
      key="table-headers"
    >
      {headers.map(({ field, label }, columnIndex) => (
        <HeaderTitle
          key={field}
          style={{
            flex: columnIndex === headers.length - 1 ? "0 0 0%" : 1,
          }}
        >
          <HeaderWrapper
            className={field === "tag_name" ? "tag-header" : ""}
            style={{
              borderBottom: sortColumn === field ? "1px solid #519acc" : "none",
              minWidth: columnIndex === headers.length - 1 ? "62px" : "initial",
            }}
            onClick={() => handleSort(field)}
          >
            <HeaderLabel
              style={{ marginRight: sortColumn === field ? "8px" : "0px" }}
            >
              {label}
            </HeaderLabel>
            {sortColumn === field && (
              <RoundDropdown
                // svgStyles={{ marginLeft: "8px" }}
                rotate={
                  direction === "desc" ? "rotate(180deg)" : "rotate(0deg)"
                }
              />
            )}
          </HeaderWrapper>
        </HeaderTitle>
      ))}
    </div>
  );
};

const TableWrapper = styled.div`
  height: 100%;
  border: 1px solid #d7d7d7;
  padding: 8px 4px 8px 8px;

  > div {
    height: calc(100% - 16px) !important;
    gap: 4px;
  }
`;
const Wrap = styled.div``;
const ProjectCardDiv = styled.div`
  display: flex;
  height: 50px;
  padding: 12px 16px;
  background-color: white;
  transition: background-color 0.2s;
  display: flex;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  align-items: center;
  font-size: 14px;
  position: relative;
  margin-right: 2px;

  &:hover {
    background-color: #dadada52;
  }
`;

const HeaderTitle = styled.span`
  display: flex;
  align-items: center;
  font-family: Arial;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
`;

const HeaderWrapper = styled.span`
  padding: 11px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #e6e6e6;
  }

  .tag-header {
    padding-left: 15px;
  }
`;

const HeaderLabel = styled.span`
  // margin-right: 8px;
`;
const TypeTag = styled.span`
  background-color: ${(props) => props.tag_color};
  color: white;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  height: 25px;
  font-family: "Open Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
`;

const StatusTag = styled.span`
  background-color: ${(props) => (props.status_color ? "#2CB882" : "#BF0413")};
  color: white;
  width: 58px;
  height: 25px;
  border-radius: 20px;
  padding: 4px 6px;
  font-family: "Open Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex: 0 0 auto !important;
  width: 58px;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const FieldData = styled.span`
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
`;

const FieldDataHours = styled.span`
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  padding-left: 19px;
`;
export default Table;
