import React from "react";
import SvgShell from "./svg_shell";

function ProjectTypeIcon({ height, width, svgStyles, color }) {
  return (
    <SvgShell height={height} width={width} svgStyles={svgStyles} color={color}>
      <path d="M56.09,55.36H8.21c-3.1,0-5.52-1.74-5.52-4v-40A1.34,1.34,0,0,1,4,10.1h15.1a4.64,4.64,0,0,1,2.38.66l4.68,2.8H58.41a3.46,3.46,0,0,1,2.56.85A3.26,3.26,0,0,1,61.63,17c0,.17,0,.36,0,.54V51.4C61.61,53.62,59.19,55.36,56.09,55.36ZM5.36,12.77V51.4c0,.46,1.08,1.29,2.85,1.29H56.09c1.77,0,2.85-.83,2.85-1.29V17.52c0-.23,0-.45,0-.66a5.75,5.75,0,0,0,0-.58,2.78,2.78,0,0,0-.56-.05H25.82a1.31,1.31,0,0,1-.69-.19l-5-3a2,2,0,0,0-1-.28Z" />
      <path d="M22.83,23.84H4.14V21.16h18l2-2.47a1.34,1.34,0,0,1,1.05-.51H60.4v2.67H25.84l-2,2.48A1.34,1.34,0,0,1,22.83,23.84Z" />
    </SvgShell>
  );
}

ProjectTypeIcon.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "#519acc",
};

export default ProjectTypeIcon;
