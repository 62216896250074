import React from "react";
import moment from "moment";
import axios from "axios";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import SingleSelectStyles from "../common/single_select_styles";
import Select, { components } from "react-select";
import styled from "styled-components";
import RoundDropdown from "../projects/round_dropdown.jsx";
import CalendarIcon from "../icons/calendar_icon.jsx";
import Swal from "sweetalert2";
import ExitIcon from "components/icons/exit_icon";
import ClosureModalIcon from "components/icons/closure_modal_icon";

const ClosureModal = styled.div`
  > div:nth-child(1) {
    display: flex;
    align-items: center;
  }
  > div:nth-child(1) img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  > div:nth-child(1) h2 {
    margin: 0px;
    font-size: 18px;
    font-family: Manrope;
    font-weight: 500;
    color: #313131;
  }

  .react-select__input > input {
    box-shadow: none !important;
    border-bottom: none !important;
    height: 48px !important;
    margin-left: 2px;
    font-family: Arial !important;
    color: transparent !important;
    text-shadow: 0px 0px #313131;
    padding-left: 5px !important;
  }
  .react-select__placeholder,
  .react-select__single-value {
    padding-top: 0px;
    font-size: 14px !important;
    padding-left: 0px !important;
    font-family: Arial !important;
  }
  .react-select__menu {
    transform: translateX(-10px);
  }
  #start-date-container .SingleDatePicker_picker {
    left: -18px !important;
    top: 55px !important;
  }

  #end-date-container .SingleDatePicker_picker {
    left: -28px !important;
    top: 55px !important;
  }

  input.text-input-v2 {
    display: block !important;
    width: 200px !important;
    margin: 10px 0px 10px !important;
  }
`;

class AddClosure extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.startPickerClick = this.startPickerClick.bind(this);
    this.endPickerClick = this.endPickerClick.bind(this);
    this.onEndDateChange = this.onEndDateChange.bind(this);
    this.onEndFocusChange = this.onEndFocusChange.bind(this);
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.onStartFocusChange = this.onStartFocusChange.bind(this);
    this.state = {
      startDate: null,
      endDate: null,
      startPickerFocus: false,
      endPickerFocus: false,
      newClosure: true,
      closures: [],
      selectedClosure: null,
      name: "",
    };
    this.roundDrop = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <RoundDropdown />
        </components.DropdownIndicator>
      );
    };
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };

    this.props.setBackdropLoader(true);
    axios
      .get("get_closure_options")
      .then((res) => {
        this.setState({ closures: res.data }, () => {
          this.props.setBackdropLoader(false);
        });
      })
      .catch((err) => {
        this.props.setBackdropLoader(false);
        console.error(err);
      });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.newClosure) {
      axios
        .post(`/api/v1/closures.json`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          closure: {
            name: this.state.name,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
          },
        })
        .then((res) => {
          M.toast({
            html: `Closure created`,
            displayLength: 3000,
            classes: "green",
          });
          this.props.data.updateClosures(res.data, "create");
          this.props.modalAction(false, "", {});
        })
        .catch((err) => console.error(err));
    } else {
      axios
        .patch(`/api/v1/closures/${this.state.selectedClosure.value}.json`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          closure: {
            name: this.state.name,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
          },
        })
        .then((res) => {
          M.toast({
            html: `Closure updated`,
            displayLength: 3000,
            classes: "green",
          });
          this.props.data.updateClosures(res.data, "update");
          this.props.modalAction(false, "", {});
        })
        .catch((err) => console.error(err));
    }
  }

  startPickerClick() {
    this.setState({ startPickerFocus: true });
  }

  endPickerClick() {
    this.setState({ endPickerFocus: true });
  }

  onEndDateChange(date) {
    this.setState({ endDate: date }, () => {
      if (this.state.endDate < this.state.startDate) {
        this.setState({ startDate: moment(this.state.endDate) });
      }
    });
  }

  onEndFocusChange({ focused }) {
    this.setState({ endPickerFocus: focused });
  }

  onStartDateChange(date) {
    this.setState({ startDate: date }, () => {
      if (this.state.startDate > this.state.endDate) {
        this.setState({ endDate: moment(this.state.startDate) });
      }
    });
  }

  onStartFocusChange({ focused }) {
    this.setState({ startPickerFocus: focused });
  }

  isDayBlocked(day) {
    return moment(day).day() === 0 || moment(day).day() === 6 ? true : false;
  }

  handleNewClosure = (e) => {
    this.setState({
      newClosure: e.target.value === "true",
      selectedClosure: null,
      startDate: null,
      endDate: null,
      name: "",
    });
  };

  handleNameChange = (e) => {
    this.setState({ name: e.target.value ? e.target.value : "" });
  };

  closeModal = () => {
    this.props.modalAction(false, "", {});
  };

  handleClosueChange = (e) => {
    this.setState({
      selectedClosure: e,
      name: e.label,
      startDate: moment(e.start_date),
      endDate: moment(e.end_date),
    });
  };

  deleteClosure = () => {
    Swal.fire({
      title: `Delete Closure`,
      text: `Are you sure that you want to delete this Closure?`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`/api/v1/closures/${this.state.selectedClosure.value}.json`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            M.toast({
              html: `Closure deleted`,
              displayLength: 3000,
              classes: "green",
            });
            this.props.data.updateClosures(res.data, "delete");
            this.props.modalAction(false, "", {});
          })
          .catch((err) => console.error(err));
      }
    });
  };

  render() {
    return (
      <div
        style={{ padding: "32px", overflow: "visible" }}
        className="modal-content "
      >
        <ClosureModal>
          <div style={{ padding: "0px " }}>
            <div
              aria-hidden="true"
              style={{
                backgroundColor: "#519acc",
                overflow: "hidden",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0px 16px 0px 0px",
              }}
            >
              <ClosureModalIcon />
            </div>
            <h2
              style={{
                fontSize: "18px",
                margin: "0px",
                fontWeight: "600",
                fontFamily: "manrope",
              }}
            >
              Closures
            </h2>
            <button
              type="button"
              id="modalClose"
              aria-label="Close Modal"
              className="btn-icon"
              onClick={this.closeModal}
              style={{ position: "absolute", top: "41px", right: "26px" }}
            >
              <ExitIcon
                svgStyles={{ pointerEvents: "none" }}
                height="25px"
                width="25px"
              />
            </button>
          </div>
          <div style={{ marginTop: "32px" }}>
            <div
              style={{ padding: "0px " }}
              className="new_closure"
              id="new_closure"
            >
              {this.state.selectedClosure && (
                <button
                  onClick={this.deleteClosure}
                  style={{
                    display: "block",
                    textAlign: "left",
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                    color: "#519acc",
                    paddingLeft: "0px",
                    marginBottom: "16px",
                  }}
                >
                  Delete Closure
                </button>
              )}
              <span className="modal-field">New Closure?</span> <br />
              <div style={{ marginTop: "8px" }}>
                <label htmlFor="project-confirmed-yes">
                  <input
                    onChange={this.handleNewClosure}
                    className="with-gap"
                    type="radio"
                    name="project-confirmed"
                    id="project-confirmed-yes"
                    value={true}
                    checked={this.state.newClosure}
                  />
                  <span style={{ marginRight: "15px" }}>Yes</span>
                </label>
                <label htmlFor="project-confirmed-no">
                  <input
                    onChange={this.handleNewClosure}
                    className="with-gap"
                    type="radio"
                    name="project-confirmed"
                    id="project-confirmed-no"
                    value={false}
                    checked={!this.state.newClosure}
                  />
                  <span style={{ marginRight: "15px" }}>No</span>
                </label>
              </div>
            </div>
            {!this.state.newClosure && (
              <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                <span style={{ margin: "0px 0px 8px" }} className="modal-field">
                  Select a Closure
                </span>
                <div style={{ marginTop: "8px" }}>
                  {" "}
                  <Select
                    classNamePrefix="react-select"
                    options={this.state.closures}
                    onChange={this.handleClosueChange}
                    value={this.state.selectedClosure}
                    blurInputOnSelect={true}
                    styles={SingleSelectStyles}
                    placeholder="Select a Closure"
                    components={{ DropdownIndicator: this.roundDrop }}
                    isSearchable={false}
                  />
                </div>
              </div>
            )}
            <p style={{ margin: "16px 0px 0px" }} className="modal-field">
              Closure Name <span style={{ color: "#519acc" }}>*</span>
            </p>
            <input
              placeholder="Enter Closure Name"
              type="text"
              className="text-input-v2 browser-default"
              maxLength={60}
              onChange={this.handleNameChange}
              value={this.state.name}
              disabled={!this.state.newClosure && !this.state.selectedClosure}
              style={{ width: "250px !important" }}
            />

            <div style={{ marginTop: "16px", display: "flex" }}>
              <div>
                <span className="modal-field">
                  Start Date <span style={{ color: "#519acc" }}>*</span>
                </span>
                <div
                  id="start-date-container"
                  className="flex-j-start flex-container flex-al-center"
                  style={{ marginTop: "8px" }}
                >
                  <div
                    style={{
                      position: "relative",
                      zIndex: 10,
                      left: "15px",
                      width: "0px",
                      top: "5px",

                      opacity:
                        !this.state.newClosure && !this.state.selectedClosure
                          ? "0.6"
                          : "1",
                    }}
                  >
                    <CalendarIcon width="22px" height="22px" color="#519acc" />
                  </div>
                  <SingleDatePicker
                    date={this.state.startDate} // momentPropTypes.momentObj or null
                    onDateChange={this.onStartDateChange} // PropTypes.func.isRequired
                    focused={this.state.startPickerFocus} // PropTypes.bool
                    onFocusChange={this.onStartFocusChange} // PropTypes.func.isRequired
                    numberOfMonths={1}
                    placeholder={"Select a date"}
                    orientation={"horizontal"}
                    disabled={
                      !this.state.newClosure && !this.state.selectedClosure
                    }
                    daySize={30}
                    navPrev={
                      <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                        <RoundDropdown rotate={"rotate(90deg)"} />
                      </div>
                    }
                    navNext={
                      <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                        <RoundDropdown rotate={"rotate(-90deg)"} />
                      </div>
                    }
                    hideKeyboardShortcutsPanel
                    inputIconPosition={"after"}
                    customInputIcon={<RoundDropdown />}
                    isOutsideRange={this.isDayBlocked}
                    id="single_date_picker" // PropTypes.string.isRequired,
                  />
                </div>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <span className="modal-field">
                  End Date <span style={{ color: "#519acc" }}>*</span>
                </span>
                <div
                  id="end-date-container"
                  className="flex-j-start flex-container flex-al-center"
                  style={{ marginTop: "8px" }}
                >
                  <div
                    style={{
                      position: "relative",
                      zIndex: 10,
                      left: "15px",
                      width: "0px",
                      top: "5px",
                      opacity:
                        !this.state.newClosure && !this.state.selectedClosure
                          ? "0.6"
                          : "1",
                    }}
                  >
                    <CalendarIcon width="22px" height="22px" color="#519acc" />
                  </div>
                  <SingleDatePicker
                    date={this.state.endDate} // momentPropTypes.momentObj or null
                    onDateChange={this.onEndDateChange} // PropTypes.func.isRequired
                    focused={this.state.endPickerFocus} // PropTypes.bool
                    onFocusChange={this.onEndFocusChange} // PropTypes.func.isRequired
                    numberOfMonths={1}
                    placeholder={"Select a date"}
                    orientation={"horizontal"}
                    daySize={30}
                    disabled={
                      !this.state.newClosure && !this.state.selectedClosure
                    }
                    navPrev={
                      <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                        <RoundDropdown rotate={"rotate(90deg)"} />
                      </div>
                    }
                    navNext={
                      <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                        <RoundDropdown rotate={"rotate(-90deg)"} />
                      </div>
                    }
                    hideKeyboardShortcutsPanel
                    inputIconPosition={"after"}
                    customInputIcon={<RoundDropdown />}
                    isOutsideRange={this.isDayBlocked}
                    id="single_date_picker" // PropTypes.string.isRequired,
                  />
                </div>
              </div>
            </div>
            <div style={{ marginTop: "32px" }} className="right-align">
              <button
                onClick={this.closeModal}
                className="common-button-cancel"
                style={{ marginRight: "10px" }}
              >
                Cancel
              </button>
              <button
                disabled={
                  !this.state.name ||
                  !this.state.startDate ||
                  !this.state.endDate ||
                  (!this.state.newClosure && !this.state.selectedClosure)
                }
                onClick={this.handleSubmit}
                className="common-button-submit white-text"
                id="create_closure_button"
              >
                {this.state.newClosure ? "Create" : "Update"} Closure
              </button>
            </div>
          </div>
        </ClosureModal>
      </div>
    );
  }
}
export default AddClosure;
