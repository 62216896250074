import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

class ModalTag extends React.Component {
  constructor(props) {
    super(props);
    this.tagMap = {
      leading: { name: "New Project", color: "#bcbcbc" },
      est_requesting: { name: "Estimate Request", color: "#d3a041" },
      est_sending: { name: "Estimate Sent", color: "#e38345" },
      project_confirming: { name: "Project Confirmed", color: "#498cc7" },
      testing: { name: "Test In Progress", color: "#c95c3e" },
      completing: { name: "Project Complete", color: "#2cb882" },
      readying: { name: "Ready for Invoice", color: "#6f50b7" },
      invoicing: { name: "Invoiced", color: "#9f408f" },
      dropping: { name: "Dropped", color: "lightgrey" },
      hiding: { name: "Hidden", color: "lightgrey" },
      archiving: { name: "Archived", color: "lightgrey" },
    };
  }

  render() {
    const { column, commentTag, tag, bugTrackerTp } = this.props;
    const result = this.tagMap[column];
    if (commentTag) {
      return (
        <CommentTag
          style={{ marginLeft: "auto" }}
          className="tag sm"
          color={result.color}
        >
          {result.name}
        </CommentTag>
      );
    }
    return (
      <div className="flex-container flex-row">
        {tag && (
          <Tag className="tag sm" color={tag.color}>
            {tag.name}
          </Tag>
        )}
        {(bugTrackerTp === true || bugTrackerTp === false) && (
          <Tag className="tag sm" color="#C25771">
            {bugTrackerTp ? "Test Platform" : "Client Bug Tracker"}
          </Tag>
        )}
      </div>
    );
  }
}

const Tag = styled.div`
  background-color: ${(props) => props.color};
  height: 23px;
  color: white;
  min-width: 75px;
  max-width: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
`;

const CommentTag = styled.span`
  display: inline-flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: #578bc2;
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 600;
`;

ModalTag.propTypes = {
  tag: PropTypes.string,
  commentTag: PropTypes.bool,
  column: PropTypes.string,
  bugTrackerTp: PropTypes.bool,
};

ModalTag.defaultProps = {
  tag: "leading",
};

export default ModalTag;
