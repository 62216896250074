import React from "react";

const MeetingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
    >
      <path
        d="M11.9898 11.9254L14.1371 10.9373C14.9603 11.4863 15.9623 11.8157 17.036 11.8157C20.0066 11.8157 22.4402 9.32705 22.4402 6.28957C22.4402 3.21542 20.0424 0.763428 17.036 0.763428C14.0655 0.763428 11.6318 3.21536 11.6318 6.28957C11.6318 7.38747 11.954 8.41225 12.4908 9.25389L11.5246 11.4497C11.4171 11.7425 11.7034 12.0719 11.9898 11.9255V11.9254ZM19.8634 5.44774C20.3287 5.44774 20.6866 5.81371 20.6866 6.28954C20.6866 6.76536 20.3287 7.13133 19.8634 7.13133C19.3981 7.13133 19.0402 6.76536 19.0402 6.28954C19.0402 5.81371 19.434 5.44774 19.8634 5.44774ZM17.036 5.44774C17.5013 5.44774 17.8592 5.81371 17.8592 6.28954C17.8592 6.76536 17.5013 7.13133 17.036 7.13133C16.5707 7.13133 16.2128 6.76536 16.2128 6.28954C16.2129 5.81371 16.6066 5.44774 17.036 5.44774ZM14.2445 5.44774C14.7098 5.44774 15.0677 5.81371 15.0677 6.28954C15.0677 6.76536 14.7098 7.13133 14.2445 7.13133C13.7792 7.13133 13.4213 6.76536 13.4213 6.28954C13.4213 5.81371 13.7792 5.44774 14.2445 5.44774Z"
        fill="url(#paint0_linear_3100_4080)"
      />
      <path
        d="M11.0233 19.9403H8.62549C8.37502 19.9403 8.16017 19.7207 8.16017 19.4645V14.0115C8.16017 11.8888 6.54966 10.0591 4.50967 9.94927C2.25488 9.83955 0.429688 11.6694 0.429688 13.9017V23.4169C0.429688 24.6247 1.39594 25.6493 2.6128 25.6493H7.44436C7.69484 25.6493 7.90968 25.8689 7.90968 26.1251V31.1389C7.90968 32.0904 8.66122 32.8589 9.59171 32.8589H11.7748C12.7053 32.8589 13.4569 32.0904 13.4569 31.1389L13.457 22.4289C13.457 21.0748 12.3475 19.9403 11.0234 19.9403L11.0233 19.9403Z"
        fill="url(#paint1_linear_3100_4080)"
      />
      <path
        d="M8.58943 4.42313C8.58943 6.8485 6.66656 8.81476 4.29471 8.81476C1.92287 8.81476 0 6.8485 0 4.42313C0 1.99776 1.92287 0.0314941 4.29471 0.0314941C6.66656 0.0314941 8.58943 1.99776 8.58943 4.42313Z"
        fill="url(#paint2_linear_3100_4080)"
      />
      <path
        d="M29.7053 9.94949C27.5937 9.94949 25.8401 11.7062 25.8401 13.902V19.4647C25.8401 19.7208 25.6254 19.9405 25.3747 19.9405H22.9769C21.6528 19.9405 20.5791 21.0384 20.5791 22.3925V31.1391C20.5791 32.0906 21.3306 32.8591 22.2611 32.8591H24.4443C25.3747 32.8591 26.1263 32.0906 26.1263 31.1391L26.1264 26.1253C26.1264 25.8692 26.3411 25.6495 26.5917 25.6495H31.4233C32.6044 25.6495 33.6064 24.6615 33.6064 23.4171V13.9019C33.5706 11.7061 31.8527 9.94946 29.7054 9.94946L29.7053 9.94949Z"
        fill="url(#paint3_linear_3100_4080)"
      />
      <path
        d="M34.0001 4.42313C34.0001 6.8485 32.0772 8.81476 29.7054 8.81476C27.3335 8.81476 25.4106 6.8485 25.4106 4.42313C25.4106 1.99776 27.3335 0.0314941 29.7054 0.0314941C32.0772 0.0314941 34.0001 1.99776 34.0001 4.42313Z"
        fill="url(#paint4_linear_3100_4080)"
      />
      <path
        d="M22.296 17.964C22.976 17.964 23.5129 17.415 23.5129 16.7197C23.5129 16.0243 22.976 15.4753 22.296 15.4753H11.6666C10.9865 15.4753 10.4497 16.0243 10.4497 16.7197C10.4497 17.415 10.9865 17.964 11.6666 17.964H15.7823V31.7244C15.7823 32.4198 16.3192 32.9688 16.9992 32.9688C17.6792 32.9688 18.2161 32.4198 18.2161 31.7244V17.964H22.296Z"
        fill="url(#paint5_linear_3100_4080)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3100_4080"
          x1="16.9708"
          y1="0.763428"
          x2="16.9708"
          y2="11.9614"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3100_4080"
          x1="6.94334"
          y1="9.94458"
          x2="6.94334"
          y2="32.8589"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3100_4080"
          x1="4.29471"
          y1="0.0314941"
          x2="4.29471"
          y2="8.81476"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3100_4080"
          x1="27.0927"
          y1="9.94946"
          x2="27.0927"
          y2="32.8591"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3100_4080"
          x1="29.7054"
          y1="0.0314941"
          x2="29.7054"
          y2="8.81476"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3100_4080"
          x1="16.9813"
          y1="15.4753"
          x2="16.9813"
          y2="32.9688"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MeetingIcon;
