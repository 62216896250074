import React, { useState } from "react";
import styled from "styled-components";
import ms from "components/modals/ModalStyled";
import ExitIcon from "../../icons/exit_icon";

const InvoiceCard = styled.div``;

const ReadyingTransition = (props) => {
  const { project, submitInvoice, cancelHandler } = props;

  const [invoiceNum, setInvoiceNum] = useState("");

  const poNumber = project.last_ready_for_invoice.po_number;

  function handleInputChange(e) {
    setInvoiceNum(e.target.value);
  }

  function submit(e) {
    e.preventDefault();
    const invoice = project.last_invoiced
      ? project.last_invoiced
      : project.last_ready_for_invoice;
    if (invoiceNum) {
      submitInvoice(invoice.id, invoiceNum);
    } else {
      // eslint-disable-next-line no-undef
      M.toast({
        html: "Please enter an invoice number to continue",
        classes: "danger",
      });
    }
  }

  return (
    <InvoiceCard>
      <ms.Form onSubmit={submit}>
        <ms.FormRow>
          <ms.FormItem>
            <div>
              <a
                style={{ color: "#519acc" }}
                target="_blank"
                rel="noreferrer"
                href={`/time_tracking/clients/${project.client_id}`}
              >
                {project.client_name}
              </a>{" "}
              -{" "}
              <a
                style={{ color: "#519acc" }}
                target="_blank"
                rel="noreferrer"
                href={`/time_tracking/projects/${project.id}`}
              >
                {project.name}
              </a>
            </div>
            <div>
              PO#:{" "}
              <b style={{ fontWeight: !poNumber ? "400" : "600" }}>
                {!poNumber ? "None Provided" : poNumber}
              </b>
            </div>
            <div>
              Payment Due:{" "}
              <b style={{ fontWeight: "600" }}>
                $
                {(project.rate / 100) *
                  (project.last_ready_for_invoice &&
                  project.last_ready_for_invoice.tracked_hours
                    ? project.last_ready_for_invoice.tracked_hours
                    : project.budgeted_hours)}
              </b>
            </div>
          </ms.FormItem>
        </ms.FormRow>
        <ms.FormRow>
          <ms.FormItem>
            <ms.Label className="custom-labels" htmlFor="filter_filters">
              Please enter an invoice number for this submission
            </ms.Label>
            <input
              required="required"
              className="browser-default text-input-v2"
              type="text"
              maxLength={60}
              value={invoiceNum}
              autoComplete="off"
              onChange={handleInputChange}
              name="filterName"
              id="filter_name"
            />
          </ms.FormItem>
        </ms.FormRow>
        <ms.ControlsRow>
          <button
            onClick={cancelHandler}
            className="common-button-cancel"
            type="button"
          >
            Cancel
          </button>
          <button className="common-button-submit" type="submit">
            Confirm
          </button>
        </ms.ControlsRow>
      </ms.Form>
    </InvoiceCard>
  );
};

export default ReadyingTransition;
