class Timer {
  constructor(t, f, p) {
    this.function = f;
    this.parameter = p;
    this.time = t;
    this.start = Date.now();
    this.timer = setTimeout(() => {
      f(p || "");
    }, t);
  }

  stopTimer() {
    clearTimeout(this.timer);
  }

  resetTimer() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.parameter ? this.function(this.parameter) : this.function();
    }, this.time);
  }

  pauseTimer() {
    clearTimeout(this.timer);
    this.pausedStart = this.time - (Date.now() - this.start);
  }

  unpauseTimer() {
    this.timer = setTimeout(
      () => {
        this.parameter ? this.function(this.parameter) : this.function();
      },
      this.pausedStart ? this.pausedStart : this.time
    );
    this.pausedStart = null;
  }
}

export default Timer;
