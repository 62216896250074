import React from "react";
import moment from "moment";
import axios from "axios";
import UnavailablityListItem from "./UnavailabilityListItem";

class UserVacationWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPeriodStart: moment().format("YYYY-MM-DD"),
      tasks: [],
    };

    this.getUserTasks = this.getUserTasks.bind(this);
    this.renderTasks = this.renderTasks.bind(this);
  }

  componentDidMount() {
    this.getUserTasks();
  }

  getUserTasks() {
    const { user } = this.props;
    const { id } = user;
    const url = `/find_tasks/${id}`;
    axios
      .get(`/find_tasks/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.setState({ tasks: res.data.tasks });
      })
      .catch((err) => console.error(err));
  }

  renderTasks() {
    const firstDay = this.state.currentPeriodStart;
    const { tasks } = this.state;
    const taskNode = [];
    let offset = 0;
    const currentPeriod = [];
    let day;
    let displayDate;
    while (offset < 180) {
      currentPeriod.push(
        moment(firstDay).add(offset++, "d").format("YYYY-MM-DD")
      );
    }
    for (let i = 0; i < currentPeriod.length; i++) {
      if (
        moment(currentPeriod[i]).format("dddd") === "Monday" ||
        moment(currentPeriod[i]).format("dddd") === "Tuesday" ||
        moment(currentPeriod[i]).format("dddd") === "Wednesday" ||
        moment(currentPeriod[i]).format("dddd") === "Thursday" ||
        moment(currentPeriod[i]).format("dddd") === "Friday"
      ) {
        for (let y = 0; y < tasks.length; y++) {
          if (
            tasks[y].start_date <= currentPeriod[i] &&
            tasks[y].end_date >= currentPeriod[i] &&
            tasks[y].name === "Vacation"
          ) {
            day = moment(currentPeriod[i]).format("dddd");
            displayDate = moment(currentPeriod[i]).format("M/DD");
            taskNode.push(
              <UnavailablityListItem
                day={day}
                displayDate={displayDate}
                taskId={tasks[y].id}
                hours={tasks[y].hours}
                key={i}
              />
            );
          }
        }
      }
    }
    return <div>{taskNode}</div>;
  }

  render() {
    return (
      <div>
        <ul className="collection">{this.renderTasks()}</ul>
      </div>
    );
  }
}

export default UserVacationWidget;
