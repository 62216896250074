import React from "react";
import styled from "styled-components";
import UserCard from "./user_card";

const AlphabetUserRow = ({ index, users }) => {
  const roleTextMap = {
    1: "Tester",
    2: "Lead",
    3: "PM",
    4: "HR",
    5: "Admin",
  };

  const RoleColorMap = {
    1: "#F55454",
    2: "#386FFE",
    3: "#20835D",
    4: "#7A51AD",
    5: "#4F2A9E",
  };

  const renderClients = () => {
    return users.map((user, i) => (
      <UserCard
        userName={user.name}
        userPath={user.id}
        userAvatar={user.avatar_file_name}
        userFullTime={user.full_time}
        userContractor={user.contractor}
        userRole={roleTextMap[user.role_id] || ""}
        userRoleColor={RoleColorMap[user.role_id] || ""}
        key={i}
      />
    ));
  };

  return (
    <Section id={index} className="scrollspy">
      <SectionHeader>
        <LetterCircle>{index}</LetterCircle>
        <DividerLine />
      </SectionHeader>
      <div
        style={{
          paddingLeft: "0px",
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
        }}
        className="row"
      >
        {renderClients()}
      </div>
    </Section>
  );
};

const Section = styled.div`
  margin: 0px 0px 32px;
`;

const SectionHeader = styled.h4`
  display: flex;
  align-items: center;
  margin: 0px 0px 16px;
`;

const LetterCircle = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 40px;
  background-color: #519acc;
  padding: 6px 11px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
`;

const DividerLine = styled.div`
  height: 1px;
  width: calc(100% - 70px);
  background-color: #d7d7d7;
`;

export default AlphabetUserRow;
