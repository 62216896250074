import React from "react";
import styled from "styled-components";
import Select from "react-select";
import { CSSTransition } from "react-transition-group";
import SingleSelectStyles from "../common/single_select_styles";

const MemberContainer = styled.div.attrs((props) => ({
  overflow: props.overflow,
}))`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const UserCircle = styled.div`
  height: 28px;
  width: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  background-color: #519acc;
  > .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #519acc;
    opacity: 0;
    background-color: #00000057;
    transition: opacity 0.3s;
    pointer-events: none;
    z-index: 1;
  }

  :hover {
    z-index: 2;
    border: 1px solid lightgrey;
  }

  :hover > .overlay {
    opacity: 1;
  }

  > img {
    height: 100%;
    width: 100%;
    pointer-events: none;
  }

  > span {
    color: white;
    pointer-events: none;
  }
`;
const AddCircle = styled.div`
  height: 28px;
  width: 28px;
  border-radius: 50%;
  display: ${(props) => (props.hide ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  color: #519acc;
  border: solid 1px transparent;
  background-color: #eceef0;
  transition: border 0.3s;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 1px 2px #c5c5c5;
  z-index: 1;

  > span {
    align-items: center;
    justify-content: center;
  }

  > svg {
    pointer-events: none;
    background: none;
  }

  :hover {
    background-color: #d9dadb;
  }

  > .circle-select {
    position: absolute;
    height: 0px;
    left: 0;
    bottom: 0;
  }

  > .circle-select > div:first-of-type {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
  }

  .react-select__menu {
    z-index: 11 !important;
  }
`;

class TeamShow extends React.Component {
  constructor(props) {
    super(props);
    const leads = [];
    this.props.users.map((u) =>
      leads.push({
        value: u.id,
        label: u.name,
        role_name: u.role_name,
        avatar_url: u.avatar_url,
      })
    );
    this.state = {
      projectLeads: leads,
      loading: false,
    };
    this.addCircleRef = React.createRef();
    this.userEnter = this.userEnter.bind(this);
    this.userLeave = this.userLeave.bind(this);
    this.userExtraEnter = this.userExtraEnter.bind(this);
  }

  userLeave() {
    const popUpElems = document.getElementsByClassName(`user-hover-card`);
    const element = document.querySelector("body");
    const scrollElems = document.getElementsByClassName(
      "ReactVirtualized__Grid ReactVirtualized__List"
    );
    Array.from(scrollElems).map((scrollElem) =>
      scrollElem.removeEventListener("scroll", this.userLeave)
    );
    Array.from(popUpElems).map((elem) => elem.classList.remove("active"));
    Array.from(popUpElems).map((elem) => element.removeChild(elem));
  }

  componentDidUpdate(prevProps) {
    if (this.props.users.length != prevProps.users.length) {
      const leads = [];
      this.props.users.map((u) =>
        leads.push({
          value: u.id,
          label: u.name,
          role_name: u.role_name,
          avatar_url: u.avatar_url,
        })
      );
      this.setState({
        projectLeads: leads,
      });
    }
  }

  userEnter(e) {
    e.preventDefault();
    e.stopPropagation();
    const popUpElems = document.getElementsByClassName(`user-hover-card`);
    if (popUpElems.length >= 1) {
      this.userLeave();
    }
    const scrollParent =
      e.target.parentElement.parentElement.parentElement.parentElement
        .parentElement.parentElement.parentElement;
    if (
      scrollParent.className === "ReactVirtualized__Grid ReactVirtualized__List"
    ) {
      scrollParent.addEventListener("scroll", this.userLeave);
    }
    const rect = e.target.getBoundingClientRect();
    const popUpElem = document.createElement("div");
    popUpElem.setAttribute("id", `${e.target.dataset.id}-pop-up`);
    popUpElem.setAttribute("class", `user-hover-card`);
    popUpElem.style.top = `${rect.top - 20}px`;
    popUpElem.style.zIndex = "901";
    popUpElem.innerHTML = `${e.target.dataset.name}`;
    const element = document.querySelector("body");
    element.appendChild(popUpElem);
    let marginModifier = 0;
    if (e.target.parentElement) {
      marginModifier = parseInt(e.target.parentElement.style.marginLeft);
      if (marginModifier === 0) {
        marginModifier = -30;
      } else {
        marginModifier += 8;
      }
    }
    // need to set left after element is appended to parent element
    popUpElem.style.left = `${rect.left - (rect.width / 2 - marginModifier)}px`;
    popUpElem.classList.add("active");
  }

  userExtraEnter(e) {
    e.preventDefault();
    e.stopPropagation();
    let self;
    const popUpElems = document.getElementsByClassName(`user-hover-card`);
    if (popUpElems.length >= 1) {
      this.userLeave();
    }
    const scrollParent =
      e.target.parentElement.parentElement.parentElement.parentElement
        .parentElement.parentElement;
    if (
      scrollParent.className === "ReactVirtualized__Grid ReactVirtualized__List"
    ) {
      scrollParent.addEventListener("scroll", this.userLeave);
    }
    const rect = e.target.getBoundingClientRect();
    const popUpElem = document.createElement("div");
    popUpElem.setAttribute("id", `${e.target.dataset.id}-pop-up`);
    popUpElem.setAttribute("class", `user-hover-card`);
    popUpElem.style.top = `${rect.top + 40}px`;
    popUpElem.style.zIndex = "901";
    let extraNames = "";
    this.state.projectLeads.map((x, index) => {
      if (index >= this.props.maxCards) {
        return (extraNames += `<li>${x.label.split(" ")[0]} is ${
          x.role_name
        }</li>`);
      }
    });
    popUpElem.innerHTML = `<ul>${extraNames}</ul>`;
    popUpElem.style.height = "min-content";
    const element = document.querySelector("body");
    element.appendChild(popUpElem);
    // need to set left after element is appended to parent element
    popUpElem.style.left = `${rect.left - rect.width / 2}px`;
    popUpElem.classList.add("active");
  }

  render() {
    return (
      <div className="flex-j-end" style={{ flex: "1" }}>
        <MemberContainer overflow={this.props.overflow}>
          {this.state.projectLeads.map((u, index) => {
            if (index <= this.props.maxCards - 1) {
              return (
                <div
                  className="hoverable"
                  data-id={u.value}
                  data-name={
                    u.role_name ? `${u.label} is ${u.role_name}` : u.label
                  }
                  onMouseEnter={this.userEnter}
                  onMouseLeave={this.userLeave}
                  key={`${u.role_name}_${u.value}`}
                  name={u.label}
                  style={{
                    position: "relative",
                    height: "30px",
                    borderRadius: "50%",
                    marginLeft: index === 0 ? "0" : "-20px",
                  }}
                >
                  <UserCircle
                    data-id={u.value}
                    data-name={
                      u.role_name
                        ? `${u.label.split(" ")[0]} is ${u.role_name}`
                        : u.label
                    }
                    name={u.label}
                    count={this.state.projectLeads.length}
                    role="button"
                    id={u.value}
                  >
                    {/* <>
                    {u && u.avatar_url && ( */}
                    {u.avatar_url && !u.avatar_url.includes("missing.png") ? (
                      <img src={u.avatar_url} />
                    ) : (
                      <span style={{ color: "#fff" }}>
                        {u.label
                          .split(" ")
                          .map((word, i) =>
                            i < 2 ? word.charAt(0).toUpperCase() : ""
                          )}
                      </span>
                    )}
                    {/* )}
                  </> */}
                  </UserCircle>
                </div>
              );
            }
          })}
          {!this.props.prospect && (
            <AddCircle
              data-id={this.state.projectLeads.length}
              onMouseEnter={this.userExtraEnter}
              onMouseLeave={this.userLeave}
              hide={
                this.props.hide
                  ? true
                  : this.state.projectLeads.length <= this.props.maxCards
              }
              role="button"
              className="hoverable"
              id="member-add-circle"
            >
              <span>
                +{this.state.projectLeads.length - this.props.maxCards}
              </span>
              <Select
                ref={this.addCircleRef}
                classNamePrefix="react-select"
                menuIsOpen={false}
                options={[]}
                value=""
                className="circle-select"
                styles={{
                  ...SingleSelectStyles,
                  ...{
                    container: (styles) => ({
                      ...styles,
                      width: "175px",
                      zIndex: "11",
                    }),
                  },
                }}
              />
              <CSSTransition
                unmountOnExit
                in={this.state.loading}
                timeout={300}
                classNames="node-fade"
              >
                <div
                  className="invoice-search-input-spinner"
                  style={{ right: "7px", backgroundColor: "#ffffff" }}
                />
              </CSSTransition>
            </AddCircle>
          )}
        </MemberContainer>
      </div>
    );
  }
}

export default TeamShow;
