import React from "react";
import styled from "styled-components";
import ContactCard from "./contact_card";

const ProjectContactsBox = styled.div`
  margin: -10px 0 0;
  flex: 1;
  p {
    font-size: 16px;
  }
  p.title-box {
    color: #6e6e6e;
    font-size: 15px;
  }
`;

// I wrote this because the hooks one didn't want to play nice with the modal. I would update the contacts props and it wouldn't update
class Contacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: -1,
      firstEdit: false,
    };
    this.addContact = this.addContact.bind(this);
    this.toggleFirstEdit = this.toggleFirstEdit.bind(this);
    this.activeIndexHandler = this.activeIndexHandler.bind(this);
    this.editContact = this.editContact.bind(this);
    this.resizeListener = this.resizeListener.bind(this);
  }

  addContact() {
    this.props.addContactHandler(this.props.type);
    // this.setState({firstEdit: true, activeIndex: this.props.contacts.length});
  }

  toggleFirstEdit(value) {
    this.setState({ firstEdit: value });
  }

  activeIndexHandler(index) {
    this.setState({ activeIndex: index });
  }

  editContact() {
    this.props.editContact(props.contact);
  }

  resizeListener() {
    var width = this.getWidth();
    this.setState({ width: width });
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  getWidth = () => {
    const div = document.getElementsByClassName("members-inner-div");
    if (div.length >= 1) {
      return div[0].clientWidth;
    } else if (window.innerWidth > 1500) {
      return window.innerWidth;
    } else {
      return 0;
    }
  };

  // componentDidUpdate(prevProps, prevState) {
  //   Object.entries(this.props).forEach(
  //     ([key, val]) =>
  //       prevProps[key] !== val && console.log(`Prop '${key}' changed`)
  //   );
  //   if (this.state) {
  //     Object.entries(this.state).forEach(
  //       ([key, val]) =>
  //         prevState[key] !== val && console.log(`State '${key}' changed`)
  //     );
  //   }
  // }

  render() {
    return (
      <ProjectContactsBox
        style={
          this.props.details ? { marginBottom: "20px", padding: "16px" } : {}
        }
      >
        <div
          style={
            this.props.scroll && this.props.scroll === true
              ? { maxHeight: "100px", padding: "2px", overflow: "auto" }
              : {}
          }
          className="members-inner-div"
        >
          <div
            style={{ margin: "10px 0", padding: "10px" }}
            className="flex-al-center flex-row flex-space-between flex-container flex-wrap"
          >
            {this.props.contacts.length === 0 && (
              <p
                style={{ flex: 1, textAlign: "center", color: "#b4b4b4" }}
              >{`No ${
                props.type === "invoicing" ? "Invoice" : "Client"
              } Contacts to Display`}</p>
            )}
            {this.props.details ? (
              <button
                style={{ marginLeft: "auto", width: "auto" }}
                disabled={this.state.activeIndex != -1}
                type="button"
                onClick={this.addContact}
                className="common-button-submit"
              >{`Add ${
                this.props.type === "invoicing" ? "Invoice" : "Client"
              } Contact`}</button>
            ) : (
              <button
                style={{ marginLeft: "auto", width: "auto" }}
                disabled={this.state.activeIndex != -1}
                type="button"
                onClick={this.addContact}
                className="common-button-submit"
              >
                Add Contact
              </button>
            )}
          </div>
          <div
            style={
              this.props.details && this.state.width >= 1000
                ? {
                    display: "grid",
                    gridTemplateColumns: "calc(50% - 5px) calc(50% - 5px)",
                    gridGap: "10px",
                  }
                : {}
            }
          >
            {this.props.contacts.map((contact, index) => {
              return (
                <ContactCard
                  firstEdit={this.state.firstEdit}
                  toggleFirstEdit={this.toggleFirstEdit}
                  index={index}
                  activeIndexHandler={this.activeIndexHandler}
                  active={this.state.activeIndex === index}
                  submitHandler={this.props.submitHandler}
                  deleteContact={this.props.deleteContactHandler}
                  key={contact.id}
                  contact={contact}
                />
              );
            })}
          </div>
        </div>
      </ProjectContactsBox>
    );
  }
}

export default Contacts;
