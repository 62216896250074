import React from "react";
import axios from "axios";
import Select, { components } from "react-select";
import "react-dates/initialize";
import RoundDropdown from "../projects/round_dropdown.jsx";
import SingleCreateableSelectWrapper from "../common/single_creatable_select_wrapper";
import SingleSelectWrapper from "../common/single_select_wrapper";
import AddProjectModalIcon from "../icons/add_project_icon.jsx";
import ExitIcon from "../icons/exit_icon.jsx";

class AddProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      selectedClient: this.props.data.selectedClient
        ? this.props.data.selectedClient
        : "",
      project_name: "",
      description: "",
      lead_user: {
        label: this.props.data.currentUser.name,
        value: this.props.data.currentUser.id,
      },
      leadSource: "",
      start_date: null,
      contacts: [],
      tag: null,
      end_date: null,
      project_status: false,
      start_picker_focus: false,
      end_picker_focus: false,
      focusCounter: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClientChange = this.handleClientChange.bind(this);
    this.handleLeadChange = this.handleLeadChange.bind(this);
    this.getSelectTheme = this.getSelectTheme.bind(this);
    this.handleStartFocus = this.handleStartFocus.bind(this);
    this.handleEndFocus = this.handleEndFocus.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleSourceChange = this.handleSourceChange.bind(this);
    this.submitButton = React.createRef();
    this.addContactHandler = this.addContactHandler.bind(this);
    this.editContactHandler = this.editContactHandler.bind(this);
    this.deleteContactHandler = this.deleteContactHandler.bind(this);
    this.labelInput = (props) => <components.Input {...props} maxLength={20} />;
    this.roundDrop = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <RoundDropdown />
        </components.DropdownIndicator>
      );
    };
    this.DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <i className="material-icons base-color-hover">search</i>
        </components.DropdownIndicator>
      );
    };
  }

  handleStartFocus(focused) {
    if (
      this.state.start_picker_focus &&
      focused.focused &&
      this.state.focusCounter === false
    ) {
      this.setState({ start_picker_focus: true, focusCounter: true });
    } else if (
      this.state.start_picker_focus &&
      focused.focused &&
      this.state.focusCounter
    ) {
      this.setState({ start_picker_focus: false, focusCounter: false });
    } else {
      this.setState({
        start_picker_focus: focused.focused,
        focusCounter: false,
      });
    }
  }

  handleEndFocus(focused) {
    // idk why but on focus happens twice on open, the focusCounter gets around this
    if (
      this.state.end_picker_focus &&
      focused.focused &&
      this.state.focusCounter === false
    ) {
      this.setState({ end_picker_focus: true, focusCounter: true });
    } else if (
      this.state.end_picker_focus &&
      focused.focused &&
      this.state.focusCounter
    ) {
      this.setState({ end_picker_focus: false, focusCounter: false });
    } else {
      this.setState({ end_picker_focus: focused.focused, focusCounter: false });
    }
  }

  handleStartDateChange(date) {
    this.setState({ start_date: date });
  }

  handleEndDateChange(date) {
    this.setState({ end_date: date });
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    if (this.props.data.clientOptions) {
      var options = [];
      this.props.data.clientOptions.map((client) => {
        options.push({ value: client.id, label: client.name });
      });
      this.setState({
        options: options,
      });
    }
    setTimeout(() => {
      var elem = document.querySelector("#add-project-modal h4");
      if (elem) {
        elem.focus();
      }
    }, 100);
  }

  componentWillUnmount() {
    var elem = document.querySelector(".common-button-submit");
    if (elem) {
      elem.focus();
    }
  }

  closeModal = () => {
    this.props.modalAction(false, "", {});
  };

  handleInputChange(e) {
    var input = e.target.value;
    var name = e.target.name;
    this.setState({ [name]: input });
  }

  handleSourceChange(e) {
    var e = e ? e : {};
    this.setState({ leadSource: e });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    var errors = [];
    if (this.state.project_name === "") {
      errors.push("a project name");
    }
    if (!this.props.data.clientId && !this.state.selectedClient) {
      errors.push("a client");
    }
    if (!this.props.data.lead_user && !this.state.lead_user) {
      errors.push("a lead");
    }
    if (!this.state.tag) {
      errors.push("a project type");
    }
    if (errors.length >= 1) {
      M.toast({
        html: `Please select ${errors
          .join(", ")
          .replace(/, ([^,]*)$/, " and $1")}`,
        displayLength: 3000,
        classes: "red",
      });
      return;
    }
    if (this.submitButton && this.submitButton.current) {
      this.submitButton.current.disabled = true;
    }
    this.props.setBackdropLoader(true);
    axios
      .post(`/api/v1/projects.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        projects_page: true,
        project: {
          name: this.state.project_name,
          client_id: this.props.data.clientId
            ? this.props.data.clientId
            : this.state.selectedClient["__isNew__"]
            ? { name: this.state.selectedClient.label, new: true }
            : this.state.selectedClient.value,
          description: this.state.description,
          start_date: this.state.start_date,
          invoicing_type: 2,
          end_date: this.state.end_date,
          lead_source: this.state.leadSource.value,
          lead_owner_id: this.state.lead_user.value,
          tag_id: this.state.tag.value,
          contacts_attributes: this.state.contacts,
        },
      })
      .then((res) => {
        M.toast({
          html: `${res.data.name} created!`,
          displayLength: 3000,
          classes: "green",
        });
        this.props.setBackdropLoader(false);
        this.props.data.projectsHandler(res.data);
        this.props.modalAction(false, "", {});
      })
      .catch((err) => {
        if (err.response.status === 422) {
          M.toast({
            html: "Project already exists, choose a unique name.",
            displayLength: 3000,
            classes: "red",
          });
        } else {
          M.toast({
            html: "There was an error creating the project",
            displayLength: 3000,
            classes: "red",
          });
        }
        this.props.setBackdropLoader(false);
        console.error(err);
      });
  };

  handleLeadChange(e) {
    this.setState({
      lead_user: e,
    });
  }

  handleClientChange(e) {
    this.setState({
      selectedClient: e,
    });
  }

  handleTagChange = (e) => {
    this.setState({
      tag: e ? e : [],
    });
  };

  getSelectTheme(theme) {
    return {
      ...theme,
      colors: { ...theme.colors, primary: "#2bbbad", neutral0: "#fafafa" },
    };
  }

  editContactHandler(contact) {
    var contacts = this.state.contacts;
    for (var i = 0; i < contacts.length; i++) {
      // hacky but I'm using the phone to pass a key to the non id ones to get them edited
      if (contacts[i].phone_number === contact.phone_number) {
        contacts.splice(i, 1, contact);
        break;
      }
    }
    this.setState({ contacts: contacts });
  }

  addContactHandler(type) {
    var contact = {
      type: type,
      name: "",
      phone_number: Math.random().toString().slice(2, 11),
      job: "Sorcerer",
      email: "",
      user_id: this.props.data.currentUser.id,
      invoicing: type === "invoicing",
    };
    var contacts = this.state.contacts;
    contacts.push(contact);
    this.setState({ contacts: contacts });
  }

  deleteContactHandler(contact) {
    var contacts = this.state.contacts;
    for (var i = 0; i < contacts.length; i++) {
      // hacky but I'm using the phone to pass a key to the non id ones to get them edited
      if (contacts[i].phone_number === contact.phone_number) {
        contacts.splice(i, 1);
        break;
      }
    }
    this.setState({ contacts: contacts });
  }

  render() {
    return (
      <div
        role="dialog"
        aria-label="Add Project Modal"
        style={{ padding: "32px" }}
        id="add-project-modal"
        className="modal-content"
      >
        <div
          style={{ marginLeft: "0px" }}
          className="flex-container flex-al-center flex-j-start"
        >
          <AddProjectModalIcon />
          <h4
            tabIndex={0}
            style={{
              marginLeft: "16px",
              fontFamily: "manrope",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Add a Project
          </h4>
          <button
            type="button"
            id="modalClose"
            aria-label="Close Modal"
            className="btn-icon"
            onClick={this.closeModal}
            style={{ position: "absolute", top: "40px", right: "26px" }}
          >
            <ExitIcon
              svgStyles={{ pointerEvents: "none" }}
              height="25px"
              width="25px"
            />
          </button>
        </div>
        <form
          className="new_project"
          id="new_project"
          onSubmit={this.handleSubmit}
        >
          {this.state.options.length > 0 && (
            <div
              style={{ marginBottom: "16px", marginTop: "32px" }}
              className=""
            >
              <label
                className="select-labels"
                htmlFor="filter_filters"
                style={{
                  fontFamily: "manrope",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                Client<span className="base-color">*</span>
              </label>
              <SingleCreateableSelectWrapper
                options={this.state.options}
                ariaLabel="Project Client dropdown"
                onChange={this.handleClientChange}
                value={this.state.selectedClient}
                customStyles={{
                  container: (styles) => ({
                    ...styles,
                    width: "360px",
                    marginTop: "8px",
                  }),
                }}
              />
            </div>
          )}
          <div className="">
            <label
              htmlFor="project_name"
              style={{
                fontFamily: "manrope",
                fontSize: "13px",
                fontWeight: "600",
              }}
            >
              Project Name<span className="base-color">*</span>
            </label>
            <input
              placeholder="Project Name"
              type="text"
              name="project_name"
              id="project_name"
              maxLength="50"
              autoComplete="off"
              className="browser-default text-input-v2"
              onChange={this.handleInputChange}
              value={this.state.project_name}
              style={{ width: "360px", marginTop: "8px", marginBottom: "8px" }}
            />

            <p
              aria-hidden={true}
              style={{
                fontFamily: "arial",
                fontSize: "13px",
                fontWeight: "400",
                margin: "0px 0px 16px",
              }}
            >{`${this.state.project_name.length}/50`}</p>
          </div>
          <div className="flex-container">
            <div className="">
              <label
                className="select-labels"
                htmlFor="filter_filters"
                style={{
                  fontFamily: "manrope",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                Project Lead<span className="base-color">*</span>
              </label>
              <SingleSelectWrapper
                options={this.props.data.leadOptions}
                onChange={this.handleLeadChange}
                placeholder={"Select User"}
                value={this.state.lead_user}
                ariaLabel="Project lead dropdown"
                menuPlacement="top"
                customStyles={{
                  container: (styles) => ({
                    ...styles,
                    width: "175px",
                    marginTop: "8px",
                  }),
                }}
              />
            </div>
          </div>
          <div style={{ marginTop: "20px" }} className="flex-container">
            <div className="">
              <label
                className="select-labels"
                htmlFor="filter_filters"
                style={{
                  fontFamily: "manrope",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                Project Type<span className="base-color">*</span>
              </label>
              <SingleSelectWrapper
                options={this.props.data.tagOptions}
                onChange={this.handleTagChange}
                placeholder={"Select Type"}
                ariaLabel="Project type dropdown"
                value={this.state.tag}
                menuPlacement="top"
                customStyles={{
                  container: (styles) => ({
                    ...styles,
                    width: "175px",
                    marginTop: "8px",
                  }),
                }}
              />
            </div>
          </div>
          <div style={{ marginTop: "30px" }} className="button-row right-align">
            <button
              type="button"
              name="cancel"
              value="Cancel"
              onClick={this.closeModal}
              className="common-button-cancel"
            >
              Cancel
            </button>
            <button
              disabled={
                this.state.selectedClient === "" ||
                this.state.project_name === "" ||
                this.state.lead_user === "" ||
                this.state.tag === null
              }
              type="submit"
              name="commit"
              value="Create Project"
              className="common-button-submit"
              style={{ marginRight: "0px" }}
            >
              Create Project
            </button>
          </div>
        </form>
      </div>
    );
  }
}
export default AddProject;
