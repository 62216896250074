import React from "react";
import moment from "moment";
import axios from "axios";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";

class EditClosure extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.startPickerClick = this.startPickerClick.bind(this);
    this.endPickerClick = this.endPickerClick.bind(this);
    this.onEndDateChange = this.onEndDateChange.bind(this);
    this.onEndFocusChange = this.onEndFocusChange.bind(this);
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.onStartFocusChange = this.onStartFocusChange.bind(this);
    this.deleteClosure = this.deleteClosure.bind(this);
    this.state = {
      name: this.props.closure.name,
      startDate: moment(this.props.closure.startDate),
      endDate: moment(this.props.closure.endDate),
      startPickerFocus: false,
      endPickerFocus: false,
    };
  }

  startPickerClick() {
    this.setState({ startPickerFocus: true });
  }

  endPickerClick() {
    this.setState({ endPickerFocus: true });
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    const name = this.refs.closure_name.value;
    axios
      .patch(`/api/v1/closures/${this.props.closure.id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        closure: {
          name,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
        },
      })
      .then((res) => {
        M.toast({
          html: `${res.data.name} updated!`,
          displayLength: 3000,
          classes: "green",
        });
        this.props.refreshClosures();
        this.props.modalAction(false, "", {});
      })
      .catch((err) => console.error(err));
  }

  deleteClosure() {
    axios
      .delete(`/api/v1/closures/${this.props.closure.id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        M.toast({
          html: `Closure deleted`,
          displayLength: 3000,
          classes: "green",
        });
        this.props.refreshClosures();
        this.props.modalAction(false, "", {});
      })
      .catch((err) => console.error(err));
  }

  onEndDateChange(date) {
    if (
      !this.refs.closure_start_date.value
      || moment(date).isAfter(this.state.startDate)
      || moment(date).isSame(this.state.startDate)
    ) {
      this.setState({ endDate: date });
      this.refs.closure_end_date.value = moment(date).format("DD MMM, YY");
    } else {
      this.setState({ endDate: date, startDate: date });
      this.refs.closure_start_date.value = moment(date).format("DD MMM, YY");
      this.refs.closure_end_date.value = moment(date).format("DD MMM, YY");
    }
  }

  onEndFocusChange({ focused }) {
    this.setState({ endPickerFocus: focused });
  }

  onStartDateChange(date) {
    if (
      !this.refs.closure_end_date.value
      || moment(date).isBefore(this.state.endDate)
      || moment(date).isSame(this.state.endDate)
    ) {
      this.setState({ startDate: date });
      this.refs.closure_start_date.value = moment(date).format("DD MMM, YY");
    } else {
      this.setState({ startDate: date, endDate: date });
      this.refs.closure_start_date.value = moment(date).format("DD MMM, YY");
      this.refs.closure_end_date.value = moment(date).format("DD MMM, YY");
    }
  }

  onStartFocusChange({ focused }) {
    this.setState({ startPickerFocus: focused });
  }

  isDayBlocked(day) {
    return !!(moment(day).day() === 0 || moment(day).day() === 6);
  }

  render() {
    return (
      <div className="modal-content">
        <h4>Edit Closure</h4>
        <form
          className="new_closure"
          id="new_closure"
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <div className="input-field">
            <input
              required="required"
              type="text"
              defaultValue={this.state.name}
              name="closure[name]"
              id="closure_name"
              ref="closure_name"
            />
            <label htmlFor="closure_name" className="active">
              Name
            </label>
          </div>

          <div className="input-field">
            <input
              className="startPicker"
              required="required"
              type="text"
              defaultValue={moment(this.state.startDate).format("DD MMM, YY")}
              name="closure[start_date]"
              id="closure_start_date"
              ref="closure_start_date"
              onClick={this.startPickerClick}
            />
            <label htmlFor="closure_start_date" className="active">
              Start date
            </label>
          </div>

          <div className="input-field">
            <input
              className="endPicker"
              required="required"
              type="text"
              defaultValue={moment(this.state.endDate).format("DD MMM, YY")}
              name="closure[end_date]"
              id="closure_end_date"
              ref="closure_end_date"
              htmlFor="end_date_picker"
              onClick={this.endPickerClick}
            />
            <label htmlFor="closure_end_date" className="active">
              End date
            </label>
            <SingleDatePicker
              verticalHeight={568}
              date={this.state.endDate} // momentPropTypes.momentObj or null
              onDateChange={this.onEndDateChange} // PropTypes.func.isRequired
              focused={this.state.endPickerFocus} // PropTypes.bool
              onFocusChange={this.onEndFocusChange} // PropTypes.func.isRequired
              numberOfMonths={12}
              orientation="vertical"
              verticalSpacing={0}
              daySize={50}
              isOutsideRange={this.isDayBlocked}
              id="end_date_picker" // PropTypes.string.isRequired,
            />
            <SingleDatePicker
              verticalHeight={568}
              date={this.state.startDate} // momentPropTypes.momentObj or null
              onDateChange={this.onStartDateChange} // PropTypes.func.isRequired
              focused={this.state.startPickerFocus} // PropTypes.bool
              onFocusChange={this.onStartFocusChange} // PropTypes.func.isRequired
              numberOfMonths={12}
              orientation="vertical"
              verticalSpacing={0}
              daySize={50}
              isOutsideRange={this.isDayBlocked}
              id="start_date_picker" // PropTypes.string.isRequired,
            />
          </div>
          <div className="button-row center">
            <input
              type="submit"
              name="commit"
              value="Update Closure"
              className="common-button-submit white-text closure-button"
              id="edit_closure_button"
              data-disable-with="Update Closure"
            />
          </div>
          <div className="button-row row center">
            <div
              className="btn white dark-gray-text closure-button"
              id="delete_closure_button"
              onClick={this.deleteClosure}
            >
              Delete Closure
            </div>
          </div>
        </form>
      </div>
    );
  }
}
export default EditClosure;
