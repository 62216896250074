import React from "react";
import styled from "styled-components";

const ActivityCard = ({ activity, currentUser }) => {
  return (
    <StyledActivityCard key={activity.id} aria-label="activity card">
      <ActivityInfo>
        {activity.client_name} -{" "}
        <span style={{ fontStyle: "italic" }}>{activity.activity_type}</span>
      </ActivityInfo>
      <UserInfo>
        <UserImage
          aria-hidden
          alt={`A very fancy pic of ${activity.user_name}`}
          src={activity.user_image}
        />
        <UserInfoText>
          {activity.user_name}
          <br />
          <UserCreatedAt>{activity.created_at}</UserCreatedAt>
        </UserInfoText>
      </UserInfo>

      {!currentUser.role_id >= 4 ||
      activity.access_ids.includes(currentUser.id) ? (
        <ProjectLink
          href={`/projects/${activity.project_id}`}
          aria-label={`${activity.project_name} project`}
        >
          {activity.project_name}{" "}
          <span style={{ color: "black" }}>project.</span>
        </ProjectLink>
      ) : (
        <ProjectText>{activity.project_name} project.</ProjectText>
      )}
    </StyledActivityCard>
  );
};

const StyledActivityCard = styled.div`
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #d7d7d7;
`;

const ActivityInfo = styled.p`
  font-family: Arial;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  margin: 0px 0px 16px;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const UserImage = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 16px;
`;

const UserInfoText = styled.p`
  margin: 0px;
  font-family: Manrope;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
`;
const UserCreatedAt = styled.span`
  font-weight: 400;
  font-family: Arial;
`;
const ProjectLink = styled.a`
  font-family: Arial;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 16px;
  color: #519acc;
`;
const ProjectText = styled.span`
  font-family: Arial;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 16px;
`;

export default ActivityCard;
