import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import FilterPillbox from "./FilterPillbox";

export default function FiltersControls(props) {
  const { filtersLists, clearFilter } = props;

  const activeFilters = useMemo(() => {
    const getActiveSelections = (filterCategory) => {
      const { list, selectedIds } = filterCategory;
      if (!selectedIds || selectedIds.length === 0) {
        return null;
      }

      const active = [];
      selectedIds.forEach((id) => {
        const selectedValue = list.find((item) => item.id === id);
        if (selectedValue) {
          active.push({ id: selectedValue.id, name: selectedValue.name });
        }
      });

      if (active.length === 0) {
        return null;
      }
      return active;
    };

    const activeCategories = [];

    Object.values(filtersLists).forEach((filterCategory) => {
      const activeSelections = getActiveSelections(filterCategory);
      if (activeSelections) {
        const minimalCategoryList = filterCategory.list.map((item) => ({
          id: item.id,
          name: item.name,
        }));
        activeCategories.push({
          category: {
            key: filterCategory.key,
            label: filterCategory.label,
            list: minimalCategoryList,
            selectedIds: filterCategory.selectedIds,
          },
          activeSelections,
        });
      }
    });

    return activeCategories;
  }, [filtersLists]);

  if (!activeFilters || activeFilters.length === 0) {
    return null;
  }

  return (
    <FiltersContainer
      $isEmpty={activeFilters.length === 0}
      id="filtersContainer"
    >
      {activeFilters.map((activeFilter) => {
        return (
          <FilterPillbox
            key={activeFilter.category.key}
            filterKey={activeFilter.category.key}
            label={activeFilter.category.label}
            selectedValues={activeFilter.activeSelections}
            clearFilter={clearFilter}
          />
        );
      })}
    </FiltersContainer>
  );
}

const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 8px;
  margin-top: ${({ isEmpty }) => (isEmpty ? "0px" : "8px")};
`;

const filterListData = PropTypes.shape({
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
});

FiltersControls.propTypes = {
  filtersLists: PropTypes.shape({
    clients: filterListData,
    projects: filterListData,
    statuses: filterListData,
    filters: filterListData,
    teams: filterListData,
  }).isRequired,
  clearFilter: PropTypes.func.isRequired,
};
