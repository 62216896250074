import React from "react";
import styled from "styled-components";

const UserCard = ({
  userName,
  userPath,
  userAvatar,
  userFullTime,
  userContractor,
  userRole,
  userRoleColor,
}) => {
  let imageId;
  if (userPath.toString().length === 1) {
    imageId = `00${userPath.toString()}`;
  } else if (userPath.toString().length === 2) {
    imageId = `0${userPath.toString()}`;
  } else {
    imageId = userPath.toString();
  }
  let imagePath = `/system/users/avatars/000/000/${imageId}/thumb/${userAvatar}`;
  if (userAvatar === null) {
    imagePath =
      "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/syncit/missing.png";
  }

  return (
    <UserLink href={`/users/${userPath}`}>
      <Card>
        <UserImage src={imagePath} alt="User Avatar" />
        <UserInfo>
          <UserName>{userName}</UserName>
          <UserLabels>
            <Label
              bgColor={
                userContractor
                  ? "#F55454"
                  : userFullTime
                  ? "#578BC2"
                  : "#D76F23"
              }
            >
              {userContractor
                ? "Contractor"
                : userFullTime
                ? "Full-Time"
                : "Part-Time"}
            </Label>
            <Label bgColor={userRoleColor} marginLeft="4px">
              {userRole}
            </Label>
          </UserLabels>
        </UserInfo>
      </Card>
    </UserLink>
  );
};

const Card = styled.div`
  display: flex;
  gap: 16px;
  min-width: 250px;
  max-width: 250px;
  padding: 16px;
  align-items: center;
  gap: 16px;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  background: #fff;
  margin-right: 4px;
  margin-bottom: 4px;
  cursor: pointer;

  &:hover {
    border: 1px solid #519acc;
  }
`;

const UserLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
`;

const UserImage = styled.img`
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: url(${(props) => props.src}), lightgray 50% / cover no-repeat;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const UserName = styled.p`
  width: 166px;
  color: #242424;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 8px;
`;

const UserLabels = styled.div`
  display: flex;
  gap: 2px;
`;

const Label = styled.div`
  color: white;
  display: flex;
  height: 23px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: ${(props) => props.bgColor};
  font-family: "Open Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  margin-left: ${(props) => props.marginLeft || 0};
`;

export default UserCard;
