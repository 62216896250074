import React from "react";
import styled from "styled-components";
import RoundDropdown from "../projects/round_dropdown";
import TimeObjectSelector from "./time_object_selector";

const NavHeading = styled.div`
  width: 100%;
  padding: 20px;
  border-bottom: solid 1px #b9b9b9;
  display: flex;
  align-items: center;
  position: relative;
`;
const DateCard = styled.div.attrs((props) => ({
  selectedDay: props.selectedDay,
}))`
  width: 20%;
  height: 100px;
  text-align: center;
  background-color: ${(props) => (props.selectedDay ? "#e0e0e0" : "white")};
  border-bottom: solid 4px
    ${(props) => (props.selectedDay ? "#519acc" : "white")};
  font-family: Manrope;
  span {
    color: #519acc;
  }
`;

const ActiveObjectCard = styled.div`
  flex: 1 1 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
  transition: max-height 0.5s;
  margin-left: 30px;
  position: relative;
  & .div-btn-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    background: transparent;
    border: none;
    padding: 0;
    top: 0px;
    left: 0px;
    z-index: 3;
  }
  label {
    font-family: "Manrope", sans-serif;
    font-size: 16px;
    color: #313131;
    font-weight: 700;
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden !important;
    width: 245px;
    display: inline-block;
    transform: none;
  }
  h4 {
    margin-right: 15px;
  }
  p {
    margin-top: 0px;
    color: #313131;
    margin-bottom: 0px;
  }
  .tag {
    min-width: 68px;
    padding: 5px 8px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    text-align: center;
    border-radius: 10px;
    font-size: 12px;
    display: inline-block;
    margin-right: 5px;
    color: white;
  }
  .tag-group {
    position: absolute;
    bottom: 15px;
    width: 220px;
    overflow: hidden;
    height: 28px;
  }
  .tag-plus {
    position: absolute;
    right: 15px;
    bottom: 18px;
    color: #2bb882;
  }
`;

class TimeContainerSubnav extends React.Component {
  constructor(props) {
    super(props);
    // this.today = moment().format("YYYY-MM-DD")
  }

  handlerFunc() {
    console.log("funk");
  }

  getOptions() {
    let options = [];
    switch (this.props.objectName) {
      case "project":
        options = [
          { name: "Clients", handler: this.handlerFunc },
          { name: "Projects", handler: this.handlerFunc },
          { name: "Tasks", handler: this.handlerFunc },
          { name: "Users", handler: this.handlerFunc },
        ];
        break;
      case "client":
        options = [
          { name: "Clients", handler: this.handlerFunc },
          { name: "Projects", handler: this.handlerFunc },
          { name: "Tasks", handler: this.handlerFunc },
          { name: "Users", handler: this.handlerFunc },
        ];
        if (this.props.nested) {
          options.splice(0, 1);
        }
        break;
      case "task":
        options = [
          { name: "Clients", handler: this.handlerFunc },
          { name: "Projects", handler: this.handlerFunc },
          { name: "Tasks", handler: this.handlerFunc },
          { name: "Users", handler: this.handlerFunc },
        ];
        if (this.props.activeObjectName === "project" && !this.props.nested) {
          options.splice(0, 2);
        }
        if (this.props.nested && this.props.activeObjectName === "project") {
          options.splice(0, 2);
        }
        break;
      case "user":
        options = [
          { name: "Clients", handler: this.handlerFunc },
          { name: "Projects", handler: this.handlerFunc },
          { name: "Tasks", handler: this.handlerFunc },
          { name: "Users", handler: this.handlerFunc },
        ];
        if (
          this.props.nested &&
          this.props.activeObjectName === "project" &&
          this.props.nestedObject.name != "clients"
        ) {
          options.splice(0, 2);
        }
        if (
          this.props.nested &&
          this.props.activeObjectName === "project" &&
          this.props.nestedObject.name === "clients"
        ) {
          options.splice(0, 1);
        }
        break;
    }
    if (this.props.activeObjectName === "client") {
      options.splice(0, 1);
    }
    return options;
  }

  render() {
    const { activeObject } = this.props;
    const chars = [].concat.apply([], Array(26)).map((_, i) => {
      return String.fromCharCode(i + 65);
    });
    chars.unshift("#");
    const { usedLetters } = this.props;
    let usedLetterIndex = 0;
    const searchUrl = new URL(window.location.href);
    if (
      searchUrl.searchParams.has("start_date") &&
      searchUrl.searchParams.has("end_date")
    ) {
      var modifier = searchUrl.search;
    }
    return (
      <div className="flex-container flex-al-center flex-wrap">
        {!(this.props.objectName === "user" && this.props.show) && (
          <TimeObjectSelector
            activeObjectName={this.props.activeObjectName}
            objectName={
              this.props.userProjects ? "user" : this.props.objectName
            }
            nested={this.props.nested}
            nestedObject={this.props.nestedObject}
            options={this.getOptions()}
          />
        )}
        {this.props.usedLetters &&
          this.props.hideLetters === false &&
          this.props.objectName != "task" &&
          !(this.props.objectName === "user" && this.props.show) && (
            <ul
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-end",
                marginRight: "15px",
              }}
            >
              {chars.map((link, index) => {
                if (usedLetters && usedLetters.includes(link)) {
                  usedLetterIndex += 1;
                }
                return (
                  <li
                    className={
                      usedLetters.includes(link) ? "letters l-hover" : "letters"
                    }
                    style={{
                      color: usedLetters.includes(link)
                        ? "#313131"
                        : "lightgray",
                      padding: "0 5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                    key={index}
                    value={index}
                  >
                    <button
                      disabled={!usedLetters.includes(link)}
                      className="div-btn-overlay"
                      onClick={this.props.letterClickHandler}
                      value={link}
                      data-index={usedLetterIndex}
                    />
                    {link}
                  </li>
                );
              })}
            </ul>
          )}
        {this.props.activeObjectName &&
          this.props.activeObjectName === "task" && (
            <div
              style={{
                display: "flex",
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "auto",
                marginRight: "30px",
                height: "50px",
                width: "200px",
              }}
            >
              <div className="flex-al-center flex-container">
                <div
                  style={{ backgroundColor: "#5ab486" }}
                  className="swatch"
                />
                <p>Billable</p>
              </div>
              <div className="flex-al-center flex-container">
                <div
                  style={{ backgroundColor: "#6caada" }}
                  className="swatch"
                />
                <p>Non-Billable</p>
              </div>
            </div>
          )}
        {((this.props.activeObjectName === "client" &&
          activeObject &&
          this.props.nestedObject &&
          this.props.nestedObject.name != "clients" &&
          this.props.objectName != "user") ||
          (this.props.nestedObject &&
            this.props.nestedObject.name === "clients" &&
            this.props.objectName === "project") ||
          (this.props.activeObjectName === "project" &&
            this.props.nested &&
            this.props.nestedObject &&
            this.props.nestedObject.name != "projects" &&
            this.props.activeObjectName === "project" &&
            this.props.nestedObject.name != "users")) && (
          <a
            style={{
              flex: "1 1 100%",
              padding: "20px",
              fontFamily: "manrope",
              fontWeight: "600",
            }}
            className="flex-container flex-al-center base-color"
            href={
              modifier
                ? `/time_tracking/clients${modifier}`
                : "/time_tracking/clients"
            }
          >
            <RoundDropdown
              rotate="rotate(90deg)"
              height={15}
              width={15}
              svgStyles={{ marginRight: "5px" }}
            />
            Back to All Clients
          </a>
        )}
        {this.props.activeObjectName === "task" && activeObject && (
          <a
            style={{
              flex: "1 1 100%",
              padding: "20px",
              backgroundColor: "transparent",
              fontFamily: "manrope",
              fontWeight: "600",
            }}
            className="flex-container flex-al-center base-color"
            href={
              modifier
                ? `/time_tracking/tasks${modifier}`
                : "/time_tracking/tasks"
            }
          >
            <RoundDropdown
              rotate="rotate(90deg)"
              height={15}
              width={15}
              svgStyles={{ marginRight: "5px" }}
            />
            Back to All Tasks
          </a>
        )}
        {this.props.activeObjectName === "project" && activeObject && (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {this.props.cameFromClients === true ? (
              <div
                style={{ paddingRight: "20px", flex: "1 1 100%" }}
                className="flex-container flex-space-between"
              >
                <a
                  style={{
                    flex: "1 1 100%",
                    padding: "20px",
                    fontFamily: "manrope",
                    fontWeight: "600",
                  }}
                  className="flex-container flex-al-center base-color"
                  href={
                    modifier
                      ? `/time_tracking/clients/${
                          activeObject.client_id
                            ? activeObject.client_id
                            : activeObject.project.client_id
                        }${modifier}`
                      : `/time_tracking/clients/${
                          activeObject.client_id
                            ? activeObject.client_id
                            : activeObject.project.client_id
                        }`
                  }
                >
                  <RoundDropdown
                    rotate="rotate(90deg)"
                    height={15}
                    width={15}
                    svgStyles={{ marginRight: "5px" }}
                  />
                  Back to {activeObject.client_name} projects
                </a>
              </div>
            ) : (
              <div
                style={{ paddingRight: "20px", flex: "1 1 100%" }}
                className="flex-container flex-space-between"
              >
                <a
                  style={{
                    flex: "1 1 100%",
                    padding: "20px",
                    fontFamily: "manrope",
                    fontWeight: "600",
                  }}
                  className="flex-container flex-al-center base-color"
                  href={
                    modifier
                      ? `/time_tracking/projects/${modifier}`
                      : `/time_tracking/projects`
                  }
                >
                  <RoundDropdown
                    rotate="rotate(90deg)"
                    height={15}
                    width={15}
                    svgStyles={{ marginRight: "5px" }}
                  />
                  Back to all projects
                </a>
              </div>
            )}
          </>
        )}
        {this.props.nestedObject &&
          this.props.nestedObject.name === "clients" &&
          this.props.objectName != "project" && (
            <div
              style={{ paddingRight: "20px", flex: "1 1 100%" }}
              className="flex-container flex-space-between"
            >
              <a
                style={{
                  flex: "1 1 100%",
                  padding: "20px",
                  fontFamily: "manrope",
                  fontWeight: "600",
                }}
                className="flex-container flex-al-center base-color"
                href={
                  modifier
                    ? `/time_tracking/clients/${activeObject.id}${modifier}`
                    : `/time_tracking/clients/${activeObject.id}`
                }
              >
                <RoundDropdown
                  rotate="rotate(90deg)"
                  height={15}
                  width={15}
                  svgStyles={{ marginRight: "5px" }}
                />
                Back to {activeObject.name} projects
              </a>
            </div>
          )}
        {this.props.activeObject &&
          (this.props.objectName === "user" && !this.props.nested ? (
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between",
                position: "relative",
                width: "100%",
                height: "94px",
                marginTop: "25px",
                padding: "0px 35px",
              }}
            >
              <a
                href={
                  modifier
                    ? `/time_tracking/users${modifier}`
                    : "/time_tracking/users"
                }
                style={{
                  display: "block",
                  fontFamily: "Manrope",
                  position: "absolute",
                  top: "-4px",
                  left: "30px",
                  color: "#519acc",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                <RoundDropdown
                  rotate="rotate(90deg)"
                  height={15}
                  width={15}
                  svgStyles={{
                    marginRight: "5px",
                    position: "relative",
                    top: "3px",
                  }}
                />
                Back to Users
              </a>
              <div>
                <img
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                    display: "inline-block",
                    verticalAlign: "bottom",
                  }}
                  src={this.props.activeObject.avatar_url}
                />
                <p
                  style={{
                    display: "inline-block",
                    margin: "0px 0px 6px 20px",
                    fontSize: "13px",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Manrope",
                      fontSize: "21px",
                      fontWeight: "600",
                    }}
                  >
                    {this.props.activeObject.name}
                  </span>
                  <br />
                  {this.props.activeObject.email}
                </p>
              </div>
              <a
                href={`/users/${this.props.activeObject.id}`}
                target="_blank"
                rel="noreferrer"
                className="link-btn"
                style={{
                  display: "block",
                  lineHeight: "45px",
                  color: "#313131",
                  textAlign: "center",
                  fontSize: "13px",
                  width: "100px",
                  height: "46px",
                  border: "solid 1px lightgray",
                  backgroundColor: "#fbfbfb",
                  borderRadius: "6px",
                  marginBottom: "12px",
                }}
              >
                Edit Profile
              </a>
            </div>
          ) : (
            (this.props.objectName != "task" ||
              (this.props.objectName === "task" && this.props.nested)) && (
              <ActiveObjectCard>
                {this.props.activeObjectName === "project" && activeObject ? (
                  <h4>
                    {activeObject.client_name} -{activeObject.name}
                  </h4>
                ) : (
                  <h4>{activeObject.name}</h4>
                )}
                {activeObject.tags && activeObject.tags.length >= 1 && (
                  <div
                    aria-label="Project tags used by this client"
                    id={`project-tag-group-${activeObject.id}`}
                  >
                    {activeObject.tags.map((t, index) => {
                      return (
                        <span
                          className="tag"
                          key={`${t.id}-${index}`}
                          style={{ backgroundColor: t.color }}
                        >
                          {t.name}
                        </span>
                      );
                    })}
                  </div>
                )}
                {activeObject.tag && (
                  <div id={`project-tag-group-${activeObject.id}`}>
                    <span
                      className="tag"
                      style={{ backgroundColor: activeObject.tag.color }}
                      role="text"
                      aria-label={`Project tag ${activeObject.tag.name}`}
                    >
                      {activeObject.tag.name}
                    </span>
                  </div>
                )}
                <div
                  className="tag-plus"
                  id={`project-tag-plus-${activeObject.id}`}
                />
              </ActiveObjectCard>
            )
          ))}
      </div>
    );
  }
}

export default TimeContainerSubnav;
