import React from "react";
import { Button, Modal } from "react-materialize";
import TimeRow from "./TimeRow";
import RoomRow from "./RoomRow";

const cellIds = [
  "8",
  "815",
  "830",
  "845",
  "9",
  "915",
  "930",
  "945",
  "10",
  "1015",
  "1030",
  "1045",
  "11",
  "1115",
  "1130",
  "1145",
  "12",
  "1215",
  "1230",
  "1245",
  "13",
  "1315",
  "1330",
  "1345",
  "14",
  "1415",
  "1430",
  "1445",
  "15",
  "1515",
  "1530",
  "1545",
  "16",
  "1615",
  "1630",
  "1645",
  "17",
  "1715",
  "1730",
  "1745",
  "18",
];

class TableOfRooms extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.closeModal = this.closeModal.bind(this);
    this.setStartEndTimes = this.setStartEndTimes.bind(this);
  }

  closeModal() {
    document.body.style.overflow = "";
    this.child.current.goBack();
  }

  setStartEndTimes() {
    this.child.current.setStartEndTimes();
  }

  render() {
    const trigger = (
      <Button className="gimmeSomeSpaceMan common-button-submit">
        Find Me a Room!
      </Button>
    );
    const roomContainer = [];
    for (let index = 0; index < this.props.rooms.length; index++) {
      const room = this.props.rooms[index];
      roomContainer.push(
        <RoomRow
          currentUser={this.props.currentUser}
          users={this.props.users}
          cellIds={cellIds}
          room={room}
          deleteHandler={this.props.deleteHandler}
          editHandler={this.props.editHandler}
          bookingHandler={this.props.bookingHandler}
          roomName={room.name}
          rooms={this.props.rooms}
          bookings={this.props.bookings[index]}
          currentDay={this.props.currentDay}
          setModal={this.props.setModal}
          id={room.id}
          key={index}
        />
      );
    }
    return (
      <div style={{ position: "relative", zIndex: "2" }}>
        <div className="room-grid-outline">
          <TimeRow />
          {roomContainer}
        </div>
      </div>
    );
  }
}

export default TableOfRooms;
