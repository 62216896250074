import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Swal from "sweetalert2";
import axios from "axios";
import ms from "components/modals/ModalStyled";
import { taskboardProjectTypes, userTypes } from "../../../types";
import TransitionContactRow from "./transition_contact_row";
import SingleSelectWrapper from "../../common/single_select_wrapper";
import DropperContainer from "../../common/dropper_container";
import RateInput from "../../inputs/rateInput";

const PAYMENT_TERMS_OPTIONS = [
  { value: 30, label: "30 days" },
  { value: 45, label: "45 days" },
  { value: 60, label: "60 days" },
];

const CompleteTransition = (props) => {
  const {
    project,
    currentUser,
    submitHandler,
    cancelHandler,
    setBackdropLoader,
  } = props;
  const [hours, setHours] = useState("0.00");
  const [editingContact, setEditingContact] = useState(false);
  const [monthly, setMonthly] = useState(project.monthly_invoice);
  const [rate, setRate] = useState(() => {
    let initialRate = 7000;
    if (project.rate) {
      initialRate =
        project.rate.toString().length >= 4 ? project.rate / 100 : 7000;
    }
    return initialRate;
  });
  const [invoiceContacts, setInvoiceContacts] = useState(
    project.contacts ? project.contacts.filter((c) => c.invoicing) : []
  );
  const [projectContacts, setProjectContacts] = useState(
    project.contacts ? project.contacts.filter((c) => !c.invoicing) : []
  );
  const [notes, setNotes] = useState(
    project.invoice_notes ? project.invoice_notes : undefined
  );
  const [poNumber, setPoNumber] = useState(
    project.po_number ? project.po_number : undefined
  );
  const [paymentTerms, setPaymentTerms] = useState(
    () =>
      PAYMENT_TERMS_OPTIONS.find(
        (o) => o.value === project.invoice_payment_terms
      ) || undefined
  );

  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const moneyFormatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    style: "currency",
    currency: "USD",
  });

  const handleRateChange = useCallback((e) => {
    setRate(e.target.value);
  }, []);

  const links = project.project_links
    ? project.project_links.filter((l) => l.link_type === "estimate")
    : [];
  const estimate = links.length > 0 ? links[0].location : null;

  const submit = (sendEmail) => {
    const errors = [];
    const projectUpdate = {
      new_invoice: {},
      project_status_attributes: {
        aasm_state: "readying",
      },
    };
    if (poNumber) {
      projectUpdate.po_number = poNumber;
      projectUpdate.new_invoice.po_number = poNumber;
    }
    if (sendEmail) {
      projectUpdate.new_invoice.send_email = sendEmail;
    }
    if (rate) {
      projectUpdate.rate = rate * 100;
    } else {
      errors.push("Please provide a project rate");
    }
    if (notes) {
      projectUpdate.invoice_notes = notes;
      projectUpdate.new_invoice.notes = notes;
    }
    projectUpdate.archive_after_billing = false;

    if (hours) {
      projectUpdate.new_invoice.tracked_hours = parseFloat(hours);
    }

    if (paymentTerms) {
      projectUpdate.invoice_payment_terms = paymentTerms.value;
    } else {
      errors.push("Please provide invoice payment terms");
    }
    projectUpdate.monthly_invoice = monthly;

    if (errors.length === 0) {
      submitHandler(projectUpdate);
    } else {
      // eslint-disable-next-line no-undef
      M.toast({
        html: errors.join(", "),
        classes: "danger",
      });
    }
  };

  const preSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Send Invoice Email",
      text: "Would you like to send accounting an email of this invoice request?",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "No",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        submit(true);
      } else if (result.dismiss && result.dismiss === "cancel") {
        submit(false);
      }
    });
  };

  const onPaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("Text");
    const num = parseFloat(pastedData);
    if (num && num < 2000) {
      setHours(num.toFixed(2));
    }
  };

  const handleCheckBoxes = () => {
    setMonthly(!monthly);
  };

  const getContacts = () => {
    setBackdropLoader(true);
    axios
      .get(`projects/${project.id}/contacts.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const invoicingContacts = res.data.filter((x) => x.invoicing);
        const nextProjectContacts = res.data.filter((x) => !x.invoicing);
        setInvoiceContacts(invoicingContacts);
        setProjectContacts(nextProjectContacts);
        setBackdropLoader(false);
      })
      .catch(() => {
        setBackdropLoader(false);
      });
  };

  const editContactHandler = (id, name, email) => {
    setBackdropLoader(true);
    axios
      .patch(`/contacts/${id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        contact: { name, email },
      })
      .then((res) => {
        const nextContacts = res.data.invoicing
          ? invoiceContacts
          : projectContacts;
        const index = nextContacts.map((c) => c.id).indexOf(res.data.id);

        if (index !== -1) {
          const contactUpdateFunc = res.data.invoicing
            ? setInvoiceContacts
            : setProjectContacts;
          nextContacts.splice(index, 1, res.data);
          contactUpdateFunc(nextContacts);
        }
        setEditingContact(false);
        setBackdropLoader(false);
        // eslint-disable-next-line no-undef
        M.toast({
          html: `Contact updated`,
          displayLength: 3000,
          classes: "green",
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        M.toast({ html: err.response.data, classes: "red" });
        setBackdropLoader(false);
      });
  };

  const addContactHandler = (type, name, email) => {
    setBackdropLoader(true);
    axios
      .post(`/contacts.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        contact: {
          name,
          email,
          user_id: currentUser.id,
          project_id: project.id,
          invoicing: type === "invoicing",
        },
      })
      .then((res) => {
        if (res.data.invoicing) {
          setInvoiceContacts([
            ...invoiceContacts.filter((c) => !c.newContact),
            ...[res.data],
          ]);
        } else {
          setProjectContacts([
            ...projectContacts.filter((c) => !c.newContact),
            ...[res.data],
          ]);
        }
        setEditingContact(false);
        // eslint-disable-next-line no-undef
        M.toast({
          html: `Contact created`,
          displayLength: 3000,
          classes: "green",
        });
      })
      .catch(() => {
        setBackdropLoader(false);
      });
  };

  const deleteContactHandler = (id, type) => {
    Swal.fire({
      title: `Delete Contact`,
      text: `Are you sure that you want to delete this contact?`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        setBackdropLoader(true);
        axios
          .delete(`/contacts/${id}.json`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then(() => {
            if (type === "invoicing") {
              setInvoiceContacts(invoiceContacts.filter((c) => c.id !== id));
            } else {
              setProjectContacts(projectContacts.filter((c) => c.id !== id));
            }
            setEditingContact(false);
            // eslint-disable-next-line no-undef
            M.toast({
              html: `Contact Deleted`,
              displayLength: 3000,
              classes: "green",
            });
          })
          .catch(() => {
            setBackdropLoader(false);
          });
      }
    });
  };

  const handleHoursChange = (e) => {
    const input = e.target.value;
    formatter.format(input);
    setHours(input);
  };

  const handlePoNumberChange = (e) => {
    setPoNumber(e.target.value ? e.target.value : "");
  };

  const addInvoiceContact = (e) => {
    e.preventDefault();
    setInvoiceContacts([
      ...invoiceContacts,
      ...[{ newContact: true, invoicing: true, id: "new-contact" }],
    ]);
    setEditingContact(true);
  };

  const removeNewInvoiceContact = () => {
    setInvoiceContacts(invoiceContacts.filter((c) => !c.newContact));
    setEditingContact(false);
  };

  const handleNotes = (e) => {
    setNotes(e.target.value);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    cancelHandler();
  };

  useEffect(() => {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  }, []);

  useEffect(() => {
    setBackdropLoader(false);
  }, [invoiceContacts, projectContacts]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getContacts();
  }, [project.contacts]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <EstimateCard>
      <ms.Form
        encType="multipart/formdata"
        onSubmit={preSubmit}
        className="flex-container flex-column"
      >
        <InvoiceDetailsContainer>
          <InvoiceDetailsInputs>
            <ms.FormRowDouble>
              <ms.FormItemDouble>
                <TaskboardFormBox>
                  <ms.Label className="custom-labels">
                    Total Project Hours
                    <span className="base-color">*</span>
                  </ms.Label>
                  <FormInput
                    className="browser-default text-input-v2"
                    name="hours"
                    type="number"
                    value={hours}
                    onPaste={onPaste}
                    onChange={handleHoursChange}
                    min={0}
                    max={1000000}
                    step={0.25}
                  />
                </TaskboardFormBox>
              </ms.FormItemDouble>
              <ms.FormItemDouble>
                <TaskboardFormBox>
                  <ms.Label className="custom-labels">
                    Payment Terms
                    <span className="base-color">*</span>
                  </ms.Label>
                  <FullWidthSingleSelectWrapper
                    value={paymentTerms}
                    onChange={(option) => {
                      setPaymentTerms(option);
                    }}
                    options={PAYMENT_TERMS_OPTIONS}
                    name="Payment Terms"
                    customStyles={{
                      container: (styles) => ({
                        ...styles,
                        width: "100%",
                      }),

                      control: (styles) => ({
                        ...styles,
                        height: "39px",
                        width: "100%",
                      }),
                    }}
                  />
                </TaskboardFormBox>
              </ms.FormItemDouble>
            </ms.FormRowDouble>
            <ms.FormRowDouble>
              <ms.FormItemFixedWidth $width="75%">
                <TaskboardFormBox>
                  <ms.Label className="custom-labels">PO Number</ms.Label>
                  <FormInput
                    className="browser-default text-input-v2"
                    name="po number"
                    type="text"
                    value={poNumber}
                    autoComplete="off"
                    style={{
                      fontFamily: "Arial",
                      fontSize: "15px",
                      marginTop: "0",
                      marginBottom: "0",
                    }}
                    onChange={handlePoNumberChange}
                    maxLength={25}
                  />
                </TaskboardFormBox>
              </ms.FormItemFixedWidth>
              <ms.FormItemFixedWidth $width="calc(25% - 16px)">
                <TaskboardFormBox>
                  <RateInput
                    rate={rate}
                    handleRateChange={handleRateChange}
                    labelClass="custom-labels"
                    customStyles={{
                      input: {
                        margin: 0,
                        height: "39px",
                        width: "100%",
                        paddingLeft: "8px",
                      },
                      label: { marginBottom: "8px" },
                    }}
                  />
                </TaskboardFormBox>
              </ms.FormItemFixedWidth>
            </ms.FormRowDouble>
          </InvoiceDetailsInputs>
          <InvoiceTotal>
            <MoneyBox>
              <p>Calculated Invoice Total:</p>
              <p>{moneyFormatter.format(rate * hours)}</p>
            </MoneyBox>
          </InvoiceTotal>
        </InvoiceDetailsContainer>
        <div
          style={{
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "flex-end",
            marginTop: "0px",
          }}
          className="flex-row flex-space-between flex-container"
        >
          <FormRowDropper>
            <DropperContainer title="Invoicing Contact(s)">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  margin: "8px 0",
                }}
              >
                <AddInvoiceContactButton
                  type="button"
                  onClick={addInvoiceContact}
                  disabled={editingContact}
                  id="add-invoice-contact"
                  className="common-button-submit"
                >
                  Add Contact
                </AddInvoiceContactButton>
              </div>
              <ms.FormRow>
                <div style={{ padding: "0px 16px" }}>
                  {invoiceContacts.map((c) => {
                    return (
                      <TransitionContactRow
                        key={c.id}
                        contact={c}
                        type="invoicing"
                        deleteContactHandler={deleteContactHandler}
                        addContactHandler={addContactHandler}
                        editContactHandler={editContactHandler}
                        setEditingContact={setEditingContact}
                        editingContact={editingContact}
                        removeNewInvoiceContact={removeNewInvoiceContact}
                      />
                    );
                  })}
                </div>
              </ms.FormRow>
            </DropperContainer>
          </FormRowDropper>
          <ms.FormRow>
            <ms.FormItem>
              <ms.Label className="custom-labels">Invoice Notes</ms.Label>
              <textarea
                className="browser-default text-input-v2"
                name="notes"
                type="text"
                value={notes}
                onChange={handleNotes}
                maxLength={2000}
                style={{
                  padding: "5px 10px",
                  minHeight: "100px",
                }}
              />
            </ms.FormItem>
          </ms.FormRow>
          <ms.FormRow>
            <InvoiceFormItem>
              <input
                name="monthly_invoice"
                className="filled-in"
                checked={monthly}
                type="checkbox"
              />
              <span onClick={handleCheckBoxes} className="invoice-checkbox">
                Invoiced Monthly
              </span>
            </InvoiceFormItem>
          </ms.FormRow>
          <FormRowSubmit>
            <EstimateContainer>
              {estimate && (
                <a
                  href={
                    estimate.includes("http") ? estimate : `https://${estimate}`
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="btn-flat"
                >
                  View Estimate
                </a>
              )}
            </EstimateContainer>
            <ms.ControlsRow>
              <button
                type="button"
                onClick={handleCancel}
                className="common-button-cancel"
              >
                Cancel
              </button>
              <button
                disabled={hours < 0.25 || editingContact}
                type="submit"
                className="common-button-submit"
              >
                Submit
              </button>
            </ms.ControlsRow>
          </FormRowSubmit>
        </div>
      </ms.Form>
    </EstimateCard>
  );
};

const MoneyBox = styled.div`
  background-color: #e6e6e6;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  height: 122px;
  width: 150px;
  padding: 16px 8px;

  p {
    font-family: Manrope;
    text-align: center;
    font-weight: 600;
  }

  p:nth-child(1) {
    font-size: 13px;
    margin: 8px 0 0px 0;
  }

  p:nth-child(2) {
    font-size: 18px;
    margin: 8px 0 0 0;
  }
`;

const EstimateCard = styled.div``;

const InvoiceDetailsContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: stretch;
`;

const InvoiceDetailsInputs = styled.div`
  width: calc(100% - 116px);
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  align-self: stretch;
`;

const InvoiceTotal = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: stretch;
  flex: 1;
  margin-bottom: auto;
  flex-grow: 1;
  // hardcoded value is the height + margin on the label in the sibling input box, to align the MoneyBox
  // with the top of the input on the left. if those values change, this should change too.
  padding-top: 29px;
`;

const TaskboardFormBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const FormInput = styled.input`
  width: 100%;
  height: 39px;
`;

const FullWidthSingleSelectWrapper = styled(SingleSelectWrapper)`
  width: 100%;
`;

const AddInvoiceContactButton = styled.button`
  width: 100%;
  margin: 32px 16px;
`;

// eslint-disable-next-line import/no-named-as-default-member
const InvoiceFormItem = styled(ms.FormItem)`
  span.invoice-checkbox {
    ${ms.h3Styles}
    display: inline-block;
    white-space: nowrap;
    height: 100%;
    width: 100%;
    padding-left: 30px;
    padding-bottom: 3px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 13px;
    line-height: 19px;
    max-width: 145px;
  }
`;

const FormRowDropper = styled.div`
  margin-bottom: 8px;
  width: 100%;
`;

const EstimateContainer = styled.div`
  margin-top: 16px;

  a {
    white-space: nowrap;
    font-size: 13px;
    font-weight: 400;
    font-family: Arial;
  }
`;

const FormRowSubmit = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const { func } = PropTypes;

CompleteTransition.propTypes = {
  cancelHandler: func,
  setBackdropLoader: func,
  submitHandler: func,
  currentUser: userTypes,
  project: taskboardProjectTypes,
};

export default CompleteTransition;
