import React from "react";

const ProjectMemberIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
    >
      <path
        d="M12.5 0.5C14.1576 0.5 15.7473 1.15848 16.9194 2.33058C18.0915 3.50268 18.75 5.0924 18.75 6.75C18.75 8.4076 18.0915 9.99732 16.9194 11.1694C15.7473 12.3415 14.1576 13 12.5 13C10.8424 13 9.25268 12.3415 8.08058 11.1694C6.90848 9.99732 6.25 8.4076 6.25 6.75C6.25 5.0924 6.90848 3.50268 8.08058 2.33058C9.25268 1.15848 10.8424 0.5 12.5 0.5ZM12.5 25.5C12.5 25.5 25 25.5 25 22.375C25 18.625 18.9062 14.5625 12.5 14.5625C6.09375 14.5625 0 18.625 0 22.375C0 25.5 12.5 25.5 12.5 25.5Z"
        fill="url(#paint0_linear_5129_5430)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5129_5430"
          x1="12.5"
          y1="0.5"
          x2="12.5"
          y2="25.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ProjectMemberIcon;
