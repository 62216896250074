import React, { useState, useCallback } from "react";
import styled from "styled-components";
import ms from "components/modals/ModalStyled";
import { components } from "react-select";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import SingleSelectWrapper from "components/common/single_select_wrapper";
import RoundDropdown from "../round_dropdown";
import CalendarIcon from "../../icons/calendar_icon";

const LeadCard = styled.div``;

const LeadTransition = (props) => {
  const { project, cancelHandler, submitHandler, leadOptions } = props;

  const [estimateOwner, setEstimateOwner] = useState(() => {
    const {
      estimate_owner_id,
      estimate_owner_name,
      lead_owner_id,
      lead_owner_name,
    } = project;
    if (estimate_owner_id) {
      return {
        label: estimate_owner_name,
        value: estimate_owner_id,
      };
    }

    if (lead_owner_id) {
      return {
        label: lead_owner_name,
        value: lead_owner_id,
      };
    }

    return undefined;
  });

  const [estDueDate, setEstDueDate] = useState(
    project.est_due_date ? moment(project.est_due_date) : undefined
  );
  const [dateFocus, setDateFocus] = useState(false);
  const [focusCounter, setFocusCounter] = useState(0);

  const handleLeadChange = useCallback((e) => {
    setEstimateOwner(e);
  }, []);

  const handleStartDateChange = useCallback((date) => {
    setEstDueDate(date);
  }, []);

  const handleDateFocus = useCallback(
    (focused) => {
      if (dateFocus && focused.focused && focusCounter === false) {
        setDateFocus(true);
        setFocusCounter(true);
      } else if (dateFocus && focused.focused && focusCounter) {
        setDateFocus(true);
        setFocusCounter(true);
      } else {
        setDateFocus(focused.focused);
        setFocusCounter(true);
      }
    },
    [dateFocus, focusCounter]
  );

  const submit = useCallback(
    (e) => {
      e.preventDefault();
      const errors = [];
      const formData = {};

      if (estDueDate) {
        formData.est_due_date = estDueDate;
      } else {
        errors.push("Please select an estimate due date");
      }

      if (estimateOwner) {
        formData.estimate_owner_id = estimateOwner.value;
        formData.lead_ids = [estimateOwner.value];
      } else {
        errors.push("Please select a estimate owner");
      }

      if (errors.length === 0) {
        submitHandler(formData, "leading", "est_requesting");
      } else {
        // eslint-disable-next-line no-undef
        M.toast({
          html: errors.join(", "),
          classes: "danger",
        });
      }
    },
    [estDueDate, estimateOwner, submitHandler]
  );

  const isDayBlocked = useCallback((day) => {
    return !!(moment(day).day() === 0 || moment(day).day() === 6);
  }, []);

  const roundDrop = (dropProps) => {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <components.DropdownIndicator {...dropProps}>
        <RoundDropdown />
      </components.DropdownIndicator>
    );
  };

  return (
    <LeadCard className="transition">
      <div>
        <ms.Form onSubmit={submit}>
          <ms.FormRowDouble>
            <ms.FormItemDouble $removeDatepickerNegMargin>
              <ms.Label className="more-padding">
                Estimate Due Date
                <span className="base-color">*</span>
              </ms.Label>
              <div
                style={{
                  position: "absolute",
                  left: "16px",
                  zIndex: 1,
                  top: "37px",
                }}
              >
                <CalendarIcon width="22px" height="22px" color="#519acc" />
              </div>
              <SingleDatePicker
                date={estDueDate}
                onDateChange={handleStartDateChange}
                focused={dateFocus}
                onFocusChange={handleDateFocus}
                numberOfMonths={1}
                placeholder="Select a date"
                orientation="horizontal"
                daySize={30}
                navPrev={
                  <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                    <RoundDropdown rotate="rotate(90deg)" />
                  </div>
                }
                navNext={
                  <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                    <RoundDropdown rotate="rotate(-90deg)" />
                  </div>
                }
                hideKeyboardShortcutsPanel
                inputIconPosition="after"
                customInputIcon={<RoundDropdown />}
                isOutsideRange={isDayBlocked}
                id="single_date_picker"
              />
            </ms.FormItemDouble>
            <ms.FormItemDouble>
              <ms.Label className="more-padding" htmlFor="filter_filters">
                Estimate Owner<span className="base-color">*</span>
              </ms.Label>
              <SingleSelectWrapper
                classNamePrefix="react-select"
                options={leadOptions}
                placeholder="Select a user"
                onChange={handleLeadChange}
                blurInputOnSelect
                components={{ DropdownIndicator: roundDrop }}
                value={estimateOwner}
                customStyles={{
                  container: (styles) => ({
                    ...styles,
                    width: "100%",
                  }),
                  control: (styles) => ({ ...styles, height: "39px" }),
                }}
              />
            </ms.FormItemDouble>
          </ms.FormRowDouble>
          <ms.ControlsRow>
            <button
              onClick={cancelHandler}
              className="common-button-cancel"
              type="button"
            >
              Cancel
            </button>
            <button className="common-button-submit" type="submit">
              Submit
            </button>
          </ms.ControlsRow>
        </ms.Form>
      </div>
    </LeadCard>
  );
};

export default LeadTransition;
