import React from "react";

function LeadTypeFilledIcon() {
  return (
    <svg
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="51"
        height="51"
        rx="25.5"
        fill="url(#paint0_linear_1845_3803)"
      />
      <path
        d="M35.2222 13.8003H29.4167C29.1323 12.9819 28.6026 12.2728 27.9009 11.7711C27.1993 11.2695 26.3603 11 25.5 11C24.6397 11 23.8007 11.2695 23.0991 11.7711C22.3974 12.2728 21.8677 12.9819 21.5833 13.8003H15.7778C15.0417 13.8025 14.3365 14.0982 13.816 14.6228C13.2956 15.1474 13.0022 15.8583 13 16.6002V36.2C13.0022 36.9419 13.2956 37.6528 13.816 38.1775C14.3365 38.7021 15.0417 38.9978 15.7778 39H35.2222C35.9583 38.9978 36.6635 38.7021 37.184 38.1775C37.7044 37.6528 37.9978 36.9419 38 36.2V16.6002C37.9978 15.8583 37.7044 15.1474 37.184 14.6228C36.6635 14.0982 35.9583 13.8025 35.2222 13.8003ZM25.5 13.8003C25.7747 13.8003 26.0432 13.8824 26.2716 14.0362C26.5 14.19 26.678 14.4087 26.7832 14.6645C26.8883 14.9203 26.9158 15.2018 26.8622 15.4734C26.8086 15.7449 26.6763 15.9944 26.4821 16.1902C26.2879 16.386 26.0404 16.5193 25.771 16.5733C25.5015 16.6273 25.2223 16.5996 24.9685 16.4937C24.7147 16.3877 24.4978 16.2083 24.3452 15.978C24.1926 15.7478 24.1111 15.4771 24.1111 15.2002C24.1122 14.8293 24.2589 14.4738 24.5191 14.2115C24.7793 13.9492 25.132 13.8014 25.5 13.8003ZM22.7222 20.8002L26.5972 24.7117L30.0972 21.1838L28.2778 19.4002H33.8333V25.0001L32.0611 23.1648L26.6 28.6737L22.7222 24.7621L19.1306 28.3797L17.1667 26.4001L22.7222 20.8002ZM35.2222 34.8H15.7778V32.0001H35.2222V34.8Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1845_3803"
          x1="25.5"
          y1="0"
          x2="25.5"
          y2="51"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
      </defs>
    </svg>
  );
}

LeadTypeFilledIcon.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "#519acc",
};

export default LeadTypeFilledIcon;
