import React from "react";
import styled from "styled-components";
import Select, { components } from "react-select";
import SingleSelectStyles from "../common/single_select_styles";
import CancelIcon from "../icons/cancel_icon";
import PlusIcon from "../icons/plus_icon";
import { CSSTransition } from "react-transition-group";

const MemberContainer = styled.div.attrs((props) => ({
  overflow: props.overflow,
}))`
  height: 40px;
  display: flex;
  width: 100%;

  > div:not(:last-child):after {
    content: attr(name);
    display: block;
    position: absolute;
    bottom: -25px;
    height: 20px;
    font-size: 13px;
    background-color: #519acc;
    color: white;
    transform: translateX(calc(-50% + 18px));
    opacity: 0;
    pointer-events: none;
    width: intrinsic;
    width: -moz-max-content;
    width: -webkit-max-content;
    // min-width: 100px;
    padding: 0px 10px;
    border-radius: 4px;
    transition: opacity 0.2s;
  }

  > div:not(:last-child):hover::after {
    opacity: 1;
  }

  ${(props) =>
    props.overflow
      ? ">div:first-child:not(:last-child):after{left: 100%;}"
      : ""}

  >div:hover {
    z-index: 2;
  }
`;

const UserCircle = styled.div`
  height: 30px;
  width: 30px;
  min-width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: #519acc;
  > .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #519acc;
    opacity: 0;
    background-color: #00000057;
    transition: opacity 0.3s;
    pointer-events: none;
    z-index: 1;
  }

  :hover {
    z-index: 2;
  }

  :hover > .overlay {
    opacity: 1;
  }

  > img {
    height: 100%;
    width: 100%;
    pointer-events: none;
  }

  > span {
    color: white;
    pointer-events: none;
  }
`;
const AddCircle = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #519acc;
  border: solid 1px transparent;
  background-color: #fafafa;
  transition: border 0.3s;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 1px 2px #c5c5c5;
  z-index: 1;

  > svg {
    pointer-events: none;
    background: none;
  }

  :hover {
    border: solid 1px #519acc;
  }

  > .circle-select {
    position: absolute;
    height: 0px;
    left: 0;
    bottom: 0;
  }

  > .circle-select > div:first-of-type {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
  }

  .react-select__menu {
    z-index: 11 !important;
  }
`;

class UserAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      loading: false,
    };
    this.addCircleRef = React.createRef();
    this.handleRemoveLead = this.handleRemoveLead.bind(this);
    this.handleAddLead = this.handleAddLead.bind(this);
    this.closeMenu = (e) => {
      if (e.target.id !== "member-add-circle") {
        this.addCircleClick();
      }
    };
  }

  addCircleClick = () => {
    var menuOpen = this.state.menuOpen;
    if (menuOpen) {
      window.removeEventListener("click", this.closeMenu);
      this.setState({ menuOpen: false });
    } else {
      this.setState({ menuOpen: true }, () => {
        window.addEventListener("click", this.closeMenu);
      });
    }
  };

  handleRemoveLead(e) {
    var id = parseInt(e.target.id);
    var users = this.props.value;
    users = users.filter((z) => z.value != id);
    this.props.handler(users);
  }

  handleAddLead(u) {
    this.props.handler(u);
  }

  render() {
    return (
      <div>
        <MemberContainer overflow={this.props.overflow}>
          {this.props.value.map((u) => {
            var count = this.props.value.length;
            return (
              <div
                key={u.value}
                name={u.label}
                style={{
                  position: "relative",
                  height: "30px",
                  borderRadius: "50%",
                  marginRight:
                    count > 16 ? "-12px" : count > 9 ? "-8px" : "4px",
                }}
              >
                <UserCircle
                  name={u.label}
                  count={count}
                  onClick={this.handleRemoveLead}
                  role="button"
                  id={u.value}
                >
                  <div className="overlay">
                    <CancelIcon width={12} />
                    {/* <i className="material-icons">remove</i>  */}
                  </div>
                  {!u.avatar_url.includes("missing.png") ? (
                    <img src={u.avatar_url} />
                  ) : (
                    <span>
                      {u.label
                        .split(" ")
                        .map((word, i) =>
                          i < 2 ? word.charAt(0).toUpperCase() : ""
                        )}
                    </span>
                  )}
                </UserCircle>
              </div>
            );
          })}
          <AddCircle
            role="button"
            id="member-add-circle"
            onClick={this.addCircleClick}
          >
            <PlusIcon width={15} />
            {/* <i className="material-icons">add</i> */}
            <Select
              ref={this.addCircleRef}
              classNamePrefix="react-select"
              menuIsOpen={this.state.menuOpen}
              options={this.props.options}
              onChange={this.handleAddLead}
              placeholder={"Select a source"}
              value={this.props.value}
              isMulti={true}
              className="circle-select"
              styles={{
                ...SingleSelectStyles,
                ...{
                  container: (styles) => ({
                    ...styles,
                    width: "175px",
                    zIndex: "11",
                  }),
                  multiValue: (styles) => ({
                    ...styles,
                    height: "30px",
                    width: "30px",
                    minWidth: "30px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    overflow: "hidden",
                    backgroundColor: "#519acc",
                  }),
                  multiValueRemove: (styles, state) => ({
                    ...styles,
                    opacity: state.isFocused ? "1" : "0",
                  }),
                },
              }}
            />
          </AddCircle>
        </MemberContainer>
      </div>
    );
  }
}

export default UserAdd;
