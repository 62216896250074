import React from "react";

function ProjectTimelineStepThree({ height, width, svgStyles, color }) {
  return (
    <svg
      aria-hidden="true"
      height={height}
      width={width}
      fill={color}
      style={svgStyles}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29 36"
    >
      <path
        d="M9.66005 0.5H19.84C20.0099 0.502073 20.1778 0.538436 20.334 0.607012C20.4901 0.675587 20.6316 0.775032 20.7503 0.899668C20.869 1.0243 20.9626 1.17169 21.0258 1.33341C21.0889 1.49512 21.1203 1.66801 21.1183 1.84218V4.53449C21.1225 4.887 20.9903 5.22687 20.7508 5.47985C20.5113 5.73283 20.1838 5.87834 19.84 5.88461H9.66005C9.49014 5.88151 9.32249 5.84413 9.16667 5.77459C9.01086 5.70506 8.86993 5.60474 8.75194 5.47936C8.63396 5.35399 8.54122 5.20601 8.47903 5.04389C8.41684 4.88176 8.38641 4.70867 8.38949 4.53449V1.84218C8.38642 1.66839 8.4169 1.49568 8.47918 1.33401C8.54147 1.17234 8.63432 1.02489 8.75241 0.900145C8.87049 0.775402 9.01148 0.675829 9.16725 0.607156C9.32302 0.538483 9.4905 0.502066 9.66005 0.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6594 1.84219H24.9299C25.44 1.84943 25.9436 1.95986 26.4119 2.16714C26.8802 2.37442 27.3039 2.67447 27.6588 3.05007C28.0137 3.42567 28.2927 3.86942 28.4799 4.35585C28.667 4.84228 28.7586 5.36181 28.7494 5.88463V31.4655C28.7576 31.9877 28.6652 32.5063 28.4776 32.9919C28.29 33.4774 28.0108 33.9202 27.656 34.2949C27.3013 34.6697 26.8779 34.969 26.4101 35.1758C25.9423 35.3826 25.4393 35.4928 24.9299 35.5H4.57005C4.06068 35.4928 3.5577 35.3826 3.08992 35.1758C2.62214 34.969 2.19874 34.6697 1.84397 34.2949C1.48919 33.9202 1.21 33.4774 1.02239 32.9919C0.834783 32.5063 0.742435 31.9877 0.750634 31.4655V5.88463C0.741404 5.36181 0.832991 4.84228 1.02014 4.35585C1.20728 3.86942 1.4863 3.42567 1.84118 3.05007C2.19606 2.67447 2.61981 2.37442 3.0881 2.16714C3.5564 1.95986 4.06002 1.84943 4.57005 1.84219H5.84061V4.5345C5.82827 5.05929 5.91756 5.58138 6.10331 6.07057C6.28905 6.55977 6.56758 7.00635 6.92276 7.38449C7.27795 7.76263 7.70275 8.06483 8.17259 8.27359C8.64244 8.48236 9.14799 8.59356 9.66003 8.60076H19.84C20.352 8.59356 20.8576 8.48236 21.3274 8.27359C21.7972 8.06483 22.222 7.76263 22.5772 7.38449C22.9324 7.00635 23.2109 6.55977 23.3967 6.07057C23.5824 5.58138 23.6717 5.05929 23.6594 4.5345V1.84219ZM10.3813 14.8515C11.6744 13.9657 13.1948 13.493 14.75 13.493C16.8355 13.493 18.8357 14.3423 20.3104 15.854C21.785 17.3657 22.6135 19.4161 22.6135 21.554C22.6135 23.1483 22.1523 24.7068 21.2883 26.0325C20.4242 27.3581 19.1961 28.3913 17.7592 29.0014C16.3224 29.6116 14.7413 29.7712 13.2159 29.4601C11.6905 29.1491 10.2894 28.3814 9.18967 27.254C8.08994 26.1267 7.34101 24.6903 7.0376 23.1266C6.73418 21.5629 6.88991 19.9421 7.48508 18.4692C8.08025 16.9962 9.08813 15.7373 10.3813 14.8515Z"
        fill={color}
      />
      <path
        d="M13.7971 24.7069C13.6463 24.7077 13.4968 24.6779 13.3572 24.6193C13.2176 24.5607 13.0907 24.4744 12.9836 24.3654L10.7679 22.1497C10.6528 22.0421 10.5599 21.9119 10.4948 21.7669C10.4297 21.6218 10.3937 21.4648 10.389 21.3052C10.3843 21.1456 10.4109 20.9866 10.4673 20.8378C10.5237 20.6889 10.6087 20.5532 10.7173 20.4387C10.8259 20.3242 10.9558 20.2332 11.0994 20.1712C11.2429 20.1092 11.3972 20.0775 11.5529 20.0778C11.7087 20.0781 11.8628 20.1105 12.0061 20.1731C12.1494 20.2357 12.279 20.3272 12.3871 20.4421L13.8126 21.8717L17.1362 18.6949C17.3631 18.482 17.6627 18.3693 17.9701 18.3812C18.2775 18.3931 18.568 18.5286 18.7786 18.7585C18.8834 18.8727 18.9649 19.0071 19.0186 19.1539C19.0722 19.3007 19.0968 19.4569 19.0911 19.6136C19.0853 19.7702 19.0492 19.9241 18.985 20.0664C18.9207 20.2086 18.8295 20.3363 18.7166 20.4421L14.5873 24.4131C14.3689 24.6087 14.0871 24.7135 13.7971 24.7069Z"
        fill={color}
      />
    </svg>
  );
}

ProjectTimelineStepThree.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "#519acc",
};

export default ProjectTimelineStepThree;

<svg
  xmlns="http://www.w3.org/2000/svg"
  width="29"
  height="36"
  viewBox="0 0 29 36"
  fill="none"
>
  <path
    d="M9.66005 0.5H19.84C20.0099 0.502073 20.1778 0.538436 20.334 0.607012C20.4901 0.675587 20.6316 0.775032 20.7503 0.899668C20.869 1.0243 20.9626 1.17169 21.0258 1.33341C21.0889 1.49512 21.1203 1.66801 21.1183 1.84218V4.53449C21.1225 4.887 20.9903 5.22687 20.7508 5.47985C20.5113 5.73283 20.1838 5.87834 19.84 5.88461H9.66005C9.49014 5.88151 9.32249 5.84413 9.16667 5.77459C9.01086 5.70506 8.86993 5.60474 8.75194 5.47936C8.63396 5.35399 8.54122 5.20601 8.47903 5.04389C8.41684 4.88176 8.38641 4.70867 8.38949 4.53449V1.84218C8.38642 1.66839 8.4169 1.49568 8.47918 1.33401C8.54147 1.17234 8.63432 1.02489 8.75241 0.900145C8.87049 0.775402 9.01148 0.675829 9.16725 0.607156C9.32302 0.538483 9.4905 0.502066 9.66005 0.5Z"
    fill="#D7D7D7"
  />
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M23.6594 1.84219H24.9299C25.44 1.84943 25.9436 1.95986 26.4119 2.16714C26.8802 2.37442 27.3039 2.67447 27.6588 3.05007C28.0137 3.42567 28.2927 3.86942 28.4799 4.35585C28.667 4.84228 28.7586 5.36181 28.7494 5.88463V31.4655C28.7576 31.9877 28.6652 32.5063 28.4776 32.9919C28.29 33.4774 28.0108 33.9202 27.656 34.2949C27.3013 34.6697 26.8779 34.969 26.4101 35.1758C25.9423 35.3826 25.4393 35.4928 24.9299 35.5H4.57005C4.06068 35.4928 3.5577 35.3826 3.08992 35.1758C2.62214 34.969 2.19874 34.6697 1.84397 34.2949C1.48919 33.9202 1.21 33.4774 1.02239 32.9919C0.834783 32.5063 0.742435 31.9877 0.750634 31.4655V5.88463C0.741404 5.36181 0.832991 4.84228 1.02014 4.35585C1.20728 3.86942 1.4863 3.42567 1.84118 3.05007C2.19606 2.67447 2.61981 2.37442 3.0881 2.16714C3.5564 1.95986 4.06002 1.84943 4.57005 1.84219H5.84061V4.5345C5.82827 5.05929 5.91756 5.58138 6.10331 6.07057C6.28905 6.55977 6.56758 7.00635 6.92276 7.38449C7.27795 7.76263 7.70275 8.06483 8.17259 8.27359C8.64244 8.48236 9.14799 8.59356 9.66003 8.60076H19.84C20.352 8.59356 20.8576 8.48236 21.3274 8.27359C21.7972 8.06483 22.222 7.76263 22.5772 7.38449C22.9324 7.00635 23.2109 6.55977 23.3967 6.07057C23.5824 5.58138 23.6717 5.05929 23.6594 4.5345V1.84219ZM10.3813 14.8515C11.6744 13.9657 13.1948 13.493 14.75 13.493C16.8355 13.493 18.8357 14.3423 20.3104 15.854C21.785 17.3657 22.6135 19.4161 22.6135 21.554C22.6135 23.1483 22.1523 24.7068 21.2883 26.0325C20.4242 27.3581 19.1961 28.3913 17.7592 29.0014C16.3224 29.6116 14.7413 29.7712 13.2159 29.4601C11.6905 29.1491 10.2894 28.3814 9.18967 27.254C8.08994 26.1267 7.34101 24.6903 7.0376 23.1266C6.73418 21.5629 6.88991 19.9421 7.48508 18.4692C8.08025 16.9962 9.08813 15.7373 10.3813 14.8515Z"
    fill="#D7D7D7"
  />
  <path
    d="M13.7971 24.7069C13.6463 24.7077 13.4968 24.6779 13.3572 24.6193C13.2176 24.5607 13.0907 24.4744 12.9836 24.3654L10.7679 22.1497C10.6528 22.0421 10.5599 21.9119 10.4948 21.7669C10.4297 21.6218 10.3937 21.4648 10.389 21.3052C10.3843 21.1456 10.4109 20.9866 10.4673 20.8378C10.5237 20.6889 10.6087 20.5532 10.7173 20.4387C10.8259 20.3242 10.9558 20.2332 11.0994 20.1712C11.2429 20.1092 11.3972 20.0775 11.5529 20.0778C11.7087 20.0781 11.8628 20.1105 12.0061 20.1731C12.1494 20.2357 12.279 20.3272 12.3871 20.4421L13.8126 21.8717L17.1362 18.6949C17.3631 18.482 17.6627 18.3693 17.9701 18.3812C18.2775 18.3931 18.568 18.5286 18.7786 18.7585C18.8834 18.8727 18.9649 19.0071 19.0186 19.1539C19.0722 19.3007 19.0968 19.4569 19.0911 19.6136C19.0853 19.7702 19.0492 19.9241 18.985 20.0664C18.9207 20.2086 18.8295 20.3363 18.7166 20.4421L14.5873 24.4131C14.3689 24.6087 14.0871 24.7135 13.7971 24.7069Z"
    fill="#D7D7D7"
  />
</svg>;
