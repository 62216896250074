import React from "react";
import ProjectDirectory from "./project_directory";
import ProjectDetail from "./project_detail";
import ReactModal from "../modals/react_modal";
import axios from "axios";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import PageNav from "../common/page_nav";
import ActivityFeedContainer from "../activity/activity_feed_container";
import ProjectMembersBox from "./project_members_box";
import PageDetail from "../common/page_detail";
import { setAlphaGroups, sentryInit } from "../utils";
import ProjectsIcon from "../icons/projects_icon";

sentryInit();

class ProjectsContainer extends React.Component {
  constructor(props) {
    super(props);
    var project = this.props.project;
    this.state = {
      project: project,
      projects: this.props.projects,
      modalOpen: false,
      currentModal: "",
      modalData: {},
      search: "",
      filtered: true,
      searchData: [],
      selectedTag: [],
      selectedStatus: [],
      selectedUser: [],
      selectedClient: [],
      projectMembers: this.props.projectMembers,
      userOptions: this.props.userOptions,
      timeTaskOptions: this.props.timeTaskOptions,
      projectTasks: this.props.projectTasks,
      timeOffProject: project
        ? project.pto || project.holiday || project.sick_time
        : false,
      alphaGroups: setAlphaGroups(this.props.projects),
      loading: false,
      currentView: this.props.project ? "project" : "projects",
      direction: "forward",
      activityEvents: this.props.activityEvents,
      activityFeedTitle: this.props.project
        ? this.props.project.name
        : "All Projects",
    };
    this.dynamicListRef = React.createRef();
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSingleSelectChange = this.handleSingleSelectChange.bind(this);
    this.filterHandler = this.filterHandler.bind(this);
  }

  scrollIntoView = (e) => {
    var row = e.target.dataset.index;
    if (this.dynamicListRef && this.dynamicListRef.current) {
      this.dynamicListRef.current.scrollToItem(row, "start");
    }
  };

  updateSearch = (event) => {
    var input = event.target.value;
    this.setProjectsData(input);
  };

  componentDidMount() {
    if (!this.props.project) {
      const url = new URL(location.href);
      if (url.searchParams.toString()) {
        localStorage.setItem(
          "projects_url_params",
          url.searchParams.toString()
        );
      } else if (localStorage.getItem("projects_url_params")) {
        var params = localStorage.getItem("projects_url_params").split("&");
        params.forEach((p) => {
          var param = p.split("=");
          url.searchParams.set(param[0], param[1].replace(/%2C/g, ","));
        });
        window.history.pushState(
          window.history.state,
          null,
          "?" + url.searchParams.toString()
        );
      }
      this.setBaseFilters(url);
      document.title = "Syncit - Projects";
    } else {
      document.title = `Syncit - ${this.props.project.name}`;
    }
  }

  searchHandler = (projects, input) => {
    if (input && input.length > 0) {
      const searchTerm = input.toLowerCase();
      let filteredItems = projects.filter((i) =>
        `${i.name.toLowerCase()}${
          i.client_name ? i.client_name.toLowerCase() : ""
        }`.includes(searchTerm)
      );
      return filteredItems;
    } else if (input === "") {
      var data = projects;
    }
    return data;
  };

  clearFilters = (name) => {
    if (typeof name === "string") {
      this.setState(
        {
          [name]: [],
        },
        () => {
          var url = new URL(location.href);
          url.searchParams.delete(name);
          if (url.searchParams.toString()) {
            localStorage.setItem(
              "projects_url_params",
              url.searchParams.toString()
            );
          } else {
            localStorage.removeItem("projects_url_params");
          }
          var stateObj = {};
          window.history.replaceState(stateObj, "", url.href);
          this.setProjectsData(this.state.search);
        }
      );
    } else {
      this.setState(
        {
          selectedTag: [],
          selectedUser: [],
          selectedClient: [],
        },
        () => {
          var url = new URL(location.href);
          url.searchParams.delete("selectedUser");
          url.searchParams.delete("selectedTag");
          url.searchParams.delete("selectedClient");
          localStorage.removeItem("projects_url_params");
          var stateObj = {};
          window.history.replaceState(stateObj, "", url.href);
          this.setProjectsData(this.state.search);
        }
      );
    }
  };

  filterHandler() {
    var self = this;
    var projects = this.state.projects;
    if (this.state.selectedTag.length >= 1) {
      projects = projects.filter((project) =>
        self.state.selectedTag.includes(project.tag_id)
      );
    }
    if (this.state.selectedStatus.length >= 1) {
      if (this.state.selectedStatus[0] === 1) {
        projects = projects.filter(
          (project) => project.active && project.pending === false
        );
      } else if (this.state.selectedStatus[0] === 2) {
        projects = projects.filter((project) => project.active === false);
      } else if (this.state.selectedStatus[0] === 3) {
        projects = projects.filter((project) => project.pending);
      } else {
        // do nothing all projects
      }
    }
    if (this.state.selectedClient.length >= 1) {
      projects = projects.filter((project) =>
        this.state.selectedClient.includes(project.client_id)
      );
    }
    if (this.state.selectedUser.length >= 1) {
      projects = projects.filter((project) =>
        project.lead_ids.some((tag) => self.state.selectedUser.includes(tag))
      );
    }
    return projects;
  }

  filterUrlChange = (key, value, append) => {
    var url = new URL(location.href);
    var newUrl;
    if (append) {
      url.searchParams.set(key, value);
      localStorage.setItem("projects_url_params", url.searchParams.toString());
    } else {
      url.searchParams.delete(key);
      if (url.searchParams.toString()) {
        localStorage.setItem(
          "projects_url_params",
          url.searchParams.toString()
        );
      } else {
        localStorage.removeItem("projects_url_params");
      }
    }
    window.history.replaceState(window.history.state, "", url.href);
  };

  filterUrlHandler = (param_key, state_key, filter_id) => {
    var url = new URL(location.href);
    var paramPresent = url.searchParams.has(param_key);
    var newStatusFilter = this.state[state_key];
    var append;
    if (newStatusFilter.includes(filter_id)) {
      if (paramPresent) {
        var ids = url.searchParams.get(param_key);
        filter_id = ids + "," + filter_id.toString();
      }
      append = true;
    } else {
      if (paramPresent) {
        var ids = url.searchParams.get(param_key).split(",");
        ids = ids.filter((x) => x != filter_id.toString());
        filter_id = ids.join(",");
        if (ids.length >= 1) {
          append = true;
        } else {
          append = false;
        }
      } else {
        append = false;
      }
    }
    this.filterUrlChange(param_key, filter_id, append);
  };

  setBaseFilters = (url) => {
    var filters = ["selectedClient", "selectedUser", "selectedTag"];
    var filterObject = {};
    for (var x = 0; x < 3; x++) {
      var params = url.searchParams.get(filters[x]);
      if (params) {
        filterObject[filters[x]] = params.split(",").map((x) => parseInt(x));
      }
    }
    filterObject.selectedStatus = [1];
    this.setState(filterObject, () => {
      this.setProjectsData("");
    });
  };

  handleSelectChange(e) {
    var e = e;
    var name = e.target.name;
    var value = parseInt(e.target.value);
    var old = this.state[name];
    if (old.includes(value)) {
      old = old.filter((x) => x != value);
    } else {
      old.push(value);
    }
    this.setState({ [name]: old }, () => {
      // var url = new URL(window.location.href);
      // var params = url.searchParams;
      // params.set(name, old)
      // history.pushState({}, null, "?" + params.toString());
      this.filterUrlHandler(name, name, value);
      this.setProjectsData(this.state.search);
    });
  }

  handleSingleSelectChange(e) {
    var e = e;
    var name = e.target.name;
    var value = parseInt(e.target.value);
    this.setState({ [name]: [value] }, () => {
      this.setProjectsData(this.state.search);
    });
  }

  setProjectsData = (input) => {
    var filtered = this.filterHandler();
    var searched = this.searchHandler(filtered, input);
    this.setState({
      search: input,
      searchData: searched,
      alphaGroups: setAlphaGroups(searched),
    });
  };

  setModal = (bool, page, data) => {
    this.setState({
      modalOpen: bool,
      currentModal: page,
      modalData: data,
    });
  };

  backToProjects = () => {
    this.setState({ direction: "back" }, () => {
      this.setState({
        project: null,
        currentView: "projects",
        activityFeedTitle: "All Projects",
        alphaGroups: setAlphaGroups(this.state.projects),
        search: "",
        searchData: [],
        selectedTag: [],
        selectedUser: [],
        selectedStatus: [],
      });
      window.history.pushState("page2", "Syncit", "/projects");
    });
  };

  onAddProjectClick = () => {
    this.setModal(true, "add-project", {
      clientOptions: this.props.clients,
      tagOptions: this.props.tagOptions,
      leadOptions: this.props.leadOptions,
      projectsHandler: this.projectsHandler,
      currentUser: this.props.currentUser,
      closeButton: true,
      customClass: "project-modal",
    });
  };

  copyProject = (project) => {
    this.setModal(false, "", {});
    setTimeout(() => {
      this.setModal(true, "copy-project", {
        customClass: "taskTransitionModal",
        project: project,
        tagOptions: this.props.tagOptions,
        leadOptions: this.props.leadOptions,
        taskboard: false,
        projectsHandler: this.projectsHandler,
      });
    }, 300);
  };

  loadProject = (id) => {
    var start = Date.now();
    this.setState({ direction: "forward", loading: true });
    axios
      .get(`/api/v1/projects/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var data = res.data;
        if (this.state.currentView === "project") {
          this.backToProjects();
          setTimeout(() => {
            this.setState({
              project: data.project,
              activityFeedTitle: data.project.name,
              loading: false,
              activityEvents: data.activityEvents,
              projectTasks: data.projectTasks,
              userOptions: data.userOptions,
              startDate: data.state_date,
              timeTaskOptions: data.timeTaskOptions,
              timeOffProject:
                data.project.pto ||
                data.project.holiday ||
                data.project.sick_time,
              currentView: "project",
              search: "",
              searchData: [],
              selectedTag: [],
              selectedUser: [],
              selectedStatus: [],
              projectMembers: data.projectMembers,
            });
            document.title = `Syncit - ${data.project.name}`;
          }, 1000);
        } else {
          if (Date.now() - start < 500) {
            setTimeout(() => {
              this.setState({
                project: data.project,
                activityFeedTitle: data.project.name,
                loading: false,
                activityEvents: data.activityEvents,
                projectTasks: data.projectTasks,
                userOptions: data.userOptions,
                startDate: data.state_date,
                timeTaskOptions: data.timeTaskOptions,
                timeOffProject:
                  data.project.pto ||
                  data.project.holiday ||
                  data.project.sick_time,
                currentView: "project",
                search: "",
                searchData: [],
                selectedTag: [],
                selectedUser: [],
                selectedStatus: [],
                projectMembers: data.projectMembers,
              });
              document.title = `Syncit - ${data.project.name}`;
            }, 500 - (Date.now() - start));
          } else {
            this.setState({
              project: data.project,
              activityFeedTitle: data.project.name,
              activityEvents: data.activityEvents,
              projectTasks: data.projectTasks,
              userOptions: data.userOptions,
              timeTaskOptions: data.timeTaskOptions,
              timeOffProject:
                data.project.pto ||
                data.project.holiday ||
                data.project.sick_time,
              loading: false,
              currentView: "project",
              search: "",
              searchData: [],
              selectedTag: [],
              selectedUser: [],
              selectedStatus: [],
              projectMembers: data.projectMembers,
            });
            document.title = `Syncit - ${data.project.name}`;
          }
        }

        var url = new URL(window.location.href);
        var params = url.searchParams;
        if (params.toString()) {
          window.history.pushState(
            "page2",
            "Syncit",
            "/projects/" + data.project.id + "?" + params.toString()
          );
        } else {
          window.history.pushState(
            "page2",
            "Syncit",
            "/projects/" + data.project.id
          );
        }
      })
      .catch((err) => console.error(err));
  };

  onEditProjectClick = () => {
    this.setModal(true, "edit-project", {
      active: this.state.active,
      tagOptions: this.props.tagOptions,
      closeButton: true,
      clientOptions: this.props.clients.map((client) => ({
        value: client.id,
        label: client.name,
      })),
      currentUser: this.props.currentUser,
      userOptions: this.props.leadOptions,
      project: this.state.project,
      projectHandler: this.projectHandler,
      cardColor: this.state.card_color,
      customClass: "project-modal",
    });
  };

  renderCurrentView = () => {
    if (this.state.project) {
      return (
        <ProjectDetail
          setModal={this.setModal}
          tagOptions={this.props.tagOptions}
          leadOptions={this.props.leadOptions}
          tags={this.props.tags}
          dynamicListRef={this.dynamicListRef}
          client={this.state.client}
          copyProject={this.copyProject}
          currentUser={this.props.currentUser}
          projectTasks={this.state.projectTasks}
          timeTaskOptions={this.state.timeTaskOptions}
          backToProjects={this.backToProjects}
          project={this.state.project}
          userOptions={this.state.userOptions}
          timeOffProject={this.state.timeOffProject}
          viewScheduleLink={`/schedule?selectedProject=${this.state.project.id}`}
          page="project"
        />
      );
    } else {
      var projects;
      if (
        this.state.search != "" ||
        this.state.selectedClient.length +
          this.state.selectedStatus.length +
          this.state.selectedTag.length +
          this.state.selectedUser.length >=
          1
      ) {
        projects = this.state.searchData;
      } else {
        projects = this.state.projects;
      }
      return (
        <ProjectDirectory
          clientView={false}
          filtersActive={
            this.state.selectedStatus.length +
              this.state.selectedTag.length +
              this.state.selectedUser.length >=
            1
          }
          clients={this.props.clients}
          selectedStatus={this.state.selectedStatus}
          setModal={this.setModal}
          currentUser={this.props.currentUser}
          loadProject={this.loadProject}
          leadOptions={this.props.leadOptions}
          tagOptions={this.props.tagOptions}
          active
          tags={this.props.tags}
          projects={projects}
          selectedClient={this.state.selectedClient}
          dynamicListRef={this.dynamicListRef}
          alphaGroupsRefresher={setAlphaGroups}
          alphaGroups={this.state.alphaGroups}
          search={this.state.search}
          searchData={this.state.searchData}
        />
      );
    }
  };

  compare(a, b) {
    var a = a.name.toLowerCase();
    var b = b.name.toLowerCase();
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  }

  projectsHandler = (action, project) => {
    var self = this;
    var newProjects = self.state.projects;
    if (action === "create") {
      newProjects.push(project);
    } else if (action === "delete" || action === "edit") {
      for (var i = 0; i < newProjects.length; i++) {
        if (newProjects[i].id === project.id) {
          if (action === "delete") {
            newProjects.splice(i, 1);
          } else if (action === "edit") {
            newProjects.splice(i, 1, project);
          }
          break;
        }
      }
    }
    var sorted = newProjects.sort(this.compare);
    var alpha = setAlphaGroups(sorted);
    self.setState(
      {
        projects: sorted,
        alphaGroups: alpha,
      },
      () => {
        this.setProjectsData(this.state.search);
        if (action === "create") {
          this.loadProject(project.id);
        }
      }
    );
  };

  projectHandler = (action, project) => {
    var self = this;
    var newProject = self.state.project;
    if (action === "delete" || action === "edit") {
      if (action === "delete") {
        newProject = null;
      } else if (action === "edit") {
        newProject = project;
      }
    }
    self.setState({
      project: newProject,
    });
  };

  render() {
    var showClearFilters =
      !this.state.project &&
      this.state.selectedUser.length +
        this.state.selectedTag.length +
        this.state.selectedClient.length >
        0;
    var classNames =
      this.state.direction === "forward" ? "node-left" : "node-right";
    var location =
      this.state.project && this.state.project.project_links.length > 0
        ? this.state.project.project_links[0].location
        : "";
    if (location && !location.includes("http")) {
      location = "https://" + location;
    }
    return (
      <div style={{ backgroundColor: "#f5f5f5" }}>
        <ReactModal
          isShowing={this.state.modalOpen}
          page={this.state.currentModal}
          data={this.state.modalData}
          modalAction={this.setModal}
        />
        <CSSTransition
          unmountOnExit
          in={this.state.loading}
          timeout={300}
          classNames="node-fade"
        >
          <div className="page-loader">
            <div className="page-load-spinner" />
          </div>
        </CSSTransition>
        <div
          style={{ maxWidth: "1026px", height: "calc(100vh - 64px)" }}
          id="projects-container"
        >
          <section
            style={{ width: "100%", marginRight: "8px", minWidth: "1024px" }}
            aria-label="Project Directory"
          >
            <button
              style={{
                display: "block",
                zIndex: "10",
                pointerEvents: showClearFilters ? "all" : "none",
                position: "absolute",

                top: "34px",
                right: "26px",
                opacity: showClearFilters ? "1" : "0",
              }}
              className="btn-flat"
              onClick={this.clearFilters}
            >
              {" "}
              Clear All Filters{" "}
            </button>
            <div style={{ padding: "32px" }}>
              <SwitchTransition mode={"out-in"}>
                <CSSTransition
                  timeout={{
                    enter: 300,
                    exit: 300,
                    appear: 1000,
                  }}
                  appear={true}
                  key={this.state.currentView}
                  classNames={"activity-fade"}
                >
                  {!this.state.project ? (
                    <PageNav
                      pageIcon={<ProjectsIcon />}
                      pageName={
                        this.state.project
                          ? this.state.project.name
                          : "Projects"
                      }
                      searchKeyDownHandler={this.searchKeyDown}
                      usedLetters={null}
                      clearFunction={this.clearFilters}
                      checkboxes={[
                        {
                          name: "selectedStatus",
                          single: true,
                          objectTitle: "Projects",
                          title: "Filter By Status",
                          handler: this.handleSingleSelectChange,
                          options: [
                            {
                              id: 1,
                              name: "Active",
                              count: this.props.projects.filter(
                                (x) => x.active && x.pending === false
                              ).length,
                            },
                            {
                              id: 2,
                              name: "Inactive",
                              count: this.props.projects.filter(
                                (x) => x.active === false
                              ).length,
                            },
                            {
                              id: 3,
                              name: "Pending",
                              count: this.props.projects.filter(
                                (x) => x.pending
                              ).length,
                            },
                            {
                              id: 4,
                              name: "All",
                              count: this.props.projects.length,
                            },
                          ],
                          selected: this.state.selectedStatus,
                        },
                        {
                          name: "selectedClient",
                          title: "Client",
                          handler: this.handleSelectChange,
                          options: this.props.clients,
                          selected: this.state.selectedClient,
                          searchEnabled: true,
                        },
                        {
                          name: "selectedUser",
                          title: "Lead",
                          handler: this.handleSelectChange,
                          options: this.props.leads,
                          selected: this.state.selectedUser,
                        },
                        {
                          name: "selectedTag",
                          title: "Type",
                          handler: this.handleSelectChange,
                          options: this.props.tags,
                          selected: this.state.selectedTag,
                        },
                      ]}
                      scrollHandler={this.scrollIntoView}
                      addHandler={this.onAddProjectClick}
                      addText={"Add Project"}
                      search={this.state.search}
                      tags={this.props.tag}
                      updateSearchHandler={this.updateSearch}
                    />
                  ) : (
                    <PageDetail
                      link={{ text: "View Estimate", location: location }}
                      startDate={this.state.project.start_date}
                      projectStatus={
                        this.state.project.project_status
                          ? this.state.project.project_status.aasm_state
                          : null
                      }
                      endDate={this.state.project.end_date}
                      detailTitles={[
                        this.state.project.client_name,
                        this.state.project.name,
                      ]}
                      tags={[this.state.project.tag]}
                      leads={this.state.project.leads}
                      clickHandler={this.onEditProjectClick}
                      projectHandler={this.projectHandler}
                      timeOffProject={this.state.timeOffProject}
                      bugTrackerTp={this.state.project.bug_tracker_tp}
                    />
                  )}
                </CSSTransition>
              </SwitchTransition>
            </div>

            <div
              style={{
                width: "calc(100% + 40px)",
                position: "relative",
                // marginBottom: "20px",
                left: "-20px",
                height: "1px",
                backgroundColor: "lightgray",
              }}
            />
            <SwitchTransition mode={"out-in"}>
              <CSSTransition
                timeout={{
                  enter: 300,
                  exit: 300,
                  appear: 1000,
                }}
                appear={true}
                key={this.state.currentView}
                classNames={classNames}
              >
                {this.renderCurrentView()}
              </CSSTransition>
            </SwitchTransition>
            <SwitchTransition mode={"out-in"}>
              <CSSTransition
                timeout={{
                  enter: 300,
                  exit: 300,
                  appear: 1000,
                }}
                appear={true}
                key={this.state.currentView}
                classNames={"activity-fade"}
              >
                {!this.state.project ? (
                  <div
                    style={{
                      position: "absolute",
                      top: "32px",
                      right: showClearFilters ? "140px" : "32px",
                      width: "100%",
                      textAlign: "right",
                    }}
                  >
                    {" "}
                    Displaying{" "}
                    {this.props.filtersActive
                      ? this.state.searchData.length
                      : this.state.searchData.length >= 0
                      ? this.state.searchData.length
                      : this.state.projects.length}{" "}
                    of {this.state.projects.length} projects
                  </div>
                ) : (
                  <div></div>
                )}
              </CSSTransition>
            </SwitchTransition>
          </section>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {/* client hours widget filler */}
            <div
              style={{
                width: "300px",
                maxHeight:
                  this.state.currentView !== "projects"
                    ? `calc(33.33% - 25px)`
                    : "0px",
                height: `calc(33.33% - 25px)`,
                backgroundColor: "white",
                marginBottom:
                  this.state.currentView !== "projects" ? "8px" : "0px",
                borderRadius: "10px",
                overflow: "hidden",
                transition:
                  "max-height 0.2s, box-shadow 0.2s, margin-bottom 0.2s",
                // border: "1px solid #D7D7D7",
              }}
            >
              <SwitchTransition mode={"out-in"}>
                <CSSTransition
                  timeout={{ enter: 300, exit: 300, appear: 1000 }}
                  appear={true}
                  key={this.state.currentView}
                  classNames={"activity-fade"}
                >
                  <ProjectMembersBox
                    currentView={this.state.currentView}
                    projectMembers={this.state.projectMembers}
                  />
                </CSSTransition>
              </SwitchTransition>
            </div>
            <ActivityFeedContainer
              currentUser={this.props.currentUser}
              currentView={this.state.currentView}
              title={this.state.activityFeedTitle}
              activities={this.state.activityEvents}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectsContainer;
