import React from "react";

const UpdatedCheckmarkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <rect
        x="1.5"
        y="1.5"
        width="29"
        height="29"
        rx="14.5"
        fill="url(#paint0_linear_5137_1364)"
      />
      <rect
        x="1.5"
        y="1.5"
        width="29"
        height="29"
        rx="14.5"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M22.6149 12.03C22.4354 11.8538 22.194 11.7551 21.9424 11.7551C21.6909 11.7551 21.4494 11.8538 21.2699 12.03L14.9499 18.5L11.8549 15.18C11.6834 14.9958 11.4464 14.8862 11.1949 14.875H11.1599C10.9754 14.8755 10.795 14.9297 10.6408 15.031C10.4866 15.1324 10.3652 15.2764 10.2915 15.4455C10.2178 15.6147 10.1949 15.8017 10.2257 15.9836C10.2565 16.1655 10.3396 16.3346 10.4649 16.47L14.2399 20.5C14.3269 20.5941 14.4319 20.6696 14.5488 20.722C14.6656 20.7744 14.7919 20.8027 14.9199 20.805C15.1754 20.8038 15.4199 20.7013 15.5999 20.52L22.5999 13.36C22.776 13.1844 22.8762 12.9468 22.879 12.6981C22.8818 12.4494 22.787 12.2096 22.6149 12.03Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5694 11.3164C20.9359 10.9567 21.4289 10.7551 21.9424 10.7551C22.456 10.7551 22.949 10.9567 23.3154 11.3164L22.6149 12.03L23.3369 11.3381C23.6901 11.7066 23.8847 12.199 23.879 12.7094C23.8732 13.2173 23.6695 13.7029 23.3114 14.0628L16.3095 21.2247C15.943 21.5938 15.445 21.8025 14.9248 21.805L14.9199 20.805L14.9014 21.8049C14.6385 21.8 14.3794 21.742 14.1395 21.6344C13.9008 21.5274 13.6862 21.3734 13.5082 21.1817L9.73073 17.149C9.47357 16.8709 9.30298 16.524 9.23976 16.1505C9.17654 15.7771 9.22344 15.3933 9.37474 15.0461C9.52604 14.6989 9.77518 14.4032 10.0917 14.1953C10.4083 13.9873 10.7786 13.876 11.1573 13.875L11.1599 13.875L11.2172 13.875L11.2394 13.876C11.7526 13.8989 12.2363 14.1223 12.5864 14.4982L14.9668 17.0516L20.5619 11.3238L20.5694 11.3164Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5137_1364"
          x1="16"
          y1="0"
          x2="16"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default UpdatedCheckmarkIcon;
