import React from "react";
import SvgShell from "./svg_shell";

function FolderIcon({ height, width, svgStyles, color }) {
  return (
    <SvgShell height={height} width={width} svgStyles={svgStyles} color={color}>
      <path d="M32,6.46a25,25,0,1,0,25,25A25,25,0,0,0,32,6.46Z" />
      <rect x="45.89" y="23.94" width="0.75" height="1.38" />
      <path
        fill="#fff"
        d="M45.9,23a1.55,1.55,0,0,1,0,.3v.69H28.38a.67.67,0,0,0-.54.26l-1,1.29H18.1V21.13h7.14a1,1,0,0,1,.52.15l2.59,1.55a.75.75,0,0,0,.36.1h16.9Z"
      />
      <path
        fill="#fff"
        d="M45.89,25.32V41.17c0,.23-.57.66-1.48.66H19.58c-.92,0-1.48-.43-1.48-.66V26.87h9.06a.67.67,0,0,0,.54-.26l1-1.29Z"
      />
      <path d="M45.89,23.94v1.38H28.72l-1,1.29a.67.67,0,0,1-.54.26H18.1V25.49h8.72l1-1.29a.67.67,0,0,1,.54-.26Z" />
      <rect x="17.46" y="25.49" width="0.63" height="1.38" />
    </SvgShell>
  );
}

FolderIcon.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "#519acc",
};

export default FolderIcon;
