import React from "react";

function SvgShell({ height, width, svgStyles, children, color }) {
  return (
    <svg
      aria-hidden="true"
      height={height}
      width={width}
      style={svgStyles}
      fill={color}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
    >
      {children}
    </svg>
  );
}

SvgShell.defaultProps = {
  height: "20px",
  width: "20px",
  svgStyles: {},
  color: "#519acc",
};

export default SvgShell;
