import React from "react";
import styled from "styled-components";

const ProgressContainer = styled.div.attrs((props) => ({
  noBudget: props.noBudget,
}))`
  position: relative;
  opacity: ${(props) => (props.noBudget ? "0.4" : "1")};
  width: 160px;
  height: 160px;
  border-radius: 50%;
  box-shadow: inset 0 0 50px #000;
  background: #222;
  z-index: 1;
`;
const ProgressSvg = styled.svg`
  position: relative;
  width: 160px;
  height: 160px;
  z-index: 1;
  overflow: visible !important;
  transform: rotate(-90deg);
  & .circle1 {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: lightgray;
    stroke-width: 20;
    stroke-linecap: round;
    transform: translate(5px, 5px);
  }
  .circle2 {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: #519acc;
    stroke-width: 17;
    stroke-linecap: round;
    stroke-dasharray: 439.81;
    stroke-dashoffset: 439.81;
    transform: translate(5px, 5px);
    transition: stroke-dashoffset 0.3s;
    animation: progress 1s forwards;
  }
`;
const ProgressNumber = styled.div.attrs((props) => ({
  label: props.label,
  over: props.over,
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: white;
  border: solid 19px lightgray;
  & h3 {
    color: ${(props) => (props.over ? "red" : "#519acc")};
    font-weight: 500;
    font-family: "Manrope", sans-serif;
    font-size: ${(props) => (props.label ? "30px" : "40px")};
    transition: 0.5s;
    margin: 0px;
    line-height: 18px;
    text-align: center;
  }
  h3 > span:nth-child(2) {
    font-size: 15px;
    color: #313131;
  }
`;

class ProgressRing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDash: "-439.82",
    };
  }

  componentDidMount({ percent, percentDecimal, rampUpPercent }) {
    const result = percentDecimal ? percent / 100 : percent;
    if (rampUpPercent && result <= 100) {
      this.rampUpPercent();
    }
    setTimeout(() => {
      this.setState({ startDash: null });
    }, 200);
  }

  componentDidUpdate({ rampUpPercent }) {
    if (rampUpPercent) {
      this.rampUpPercent();
    }
  }

  rampUpPercent({ percentDecimal, id, percent }) {
    const result = percentDecimal ? percent / 100 : percent;
    const elem = document.getElementById(id || "percent-text");
    if (elem) {
      let counter = 0;
      const roundedResult = Math.round(result) / 20;
      const time = setInterval(() => {
        if (counter < 20) {
          elem.innerText = Math.round(roundedResult * counter);
          counter += 1;
        } else {
          elem.innerText = Math.round(roundedResult * counter);
          clearInterval(time);
        }
      }, 50);
    }
  }

  render({
    hidden,
    percentDecimal,
    percent,
    rampUpPercent,
    noBudget,
    label,
    id,
  }) {
    const result = hidden ? 0 : percentDecimal ? percent / 100 : percent;
    const over = result > 100;
    const strokeDashoffset = over
      ? "0"
      : this.state.startDash
      ? this.state.startDash
      : `${-439.82 * (1 - percent / 100)}`;
    return (
      <ProgressContainer
        noBudget={hidden || noBudget}
        aria-hidden={hidden || noBudget}
      >
        <ProgressSvg>
          <circle className="circle1" cx="75" cy="75" r="70" />
          {!hidden && !noBudget && percent > 0 && (
            <circle
              style={{ strokeDashoffset }}
              className="circle2"
              cx="75"
              cy="75"
              r="70"
            />
          )}
        </ProgressSvg>
        <ProgressNumber over={over} label={label}>
          <h3>
            {noBudget ? (
              <span
                style={{ fontSize: "18px", color: "#313131", opacity: "0.4" }}
              >
                Non-Billable
              </span>
            ) : (
              <>
                <span id={id || "percent-text"}>
                  {over ? ">100" : !rampUpPercent ? Math.round(percent) : 0}
                </span>
                %
                {label && (
                  <span className="arial">
                    <br />
                    {label}
                  </span>
                )}
              </>
            )}
          </h3>
        </ProgressNumber>
      </ProgressContainer>
    );
  }
}

ProgressRing.defaultProps = {
  percentDecimal: false, // set to true is using decimal number for percent prop (example: 0.6 instead of 60)
  percent: 0,
};

export default ProgressRing;
