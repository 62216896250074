import React from "react";
import axios from "axios";
import styled from "styled-components";
import { ReactTrixRTEInput } from "react-trix-rte";
import Swal from "sweetalert2";
import EditIcon from "../icons/edit_icon";
import HelpIcon from "../icons/help_icon";
import RoundDropdown from "../projects/round_dropdown";

class Help extends React.Component {
  constructor(props) {
    super(props);
    const { pages, allTopics, tags, currentUser } = props;
    this.state = {
      pages: pages || [],
      allTopics: allTopics || "",
      tags: tags || [],
      search: "",
      currentUser,
      editMode: false,
      activePage: null,
    };
    this.getHelp = this.getHelp.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEditorReady = this.handleEditorReady.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.deleteClick = this.deleteClick.bind(this);
    this.getCurrentPage = this.getCurrentPage.bind(this);
    this.updateSearchHandler = this.updateSearchHandler.bind(this);
    this.updateTitle = this.updateTitle.bind(this);
    this.updateTopic = this.updateTopic.bind(this);
    this.updateTags = this.updateTags.bind(this);
    this.updatePriority = this.updatePriority.bind(this);
    this.contactGuerd = this.contactGuerd.bind(this);
    this.viewMore = this.viewMore.bind(this);
  }

  updateSearchHandler(e) {
    const input = e.target.value;
    const pages = this.state.pages.filter((page) => {
      page.tags.some((x) => x.includes(input.toLowerCase()));
    });
    this.setState({
      search: input,
    });
  }

  toggleEditMode() {
    const { activePage } = this.state;
    if (activePage.title + activePage.tags + activePage.topic === "") {
      Swal.fire({
        title: `Discard new help page`,
        text: "Are you sure that you want to discard this page?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.handleDelete();
          // eslint-disable-next-line no-undef
          M.toast({
            html: "Page Removed",
            displayLength: 3000,
            classes: "green",
          });
        }
      });
    } else {
      this.setState({ editMode: !this.state.editMode });
    }
  }

  deleteClick() {
    Swal.fire({
      title: `Discard help page`,
      text: "Are you sure that you want to discard this page?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.handleDelete();
        // eslint-disable-next-line no-undef
        M.toast({
          html: "Page Removed",
          displayLength: 3000,
          classes: "green",
        });
      }
    });
  }

  getCurrentPage(e) {
    const id = parseInt(e.target.value);
    const page = this.state.pages.find((x) => x.id === id);
    this.setState({ activePage: page });
  }

  contactGuerd() {
    Swal.fire({
      title: "Guerd",
      text: "What do you want this to do?",
    });
  }

  handleCreate() {
    axios
      .post(`/help.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const resp = res.data;
        this.setState({
          pages: resp.pages,
          activePage: resp.activePage,
          tags: resp.tags,
          allTopics: resp.allTopics,
          editMode: true,
        });
      })
      .catch((err) => console.error(err));
  }

  handleDelete() {
    const helpPage = this.state.activePage;
    axios
      .delete(`/help/${helpPage.id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const resp = res.data;
        this.setState({
          pages: resp.pages,
          activePage: resp.activePage,
          tags: resp.tags,
          allTopics: resp.allTopics,
          editMode: false,
        });
      })
      .catch((err) => console.error(err));
  }

  handleSave() {
    const helpPage = this.state.activePage;
    axios
      .post(`/help/${helpPage.id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        help_page: helpPage,
      })
      .then((res) => {
        const resp = res.data;
        this.setState({
          pages: resp.pages,
          activePage: resp.activePage,
          tags: resp.tags,
          allTopics: resp.allTopics,
          editMode: false,
        });
      })
      .catch((err) => console.error(err));
  }

  handleBack() {
    this.setState({ activePage: null });
  }

  getHelp() {
    axios
      .get("/help", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.setState({
          pages: res.data.pages,
          currentUser: res.data.currentUser,
          tags: res.data.tags,
          allTopics: res.data.allTopics,
        });
      })
      .catch((err) => console.error(err));
  }

  componentDidMount() {
    if (Object.values(this.props).length === 0) {
      this.getHelp();
    }
    document.title = `Syncit - Help`;
  }

  updateTitle(e) {
    const title = e.target.value;
    const { activePage } = this.state;
    activePage.title = title;
    this.setState(activePage);
  }

  updateTopic(e) {
    const topic = e.target.value;
    const { activePage } = this.state;
    activePage.topic = topic;
    this.setState(activePage);
  }

  updateTags(e) {
    const tags = e.target.value.split(",");
    const { activePage } = this.state;
    activePage.tags = tags;
    this.setState(activePage);
  }

  updatePriority(e) {
    const position = e.target.value;
    const { activePage } = this.state;
    activePage.position = position;
    this.setState(activePage);
  }

  handleEditorReady(editor) {
    // this is a reference back to the editor if you want to
    // do editing programatically
    editor.insertHTML(this.state.activePage.body.body);
  }

  handleChange(event, newValue) {
    const { activePage } = this.state;
    activePage.body = newValue;
    this.setState({ activePage });
    // html is the new html content
    // text is the new text content
  }

  viewMore(topic) {
    const page = {
      id: -1,
      title: "",
      tags: [],
      topic,
      position: 0,
      created_at: "2021-04-14T20:27:16.289-07:00",
      updated_at: "",
      body: "",
    };
    this.setState({ activePage: page });
  }

  render() {
    return (
      <div className="page-wrapper">
        <div className="modal-content" id="help-container">
          <div>
            {this.state.activePage === null ? (
              <div className="help-index">
                {this.state.currentUser &&
                  this.state.currentUser.role_id >= 4 && (
                    <button
                      className="common-button-submit"
                      onClick={this.handleCreate}
                      type="button"
                    >
                      Create Help Page
                    </button>
                  )}
                <HelpTitle>
                  <HelpIconContainer>
                    <HelpIcon width="30px" height="30px" />
                  </HelpIconContainer>
                  <h2>SyncIt Help</h2>
                </HelpTitle>
                <h5 className="center">
                  Use the search bar or select a topic to find an answer
                </h5>
                <div className="flex-container flex-al-center">
                  <div
                    id="helpSearch"
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "auto",
                    }}
                  >
                    <i
                      aria-hidden="true"
                      style={{
                        position: "absolute",
                        pointerEvents: "none",
                        left: "16px",
                        color: "#519acc",
                        height: "21px",
                        width: "17px",
                      }}
                      className="material-icons prefix"
                    >
                      {" "}
                      search{" "}
                    </i>
                    <input
                      id="help-search-input"
                      className="browser-default big"
                      type="text"
                      onChange={this.updateSearchHandler}
                      placeholder="Search to locate a training resource"
                      aria-live="polite"
                      value={this.state.search}
                      aria-label="Search"
                    />
                  </div>
                </div>
                <div className="help-container">
                  {this.state.search === ""
                    ? Object.entries(this.state.tags).map((tag, index) => {
                        return (
                          <div className="help-topic" key={tag[0]}>
                            <p style={{ marginBottom: "15px" }}>
                              {tag[0]
                                .replace(/[\[\]']+/g, "")
                                .replace(/['"]+/g, "")}
                            </p>
                            <div className="help-topics-list">
                              {tag[1].slice(0, 6).map((page, innie) => {
                                return (
                                  <div className="help-item" key={innie}>
                                    <button
                                      value={page.id}
                                      onClick={this.getCurrentPage}
                                      className="help-topic-button"
                                    >
                                      {page.title === ""
                                        ? "Unfinished Help Page"
                                        : page.title}
                                    </button>
                                  </div>
                                );
                              })}
                              <div className="help-item-last">
                                <button
                                  className="help-topics-view-more-button"
                                  type="button"
                                  onClick={() => this.viewMore(tag[1][0].topic)}
                                >
                                  <span>View All Topics</span>
                                  <svg
                                    width="7"
                                    height="11"
                                    viewBox="0 0 7 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M0.496011 2.55814C-0.0500887 1.99535 -0.149379 1.07442 0.39672 0.511628C0.694592 0.204651 1.09176 0 1.43927 0C1.78679 0 2.18395 0.153488 2.43218 0.409302L6.75133 4.86047C7.09885 5.2186 7.09885 5.73023 6.75133 6.08837L5.31161 7.57209L2.38254 10.5907C1.83644 11.1535 0.942819 11.1535 0.347074 10.5907C-0.199025 10.0279 -0.199025 9.10698 0.347074 8.49302L3.27615 5.47442C3.27615 5.47442 3.27615 5.47442 3.27615 5.42326L0.496011 2.55814Z"
                                      fill="#519ACC"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : Object.entries(this.state.tags).map((tag, index) => {
                        const visible = tag[1].map((y) =>
                          y.tags.some((tag) =>
                            tag
                              .toLowerCase()
                              .includes(this.state.search.toLowerCase())
                          )
                        );
                        return (
                          <div
                            style={{
                              display: `${
                                visible.some((x) => x === true)
                                  ? "block"
                                  : "none"
                              }`,
                            }}
                            className="help-topic"
                            key={index}
                          >
                            <p style={{ marginBottom: "15px" }}>
                              {tag[0]
                                .replace(/[\[\]']+/g, "")
                                .replace(/['"]+/g, "")}
                            </p>
                            {tag[1].map((page, innie) => {
                              const innerVis = page.tags.some((tag) =>
                                tag
                                  .toLowerCase()
                                  .includes(this.state.search.toLowerCase())
                              );
                              return (
                                <div
                                  style={{
                                    display: `${innerVis ? "block" : "none"}`,
                                  }}
                                  className="help-item"
                                  key={innie}
                                >
                                  <button
                                    value={page.id}
                                    onClick={this.getCurrentPage}
                                  >
                                    {page.title === ""
                                      ? "Unfinished Help Page"
                                      : page.title}
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                  {this.state.search != "" &&
                    this.state.allTopics.filter((x) =>
                      x.toLowerCase().includes(this.state.search.toLowerCase())
                    ).length === 0 && (
                      <p className="no-results">
                        No matching results for this query
                      </p>
                    )}
                </div>
              </div>
            ) : (
              <OuterHelpContainer>
                <HelpHeaderContainer>
                  <HelpTitle>
                    <HelpIconContainer>
                      <HelpIcon width="30px" height="30px" />
                    </HelpIconContainer>
                    <h2>SyncIt Help</h2>
                  </HelpTitle>
                  <div
                    className="flex-container flex-al-center"
                    style={{ marginTop: "16px" }}
                  >
                    <button
                      className="btn-flat back"
                      onClick={this.handleBack}
                      type="button"
                    >
                      Help
                    </button>
                    <RoundDropdown
                      color="lightgrey"
                      height="10px"
                      width="10px"
                      rotate="rotate(270deg)"
                    />
                    <RoundDropdown
                      color="lightgrey"
                      height="10px"
                      width="10px"
                      rotate="rotate(270deg) translateY(-4px)"
                    />
                    <div>{this.state.activePage.topic}</div>
                  </div>
                </HelpHeaderContainer>
                <HelpDetailsContainer>
                  <HelpTopicsList>
                    <p
                      style={{ fontSize: "14px", fontWeight: "600", margin: 0 }}
                      className="help-manrope"
                    >
                      {
                        this.state.pages.filter(
                          (x) => x.topic === this.state.activePage.topic
                        ).length
                      }{" "}
                      Topics
                    </p>
                    <div className="help-scroll">
                      {this.state.pages
                        .filter((x) => x.topic === this.state.activePage.topic)
                        .map((page, index) => {
                          return (
                            <div className="help-item innie" key={index}>
                              <button
                                type="button"
                                style={{
                                  fontStyle: "italic",
                                  color: `${
                                    this.state.activePage.title === page.title
                                      ? "#519acc"
                                      : "#242424"
                                  }`,
                                }}
                                value={page.id}
                                onClick={this.getCurrentPage}
                              >
                                {page.title === ""
                                  ? "Unfinished Help Page"
                                  : page.title}
                              </button>
                            </div>
                          );
                        })}
                    </div>
                  </HelpTopicsList>
                  <HelpDetailsBody>
                    {this.state.editMode === false ? (
                      <div className="editable-false">
                        <HelpTopicHeaderOuterContainer className="flex-container flex-al-center">
                          <HelpTopicHeaderInnerContainer>
                            {this.state.activePage &&
                            this.state.activePage.id != -1 ? (
                              <div>
                                <h4 style={{ marginBottom: "8px" }}>
                                  {this.state.activePage.title}
                                </h4>
                                <span
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "12px",
                                  }}
                                >
                                  Last Updated:{" "}
                                  {this.state.activePage.updated_at}
                                </span>
                              </div>
                            ) : (
                              <div>
                                <p>Choose an article on the left</p>
                              </div>
                            )}
                            {this.state.currentUser &&
                              this.state.currentUser.role_id >= 4 &&
                              this.state.activePage &&
                              this.state.activePage.id != -1 && (
                                <EditControls>
                                  <button
                                    className="btn btn-flat"
                                    onClick={this.toggleEditMode}
                                    type="button"
                                  >
                                    <EditIcon height={20} width={20} />
                                  </button>
                                  <button
                                    className="btn btn-flat"
                                    onClick={this.deleteClick}
                                    type="button"
                                  >
                                    <img
                                      alt="trash"
                                      src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/syncit/Icon__trash_1.svg"
                                      style={{ width: "20px", height: "20px" }}
                                    />
                                  </button>
                                </EditControls>
                              )}
                          </HelpTopicHeaderInnerContainer>
                        </HelpTopicHeaderOuterContainer>
                        <HelpTopicContent
                          dangerouslySetInnerHTML={{
                            __html: this.state.activePage.body,
                          }}
                          className="trix-content"
                        />
                      </div>
                    ) : (
                      <div className="editable-true">
                        <div className="input-field flex-container flex-al-center flex-wrap">
                          <input
                            type="text"
                            style={{ flex: "1 1 100%" }}
                            value={this.state.activePage.topic}
                            onChange={this.updateTopic}
                            className="browser-default text-input-v2"
                          />
                          <label>
                            Topic: Which section each article is placed into,
                            ex: Client
                          </label>
                        </div>
                        <div className="input-field flex-container flex-al-center flex-wrap">
                          <input
                            type="text"
                            style={{ flex: "1 1 100%" }}
                            value={this.state.activePage.title}
                            onChange={this.updateTitle}
                            className="browser-default text-input-v2"
                          />
                          <label>Title:</label>
                        </div>
                        <div className="input-field flex-container flex-al-center flex-wrap">
                          <input
                            type="text"
                            style={{ flex: "1 1 100%" }}
                            value={this.state.activePage.tags}
                            onChange={this.updateTags}
                            className="browser-default text-input-v2"
                          />
                          <label>
                            Tags: these help users find articles, please
                            separate each tag with a comma ex: pizza, eggrolls
                          </label>
                        </div>
                        <div
                          style={{ width: "100px" }}
                          className="input-field flex-container flex-al-center flex-wrap"
                        >
                          <input
                            type="number"
                            style={{ flex: "1 1 100%" }}
                            value={this.state.activePage.position}
                            onChange={this.updatePriority}
                            className="browser-default text-input-v2"
                          />
                          <label>Priority: </label>
                        </div>
                        <div className="help-editable-body">
                          <ReactTrixRTEInput
                            defaultValue={this.state.activePage.body}
                            onChange={this.handleChange}
                            isRailsDirectUpload
                          />
                          <div className="flex-container flex-j-end">
                            <button
                              className="common-button-cancel"
                              style={{ marginRight: "10px" }}
                              onClick={this.toggleEditMode}
                              type="button"
                            >
                              Cancel
                            </button>
                            <button
                              disabled={
                                this.state.activePage.title === "" ||
                                this.state.activePage.tags === "" ||
                                this.state.activePage.topic === ""
                              }
                              className="common-button-submit"
                              onClick={this.handleSave}
                              type="button"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.activePage === null && (
                      <div className="help-footer">
                        <p>Can&apos;t find what you&apos;re looking for?</p>
                        <button
                          onClick={this.contactGuerd}
                          className="btn common-button-submit"
                        >
                          Let Us Know{" "}
                        </button>
                      </div>
                    )}
                  </HelpDetailsBody>
                </HelpDetailsContainer>
              </OuterHelpContainer>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const HelpTitle = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  height: 24px;

  h2 {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 700;
    color: #242424;
    margin: 0;
  }
`;

const HelpIconContainer = styled.div`
  height: 28px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

const OuterHelpContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HelpHeaderContainer = styled.div`
  width: 100%;
  padding: 24px 32px;
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid lightgrey;
`;
const HelpDetailsContainer = styled.div`
  display: flex;
  width: 100%;
`;

const HelpDetailsBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 75%;
`;

const HelpTopicsList = styled.div`
  flex-basis: 25%;
  padding: 24px 32px;
  border-right: 1px solid lightgrey;
`;

const HelpTopicHeaderOuterContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid lightgrey;
`;

const HelpTopicHeaderInnerContainer = styled.div`
  width: 100%;
  padding: 24px 32px;
  position: relative;
`;

const HelpTopicContent = styled.div`
  padding: 24px 32px;
`;

const EditControls = styled.div`
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
`;
export default Help;
