import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import axios from "axios";
import styled from "styled-components";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import SingleSelectStyles from "components/common/single_select_styles";
import SingleSelectWrapper from "components/common/single_select_wrapper";
import NumberCounter from "components/common/number_counter";
import ModalHeadline from "components/modals/ModalHeadline";
import CalendarIcon from "components/icons/calendar_icon";
import RoundDropdown from "components/projects/round_dropdown";
import ms from "./ModalStyled";

const ReportSickTime = (props) => {
  const { data, refreshUserTasks, modalAction } = props;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startPickerFocus, setStartPickerFocus] = useState(false);
  const [endPickerFocus, setEndPickerFocus] = useState(false);
  const [manager, setManager] = useState(null);
  const [options, setOptions] = useState([]);
  const [hours, setHours] = useState(8);
  const [timeOffDetails, setTimeOffDetails] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (manager === null) {
      // eslint-disable-next-line no-undef
      M.toast({
        html: `<span aria-live="assertive">Please select a manager</span>`,
        displayLength: 3000,
        classes: "red",
      });
      return null;
    }

    axios
      .get(`/api/v1/users/report_sick_time`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          user_name: data.user.name,
          user_id: data.user.id,
          startDate: moment(startDate).format("MMMM Do, YYYY"),
          endDate: moment(endDate).format("MMMM Do, YYYY"),
          hours: startDate.isSame(endDate) ? hours : 8,
          manager: manager.value,
          details: timeOffDetails,
        },
      })
      .then(() => {
        // eslint-disable-next-line no-undef
        M.toast({
          html: `<span role="alert" >Sick Time Reported</span>`,
          displayLength: 5000,
          classes: "green",
        });
        // Announce to screen reader that sick time was reported
        const liveRegion = document.getElementById("live-region");
        if (liveRegion) {
          liveRegion.textContent = "Sick Time Reported";
        }
        refreshUserTasks();
        document.getElementById("sick-time-button").focus();
        modalAction(false, "", {});
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        M.toast({
          html: `<span role="alert" There was an error submitting the sick time</span>`,
          displayLength: 3000,
          classes: "red",
        });
        const liveRegion = document.getElementById("live-region");
        if (liveRegion) {
          liveRegion.textContent =
            "There was an error submitting the sick time";
        }
        console.error(err);
      });
    return null;
  };

  const isDayBlocked = (day) => {
    return !!(moment(day).day() === 0 || moment(day).day() === 6);
  };

  const handleInputChange = (e) => {
    setManager(e);
    const elem = document.getElementById(`aria-custom-status`);
    if (elem && manager) {
      elem.innerText = `manager ${manager.label} selected`;
    }
  };

  const handleHourChange = (newHours) => {
    setHours(newHours);
  };

  const handleStartFocus = (focused) => {
    setStartPickerFocus(focused.focused);
  };

  const handleEndFocus = (focused) => {
    setEndPickerFocus(focused.focused);
  };

  const handleEndDateChange = (date) => {
    if (moment(date).isAfter(startDate) || moment(date).isSame(startDate)) {
      setEndDate(date);
    } else {
      setStartDate(date);
      setEndDate(date);
    }
    const elem = document.getElementById(`aria-custom-status`);
    if (elem) {
      elem.innerText = `${moment(date).format(
        "MMMM Do YYYY"
      )} selected for end date`;
    }
  };

  const handleStartDateChange = (date) => {
    if (moment(date).isBefore(endDate) || moment(date).isSame(endDate)) {
      setStartDate(date);
    } else {
      setStartDate(date);
      setEndDate(date);
    }
    const elem = document.getElementById(`aria-custom-status`);
    if (elem) {
      elem.innerText = `${moment(date).format(
        "MMMM Do YYYY"
      )} selected for start date`;
    }
  };

  const onSelectKeyDown = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      setTimeout(() => {
        const elem = document.querySelector(
          ".react-select__option--is-focused"
        );
        const ariaElem = document.getElementById(`aria-custom-status`);
        if (elem && ariaElem) {
          ariaElem.innerText = elem.classList.contains(
            "react-select__option--is-selected"
          )
            ? `option ${elem.innerText}, currently selected`
            : `option ${elem.innerText}`;
        }
      }, 50);
    }
  };

  const closeModal = () => {
    modalAction(false, "", {});
  };

  const singleDay = useMemo(() => {
    return startDate && endDate && startDate.isSame(endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    const elems = document.querySelectorAll(
      ".SingleDatePickerInput_calendarIcon"
    );
    elems.forEach((e) => e.setAttribute("aria-hidden", "true"));
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };

    axios
      .get(`/api/v1/users/managers`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const nextOptions = res.data.map((user) => {
          return { value: user.id, label: user.name };
        });
        setOptions(nextOptions);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <SickTimeModal
      id="submit-sick-time-modal"
      aria-label="Submit Sick Time"
      className="modal-content"
      style={{ maxHeight: "96vh" }}
    >
      <ModalHeadline modalKey="report_sick_time" closeModal={closeModal} />
      <ms.Form
        className="new_time_off_request"
        id="new_time_off_request"
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <ms.FormRowDouble>
          <ms.FormItemDouble>
            <ms.Label className="custom-labels" htmlFor="filter_filters">
              Manager<span className="base-color">*</span>
            </ms.Label>
            <SingleSelectWrapper
              options={options}
              value={manager}
              onChange={handleInputChange}
              onKeyDown={onSelectKeyDown}
              placeholder="Select a manager"
              name="manager"
              styles={{
                ...SingleSelectStyles,
                container: (styles) => ({ ...styles, width: "100%" }),
              }}
            />
          </ms.FormItemDouble>
          <ms.FormItemDouble />
        </ms.FormRowDouble>
        <ms.FormRowDouble>
          <ms.FormItemDouble>
            <ms.Label className="custom-labels" htmlFor="filter_filters">
              Start Date<span className="base-color">*</span>
            </ms.Label>
            <div
              className="flex-j-start flex-container flex-al-center"
              aria-label="start date picker"
            >
              <div
                style={{
                  position: "relative",
                  left: "16px",
                  zIndex: 1,
                  top: "5px",
                }}
              >
                <CalendarIcon width="22px" height="22px" color="#519acc" />
              </div>
              <SingleDatePicker
                date={startDate}
                onDateChange={handleStartDateChange}
                focused={startPickerFocus}
                onFocusChange={handleStartFocus}
                numberOfMonths={1}
                placeholder="Select a date"
                orientation="horizontal"
                ariaLabel="Start Date picker. Use arrow keys to navigate calendar"
                daySize={30}
                navPrev={
                  <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                    <RoundDropdown rotate="rotate(90deg)" />
                  </div>
                }
                navNext={
                  <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                    <RoundDropdown rotate="rotate(-90deg)" />
                  </div>
                }
                hideKeyboardShortcutsPanel
                inputIconPosition="after"
                customInputIcon={<RoundDropdown />}
                isOutsideRange={isDayBlocked}
                id="single_date_picker"
              />
            </div>
          </ms.FormItemDouble>
          <ms.FormItemDouble>
            <ms.Label className="custom-labels" htmlFor="filter_filters">
              End Date<span className="base-color">*</span>
            </ms.Label>
            <div
              className="flex-j-start flex-container flex-al-center"
              aria-label="end date picker"
            >
              <div
                style={{
                  position: "relative",
                  left: "16px",
                  zIndex: 1,
                  top: "5px",
                }}
              >
                <CalendarIcon width="22px" height="22px" color="#519acc" />
              </div>
              <SingleDatePicker
                date={endDate}
                onDateChange={handleEndDateChange}
                focused={endPickerFocus}
                onFocusChange={handleEndFocus}
                numberOfMonths={1}
                placeholder="Select a date"
                orientation="horizontal"
                daySize={30}
                navPrev={
                  <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                    <RoundDropdown rotate="rotate(90deg)" />
                  </div>
                }
                navNext={
                  <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                    <RoundDropdown rotate="rotate(-90deg)" />
                  </div>
                }
                hideKeyboardShortcutsPanel
                inputIconPosition="after"
                customInputIcon={<RoundDropdown />}
                isOutsideRange={isDayBlocked}
                id="single_date_picker"
              />
            </div>
          </ms.FormItemDouble>
        </ms.FormRowDouble>
        <ms.FormRow>
          <ms.Disclaimer>
            Please confirm your available sick time in{" "}
            {data.user.full_time ? "Harvest" : "ADP"}
          </ms.Disclaimer>
        </ms.FormRow>

        {singleDay && (
          <ms.FormRow>
            <ms.FormItem>
              <ms.Label className="custom-labels" htmlFor="filter_filters">
                Hours
              </ms.Label>
              <NumberCounter
                disabled={false}
                min={1}
                max={8}
                value={hours}
                step={1}
                updateValue={handleHourChange}
              />
            </ms.FormItem>
          </ms.FormRow>
        )}
        <ms.FormRow>
          <ms.FormItem>
            <ms.Label className="custom-labels" htmlFor="filter_filters">
              Notes
            </ms.Label>
            <textarea
              aria-label="sick time details, optional"
              type="text"
              name="time_off[name]"
              id="time_off_details"
              className="browser-default"
              style={{ minHeight: "100px", resize: "none" }}
              maxLength={1000}
              value={timeOffDetails}
              placeholder="Optional"
              onChange={(e) => setTimeOffDetails(e.target.value)}
            />
          </ms.FormItem>
        </ms.FormRow>
        <ms.ControlsRow>
          <button
            type="button"
            onClick={closeModal}
            className="common-button-cancel"
          >
            Cancel
          </button>
          <input
            type="submit"
            name="commit"
            value="Submit"
            className="common-button-submit white-text"
            id="submit_request_button"
            disabled={!startDate || !endDate}
          />
        </ms.ControlsRow>
      </ms.Form>
      {/* Hidden live region for announcements */}
      <div
        id="live-region"
        aria-live="polite"
        style={{ position: "absolute", left: "-9999px" }}
      ></div>
    </SickTimeModal>
  );
};

const SickTimeModal = styled.div`
  /* > div:nth-child(1) {
    display: flex;
    align-items: center;
  } */

  > div:nth-child(1) img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  > div:nth-child(1) h2 {
    margin: 0px;
    font-size: 18px;
    font-family: Manrope;
    font-weight: 500;
    color: #313131;
  }

  textarea {
    margin-top: 0;
    padding: 12px 16px;
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    min-height: 42px;
    font-size: 13px;
    font-family: Arial;
    font-weight: 400;

    &::placeholder {
      color: #c0c0c0;
    }
  }

  .hide-focus:focus {
    box-shadow: none !important;
    outline: none !important;
  }
`;

export default ReportSickTime;
