import React from "react";

class NameColumn extends React.Component {
  constructor(props) {
    super(props);

    this.removeUser = this.removeUser.bind(this);
  }

  removeUser() {
    this.props.modalAction(true, "project-remove-user", {
      projectId: this.props.projectId,
      userId: this.props.id,
    });
  }

  render() {
    return (
      <div className="col s2 " style={{ display: "flex" }}>
        <div className="col s12" id="userNameCol">
          <div className="col s1">
            <span
              className={this.props.fullTime ? "full-time" : "part-time"}
            />
          </div>
          <div className="col s11">
            <p className="truncate">
              {this.props.showRemoveUser ? (
                <i
                  className="material-icons person-edit black-text user-remove hover"
                  onClick={this.removeUser}
                >
                  remove_circle_outline
                </i>
              ) : (
                ""
              )}
              {this.props.name}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default NameColumn;
