import React from "react";

const YellowRoomIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M19.7144 6V5C19.7144 4.46957 19.5036 3.96086 19.1286 3.58579C18.7535 3.21071 18.2448 3 17.7144 3H15.7144C15.1839 3 14.6752 3.21071 14.3001 3.58579C13.9251 3.96086 13.7144 4.46957 13.7144 5V6H11.7144V5C11.7144 4.46957 11.5036 3.96086 11.1286 3.58579C10.7535 3.21071 10.2448 3 9.71436 3H7.71436C7.18392 3 6.67521 3.21071 6.30014 3.58579C5.92507 3.96086 5.71436 4.46957 5.71436 5V6H3.71436V20H21.7144V6H19.7144Z"
        fill="white"
      />
    </svg>
  );
};

export default YellowRoomIcon;
