import React, { useState, useRef, useEffect } from "react";
import Select, { components } from "react-select";
import styled from "styled-components";
import RoundDropdown from "../projects/round_dropdown";
import SingleSelectStyles from "./single_select_styles";

const SingleSelectWrapper = (props) => {
  const [selectedValue, setSelectedValue] = useState(props.value);
  const [menuOpen, setMenuOpen] = useState(false);
  const selectRef = useRef();

  useEffect(() => {
    setSelectedValue(props.value);
  }, [props.value]);

  const onMenuOpen = () => {
    setMenuOpen(true);
    setTimeout(() => {
      const menuElement = document.querySelector(".react-select__menu");
      const optionElements = document.querySelectorAll(".react-select__option");
      if (menuElement) {
        menuElement.blur();
        menuElement.setAttribute("role", "listbox");
        optionElements.forEach((option) => {
          option.setAttribute("tabindex", "0");
          option.setAttribute("role", "option");
        });
      }
    }, 0);
  };

  const onMenuClose = () => {
    setMenuOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      const focusableOptions = document.querySelectorAll(
        ".react-select__option"
      );
      const currentFocusIndex = Array.prototype.indexOf.call(
        focusableOptions,
        document.activeElement
      );
      const nextFocusIndex = currentFocusIndex + (event.shiftKey ? -1 : 1);
      if (nextFocusIndex >= 0 && nextFocusIndex < focusableOptions.length) {
        focusableOptions[nextFocusIndex].focus();
      } else {
        selectRef.current.select.inputRef.focus();
      }
    }
  };

  useEffect(() => {
    const menuElement = document.querySelector(".react-select__menu");
    if (menuElement) {
      menuElement.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      if (menuElement) {
        menuElement.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, []);

  return (
    <SelectAriaWrapper>
      <Select
        classNamePrefix="react-select"
        name={props.name}
        ref={selectRef}
        options={props.options}
        onChange={(newValue) => {
          setSelectedValue(newValue);
          if (props.onChange) {
            props.onChange(newValue, { name: props.name });
          }
        }}
        components={{
          DropdownIndicator: (componentProps) => (
            <RoundDropdown {...componentProps} />
          ),
          SingleValue: (componentProps) => (
            <div style={{ display: "grid", color: "#000" }}>
              <components.SingleValue {...componentProps} />
            </div>
          ),
        }}
        placeholder={props.placeholder}
        blurInputOnSelect={props.blurInputOnSelect || false}
        isSearchable={true}
        defaultValue={props.defaultValue || undefined}
        value={selectedValue}
        className={props.className || ""}
        isDisabled={props.isDisabled}
        isOptionDisabled={props.isOptionDisabled}
        menuPlacement={props.menuPlacement}
        styles={{
          ...SingleSelectStyles,
          padding: "8px",
          theme: (theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
            },
          }),
          ...props.customStyles,
        }}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        menuIsOpen={menuOpen}
      />
    </SelectAriaWrapper>
  );
};

SingleSelectWrapper.defaultProps = {
  customStyles: {},
  menuPlacement: "auto",
  className: "",
};

const SelectAriaWrapper = styled.div``;

export default SingleSelectWrapper;
