const baseColor = "#519acc";
const lightGrey = "#f0f0f0";
const SingleSelectStyles = {
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: "white",
      borderRadius: "5px",
      outlineColor: baseColor,
      cursor: "pointer",
      boxShadow: state.isFocused
        ? `1px 1px 1px 0px ${baseColor}`
        : `1px 1px 3px 0px ${lightGrey}`,
      borderStyle: "solid",
      borderWidth: "1px",
      color: state.isFocused ? `#d1d1d1` : "#313131",
      borderColor: state.menuIsOpen
        ? baseColor
        : state.isFocused
        ? baseColor
        : "#d3d3d3",
      minHeight: "35px",
      height: "39px",
      "&:hover": {
        borderColor: baseColor,
      },
    };
  },
  placeholder: (styles) => {
    return {
      ...styles,
      color: "inherit",
      position: "relative",
    };
  },
  singleValue: (styles, state) => {
    return {
      ...styles,
      color: state.selectProps.isSearchable ? "inherit" : "#313131",
      height: "100%",
      maxHeight: 21,
    };
  },
  container: (styles) => ({
    ...styles,
    fontFamily: "Arial",
    width: "200px",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    height: "100%",
    marginTop: "0",
    backgroundColor: "transparent",
  }),
  input: (styles) => {
    return {
      ...styles,
      boxShadow: "none !important",
      margin: "0",
      padding: "0",
      height: "21px",
      position: "relative",
      input: { height: 21 },
    };
  },
  menu: (styles) => ({
    ...styles,
    transform: "translateX(-12.5px)",
    width: "225px",
    borderRadius: "5px",
    boxShadow: "0 0 9px 2px hsla(0,0%,0%,0.3), 0 4px 11px hsla(0,0%,0%,0.1)",
    zIndex: 2,
  }),
  menuList: (styles) => ({ ...styles, borderRadius: "5px" }),
  valueContainer: (styles) => {
    return {
      ...styles,
      display: "flex",
      maxHeight: "42px",
      height: "100%",
      color: "inherit",
      overflow: "visible",
      input: { position: "absolute" },
      "input[readonly]": {
        position: "absolute",
      },
    };
  },
  indicatorsContainer: (styles, state) => ({
    ...styles,
    color: state.isFocused ? baseColor : baseColor,
    alignItems: "center",
    height: "42px",
    padding: "8px",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: `${baseColor} !important`,
    cursor: "pointer",
    alignItems: "center",
    height: "42px",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    let backgroundColor = "white";
    if (isFocused && !isDisabled && !isSelected) {
      backgroundColor = baseColor;
    }
    return {
      ...styles,
      backgroundColor,
      color: isFocused
        ? isSelected
          ? "#519acc"
          : "white"
        : isSelected
        ? "#519acc"
        : "#313131",
      cursor: isDisabled ? "not-allowed" : "pointer",
      paddingLeft: "25px",
    };
  },
};

export default SingleSelectStyles;
