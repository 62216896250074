import {
  shape, string, number, bool, arrayOf, oneOfType
} from "prop-types";

const userLite = shape({
  avatar_file_name: string,
  avatar_url: string,
  id: number,
  name: string,
});

const lastInvoicedTypes = shape({
  budgeted_hours: number,
  completed_date: string,
  created_at: string,
  entry_end_date: string,
  entry_start_date: string,
  id: number,
  inv_number: string,
  invoice_status: number,
  new_invoice: bool,
  notes: string,
  po_number: string,
  project_id: number,
  submitted_date: string,
  total_hours: number,
  tracked_hours: number,
  updated_at: string,
});

const taskboardProjectTypes = shape({
  access_ids: arrayOf(number),
  archive_after_billing: bool,
  billable: bool,
  budget_remaining: number,
  budgeted_hours: number,
  bug_tracker_tp: oneOfType([bool, string]),
  client_id: number,
  client_name: string,
  client_parent: bool,
  comment_count: number,
  completion_date: string,
  created_at: string,
  est_due_date: string,
  est_send_date: string,
  estimate_owner_avatar_url: string,
  estimate_owner_id: number,
  estimate_owner_name: string,
  follow_up_date: string,
  id: number,
  invoice_notes: string,
  last_invoiced: lastInvoicedTypes,
  last_ready_for_invoice: lastInvoicedTypes,
  lead_ids: arrayOf(number),
  lead_owner_avatar_url: string,
  lead_owner_id: number,
  lead_owner_name: string,
  lead_source: string,
  leads: arrayOf(userLite),
  name: string,
  po_number: string,
  project_links: arrayOf(
    shape({
      id: number,
      link_type: string,
      location: string,
      name: string,
      project_id: number,
      user_id: number,
    })
  ),
  project_managers: arrayOf(userLite),
  project_status: shape({
    id: number,
    project_id: number,
    aasm_state: string,
    active: bool,
  }),
  rate: number,
  tag: shape({ id: number, name: string, color: string }),
  test_end_date: string,
  test_start_date: string,
  updated_at: string,
  users: arrayOf(
    shape({
      avatar_url: string,
      email: string,
      id: number,
      name: string,
      role: shape({
        id: number,
        name: string,
        created_at: string,
        updated_at: string,
      }),
    })
  ),
});

export default taskboardProjectTypes;
