import React from "react";
import moment from "moment";
import ReactDOM from "react-dom";
import axios from "axios";
import ReactModal from "../modals/react_modal";

class CraterView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDay: moment().format("MM-DD-YYYY"),
      bookings: this.props.bookings,
      bookingsArray: [[]],
      currentTime: moment().format("h:mm"),
      currentTimeIndex: 0,
      chinookAvailable: true,
      shastaAvailable: true,
      wascoAvailable: true,
      legoAvailable: true,
      nextBookingArray: [36],
      modalOpen: false,
      currentModal: "",
      modalData: {},
    };
    this.counter = 0;
    this.timeArray = [
      "9:00",
      "9:15",
      "9:30",
      "9:45",
      "10:00",
      "10:15",
      "10:30",
      "10:45",
      "11:00",
      "11:15",
      "11:30",
      "11:45",
      "12:00",
      "12:15",
      "12:30",
      "12:45",
      "1:00",
      "1:15",
      "1:30",
      "1:45",
      "2:00",
      "2:15",
      "2:30",
      "2:45",
      "3:00",
      "3:15",
      "3:30",
      "3:45",
      "4:00",
      "4:15",
      "4:30",
      "4:45",
      "5:00",
      "5:15",
      "5:30",
      "5:45",
    ];
    this.renderRoomRows = this.renderRoomRows.bind(this);
    this.renderCraterRow = this.renderCraterRow.bind(this);
    this.handleRoomCardClick = this.handleRoomCardClick.bind(this);
    this.setModal = this.setModal.bind(this);
    this.refreshBookings = this.refreshBookings.bind(this);
    this.refreshRows = this.refreshRows.bind(this);
  }

  componentDidMount() {
    const elem = document.querySelector("header");
    elem.parentNode.removeChild(elem);
    const container = document.getElementsByClassName("container");
    if (container && container[0]) {
      container[0].style.paddingLeft = "1%";
      container[0].style.paddingRight = "1%";
    }

    setInterval(() => {
      if (moment().format("h:mm") !== this.state.currentTime) {
        const dayMinutes = moment().diff(moment().startOf("day"), "minutes") - 540;
        if (dayMinutes >= 0) {
          const timeIndex = Math.floor(dayMinutes / 15);
          if (timeIndex < 36) {
            let craterAvailable = true;
            const nextBookingArray = [36];
            for (let x = timeIndex; x < 36; x++) {
              if (
                this.state.bookingsArray[0][x] === "1"
                && nextBookingArray[0] === 36
              ) {
                nextBookingArray[0] = x;
              }
            }
            if (this.state.bookingsArray[0][timeIndex] === "1") {
              craterAvailable = false;
            }

            this.setState({
              currentTimeIndex: timeIndex,
              nextBookingArray,
              craterAvailable,
            });
          }
        }
        this.setState({
          currentTime: moment().format("h:mm"),
        });
        this.refreshBookings();
      }
    }, 3000);

    this.refreshRows();
  }

  renderCraterRow(time, index) {
    return this.renderRoomRows(time, index, 0);
  }

  renderRoomRows(time, index, roomIndex) {
    if (this.counter > 0) {
      this.counter -= 1;
      return;
    }
    if (time === "0") {
      return (
        <div key={`${roomIndex}-${index}`} className="room-view-cell-open">
          -
        </div>
      );
    }
    for (
      let x = index;
      x < this.state.bookingsArray[roomIndex].length - 1;
      x++
    ) {
      if (this.state.bookingsArray[roomIndex][x + 1] === "1") {
        this.counter += 1;
      } else {
        break;
      }
    }
    return (
      <div
        key={`${roomIndex}-${index}`}
        className="room-view-cell-booked"
        style={{ width: `${2.35 * (this.counter + 1)}%` }}
      />
    );
  }

  handleRoomCardClick(e) {
    const dayMinutes = moment().diff(moment().startOf("day"), "minutes") - 540;
    const extendBooking = dayMinutes % 15 > 0;
    if (e.target.id === "crater-room-card") {
      if (this.state.craterAvailable) {
        this.setModal(true, "add-room-view-booking", {
          room_id: 1,
          room_name: "Crater Lake Room",
          bookingsArray: this.state.bookingsArray[0],
          extendBooking,
          currentTimeIndex: this.state.currentTimeIndex,
        });
      } else {
        let selectedBooking = {};
        this.state.bookings.forEach((booking) => {
          if (
            booking.room.name === "Crater Lake"
            && booking.booking_array[this.state.currentTimeIndex + 4] === "1"
          ) {
            selectedBooking = booking;
          }
        });
        const updatedBookingsArray = this.state.bookingsArray[0].map((x, index) =>
          (x === selectedBooking.booking_array[index + 4] ? "0" : x));
        this.setModal(true, "current-booking", {
          selectedBooking,
          bookingsArray: updatedBookingsArray,
          extendBooking,
          currentTimeIndex: this.state.currentTimeIndex,
        });
      }
    }
  }

  refreshBookings() {
    axios
      .get("/meetings/annex", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.setState(
          {
            bookings: res.data,
          },
          this.refreshRows
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  refreshRows() {
    const bookingsArray = [];
    for (var x = 0; x < 1; x++) {
      bookingsArray.push([
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
      ]);
    }
    for (var x = 0; x < this.state.bookings.length; x++) {
      if (this.state.bookings[x].room.name === "Crater Lake") {
        this.state.bookings[x].booking_array.forEach((time, index) => {
          if (time === "1") {
            bookingsArray[0][index] = "1";
          }
        });
      }
    }
    bookingsArray[0].shift();
    bookingsArray[0].shift();
    bookingsArray[0].shift();
    bookingsArray[0].shift();

    const dayMinutes = moment().diff(moment().startOf("day"), "minutes") - 540;
    if (dayMinutes >= 0) {
      const timeIndex = Math.floor(dayMinutes / 15);
      if (timeIndex < 36) {
        let craterAvailable = true;
        const nextBookingArray = [36];
        for (var x = timeIndex; x < 36; x++) {
          if (bookingsArray[0][x] === "1" && nextBookingArray[0] === 36) {
            nextBookingArray[0] = x;
          }
        }
        if (bookingsArray[0][timeIndex] === "1") {
          craterAvailable = false;
        }

        this.setState({
          currentTimeIndex: timeIndex,
          nextBookingArray,
          craterAvailable,
        });
      }
    }

    this.setState({
      bookingsArray,
    });
  }

  setModal(bool, page, data) {
    this.setState({
      modalOpen: bool,
      currentModal: page,
      modalData: data,
    });
  }

  render() {
    return (
      <div>
        <ReactModal
          refreshBookings={this.refreshBookings}
          isShowing={this.state.modalOpen}
          page={this.state.currentModal}
          data={this.state.modalData}
          modalAction={this.setModal}
        />
        <div>
          <div
            style={{
              display: "center",
              margin: "5px",
              height: "100%",
              position: "relative",
            }}
          >
            <div
              className="room-grid-outline"
              style={{
                width: "50%",
                minWidth: "70%",
                display: "inline-block",
                border: "none",
              }}
            >
              <span
                style={{
                  width: "100%",
                  display: "block",
                  textAlign: "center",
                  fontSize: "30px",
                }}
              >
                Today's Schedule
              </span>
              <div id="schedule-heading" style={{ width: "100%" }}>
                <div
                  style={{
                    display: "inline-block",
                    height: "50px",
                    width: "15%",
                  }}
                />
                <div
                  className="time-label-hour-border-crater"
                  style={{
                    display: "inline-block",
                    height: "50px",
                    width: "9.4%",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  9am
                </div>
                <div
                  className="time-label-hour-border-crater"
                  style={{
                    display: "inline-block",
                    height: "50px",
                    width: "9.4%",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  10am
                </div>
                <div
                  className="time-label-hour-border-crater"
                  style={{
                    display: "inline-block",
                    height: "50px",
                    width: "9.4%",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  11am
                </div>
                <div
                  className="time-label-hour-border-crater"
                  style={{
                    display: "inline-block",
                    height: "50px",
                    width: "9.4%",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  12pm
                </div>
                <div
                  className="time-label-hour-border-crater"
                  style={{
                    display: "inline-block",
                    height: "50px",
                    width: "9.4%",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  1pm
                </div>
                <div
                  className="time-label-hour-border-crater"
                  style={{
                    display: "inline-block",
                    height: "50px",
                    width: "9.4%",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  2pm
                </div>
                <div
                  className="time-label-hour-border-crater"
                  style={{
                    display: "inline-block",
                    height: "50px",
                    width: "9.4%",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  3pm
                </div>
                <div
                  className="time-label-hour-border-crater"
                  style={{
                    display: "inline-block",
                    height: "50px",
                    width: "9.4%",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  4pm
                </div>
                <div
                  style={{
                    display: "inline-block",
                    height: "50px",
                    width: "9.4%",
                    textAlign: "center",
                  }}
                >
                  5pm
                </div>
              </div>
              <div id="crater-row" style={{ width: "100%", height: "50px" }}>
                <div className="room-view-row-label">
                  <div
                    className="color-1"
                    style={{ display: "inline-block" }}
                  />
                  Crater Lake
                </div>
                {this.state.bookingsArray[0].map(this.renderCraterRow)}
              </div>
            </div>
            <div id="room-current-time-container">
              <span>{this.state.currentTime}</span>
            </div>
            <div id="room-view-booking-container">
              <div>
                <div
                  id="crater-room-card"
                  className="room-view-room-card"
                  onClick={this.handleRoomCardClick}
                >
                  <div
                    className={`room-view-card-overlay ${
                      this.state.craterAvailable
                        ? "room-view-card-overlay-open"
                        : "room-view-card-overlay-booked"
                    }`}
                  >
                    <span>
                      {this.state.craterAvailable
                        ? this.state.nextBookingArray[0] === 36
                          ? "Open"
                          : `Open until ${
                            this.timeArray[this.state.nextBookingArray[0]]
                          }`
                        : "In Use"}
                    </span>
                  </div>
                </div>
                <span>Crater Lake Room</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CraterView;
