import React from "react";

const UsersIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M14 0C15.8565 0 17.637 0.737498 18.9497 2.05025C20.2625 3.36301 21 5.14348 21 7C21 8.85652 20.2625 10.637 18.9497 11.9497C17.637 13.2625 15.8565 14 14 14C12.1435 14 10.363 13.2625 9.05025 11.9497C7.7375 10.637 7 8.85652 7 7C7 5.14348 7.7375 3.36301 9.05025 2.05025C10.363 0.737498 12.1435 0 14 0ZM14 28C14 28 28 28 28 24.5C28 20.3 21.175 15.75 14 15.75C6.825 15.75 0 20.3 0 24.5C0 28 14 28 14 28Z"
        fill="url(#paint0_linear_2033_4894)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2033_4894"
          x1="14"
          y1="0"
          x2="14"
          y2="28"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519ACC" />
          <stop offset="1" stopColor="#2B88C8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default UsersIcon;
